import { addBlock as add } from './addBlock'
import { blockExists, groupExists } from './blockExists'
import { findBlock } from './findBlock'
import { findBlocks } from './findBlocks'
import { removeBlockByName as remove } from './removeBlockByName'
import { rename } from './rename'
import { ungroup } from './ungroup'
import { update } from './update'

export const blockController = {
  findBlocks,
  findBlock,
  rename,
  remove,
  ungroup,
  update,
  add,
  blockExists,
  groupExists
}

export type BlockController = typeof blockController
