import type { EpilotPalette, EpilotTheme, EpilotTypography } from './types'

export const DefaultEpilotTheme: EpilotTheme = {
  prefix: '',
  typography: {
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontSize: 14,
    fontFamily: 'ProximaNova, Open Sans, Helvetica, Arial, sans-serif',
    h1: {
      fontSize: 96
    },
    h2: {
      fontSize: 60
    },
    h3: {
      fontSize: 48,
      lineHeight: '120%'
    },
    h4: {
      fontSize: 32,
      lineHeight: '120%'
    },
    h5: {
      fontSize: 24,
      lineHeight: '150%'
    },
    h6: {
      fontSize: 20,
      lineHeight: '150%'
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '150%'
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: '150%'
    },
    body1: {
      fontSize: 16,
      lineHeight: '150%'
    },
    body2: {
      fontSize: 14,
      lineHeight: '157%'
    },
    button: {
      fontSize: 16,
      textTransform: 'none'
    },
    caption: {
      fontSize: 12,
      lineHeight: '150%'
    },
    overline: {
      fontSize: 12,
      lineHeight: '150%',
      textTransform: 'uppercase'
    }
  } as EpilotTypography,
  palette: {
    primary: {
      main: '#039BE5',
      contrastText: '#FFFFFF',
      '900': '#152B42',
      // secondary is the shade 800
      '800': '#1B3855',
      '700': '#28527D',
      '600': '#0F5B99',
      '500': '#039BE5',
      '400': '#65C8F7',
      '300': '#ADDFF7',
      '200': '#D9F1FC',
      '100': '#EDF9FF',
      '50': '#F7FBFD'
    },
    secondary: {
      main: '#FF6A3F',
      contrastText: '#FFFFFF',
      '600': '#F44719',
      '500': '#FF6A3F',
      '300': '#FF8461',
      '200': '#FFA78E',
      '100': '#FFCABA',
      '50': '#FBE8E6'
    },
    success: {
      main: '#13BB89',
      contrastText: '#FFFFFF',
      '500': '#13BB89',
      '200': '#7AD3B2',
      '100': '#B1E4D0',
      '50': '#DFF4EC'
    },
    info: {
      main: '#26BCFC',
      contrastText: '#172B4D',
      '500': '#26BCFC',
      '200': '#70CFFF',
      '100': '#ACE2FF',
      '50': '#DEF4FF'
    },
    warning: {
      main: '#FFBF44',
      contrastText: '#172B4D',
      '500': '#FFBF44',
      '200': '#FFD17C',
      '100': '#FFE3B0',
      '50': '#FFF4DF'
    },
    error: {
      main: '#FF3A3F',
      contrastText: '#FFFFFF',
      '500': '#FF3A3F',
      '300': '#F46668',
      '200': '#FB9393',
      '100': '#FFC9CF',
      '50': '#FFEBEC'
    },
    focus: {
      main: '#5B4CEC',
      dark: '#5B4CEC',
      contrastText: '#FFFFFF',

      '500': '#5B4CEC',
      '300': '#806CEF',
      '200': '#A797F2',
      '100': '#CBC0F7'
    },
    accent1: {
      main: '#FF6A3F',
      contrastText: '#FFFFFF',
      '600': '#F44719',
      '500': '#FF6A3F',
      '300': '#FF8461',
      '200': '#FFA78E',
      '100': '#FFCABA',
      '50': '#FBE8E6'
    },
    accent2: {
      main: '#99E0EF',
      contrastText: '#172B4D',
      '500': '#59C3DC',
      '400': '#62CBE2',
      '300': '#77D4E7',
      '200': '#99E0EF',
      '100': '#BEECF6',
      '50': '#E5F7FB'
    },
    tertiary: {
      main: '#99E0EF',
      contrastText: '#172B4D',
      '500': '#59C3DC',
      '400': '#62CBE2',
      '300': '#77D4E7',
      '200': '#99E0EF',
      '100': '#BEECF6',
      '50': '#E5F7FB'
    },
    grey: {
      '10': '#FCFEFF',
      '20': '#F5F7FA',
      '30': '#EDF2F7',
      '40': '#E6EEF7',
      '50': '#DEEAF7',
      '60': '#D5E1ED',
      '70': '#C5D0DB',
      '80': '#AFBCC9',
      '90': '#9DADBD',
      '100': '#8C9DAD',
      '200': '#7E8D9C',
      '300': '#6F7C8A',
      '400': '#596775',
      '500': '#505E6B',
      '600': '#44525E',
      '700': '#35434F',
      '800': '#27333D',
      '900': '#263847',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    action: {
      active: '#324C66',
      hover: '#EFF5FA',
      selected: '#324C66',
      selectedText: '#ffffff',
      disabled: '#E6EEF7',
      focus: '#324C66'
    },
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#172B4D',
      secondary: '#8C9EB0'
    },
    divider: '#DEEAF7',
    green: {
      main: '#2FBC8A',
      contrastText: '#FFFFFF'
    },
    darkgrey: {
      main: '#1B3855',
      contrastText: '#FFFFFF'
    },
    bostonblue: {
      main: '#349BBC',
      contrastText: '#FFFFFF'
    },
    darkgreen: {
      main: '#165D37',
      contrastText: '#FFFFFF'
    },
    purple: {
      main: '#9A79F8',
      contrastText: '#FFFFFF'
    },
    pink: {
      main: '#F09DDE',
      contrastText: '#FFFFFF'
    },
    lightblue: {
      main: '#6299F8',
      contrastText: '#FFFFFF'
    },
    blue: {
      main: '#0A69F7',
      contrastText: '#FFFFFF'
    },
    lavender: {
      main: '#CDD6F8',
      contrastText: '#172B4D'
    },
    red: {
      main: '#F9747C',
      contrastText: '#172B4D'
    },
    cyan: {
      main: '#B6EFF3',
      contrastText: '#172B4D'
    },
    orange: {
      main: '#FFC65C',
      contrastText: '#172B4D'
    }
  } as EpilotPalette,
  spacing: 8,
  shape: {
    borderRadius: 4
  },
  props: {
    enable_ripple: false,
    content_max_width: 1128
  },
  shadows: [
    // 0
    'none',

    // 1
    '0px 1px 4px rgba(21, 43, 66, 0.16)',

    // 2
    '0px 0px 8px rgba(21, 43, 66, 0.24)',

    // 3
    '0px 0px 24px rgba(21, 43, 66, 0.12)',

    // 4
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',

    // 5
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',

    // 6
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',

    // 7
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',

    // Elevation8 affects Popover default boxShadow
    // 8
    '0px 0px 8px rgba(21, 43, 66, 0.24)',

    // 9
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',

    // 10
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',

    // 11
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',

    // 12
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',

    // 13
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',

    // 14
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',

    // 15
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',

    // 16
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',

    // 17
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',

    // 18
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',

    // 19
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',

    // 20
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',

    // 21
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',

    // 22
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',

    // 23
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',

    // 24
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
  ]
}
