import DateFnsUtils from '@date-io/date-fns'
import {
  DateTimePicker as MUIDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import type { DateTimePickerProps } from '..'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  makeStyles,
  createStyles
} from '..'

const useClasses = makeStyles(() =>
  createStyles({ root: { lineHeight: '1.66' } })
)

export function DateTimePicker(props: DateTimePickerProps) {
  const {
    disabled,
    error,
    fullWidth,
    helperText,
    id = 'no-id',
    label,
    required,
    containerStyle,
    value,
    ampm = false,
    size = 'medium',
    format = 'dd.MM.yyyy HH:mm',
    onChange,
    shouldDisableDate,
    customBackgroundColor,
    ...rest
  } = props

  const htmlId = id ? id.replace(/^#\/properties\//, '') : undefined
  const classes = useClasses()

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      style={containerStyle}
    >
      {(label || required) && (
        <InputLabel
          classes={{ root: classes.root }}
          htmlFor={`field-${id}`}
          shrink
        >
          {label}
          {required ? ' *' : ''}
        </InputLabel>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MUIDateTimePicker
          TextFieldComponent={(props) => (
            <TextField
              customBackgroundColor={customBackgroundColor}
              disabled={disabled}
              error={!!error}
              fullWidth={fullWidth}
              id={htmlId}
              label={label || undefined}
              onChange={props.onChange}
              onClick={props.onClick}
              required={required}
              size={size}
              value={props.value}
            />
          )}
          {...rest}
          ampm={ampm}
          animateYearScrolling
          error={error}
          format={format}
          fullWidth={fullWidth}
          id={htmlId && `field-${htmlId}`}
          maxDate={undefined}
          minDate={undefined}
          name={htmlId}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate || undefined}
          size={size}
          value={value}
        />
      </MuiPickersUtilsProvider>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
