import { Stepper as MuiStepper } from '@material-ui/core'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import type { StepperProps } from './types'

export { Step, StepLabel, StepContent, StepConnector } from '@material-ui/core'

export const Stepper = forwardRef<
  ElementRef<typeof MuiStepper>,
  PropsWithoutRef<StepperProps>
>(({ alignment = 'left', orientation = 'horizontal', ...props }, ref) => (
  <MuiStepper
    alternativeLabel={orientation === 'horizontal' && alignment === 'center'}
    orientation={orientation}
    ref={ref}
    {...props}
  />
))

Stepper.displayName = 'Stepper'
