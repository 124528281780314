import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductDescription } from '../TileBody/ProductDescription'

import classes from './TileSections.module.scss'

export const TileDescription = () => {
  return (
    <TileLayoutContainer
      className={classes.descriptionContainer}
      tileLayoutSection="description"
    >
      <ProductDescription />
    </TileLayoutContainer>
  )
}
