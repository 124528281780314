import classNames from 'classnames'
import { forwardRef } from 'react'
import type { PropsWithoutRef } from 'react'

import { Button } from '../Button'
import { Icon } from '../Icon'

import classes from './IconButton.module.scss'
import type { IconButtonProps } from './types'

export const IconButton = forwardRef<
  HTMLButtonElement,
  PropsWithoutRef<IconButtonProps>
>((props, ref) => {
  const { name, className, color, hoverColor, size, variant, ...rest } = props

  return (
    <Button
      className={classNames('Concorde-IconButton', classes.root, className)}
      color={color}
      ref={ref}
      {...rest}
      label={
        <Icon
          color={color}
          hoverColor={hoverColor}
          name={name}
          size={size}
          variant={variant}
        />
      }
    />
  )
})

IconButton.displayName = 'IconButton'
