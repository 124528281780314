import type {
  GeneralTileConfiguration,
  ProductTileDataConfig,
  SelectionType,
  TileControlConfiguration
} from '../types'

type CatalogProductTileConfig = ProductTileDataConfig & {
  generalTileConfiguration?: GeneralTileConfiguration
  tileControlConfiguration?: TileControlConfiguration
  selectionType?: SelectionType
}

export const withDefaultConfigurationValues = (
  config: CatalogProductTileConfig
) => {
  return {
    ...config,
    isSelected: config.isSelected ?? false,
    isFeatured: config.isFeatured ?? false,
    featuredLabel: config.featuredLabel ?? '',
    featuredColor: config.featuredColor ?? '',
    featuredLabelColor: config.featuredLabelColor ?? '',
    selectionType: config.selectionType ?? 'single',
    generalTileConfiguration: {
      ...config.generalTileConfiguration,
      highlightSelected:
        config.generalTileConfiguration?.highlightSelected ?? false,
      ctaConfig: {
        label:
          config.generalTileConfiguration?.ctaConfig?.label ??
          'cta_add_to_cart',
        undoActionLabel:
          config.generalTileConfiguration?.ctaConfig?.undoActionLabel ??
          'cta_add_to_cart_undo',
        disabled: config.generalTileConfiguration?.ctaConfig?.disabled ?? false
      },
      quantityConfig: {
        show: config.generalTileConfiguration?.quantityConfig?.show ?? false,
        minValue:
          config.generalTileConfiguration?.quantityConfig?.minValue ?? 0,
        maxValue: config.generalTileConfiguration?.quantityConfig?.maxValue
      },
      showFeaturesIcon:
        config.generalTileConfiguration?.showFeaturesIcon ?? true,
      showImages: config.generalTileConfiguration?.showImages ?? true,
      displayPriceComponents:
        config.generalTileConfiguration?.displayPriceComponents ?? true,
      displayUnitaryAverage:
        config.generalTileConfiguration?.displayUnitaryAverage ?? true,
      displayRecurringTotal:
        config.generalTileConfiguration?.displayRecurringTotal ?? false,
      recurringTotalUnitOption:
        config.generalTileConfiguration?.recurringTotalUnitOption ?? 'yearly',
      displayProductDescription:
        config.generalTileConfiguration?.displayProductDescription ?? true,
      showTrailingDecimalZeros:
        config.generalTileConfiguration?.showTrailingDecimalZeros ?? false
    },
    tileControlConfiguration: {
      ...config.tileControlConfiguration
    },
    content: {
      ...config.content,
      priceComponents: {
        ...config.content?.priceComponents,
        title: config.content?.priceComponents?.title ?? 'PriceComponents'
      },
      details: {
        ...config.content?.details,
        title: config.content?.details?.title ?? 'Details'
      },
      downloads: {
        ...config.content?.downloads,
        title: config.content?.downloads?.title ?? 'Downloads'
      }
    }
  }
}
