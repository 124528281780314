const UNIT_AMOUNT = 100

/**
 * Determines whether the an amount should be shown with full precision or not.
 *
 * @param value - The number to check.
 * @returns True if the decimal part should be shown, false otherwise.
 */
export const shouldShowDecimals = (value?: number) => {
  return typeof value === 'number' && value < UNIT_AMOUNT
}
