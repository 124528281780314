import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core'

import type {
  DialogProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogTitleProps
} from './types'

export function Dialog(props: DialogProps) {
  const { children, ...rest } = props

  return <MuiDialog {...rest}>{children}</MuiDialog>
}

export function DialogActions(props: DialogActionsProps) {
  const { children, ...rest } = props

  return <MuiDialogActions {...rest}>{children}</MuiDialogActions>
}

export function DialogContent(props: DialogContentProps) {
  const { children, ...rest } = props

  return <MuiDialogContent {...rest}>{children}</MuiDialogContent>
}

export function DialogContentText(props: DialogContentTextProps) {
  const { children, ...rest } = props

  return <MuiDialogContentText {...rest}>{children}</MuiDialogContentText>
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, ...rest } = props

  return <MuiDialogTitle {...rest}>{children}</MuiDialogTitle>
}
