import type { InjectFilterLogic } from '../inject'
import type { BlockLocatorDetails, Step } from '../utils'
import {
  logicActionTypes,
  logicOperators,
  LogicTriggerEventName,
  CROSS_SELL_FILTER_SELECTION,
  blockController,
  CONTROL_NAME
} from '../utils'

import { getProductSelectionBlocksInStep } from './index'

const getTargetCrossSellProductSelectionBlocks = (
  crossSellProductSelectionBlocks: Array<BlockLocatorDetails>,
  stepIndex: number
) => {
  return crossSellProductSelectionBlocks?.filter((block: BlockLocatorDetails) =>
    block?.uischema?.options?.selectedBlocks?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (selectedBlock: any) => selectedBlock.step.stepIndex === stepIndex
    )
  )
}

const getCrossSellLogic = (
  targetBlock: BlockLocatorDetails,
  sourceBlock: BlockLocatorDetails
): InjectFilterLogic => {
  const {
    stepName: targetStepName,
    name: targetBlockName,
    stepIndex: targetStepIndex,
    stepId: targetStepId
  } = targetBlock

  return {
    actions: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target: `##${targetStepName}/${targetBlockName}##` as `##${string}##`
      }
    ],
    actionsIncludesFilter: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target: `##${targetStepName}/${targetBlockName}##` as `##${string}##`,
        targetBlockName: `${targetBlockName}`,
        targetStepIndex,
        targetStepId: `${targetStepId}`
      }
    ],
    blockName: sourceBlock.name,
    stepId: sourceBlock.stepId,
    conditions: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    conditionsRelatedToStep: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    injectedFilterValueName: CROSS_SELL_FILTER_SELECTION
  }
}

export const getCrossSellLogicsForStep = (
  steps: Array<Step>,
  index: number
) => {
  const result: (InjectFilterLogic | [])[] = []

  const productSelectionBlocks = blockController.findBlocks(steps, {
    type: CONTROL_NAME.PRODUCT_SELECTION_CONTROL
  })

  const productSelectionBlocksInStep = getProductSelectionBlocksInStep(
    productSelectionBlocks,
    index
  )
  const targetCrossSellProductSelectionBlocks =
    getTargetCrossSellProductSelectionBlocks(productSelectionBlocks, index)

  if (!targetCrossSellProductSelectionBlocks.length) {
    result.push([])

    return result
  }

  if (productSelectionBlocksInStep) {
    productSelectionBlocksInStep.forEach(
      (productSelectionBlockInStep: BlockLocatorDetails) => {
        targetCrossSellProductSelectionBlocks.forEach(
          (targetCrossSellProductSelectionBlock) => {
            const logic = getCrossSellLogic(
              targetCrossSellProductSelectionBlock,
              productSelectionBlockInStep
            )

            result.push(logic)
          }
        )
      }
    )

    return result
  }

  return result
}
