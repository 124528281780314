import { AVAILABILITY_CHECK_VALUES_PROPERTY } from '@epilot/journey-logic-commons'
import type { AvailabilityCheckControlData } from '@epilot/journey-logic-commons'

import type { AddressControlData, AddressFormProps } from './types'

type AvailabilityCheckData = {
  [blockIdentifier: string]: AvailabilityCheckControlData
}

export type WithDataInjectionProps = {
  injectedData?: Partial<AddressControlData>
  [AVAILABILITY_CHECK_VALUES_PROPERTY]?: AvailabilityCheckData
}

/**
 * HOC passing injectedData (if any) to inner Address Form.
 */
export const withDataInjection = (
  Component: React.ComponentType<AddressFormProps & WithDataInjectionProps>
): React.FC<AddressFormProps & WithDataInjectionProps> => {
  const WrappedComponent = (
    props: AddressFormProps & WithDataInjectionProps
  ) => {
    if (
      !props.isInjectAddressEnabled ||
      !props.injectAddressSettings?.relatedBlock ||
      !props[AVAILABILITY_CHECK_VALUES_PROPERTY] ||
      !props[AVAILABILITY_CHECK_VALUES_PROPERTY]?.[
        props.injectAddressSettings?.relatedBlock
      ]
    ) {
      return <Component {...props} injectedData={undefined} />
    }

    const addressData = getAddressData(
      props.data,
      props.countryAddressSettings,
      props.fields,
      props[AVAILABILITY_CHECK_VALUES_PROPERTY]?.[
        props.injectAddressSettings?.relatedBlock
      ]
    )

    return (
      <Component
        {...props}
        countryAddressSettings={addressData.countryAddressSettings}
        data={addressData.data}
        injectedData={addressData.injectedData}
      />
    )
  }

  WrappedComponent.displayName = 'WithDataInjection'

  return WrappedComponent
}

const getAddressData = (
  data: AddressControlData,
  countryAddressSettings: AddressFormProps['countryAddressSettings'],
  fields: AddressFormProps['fields'],
  injectedAvailabilityData: AvailabilityCheckControlData
): {
  injectedData?: Partial<AddressControlData>
  data: AddressControlData
  countryAddressSettings: AddressFormProps['countryAddressSettings']
} => {
  if (!injectedAvailabilityData) {
    return {
      data,
      injectedData: undefined,
      countryAddressSettings
    }
  }

  // map the injected data to the address data
  const injectedAddressData: Partial<AddressControlData> = {
    city: injectedAvailabilityData.city,
    zipCode: injectedAvailabilityData.zipCode,
    streetName: injectedAvailabilityData.streetName,
    houseNumber: injectedAvailabilityData.streetNumber,
    countryCode: injectedAvailabilityData.countryCode || 'DE',
    coordinates: injectedAvailabilityData.coordinates
  }

  countryAddressSettings = {
    ...(countryAddressSettings || {}),
    // use the country code from the availability check
    countryCode: injectedAddressData?.countryCode,
    // enable autocomplete for injected address only if the country code is DE
    enableAutoComplete: injectedAddressData?.countryCode === 'DE'
  }

  // this will not be needed when we implement this generically for the address component
  const postalCodeUpdated =
    data?.zipCode &&
    (injectedAddressData.zipCode || injectedAddressData.zipCode === '') &&
    data?.zipCode !== injectedAddressData.zipCode

  return {
    injectedData: injectedAddressData,
    data: {
      ...(data || {}),
      ...(isFieldVisible('zipCity', fields) && {
        city:
          postalCodeUpdated && !injectedAddressData.city
            ? undefined
            : injectedAddressData.city
              ? injectedAddressData.city
              : data?.city,
        zipCode:
          postalCodeUpdated && !injectedAddressData.zipCode
            ? undefined
            : injectedAddressData.zipCode
              ? injectedAddressData.zipCode
              : data?.zipCode
      }),
      ...(isFieldVisible('streetName', fields) && {
        streetName:
          postalCodeUpdated && !injectedAddressData.streetName
            ? undefined
            : injectedAddressData.streetName
              ? injectedAddressData.streetName
              : data?.streetName
      }),
      ...(isFieldVisible('houseNumber', fields) && {
        houseNumber:
          postalCodeUpdated && !injectedAddressData.houseNumber
            ? undefined
            : injectedAddressData.houseNumber
              ? injectedAddressData.houseNumber
              : data?.houseNumber
      }),
      coordinates: injectedAddressData?.coordinates ?? data?.coordinates,
      countryCode: countryAddressSettings?.countryCode || 'DE'
    },
    countryAddressSettings
  }
}

/**
 * Checks if the field is visible in the address block configuration
 * @param fieldKey
 * @param fields
 * @returns
 **/
const isFieldVisible = (
  fieldKey: string,
  fields: AddressFormProps['fields']
) => {
  return fields && fieldKey in fields
}
