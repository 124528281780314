import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles({
  checkboxNoLabelContainer: {
    textAlign: 'right',
    marginBottom: '-20px',
    marginRight: '-4px'
  },
  column: {
    flexDirection: 'column',
    margin: -1.5
  }
})
