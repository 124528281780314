import {
  BodyText,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  H2,
  H6,
  IconButton,
  SmallText,
  useTheme,
  clsx
} from '@epilot/journey-elements'
import {
  areProductDownloadsHydrated,
  areProductImagesHydrated
} from '@epilot/journey-logic-commons'
import type { Price } from '@epilot/journey-logic-commons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Close from '@material-ui/icons/Close'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ProductAction,
  ProductQuantityStepper,
  ProductImageRenderer
} from '../../../../renderers/controls/ProductSelectionControl/components/htmlComponents'
import { useTileContext } from '../../../../renderers/controls/ProductSelectionControl/utils/tile-context-utils'
import { extractPaymentConditionItems } from '../../utils/product-details-modal-utils'

import { MarkdownRenderer } from './MarkdownRenderer'
import { ProductDetailsDownloads } from './ProductDetailsDownloads'
import { ProductDetailsFeatures } from './ProductDetailsFeatures'
import { ProductDetailsPaymentConditions } from './ProductDetailsPaymentConditions'
import { ProductDetailsPriceInformation } from './ProductDetailsPriceInformation'
import { useProductDetailsModalStyles } from './styles'

type ProductDetailsModalProps = {
  onClose: () => void
  isOpen: boolean
}

export const ProductDetailsModal = ({
  onClose,
  isOpen
}: ProductDetailsModalProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useProductDetailsModalStyles()
  const { product, price } = useTileContext()

  const downloads = useMemo(
    () =>
      areProductDownloadsHydrated(product)
        ? product.product_downloads.filter(
            (download) => download.access_control !== 'private'
          )
        : [],
    [product]
  )

  const { name, description } = product

  /**
   * @todo Remove type-casting once the API spec
   * includes the additional_information field
   */
  const { additional_information: additionalInformation } =
    price as typeof price & {
      additionalInformation?: string
    }

  const conditions = useMemo(
    () =>
      extractPaymentConditionItems([
        price,
        ...((price.price_components as Price[]) ?? [])
      ]),
    [price]
  )

  const shouldDisplayImageGallery = useMemo(
    () =>
      areProductImagesHydrated(product) &&
      product.product_images.some(
        (image) => image.access_control !== 'private'
      ),
    [product]
  )

  return (
    <Dialog
      aria-describedby="product-details-dialog-description"
      aria-labelledby="product-details-dialog-title"
      classes={{ paper: classes.paper }}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={
        conditions.length || additionalInformation || downloads.length
          ? 'lg'
          : 'md'
      }
      onClose={onClose}
      open={isOpen}
      scroll="paper"
    >
      <Box padding="16px" position="relative" width="full">
        <Box position="absolute" right={8} top={8}>
          <IconButton aria-label="close" onClick={onClose}>
            <Close className={classes.icon} fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent className={classes.dialogContainer} dividers={false}>
        <div className={classes.container}>
          {shouldDisplayImageGallery && (
            <div className={classes.imageContainer}>
              <ProductImageRenderer />
            </div>
          )}
          <div className={classes.descriptionContainer}>
            {name && <H2 className={classes.heading}>{name}</H2>}
            {description && (
              <DialogContentText
                id="product-details-dialog-description"
                tabIndex={-1}
              >
                <BodyText>{description}</BodyText>
              </DialogContentText>
            )}
            <ProductDetailsFeatures />
          </div>
        </div>

        <div className={classes.detailsContainer}>
          <div
            className={clsx(
              classes.detailsContent,
              !(conditions.length || additionalInformation) &&
                classes.detailsContentNoInformation
            )}
          >
            <div>
              <H6 className={classes.heading} id="price-information-heading">
                {t(
                  'product_details.headings.price_information',
                  'Price Information'
                )}
              </H6>
              <ProductDetailsPriceInformation />
            </div>
            {!!downloads.length && (
              <div>
                <H6 className={classes.heading}>
                  {t('product_details.headings.downloads', 'Downloads')}
                </H6>
                <ProductDetailsDownloads downloads={downloads} />
              </div>
            )}
          </div>
          {!!(conditions.length || additionalInformation) && (
            <div>
              <H6 className={classes.heading}>
                {t(
                  'product_details.headings.conditions_and_information',
                  'Additional Information'
                )}
              </H6>
              {!!additionalInformation && (
                <div className={classes.paragraphContainer}>
                  <BodyText>
                    <MarkdownRenderer>{additionalInformation}</MarkdownRenderer>
                  </BodyText>
                </div>
              )}
              {!!conditions.length && (
                <ProductDetailsPaymentConditions conditions={conditions} />
              )}
            </div>
          )}
        </div>

        <div className={classes.container}>
          {product.legal_footnote && (
            <SmallText>
              <MarkdownRenderer>{product.legal_footnote}</MarkdownRenderer>
            </SmallText>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsContainer}>
          <ProductQuantityStepper />
          <ProductAction onSelect={onClose} />
        </div>
      </DialogActions>
    </Dialog>
  )
}
