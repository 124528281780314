import { format as formatDate, add, isDate } from 'date-fns'
import type { TFunction } from 'i18next'
import type { DateSchema as YupDateSchema } from 'yup'
import { date, object, ref } from 'yup'

import { addDate } from '../../utils'

type DateSchema = YupDateSchema<Date | null | undefined>

const commonSchema = (prefix: string, t: TFunction, format: string) =>
  date()
    .nullable()
    .when(`$required`, (required: boolean | undefined, schema: DateSchema) => {
      return required ? schema.required(t('field_required')) : schema
    })
    .when(
      `$${prefix}.limits.disablePast`,
      (disablePast: boolean | undefined, schema: DateSchema) => {
        if (disablePast) {
          const message = `${t(
            'The date you entered is not available. Please enter a date after the'
          )} ${formatDate(addDate(), format)}`

          return schema.min(addDate(), message)
        }

        return schema
      }
    )
    .when(
      `$${prefix}.limits.minByDays`,
      (minByDays: number | undefined, schema: DateSchema) => {
        if (typeof minByDays === 'number') {
          const message = `${t(
            'The date you entered is not available. Please enter a date after the'
          )} ${formatDate(add(addDate(), { days: minByDays }), format)}`

          return schema.min(add(addDate(), { days: minByDays }), message)
        }

        return schema
      }
    )
    .when(
      `$${prefix}.limits.maxByDays`,
      (maxByDays: number | undefined, schema: DateSchema) => {
        if (typeof maxByDays === 'number') {
          const message = `${t(
            'The date you entered is not available. Please enter a date before the'
          )} ${formatDate(add(addDate(), { days: maxByDays + 1 }), format)}`

          const maxDate = add(addDate(), { days: maxByDays + 1 })

          return schema.max(maxDate, message)
        }

        return schema
      }
    )
    .when(
      '$disableDays',
      (disableDays: number[] | undefined, schema: DateSchema) => {
        if (disableDays) {
          return schema.test(
            'is-disabled-day',
            () => {
              const availableDays = [0, 1, 2, 3, 4, 5, 6]
                .filter((day) => !disableDays.includes(day))
                .map((day) => t(`day.${day}`))
                .join(', ')

              const message = `${t(
                'Please choose one of the following days of the week'
              )} ${availableDays}`

              return message
            },
            (value: Date | null | undefined) => {
              if (!value) return true
              const day = value.getDay()

              return !disableDays.includes(day)
            }
          )
        }

        return schema
      }
    )
    .typeError(
      `${t(
        'The date you entered is incomplete. Please enter a complete date in the format'
      )} ${format}`
    )

type CreateSchemaFor = { endDate?: boolean }
export const createDateSchema = (
  t: TFunction,
  format: string,
  createSchemaFor?: CreateSchemaFor
) => {
  return object({
    startDate: commonSchema('startDate', t, format),
    ...(createSchemaFor &&
      createSchemaFor.endDate && {
        endDate: commonSchema('endDate', t, format).min(
          ref('startDate'),
          ({ min }) => {
            if (!isDate(min)) {
              return `${t(
                'The date you entered is incomplete. Please enter a complete date in the format'
              )}`
            }

            return `${t(
              'The date you entered is not available. Please enter a date after the'
            )} ${formatDate(
              add(typeof min === 'string' ? addDate() : min, { days: -1 }),
              format
            )}`
          }
        )
      })
  })
}
