import type { EmbedOptions } from '@epilot/journey-logic-commons'

import { extractUUIDsFromParams } from './extractUUIDsFromParams'

const parseBoolean = (value: string) =>
  value === 'true' ? true : value === 'false' ? false : undefined

/**
 * getJourneyParams is not reactive, recomputes all params every time it is called
 * @todo Should be refactored to take advantage of react hooks, to ensure
 * params are only re-computed when the location changes
 */
const getJourneyParams = () => {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)

  const paramsMapRaw = Object.fromEntries(params.entries())

  const paramsMap = extractUUIDsFromParams(paramsMapRaw) as Record<
    string,
    string
  >

  const { debug, journeyId, preview, topBar, mode, lang, isEmbedded } =
    paramsMap

  return {
    id: journeyId,
    debug: debug ? parseBoolean(debug) : false,
    preview: (preview && parseBoolean(preview)) || false,
    topBar: topBar ? parseBoolean(topBar) : undefined,
    mode: (mode === 'full-screen' || mode === 'inline' ? mode : undefined) as
      | EmbedOptions['mode']
      | undefined,
    // pass contextData if in preview mode for testing purposes
    contextData: paramsMap,
    isEmbedded: isEmbedded === 'true',
    journeyLanguage: lang || 'de'
  }
}

export default getJourneyParams
