import type { Step } from '../utils'
import { blockController } from '../utils'

import type { NavigateFromStepLogic } from './getLogicsNavigateFromStep'
import type { DefaultStepMap, StepId, StepIndex, StepMap } from './types'

const computePossibleNextStepsForStep = ({
  currentStep,
  nextStep,
  currentStepLogic: stepLogic,
  stepIndex
}: {
  currentStep: Step
  /* There might be no next step */
  nextStep?: Step
  /* There might not be logic for the current steº */
  currentStepLogic: NavigateFromStepLogic[]
  stepIndex: number
}): StepId[] => {
  const actionButtons = blockController.findBlocks(
    { ...currentStep, stepIndex },
    { type: 'ActionBarControl' }
  )

  const possibleNextSteps: StepId[] = []

  stepLogic?.forEach((currentLogics) => {
    currentLogics.actionsIncludesNav.forEach(({ targetStepId }) => {
      if (targetStepId && !possibleNextSteps.includes(targetStepId)) {
        possibleNextSteps.push(targetStepId)
      }
    })
  })

  actionButtons.forEach((actionButton) => {
    const targetStepId =
      actionButton.uischema?.options?.ctaButton.targetStepId ?? nextStep?.stepId

    if (targetStepId && !possibleNextSteps.includes(targetStepId)) {
      possibleNextSteps.push(targetStepId)
    }
  })

  return possibleNextSteps
}

/**
 * Computes a map of possible next steps for each step
 * @example // { 'step1': ['step2', 'step3'], 'step2': ['step3'], 'step3': [] }
 * @example // { 'step1': ['step2', 'step3'], 'step2': ['step3'], 'step3': [] }
 */
export const computePossibleNextStepMap = (
  steps: Step[],
  logics: NavigateFromStepLogic[][]
): StepMap =>
  steps.reduce<StepMap>(
    (possibleNextStepMap, { stepId }, index) => ({
      ...possibleNextStepMap,
      [stepId]: computePossibleNextStepsForStep({
        currentStep: steps[index],
        nextStep: steps[index + 1],
        currentStepLogic: logics[index],
        stepIndex: index
      })
    }),
    {}
  )

/**
 * Computes a map of possible default next steps.
 */
export const computeDefaultNextStepMap = (steps: Step[]): DefaultStepMap => {
  return steps.reduce<DefaultStepMap>(
    (possibleNextStepMap, { stepId }, index) => ({
      ...possibleNextStepMap,
      [index]: computePossibleDefaultNextStepForStep({
        steps,
        stepIndex: index,
        stepId: stepId
      })
    }),
    {}
  )
}

/**
 * Computes the possible default next step.
 */
export const computePossibleDefaultNextStepForStep = ({
  steps,
  stepIndex
}: {
  steps: Step[]
  stepIndex: number
  stepId: StepId
}): StepIndex | undefined => {
  const currentStep = steps[stepIndex]

  const actionButton = blockController.findBlock(
    { ...currentStep, stepIndex },
    { type: 'ActionBarControl' }
  )

  if (actionButton) {
    const targetStepId = actionButton.uischema?.options?.ctaButton.targetStepId

    const nextTargetStepIndex = targetStepId
      ? steps.findIndex((step) => step.stepId === targetStepId)
      : stepIndex + 1

    if (nextTargetStepIndex < steps.length) {
      return nextTargetStepIndex
    }
  }

  return undefined
}
