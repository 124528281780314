import classNames from 'classnames'
import type { SyntheticEvent } from 'react'
import { useEffect, useState, forwardRef } from 'react'

import classes from './Image.module.scss'
import type { ImageProps } from './types'

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  const {
    alt,
    src,
    className,
    containerProps,
    onLoad,
    onError,
    fallbackSrc,
    loadingPlaceholder,
    isDefaultLoaded = true,
    errorText,
    ...rest
  } = props

  const [isLoaded, setIsLoaded] = useState(isDefaultLoaded)
  const [hasError, setHasError] = useState(false)

  const handleLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    if (!isLoaded) setIsLoaded(true)
    if (onLoad) {
      onLoad(event)
    }
  }

  const handleError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setHasError(true)
    if (onError) {
      onError(event)
    }
  }

  useEffect(() => {
    // Reset when src changes
    setHasError(false)
  }, [src])

  return (
    <div
      {...containerProps}
      className={classNames(
        'Concorde-Image',
        classes.root,
        containerProps?.className
      )}
    >
      {!isLoaded && !hasError && (
        <>
          {loadingPlaceholder || (
            <div
              className={classNames(
                'Concorde-Image__Placeholder',
                classes['image-placeholder']
              )}
            />
          )}
        </>
      )}
      {hasError ? (
        <div
          className={classNames(
            'Concorde-Image__Error',
            classes['image-error']
          )}
        >
          <p>{errorText || 'Image failed to load'}</p>
        </div>
      ) : (
        <img
          {...rest}
          alt={alt}
          className={classNames(
            'Concorde-Image__Image',
            classes['image'],
            isLoaded && classes['image-loaded'],
            className
          )}
          onError={handleError}
          onLoad={handleLoad}
          ref={ref}
          src={src || fallbackSrc}
        />
      )}
    </div>
  )
})

Image.displayName = 'Image'
