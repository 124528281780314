import { Hidden, EnumControl } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStepBlockId } from '../../utils'
import { getEnumOptions } from '../../utils/helper'
import { includeCommon } from '../../utils/includeCommon'

export type EnumAutocompleteControlOptions = {
  optionsLabels?: string[]
  clearPathOnChange?: string[]
}
export function EnumAutocompleteControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  required,
  enabled,
  schema,
  visible,
  id
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path, id)
  const label = uischema?.options?.label || uischema?.label
  const { t } = useTranslation()
  const { errorMessage, examples, default: defaultSelect } = schema
  const options = getEnumOptions(schema, uischema)
  const { optionsLabels, clearPathOnChange = [] } =
    (uischema.options as EnumAutocompleteControlOptions) || {}
  const placeholder = Array.isArray(examples) && examples[0] ? examples[0] : ''

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
        setSelected(undefined)
      })
    }
    handleChange(path, value)
    setSelected(value)
  }

  const [selected, setSelected] = useState(data)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      // skip the effect on initial mount to prevent setting the value to schema.default when the journey goes back
      isInitialMount.current = false
    } else {
      setSelected(defaultSelect)
    }
  }, [defaultSelect])

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        defaultSelection={''}
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : t('Please select one of the options')
            : ''
        }
        id={stepBlockId}
        label={label ? `${label}${required ? '*' : ''}` : ''}
        noOptionsText={t('no_options', 'Keine Optionen gefunden')}
        onChange={handleOnChange}
        options={options}
        optionsLabels={optionsLabels?.map((label) =>
          label === undefined || label === null ? '' : label
        )}
        placeholder={placeholder}
        required={required}
        uiType="autocompelete"
        value={selected || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: EnumAutocompleteControlPure
  }) as never
)
