import type { Theme } from '@epilot/journey-elements'
import {
  Grid,
  Typography,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import type {
  Price,
  BillingPeriod,
  PriceItem
} from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import { CartItemDetailsLineDiscount } from './CartItemDetailsLineDiscount'
import { ComponentMappingData } from './ComponentMappingData/ComponentMappingData'

export type CartItemDetailsLineContentProps = {
  displayUnitaryAverage?: boolean
  item: PriceItem
  showTrailingDecimalZeros?: boolean
  taxIndexes?: number[]
  t: TFunction
  shouldHideRecurrence?: boolean
}

export const CartItemDetailsLineContent = ({
  displayUnitaryAverage,
  item,
  showTrailingDecimalZeros,
  taxIndexes,
  t,
  shouldHideRecurrence
}: CartItemDetailsLineContentProps) => {
  const classes = useCartItemLineContentStyles()

  const type = getPriceType(
    item._price?.type as Price['type'],
    item._price?.billing_period as BillingPeriod
  )

  const amountToDisplay = shouldShowDecimals(item.amount_total)
    ? item.amount_total_decimal
    : item.amount_total

  return (
    <Grid
      alignItems="center"
      className={classes.item}
      container
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid className={classes.detail} container item wrap="wrap">
        <Grid item>
          <Typography color="textSecondary" variant="body2">
            <strong>{item.description}</strong>{' '}
            {!!taxIndexes?.length && (
              <sup className={classes.superscript}>{taxIndexes.join(', ')}</sup>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="textSecondary"
            data-testid="cart-item-line-mapping-data"
            noWrap
            variant="caption"
          >
            <ComponentMappingData
              component={item}
              displayUnitaryAverage={displayUnitaryAverage}
              itemQuantity={item.quantity}
              showTrailingDecimalZeros={showTrailingDecimalZeros}
              t={t}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={classes.detail}
        container
        item
        justifyContent="flex-end"
        wrap="wrap-reverse"
      >
        {!shouldHideRecurrence && (
          <Grid item>
            <Typography color="textSecondary" noWrap variant="caption">
              {t(type)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography
            align="right"
            color="textSecondary"
            data-testid="cart-item-line-price"
            noWrap
            variant="body2"
          >
            <strong>
              {getDisplayPrice(
                t,
                amountToDisplay,
                item._price?.price_display_in_journeys,
                showTrailingDecimalZeros,
                item.currency
              )}
            </strong>
          </Typography>
          {item._price?.price_display_in_journeys !== 'show_as_on_request' &&
            item.discount_amount !== undefined && (
              <CartItemDetailsLineDiscount
                item={item}
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                t={t}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useCartItemLineContentStyles = makeStyles<Theme>((theme) =>
  createStyles({
    superscript: {
      lineHeight: 0
    },
    item: {
      gap: theme.spacing(2)
    },
    detail: {
      gap: theme.spacing(1),
      flex: '1 1 auto !important',
      width: 'initial'
    }
  })
)
