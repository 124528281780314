import type { JsonSchema } from '@jsonforms/core'

import { isUiSchemaWithElements } from '../../types'
import type { Rule, Step, UiSchema } from '../../types'

import { findBlock } from './findBlock'
import type { StepExtended } from './types'

/**
 * Updates all occurences of a block name inside a journey
 * @param items the step used for the delete operation, and potential logics, rules, etc. to take into consideration
 * @param blockNameCurrent the current block name to be replaced
 * @param block the block values to update
 * @returns updated step
 */
export const updateBlock = (
  { step, rules }: { step: StepExtended; rules?: Rule[] },
  blockNameCurrent: string,
  block: {
    schema?: {
      [key: string]: JsonSchema
    }
    required?: boolean
    name: string
    uischema?: UiSchema
  },
  operation: 'update' | 'rename' = 'update'
): {
  step: Step
  rules: Rule[] | undefined
  operationStatus: 'not-found' | 'updated'
} => {
  const { stepIndex, ...stepCurrent } = step
  // rules start with 1
  const ruleIndex = stepIndex + 1

  const { uischema, schema } =
    findBlock(
      { ...stepCurrent, stepIndex: stepIndex },
      { name: blockNameCurrent },
      // due to representBy: full, the renamed block + it's parents are returned
      { replaceBy: block, representBy: 'full' },
      operation
    ) ?? {}

  // if block found
  if (schema && uischema && isUiSchemaWithElements(uischema)) {
    // only look into rules if block found
    const rulesUpdated =
      rules &&
      rules.map((rule) => {
        return {
          ...rule,
          target: rule.target.replace(
            `${ruleIndex}/${blockNameCurrent}`,
            `${ruleIndex}/${block.name}`
          ),
          source: rule.source.replace(
            `${ruleIndex}/${blockNameCurrent}`,
            `${ruleIndex}/${block.name}`
          )
        }
      })

    return {
      step: {
        ...stepCurrent,
        schema,
        /**
         * @todo Add logic to ensure we're not dealing with nested uischema
         */
        uischema: {
          ...uischema,
          elements: (uischema?.elements as never) || []
        }
      },
      rules: rulesUpdated,
      operationStatus: 'updated'
    }
  }

  return { step: stepCurrent, rules, operationStatus: 'not-found' }
}

export type UpdateBlockParameters = Parameters<typeof updateBlock>
