import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import frLocale from 'date-fns/locale/fr'

export const LOCALE_MAP = {
  en: enLocale,
  de: deLocale,
  fr: frLocale
} as const

export const LOCALES = ['en', 'de', 'fr'] as const

export function isValidLocale(
  locale: string
): locale is (typeof LOCALES)[number] {
  return LOCALES.includes(locale as (typeof LOCALES)[number])
}
