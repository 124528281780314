import { addDate } from '@epilot/journey-logic-commons'
import { add } from 'date-fns'

export const getLimitDate = (limit?: number) => {
  return typeof limit === 'number'
    ? add(addDate(), {
        days: limit
      })
    : undefined
}
