import { useEffect } from 'react'
import type {
  UseFormReset,
  UseFormResetField,
  Path,
  PathValue,
  FieldValues
} from 'react-hook-form'

type FieldsToResetData = {
  name: string
  value: string | number | undefined | null
}

type UseResetFormOnChangeParams<T> = {
  name: Path<T>
  watch: PathValue<T, Path<T>> | undefined
  fieldsToReset: FieldsToResetData[]
  isResetForbidden?: boolean
}

/**
 * Watches a given field, and resets the specified fields to their respective values if the watched field changes
 *
 * @template T The type of the form values object
 * @param { UseFormSetValue<T> | UseFormResetField<T> | UseFormReset<FieldValues> | any } resetField The resetField function from react-hook-form
 * @param {UseResetFormOnChangeParams<T>} params The parameters for the hook
 * @param {string} params.name The name of the field to watch
 * @param {UnpackNestedValue<PathValue<T, Path<T>>> | undefined} params.watch The value to watch for changes
 * @param {FieldsToResetData[]} params.fieldsToReset The list of fields to reset and their new values
 * @returns {void}
 */
export const useResetFieldsIfOneChanged = <T extends FieldValues>(
  resetField: UseFormResetField<T> | UseFormReset<FieldValues> | any,
  params: UseResetFormOnChangeParams<T>
) => {
  const { watch, fieldsToReset, isResetForbidden = false } = params

  useEffect(() => {
    if (!resetField) {
      return
    }
    if (isResetForbidden) return

    // Reset the specified fields to their new values when the watched field changes
    fieldsToReset.forEach((field) => {
      resetField(field?.name as any, {
        defaultValue: field?.value
      })
    })
  }, [watch])
}
