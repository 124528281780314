import type {
  OptionalPriceComponentBlockMappingMetadata,
  PriceInputMappings
} from '@epilot/journey-logic-commons'
import type {
  JourneyCart,
  JourneyCartItem,
  JourneyCartItemProduct
} from '@epilot/json-renderers'

const getPriceMappings = (
  product: JourneyCartItemProduct
): PriceInputMappings | undefined => {
  const selectedPrice = product.selectionMetadata?.selectedPrice

  if (selectedPrice?.is_composite_price) {
    return selectedPrice.price_components
      ?.map(
        (priceComponent) =>
          typeof priceComponent.blockMappingData.numberInput === 'number' && {
            price_id: priceComponent._id,
            value: priceComponent.blockMappingData.numberInput,
            frequency_unit: priceComponent.blockMappingData.frequencyUnit
          }
      )
      .filter(Boolean)
  }

  return typeof selectedPrice?.blockMappingData?.numberInput === 'number'
    ? [
        {
          price_id: selectedPrice?._id,
          value: selectedPrice?.blockMappingData.numberInput,
          frequency_unit: selectedPrice?.blockMappingData.frequencyUnit
        }
      ]
    : undefined
}

const getExternalPriceData = (product: JourneyCartItemProduct) => {
  const selectedPrice = product.selectionMetadata?.selectedPrice

  if (!selectedPrice || !selectedPrice.getag_price) {
    return
  }

  return {
    metadata: selectedPrice.getag_price
  }
}

const toCartItem = (
  item: JourneyCartItem,
  omittedPriceComponents?: OptionalPriceComponentBlockMappingMetadata[]
): JourneyCartItem => {
  const priceMappings = getPriceMappings(item.product)
  const externalPriceData = getExternalPriceData(item.product)

  const selectedPriceComponentIds =
    item.product.selectionMetadata?.selectedPrice?.price_components
      ?.filter(
        (priceComponent) =>
          !omittedPriceComponents?.some(
            ({ productId, priceId, priceComponentId }) =>
              productId === item.product.selectedProductId &&
              priceId === item.product.selectedPriceId &&
              priceComponentId === priceComponent._id
          )
      )
      .map((priceComponent) => priceComponent._id)

  return {
    ...item,
    ...(priceMappings && { price_mappings: priceMappings }),
    ...(externalPriceData && {
      external_fees_metadata: externalPriceData.metadata
    }),
    ...(selectedPriceComponentIds?.length && {
      selected_price_component_ids: selectedPriceComponentIds
    })
  }
}

export const toCartItems = (
  journeyCart: JourneyCart,
  omittedPriceComponents?: OptionalPriceComponentBlockMappingMetadata[]
): JourneyCartItem[] => {
  // Loop through multiple product selection steps
  return (
    Object.values(journeyCart)
      .flatMap((cart) => cart)
      // if option "select one product each" set, cart will be an array of items
      .filter(Boolean)
      .map((item) => toCartItem(item, omittedPriceComponents))
  )
}
