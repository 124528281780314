import {
  areProductDownloadsHydrated,
  priceHasComponents,
  hasTieredPricingModel
} from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { useTileContext } from '../../utils/tile-context-utils'
import { TileFooterHtml } from '../htmlComponents/TileFooter'

export const TileFooter = () => {
  const {
    price,
    product,
    config,
    tileState: { priceDisplayInJourneys }
  } = useTileContext()

  const downloads = areProductDownloadsHydrated(product)
    ? product.product_downloads.filter(
        (download) => download.access_control !== 'private'
      )
    : []

  const shouldDisplayPriceComponents = useMemo(
    () =>
      priceDisplayInJourneys !== 'show_as_on_request' &&
      config.generalTileConfiguration.displayPriceComponents &&
      priceHasComponents(price),
    [
      config.generalTileConfiguration.displayPriceComponents,
      price,
      priceDisplayInJourneys
    ]
  )

  const shouldDisplayTiersDetails = useMemo(() => {
    return (
      priceDisplayInJourneys !== 'show_as_on_request' &&
      hasTieredPricingModel(price) &&
      price.tiers?.length > 0
    )
  }, [price, priceDisplayInJourneys])
  const shouldDisplayDownloads = Boolean(downloads.length)

  return (
    <TileFooterHtml
      downloads={downloads}
      shouldDisplayDownloads={shouldDisplayDownloads}
      shouldDisplayPriceComponents={shouldDisplayPriceComponents}
      shouldDisplayTiersDetails={shouldDisplayTiersDetails}
    />
  )
}
