export enum PAGE_TYPE {
  NUMBER = 'NUMBER',
  DOTS = 'DOTS'
}

export interface Page {
  value: number
  type: PAGE_TYPE
}

interface PaginationLocalesCountTextParams {
  from: number
  to: number
  total: number
}

export interface PaginationLocales {
  pageInputLabel: string
  countText: (params: PaginationLocalesCountTextParams) => string
}

export type PaginationProps = {
  activePage: number
  onActivePageChange: (page: number) => void
  onPageHover?: (page: number) => void

  pageSizes?: number[]
  pageSize: number
  onPageSizeChange?: (pageSize: number) => void

  itemsCount: number

  showPageInput?: boolean
  showPageSizeSelector?: boolean

  locales?: Partial<PaginationLocales>
  dataTestId?: string
  id?: string
}
