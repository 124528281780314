/* eslint-disable @typescript-eslint/no-explicit-any */

import type { NestedUiSchema, UiSchema } from '../../types'
import {
  isBlockUiSchema,
  isLayoutUiSchema,
  isNestedUiSchema
} from '../../types'

import type { StepExtended } from './types'

export function findBlockUiSchemasByType(
  searchItem: UiSchema | undefined,
  type: string,
  callerBlock?: UiSchema | NestedUiSchema
): {
  items: { uischema: UiSchema; parent?: { scope?: string; type?: string } }[]
  operationStatus: 'not-found' | 'found'
}
export function findBlockUiSchemasByType(
  searchItem: NestedUiSchema | undefined,
  type: string,
  callerBlock?: UiSchema
): {
  items: {
    uischema: NestedUiSchema
    zoneIndex: number
    parent?: { scope?: string; type?: string }
  }[]
  operationStatus: 'not-found' | 'found'
}
export function findBlockUiSchemasByType(
  searchItem: StepExtended['uischema'] | undefined,
  type: string,
  callerBlock?: UiSchema | NestedUiSchema
): {
  items: {
    uischema: StepExtended['uischema']
    zoneIndex?: number
    parent?: { scope?: string; type?: string }
  }[]
  operationStatus: 'not-found' | 'found'
} {
  if (searchItem) {
    // check if block found by comparing the type, or whether its wildcard
    if (
      type === searchItem.type ||
      (isBlockUiSchema(searchItem) && type === '*')
    ) {
      const parent = { scope: callerBlock?.scope, type: callerBlock?.type }

      return {
        items: [{ uischema: searchItem, parent }],
        operationStatus: 'found'
      }
    } else {
      if (isNestedUiSchema(searchItem)) {
        // go through elements and check recursively per zone
        const { elements } = searchItem
        const blocks: { uischema: UiSchema; zoneIndex: number }[] = []

        for (let zoneIndex = 0; zoneIndex < elements.length; zoneIndex++) {
          const zoneElement = elements[zoneIndex]

          for (let index = 0; index < zoneElement.length; index++) {
            const { items } = findBlockUiSchemasByType(
              zoneElement[index],
              type,
              searchItem
            )

            if (items) {
              blocks.push(...items.map((item) => ({ ...item, zoneIndex })))
            }
          }
        }

        if (blocks.length > 0) {
          return { items: blocks, operationStatus: 'found' }
        }
      } else if (isLayoutUiSchema(searchItem)) {
        const { elements } = searchItem
        const blocks: { uischema: UiSchema }[] = []

        // go through elements and check recursively
        for (let index = 0; index < elements.length; index++) {
          const { items } = findBlockUiSchemasByType(
            elements[index],
            type,
            searchItem
          )

          if (items) {
            blocks.push(...items)
          }
        }

        if (blocks.length > 0) {
          return { items: blocks, operationStatus: 'found' }
        }
      }
    }
    // neither block found, nor elements array given
  }

  return { items: [], operationStatus: 'not-found' }
}
