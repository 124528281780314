import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Coupon } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'
import { ProductImageRenderer } from '../ProductImageRenderer'

import { ProductTileCoupon } from './ProductTileCoupon'

const useProductImageWithCouponClasses = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative'
    },
    coupon: {
      position: 'absolute',
      top: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 1,
      marginBottom: theme.spacing(2)
    }
  })
)

export const ProductImageWithCoupon = ({ coupon }: { coupon: Coupon }) => {
  const { config } = useTileContext()
  const classes = useProductImageWithCouponClasses()

  if (!config.generalTileConfiguration.showImages) {
    return null
  }

  return (
    <div className={classes.container}>
      <ProductTileCoupon className={classes.coupon} coupon={coupon} />
      <ProductImageRenderer />
    </div>
  )
}
