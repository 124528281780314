import { clsx } from '@epilot/journey-logic-commons'
import classNames from 'classnames'
import type { PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import classes from './Button.module.scss'
import type { ButtonCSSProperties, ButtonProps } from './types'

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithoutRef<ButtonProps>
>((props, ref) => {
  const {
    leftIcon,
    rightIcon,
    label,
    className,
    isDisabled,
    style,
    color,
    backgroundColor,
    hoverBgColor,
    activeBgColor,
    variant,
    ...rest
  } = props

  const variantClass = isDisabled
    ? classes['variant-disabled']
    : variant
      ? classes[`variant-${variant}`]
      : undefined

  const customColors: ButtonCSSProperties = {
    '--concorde-button-label-color': color,
    '--concorde-button-background-color': backgroundColor,
    '--button-hover-bg-color': hoverBgColor,
    '--button-active-bg-color': activeBgColor
  }

  const customStyles = {
    ...style,
    ...customColors
  }

  return (
    <button
      aria-disabled={isDisabled || variant === 'disabled'}
      className={clsx('Concorde-Button', classes.root, variantClass, className)}
      ref={ref}
      style={customStyles}
      {...rest}
    >
      {leftIcon}
      <span
        className={classNames(
          'Concorde-Button__Label',
          classes['button-label']
        )}
      >
        {label}
      </span>
      {rightIcon}
    </button>
  )
})

Button.displayName = 'Button'
