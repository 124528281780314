import type { ComponentType } from 'react'

import { TileLayoutContainer } from '../../TileLayoutContainer'
import type { GetAgComponentProps } from '../../types'
import { ProductPrices } from '../TileBody/ProductPrices'

import classes from './TileSections.module.scss'

type TilePricesProps = {
  GetAgComponent: ComponentType<GetAgComponentProps>
}

export const TilePrices = ({ GetAgComponent }: TilePricesProps) => {
  return (
    <TileLayoutContainer
      className={classes.priceContainer}
      tileLayoutSection="price"
    >
      <ProductPrices GetAgComponent={GetAgComponent} />
    </TileLayoutContainer>
  )
}
