import type { RecurrenceAmount, PriceItem } from '@epilot/journey-logic-commons'
import { computePriceItemDetails, BillingPeriods } from '@epilot/pricing'

export const sortRecurrences = (
  firstRecurrence: RecurrenceAmount,
  secondRecurrence: RecurrenceAmount
) => {
  if (firstRecurrence.type === 'one_time') return -1
  if (secondRecurrence.type === 'one_time') return 1

  const sortedRecurrences = [...BillingPeriods]
  const firstRecurrencePeriodIndex = sortedRecurrences.indexOf(
    firstRecurrence.billing_period!
  )
  const secondRecurrencePeriodIndex = sortedRecurrences.indexOf(
    secondRecurrence.billing_period!
  )

  return firstRecurrencePeriodIndex - secondRecurrencePeriodIndex
}

export const sortItemRecurrenceDetails = (
  item: PriceItem
): RecurrenceAmount[] =>
  item.is_composite_price
    ? computePriceItemDetails(item).total_details?.breakdown?.recurrences?.sort(
        sortRecurrences
      ) || [
        {
          ...item,
          billing_period: item._price?.billing_period,
          amount_subtotal: item.amount_subtotal ?? 0,
          amount_subtotal_decimal: item.amount_subtotal_decimal ?? '0',
          amount_total: item.amount_total ?? 0,
          amount_total_decimal: item.amount_total_decimal ?? '0'
        }
      ]
    : [
        {
          ...item,
          billing_period: item._price?.billing_period,
          amount_subtotal: item.amount_subtotal ?? 0,
          amount_subtotal_decimal: item.amount_subtotal_decimal ?? '0',
          amount_total: item.amount_total ?? 0,
          amount_total_decimal: item.amount_total_decimal ?? '0'
        }
      ]
