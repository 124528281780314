/**
 * Through recursivity, recursivelyRenameObjectKeys will iterate over all of the keys of the object,
 * and return the a new object in which all of the keys have been renamed by the `renamer` function.
 * @param obj The object to recursively rename the keys of
 * @param renamer The function to rename the keys. It takes a key as a parameter, and returns the new key.
 */
const recursivelyRenameObjectKeys = <
  Value extends Record<string, unknown> = Record<string, unknown>
>(
  obj: Value,
  keyRenamer: (key: string) => string
): Value =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      keyRenamer(key),
      typeof value === 'object' && value !== null
        ? recursivelyRenameObjectKeys(value as Value, keyRenamer)
        : value
    ])
  ) as Value

export default recursivelyRenameObjectKeys
