import { FactorPreset } from '@epilot/journey-logic-commons'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type {
  InputCalculatorFormValues,
  InputCalculatorFormProps,
  HydratedDeviceOption
} from '../types'
import { FACTOR_DICTIONARY } from '../utils'

import { AutoSave } from './AutoSave'
import { ConsumptionFields } from './ConsumptionFields'
import { ConsumptionFieldsRootErrors } from './ConsumptionFieldsRootErrors'

export const InputCalculatorForm = ({
  path,
  hasError,
  deviceUnit = '',
  isRequired,
  deviceOptions,
  allowOther,
  data,
  uischema,
  handleChange,
  id
}: InputCalculatorFormProps) => {
  const { control, setValue, reset, handleSubmit } =
    useForm<InputCalculatorFormValues>({
      defaultValues: {
        ...data
      }
    })

  const { t } = useTranslation()
  const stringifiedUiSchema = JSON.stringify(uischema)

  const hydratedDeviceOptions: HydratedDeviceOption[] = useMemo(() => {
    const devices = (deviceOptions || []).map((option) => ({
      name: option.value,
      type: option.factor?.preset,
      factorMap:
        option.factor?.preset === FactorPreset.Custom &&
        option.factor?.custom_mappings
          ? option.factor?.custom_mappings
          : FACTOR_DICTIONARY.get(option.factor?.preset || '')
    }))

    if (allowOther) {
      devices.push({
        name: t('input_calculator.other_option'),
        type: undefined,
        factorMap: undefined
      })
    }

    return devices
  }, [deviceOptions])

  const preSelectedOptions: InputCalculatorFormValues['devices'] =
    deviceOptions
      ?.filter((option) => option.preSelect)
      .map((option) => {
        return {
          name: option.value,
          quantity: '1',
          unitaryConsumption: ''
        }
      }) || []

  const dataDevices = data?.devices || []

  const isFirstRender = useRef(true)

  // this useEffect sets preselected options for the journey
  useEffect(() => {
    if (dataDevices.length === 0) {
      reset({
        ...data,
        devices: preSelectedOptions
      })
    }
  }, [])

  // this useEffect sets preselected options for the journey preview when jb user changes the preselected options in the configurator
  // useRef is used to prevent updating options in the journey
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      return
    }

    reset({
      ...data,
      devices: preSelectedOptions
    })
  }, [stringifiedUiSchema])

  const showDeleteButton =
    preSelectedOptions.length > 1 ||
    dataDevices.length > 1 ||
    hydratedDeviceOptions.length > 1

  // the list of conditions to show the add device button
  const showAddDeviceButton =
    preSelectedOptions.length !== dataDevices.length ||
    dataDevices.length !== hydratedDeviceOptions.length ||
    (hydratedDeviceOptions.length === 1 && dataDevices.length === 0)

  // the condition to hide the add device button
  const hideAddDeviceButton =
    hydratedDeviceOptions.length === 1 && dataDevices.length === 1

  return (
    <>
      <ConsumptionFields
        consumptionOptions={hydratedDeviceOptions}
        control={control}
        digitsAfterDecimalPoint={uischema.options?.digitsAfterDecimalPoint}
        hasError={hasError}
        id={id}
        isRequired={isRequired}
        path={path}
        setValue={setValue}
        showAddDeviceButton={showAddDeviceButton && !hideAddDeviceButton}
        showDeleteButton={showDeleteButton}
        unit={deviceUnit}
      />
      <ConsumptionFieldsRootErrors control={control} hasError={hasError} />
      <AutoSave
        control={control}
        data={data}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        path={path}
        reset={reset}
        uischema={uischema}
      />
    </>
  )
}
