import { ParagraphBlockStringType } from './types'

const Base64Test =
  /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/

export function getParagraphBlockString(str: string): {
  type: ParagraphBlockStringType
  value: string
} {
  if (Base64Test.test(str)) {
    let decoded = ''

    try {
      decoded = fromBinary(str)
    } catch {
      decoded = str
    }

    if (isJson(decoded)) {
      return {
        type: ParagraphBlockStringType.BASE64_LEXICAL,
        value: decoded
      }
    } else {
      return {
        type: ParagraphBlockStringType.BASE64_MARKDOWN,
        value: decoded
      }
    }
  } else {
    if (isJson(str)) {
      return {
        type: ParagraphBlockStringType.LEXICAL,
        value: str
      }
    } else {
      return {
        type: ParagraphBlockStringType.MARKDOWN,
        value: str
      }
    }
  }
}

function isJson(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }

  return true
}

function fromBinary(encoded: string) {
  const binary = atob(encoded)
  const bytes = new Uint8Array(binary.length)

  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i)
  }

  return String.fromCharCode(...new Uint16Array(bytes.buffer))
}
