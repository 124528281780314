import {
  Checkbox,
  FormControl,
  FormHelperText,
  Typography,
  clsx
} from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { IconLabel } from '../../../../components/IconLabel'
import type { MultichoiceControlComponentProps } from '../types'

import { useStyles } from './styles'

export function MultichoiceCheckbox({
  options = [],
  value = [],
  onChange,
  optionsLabels = [],
  disabled,
  errorMessage,
  label,
  required,
  maxSelection,
  optionsIcons = [],
  id
}: MultichoiceControlComponentProps) {
  const { t } = useTranslation()
  const addToValue = (option: string) => {
    onChange(Array.isArray(value) ? [...value, option] : [option])
  }
  const classes = useStyles()
  const removeFromValue = (option: string) => {
    const optionsIndex = Array.isArray(value)
      ? value.findIndex((op) => op === option)
      : -1

    if (optionsIndex > -1) {
      const newOptions = [...value]

      newOptions.splice(optionsIndex, 1)
      onChange(newOptions)
    }
  }

  // maxSelection as undefined or =0 means unlimited
  const disableSelection =
    maxSelection && Array.isArray(value) && value.length === maxSelection
      ? true
      : false

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={errorMessage ? true : false}
    >
      <div
        className={clsx(
          required && !label ? classes.checkboxNoLabelContainer : ''
        )}
      >
        <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
          {`${label ? label : ''}${required ? '*' : ''}`}
        </Typography>
      </div>
      {options.map((op: string, index: number) => {
        const optionLabel = optionsLabels[index] ? optionsLabels[index] : op
        const checked = Array.isArray(value) && value.includes(op)
        const iconConfig = optionsIcons[index]
        const fieldId = appendStepBlockId(id, optionLabel)

        return (
          <Checkbox
            checked={checked}
            color="primary"
            disabled={disableSelection && !checked}
            id={fieldId}
            key={op}
            label={<IconLabel iconConfig={iconConfig} label={optionLabel} />}
            onChange={(_e, checked) =>
              checked ? addToValue(op) : removeFromValue(op)
            }
          />
        )
      })}
      <FormHelperText>
        {errorMessage
          ? t('Please select one of the options')
          : disableSelection
            ? t('max_selection_reached')
            : ''}
      </FormHelperText>
    </FormControl>
  )
}
