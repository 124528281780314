import type { TileLayoutSection } from '@epilot/journey-logic-commons'
import type {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction
} from 'react'
import { createContext, useContext, useState } from 'react'

type SectionHeightMap = Map<MutableRefObject<HTMLDivElement | null>, number>
type HeightMap = Map<TileLayoutSection, SectionHeightMap>
export type HeightMapSetter = Dispatch<SetStateAction<HeightMap>>

type TileLayoutContextValue = [HeightMap, HeightMapSetter]

const TileLayoutContext = createContext<undefined | TileLayoutContextValue>(
  undefined
)

type TileLayoutProviderProps = {
  children: ReactNode
}

export const TileLayoutContextProvider = ({
  children
}: TileLayoutProviderProps) => {
  const heightMapApi = useState<HeightMap>(new Map())

  return (
    <TileLayoutContext.Provider value={heightMapApi}>
      {children}
    </TileLayoutContext.Provider>
  )
}

export const useTileLayoutContext = () => {
  const context = useContext(TileLayoutContext)

  if (!context) {
    throw new Error(
      'useTileLayoutContext must be used within a TileLayoutProvider'
    )
  }

  return context
}
