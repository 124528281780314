/* eslint-disable @typescript-eslint/no-namespace */
import { useTheme } from '@epilot/journey-elements'
import type { DOMAttributes } from 'react'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import type { CustomBlockComponentProps } from './types'

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: unknown }>

declare global {
  namespace JSX {
    interface IntrinsicElements {
      [component: string]: CustomElement<unknown>
    }
  }
}

// this component shall display the user custom bundle and communicate the data to it and get the data back
export function CustomBlockComponent({
  bundleURL,
  value,
  tagName,
  required,
  onChange,
  errors,
  args
}: CustomBlockComponentProps) {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<CustomElement<any>>()

  const theme = useTheme()

  // load custom script if not yet loaded
  useEffect(() => {
    if (bundleURL) {
      const head = document.getElementsByTagName('body')
      const scriptTag = document.createElement('script')

      scriptTag.src = bundleURL as string
      scriptTag.async = true

      if (!document.querySelector(`script[src="${bundleURL}"]`)) {
        head[0].appendChild(scriptTag)
      }
    }
  }, [bundleURL])

  // pass props as custome element properties
  useEffect(() => {
    if (ref.current) {
      // getter and setter for block value
      ref.current['value'] =
        typeof value === 'object' ? JSON.stringify(value) : value
      ref.current['setValue'] = onChange

      // pass required and errors
      ref.current['required'] = required
      ref.current['errors'] = errors
      ref.current['args'] = args
    }
  }, [errors, onChange, required, value, args])

  if (!tagName || !bundleURL) {
    return (
      <div>
        <p>
          {t('custom_block.config_error', 'The block is missing configuration')}
        </p>
        <ul>
          {!tagName && (
            <li>{t('custom_block.tag_error', 'Tag name is missing')}</li>
          )}
          {!bundleURL && (
            <li>{t('custom_block.bundle_error', 'Bundle URL is missing')}</li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <div className={`custom-block custom-block-${tagName}`}>
      {tagName &&
        React.createElement(tagName, {
          value: typeof value === 'object' ? JSON.stringify(value) : value,
          theme: JSON.stringify(theme),
          required: required,
          errors: errors,
          setValue: onChange,
          args,
          ref
        })}
    </div>
  )
}
