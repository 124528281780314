import { isNestedUiSchema } from '../../../utils'
import type { Step } from '../../../utils'
import { getBlockControlIdentifier, getPossibleValues } from '../helpers'

import { findBlockSchema } from './findBlockSchema'
import { findBlockUischema } from './findBlockUischema'
import { findBlockUiSchemasByType } from './findBlockUiSchemasByType'
import type {
  SearchCriteria,
  BlockLocator,
  SearchOptions,
  Block,
  BlockLocatorDetails,
  BlockFindingOperation
} from './types'

export function findBlockInStep(
  step: Step,
  searchCriteria: { name: string },
  searchOptions?: Omit<SearchOptions, 'representBy'> & {
    representBy?: 'single'
  },
  operation?: BlockFindingOperation
): BlockLocatorDetails | undefined
export function findBlockInStep(
  step: Step,
  searchCriteria: { type: string },
  searchOptions?: Omit<SearchOptions, 'representBy'> & {
    representBy?: 'single'
  },
  operation?: BlockFindingOperation
): BlockLocatorDetails[] | undefined
export function findBlockInStep(
  step: Step,
  searchCriteria: SearchCriteria,
  searchOptions: Omit<SearchOptions, 'representBy'> & { representBy: 'full' },
  operation?: BlockFindingOperation
): Block | undefined
export function findBlockInStep(
  step: Step,
  searchCriteria: SearchCriteria,
  searchOptions?: SearchOptions,
  operation?: BlockFindingOperation
): BlockLocatorDetails | BlockLocatorDetails[] | Block | undefined {
  const { schema, uischema } = step

  if ('name' in searchCriteria) {
    let blockUischema: Step['uischema'] | undefined = undefined
    let zoneIndex: number | undefined = undefined
    let parent: { scope?: string; type?: string } | undefined = undefined

    if (!isNestedUiSchema(uischema)) {
      const item = findBlockUischema(
        uischema,
        searchCriteria,
        searchOptions,
        operation
      )

      if (item?.uischema) {
        blockUischema = item.uischema
        parent = item.parent
      }
    } else {
      const item = findBlockUischema(
        uischema,
        searchCriteria,
        searchOptions,
        operation
      )

      if (item?.uischema) {
        blockUischema = item.uischema
        zoneIndex = item.zoneIndex
        parent = item.parent
      }
    }

    const name = blockUischema?.scope?.split('/').pop()

    // if searched by type, use name from found uischema
    const schemaSearchCriteria = searchCriteria.name
      ? searchCriteria
      : { name: name! }
    // find blocks schema
    const blockSchema = findBlockSchema(
      schema,
      schemaSearchCriteria,
      searchOptions,
      operation === 'update' || operation === 'rename' ? operation : undefined
    )

    if (searchOptions?.representBy === 'full') {
      if (blockUischema) {
        return {
          schema: blockSchema,
          uischema: blockUischema
        }
      }
    }

    // bail if block not found
    if (!blockUischema || !name) {
      return
    }

    const property = blockSchema?.properties?.[name]

    return {
      schema: property
        ? {
            [name]: property
          }
        : undefined,
      required: blockSchema?.required?.includes(name),
      uischema: blockUischema,
      name,
      stepName: step.name,
      stepId: step.stepId,
      zoneIndex: zoneIndex,
      parent: parent,
      controlNameId: getBlockControlIdentifier(property, blockUischema),
      possibleValues: getPossibleValues(property),
      type: blockUischema.type,
      scope: blockUischema.scope
    }
  } else if (searchCriteria.type) {
    let blockUischemas: {
      uischema: Step['uischema']
      zoneIndex?: number
      parent?: { scope?: string; type?: string }
    }[] = []

    if (!isNestedUiSchema(uischema)) {
      const { items } = findBlockUiSchemasByType(uischema, searchCriteria.type)

      if (items) {
        blockUischemas = items
      }
    } else {
      const { items } = findBlockUiSchemasByType(uischema, searchCriteria.type)

      if (items) {
        blockUischemas = items
      }
    }

    const blocks: (BlockLocator | undefined)[] = blockUischemas.map(
      ({ uischema, zoneIndex, parent }) => {
        const name = uischema?.scope?.split('/').pop()

        // bail if block not found
        if (!uischema || !name) {
          return
        }

        const blockSchema = findBlockSchema(schema, { name })
        const property = blockSchema?.properties?.[name]

        return {
          schema: property
            ? {
                [name]: property
              }
            : undefined,
          required: blockSchema?.required?.includes(name),
          uischema,
          name,
          stepName: step.name,
          parent: parent,
          stepId: step.stepId,
          zoneIndex: zoneIndex,
          controlNameId: getBlockControlIdentifier(property, uischema),
          possibleValues: getPossibleValues(property),
          type: uischema.type,
          scope: uischema.scope
        }
      }
    )

    return blocks.filter(Boolean) as BlockLocatorDetails[]
  }

  // eslint-disable-next-line no-console
  console.error(
    'Error in findBlockInStep. Search Criteria do not match accepted options'
  )

  return undefined
}
