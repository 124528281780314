import { createStyles, IconButton, makeStyles } from '@material-ui/core'
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  MoreHoriz
} from '@material-ui/icons'
import classNames from 'classnames'
import React, { memo, useEffect, useState } from 'react'
import type { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

import { MenuItem } from '../Menu'
import { Select } from '../Select'
import { TextField } from '../TextField'
import type { EpilotTheme } from '../ThemeProvider'
import { BodyText } from '../Typography'

import { useLocales, getPages } from './PaginationHelper'
import { PAGE_TYPE } from './types'
import type { PaginationLocales, PaginationProps } from './types'

const STANDARD_DIMENSIONS = {
  width: 33,
  height: 33
}

const useButtonClasses = makeStyles((theme: EpilotTheme) =>
  createStyles({
    root: {
      width: STANDARD_DIMENSIONS.width,
      height: STANDARD_DIMENSIONS.height,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme.palette?.grey[70]}`,
      borderRadius: '50%',
      backgroundColor: theme.palette?.background.default,
      fontWeight: 600,
      color: theme.palette?.text.primary,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.palette?.background.default,
        borderColor: theme.palette?.primary.main
      }
    },
    active: {
      backgroundColor: theme.palette?.primary.main,
      borderColor: theme.palette?.primary.main,
      color: theme.palette?.background.default,

      '&:hover': {
        backgroundColor: theme.palette?.primary[600],
        borderColor: theme.palette?.primary[600]
      }
    }
  })
)

type PageButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { active?: boolean }

const PageButton = memo(
  ({ active = false, className, ...props }: PageButtonProps) => {
    const classes = useButtonClasses()

    return (
      <button
        className={classNames(
          classes.root,
          { [classes.active]: active },
          className
        )}
        {...props}
      />
    )
  }
)

PageButton.displayName = 'PageButton'

const usePaginationClasses = makeStyles((theme: EpilotTheme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',

      '& >*:not(:last-child)': {
        marginRight: 8
      }
    },
    arrowIcon: {
      padding: 0,
      width: STANDARD_DIMENSIONS.width,
      height: STANDARD_DIMENSIONS.height,
      color: theme.palette?.grey[200]
    },
    dotsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: STANDARD_DIMENSIONS.width,
      height: STANDARD_DIMENSIONS.height
    },
    dotsIcon: {
      color: theme.palette?.grey[200]
    },
    pageInput: {
      width: 56
    },
    countText: {
      marginTop: 3,
      minWidth: 96,
      textAlign: 'right',
      marginLeft: -8
    },
    selectRoot: {
      paddingTop: 6.5,
      paddingBottom: 6.5
    },
    selectContainer: {
      marginTop: 4,

      '& svg': {
        color: `${theme.palette?.text.primary} !important`
      }
    }
  })
)

const getCountText = (
  locales: PaginationLocales,
  numberOfPages: number,
  activePage: number,
  pageSize: number,
  itemsCount: number
) =>
  locales.countText({
    from: (activePage - 1) * pageSize + 1,
    to: numberOfPages === activePage ? itemsCount : activePage * pageSize,
    total: itemsCount
  })

export const Pagination = memo((props: PaginationProps) => {
  const {
    // state
    activePage,
    pageSizes = [],
    pageSize,
    itemsCount,
    showPageInput = true,
    showPageSizeSelector = true,
    locales: propLocales = {},
    // actions
    onActivePageChange,
    onPageSizeChange,
    onPageHover,
    dataTestId,
    id
  } = props
  const [hovered, setHovered] = useState<boolean>(false)
  const locales = useLocales(propLocales)
  const classes = usePaginationClasses()
  const [pageInputValue, setPageInputValue] = useState(activePage.toString())
  const numberOfPages = Math.ceil(itemsCount / pageSize)
  const pages = getPages(numberOfPages, activePage)

  useEffect(() => {
    setPageInputValue(activePage.toString())
  }, [activePage])

  const onPageChange = (pageValue: number) => {
    onActivePageChange(pageValue)
    setPageInputValue(pageValue.toString())
  }

  const updateActivePageByInputPageValue = () => {
    const pageInputNumber = Number(pageInputValue)

    if (pageInputNumber <= 1) {
      onPageChange(1)
      setPageInputValue('1')

      return
    }

    if (pageInputNumber >= numberOfPages) {
      onPageChange(numberOfPages)
      setPageInputValue(numberOfPages.toString())

      return
    }

    onPageChange(pageInputNumber)
    setPageInputValue(pageInputNumber.toString())
  }

  const onPageSizeUpdate = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newPageSize = event.target.value as number

    onActivePageChange(1)
    setPageInputValue('1')
    onPageSizeChange?.(newPageSize)
  }

  const onPageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInputValue(event.target.value)
  }

  const onPageInputEnterPressed = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === 'Enter') {
      updateActivePageByInputPageValue()
    }
  }

  const onPageInputBlur = () => {
    updateActivePageByInputPageValue()
  }

  const countText = getCountText(
    locales,
    numberOfPages,
    activePage,
    pageSize,
    itemsCount
  )

  return (
    <div className={classes.buttonsContainer}>
      {showPageInput && (
        <>
          <BodyText>{locales.pageInputLabel}</BodyText>
          <TextField
            classes={{ root: classes.pageInput }}
            label="Label"
            onBlur={onPageInputBlur}
            onChange={onPageInputChange}
            onKeyPress={onPageInputEnterPressed}
            placeholder="..."
            size="small"
            type="number"
            value={pageInputValue}
            variant="outlined"
          />
        </>
      )}

      <IconButton
        classes={{ root: classes.arrowIcon }}
        {...(dataTestId
          ? { 'data-testid': `${dataTestId}-first-page-button` }
          : {})}
        disabled={activePage === 1}
        id={id && `${id}-first-page-button`}
        onClick={() => onPageChange(1)}
      >
        <FirstPage />
      </IconButton>

      <IconButton
        classes={{ root: classes.arrowIcon }}
        disabled={activePage === 1}
        id={id && `${id}-previous-page-button`}
        onClick={() => onPageChange(activePage - 1)}
      >
        <ChevronLeft />
      </IconButton>

      {pages.map(
        (page) =>
          (page.type === PAGE_TYPE.DOTS && (
            <div className={classes.dotsContainer} key={page.value}>
              <MoreHoriz className={classes.dotsIcon} />
            </div>
          )) || (
            <PageButton
              active={activePage === page.value}
              id={id && `${id}-page-${page.value}-button`}
              key={page.value}
              onClick={() => onPageChange(page.value)}
              onMouseEnter={() => {
                /**
                 * We want to ensure onPageHover doesn't get triggered on layout shifts
                 * E.g. when hovering over the dots, the page number will change
                 * and the layout will shift, which would trigger onPageHover.
                 */
                if (!hovered) {
                  setHovered(true)
                  onPageHover?.(page.value)
                }
              }}
              onMouseLeave={() => setHovered(false)}
            >
              {page.value}
            </PageButton>
          )
      )}

      <IconButton
        classes={{ root: classes.arrowIcon }}
        disabled={activePage === numberOfPages}
        id={id && `${id}-next-page-button`}
        onClick={() => onPageChange(activePage + 1)}
      >
        <ChevronRight />
      </IconButton>

      <IconButton
        classes={{ root: classes.arrowIcon }}
        {...(dataTestId
          ? { 'data-testid': `${dataTestId}-last-page-button` }
          : {})}
        disabled={activePage === numberOfPages}
        id={id && `${id}-last-page-button`}
        onClick={() => onPageChange(numberOfPages)}
      >
        <LastPage />
      </IconButton>

      <BodyText className={classes.countText}>{countText}</BodyText>

      {showPageSizeSelector && (
        <div className={classes.selectContainer}>
          <Select
            classes={{ root: classes.selectRoot }}
            id={id && `${id}-select-page-size`}
            onChange={onPageSizeUpdate}
            value={pageSize}
            variant="outlined"
          >
            {pageSizes.map((pageSize) => (
              <MenuItem key={pageSize} value={pageSize}>
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
})

Pagination.displayName = 'Pagination'
