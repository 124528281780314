import {
  Typography,
  FormHelperText,
  clsx,
  Checkbox,
  Button
} from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { IconLabel } from '../../../../components/IconLabel'
import { useEnumButtonStyles } from '../../../../renderers/controls/EnumButtonControl/styles/useEnumButtonStyles'
import useResizeDirection from '../../EnumButtonControl/useResizeDirection'
import type { MultichoiceControlComponentProps } from '../types'

export function MultichoiceButton({
  options = [],
  value = [],
  onChange,
  optionsLabels = [],
  errorMessage,
  label,
  required,
  maxSelection,
  optionsIcons = [],
  id
}: MultichoiceControlComponentProps) {
  const { t } = useTranslation()
  const buttonStyles = useEnumButtonStyles()

  const [direction, buttonWrapRef] = useResizeDirection()

  const addToValue = (option: string) => {
    onChange(Array.isArray(value) ? [...value, option] : [option])
  }

  const removeFromValue = (option: string) => {
    const optionsIndex = Array.isArray(value)
      ? value.findIndex((op) => op === option)
      : -1

    if (optionsIndex > -1) {
      const newOptions = [...value]

      newOptions.splice(optionsIndex, 1)
      onChange(newOptions)
    }
  }

  const handleChange = (checked: boolean, option: string) =>
    checked ? addToValue(option) : removeFromValue(option)

  // maxSelection as undefined or =0 means unlimited
  const disableSelection =
    maxSelection && Array.isArray(value) && value.length === maxSelection
      ? true
      : false

  return (
    <div className={label ? '' : buttonStyles.containerWithoutLabel}>
      <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
        {`${label}${required ? '*' : ''}`}
      </Typography>

      <div
        className={clsx(buttonStyles.buttonWrap, {
          [buttonStyles.columnDirection]: direction === 'column'
        })}
        ref={buttonWrapRef}
      >
        {options.map((option: string, index: number) => {
          const optionLabel = optionsLabels[index]
            ? optionsLabels[index]
            : option

          const fieldId = appendStepBlockId(id, optionLabel)
          const checked = Array.isArray(value) && value.includes(option)

          const disabled = disableSelection && !checked ? true : false

          const iconConfig = optionsIcons[index]

          return (
            <Button
              className={clsx(
                buttonStyles.button,
                checked && buttonStyles.buttonActive
              )}
              color="primary"
              disabled={disabled}
              key={option}
              onClick={() => handleChange(!checked, option)}
              size="large"
              variant="outlined"
            >
              <Checkbox
                checked={checked}
                color="primary"
                disabled={disabled}
                id={fieldId}
              />
              <div>
                <IconLabel iconConfig={iconConfig} label={optionLabel} />
              </div>
            </Button>
          )
        })}
      </div>
      <FormHelperText error={errorMessage ? true : false}>
        {errorMessage
          ? t('Please select one of the options')
          : disableSelection
            ? t('max_selection_reached')
            : ''}
      </FormHelperText>
    </div>
  )
}
