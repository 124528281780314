import {
  Card,
  CardContent,
  H6,
  Typography,
  makeStyles
} from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'

import { useJourneyContext } from './context'
import { determineBlockDisabledStatus } from './displaySettings'
import { IsMobileOrTablet } from './helper'

type IncludeCommonOptions = {
  component: (props: EpilotControlProps) => JSX.Element
}

export function includeCommon({ component: Component }: IncludeCommonOptions) {
  const WrappedComponent = ({ ...props }: EpilotControlProps) => {
    const { uischema, enabled: enabledFromJsonForms } = props
    const { options } = uischema
    const isNested = options?.isNested
    const classes = useStyles()

    const { context } = useJourneyContext()

    const mobile = IsMobileOrTablet()

    const disabledFields =
      (context.blocksDisplaySettings
        ? determineBlockDisabledStatus(
            context.blocksDisplaySettings,
            context._navigationInfo.currentStepIndex,
            uischema.scope
          )
        : false) || !enabledFromJsonForms

    const content =
      options?.title || options?.subtitle ? (
        <>
          <div className={classes.contentContainer}>
            {options?.title ? (
              <div className={classes.titleContainer}>
                <H6>
                  <b className={mobile ? classes.smallFont : classes.bigFont}>
                    {options?.title}
                  </b>
                </H6>
              </div>
            ) : null}
            {options?.subtitle ? (
              <div>
                <Typography variant="body1">{options.subtitle}</Typography>
              </div>
            ) : null}
          </div>
          <Component
            disabledFields={
              Array.isArray(disabledFields) ? disabledFields : undefined
            }
            {...props}
            enabled={!disabledFields}
          />
        </>
      ) : (
        <Component
          disabledFields={
            Array.isArray(disabledFields) ? disabledFields : undefined
          }
          {...props}
          enabled={!disabledFields}
        />
      )

    if (uischema.options?.display === false) return null

    return options?.showPaper && !context.isLauncherJourney && !isNested ? (
      <Card className={classes.card}>
        <CardContent className={classes.notNested}>{content}</CardContent>
      </Card>
    ) : (
      <div className={isNested ? classes.nested : classes.notNested}>
        {content}
      </div>
    )
  }

  return WrappedComponent
}

const useStyles = makeStyles((theme) => ({
  smallFont: {
    fontSize: '80%'
  },
  bigFont: {
    fontSize: '100%'
  },
  titleContainer: {
    paddingBottom: 0
  },
  notNested: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  nested: {
    paddingTop: theme.spacing(3)
  },
  contentContainer: {
    paddingBottom: theme.spacing(1)
  },
  card: {
    borderRadius: `min(${theme.shape.borderRadius}px, 20px)`
  }
}))
