import type {
  AddressSuggestionsSource,
  FieldOption,
  Address
} from '@epilot/journey-logic-commons'
import { useEffect, useMemo } from 'react'
import type {
  Control,
  Path,
  PathValue,
  UseControllerProps,
  UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldAutocomplete } from '../../../../components/FieldAutocomplete'
import { useConfig, useJourneyContext } from '../../../../utils'
import type {
  ZipCitySuggestion,
  CustomValidationRules,
  ZipCitySuggestionData
} from '../types'
import { FeedBackType } from '../types'
import { isZipCitySuggestion } from '../types.guard'
import {
  sendFeedback,
  regexZipCodeCityByCountry,
  zipCodeExamplesByCountry
} from '../utils'

import { useAddressSuggestions } from './hooks'

export type FieldZipCityInputProps = {
  addressSuggestionsSource?:
    | AddressSuggestionsSource
    | AddressSuggestionsSource[]
    | undefined
  control: Control<Address>
  name: Path<Address>
  hasError: boolean
  defaultValue?: PathValue<Address, Path<Address>>
  path: string
  rules?: UseControllerProps['rules']
  fieldValues: Address
  acceptSuggestedOnly?: boolean
  freeSolo?: boolean
  disableAddressSuggestions?: boolean
  addressCountryCode?: string
  hideCity?: boolean
  setValue: UseFormReturn<Address>['setValue']
  register: UseFormReturn<Address>['register']
  setAddressDown?: (value: boolean) => void
  keepAutoCompleteClosed?: boolean
  id: string
} & FieldOption

const MIN_CHARS = 2

export const FieldZipCityInput = (props: FieldZipCityInputProps) => {
  const {
    acceptSuggestedOnly,
    addressSuggestionsSource,
    label,
    setValue,
    setAddressDown,
    register,
    required,
    fieldValues,
    disableAddressSuggestions,
    addressCountryCode,
    hideCity,
    keepAutoCompleteClosed,
    ...autocompleteProps
  } = props

  // register city as there is no dedicated component
  register('city', { required: hideCity ? false : !!required })

  const { t } = useTranslation()

  const { context } = useJourneyContext()
  const { ADDRESS_API_URL } = useConfig()
  const {
    journey: { organizationId: orgId, settings }
  } = context
  const publicToken = settings?.publicToken as string

  const { addressDown, getSuggestions, options, isLoading, metaValue } =
    useAddressSuggestions(
      'zipCity',
      disableAddressSuggestions,
      fieldValues,
      addressSuggestionsSource,
      publicToken,
      settings?.addressSuggestionsFileUrl,
      orgId,
      settings?.addressSuggestionsFileId
    )

  useEffect(() => {
    setAddressDown?.(addressDown)
  }, [setAddressDown, addressDown])

  useEffect(() => {
    setValue?.('meta', metaValue)
  }, [setValue, metaValue])

  const getCustomValidationRules: CustomValidationRules = useMemo(() => {
    return {
      ...(addressCountryCode && {
        rules: {
          validate: {
            zipCodeCityFormat: (value: string) => {
              if (!required && !value) {
                return true
              }

              if (
                value &&
                fieldValues.city &&
                regexZipCodeCityByCountry(addressCountryCode).test(
                  `${value} ${fieldValues.city}`
                )
              ) {
                return true
              }

              return false
            }
          }
        },
        errorMessages: {
          zipCodeCityFormat: t('address_zip_code_city_format_error', {
            zipCodeExample: zipCodeExamplesByCountry(addressCountryCode)
          })
        }
      })
    }
  }, [addressCountryCode, fieldValues.city, required, t])

  return (
    <FieldAutocomplete<Address, ZipCitySuggestion, ZipCitySuggestion>
      {...autocompleteProps}
      acceptSuggestedOnly={acceptSuggestedOnly}
      getOptionLabel={(option: string | ZipCitySuggestion) => {
        // if an option is selected, the field contains only the zip, so find value based on field value + other fields city value
        if (typeof option === 'string' && fieldValues.city) {
          return `${option} ${fieldValues.city}`
        }

        if (typeof option === 'string' && !acceptSuggestedOnly) {
          return option
        }

        if (isZipCitySuggestion(option)) {
          const { postal_code, city } = option

          // option will be object before selecting a value
          if (postal_code && city) {
            return `${postal_code} ${city}`
          }
        }

        return ''
      }}
      getOptionSelected={(option: ZipCitySuggestion, value: string) => {
        // selected value is only zipCode
        return option.postal_code === value
      }}
      getSuggestions={
        getSuggestions as (
          value: string
        ) => Promise<ZipCitySuggestion[]> | undefined
      }
      keepAutoCompleteClosed={keepAutoCompleteClosed}
      label={(label || t('City / Postcode')) + (required ? ' *' : '')}
      loading={isLoading}
      minChars={MIN_CHARS}
      noOptionsText={t('no_zip_code')}
      onBlur={(suggestions, field, setInputValue) => {
        if (suggestions && suggestions.length === 1) {
          field.onChange(suggestions[0].postal_code)

          // set related fields value
          setValue('city', suggestions[0]['city'])
        } else if (acceptSuggestedOnly && !field.value) {
          setInputValue('')
        }
      }}
      // select first option if blurring out
      onChange={(value, field) => {
        if (value && value['postal_code'] && value['city']) {
          // set own fields value
          field.onChange(value.postal_code)

          // set related fields value
          setValue('city', value['city'])

          // send feedback
          sendFeedback(
            ADDRESS_API_URL,
            FeedBackType.POSTAL_CODE_OR_CITY,
            fieldValues.countryCode,
            value,
            publicToken
          )
        } else {
          // clear data as e.g. clear button clicked
          field.onChange('')
          setValue('city', '')
        }
      }}
      // select first option if blurring out
      onInputChange={(value, field, setInputValue) => {
        if (!acceptSuggestedOnly) {
          const [zipCode, ...city] = value.split(' ')

          field.onChange(zipCode)
          setValue('city', city ? city.join(' ') : '')
        } else if (field.value) {
          field.onChange(field.value)
          setValue('city', fieldValues.city || '')
        } else {
          field.onChange(null)
          setValue('city', '')
        }
        setInputValue(value)
      }}
      options={options as ZipCitySuggestionData}
      required={required}
      rules={getCustomValidationRules.rules}
      rulesCustomErrorMessages={getCustomValidationRules.errorMessages}
    />
  )
}
