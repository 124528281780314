import type { JsonSchema7 } from '@jsonforms/core'

export function getPossibleValues(property: JsonSchema7 | undefined) {
  if (property?.enum) {
    return property.enum
  } else if (property?.type === 'boolean') {
    return ['true', 'false']
  } else {
    return undefined
  }
}
