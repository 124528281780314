import { Link as ConcordeLink, Icon } from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  Link,
  StackSpacing,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import type { File } from '@epilot/journey-logic-commons'
import { appendStepBlockId, getPublicUrl } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'

type ProductDownloadsProps = {
  downloads: File[]
}

function getDownloadLinkId(id: string, title?: string) {
  return appendStepBlockId(id, `Product Downloads ${title}`)
}

export const ProductDownloads = ({ downloads }: ProductDownloadsProps) => {
  const { config, actions, useNewDesign, id } = useTileContext()
  const title = actions.t(config.content.downloads.title)
  const classes = useProductDownloadsStyles()

  return (
    <>
      {useNewDesign ? (
        <div className={classes.newContainer}>
          {downloads.map((download) => (
            <div className={classes.linkContainer} key={download._id}>
              <Icon name="download" />
              <ConcordeLink
                className={classes.link}
                href={getPublicUrl(download)}
                id={getDownloadLinkId(id, download._title)}
                target="_blank"
              >
                {download.filename}
              </ConcordeLink>
            </div>
          ))}
        </div>
      ) : (
        <StackSpacing scale={1}>
          <BodyText className={classes.title}>{title}</BodyText>

          <div className={classes.container}>
            {downloads.map((download) => (
              <Link
                href={getPublicUrl(download)}
                id={getDownloadLinkId(id, download._title)}
                key={download._id}
                target="_blank"
              >
                <BodyText className={classes.downloads} variant="subtitle2">
                  {download.filename}
                </BodyText>
              </Link>
            ))}
          </div>
        </StackSpacing>
      )}
    </>
  )
}

const useProductDownloadsStyles = makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      fontWeight: 600
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '100%'
    },
    downloads: {
      maxWidth: 'calc(100% - 28px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      fontSize: 12
    },
    newContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: '100%',
      rowGap: '8px',
      columnGap: '16px',
      padding: '16px 0 8px'
    },
    linkContainer: {
      display: 'flex',
      gap: '0.25rem',
      color: theme.palette.primary.main,
      alignItems: 'center'
    },
    link: {
      fontSize: 14,
      lineHeight: 1.5,
      wordBreak: 'break-word'
    }
  })
)
