import { findBlocks } from './findBlocks'
import type {
  Block,
  BlockLocatorDetails,
  BlockFindingOperation,
  SearchCriteria,
  SearchOptions,
  SearchElement
} from './types'

export function findBlock(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions?: Omit<SearchOptions, 'representBy'> & {
    representBy?: 'single'
  },
  operation?: BlockFindingOperation
): BlockLocatorDetails | undefined
export function findBlock(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions: Omit<SearchOptions, 'representBy'> & { representBy: 'full' },
  operation?: BlockFindingOperation
): Block | undefined
export function findBlock(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions?: SearchOptions,
  operation?: BlockFindingOperation
) {
  /**
   * @todo This condition is redundant
   */
  if (searchOptions?.representBy === 'full') {
    const blocks = findBlocks(
      searchElement,
      searchCriteria,
      { ...searchOptions, representBy: 'full' },
      operation
    )

    // check if at least one block found
    if (blocks.length > 0) {
      // warn if multiple have been found
      if (blocks.length > 1) {
        // eslint-disable-next-line no-console
        console.warn(
          'findBlock --> multiple blocks found for search criteria. First block was returned'
        )
      }

      return blocks[0]
    }
  } else {
    const blocks = findBlocks(
      searchElement,
      searchCriteria,
      { ...searchOptions, representBy: undefined },
      operation
    )

    // check if at least one block found
    if (blocks.length > 0) {
      // warn if multiple have been found
      if (blocks.length > 1) {
        // eslint-disable-next-line no-console
        console.warn(
          'findBlock --> multiple blocks found for search criteria. First block was returned'
        )
      }

      return blocks[0]
    }
  }

  return
}
