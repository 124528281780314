import type { Step } from '../../types'

import type { SearchCriteria, SearchOptions } from './types'

export const findBlockSchema = (
  schema: Step['schema'],
  searchCriteria: SearchCriteria,
  searchOptions: SearchOptions = {},
  operation?: 'update' | 'rename'
): Step['schema'] | undefined => {
  const { properties, required = [], ...rest } = schema

  // find blocks schema
  if (properties && 'name' in searchCriteria && searchCriteria.name) {
    const { name } = searchCriteria
    const { replaceBy, representBy } = searchOptions

    // check if property exists is enough as we dont have nested block schemas for now
    if (properties[name]) {
      const blockRequired =
        operation === 'update' ? replaceBy?.required : required.includes(name)
      // check if the key should be replaced
      const key = replaceBy?.name || name

      const blockSchema = replaceBy?.schema?.[key]

      const schemaUpdated: Step['schema'] = {}

      // if full representational mode -> include other properties
      if (representBy === 'full') {
        // exclude original property so it's not merged back in when replaced
        const { [name]: _exclName, ...otherProperties } = properties

        // remove the items first in all cases, then, if blockRequired -> add the key back
        const requiredFiltered = required.filter((item) => item !== name)

        Object.assign(schemaUpdated, {
          ...rest,
          properties: {
            ...otherProperties,
            [key]: blockSchema || properties[name]
          },
          required: blockRequired
            ? [...requiredFiltered, key]
            : requiredFiltered
        })
      } else {
        // generate basic schema
        Object.assign(schemaUpdated, {
          required: blockRequired ? [key] : [],
          properties: { [key]: blockSchema || properties[name] }
        })
      }

      return schemaUpdated
    } else if (representBy === 'full') {
      return schema
    }
  }

  return
}
