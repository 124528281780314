import type { BillingPeriod, Price } from '@epilot/journey-logic-commons'
import {
  getPriceDisplayText,
  getPriceType
} from '@epilot/journey-logic-commons'

import { Typography } from '../../../../components'
import classes from '../../styles/ProductPriceItem.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

export interface PriceItemProps {
  priceType?: Price['type']
  billingPeriod?: BillingPeriod
  priceDisplayInJourneys?: Price['price_display_in_journeys']
  amount?: number | string
  discountAmount?: number | string
  beforeDiscountAmountToDisplay?: number | string
  inline?: boolean
  currency?: string
  size?: 'large' | 'medium' | 'small'
  hideOneTimeLabel?: boolean
  isPriceRecurringTotal?: boolean
}

export const ProductPriceItem = (props: PriceItemProps) => {
  const { actions, config } = useTileContext()
  const {
    amount,
    priceType,
    currency,
    billingPeriod,
    priceDisplayInJourneys,
    hideOneTimeLabel,
    isPriceRecurringTotal,
    discountAmount,
    beforeDiscountAmountToDisplay
  } = props

  const {
    prefix,
    formattedPrice,
    formatedPriceDiscount,
    formatedPriceBeforeDiscount
  } = getPriceDisplayText({
    t: actions.t,
    amount,
    discountAmount,
    beforeDiscountAmountToDisplay,
    priceDisplayInJourneys,
    showTrailingDecimalZeros:
      config.generalTileConfiguration.showTrailingDecimalZeros,
    currency
  })
  const displayType = getPriceType(priceType, billingPeriod)

  const isOneTimePrice = priceType === 'one_time' || !priceType
  const shouldHideLabel = isOneTimePrice && hideOneTimeLabel

  const priceClassName = isPriceRecurringTotal
    ? classes.priceRecurringTotal
    : classes.price
  const priceTag = isPriceRecurringTotal ? 'h5' : 'h3'

  return (
    <div className={classes.container}>
      <div className={classes.priceSection}>
        <Typography as={priceTag} className={priceClassName}>
          {prefix && <span>{prefix} </span>}
          {formattedPrice !== undefined && <span>{formattedPrice}</span>}
        </Typography>

        <Typography as="p" className={classes.prices}>
          {formatedPriceBeforeDiscount !== undefined && (
            <span className={classes.priceBeforeDiscount}>
              {formatedPriceBeforeDiscount}
            </span>
          )}
          {formatedPriceDiscount !== undefined && (
            <span className={classes.discount}>-{formatedPriceDiscount}</span>
          )}
        </Typography>
      </div>
      {priceDisplayInJourneys !== 'show_as_on_request' && !shouldHideLabel && (
        <Typography as="h6" className={priceClassName}>
          {actions.t(displayType)}
        </Typography>
      )}
    </div>
  )
}
