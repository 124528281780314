import type { TypographyProps } from '@epilot/journey-elements'
import { Hidden, Typography } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import type { ControlElement } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { stringToHTML, useJourneyContext, useStepBlockId } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

import { useLabelRendererStyles } from './styles'

export type LabelRendererOptions = TypographyProps

// Paragraph block renderer
const LabelRenderer = ({ uischema, visible, path }: EpilotControlProps) => {
  const stepBlockId = useStepBlockId(path)
  const { context } = useJourneyContext()
  const { t } = useTranslation()
  const classes = useLabelRendererStyles()
  const element = uischema as ControlElement & { text: string }
  const { display: _d, ...options } = (uischema?.options as any) || {}
  const { color: colorProp, variant } = (options as LabelRendererOptions) || {}

  // determine color. If passed via prop, use it.
  // If not, check if variant is a heading and use textPrimary. Else use default
  const color = colorProp
    ? colorProp
    : variant && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)
      ? 'textPrimary'
      : undefined

  const processedString = useMemo(
    () =>
      element?.text
        ? stringToHTML(
            element.text,
            { allowParagraphs: true },
            context._stepsStateArray,
            context._journeySources,
            t
          )
        : '',
    [element?.text, context._stepsStateArray, context._journeySources]
  )

  return (
    <Hidden xsUp={!visible}>
      <Typography
        className={classes.container}
        color={color}
        id={stepBlockId}
        variant={variant}
      >
        {processedString}
      </Typography>
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: LabelRenderer }) as never
)
