import { useCallback, useState } from 'react'

import { JOURNEY_LOADING_EVENT } from '../../../utils/constants/EventConstant'
import type {
  EventDetailType,
  JourneyLoadingEventPayload
} from '../../../utils/constants/types'
import { useListener } from '../../../utils/hooks/useListener'

export const useLoadingListener = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const loadingListener = useCallback(
    ({
      detail: { payload }
    }: CustomEvent<EventDetailType<JourneyLoadingEventPayload>>) =>
      setLoading(payload.value),
    []
  )

  useListener<JourneyLoadingEventPayload>(
    JOURNEY_LOADING_EVENT,
    loadingListener
  )

  return loading
}
