import { uniq } from 'lodash'
import { useEffect, useState } from 'react'

import type { EntityLookupControlData } from '..'
import { useJourneyContext } from '../../../../utils'
import type {
  CustomerPortalClient,
  EntitySlug
} from '../../../../utils/clients/customer-portal-client'
import type {
  BaseEntity,
  EntityClient
} from '../../../../utils/clients/entity-client'
import { useEntityClient } from '../../../../utils/clients/entity-client'

type setEntitiesProps = {
  slug?: string
  relatedLookupBlock?: string
  relatedLookupAttribute?: string
  relatedContextEntity?: string
  relatedContextAttribute?: string
  tableAttributes?: {
    name: string
    label: string
    id?: string
  }[]
}

const useSetEntities = (props: setEntitiesProps) => {
  const [entities, setEntities] = useState<BaseEntity[]>([])
  const { context } = useJourneyContext()
  const { client, isPortal } = useEntityClient()

  useEffect(() => {
    if (props.relatedLookupBlock && props.relatedLookupAttribute) {
      const [stepIndex, blockName] = props.relatedLookupBlock?.split('/') || []
      const lookupData = context._stepsHistoryStateArray[+stepIndex]?.[
        blockName
      ] as EntityLookupControlData
      const value = lookupData?.entity?.[props.relatedLookupAttribute]

      if (value) {
        setEntities(value)
      }
    } else if (props.relatedContextEntity && props.relatedContextAttribute) {
      const key = props.relatedContextEntity.toLowerCase()
      const lookupData = {
        entity: context._contextEntitiesData?.[key]
      } as EntityLookupControlData
      const value = lookupData?.entity?.[props.relatedContextAttribute]

      if (value) {
        setEntities(value)
      }
    } else if (props.slug && props.tableAttributes) {
      if (isPortal) {
        ;(client as CustomerPortalClient)
          .searchPortalUserEntities(null, {
            slug: props.slug as EntitySlug,
            sort: '_created_at:asc',
            // this may or may not be a good idea
            fields: uniq([
              '_id',
              ...props.tableAttributes.map((attr) => attr.name),
              ...(props.slug === 'contract' ? ['customer'] : [])
            ]),
            hydrate: true
          })
          .then((res) => {
            const results = res?.data?.results || []

            setEntities(results)
          })
      } else {
        ;(client as EntityClient)
          .searchEntities(null, {
            q: `_schema:${props.slug}`,
            // this may or may not be a good idea
            fields: uniq([
              '_id',
              ...props.tableAttributes.map((attr) => attr.name),
              ...(props.slug === 'contract' ? ['customer'] : [])
            ]),
            size: 20,
            sort: '_created_at:asc',
            hydrate: true
          } as never)
          .then((res) => {
            const results = res?.data?.results || []

            setEntities(results)
          })
      }
    }
  }, [
    props.tableAttributes,
    props.relatedLookupBlock,
    props.relatedLookupAttribute,
    props.relatedContextEntity,
    props.relatedContextAttribute,
    props.slug,
    client,
    isPortal,
    context._stepsHistoryStateArray
  ])

  return [entities]
}

export default useSetEntities
