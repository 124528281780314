import { Card, CardContent, clsx } from '@epilot/journey-elements'
import React from 'react'

import { Image } from '../Image'

import { useStyles } from './styles'

interface ImageButtonProps {
  children: React.ReactNode
  onClick: () => void
  checked: boolean
  imageType: string | undefined
  imageURL: string
  unsetFlexBasis?: boolean
  id?: string
}
export const ImageButton = ({
  children,
  onClick,
  checked,
  imageType,
  imageURL,
  unsetFlexBasis,
  id
}: ImageButtonProps) => {
  const style = useStyles()

  return (
    <Card
      className={clsx(
        style.button,
        checked && style.buttonActive,
        imageType !== 'infographic' && style.buttonIcon,
        unsetFlexBasis && style.unsetFlexBasis
      )}
      id={id}
      onClick={onClick}
      variant="outlined"
    >
      <CardContent className={style.cardContent}>{children}</CardContent>
      <CardContent
        className={
          imageType !== 'infographic'
            ? style.iconContainer
            : style.infographicContainer
        }
      >
        {imageURL && (
          <Image
            alt="label"
            className={
              imageType !== 'infographic'
                ? style.iconImage
                : style.infographicImage
            }
            src={imageURL}
          />
        )}
      </CardContent>
    </Card>
  )
}
