import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker as MUIDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'
import type { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker'
import type { KeyboardDateTimePickerProps } from '@material-ui/pickers/DateTimePicker'

import type { DatePickerProps } from '..'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  makeStyles,
  createStyles
} from '..'

export { MuiPickersUtilsProvider }
export { KeyboardTimePicker }
export { KeyboardDatePicker }
export { KeyboardDatePickerProps }
export { KeyboardDateTimePickerProps }
export { KeyboardDateTimePicker }

export { DateFnsUtils }
const useClasses = makeStyles(() =>
  createStyles({ root: { lineHeight: '1.66' } })
)

export function DatePicker(props: DatePickerProps) {
  const {
    disabled,
    error,
    fullWidth,
    helperText,
    id = 'no-id',
    label,
    required,
    containerStyle,
    value,
    size = 'medium',
    format = 'dd.MM.yyyy',
    onChange,
    shouldDisableDate,
    customBackgroundColor,
    ...rest
  } = props

  const htmlId = id ? id.replace(/^#\/properties\//, '') : undefined
  const classes = useClasses()

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      style={containerStyle}
    >
      {(label || required) && (
        <InputLabel
          classes={{ root: classes.root }}
          htmlFor={`field-${id}`}
          shrink
        >
          {label}
          {required ? ' *' : ''}
        </InputLabel>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MUIDatePicker
          TextFieldComponent={(props) => (
            <TextField
              customBackgroundColor={customBackgroundColor}
              disabled={disabled}
              error={!!error}
              fullWidth={fullWidth}
              id={htmlId}
              label={label || undefined}
              onChange={props.onChange}
              onClick={props.onClick}
              required={required}
              size={size}
              value={props.value}
            />
          )}
          {...rest}
          error={error}
          format={format}
          fullWidth={fullWidth}
          id={htmlId && `field-${htmlId}`}
          name={htmlId}
          onChange={onChange}
          shouldDisableDate={shouldDisableDate || undefined}
          size={size}
          value={value}
        />
      </MuiPickersUtilsProvider>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
