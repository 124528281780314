import { datadogRum } from '@datadog/browser-rum'
import { H5, LargeText, Typography, clsx } from '@epilot/journey-elements'
import type { StepState, StepExtended } from '@epilot/journey-logic-commons'
import { once } from '@epilot/journey-logic-commons'
import type { JsonFormsOnChange } from '@epilot/json-renderers'
import { useAdditionalErrorsFromState } from '@epilot/json-renderers'
import type {
  CoreActions,
  JsonFormsCore,
  ValidationMode
} from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TIME_TO_FIRST_RENDER_METRIC } from '../utils/config'
import type { initAJV } from '../utils/initAJV'
import { allRenderers } from '../utils/renderers'
import { IsMobile } from '../utils/tools'

import { useWrapperStyles } from './JourneyPage/styles'
import classes from './styles/StepComponent.module.scss'

/**
 * Ensures the underlying metric is stopped only once.
 */
export const endTimeToFirstRender = once(() => {
  datadogRum.stopDurationVital(TIME_TO_FIRST_RENDER_METRIC)
})

export type StepComponentProps = {
  /**
   * @todo Consider accessing this property from context in component instead of having it drilled
   */
  validationMode: ValidationMode
  step: StepExtended
  data: StepState
  onChange: JsonFormsOnChange
  ajv: ReturnType<typeof initAJV>
  debug?: boolean
}

const RequiredLabel = () => {
  const { t } = useTranslation()

  return (
    <Typography
      className={classes.label}
      color="textSecondary"
      variant="caption"
    >
      {'* ' + t('input_required', 'Pflichteingabe')}
    </Typography>
  )
}

const errorMiddleware = (
  state: JsonFormsCore,
  action: CoreActions,
  defaultReducer: (state: JsonFormsCore, action: CoreActions) => JsonFormsCore
) => {
  const { data, errors } = state

  if (errors && errors.length) {
    // eslint-disable-next-line no-console
    console.debug('Detected JSON Forms errors', { errors, data })
  }

  return defaultReducer(state, action)
}

export const StepComponent = ({
  data,
  debug,
  step,
  validationMode,
  onChange,
  ajv
}: StepComponentProps) => {
  const additionalErrors = useAdditionalErrorsFromState(
    data,
    validationMode,
    step
  )
  const mobile = IsMobile()
  const { wrapperStyles } = useWrapperStyles()

  const hasRequiredField = Boolean(
    step.schema?.required && step.schema.required.length > 0
  )

  /**
   * Marks the end of the first render.
   * NOTE: This should only be called once, after the component is mounted the first time.
   */
  useEffect(() => void endTimeToFirstRender(), [])

  return (
    <>
      <div className={wrapperStyles}>
        <div
          className={
            step.showStepName || step.showStepSubtitle
              ? classes.titleContainer
              : ''
          }
        >
          <div
            className={clsx(
              classes.labelContainer,
              step.showStepName ? classes.spaceBetween : classes.flexEnd
            )}
          >
            {step.showStepName && (
              <div className={mobile ? '' : classes.desktopStepNameContainer}>
                <H5 color="textPrimary" gutterBottom={!!step.showStepSubtitle}>
                  <b
                    className={
                      mobile ? classes.mobileStepName : classes.desktopStepName
                    }
                  >
                    {step.title === undefined ? step.name : step.title}
                  </b>
                </H5>
              </div>
            )}
            {!mobile && hasRequiredField && <RequiredLabel />}
          </div>

          {step.showStepSubtitle && (
            <div
              className={
                mobile
                  ? classes.mobileSubtitleContainer
                  : classes.desktopSubtitleContainer
              }
            >
              <LargeText>
                <span
                  className={
                    mobile ? classes.mobileSubtitle : classes.desktopSubtitle
                  }
                >
                  {step.subTitle}
                </span>
              </LargeText>
            </div>
          )}
        </div>
        {mobile && hasRequiredField && (
          <div className={classes.requiredLabelContainer}>
            <RequiredLabel />
          </div>
        )}
      </div>
      <JsonForms
        additionalErrors={additionalErrors}
        ajv={ajv}
        data={data}
        middleware={debug ? errorMiddleware : undefined}
        onChange={onChange}
        renderers={allRenderers}
        schema={step.schema}
        uischema={step.uischema}
        validationMode={validationMode}
      />
    </>
  )
}

StepComponent.displayName = 'StepComponent'
