import { makeStyles } from '../'

export const useBooleanControlStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    display: 'flex',
    gap: 4
  }
})
