import {
  Typography,
  FormHelperText,
  clsx,
  Checkbox
} from '@epilot/journey-elements'
import {
  getOptimizedImageURL,
  appendStepBlockId
} from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { IconLabel } from '../../../../components/IconLabel'
import { ImageButton } from '../../../../components/ImageButton/ImageButton'
import { useEnumButtonStyles } from '../../../../renderers/controls/EnumButtonControl/styles/useEnumButtonStyles'
import { useConfig } from '../../../../utils'
import type { MultichoiceControlComponentProps } from '../types'

const OPTIMAL_IMAGE_WIDTH = '400'
const OPTIMAL_ICON_WIDTH = '120'

export function MultichoiceImageButton({
  options = [],
  value = [],
  onChange,
  optionsLabels = [],
  optionsImages = [],
  errorMessage,
  label,
  required,
  imageType,
  maxSelection,
  optionsIcons = [],
  id
}: MultichoiceControlComponentProps) {
  const { t } = useTranslation()
  const buttonStyles = useEnumButtonStyles()
  const { IMAGE_PREVIEW_API_URL } = useConfig()

  const addToValue = (option: string) => {
    onChange(Array.isArray(value) ? [...value, option] : [option])
  }

  const removeFromValue = (option: string) => {
    const optionsIndex = Array.isArray(value)
      ? value.findIndex((op) => op === option)
      : -1

    if (optionsIndex > -1) {
      const newOptions = [...value]

      newOptions.splice(optionsIndex, 1)
      onChange(newOptions)
    }
  }

  const handleChange = (checked: boolean, option: string) =>
    checked ? addToValue(option) : removeFromValue(option)

  // Kind of "dirty" trick to force buttons to use the minimal amount
  // of space when "alone" on a row. Looking for better solution.
  const dummyDiv = <div className={clsx(buttonStyles.dummyDiv)} />

  const hasImages = Array.isArray(optionsImages) && optionsImages.length > 0

  // maxSelection as undefined or =0 means unlimited
  const disableSelection =
    maxSelection && Array.isArray(value) && value.length === maxSelection
      ? true
      : false

  return (
    <div className={label ? '' : buttonStyles.containerWithoutLabel}>
      <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
        {`${label}${required ? '*' : ''}`}
      </Typography>

      <div
        className={clsx(
          buttonStyles.buttonImageWrap,
          imageType !== 'infographic' && buttonStyles.noWrapImageContainer
        )}
      >
        {options.map((option: string, index: number) => {
          const optionLabel = optionsLabels[index]
            ? optionsLabels[index]
            : option

          const fieldId = appendStepBlockId(id, optionLabel)
          const optionImage =
            hasImages && optionsImages[index] && optionsImages[index].url
              ? getOptimizedImageURL(
                  IMAGE_PREVIEW_API_URL,
                  optionsImages[index].url,
                  optionsImages[index].id,
                  imageType === 'icon'
                    ? OPTIMAL_ICON_WIDTH
                    : OPTIMAL_IMAGE_WIDTH
                )
              : 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png'

          const checked = Array.isArray(value) && value.includes(option)

          const disableChoice = disableSelection && !checked ? true : false

          const iconConfig = optionsIcons[index]

          return (
            <ImageButton
              checked={checked}
              imageType={imageType}
              imageURL={optionImage}
              key={option}
              onClick={() => handleChange(!checked, option)}
            >
              <Checkbox
                checked={checked}
                color="primary"
                disabled={disableChoice}
                id={fieldId}
              />
              <IconLabel iconConfig={iconConfig} label={optionLabel} />
            </ImageButton>
          )
        })}
        {imageType === 'infographic' && (
          <>
            {dummyDiv}
            {dummyDiv}
          </>
        )}
      </div>
      <FormHelperText error={errorMessage ? true : false}>
        {errorMessage
          ? t('Please select one of the options')
          : disableSelection
            ? t('max_selection_reached')
            : ''}
      </FormHelperText>
    </div>
  )
}
