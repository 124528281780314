import { LargeText, useTheme } from '@epilot/journey-elements'
import Frame from '@epilot360/icons/react/Frame'
import Sun from '@epilot360/icons/react/Sun'
import { useTranslation } from 'react-i18next'

import type { SolarPotential } from '../types'
import { getFormattedLocaleNumber } from '../utils'

import { useWidgetStyles } from './styles'
import { WidgetContainer } from './WidgetContainer'

export const RooftopDataWidget = ({
  solarPotential
}: {
  solarPotential: SolarPotential | null
}) => {
  const classes = useWidgetStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <WidgetContainer>
      <LargeText className={classes.title}>
        {t('rooftop_planner.rooftop_title')}
      </LargeText>
      <div className={classes.line}>
        <div className={classes.textContainer}>
          <Sun fill={theme.palette.primary.main} />
          <span>{t('rooftop_planner.sunshine_hours')}</span>
        </div>
        <span className={classes.dataCell}>
          {solarPotential && solarPotential.maxSunshineHoursPerYear
            ? getFormattedLocaleNumber(solarPotential.maxSunshineHoursPerYear)
            : '-'}
        </span>
      </div>
      <div className={classes.line}>
        <div className={classes.textContainer}>
          <Frame fill={theme.palette.primary.main} />
          <span>{t('rooftop_planner.rooftop_surface')}</span>
        </div>
        <span className={classes.dataCell}>
          {solarPotential && solarPotential.maxArrayAreaMeters2
            ? getFormattedLocaleNumber(solarPotential.maxArrayAreaMeters2)
            : '-'}
        </span>
      </div>
    </WidgetContainer>
  )
}
