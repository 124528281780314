import { appendStepBlockId } from '@epilot/journey-logic-commons'

import { Button } from '../../../../components'
import classes from '../../styles/ProductAction.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

interface ProductActionProps {
  onSelect?: () => void
  onClick?: () => void
}

export const ProductAction = ({ onSelect }: ProductActionProps) => {
  const { config, actions, product, price, tileState, id } = useTileContext()
  const {
    isSelected,
    generalTileConfiguration: { ctaConfig }
  } = config
  const { label, undoActionLabel, disabled } = ctaConfig
  const ctaLabel = actions.t(isSelected ? undoActionLabel : label)

  const handleOnClick: ProductActionProps['onClick'] = () => {
    if (isSelected) {
      actions.deselectProductTile({
        productId: product._id,
        priceId: price._id
      })
    } else {
      actions.selectProductTile({
        product: {
          selectedProductId: product._id,
          selectedPriceId: price._id,
          selectionMetadata: {
            selectedProduct: product,
            selectedPrice: price,
            blockConfiguration: {
              isRequired: Boolean(
                config.generalTileConfiguration?.isBlockRequired
              ),
              showQuantity: Boolean(
                config.generalTileConfiguration?.quantityConfig.show
              ),
              blockPath: config.generalTileConfiguration.blockPath || ''
            }
          }
        },
        quantity: tileState.quantity
      })
      onSelect?.()
    }
  }

  const buttonId = appendStepBlockId(id, 'Add To Cart Button')

  return (
    <Button
      className={classes.button}
      id={buttonId}
      isDisabled={disabled}
      label={ctaLabel}
      onClick={handleOnClick}
      tabIndex={0}
      variant={isSelected ? 'ghost' : 'primary'}
    />
  )
}
