import { TextField as MuiTextField } from '@material-ui/core'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import { useTextFieldStyles } from './styles'
import type { TextFieldProps } from './types'

export const TextField = forwardRef(
  (
    { InputProps, customBackgroundColor, ...props }: TextFieldProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = useTextFieldStyles({ customBackgroundColor, ...props })

    return (
      <MuiTextField
        InputProps={{ classes, ...InputProps }}
        ref={ref}
        {...props}
      />
    )
  }
)
