import type { TypographyVariant } from '@material-ui/core'

import type { CheckboxProps } from '../'
import { List, ListItem, ListItemPrefix, Icon, ListItemContent } from '../'

import { BooleanCheckboxControl } from './BooleanCheckboxControl'
import { BooleanSwitchControl } from './BooleanSwitchControl'
import type { EpilotCommonControlProps } from './types'

export type BooleanControlProps = EpilotCommonControlProps & {
  /**
   * the type of the displayed enum
   * @default 'checkbox'
   */
  uiType?: 'checkbox' | 'switch'

  /**
   * color of the inputs
   * @default "primary"
   */
  color?: 'primary' | 'secondary' | 'default'

  /**
   * label fontSize
   * @default "body1"
   */
  typographyVariant?: TypographyVariant

  /**
   * if true, the required * (asterisk) will be hide
   * @default false
   */
  hideRequiredAsterisk?: boolean

  /**
   * size of the control
   */
  size?: CheckboxProps['size']

  /**
   * id of the control
   */
  id?: CheckboxProps['id']
}

export function BooleanControl({
  uiType,
  displayMode = 'input',
  ...props
}: BooleanControlProps) {
  if (displayMode === 'display') {
    return <BooleanControlDisplayMode {...props} />
  }
  switch (uiType) {
    case 'switch':
      return <BooleanSwitchControl {...props} />
    default:
      return <BooleanCheckboxControl {...props} />
  }
}

function BooleanControlDisplayMode({ label, value, id }: BooleanControlProps) {
  return (
    <List id={id}>
      <ListItem>
        <ListItemPrefix>
          <Icon name={value ? 'check' : 'close'} />
        </ListItemPrefix>
        <ListItemContent primary={label} />
      </ListItem>
    </List>
  )
}
