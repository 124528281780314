import { Image } from '@epilot/concorde-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { File } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'

export type ImageFile = File & {
  id: string
  src: string
}
interface ProductImageProps {
  image: ImageFile
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      height: 'auto',
      width: '100%',
      objectFit: 'cover',
      aspectRatio: '3/2'
    }
  })
)

export const OPTIMAL_IMAGE_WIDTH = '350'

export const ProductImage = ({ image }: ProductImageProps) => {
  const classes = useStyles()
  const { useNewDesign } = useTileContext()

  const ImageComponent = useNewDesign ? Image : 'img'

  return (
    <ImageComponent
      alt={image.filename}
      className={classes.image}
      src={image.src}
    />
  )
}
