import { useEffect } from 'react'
import type {
  UseFormResetField,
  Path,
  UnpackNestedValue,
  PathValue,
  FieldValues
} from 'react-hook-form'

import { matchesValue } from '../../helper'

type UseResetOnNotEqualParams<T> = {
  name: Path<T>
  watch: { name: Path<T>; value: UnpackNestedValue<PathValue<T, Path<T>>> }
  value: UnpackNestedValue<PathValue<T, Path<T>>>
  match: Record<string, UnpackNestedValue<PathValue<T, Path<T>>>> | undefined
}
/**
 * Resets a field by given name + value if the watched fields value does not match
 * @param resetField function from react-hook-form
 */

export const useResetOnNotEqual = <T extends FieldValues>(
  resetField: UseFormResetField<T> | undefined,
  params: UseResetOnNotEqualParams<T>
) => {
  const { name, watch, value, match } = params

  useEffect(() => {
    if (!resetField) {
      return
    }

    if (match && !matchesValue(match, { [watch.name]: watch.value })) {
      resetField(name, {
        defaultValue: value
      })
    }
  }, [watch.value])
}
