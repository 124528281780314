import { Button as ConcordeButton, Icon } from '@epilot/concorde-elements'
import { Button } from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import LaunchIcon from '@material-ui/icons/Launch'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTileContext } from '../../../renderers/controls/ProductSelectionControl/utils/tile-context-utils'

import { ProductDetailsModal } from './components/ProductDetailsModal'
import { useProductDetailsStyles } from './components/styles'

export const ProductDetails = () => {
  const { useNewDesign, id } = useTileContext()
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const classes = useProductDetailsStyles()
  const { t } = useTranslation()

  function toggleDetailsModal() {
    setShowDetailsModal((showDetailsModal) => !showDetailsModal)
  }

  const detailsButtonId = appendStepBlockId(id, 'Product Details Button')

  return (
    <>
      {useNewDesign ? (
        <ConcordeButton
          className={classes.newDetailsButton}
          id={detailsButtonId}
          label={t('product_details.toggle_modal', 'More Details')}
          onClick={toggleDetailsModal}
          rightIcon={<Icon name="expand_content" />}
          variant="ghost"
        />
      ) : (
        <Button
          className={classes.detailsButton}
          fullWidth
          id={detailsButtonId}
          onClick={toggleDetailsModal}
          size="large"
        >
          {t('product_details.toggle_modal', 'More Details')}
          <LaunchIcon className={classes.icon} fontSize="small" />
        </Button>
      )}
      <ProductDetailsModal
        isOpen={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
      />
    </>
  )
}
