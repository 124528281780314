import {
  Grid,
  TextField,
  InputAdornment,
  Typography
} from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import type {
  MeterCounterEntity,
  MeterReadingValidation
} from '../../../utils/clients/entity-client'

import type { CounterReading } from './types'

type CounterReadingFormProps = {
  counterEntity: MeterCounterEntity
  value?: CounterReading
  handleChange: (value: CounterReading) => void
  hasError?: boolean
  oldReading?: MeterReadingValidation
  id: string
}

export function CounterReadingForm({
  value,
  handleChange,
  counterEntity,
  hasError,
  oldReading,
  id
}: CounterReadingFormProps) {
  const hasReadingError = Boolean(
    hasError &&
      value?.value &&
      oldReading &&
      oldReading.min_value &&
      value.value < oldReading.min_value
  )

  const { t } = useTranslation()

  const fieldLabel = `${
    counterEntity.direction === 'feed-in'
      ? t('reading_value_feed_in')
      : t('reading_value_feed_out')
  }${
    counterEntity?.tariff_type?.toLowerCase() === 'nt'
      ? ' - ' + t('reading_value_nt')
      : counterEntity?.tariff_type?.toLowerCase() === 'ht'
        ? ' - ' + t('reading_value_ht')
        : ''
  }`

  const fieldId = appendStepBlockId(
    id,
    `${
      counterEntity.direction === 'feed-in'
        ? 'Reading Value Feed In'
        : 'Reading Value Feed Out'
    }${
      counterEntity?.tariff_type?.toLowerCase() === 'nt'
        ? ' - NT Counter'
        : counterEntity?.tariff_type?.toLowerCase() === 'ht'
          ? ' - HT Counter'
          : ''
    }`
  )

  return (
    <>
      <Grid item sm={6} xs={12}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {counterEntity.unit && (
                  <Typography variant="h6">
                    {
                      // this is complex because not all units labels are pre defined by epilot
                      t(`units.${counterEntity.unit}`, counterEntity.unit)
                    }
                  </Typography>
                )}
              </InputAdornment>
            )
          }}
          error={(hasError && !value?.value) || hasReadingError}
          fullWidth
          helperText={
            (hasReadingError &&
              t(
                'meter_reading_error',
                'New reading can not be less than the old reading'
              )) ||
            (hasError && !value?.value && t('field_required'))
          }
          id={fieldId}
          label={fieldLabel}
          onChange={(e) =>
            handleChange({
              value: parseInt(e.target.value),
              unit: counterEntity.unit,
              counterId: counterEntity._id,
              direction: counterEntity.direction,
              _label: fieldLabel
            })
          }
          placeholder={
            oldReading?.min_value
              ? `${t(
                  'forexample_short',
                  'z.B.'
                )} ${oldReading.min_value.toString()}`
              : ''
          }
          required={true}
          type="number"
          value={value?.value}
        />
      </Grid>
    </>
  )
}
