import type { StepExtended, StepState } from '@epilot/journey-logic-commons'
import { isBlockValid } from '@epilot/journey-logic-commons'
import type { ValidationMode } from '@jsonforms/core'
import type { ErrorObject } from 'ajv'
import { useMemo } from 'react'

export const useAdditionalErrorsFromState = (
  state: StepState,
  validationMode: ValidationMode,
  step: StepExtended
) => {
  const additionalErrors = useMemo(() => {
    if (validationMode === 'ValidateAndShow' && typeof state === 'object') {
      return Object.keys(state)
        .filter((blockName) => !isBlockValid(step, state, blockName))
        .map<ErrorObject>((blockName) => ({
          instancePath: `/${blockName}`,
          message: `${blockName} is invalid`,
          dataPath: '',
          schemaPath: '',
          keyword: '',
          params: {}
        }))
    }

    return []
  }, [validationMode, state, step])

  return additionalErrors
}
