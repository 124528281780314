import type { IconConfig } from '@epilot/journey-logic-commons'
import { makeStyles } from '@material-ui/core'

import { MaterialSymbol } from './MaterialSymbol'

type IconLabelProps = {
  label: string
  iconConfig?: IconConfig
}

const useStyles = makeStyles(() => ({
  text: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8
  }
}))

export function IconLabel({ label, iconConfig }: IconLabelProps) {
  const styles = useStyles()
  const Icon =
    iconConfig && iconConfig !== null && iconConfig?.name ? (
      <MaterialSymbol name={iconConfig.name} settings={iconConfig.settings} />
    ) : (
      <></>
    )

  return (
    <div className={styles.text}>
      {Icon}
      {label}
    </div>
  )
}
