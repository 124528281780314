import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useProductDetailsStyles = makeStyles<EpilotTheme>((theme) =>
  createStyles({
    icon: {
      color: theme.palette?.primary?.main,
      marginLeft: 8
    },
    detailsButton: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: `min(${theme.shape?.borderRadius}px, 20px)`,
      borderBottomRightRadius: `min(${theme.shape?.borderRadius}px, 20px)`,
      backgroundColor: theme.palette?.grey?.[20],
      color: theme.palette?.text?.primary,
      fontSize: 14,
      '& > span': { maxWidth: 'fit-content' },
      '&:hover': {
        backgroundColor: theme.palette?.grey?.[20]
      }
    },
    newDetailsButton: {
      margin: '8px auto',
      width: 'min(200px, 100%)'
    }
  })
)

export const useProductDetailsModalStyles = makeStyles<Theme & EpilotTheme>(
  (theme) =>
    createStyles({
      dialogContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4)
      },
      container: {
        display: 'flex',
        gap: theme.spacing(6),
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row'
        }
      },
      imageContainer: {
        flex: 1,
        display: 'grid'
      },
      heading: {
        marginBottom: theme.spacing(4)
      },
      paragraphContainer: {
        marginBottom: theme.spacing(4)
      },
      actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 16,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row'
        },
        ['& > *']: {
          width: '100%',
          [theme.breakpoints.up('sm')]: {
            width: 'auto',
            minWidth: '30%'
          }
        }
      },
      detailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          '& > div': {
            flex: 1
          }
        }
      },
      detailsContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4)
      },
      detailsContentNoInformation: {
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          '& > div': {
            flex: 1
          }
        }
      },
      descriptionContainer: {
        flex: 2
      },
      paper: {
        borderRadius:
          theme.shape?.borderRadius !== undefined
            ? `min(${theme.shape.borderRadius}px, 20px)`
            : '4px'
      }
    })
)
