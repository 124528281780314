import { Autocomplete, CircularProgress } from '@epilot/journey-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IsMobile } from '../../utils/helper'
import { usePrevious } from '../../utils/hooks/usePrevious'

import { useFieldAutocompleteStyles } from './styles'
import type { FieldAutocompleteProps } from './types'

export const FieldAutocomplete = <
  T extends Record<string, unknown>, // fields value type
  O extends string | Record<string, unknown> = string, // options
  S extends string | Record<string, unknown> = string // suggestions
>(
  props: FieldAutocompleteProps<T, O, S>
) => {
  const {
    hasError,
    control,
    name,
    label,
    placeholder,
    errorMessage,
    defaultValue,
    required,
    disabled,
    rules,
    rulesCustomErrorMessages,
    acceptSuggestedOnly,
    noOptionsText,
    options,
    path,
    minChars = 2,
    loading,
    onBlur,
    getSuggestions,
    renderOption,
    onChange,
    onInputChange,
    getOptionLabel,
    getOptionSelected,
    freeSolo,
    keepAutoCompleteClosed = false
  } = props

  const { t } = useTranslation()
  const classes = useFieldAutocompleteStyles()
  const id = props.id || `${toDashCase(path)}-${name}`

  const isMobile = IsMobile()

  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)
  const inputValuePrev = usePrevious(inputValue)

  useEffect(() => {
    // getSuggestions would be rebuild if related values change, which should not trigger the call
    if (inputValue !== inputValuePrev) {
      getSuggestions(inputValue)
    }
  }, [inputValue, inputValuePrev, getSuggestions])

  return (
    <Controller<T>
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        const fieldError = fieldState.error

        return (
          <Autocomplete
            {...field}
            ListboxProps={{
              class: isMobile ? classes.listBoxMobile : classes.listBoxDesktop
            }}
            disableOpenOnFocus={!inputValue || inputValue.length > minChars}
            disabled={disabled}
            error={
              hasError && fieldError
                ? acceptSuggestedOnly && !field.value && inputValue
                  ? t('no_selected_suggestion')
                  : errorMessage ||
                    fieldError?.message ||
                    rulesCustomErrorMessages?.[fieldError?.type || ''] ||
                    t('field_required')
                : ''
            }
            flipPopperEnabled
            freeSolo={freeSolo ?? !acceptSuggestedOnly}
            fullWidth
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            id={id}
            inputValue={inputValue}
            label={label}
            loading={loading}
            loadingText={
              <div className={classes.loadingContainer}>
                <CircularProgress color="primary" size="16px" />
              </div>
            }
            multiple={false}
            noOptionsText={noOptionsText || t('no_options')}
            onBlur={() => {
              if (onBlur) {
                onBlur(options, field, setInputValue)

                return
              }

              if (options && options.length === 1) {
                field.onChange(options[0])
              } else if (acceptSuggestedOnly && !field.value) {
                setInputValue('')
              }
            }}
            // select first option if blurring out
            onChange={(_: unknown, data: any) => {
              if (onChange) {
                onChange(data, field)

                return
              }

              if (typeof data === 'string') {
                return field.onChange(data)
              } else {
                return field.onChange('')
              }
            }}
            // when selecting option
            onClose={() => setOpen(false)}
            onInputChange={(_, value) => {
              // Make sure to not open the autocomplete when initializing the field
              if (inputValuePrev !== undefined) {
                setOpen(value.length > minChars)
              }

              // if the GoogleMap feature is on, close the autocomplete and set the value
              if (
                value.length > minChars + 3 &&
                keepAutoCompleteClosed &&
                options.length < 2
              ) {
                setOpen(false)
                options.length === 1
                  ? field.onChange(options[0])
                  : field.onChange(value)
              }

              if (onInputChange) {
                onInputChange(value, field, setInputValue)

                return
              }

              field.onChange(
                !acceptSuggestedOnly ? value : field.value ? field.value : null
              ) // keep state empty until selecting value or having it initially. Still update it to go through validation

              setInputValue(value)
            }}
            // when typing
            onOpen={() => {
              if (inputValue.length > minChars) {
                setOpen(true)
              }
            }}
            open={open}
            options={options}
            placeholder={placeholder}
            renderOption={renderOption}
            textfieldProps={{
              ...(disabled && {
                InputProps: {
                  classes: {
                    disabled: classes.disabled
                  }
                }
              }),
              InputLabelProps: { htmlFor: id }
            }}
            value={typeof field.value === 'string' ? field.value : ''}
          />
        )
      }}
      rules={
        {
          required: !!required,
          validate: {
            noSelectedSuggestion: (value: unknown) => {
              // dont validate further if no value. Other, internal rules still validate
              if (acceptSuggestedOnly && !value && inputValue) {
                return false
              }

              return true
            }
          },
          ...rules
        } as never
      }
    />
  )
}
