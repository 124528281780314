import { SmallText, Switch, Typography } from '../'

import type { BooleanControlProps } from './BooleanControl'
import { useBooleanControlStyles } from './styles'

export type BooleanSwitchControlProps = BooleanControlProps

export function BooleanSwitchControl({
  label,
  value,
  disabled = false,
  required,
  onChange,
  color = 'primary',
  errorMessage,
  typographyVariant = 'body1',
  ...rest
}: BooleanSwitchControlProps) {
  const classes = useBooleanControlStyles()

  return (
    <div className={classes.container}>
      <Switch
        checked={value}
        color={color}
        disabled={disabled}
        label={
          <span className={classes.label}>
            <Typography color="textSecondary" variant={typographyVariant}>
              {label}
            </Typography>
            <Typography
              color={errorMessage ? 'error' : 'textSecondary'}
              variant={typographyVariant}
            >
              {required ? '*' : ''}
            </Typography>
          </span>
        }
        labelPlacement="end"
        onChange={() => onChange(!value)}
        required={required}
        {...rest}
      />
      {errorMessage ? <SmallText color="error">{errorMessage}</SmallText> : ''}
    </div>
  )
}
