import type { TileLayoutSection } from '@epilot/journey-logic-commons'
import { Fragment } from 'react'
import type { ComponentType } from 'react'

import type {
  ComponentMappingDataGraduatedProps,
  GetAgComponentProps,
  ProductDetailsModalProps
} from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

import { TileCTA } from './TileCTA'
import { TileDescription } from './TileDescription'
import { TileDetails } from './TileDetails'
import { TileDownloads } from './TileDownloads'
import { TileFeatures } from './TileFeatures'
import { TileHeadline } from './TileHeadline'
import { TileImage } from './TileImage'
import { TilePriceComponents } from './TilePriceComponents'
import { TilePrices } from './TilePrices'

interface TileSectionsProps {
  GetAgComponent: ComponentType<GetAgComponentProps>
  ProductDetailsModalComponent: ComponentType<ProductDetailsModalProps>
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
  IMAGE_PREVIEW_API_URL: string
}

type TileVariantObject<
  T extends keyof TileSectionsProps = keyof TileSectionsProps
> = {
  component: ComponentType<Pick<TileSectionsProps, T>>
  propKeys?: T[]
}

const tileVariantSections: Record<TileLayoutSection, TileVariantObject> = {
  headline: {
    component: TileHeadline
  },
  description: {
    component: TileDescription
  },
  image: {
    component: TileImage,
    propKeys: ['IMAGE_PREVIEW_API_URL']
  },
  price: {
    component: TilePrices,
    propKeys: ['GetAgComponent']
  },
  'price-components': {
    component: TilePriceComponents,
    propKeys: ['ComponentMappingDataGraduated']
  },
  expand: {
    component: TileDetails,
    propKeys: ['ProductDetailsModalComponent']
  },
  downloads: { component: TileDownloads },
  CTA: { component: TileCTA },
  features: { component: TileFeatures }
}

export const TileSections = (props: TileSectionsProps) => {
  const {
    config: { generalTileConfiguration }
  } = useTileContext()

  const tileVariantOptions = generalTileConfiguration.tileLayoutVariant?.options

  const sortedOptions = tileVariantOptions?.sort((a, b) => a.order - b.order)

  if (!sortedOptions || !tileVariantOptions) return null

  return (
    <>
      {sortedOptions.map((option) => {
        const section = tileVariantSections[option.key]
        const sectionProps = section.propKeys?.reduce((acc, key) => {
          return {
            ...acc,
            [key]: props[key]
          }
        }, {}) as TileSectionsProps

        const Component = section.component

        if (option.isVisible === false) return null

        return (
          <Fragment key={option.key}>
            <Component {...sectionProps} />
          </Fragment>
        )
      })}
    </>
  )
}
