import { Typography } from '../../../../components'
import classes from '../../styles/ProductDescription.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

export const ProductDescription = () => {
  const { product, config, tileVariantsEnabled } = useTileContext()
  const { description } = product

  if (
    !tileVariantsEnabled &&
    !config.generalTileConfiguration.displayProductDescription
  ) {
    return null
  }

  return (
    <div className={classes.container}>
      <Typography as="p">{description}</Typography>
    </div>
  )
}
