/* eslint-disable @typescript-eslint/no-explicit-any */
import type { S3File } from '@epilot/journey-elements'
import { CONTROL_NAME } from '@epilot/journey-logic-commons'

import { toDateString } from './helper'
import { formatNumberInUserLocale } from './numberFormat'

export function getBlockDataFormat(blockData: any, blockType?: string): string {
  switch (blockType) {
    case CONTROL_NAME.PERSONAL_INFORMATION_CONTROL:
      // 2d array for collect the data for each Block Control type
      return [
        blockData?.salutation || blockData?.title,
        blockData?.firstName,
        blockData?.lastName,

        blockData?.birthDate ? toDateString(blockData.birthDate) : undefined,
        blockData?.email,
        blockData?.telephone,
        blockData?.companyName,
        blockData?.registryCourt,
        blockData?.registerNumber
      ]
        .filter(Boolean)
        .join(', ')
    case CONTROL_NAME.ADDRESS_BLOCK:
      return [
        blockData?.salutation || blockData?.title,
        blockData?.firstName,
        blockData?.lastName,
        blockData?.streetName,
        blockData?.houseNumber,
        blockData?.zipCode,
        blockData?.city,
        blockData?.addressExtention || blockData?.extention,
        blockData?.companyName
      ]
        .filter(Boolean)
        .join(', ')
    case CONTROL_NAME.AVAILABILITY_CHECK:
      return [
        blockData?.streetName,
        blockData?.streetNumber,
        blockData?.zipCode,
        blockData?.city
      ]
        .filter(Boolean)
        .join(', ')
    case CONTROL_NAME.UPLOAD_CONTROL: {
      const fileNames = (blockData as any[])?.map((file: S3File) => {
        return file.original_name
      })

      return fileNames.join(', ')
    }

    case CONTROL_NAME.DATE_PICKER: {
      const dateStringStart = blockData && toDateString(blockData?.startDate)
      const dateStringEnd = blockData && toDateString(blockData?.endDate)

      return [dateStringStart, dateStringEnd].filter(Boolean).join(' - ')
    }

    case CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL: {
      const company_name = blockData && blockData?.company_name

      return company_name
    }

    case CONTROL_NAME.CONTROL: {
      let type = (typeof blockData).toString()

      if (Array.isArray(blockData)) type = 'array'

      switch (type) {
        case 'array':
          return blockData?.join(', ')

        case 'boolean':
          return blockData ? 'Ja' : 'Nein'

        case 'object':
          return typeof blockData['_label'] === 'string' &&
            typeof blockData['value'] === 'boolean'
            ? `${blockData['_label'] || ''} -{' '}
                          ${blockData['value'] ? 'Ja' : 'Nein'}`
            : blockData

        default:
          return blockData
      }
    }

    case CONTROL_NAME.PAYMENT_CONTROL: {
      return [
        blockData?.label || blockData?.type,
        blockData?.data?.fullname,
        blockData?.data?.iban
      ]
        .filter(Boolean)
        .join(', ')
    }

    case CONTROL_NAME.METER_READING_CONTROL: {
      return [
        blockData?.label || blockData?.type,
        blockData?.maloId,
        blockData?.meterId,
        blockData?.meterType,
        toDateString(blockData?.readingDate),
        blockData?.numberInput,
        blockData?.numberUnit,
        blockData?.readBy,
        blockData?.reason
      ]
        .filter(Boolean)
        .join(', ')
    }

    case CONTROL_NAME.MULTICHOICE_CONTROL: {
      return blockData?.join(', ')
    }

    case CONTROL_NAME.NUMBER_INPUT_CONTROL: {
      return [
        blockData?.numberInput
          ? formatNumberInUserLocale(blockData.numberInput)
          : '',
        // only display the unit if a number is present
        blockData?.numberInput ? blockData.numberUnit : ''
      ]
        .filter(Boolean)
        .join(' ')
    }

    case CONTROL_NAME.CONSENTS_CONTROL: {
      if (blockData) {
        const keys = Object.keys(blockData)

        return keys
          .map((k) => `${k} - ${blockData[k].agreed ? 'Ja' : 'Nein'}`)
          .join(', ')
      } else {
        return ''
      }
    }

    case CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL: {
      // TODO
      return ''
    }

    case CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL: {
      return blockData?.newValue
        ? typeof blockData?.newValue === 'string'
          ? blockData?.newValue
          : JSON.stringify(blockData.newValue)
        : ''
    }

    default:
      if (
        blockData &&
        typeof blockData === 'object' &&
        '_summary' in blockData
      ) {
        return blockData._summary
      }

      return blockData
  }
}
