import * as AddressSuggestionsService from './address-suggestions-service'
import * as PricingService from './pricing-service'

export interface ServicesInitConfig {
  pricingServiceUrl?: string
  addressSuggestionsServiceUrl?: string
  orgId: string
  publicToken: string
}

export function initServices({
  pricingServiceUrl,
  addressSuggestionsServiceUrl,
  orgId,
  publicToken
}: ServicesInitConfig) {
  if (!pricingServiceUrl && !addressSuggestionsServiceUrl) {
    throw new Error(
      'At least one service URL should be provided to init the services'
    )
  }

  if (pricingServiceUrl) {
    PricingService.initClient(pricingServiceUrl, orgId, publicToken)
  }

  if (addressSuggestionsServiceUrl) {
    AddressSuggestionsService.initClient(
      addressSuggestionsServiceUrl,
      orgId,
      publicToken
    )
  }
}
