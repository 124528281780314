import { Hidden } from '@epilot/journey-elements'
import type { IconName } from '@epilot/journey-elements'
import { isNumberInputControlOptions } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../..'
import { includeCommon } from '../../../utils/includeCommon'
import { formatNumberInUserLocale } from '../../../utils/numberFormat'

import { NumberInputForm } from './components/NumberInputForm'
import type { NumberInputControlData, NumberInputControlOptions } from './types'
import { isNumberInputControlData } from './types.guard'
import { convertNumberInputData, parseNumberInputData } from './utils'

export function NumberInputControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  visible,
  required,
  id
}: EpilotControlProps) {
  const { suggestions: rawSuggestions, ...options } =
    uischema.options as NumberInputControlOptions
  const hasError = !!errors

  const stepBlockId = useStepBlockId(path, id)

  const suggestions = (rawSuggestions || [])
    .filter((s) => s.value && s.value !== null)
    .map((sug) => ({
      label: sug.label,
      value: sug.value.replace(',', '.')
    }))

  if (!isNumberInputControlOptions<IconName>(options)) {
    console.error(
      'NumberInputControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isNumberInputControlData(data)) {
    console.error(
      'NumberInputControl -> Invalid data detected. Current data is:',
      {
        data
      }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  const handleFormChange = (
    fieldPath: string,
    data: NumberInputControlData
  ) => {
    handleChange(fieldPath, convertNumberInputData(data, options))
  }

  return (
    <Hidden xsUp={!visible}>
      <NumberInputForm
        data={parseNumberInputData(data, options)}
        handleChange={handleFormChange}
        hasError={hasError}
        id={stepBlockId}
        input_icon={options.input_icon}
        isRequired={!!required}
        path={path}
        suggestions={suggestions
          .sort((a, b) => +a.value - +b.value)
          .map((s) => ({
            label: s.label,
            value: formatNumberInUserLocale(s.value)
          }))}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: NumberInputControlPure
  }) as never
)
