import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  itemPrefix: {
    minWidth: 150
  },
  title: {
    whiteSpace: 'pre-wrap'
  }
})

export const usePaymentInfoStyles = makeStyles({
  toggleBox: {
    marginTop: '-15px',
    marginBottom: '10px'
  },
  otherBox: {
    marginLeft: '-9px'
  }
})
