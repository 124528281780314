export type PricingBlockMappingMetadata = {
  configuratorStepId?: string
  priceId?: string
  priceComponentId?: string
  productId?: string
} & WithTransientBlockMappingMetadata

export type OptionalPriceComponentBlockMapping = BaseBlockMapping & {
  type: 'optional-price-component'
  metadata: OptionalPriceComponentBlockMappingMetadata
  operator: MAPPING_OPERATOR
  value: boolean | number | string | null
}

export type PricingBlockMapping = BaseBlockMapping & {
  type: 'pricing'
  metadata: PricingBlockMappingMetadata
}

export type OptionalPriceComponentBlockMappingMetadata = {
  productId: string
  priceId: string
  priceComponentId: string
}

/**
 * Transient properties that will be used only during runtime (not persisted)
 */
export type WithTransientBlockMappingMetadata = {
  configuratorBlockName?: string
  configuratorStepName?: string
  configuratorStepIndex?: number
}

export type GenericBlockMapping = BaseBlockMapping & {
  type: 'generic'
  metadata: GenericBlockMappingMetadata
}

type GenericBlockMappingMetadata = {
  entityId: string
}

type BaseBlockMapping = {
  blockName?: string
  stepId?: string
}

export enum MAPPING_OPERATOR {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS'
}
