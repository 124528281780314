import { clsx } from '@epilot/journey-elements'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { usePriceInformationTaxesStyles } from './styles'
import type { TaxWithTaxInclusivityBehaviour } from './utils'

type ProductDetailsPriceInformationTaxesProps = {
  taxes: TaxWithTaxInclusivityBehaviour[]
  displayIndexes: boolean
  className?: string
}

export const ProductDetailsPriceInformationTaxes = ({
  taxes,
  displayIndexes,
  className
}: ProductDetailsPriceInformationTaxesProps) => {
  const { t } = useTranslation()
  const classes = usePriceInformationTaxesStyles()

  return (
    <div className={clsx(classes.container, className)}>
      {taxes.map((tax, index, list) => (
        <Fragment key={tax._id}>
          {tax.inclusive &&
            t('product_details.tax_rate_inclusive', 'inclusive')}{' '}
          {tax.rate}%{displayIndexes && <sup>{index + 1}</sup>}{' '}
          {t('product_details.tax_name', 'VAT')}
          {index === list.length - 1
            ? ''
            : index === list.length - 2
              ? ` ${t('and', 'and')} `
              : ', '}
        </Fragment>
      ))}
    </div>
  )
}
