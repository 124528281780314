import type {
  InjectedAddressData,
  ProductTileData,
  ProductType
} from '@epilot/journey-logic-commons'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import type { JourneyContextValue } from '../../../../utils'
import { useConfig } from '../../../../utils'
import { searchGetAgProviders } from '../../../../utils/services/product-service'

type UseGetAgProvidersProps = {
  injectedAddress?: InjectedAddressData
  productTiles: (ProductTileData & {
    priceId?: string | undefined
  })[]
  context: JourneyContextValue
  blockPath: string
}

const USE_GET_AG_PROVIDERS_QUERY_KEY = 'getag-providers'

export const useGetAgProviders = ({
  injectedAddress,
  productTiles,
  blockPath,
  context
}: UseGetAgProvidersProps) => {
  const [initialFetch, setInitialFetch] = useState(true)
  const [showAdditionalAddress, setShowAdditionalAddress] = useState(false)
  const additionalAddressData =
    context._productsAdditionalAddresses?.[blockPath]

  const { PRICING_API_URL } = useConfig()

  const addressData = {
    countryCode: 'DE',
    streetName:
      (showAdditionalAddress && !injectedAddress?.streetName
        ? additionalAddressData?.streetName
        : injectedAddress?.streetName) || '',
    houseNumber:
      (showAdditionalAddress && !injectedAddress?.streetNumber
        ? additionalAddressData?.houseNumber
        : injectedAddress?.streetNumber) || ''
  }

  const {
    hasGetAGProducts,
    availableGetAGProductCategories,
    hasGasGetAGProduct,
    hasPowerGetAGProduct
  } = useMemo(() => {
    const { hasPowerGetAGProduct, hasGasGetAGProduct } =
      checkGetAGProductCategories(productTiles)

    return {
      hasGetAGProducts: hasPowerGetAGProduct || hasGasGetAGProduct,
      hasPowerGetAGProduct,
      hasGasGetAGProduct,
      availableGetAGProductCategories: [
        hasPowerGetAGProduct && 'power',
        hasGasGetAGProduct && 'gas'
      ].filter(Boolean) as ProductType[]
    }
  }, [productTiles])

  const providersQueryRequest = buildProvidersQueryRequest(
    context,
    injectedAddress,
    addressData
  )

  const {
    data: queryResponse,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [
      USE_GET_AG_PROVIDERS_QUERY_KEY,
      providersQueryRequest.orgId,
      providersQueryRequest.params
    ],
    queryFn: async () => {
      const providersResponse = await Promise.all(
        availableGetAGProductCategories.map((productType) =>
          searchGetAgProviders(PRICING_API_URL, providersQueryRequest.orgId, {
            ...providersQueryRequest.params,
            productType
          })
        )
      )

      const powerIndex = availableGetAGProductCategories.indexOf('power')
      const gasIndex = availableGetAGProductCategories.indexOf('gas')

      return {
        powerProviders: hasPowerGetAGProduct
          ? providersResponse[powerIndex].data
          : [],
        gasProviders: hasGasGetAGProduct ? providersResponse[gasIndex].data : []
      }
    },
    enabled:
      hasGetAGProducts && !!injectedAddress?.zipCode && !!injectedAddress?.city,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      if (
        res.powerProviders.length > 1 ||
        res.gasProviders.length > 1 ||
        !initialFetch
      ) {
        setShowAdditionalAddress(true)
      } else {
        setShowAdditionalAddress(false)
      }
      setInitialFetch(false)
    },
    onError: (error: Error) => {
      console.error('Error while searching getag providers', error)
    }
  })

  return {
    hasGetAGProducts,
    getAgAdditionalAddressData: addressData,
    isLoadingGetAGProviders: isLoading || (isFetching && initialFetch),
    getAgPowerUniqueProvider:
      queryResponse?.powerProviders?.length === 1
        ? queryResponse?.powerProviders[0]
        : undefined,
    getAgGasUniqueProvider:
      queryResponse?.gasProviders?.length === 1
        ? queryResponse?.gasProviders[0]
        : undefined,
    showAdditionalAddress
  }
}

const checkGetAGProductCategories = (
  productTiles: (ProductTileData & { priceId?: string | undefined })[]
) => {
  let hasPowerGetAGProduct = false
  let hasGasGetAGProduct = false

  for (const tile of productTiles) {
    if (tile.isGetAgPrice) {
      if (tile.getAgCategory === 'power' || !tile.getAgCategory) {
        hasPowerGetAGProduct = true
      } else if (tile.getAgCategory === 'gas') {
        hasGasGetAGProduct = true
      }

      if (hasPowerGetAGProduct && hasGasGetAGProduct) {
        break
      }
    }
  }

  return { hasPowerGetAGProduct, hasGasGetAGProduct }
}

const buildProvidersQueryRequest = (
  context: JourneyContextValue,
  injectedAddress: InjectedAddressData | undefined,
  addressData: { countryCode: string; streetName: string; houseNumber: string }
) => {
  return {
    orgId: context.journey.organizationId || '',
    params: {
      postalCode: injectedAddress?.zipCode || '',
      city: injectedAddress?.city || '',
      street: addressData.streetName,
      streetNumber: addressData.houseNumber
    }
  }
}
