import { Grid } from '@epilot/journey-elements'
import type {
  AddressFields,
  AvailabilityCheck
} from '@epilot/journey-logic-commons'
import {
  AvailabilityMode,
  appendStepBlockId
} from '@epilot/journey-logic-commons'
import isEmpty from 'lodash/isEmpty'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddressFinderMap } from '../../../../components/AddressFinderMap'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetFieldsIfOneChanged } from '../../../../utils/hooks/forms/useResetFieldsIfOneChanged'
import { FieldStreetInput } from '../../AddressControl/components/FieldStreetInput'
import { FieldZipCityInput } from '../../AddressControl/components/FieldZipCityInput'
import type { AvailabilityCheckFormProps } from '../types'
import { cleanAvailabilityCheckValues } from '../utils'

export const AvailabilityCheckForm = (
  props: AvailabilityCheckFormProps
): ReactElement => {
  const {
    addressSuggestionsSource,
    availabilityMode = AvailabilityMode.PostalCode,
    enableAutoComplete = true,
    handleChange,
    path,
    hasError,
    fields,
    productAvailabilityServiceFields = {},
    postalCodeAvailabilityFields = {} as AddressFields,
    data,
    enableFreeText = false,
    isRequired,
    countryCode,
    googleMapsIntegrationOptions,
    id
  } = props

  const { t } = useTranslation(undefined, {
    keyPrefix: 'availability',
    useSuspense: true
  })

  const showAvailabilityServiceZipCode =
    !!productAvailabilityServiceFields.zipCode
  const showAvailabilityServiceStreetName =
    !!productAvailabilityServiceFields.streetName
  const showAvailabilityServiceStreetNumber =
    !!productAvailabilityServiceFields.streetNumber

  const showPostalCodeAvailabilityStreetName =
    !!postalCodeAvailabilityFields.streetName
  const showPostalCodeAvailabilityStreetNumber =
    !!postalCodeAvailabilityFields.streetNumber

  const acceptSuggestedOnly = enableAutoComplete && !enableFreeText
  const [addressDown, setAddressDown] = useState<boolean>(false)
  const [isResetForbidden, setIsResetForbidden] = useState(false)

  const { control, fieldValues, setValue, register, resetField } =
    useFormHandler<AvailabilityCheck>({
      handleChange: (
        path: string,
        availability: AvailabilityCheck | null | undefined
      ) => {
        cleanAvailabilityCheckValues(availability)

        return handleChange(path, availability)
      },
      fields,
      path,
      data,
      isRequired,
      formProps: {
        defaultValues: {
          countryCode: countryCode || 'DE',
          ...data
        }
      }
    })

  useResetFieldsIfOneChanged(resetField, {
    name: 'zipCode',
    watch: fieldValues?.zipCode,
    isResetForbidden,
    fieldsToReset: [
      { name: 'streetName', value: '' },
      { name: 'streetNumber', value: '' }
    ]
  })

  return (
    <Grid container>
      {availabilityMode === AvailabilityMode.PostalCode && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldZipCityInput
              acceptSuggestedOnly={false}
              addressCountryCode={countryCode}
              addressSuggestionsSource={addressSuggestionsSource}
              control={control}
              defaultValue={fields.zipCode.defaultValue || ''}
              disableAddressSuggestions={
                enableAutoComplete !== undefined ? !enableAutoComplete : false
              }
              fieldValues={fieldValues}
              hasError={hasError}
              id={appendStepBlockId(id, 'Zip Code')}
              keepAutoCompleteClosed={
                googleMapsIntegrationOptions?.isRepositioningAllowed
              }
              label={t('zipCode')}
              name="zipCode"
              path={path}
              register={register}
              setValue={setValue}
              {...(!isEmpty(postalCodeAvailabilityFields)
                ? postalCodeAvailabilityFields.zipCode
                : fields.zipCode)}
            />
          </Grid>

          <Grid container item spacing={2}>
            {showPostalCodeAvailabilityStreetName && (
              <Grid item xs={showPostalCodeAvailabilityStreetNumber ? 6 : 12}>
                <FieldStreetInput
                  acceptSuggestedOnly={false}
                  addressSuggestionsSource={addressSuggestionsSource}
                  control={control}
                  defaultValue={
                    postalCodeAvailabilityFields.streetName?.defaultValue || ''
                  }
                  disableAddressSuggestions={
                    enableAutoComplete !== undefined
                      ? !enableAutoComplete
                      : false
                  }
                  fieldValues={fieldValues}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Street Name')}
                  keepAutoCompleteClosed={
                    googleMapsIntegrationOptions?.isRepositioningAllowed
                  }
                  label={t('streetName')}
                  name="streetName"
                  path={path}
                  {...postalCodeAvailabilityFields.streetName}
                />
              </Grid>
            )}
            {showPostalCodeAvailabilityStreetNumber && (
              <Grid item xs={showPostalCodeAvailabilityStreetName ? 6 : 12}>
                <FieldStreetInput
                  acceptSuggestedOnly={false}
                  addressSuggestionsSource={addressSuggestionsSource}
                  control={control}
                  defaultValue={
                    postalCodeAvailabilityFields.streetNumber?.defaultValue ||
                    ''
                  }
                  disableAddressSuggestions={true}
                  fieldValues={fieldValues}
                  freeSolo={true}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Street Number')}
                  label={t('streetNumber')}
                  name="streetNumber"
                  path={path}
                  {...postalCodeAvailabilityFields.streetNumber}
                  keepAutoCompleteClosed={
                    googleMapsIntegrationOptions?.isRepositioningAllowed
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {availabilityMode === AvailabilityMode.ProductAvailabilityService && (
        <Grid container spacing={2}>
          {showAvailabilityServiceZipCode && (
            <Grid item xs={12}>
              <FieldZipCityInput
                acceptSuggestedOnly={!addressDown && acceptSuggestedOnly}
                addressCountryCode={countryCode}
                addressSuggestionsSource={addressSuggestionsSource}
                control={control}
                defaultValue={
                  productAvailabilityServiceFields.zipCode?.defaultValue || ''
                }
                disableAddressSuggestions={
                  enableAutoComplete !== undefined ? !enableAutoComplete : false
                }
                fieldValues={fieldValues}
                freeSolo={enableFreeText}
                hasError={hasError}
                id={appendStepBlockId(id, 'Zip Code')}
                label={t('zipCode')}
                name="zipCode"
                path={path}
                register={register}
                setAddressDown={setAddressDown}
                setValue={setValue}
                {...productAvailabilityServiceFields.zipCode}
                keepAutoCompleteClosed={
                  googleMapsIntegrationOptions?.isRepositioningAllowed
                }
              />
            </Grid>
          )}
          <Grid container item spacing={2}>
            {showAvailabilityServiceStreetName && (
              <Grid item xs={showAvailabilityServiceStreetNumber ? 6 : 12}>
                <FieldStreetInput
                  acceptSuggestedOnly={!addressDown && acceptSuggestedOnly}
                  addressSuggestionsSource={addressSuggestionsSource}
                  control={control}
                  defaultValue={
                    productAvailabilityServiceFields.streetName?.defaultValue ||
                    ''
                  }
                  disableAddressSuggestions={
                    enableAutoComplete !== undefined
                      ? !enableAutoComplete
                      : false
                  }
                  fieldValues={fieldValues}
                  freeSolo={enableFreeText}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Street Name')}
                  keepAutoCompleteClosed={
                    googleMapsIntegrationOptions?.isRepositioningAllowed
                  }
                  label={t('streetName')}
                  name="streetName"
                  path={path}
                  {...productAvailabilityServiceFields.streetName}
                />
              </Grid>
            )}
            {showAvailabilityServiceStreetNumber && (
              <Grid item xs={showAvailabilityServiceStreetName ? 6 : 12}>
                <FieldStreetInput
                  acceptSuggestedOnly={false}
                  addressSuggestionsSource={addressSuggestionsSource}
                  control={control}
                  defaultValue={
                    productAvailabilityServiceFields.streetNumber
                      ?.defaultValue || ''
                  }
                  disableAddressSuggestions={true}
                  fieldValues={fieldValues}
                  freeSolo={true}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Street Number')}
                  keepAutoCompleteClosed={
                    googleMapsIntegrationOptions?.isGoogleMapsEnabled
                  }
                  label={t('streetNumber')}
                  name="streetNumber"
                  path={path}
                  {...productAvailabilityServiceFields.streetNumber}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {googleMapsIntegrationOptions?.isGoogleMapsEnabled && (
        <AddressFinderMap
          addressData={{
            zipCode: fieldValues.zipCode,
            city: fieldValues.city,
            streetName: fieldValues.streetName,
            houseNumber: fieldValues.streetNumber,
            coordinates: fieldValues.coordinates
          }}
          houseNumberKey="streetNumber"
          id={appendStepBlockId(id, 'Google Maps Street Number')}
          isRepositioningAllowed={
            googleMapsIntegrationOptions?.isRepositioningAllowed
          }
          isResetForbidden={isResetForbidden}
          setIsResetForbidden={setIsResetForbidden}
          setValue={setValue}
        />
      )}
    </Grid>
  )
}
