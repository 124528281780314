export function getPreviewData(attributeType: string) {
  switch (attributeType) {
    case 'string':
    case 'radio':
    case 'select':
    case 'status':
      return ''
    case 'checkbox':
    case 'boolean':
      return true
    case 'number':
      return { numberInput: '0' }
    case 'multiselect':
      return []
    case 'date':
      return undefined
    default:
      return ''
  }
}
