import type { Logic, LogicAction, LogicCondition, Step } from '../utils'
import {
  BLOCK_LOCATOR_SEPARATOR,
  logicActionTypes,
  parseLogicActionTarget
} from '../utils'

export type SkipStepLogic = Logic & {
  blockName: string
  stepId: string
  conditionsRelatedToStep: LogicCondition[]
  actionsIncludesSkip: (LogicAction & {
    // can be comma separated list of step ids
    targetStepId: string
  })[]
}

export function getLogicsSkipStep(
  steps?: Step[],
  logics?: Logic[]
): SkipStepLogic[][] {
  if (!steps || !logics) {
    return []
  }
  const logicsPerStepIndex: SkipStepLogic[][] = new Array(steps.length).map(
    () => []
  )

  steps.forEach((step, i) => {
    const logsInStep: SkipStepLogic[] = []

    logics.forEach((log) => {
      let blockName = ''
      // One of the conditions includes a block in the Step
      const condSourceStep = log.conditions?.filter((cond) => {
        const parts = cond.conditionSource?.split(BLOCK_LOCATOR_SEPARATOR)

        if (parts && parts[0] === `$$${step.stepId}`) {
          blockName = parts[1]
            ? parts[1].endsWith('$$')
              ? parts[1].slice(0, -2)
              : parts[1]
            : ''

          return true
        }

        return false
      })

      if (Array.isArray(condSourceStep) && condSourceStep.length > 0) {
        // One of the actions include the Skip Step action type
        const actSkipStep = log.actions
          ?.filter((act) => logicActionTypes.SkipStep.includes(act.actionType))
          .map((e) => {
            const target = parseLogicActionTarget(e.target)
            const targetParts = target?.split(BLOCK_LOCATOR_SEPARATOR)

            return {
              ...e,
              targetStepId: targetParts?.[0]
            }
          })

        if (Array.isArray(actSkipStep) && actSkipStep.length > 0) {
          logsInStep.push({
            ...log,
            blockName: blockName,
            stepId: step.stepId,
            conditionsRelatedToStep: condSourceStep,
            actionsIncludesSkip: actSkipStep as never
          })
        }
      }
    })

    logicsPerStepIndex[i] = logsInStep
  })

  return logicsPerStepIndex
}
