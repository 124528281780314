import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core'

import type { SwitchProps } from './types'

export default function Switch(props: SwitchProps) {
  const { label, labelPlacement = 'end', ...rest } = props

  return (
    <FormControlLabel
      control={<MuiSwitch {...rest} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
