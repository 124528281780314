import { makeStyles } from '@material-ui/core'
import { darken } from '@material-ui/core/styles'
import React from 'react'

import type { EpilotTheme } from '../'
import { createStyles, Icon, IconButton, TextField } from '../'

import type { StepperInputProps } from './types'

// looks like StepperInput is not used in journeys

const sharedButtonStyle = {
  height: ({ size }: StepperInputProps) =>
    size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px',
  width: ({ size }: StepperInputProps) =>
    size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}

const useStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    withError: {
      border: '0 !important'
    },
    textfieldRoot: {
      background: 'transparent !important',
      padding: 0,
      display: 'flex',
      '& .MuiIconButton-root': {
        color: theme?.palette?.secondary.main,
        cursor: 'pointer',
        fontSize: ({ size }: StepperInputProps) =>
          size === 'small' ? '14px' : size === 'medium' ? '16px' : '20px',
        height: ({ size }: StepperInputProps) =>
          size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px',
        width: ({ size }: StepperInputProps) =>
          size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px',
        padding: ({ size }: StepperInputProps) =>
          size === 'small' ? '5px' : size === 'medium' ? '8px' : '10px'
      },
      '& .MuiIcon-root:active': {
        background: theme?.palette?.action.hover,
        border: `1px solid ${theme?.palette?.primary?.[600]} !important`
      },
      '&.Mui-disabled .MuiIcon-root': {
        color: theme?.palette?.action.disabled
      },
      '&.Mui-focused input': {
        borderBottom: `1px solid ${theme?.palette?.primary.main}`,
        borderTop: `1px solid ${theme?.palette?.primary.main}`
      },
      '&.Mui-focused .MuiIcon-root': {
        border: `1px solid ${theme?.palette?.primary.main}`
      },
      '& .text-adornment': {
        color: theme.palette?.text.secondary,
        borderBottom: `1px solid ${theme.palette?.grey?.[70]}`,
        borderTop: `1px solid ${theme.palette?.grey?.[70]}`,
        height: ({ size }: StepperInputProps) =>
          size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px',
        padding: ({ size }: StepperInputProps) =>
          `0 ${size === 'small' ? '8px' : '12px'}`,
        display: 'flex',
        alignItems: 'center'
      },
      '&.Mui-focused .text-adornment': {
        borderBottom: `1px solid ${theme?.palette?.primary.main}`,
        borderTop: `1px solid ${theme?.palette?.primary.main}`
      }
    },
    textfieldInput: {
      color: theme.palette?.text.secondary,
      borderBottom: `1px solid ${theme.palette?.grey?.[70]}`,
      borderTop: `1px solid ${theme.palette?.grey?.[70]}`,
      boxSizing: 'border-box',
      height: ({ size }: StepperInputProps) =>
        `${
          size === 'small' ? '24px' : size === 'medium' ? '32px' : '40px'
        } !important`,
      padding: ({ size }: StepperInputProps) =>
        `0 ${size === 'small' ? '8px' : '12px'} !important`,
      fontSize: ({ size }: StepperInputProps) =>
        size === 'small'
          ? theme?.typography?.caption.fontSize
          : theme?.typography?.body2.fontSize,
      textAlign: 'center',
      borderRadius: 0
    },
    iconButtonStart: {
      borderRadius: 0,
      border: `1px solid ${theme.palette?.grey?.[70]}`,
      borderRight: `1px solid ${theme.palette?.grey?.[70]} !important`,
      borderTopLeftRadius: theme?.shape?.borderRadius,
      borderBottomLeftRadius: theme?.shape?.borderRadius,
      '&:hover .MuiIcon-root': {
        color: darken('' + theme?.palette?.secondary.main, 0.25)
      },
      ...sharedButtonStyle
    },
    iconButtonEnd: {
      borderRadius: 0,
      border: `1px solid ${theme.palette?.grey?.[70]}`,
      '&:hover .MuiIcon-root': {
        color: darken('' + theme?.palette?.secondary.main, 0.25)
      },
      borderLeft: `1px solid ${theme.palette?.grey?.[70]} !important`,
      borderTopRightRadius: theme?.shape?.borderRadius,
      borderBottomRightRadius: theme?.shape?.borderRadius,
      ...sharedButtonStyle
    }
  })
)

export const StepperInput = React.forwardRef<
  HTMLInputElement,
  StepperInputProps
>((props, ref) => {
  const {
    startIcon,
    endIcon,
    error,
    value = 0,
    textAdornment = '',
    onStartIconClick,
    onEndIconClick,
    size = 'large',
    type,
    id,
    ...rest
  } = props

  const classes = useStyles({ size, error })

  return (
    <TextField
      {...rest}
      InputProps={{
        endAdornment: (
          <>
            {textAdornment && (
              <span className="text-adornment">{textAdornment}</span>
            )}
            {endIcon || (
              <IconButton
                aria-label="Add"
                className={classes.iconButtonEnd}
                id={id && `${id}_add_button`}
                onClick={onEndIconClick}
              >
                <Icon name="add" />
              </IconButton>
            )}
          </>
        ),
        startAdornment: (
          <>
            {startIcon || (
              <IconButton
                aria-label="Remove"
                className={classes.iconButtonStart}
                id={id && `${id}_remove_button`}
                onClick={onStartIconClick}
              >
                <Icon name="remove" />
              </IconButton>
            )}
          </>
        ),
        classes: {
          root: `${classes.textfieldRoot} ${!error ? classes.withError : ''}`,
          input: classes.textfieldInput
        }
      }}
      error={error}
      hiddenLabel
      id={id}
      label=""
      ref={ref}
      type={type ?? 'tel'}
      value={value}
    />
  )
})
