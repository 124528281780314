import type { Contact } from './contact'
import type { FieldOption, LayoutOptions } from './sharedBlocksTypes'
import type { ContactControlOptions } from './sharedControlOptionsTypes'

export type Address = {
  zipCode?: string
  city?: string
  streetName?: string
  houseNumber?: string
  extention?: string
  suburb?: string
  plotOfLand?: string
  plotArea?: string
  coordinates?: string
  countryCode: string
  meta?: {
    manualData: boolean
  }
} & Contact

export type AddressFields = {
  zipCode?: FieldOption & { layout?: LayoutOptions }
  streetName?: FieldOption & { layout?: LayoutOptions }
  streetNumber?: FieldOption & { layout?: LayoutOptions }
}

export type AddressSuggestionsSource =
  | 'deutschePostService'
  | 'customAddressesFile'
  | 'getAgService'

export enum AvailabilityMode {
  PostalCode = 'postalCode',
  ProductAvailabilityService = 'productAvailabilityService'
}

export type AvailabilityCheckLogicsConfiguratorOptions = Array<{
  id: string
  stepId: string
  fileId: string
  fileName?: string
}>

type AddressFieldOption = FieldOption & { layout?: LayoutOptions }

export type ListedFields = {
  zipCity?: AddressFieldOption
  suburb?: AddressFieldOption
  streetName?: AddressFieldOption
  houseNumber?: AddressFieldOption
  extention?: AddressFieldOption
} & ContactControlOptions['fields']

export type UnlistedFields = {
  zipCity?: AddressFieldOption
  suburb?: AddressFieldOption
  plotOfLand?: AddressFieldOption
  plotArea?: AddressFieldOption
} & ContactControlOptions['fields']

export type InjectedAddressData = {
  zipCode?: string
  city?: string
  countryCode: string
  streetName?: string
  streetNumber?: string
}
