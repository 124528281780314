import { apiCall } from '../../../api'
import { GOOGLE_MAP_ID } from '../../../utils'

import type {
  AvailabilityBlockData,
  SolarPanelConfig,
  SolarPotential
} from './types'

export const GERMANY_CENTER = {
  lat: 51.1657,
  lng: 10.4515
}

export const MAP_SETTINGS = {
  zoom: 6,
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  gestureHandling: 'cooperative' as const,
  rotateControlOptions: {
    position: 7
  },
  zoomControlOptions: {
    position: 9
  },
  tilt: 0,
  mapId: GOOGLE_MAP_ID,
  center: GERMANY_CENTER,
  styles: [
    {
      featureType: 'all' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'poi' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    }
  ]
}

export function getFormattedLocaleNumber(number: number) {
  const userLocale = navigator.language || 'de-DE'

  return new Intl.NumberFormat(userLocale, {
    maximumFractionDigits: 0
  }).format(number)
}

export const getSolarPanelsUserCount = ({
  solarPanelConfigs,
  yearlyConsumption
}: {
  yearlyConsumption: string
  solarPanelConfigs: SolarPanelConfig[]
}) => {
  const maxArrayPanelsCount =
    solarPanelConfigs[solarPanelConfigs.length - 1].panelsCount

  return (
    solarPanelConfigs.find((solarPanel) => {
      return solarPanel.yearlyEnergyDcKwh > Number(yearlyConsumption)
    })?.panelsCount ?? maxArrayPanelsCount
  )
}

export const getHouseAddress = (
  relatedAvailabilityBlockData: AvailabilityBlockData
) => {
  return relatedAvailabilityBlockData?.zipCode &&
    relatedAvailabilityBlockData?.city
    ? `${relatedAvailabilityBlockData?.streetName ?? ''} ${
        relatedAvailabilityBlockData?.streetNumber ?? ''
      } ${relatedAvailabilityBlockData?.streetName ? ',' : ''} ${
        relatedAvailabilityBlockData?.zipCode
      }, ${relatedAvailabilityBlockData?.city}`
    : undefined
}

export async function getClosestBuildingInsights(
  googleMapsApiUrl: string,
  location: google.maps.LatLngLiteral | google.maps.LatLng,
  publicToken: string
): Promise<SolarPotential | undefined> {
  try {
    const response = await apiCall({
      method: 'GET',
      url: `${googleMapsApiUrl}/v1/solar-api/building-insights?latitude=${location.lat}&longitude=${location.lng}`,
      headers: {
        authorization: `Bearer ${publicToken}`
      }
    })
    const solarPotential: SolarPotential | undefined = response.data

    return solarPotential
  } catch (error) {
    console.error(
      'Failed to retrieve building insights for the location:',
      error
    )
    throw error
  }
}
