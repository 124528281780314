import type {
  Logic,
  LogicAction,
  LogicCondition,
  Step,
  BlockLocatorDetails
} from '../utils'
import {
  BLOCK_LOCATOR_SEPARATOR,
  logicActionTypes,
  parseLogicActionTarget,
  getObjectPropertyByPath,
  parseLogicConditionValueFromPath,
  blockController
} from '../utils'

export type NavigateFromStepLogic = Logic & {
  blockName: string
  parentBlockName?: string
  conditionsRelatedToStep: LogicCondition[]
  nextProductBlocksRelatedToStep: BlockLocatorDetails[]
  actionsIncludesNav: (LogicAction & {
    targetStepIndex: number
    targetStepId: string
  })[]
}
/**
 * return array, each elements is an array of logic objects that corresponds to navigating from Step
 * This means, ALL OF THE FOLLOWING IS TRUE:
 * 1. One of the conditions includes a block in the Step
 * 2. One of the actions include the navigation action type
 */
export function getLogicsNavigateFromStep(
  steps: Step[],
  logics: Logic[]
): NavigateFromStepLogic[][] {
  if (!steps || !logics) {
    return []
  }
  const logicsPerStepIndex: NavigateFromStepLogic[][] = Array.from(
    { length: steps.length },
    () => []
  )

  steps.forEach((step, i) => {
    const logsInStep: NavigateFromStepLogic[] = []
    let nextProductBlocksRelatedToStep: BlockLocatorDetails[]

    logics.forEach((log) => {
      let blockName = ''
      let parentBlockName
      // One of the conditions includes a block in the Step
      const condSourceStep = log.conditions?.filter((cond) => {
        const parts = cond.conditionSource?.split(BLOCK_LOCATOR_SEPARATOR)

        // check if part includes step Id | step name. If it starts with $$ slice it
        if (parts) {
          const condition = parts[0].startsWith('$$')
            ? parts[0].slice(2)
            : parts[0]

          if (condition === step.stepId) {
            blockName = parts[1]
              ? parts[1].endsWith('$$')
                ? parts[1].slice(0, -2)
                : parts[1]
              : ''

            const { uischema: blockUischema, parent: parentBlock } =
              blockController.findBlock(
                { ...step, stepIndex: i },
                { name: blockName }
              ) ?? {}

            parentBlockName = parentBlock?.scope?.split('/')?.pop()

            // if condition contains conditionValueFromPath, then override conditionValue with the value from the block config
            if (
              cond.conditionValueFromPath ||
              cond.conditionValueFromInternalIntegration
            ) {
              let pathValue

              if (cond.conditionValueFromPath) {
                pathValue = parseLogicConditionValueFromPath(
                  cond.conditionValueFromPath
                )
                if (pathValue && pathValue !== 'undefined') {
                  // get block option value by path
                  if (blockUischema) {
                    const value = getObjectPropertyByPath(
                      blockUischema.options,
                      pathValue
                    )

                    if (value !== undefined && value !== null) {
                      cond.conditionValue = `%%${value}%%`
                    }
                  }
                }
              } else if (cond.conditionValueFromInternalIntegration) {
                nextProductBlocksRelatedToStep = blockController.findBlocks(
                  steps.filter((_, index) => index >= i + 1),
                  { type: 'ProductSelectionControl' }
                )
              }
            }

            return true
          }
        }

        return false
      })

      if (Array.isArray(condSourceStep) && condSourceStep.length > 0) {
        // One of the actions include the navigation action type
        const actNav = log.actions
          ?.filter((act) =>
            logicActionTypes.NavigateToStep.includes(act.actionType)
          )
          .map((e) => ({
            ...e,
            targetStepIndex: findStepIndexById(
              steps,
              `${parseLogicActionTarget(e.target)}`,
              0
            ),
            targetStepId: `${parseLogicActionTarget(e.target)}`,
            conditionResult:
              e.conditionResult !== undefined ? e.conditionResult : true
          }))

        if (Array.isArray(actNav) && actNav.length > 0) {
          logsInStep.push({
            ...log,
            blockName: blockName,
            parentBlockName: parentBlockName,
            conditionsRelatedToStep: condSourceStep,
            actionsIncludesNav: actNav as never,
            nextProductBlocksRelatedToStep
          })
        }
      }
    })

    logicsPerStepIndex[i] = logsInStep
  })

  return logicsPerStepIndex
}

export function findStepIndexById(
  steps: Step[],
  stepId: string,
  fallBack: number
) {
  const indexOfId = steps.findIndex((s: Step) => s && s.stepId === stepId)

  if (indexOfId >= 0) {
    return indexOfId
  }

  // eslint-disable-next-line no-console
  console.error(
    "findStepIndexById - StepId doesn't exist in the steps array. Returning fallback."
  )

  return fallBack
}
