import type { JourneyStorage } from './types'

/**
 * As journeys are typically embedded in iframes, we need to check whether the browser supports local + session Storage,
 * or whether it's disabled due to blocking 3rd party cookies.
 */
export const journeyStorage: JourneyStorage = {
  getItem: (key, type = 'local') => {
    try {
      const storage = type === 'local' ? localStorage : sessionStorage

      return storage.getItem(key)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting item from storage', { type, key, e })

      return null
    }
  },
  setItem: (key, value, type = 'local') => {
    try {
      const storage = type === 'local' ? localStorage : sessionStorage

      storage.setItem(key, value)

      return true
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error setting item in storage', { type, key, value, e })

      return false
    }
  }
}
