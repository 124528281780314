import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { PriceItem, Tax } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CartItemDetailsLineContent } from './CartItemDetailsLineContent'

type TaxWithTaxInclusivityBehaviour = Tax & { inclusive: boolean }

const useStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    listItem: {
      padding: '8px 12px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '8px',
      backgroundColor: 'white'
    }
  })
)

type ProductDetailsPriceInformationItemComponentProps = {
  component: PriceItem
  displayUnitaryAverage?: boolean
  taxes?: TaxWithTaxInclusivityBehaviour[]
  showTrailingDecimalZeros?: boolean
  shouldHideRecurrence?: boolean
}

export const ProductDetailsPriceInformationItemComponent = ({
  component,
  displayUnitaryAverage,
  taxes = [],
  showTrailingDecimalZeros,
  shouldHideRecurrence
}: ProductDetailsPriceInformationItemComponentProps) => {
  const { t: productSelectionT } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })
  const classes = useStyles()

  const taxIndexes = useMemo<number[]>(() => {
    const componentTaxes =
      component.taxes
        ?.map(({ tax }) => tax)
        .filter((tax): tax is Tax => !!tax) ?? []

    const indexes = componentTaxes
      .map(({ _id }) =>
        taxes.findIndex(
          (tax) =>
            tax._id === _id &&
            tax.inclusive === component._price?.is_tax_inclusive
        )
      )
      .filter((index) => index >= 0)
      .map((index) => index + 1)

    return indexes
  }, [taxes, component.taxes, component._price?.is_tax_inclusive])

  return (
    <li className={classes.listItem} key={component.price_id}>
      <CartItemDetailsLineContent
        displayUnitaryAverage={displayUnitaryAverage}
        item={component}
        shouldHideRecurrence={shouldHideRecurrence}
        showTrailingDecimalZeros={showTrailingDecimalZeros}
        t={productSelectionT}
        taxIndexes={taxIndexes}
      />
    </li>
  )
}
