import { TextField } from '@epilot/journey-elements'
import type { FieldOption } from '@epilot/journey-logic-commons'
import type { Control, Path, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { InputCalculatorFormValues } from '../types'

type QuantityFieldProps<T extends FieldValues> = {
  control: Control<T>
  name: Path<InputCalculatorFormValues>
  hasError: boolean
  path: string
  unit?: string
  isMobile?: boolean
  id: string
} & FieldOption

export const QuantityField = (
  props: QuantityFieldProps<InputCalculatorFormValues>
) => {
  const { hasError, control, name, label, errorMessage, required, id } = props

  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        // to prevent users enter letters
        const handleInputChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          field.onChange(event)
        }

        return (
          <TextField
            InputLabelProps={{ htmlFor: id }}
            customBackgroundColor="transparent"
            error={hasError && fieldState.error !== undefined}
            fullWidth
            helperText={
              hasError &&
              fieldState.error &&
              (fieldState.error?.message || errorMessage || t('field_required'))
            }
            id={id}
            // it is required to have type=text to manage big numbers
            inputProps={{
              inputMode: 'text',
              pattern: '^[0-9]*$'
            }}
            label={label || t(name)}
            required={required}
            type="text"
            {...field}
            onChange={handleInputChange}
          />
        )
      }}
      rules={{
        required,
        pattern: {
          value: /^[0-9]*$/,
          message: t('field_number_input_invalid_digits')
        },
        validate: (value) => {
          if (typeof value === 'string' && parseInt(value, 10) >= 1) {
            return true
          } else {
            return t(
              'input_calculator.quantity_min_error',
              'Quantity must be more than 1'
            )
          }
        }
      }}
    />
  )
}
