import type { Journey } from '../types'

import { generateStepId } from './generateStepId'
/**
 * This function make sure that all steps has stepId, and if one does not have it, it will assign one
 * @param journey
 * @todo This function is mutating the original journey object, which is not ideal
 * refactor it to avoid mutability issues (map bellow rather than for each and setting actions)
 *
 * WARNING: Traversing the list of Steps to generate a new StepId can be a performance bottleneck
 */
export function assignIdsToAllSteps(journey: Journey): Journey {
  const steps = journey.steps
  const logics = journey.logics

  const fixedSteps = steps.map((step) => {
    // If stepname includes slash, replace it with a space
    const stepName = step.name.replace('/', ' ')
    const oldId = step.stepId

    // If stepId does not exist, or if it includes a slash, generate a new one
    // Either way, update with the above generated step name
    const updatedStep =
      !step.stepId || step.stepId.includes('/')
        ? { ...step, name: stepName, stepId: generateStepId(steps, stepName) }
        : { ...step, name: stepName }

    // Fix logics if stepId changed
    if (oldId !== updatedStep.stepId && logics) {
      for (const logic of logics) {
        logic.actions.forEach((action, idx) => {
          logic.actions[idx] = action.replace(oldId, updatedStep.stepId)
        })
        // Assigning any to condition because type is overly specific and does not allow string type
        logic.conditions.forEach((condition: any, idx) => {
          logic.conditions[idx] = condition.replace(oldId, updatedStep.stepId)
        })
      }
    }

    return updatedStep
  })

  const fixedJourney = { ...journey, steps: fixedSteps }

  return fixedJourney
}

export { generateStepId }
