import {
  Typography as ConcordeTypography,
  Spacing
} from '@epilot/concorde-elements'
import { BodyText, createStyles, makeStyles } from '@epilot/journey-elements'
import { priceHasComponents } from '@epilot/journey-logic-commons'
import type { CompositePriceItem, Price } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../utils/tile-context-utils'

import { ConcordePriceComponentsItem } from './TileFooter/ConcordePriceComponentsItem'
import { PriceItemComponent } from './TileFooter/PriceItemComponent'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontWeight: 600
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      width: '100%',
      maxWidth: '100%'
    },
    concordeContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      gap: 8,
      justifyContent: 'center',
      width: '100%',
      maxWidth: '100%',
      margin: '0 auto'
    }
  })
)

type ProductPricesComponentsProps = {
  displayTitle?: boolean
  className?: string
}

export const ProductPricesComponents = ({
  displayTitle = true,
  className
}: ProductPricesComponentsProps) => {
  const classes = useStyles()
  const { price, product, config, actions, pricingDetails, useNewDesign } =
    useTileContext()

  const components = (pricingDetails?.items?.[0] as CompositePriceItem)
    ?.item_components

  const title = actions.t(
    config.content.priceComponents.title,
    'Price components'
  )
  const shouldDisplayComponents =
    config.generalTileConfiguration.displayPriceComponents &&
    priceHasComponents(price)

  if (!shouldDisplayComponents) {
    return null
  }

  const Typography = useNewDesign ? ConcordeTypography : BodyText
  const PriceComponent = useNewDesign
    ? ConcordePriceComponentsItem
    : PriceItemComponent

  return (
    <Spacing className={className} scale={1} variant="stack">
      {displayTitle && (
        <Typography className={classes.title}>{title}</Typography>
      )}
      <div
        className={useNewDesign ? classes.concordeContainer : classes.container}
      >
        {(components || []).map((priceComponent: Price, index: number) => (
          <PriceComponent
            key={`${product._id}:${price._id}:${priceComponent._id}:${index}`}
            priceComponent={priceComponent}
          />
        ))}
      </div>
    </Spacing>
  )
}
