import {
  Grid,
  StackSpacing,
  IconButton,
  LargeText,
  Divider,
  BodyText,
  Icon
} from '@epilot/journey-elements'
import type { Source } from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { ReactElement } from 'react'
import { isValidElement } from 'react'

import { JOURNEY_NAVIGATION_EVENT } from '../../../utils/constants/EventConstant'
import type { EventDetailType } from '../../../utils/constants/types'

import { useBlockGroupStyles } from './styles'

/**
 * a function that take care of displaying the block hrader in summar
 * @param blockDisplayName block display name which could be simple th block name or displayName of the block if it was configured in the block config as such
 * @param blockRenderData the data to be rendered, it isa simple string or an array of arrays, the first level represents the rows and the second level is the actual data
 * @param source the source of the block
 * @returns
 */
export function BlockGroup(props: {
  blockDisplayName: string
  blockRenderData: (string | ReactElement)[][]
  source?: Source
  id: string
}) {
  const { blockDisplayName, blockRenderData, source, id } = props
  const isEmpty =
    !blockRenderData ||
    blockRenderData === null ||
    Object.values(blockRenderData).join('').replace(/,/g, '') === ''
  const stepIndex = source?.stepIndex
  const blockName = source?.name

  const classes = useBlockGroupStyles()

  if (isEmpty) {
    return <></>
  }

  return (
    <StackSpacing alignItems="stretch" key={blockName} scale={2}>
      <Grid container>
        <Grid className={classes.blockHeader} item>
          <LargeText color="textPrimary">
            <b>{blockDisplayName}</b>
          </LargeText>
          {stepIndex !== -1 && (
            <IconButton
              id={appendStepBlockId(id, `Edit ${blockDisplayName} Button`)}
              onClick={() => handleOnEditorButton(stepIndex || 0)}
            >
              <Icon color="secondary" name="edit" />
            </IconButton>
          )}
        </Grid>
        <Divider className={classes.divider} orientation="horizontal"></Divider>
      </Grid>
      <StackSpacing alignItems="stretch" scale={3}>
        {blockRenderData.map((row, index) => {
          // the block data row might be an array of strings or react components
          // if a react, we wrap them with a Grid
          // if a string we will joing them with a space
          if (row.every(isValidElement)) {
            const elements = row.map((el, index) => {
              return (
                <Grid item key={index}>
                  {el}
                </Grid>
              )
            })

            return elements.length > 0 ? elements : <></>
          } else {
            // join text without space and undefine
            const joinedText = row.filter(Boolean).join(' ')

            return joinedText ? (
              <Grid item key={index}>
                <BodyText color={'textSecondary'}>{joinedText}</BodyText>
              </Grid>
            ) : (
              <></>
            )
          }
        })}
      </StackSpacing>
    </StackSpacing>
  )
}

function handleOnEditorButton(index: number) {
  const event = new CustomEvent<EventDetailType>(JOURNEY_NAVIGATION_EVENT, {
    detail: {
      payload: {
        stepIndex: index
      }
    }
  })

  document.dispatchEvent(event)
}
