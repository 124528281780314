import { replaceSpaceWithUnderscore } from '@epilot/journey-logic-commons'

import { useJourneyContext } from '../context'

const STEP_BLOCK_ID_PREFIX = 'ja'

/**
 * Generates a unique ID for interactable fields on the journeys
 */
const getStepBlockId = (stepName: string, blockName: string): string => {
  const transformedStepName = replaceSpaceWithUnderscore(stepName)
  const transformedBlockName = replaceSpaceWithUnderscore(blockName)

  return `${STEP_BLOCK_ID_PREFIX}.${transformedStepName}.${transformedBlockName}`
}

/**
 * Returns the step and block ID for current interactable fields on the journeys
 */
export function useStepBlockId(blockName: string, currentId?: string) {
  const {
    context: {
      _navigationInfo: { currentStepIndex },
      journey: { steps }
    }
  } = useJourneyContext()

  // In case of an existing step block ID like in Entity Attribute Editor blocks
  if (currentId?.startsWith(STEP_BLOCK_ID_PREFIX)) return currentId

  if (!steps || !steps.length) return blockName

  const stepName = steps[currentStepIndex]?.name || ''

  const id = getStepBlockId(stepName, blockName)

  return id
}
