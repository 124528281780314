import Error from '@epilot360/icons/react/Error'
import { useState, useEffect, useCallback, useRef } from 'react'

import { useTheme } from '../'

import { FileProgress } from './FileProgress'
import { FileStatusNotification } from './FileStatusNotification'
import { withUploadProgress } from './helpers'
import type { FileUploadProps } from './types'
import { FileUploadStatus } from './types'

export function FileUpload({
  file,
  uploadFileHandler,
  labels,
  onProgressFinished,
  showLoadingBar
}: FileUploadProps) {
  const [progress, setProgress] = useState<number>(0)
  const [status, setStatus] = useState<FileUploadStatus>(
    FileUploadStatus.Progress
  )
  const abortControllerRef = useRef<AbortController>()
  const theme = useTheme()

  const onProgress = (progressEvent?: ProgressEvent) => {
    progressEvent &&
      setProgress(
        Math.floor((progressEvent.loaded * 100) / progressEvent.total)
      )
  }

  const onCancel = useCallback(() => {
    abortControllerRef.current?.abort()
    setStatus(FileUploadStatus.Cancelled)
    onProgressFinished?.(FileUploadStatus.Cancelled)
  }, [onProgressFinished])

  useEffect(() => {
    abortControllerRef.current = new AbortController()

    withUploadProgress(
      uploadFileHandler,
      file,
      onProgress,
      abortControllerRef.current?.signal
    )
      .then(() => {
        setStatus(FileUploadStatus.Successful)
        onProgressFinished?.(FileUploadStatus.Successful)
      })
      .catch((e: Error) => {
        // wont record errors when the user intentionally cancelled
        if (e?.name === 'CanceledError') return

        setStatus(FileUploadStatus.Failed)
        onProgressFinished?.(FileUploadStatus.Failed, e)
      })
    // runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @todo implement pause logic
   * @todo switch success color from primary to success
   */

  switch (status) {
    case FileUploadStatus.Progress:
    case FileUploadStatus.Cancelled:
    case FileUploadStatus.Successful:
      return showLoadingBar ? (
        <FileProgress
          fileName={file.name}
          onClickCancel={onCancel}
          progress={progress}
          status={status}
          statusComponents={labels}
        />
      ) : null
    case FileUploadStatus.Failed:
      return (
        <FileStatusNotification
          icon={<Error fill={theme.palette.error.main} variant="filled" />}
          message={labels.Failed}
        />
      )

    default:
      return null
  }
}
