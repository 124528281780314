import {
  getPriceMappings,
  getExternalFeesMappings
} from '@epilot/journey-logic-commons'
import type {
  Price,
  PriceWithBlockMappings
} from '@epilot/journey-logic-commons'
import { computeAggregatedAndPriceTotals } from '@epilot/pricing'
import { useMemo } from 'react'

export const useComputePricingDetails = ({
  price,
  quantity = 1,
  overrides
}: {
  price: PriceWithBlockMappings
  quantity?: number
  overrides?: {
    blockMappings?: Price['price_mappings']
    externalFeesMappings?: Price['external_fees_mappings']
  }
}) => {
  return useMemo(() => {
    const blockMappings =
      overrides?.blockMappings ||
      getPriceMappings({
        ...price,
        price: price
      })

    const externalFeesMappings =
      overrides?.externalFeesMappings || getExternalFeesMappings(price)

    const totals = computeAggregatedAndPriceTotals([
      {
        ...price,
        _price: price,
        quantity,
        ...(blockMappings.length > 0 && { price_mappings: blockMappings }),
        ...(externalFeesMappings.length > 0 && {
          external_fees_mappings: externalFeesMappings
        })
      }
    ])

    return {
      pricingDetails: totals,
      blockMappings,
      externalFeesMappings
    }
  }, [
    overrides?.blockMappings,
    overrides?.externalFeesMappings,
    price,
    quantity
  ])
}
