import type { ComponentProps } from 'react'
import React from 'react'

const defaultState = ''

export const ClassPrefixContext = React.createContext(defaultState)

type ClassPrefixProviderProps = Omit<
  ComponentProps<typeof ClassPrefixContext.Provider>,
  'value'
> & { classPrefix: string }

export const ClassPrefixProvider = ({
  classPrefix,
  ...props
}: ClassPrefixProviderProps) => {
  const prefix = classPrefix ? `${classPrefix}-` : ''

  return <ClassPrefixContext.Provider value={prefix} {...props} />
}
