import { Hidden } from '@epilot/journey-elements'
import {
  isAvailabilityCheckControlOptions,
  isAvailabilityCheckControlData
} from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { AvailabilityCheckForm } from './components/AvailabilityCheckForm'

function AvailabilityCheckControl({
  data,
  handleChange,
  path,
  errors,
  uischema,
  required,
  visible
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const hasError = !!errors
  const options = uischema.options

  if (!isAvailabilityCheckControlOptions(options)) {
    console.error(
      'AvailabilityCheckControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isAvailabilityCheckControlData(data)) {
    console.error(
      'AvailabilityCheckControl -> Invalid data detected. Current data is:',
      {
        data
      }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <AvailabilityCheckForm
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        id={stepBlockId}
        isRequired={!!required}
        path={path}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: AvailabilityCheckControl }) as never
)
