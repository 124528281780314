import {
  Divider,
  Icon,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  useTheme
} from '@epilot/journey-elements'
import type { StepState } from '@epilot/journey-logic-commons'
import type {
  ControlElement,
  JsonFormsRendererRegistryEntry
} from '@jsonforms/core'
import { useEffect, useMemo } from 'react'

import type { JourneyLauncherControlOptions } from '../../renderers/controls/JourneyLauncherControl'
import type { JsonFormsOnChange } from '../../utils'
import { useJourneyContext } from '../../utils/context/JourneyContext'

import { EmptyJourneyLauncher } from './EmptyJourneyLauncher'
import { JourneyLauncherTabContent } from './JourneyLauncherTabContent'
import { useLauncherTabsStyles } from './styles'

type JourneyLauncherTabsProps = {
  data: StepState
  onChange: JsonFormsOnChange
  journeys: JourneyLauncherControlOptions['linkedJourneys']
  renderers: JsonFormsRendererRegistryEntry[]
  uiSchema?: ControlElement
}

export const JourneyLauncherTabs = ({
  journeys,
  renderers,
  uiSchema,
  data,
  onChange
}: JourneyLauncherTabsProps) => {
  const {
    context: { _activeLinkedJourneyId },
    updateContext
  } = useJourneyContext()
  const theme = useTheme()
  const classes = useLauncherTabsStyles({
    ctaColor: theme?.palette?.primary?.main,
    ctaText: theme?.palette?.primary?.contrastText
  })

  const selectedJourney = useMemo(
    () => journeys.find(({ id }) => id === _activeLinkedJourneyId),
    [_activeLinkedJourneyId, journeys]
  )

  const navigateToLinkedJourney = (linkedJourneyId: string) =>
    updateContext((value) => ({
      ...value,
      _activeLinkedJourneyId: linkedJourneyId
    }))

  useEffect(() => {
    if (journeys?.length === 0) {
      updateContext((value) => ({
        ...value,
        _activeLinkedJourneyId: undefined
      }))

      return
    }

    if (!selectedJourney) {
      const [firstJourney] = journeys

      updateContext((value) => ({
        ...value,
        _activeLinkedJourneyId: firstJourney.id
      }))
    }
  }, [_activeLinkedJourneyId, journeys, selectedJourney, updateContext])

  if (!journeys?.length) {
    return <EmptyJourneyLauncher />
  }

  if (!(_activeLinkedJourneyId && selectedJourney)) {
    return null
  }

  return (
    <div>
      <TabContext value={_activeLinkedJourneyId}>
        <div className={classes.tabContainer}>
          <TabList
            TabIndicatorProps={{ style: { display: 'none' } }}
            className={classes.tabs}
            scrollButtons="auto"
            variant="scrollable"
          >
            {journeys.map(({ id, label, icon }) => (
              <Tab
                className={[
                  classes.tabLabelWrapper,
                  classes.tab,
                  id === _activeLinkedJourneyId && classes.tabSelected
                ]
                  .filter(Boolean)
                  .join(' ')}
                icon={
                  icon ? (
                    <Icon
                      className={[classes.showOnLg, classes.noMargin].join(' ')}
                      fontSize="small"
                      name={icon}
                    />
                  ) : undefined
                }
                key={id}
                label={<span className={classes.tabLabel}>{label}</span>}
                onClick={() => navigateToLinkedJourney(id)}
                value={id}
              />
            ))}
          </TabList>
        </div>
        {!uiSchema?.options?.showPaper && (
          <Divider className={classes.divider} />
        )}
        <div className={classes.tabContentWrapper}>
          {journeys.map(({ id: journeyId }) => (
            <TabPanel key={journeyId} value={journeyId}>
              <JourneyLauncherTabContent
                data={data}
                journeyId={journeyId}
                onChange={onChange}
                renderers={renderers}
                uiSchema={uiSchema}
              />
            </TabPanel>
          ))}
        </div>
      </TabContext>
    </div>
  )
}
