import { fromEvent } from 'file-selector'
import { useCallback } from 'react'
import type { DropEvent } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'

import { Icon } from '../Icon'
import { useTheme } from '../styles'
import type { EpilotTheme } from '../ThemeProvider'
import { isDragEvent } from '../utils'

import type { UploadZoneProps } from './types'

/** *
 * @version 0.WIP 🚧 this component is still being designed by the designers and due for replacement soon
 */

export function UploadZone(props: UploadZoneProps) {
  const theme = useTheme() as EpilotTheme

  const {
    uploadIcon = <Icon name="cloud_upload" />,
    uploadMessage = 'Drop some files here ...',
    customDropZone,
    acceptedFilesMIME = 'font/*',
    disabled = false,
    dropAcceptedColor = theme.palette?.action.hover,
    dropRejectedColor = theme.palette?.error?.[50],
    onDrop,
    onDropAccepted,
    onDropRejected,
    disableClickEvent = false,
    containerStyle,
    minSize,
    maxSize,
    maxQuantity = 1,
    error = false,
    id
  } = props

  // state for the background to be used in rejected, approved...
  // the default color is coming from the theme
  const defaultBgColor = theme.palette?.background.default
  const defaultBorderColor = '#C5D0DB'

  const fileGetter = useCallback(async (event: DropEvent): Promise<File[]> => {
    const fileList = isDragEvent(event)
      ? Object.values(event.dataTransfer?.files || {})
      : await fromEvent(event)

    const files = fileList.map((file) => {
      const tempId: Array<string | number> = [event.timeStamp]

      let fileRef: File

      if (file instanceof File) {
        fileRef = file
      } else {
        const _file = file.getAsFile()

        if (!_file) {
          // this would only happen if the dropped item is a string instead, which shouldn't happen
          // @see https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/kind
          throw new Error(
            'UploadZone.fileGetter -> Expected a file but got null'
          )
        }

        fileRef = _file
      }

      tempId.push(
        fileRef.name,
        fileRef.size,
        fileRef.type,
        fileRef.lastModified
      )

      return Object.assign(fileRef, { tempId: tempId.join('-') })
    })

    return files
  }, [])

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      // limit the file sizes
      minSize,
      maxSize,

      // pass what files to accept
      accept: acceptedFilesMIME,

      // should allow multiple files upload (0 means unlimited)
      multiple: maxQuantity !== 1,

      // how many files can be uploaded
      maxFiles: maxQuantity,

      // is it disabled
      disabled,

      // disable click
      noClick: disableClickEvent,

      // callback when file is accepted
      onDropAccepted,

      // callback when file is rejected
      onDropRejected,

      // callback when files are dropped
      onDrop,

      // custom getter fn to retrieve files from the event
      getFilesFromEvent: fileGetter
    })

  return customDropZone ? (
    customDropZone
  ) : (
    <div
      {...getRootProps({ className: 'dropzone', id })}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        border: 1,
        borderStyle: 'dashed',
        borderRadius:
          theme.shape?.borderRadius !== undefined
            ? `min(${theme.shape.borderRadius}px, 20px)`
            : '4px',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'center',
        backgroundColor: defaultBgColor,
        borderColor: !error ? defaultBorderColor : theme.palette?.error.main,
        ...(isDragAccept
          ? {
              backgroundColor: dropAcceptedColor,
              borderColor: theme.palette?.primary.main
            }
          : {}),
        ...(isDragReject
          ? {
              backgroundColor: dropRejectedColor,
              borderColor: theme.palette?.error.main
            }
          : {}),
        ...containerStyle
      }}
    >
      <input {...getInputProps()} />
      <span style={{ padding: 20 }}>
        <span>{uploadIcon}</span>
        <span>{uploadMessage}</span>
      </span>
    </div>
  )
}
