import { isEnumControl, schemaTypeIs } from '@jsonforms/core'

import type { StepExtended } from '../blockController'
import { CONTROL_NAME } from '../types'

/**
 * Function trying to identify and return type of the block based on schema + uischema
 */
export const getBlockControlIdentifier = (
  blockSchema: StepExtended['schema'] | undefined,
  blockUiSchema: StepExtended['uischema'] | undefined
) => {
  const context = { config: {}, rootSchema: {} }

  if (blockSchema && blockUiSchema) {
    // Somehow schemaTypeIs has to be higher than isEnumControl as both will be true if the block is a required boolean
    if (schemaTypeIs('boolean')(blockUiSchema, blockSchema, context)) {
      return CONTROL_NAME.BOOLEAN_CONTROL
    }

    if (isEnumControl(blockUiSchema, blockSchema, context)) {
      return CONTROL_NAME.ENUM_CONTROL
    }

    if (
      schemaTypeIs('string')(blockUiSchema, blockSchema, context) &&
      blockUiSchema?.type === CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL
    ) {
      return CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL
    }

    // this is important to come before TEXTFIELD_CONTROL as they both use the same schema type
    if (blockUiSchema?.type === CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL) {
      return CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL
    }

    if (schemaTypeIs('string')(blockUiSchema, blockSchema, context)) {
      return CONTROL_NAME.TEXTFIELD_CONTROL
    }

    if (blockUiSchema?.type === CONTROL_NAME.METER_READING_DYNAMIC_CONTROL) {
      return CONTROL_NAME.METER_READING_CONTROL
    }
  }

  return blockUiSchema?.type as CONTROL_NAME
}
