import { useCallback, useEffect, useRef, useState } from 'react'

type ImageProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'onLoad, onError, key'
>

export const Image = (props: ImageProps) => {
  const { src, alt, style, ...rest } = props
  const [display, setDisplay] = useState<string>('none')

  const componentRef = useRef<number>()

  useEffect(() => {
    componentRef.current = 5
  }, [])

  const handleError = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ currentTarget }) => {
      if (componentRef && componentRef.current && componentRef.current > 0) {
        setTimeout(() => {
          currentTarget.onerror = null
          currentTarget.src = src
          componentRef.current =
            componentRef && componentRef.current && componentRef.current - 1
        }, 1000)
      }
    },
    [src]
  )

  const handleLoad = useCallback(() => {
    setDisplay('auto')
  }, [])

  const newStyle = {
    ...style,
    display: display
  }

  return (
    <img
      alt={alt}
      key={`${src} - ${display}`}
      onError={handleError}
      onLoad={handleLoad}
      src={src}
      style={newStyle}
      {...rest}
    />
  )
}
