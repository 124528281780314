import { H5, LargeText, Typography, clsx } from '@epilot/journey-elements'
import type { Step } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { IsMobile } from '../../utils/helper'

import { useWrapperStyles } from './styles'

export const EmbeddedStepTitle = ({ firstStep }: { firstStep: Step }) => {
  const mobile = IsMobile()
  const classes = useWrapperStyles()

  const hasRequiredField = firstStep?.schema?.required
    ? firstStep.schema.required.length > 0
    : false

  if (!firstStep) return null

  return (
    <div className={classes.wrapper}>
      <div>
        <div
          className={clsx(
            classes.container,
            firstStep.showStepName ? classes.spaceBetween : classes.flexEnd
          )}
        >
          {firstStep.showStepName && (
            <div className={mobile ? '' : classes.stepNameContainer}>
              <H5
                color="textPrimary"
                gutterBottom={Boolean(firstStep.showStepSubtitle)}
              >
                <b>{firstStep.title ?? firstStep.name}</b>
              </H5>
            </div>
          )}
          {!mobile && hasRequiredField && <RequiredLabel />}
        </div>

        {firstStep.showStepSubtitle && (
          <div
            className={
              mobile ? classes.mobileSubtitle : classes.desktopSubtitle
            }
          >
            <LargeText>
              <span>{firstStep.subTitle}</span>
            </LargeText>
          </div>
        )}
      </div>
      {mobile && hasRequiredField && (
        <div className={classes.labelContainer}>
          <RequiredLabel />
        </div>
      )}
    </div>
  )
}

const RequiredLabel: React.VFC = () => {
  const { t } = useTranslation()

  const classes = useWrapperStyles()

  return (
    <Typography
      className={classes.label}
      color="textSecondary"
      variant="caption"
    >
      {'* ' + t('input_required', 'Pflichteingabe')}
    </Typography>
  )
}
