import { Grid } from '@epilot/journey-elements'
import type {
  ConsentConfig,
  ConsentData,
  ConsentTopic
} from '@epilot/journey-logic-commons'

import { ConsentView } from './ConsentView'

export type ConsentsListProps = {
  configs: ConsentConfig[]
  value: Record<ConsentTopic, ConsentData>
  onChange: (value: Record<ConsentTopic, ConsentData>) => void
  error?: string
  id: string
}
export function ConsentsList({
  value,
  onChange,
  configs,
  error,
  id
}: ConsentsListProps) {
  return (
    <Grid className="notranslate" container translate="no">
      {configs.map((conf, i) => (
        <Grid item key={'config' + i} xs={12}>
          <ConsentView
            config={conf}
            error={error}
            id={id}
            onChange={onChange}
            value={value}
          />
        </Grid>
      ))}
    </Grid>
  )
}
