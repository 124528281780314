import type { ComponentType } from 'react'

import classes from '../../styles/TileBody.module.scss'
import type {
  GetAgComponentProps,
  ComponentMappingDataGraduatedProps,
  NormalizedSectionContainerProps
} from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

import { ProductCTA } from './ProductCTA'
import { ProductDescription } from './ProductDescription'
import { ProductFeatures } from './ProductFeatures'
import { ProductPrices } from './ProductPrices'
import { ProductPricesComponents } from './ProductPricesComponents'

export const TileBody = ({
  GetAgComponent,
  ComponentMappingDataGraduated,
  NormalizedSectionContainer
}: {
  GetAgComponent: ComponentType<GetAgComponentProps>
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
  NormalizedSectionContainer: ComponentType<NormalizedSectionContainerProps>
}) => {
  const { displayCtaButtonUnderPrice } = useTileContext()

  return (
    <article className={classes.container}>
      <NormalizedSectionContainer sectionName="concordeDescription">
        <ProductDescription />
      </NormalizedSectionContainer>
      <NormalizedSectionContainer sectionName="concordePrice">
        <ProductPrices GetAgComponent={GetAgComponent} />
      </NormalizedSectionContainer>
      <NormalizedSectionContainer
        className={classes.tileBodyBottom}
        sectionName="concordePriceComponents"
      >
        <ProductPricesComponents
          ComponentMappingDataGraduated={ComponentMappingDataGraduated}
        />
      </NormalizedSectionContainer>
      {displayCtaButtonUnderPrice && <ProductCTA />}
      <NormalizedSectionContainer sectionName="concordeFeatures">
        <ProductFeatures />
      </NormalizedSectionContainer>
      {!displayCtaButtonUnderPrice && <ProductCTA />}
    </article>
  )
}
