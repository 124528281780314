import type { AddressSuggestionsSource } from './types'

export const convertToAddressSuggestionsSourceType = (
  value: AddressSuggestionsSource[] | string | undefined
): AddressSuggestionsSource[] => {
  if (typeof value === 'string') {
    return [value as unknown as AddressSuggestionsSource]
  } else if (Array.isArray(value) && value.length !== 0) {
    return value as AddressSuggestionsSource[]
  } else {
    return ['deutschePostService']
  }
}
