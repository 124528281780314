import {
  Button,
  createStyles,
  makeStyles,
  MobileStepper,
  useTheme
} from '@epilot/journey-elements'
import ArrowCircleLeft from '@epilot360/icons/react/ArrowCircleLeft'
import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import { ProductImage } from './ProductImage'
import type { ImageFile } from './ProductImage'

interface ProductImageStepperProps {
  images: ImageFile[]
  id?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      maxHeight: 242,
      '& div': {
        height: '100%'
      }
    },
    imageContainer: {
      display: 'flex'
    },
    stepper: {
      backgroundColor: 'inherit'
    }
  })
)

export const ProductImageStepper = ({
  images,
  id
}: ProductImageStepperProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyles()
  const maxSteps = images.length

  if (images.length === 0) {
    return null
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <>
      <div className={classes.container}>
        <SwipeableViews
          enableMouseEvents
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {images.map((image, index) => {
            /**
             * Only render some images to optimize data usage
             */
            const isImageCloseToActiveStep = Math.abs(activeStep - index) <= 2

            return (
              <div className={classes.imageContainer} key={image._id}>
                {isImageCloseToActiveStep && <ProductImage image={image} />}
              </div>
            )
          })}
        </SwipeableViews>
        <ProductImageStepperButton
          handleOnClick={() =>
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
          }
          id={id && `${id}_back_button`}
          isBackButton={true}
          show={activeStep !== 0}
        />
        <ProductImageStepperButton
          handleOnClick={() =>
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }
          id={id && `${id}_next_button`}
          isBackButton={false}
          show={activeStep !== maxSteps - 1}
        />
      </div>
      <MobileStepper
        activeStep={activeStep}
        backButton={<div />}
        className={classes.stepper}
        id={id}
        nextButton={<div />}
        position="static"
        steps={maxSteps}
      />
    </>
  )
}

const useButtonStyles = makeStyles(() =>
  createStyles({
    button: {
      display: 'block',
      opacity: 0.8,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: ({ isBackButton }: { isBackButton: boolean }) =>
        isBackButton ? '0' : 'auto',
      right: ({ isBackButton }: { isBackButton: boolean }) =>
        !isBackButton ? '0' : 'auto'
    }
  })
)

const ProductImageStepperButton = ({
  isBackButton,
  handleOnClick,
  show,
  id
}: {
  isBackButton: boolean
  handleOnClick: () => void
  show: boolean
  id?: string
}) => {
  const theme = useTheme()

  const classes = useButtonStyles({
    isBackButton
  })
  const iconStyle = {
    transform: !isBackButton ? 'rotate(180deg)' : ''
  }

  if (!show) return null

  return (
    <Button
      className={classes.button}
      id={id}
      onClick={handleOnClick}
      size="small"
    >
      <ArrowCircleLeft
        fill={theme.palette.primary.main}
        style={iconStyle}
        variant="filled"
      />
    </Button>
  )
}
