/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { isEnumButtonControlData } from '../EnumButtonControl/types.guard'

import type { MeterReadingControlData } from './types'

export function isMeterReadingControlData(
  obj: any,
  _argumentName?: string
): obj is MeterReadingControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (isEnumButtonControlData(obj.maloId) as boolean) &&
      (isEnumButtonControlData(obj.meterId) as boolean) &&
      (isEnumButtonControlData(obj.meterType) as boolean) &&
      (isEnumButtonControlData(obj.readBy) as boolean) &&
      (typeof obj.readingDate === 'undefined' ||
        obj.readingDate === null ||
        obj.readingDate instanceof Date) &&
      (typeof obj.readingValue === 'undefined' ||
        obj.readingValue === null ||
        typeof obj.readingValue === 'number') &&
      (typeof obj.feedInValue === 'undefined' ||
        obj.feedInValue === null ||
        typeof obj.feedInValue === 'number') &&
      (typeof obj.feedOutValue === 'undefined' ||
        obj.feedOutValue === null ||
        typeof obj.feedOutValue === 'number') &&
      (typeof obj.ntValue === 'undefined' ||
        obj.ntValue === null ||
        typeof obj.ntValue === 'number') &&
      (typeof obj.htValue === 'undefined' ||
        obj.htValue === null ||
        typeof obj.htValue === 'number') &&
      (isEnumButtonControlData(obj.readingUnit) as boolean) &&
      (isEnumButtonControlData(obj.reason) as boolean))
  )
}
