import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles({
  container: {
    width: '100%'
  },
  ctaButton: {
    // using minWidth here, so button can auto extend if text larger than original space
    minWidth: '100%'
  },
  errorMessage: {
    marginTop: '0.5rem',
    width: 'fit-content',
    fontSize: '0.875rem',
    marginLeft: 'auto'
  },
  displayBlock: {
    display: 'block',
    visibility: 'visible'
  },
  displayNone: {
    display: 'none',
    visibility: 'hidden'
  }
})
