import _get from 'lodash/get'

import { parseLogicLiveValueFromPath } from './logicRegEx'

import type { LiveValue, LogicCondition } from '.'
import { isValidLiveValue } from '.'

/**
 * Computes the live value from the provided `initialValue` based on the `condition.liveValueFromPath`.
 * @param condition - The LogicCondition object containing the `liveValueFromPath`.
 * @param initialValue - The initial value from which the live value needs to be computed.
 * @returns The computed live value, which could be a string, an array of strings, or undefined.
 */
export const computeLiveValue = (
  condition: LogicCondition,
  initialValue: unknown
): LiveValue | undefined => {
  // Check if `condition.liveValueFromPath` is defined.
  if (condition.liveValueFromPath) {
    // Parse the liveValuePath from the condition.liveValueFromPath using logicRegEx.
    const liveValuePath = parseLogicLiveValueFromPath(
      condition.liveValueFromPath
    )

    // If liveValuePath is not successfully parsed, return undefined.
    if (!liveValuePath) {
      return undefined
    }

    // generate paths based on possible pipe separated string
    const paths = liveValuePath.split('|')

    // Check if `initialValue` is an array.
    if (Array.isArray(initialValue)) {
      // Use reduce to iterate over each item in the array and add the value by path to the resulting array
      const values = initialValue.reduce<string[]>((result, item) => {
        // Check if the item is an object and not null.
        if (typeof item === 'object' && item !== null) {
          // Get the value from the object using the parsed liveValuePath.
          const itemValue = attemptGetValueByPaths(item, paths)

          // If the value is a string, add it to the result array.
          if (typeof itemValue === 'string') {
            return [...result, itemValue]
          }
        }

        return result
      }, [])

      // If there are no valid string values in the array, return undefined.
      if (!values.length) {
        return undefined
      }

      // Return the array of computed values.
      return values
    }

    // If `initialValue` is an object, get the value using the parsed paths.
    if (typeof initialValue === 'object' && initialValue !== null) {
      const value = attemptGetValueByPaths(initialValue, paths)

      // If the value is a string, return it; otherwise, return undefined.
      if (typeof value === 'string') {
        return value
      }

      return undefined
    }
  }

  // Check if `initialValue` is a string or an array of strings.
  if (isValidLiveValue(initialValue)) {
    // Return `initialValue` as it is already a valid live value.
    return initialValue
  }

  // Return undefined if no valid live value is computed.
  return undefined
}

function attemptGetValueByPaths(
  input: unknown,
  paths: string[]
): string | undefined {
  const values = paths.map((path) => _get(input, path)).filter(Boolean)

  if (values.length && values.every((value) => typeof value === 'string')) {
    return values.join('|')
  }

  return
}
