import { functionalValidators, regExpStatments } from '@epilot/validators'
import { createAjv } from '@jsonforms/core'

export function initAJV() {
  const ajv = createAjv({
    allErrors: true,
    verbose: true,
    useDefaults: true
  })

  Object.keys(regExpStatments).forEach((key: string) => {
    ajv.addFormat(regExpStatments[key].name, regExpStatments[key].regex)
  })
  Object.keys(functionalValidators).forEach((key: string) => {
    ajv.addFormat(
      functionalValidators[key].name,
      functionalValidators[key].callback
    )
  })

  return ajv
}
