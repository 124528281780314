import { StepperInput as ConcordeStepperInput } from '@epilot/concorde-elements'
import { StepperInput } from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { ComponentProps, ChangeEvent } from 'react'

import { useTileContext } from '../../utils/tile-context-utils'

import { useProductQuantityStyles } from './styles'

export const ProductQuantityStepper = (
  props: ComponentProps<typeof StepperInput>
) => {
  const classes = useProductQuantityStyles()
  const { config, actions, tileState, useNewDesign, id } = useTileContext()
  const showQuantity = config.generalTileConfiguration.quantityConfig.show
  const { quantity } = tileState
  const { setQuantity, increaseQuantity, decreaseQuantity } = actions

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const inputValue = parseInt(e.target.value)
    const isValidNumber = !isNaN(inputValue) && isFinite(inputValue)

    if (isValidNumber) {
      return setQuantity(inputValue)
    }

    return setQuantity(0)
  }

  if (!showQuantity) return null

  const stepperId = appendStepBlockId(id, 'Quantity Stepper')

  return (
    <>
      {useNewDesign ? (
        <ConcordeStepperInput
          adornmentProps={{
            className: classes.stepperButton
          }}
          aria-label="Product quantity"
          className={classes.stepperInput}
          id={stepperId}
          isFullWidth={props.fullWidth}
          onChange={handleChange}
          onEndIconClick={increaseQuantity}
          onStartIconClick={decreaseQuantity}
          value={quantity}
        />
      ) : (
        <StepperInput
          id={stepperId}
          inputProps={{
            'aria-label': 'Product quantity',
            size: '3'
          }}
          onChange={handleChange}
          onEndIconClick={increaseQuantity}
          onStartIconClick={decreaseQuantity}
          value={quantity}
          {...props}
        />
      )}
    </>
  )
}
