export const FIELD_MAPPING = {
  ZIP_CITY: {
    label: 'zipCityLabel' as const,
    placeholder: 'zipCityPlaceholder' as const,
    name: 'zipCity' as const,
    targetName: 'zipCity' as const
  },
  STREET: {
    label: 'streetLabel' as const,
    placeholder: 'streetPlaceholder' as const,
    name: 'streetName' as const,
    targetName: 'streetName' as const
  },
  HOUSE: {
    label: 'housenumberLabel' as const,
    placeholder: 'housenumberPlaceholder' as const,
    name: 'housenumber' as const,
    targetName: 'houseNumber' as const
  },
  EXTENTION: {
    label: 'extentionLabel' as const,
    placeholder: 'extentionPlaceholder' as const,
    name: 'extention' as const,
    targetName: 'extention' as const
  }
}
