import type {
  AutoFillPIOptions,
  FieldOption,
  FieldOptionNumber,
  GoogleMapsIntegrationOptions,
  IconConfig,
  LayoutOptions,
  NumberSuggestion,
  SerializedBlockSummary
} from './sharedBlocksTypes'

import type {
  AddressFields,
  AddressSuggestionsSource,
  AvailabilityCheckLogicsConfiguratorOptions,
  AvailabilityMode,
  CUSTOMER_TYPE,
  InputCalculatorOptionDevice,
  ListedFields,
  UnlistedFields
} from '.'

/** @see {isPvRoofPlannerOptions} ts-auto-guard:type-guard */
export type PvRoofPlannerOptions = {
  relatedAvailabilityBlock: string | undefined // format: stepIndex/blockName/controlName (easy to get from the context)
  relatedNumberBlock: string | undefined // format: stepIndex/blockName/controlName (easy to get from the context)
  showRecommendAmountOfPanels: boolean | undefined
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  panelLifetimeYears: number | undefined
}

/** @see {isContactControlOptions} ts-auto-guard:type-guard */
export type ContactControlOptions = {
  label?: string
  mode?: string
  purposeLabels?: string[]
  fields: {
    salutation?: FieldOption & {
      genderType?: 'GENDER3' // @Deprecated
      options?: string[]
    }
    title?: FieldOption
    firstName?: FieldOption
    lastName?: FieldOption
    birthDate?: FieldOption<Date>
    email?: FieldOption
    telephone?: FieldOption
    customerType?: FieldOption<CUSTOMER_TYPE> & {
      options?: { value: CUSTOMER_TYPE; label?: string }[]
    }
    companyName?: FieldOption & {
      showIf?: { customerType: CUSTOMER_TYPE }
    }
    registryCourt?: FieldOption & {
      showIf?: { customerType: CUSTOMER_TYPE }
    }
    registerNumber?: FieldOption & {
      showIf?: { customerType: CUSTOMER_TYPE }
    }
  }
} & AutoFillPIOptions

/** @see {isAvailabilityCheckControlOptions} ts-auto-guard:type-guard */
export type AvailabilityCheckControlOptions = {
  fields: {
    zipCode: FieldOption & { layout?: LayoutOptions }
  }
  productAvailabilityServiceFields: AddressFields
  postalCodeAvailabilityFields: AddressFields
  allowedPostalCodesCommaSeparated?: string | null
  countryCode: string
  availabilityMode?: AvailabilityMode
  addressSuggestionsSource?:
    | AddressSuggestionsSource[]
    | AddressSuggestionsSource
  enableAutoComplete?: boolean
  enableFreeText?: boolean
  googleMapsIntegrationOptions?: GoogleMapsIntegrationOptions
  availabilityCheckLogicsConfiguratorOptions?: AvailabilityCheckLogicsConfiguratorOptions
} & AutoGeneratedRelations

type AutoGeneratedRelations = {
  autoGeneratedLogicsIds?: string[]
  autoGeneratedStepIds?: string[]
}

export type SummaryControlRendererOptions = {
  fields?: SerializedBlockSummary[]
  title?: string
  subTitle?: string
  showPaper?: boolean
}

/** @see {isInputCalculatorControlOptions} ts-auto-guard:type-guard */
export type InputCalculatorControlOptions = {
  deviceUnit?: string
  deviceOptions?: InputCalculatorOptionDevice[]
  frequencyUnit?: string
  required?: boolean
  allowOther?: boolean
  digitsAfterDecimalPoint?: number
}

type MultichoiceUiTypes = 'checkbox' | 'button' | 'imageButton'

export type MultichoiceControlComponentProps<T> = {
  value?: string[]

  /**
   * the type of the displayed ui
   * @default 'button'
   */
  uiType: MultichoiceUiTypes

  /**
   * the options to be used in the values
   * @default []
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[]

  /**
   * the labels of the options, if it was passed empty, the options themselfs will be used
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsLabels?: any[]

  /**
   * the image of the options, if it was passed empty
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsImages?: any[]
  imageType?: 'infographic' | 'icon'

  /**
   * default selection value
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSelection?: any[]
  hasError?: boolean
  maxSelection?: number
  optionsIcons?: (IconConfig | undefined)[]
  /**
   * the display look of the control.
   * @default "input"
   */
  displayMode?: 'input' | 'display'

  /**
   * a callback that will be triggered when the user makes a change
   */
  onChange: (value: any) => void

  /**
   * marking the control as required
   */
  required?: boolean

  /**
   * disable editing in the control
   */
  disabled?: boolean

  /**
   * error message to be shown in the control, the control will take care of changing its style
   */
  errorMessage?: string

  /**
   * a small text explaining the input, it will be replaced with the errorMessage if it was passed
   */
  helperText?: string

  /**
   * label of the input
   */
  label: string | T

  /**
   * a place holder / example to be filled in the input
   * NOTE: the control will fall back to the label as a placeholder if the placeholder is not passed
   */
  placeholder?: string

  /**
   * the size of the control
   * @default "medium"
   */
  size?: 'small' | 'medium'

  /**
   * id of the input
   */
  id: string
}

export type ActionBarControlOptions = {
  ctaButton: ActionGoNext | ActionSubmitAndGoNext
  goBackButton: ActionGoBack
  consents: ConsentBox[]
}

export type ActionGoNext = ActionCommon & {
  actionType: ACTION_TYPE.GO_NEXT
  targetStepId: string
}

export type ActionGoBack = ActionCommon & {
  actionType: ACTION_TYPE.GO_BACK
}

/**
 * @private Should not be exported
 */
type ActionCommon = {
  label: string
  isVisible: boolean
}

export type Action = ActionSubmitAndGoNext | ActionGoNext | ActionGoBack

export type ConsentBox = {
  name: string
  text: string
  isRequired: boolean
  isVisible: boolean
}

export type ActionSubmitAndGoNext = ActionCommon & {
  actionType: ACTION_TYPE.SUBMIT_GO_NEXT
  targetStepId: string
}

export enum ACTION_TYPE {
  SUBMIT_GO_NEXT = 'SubmitAndGoNext',
  GO_NEXT = 'GoNext',
  GO_BACK = 'GoBack'
}

/** @see {isNumberInputControlOptions} ts-auto-guard:type-guard */
export type NumberInputControlOptions<T> = {
  fields: {
    numberInput: FieldOptionNumber
  }
  required?: boolean
  suggestions?: NumberSuggestion[]
  iconInputName?: T
  input_icon?: IconConfig
}

export type AddressControlOptionsDto<GridProps> =
  | AddressControlOptions
  | AddressControlOptionsLegacy<GridProps>

/** @see {isAddressControlOptions} ts-auto-guard:type-guard */
export type AddressControlOptions = {
  label?: string
  acceptSuggestedOnly?: boolean
  isBilling?: boolean
  isDelivery?: boolean
  labels?: string[]
  supportUnlisted?: boolean
  fields: ListedFields
  unlistedFields: UnlistedFields
  countryAddressSettings?: CountryAddressSettings
  addressSuggestionsSource?:
    | AddressSuggestionsSource[]
    | AddressSuggestionsSource
  googleMapsIntegrationOptions?: GoogleMapsIntegrationOptions
} & AutoFillAddressOptions &
  InjectAddressOptions

type AutoFillAddressOptions = {
  isAutoFillAddressEnabled?: boolean
  autoFillAddressSettings?: {
    relatedBlock?: string // format: stepId/blockName
  }
}

type InjectAddressOptions = {
  isInjectAddressEnabled?: boolean
  injectAddressSettings?: {
    relatedBlock?: string // format: stepId/blockName
  }
}

type CountryAddressSettings = {
  countryCode?: string
  enableAutoComplete?: boolean
  enableFreeText?: boolean
}

export type AddressControlOptionsLegacy<GridProps> =
  AddressModuleProps<GridProps> & {
    integrationAPI?: string
    /**
     * How the fields should be displayed & ordered after enet requests more fields
     */
    fieldsOrderAfterIntegration?: AddressModuleProps<GridProps>['fieldsOrder']

    /**
     * which fields after enet request
     */
    requiredFieldsAfterIntegration?: AddressModuleProps<GridProps>['requiredFields']

    /**
     * a callback that will be called once the user selects a zip code
     */
    onZipSelected?: (address?: Address, errors?: string[]) => Promise<boolean>
  }

type AddressModuleProps<GridProps> = {
  /**
   * initial address
   */
  initialAddress?: Address

  /**
   * setting this to true, means the user input will not be accepted if it is not one of the suggested values
   * IMPORTANT: it will not work if streetAddressAPI & cityZipAPI were not passed.
   * MAKE SURE streetAddressAPI & cityZipAPI are correct
   * @default false
   */
  acceptOnlySuggestedAddresses?: boolean

  /**
   * Error message displayed in the dropdown list when no zip and city is found.
   * * @default 'Postleitzahl nicht gefunden'
   */
  noOptionsTextZipCity?: string

  /**
   * Error message displayed in the dropdown list when no street is found.
   * * @default 'Straße nicht gefunden'
   */
  noOptionsTextStreet?: string

  /**
   * Error message displayed if entered zip and city is not one of the suggested.
   * Only works when acceptOnlySuggestedAddresses = true
   * * @default 'Bitte einen Vorschlag aus der Liste auswählen.'
   */
  noOptionSelectedTextZipCity?: string

  /**
   * Error message displayed if entered street is not one of the suggested.
   * Only works when acceptOnlySuggestedAddresses = true
   * * @default 'Bitte einen Vorschlag aus der Liste auswählen.'
   */
  noOptionSelectedTextStreet?: string

  /**
   * API end point for the zip code / city suggestions
   */
  cityZipAPI?: string

  /**
   * API end point that will be used for street names suggestion
   */
  streetAddressAPI?: string

  /**
   * API end point that will be used sending feedback about street names suggestions
   */
  feedbackAPI?: string

  /**
   * labels for the fields
   */
  labels: {
    streetLabel?: string
    zipLabel?: string
    housenumberLabel?: string
    cityLabel?: string
    zipCityLabel?: string
    extentionLabel?: string
    countryLabel?: string
  }

  /**
   * placeholders for the fields
   */
  placeholders?: {
    streetPlaceholder?: string
    zipPlaceholder?: string
    housenumberPlaceholder?: string
    cityPlaceholder?: string
    zipCityPlaceholder?: string
    extentionPlaceholder?: string
    countryPlaceholder?: string
  }

  /**
   * mark fields as required
   */
  requiredFields?: {
    streetName?: boolean // v3
    street?: boolean // v2
    zip?: boolean
    housenumber?: boolean
    city?: boolean
    zipCity?: boolean
    extention?: boolean
    country?: boolean
  }

  /**
   * mark fields as disabled
   */
  disabledFields?: {
    streetName?: boolean // v3
    street?: boolean // v2
    zip?: boolean
    housenumber?: boolean
    city?: boolean
    zipCity?: boolean
    extention?: boolean
    country?: boolean
  }

  /**
   * grid spacing
   * @default 1
   */
  gridSpacing?: GridSpacing

  /**
   * a callback that will be called once there is an update to the address
   */
  onChange?: (address?: Address, errors?: string[]) => void

  /**
   * a callback that will be called once there are errors, the name of the fields with errors will be passed
   */
  onErrors?: (errors?: string[]) => void

  /**
   * i18n of the input
   * @default 'de'
   */
  lang?: 'en' | 'de'

  /**
   * Regex for the zip / city
   * @default " / "
   */
  zipCitySplitString?: string

  /**
   * starts suggesting after this length
   * this means when the user enters the 3rd cahr, the module will start suggesting data
   * @default 2
   */
  suggestStreetsAfter?: number

  /**
   * starts suggesting after this length
   * this means when the user enters the 2rd cahr, the module will start suggesting data
   * @default 1
   */
  suggestZipsAfter?: number

  /**
   * How the fields should be displayed & ordered
   */
  fieldsOrder: {
    groupName?: string
    gridItemProps?: GridProps
    name: AddressFieldName
  }[]

  /**
   * props to be passed to the grid container
   */
  containerProps?: GridProps

  /**
   * disable the fields
   */
  disabled?: boolean

  /**
   * if a string was sent, it will be displayed under the module and turn the components red
   */
  error?: string

  /**
   * if true, the required * (asterisk) will be hide
   * @default false
   */
  hideRequiredAsterisk?: boolean
}

type AddressFieldName =
  | 'COUNTRY'
  | 'STREET'
  | 'HOUSE'
  | 'ZIP'
  | 'CITY'
  | 'EXTENTION'
  | 'ZIP_CITY'
  | ''

type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

type Address = {
  streetName?: string
  houseNumber?: string
  zipCode?: string
  city?: string
  personName?: string
  /**
   * ex. used for c/o ****
   */
  addressExtention?: string
  countryCode?: string
}

/** @see {isMeterReadingControlOptions} ts-auto-guard:type-guard */
export type MeterReadingControlOptions = {
  fields: MeterReadingFields
  entity_updating_mode?: boolean
  required?: boolean
}

export type MeterReadingFields = {
  maloId?: FieldOption
  meterId?: FieldOption
  meterType?: FieldOption & {
    showOneDirectional?: boolean
    showTwoDirectional?: boolean
    showBiDirectional?: boolean
    value?: string
  }
  readBy?: FieldOption
  readingDate?: FieldOption<Date>
  readingValue: FieldOptionNumber
  reason?: FieldOption
}

/** @see {isAvailabilityCheckControlData} ts-auto-guard:type-guard */
export type AvailabilityCheckControlData = AvailabilityCheck | undefined | null

export type AvailabilityCheck = {
  zipCode?: string
  city?: string
  countryCode: string
  streetName?: string
  streetNumber?: string
  coordinates?: string
}
