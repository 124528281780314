import { Collapse, FormLabel, EnumControl } from '@epilot/journey-elements'
import { useEffect } from 'react'

import type { PaymentInformationValue } from '../PaymentInformation'

import { usePaymentInfoStyles } from './styles'
import { PAYMENT_METHOD } from './types'
import type { PaymentInformationProps, PaymentMethod } from './types'

export function PaymentInformation(props: PaymentInformationProps) {
  const {
    paymentMethods: paymentMethodsInitial,
    color = 'primary',
    disabled,
    onPaymentMethodSelected,
    value,
    error,
    initialType,
    uiType = 'radio',
    required,
    id,
    ...rest
  } = props
  const classes = usePaymentInfoStyles()
  const paymentMethods =
    paymentMethodsInitial?.map((method) =>
      method.type === 'BankTransfer'
        ? { ...method, type: PAYMENT_METHOD.TRANSFER }
        : method.type === 'SEPA'
          ? { ...method, type: PAYMENT_METHOD.SEPA }
          : method
    ) || []

  const selectedMethod: PaymentMethod | null =
    value && value.type
      ? paymentMethods[getPaymentIndex(paymentMethods, value.type as string)]
      : null

  const InformationComponent = selectedMethod
    ? selectedMethod.informationComponent
    : () => <span></span>
  const componentProps = selectedMethod ? selectedMethod.componentProps : {}

  const initialTypeValue =
    initialType === 'BankTransfer'
      ? PAYMENT_METHOD.TRANSFER
      : initialType === 'SEPA'
        ? PAYMENT_METHOD.SEPA
        : undefined

  const handleChange = (value?: PaymentInformationValue) => {
    const labelIndex = paymentMethods.findIndex((pm) => pm.type === value?.type)
    const label = paymentMethods[labelIndex]?.label

    value = {
      ...value,
      label: label
    }
    // call the callback
    if (onPaymentMethodSelected) {
      onPaymentMethodSelected(value, paymentHasErrors(value, componentProps))
    }
  }

  useEffect(() => {
    // initialise JSONForms data with selected type if only one given
    // TODO replace this with a better solution
    if (!value && paymentMethods.length === 1) {
      setTimeout(() => {
        handleChange({
          type: paymentMethods[0].type as PAYMENT_METHOD | undefined,
          data: undefined
        })
      }, 200)
    }
  }, [paymentMethods.length, value, required])

  useEffect(() => {
    if (!value && initialType) {
      setTimeout(() => {
        handleChange({ type: initialTypeValue })
      }, 200)
    }
  }, [initialType, value, required])

  return (
    <div {...rest}>
      {paymentMethods.length === 1 ? (
        <FormLabel>{paymentMethods[0].label}</FormLabel>
      ) : (
        <div
          className={uiType === 'toggle' ? classes.toggleBox : classes.otherBox}
        >
          <EnumControl
            color={color}
            defaultSelection={initialTypeValue}
            disabled={disabled}
            errorMessage={error && !isValidField(value) ? error : ''}
            id={id}
            label=""
            onChange={(value: PAYMENT_METHOD) =>
              handleChange({ type: value, data: undefined })
            }
            options={paymentMethods.map((pm) => pm.type)}
            optionsLabels={paymentMethods.map((pm) => pm.label)}
            uiType={uiType}
            value={value?.type}
          />
        </div>
      )}

      <Collapse in={selectedMethod !== null} timeout="auto" unmountOnExit>
        <InformationComponent
          error={error}
          {...componentProps}
          id={id}
          onChange={(v: PaymentInformationValue['data']) =>
            handleChange({ ...value, data: v })
          }
          required={required}
          value={value?.data}
        />
      </Collapse>
    </div>
  )
}
function isValidField(value?: PaymentInformationValue) {
  return !(value?.type === undefined || (value && value.type.length === 0))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasConsentLabel = (arg: any): arg is { consentLabel?: unknown } => {
  return !!(arg && 'consentLabel' in arg)
}

function paymentHasErrors(
  paymentInfoValues: PaymentInformationValue | undefined,
  options: unknown
) {
  // if there is error in payment Info
  let hasFieldsError = false

  if (paymentInfoValues) {
    if (paymentInfoValues?.type === PAYMENT_METHOD.SEPA) {
      const consentError =
        hasConsentLabel(options) &&
        !!options.consentLabel &&
        !paymentInfoValues?.data?.consent

      hasFieldsError =
        !paymentInfoValues?.data ||
        !paymentInfoValues?.data?.fullname ||
        !paymentInfoValues?.data?.iban ||
        consentError
    }
  }

  return hasFieldsError
}
function getPaymentIndex(payments: PaymentMethod[], searchInput: string) {
  for (let i = 0; i < payments.length; i++) {
    if (searchInput === payments[i].type) {
      return i
    }
  }

  return 0
}
