/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { isEnumButtonControlData } from '../EnumButtonControl/types.guard'

import type { NumberInputControlData } from './types'

export function isNumberInputControlData(
  obj: any,
  _argumentName?: string
): obj is NumberInputControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.numberInput === 'undefined' ||
        obj.numberInput === null ||
        typeof obj.numberInput === 'string') &&
      (isEnumButtonControlData(obj.numberUnit) as boolean) &&
      (isEnumButtonControlData(obj.frequencyUnit) as boolean) &&
      (typeof obj._isValid === 'undefined' ||
        obj._isValid === false ||
        obj._isValid === true))
  )
}
