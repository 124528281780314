import type {
  SkipStepLogic,
  StepState,
  LogicCondition,
  DefaultStepMap,
  Step
} from '@epilot/journey-logic-commons'
import {
  LogicTriggerEventName,
  isConditionTrue
} from '@epilot/journey-logic-commons'

import type { SkipStepConditionsStatus } from '../../utils/types'

/**
 * Apply skip step logics
 */
export const applySkipStepLogics = async (
  skipStepLogics: SkipStepLogic[][],
  steps: Step[],
  stepsState: StepState[],
  stepIndex: number,
  triggerEvent: string,
  skipStepConditionsStatus: SkipStepConditionsStatus,
  triggerBlockNames: string[] | undefined
) => {
  const stepSkipLogics = skipStepLogics?.[stepIndex]

  // check if there is any skip step logic to apply
  const stepSkipLogicsToApply = triggerEvent
    ? stepSkipLogics?.filter((logic) => {
        if (triggerEvent === LogicTriggerEventName.VALUE_CHANGE) {
          return (
            logic.conditionsRelatedToStep[0].event === triggerEvent &&
            (triggerBlockNames?.includes(logic.blockName) || !triggerBlockNames)
          )
        }

        // we don't support other events yet (by default we have this logic type only to support value change)
        return false
      }) || []
    : []

  // evaluate skip step logics
  // for each logics to apply check if conditions are met to skip, if so update status
  for (const logic of stepSkipLogicsToApply) {
    const actions = logic.actionsIncludesSkip
    const conditions = logic.conditionsRelatedToStep
    const blockName = logic.blockName

    // possible comma separated list of step ids
    const targetStepIdList = actions[0].targetStepId
      ?.split(',')
      ?.filter(Boolean)

    const blockState =
      stepsState[stepIndex]?.[blockName] === undefined
        ? false
        : stepsState[stepIndex]?.[blockName]

    if (blockName && conditions && conditions.length > 0 && actions) {
      const skip = await evaluateCondition(
        conditions[0],
        blockState,
        actions[0].conditionResult
      )

      targetStepIdList?.forEach((targetStepId) => {
        const targetStepIndex = steps.findIndex(
          (step) => step.stepId === targetStepId
        )
        const logicId = logic._tempId?.split('::')?.pop()

        registerSkipStepConditionsStatus(
          skipStepConditionsStatus,
          targetStepIndex,
          targetStepId,
          stepIndex,
          blockName,
          skip,
          logicId
        )
      })
    }
  }

  return {
    logicsCount: stepSkipLogicsToApply.length
  }
}

/*
 * Find skip step conditions status
 */
export const isStepSkipped = (
  skipStepConditionsStatus: SkipStepConditionsStatus,
  stepIdx: number
) => {
  const skipStepConditionsStatusForTargetStep =
    skipStepConditionsStatus[stepIdx]

  if (!skipStepConditionsStatusForTargetStep) {
    return false
  }

  const isSkipped = Object.keys(skipStepConditionsStatusForTargetStep).some(
    (trigger) => skipStepConditionsStatusForTargetStep[trigger] === true
  )

  return isSkipped
}

/**
 * Evaluate skip step condition
 */
export const getNextNonSkippedStepIndex = (
  stepIndexCandidate: number,
  skipStepConditionsStatus: SkipStepConditionsStatus,
  computedDefaultNextStepMap: DefaultStepMap
) => {
  if (isStepSkipped(skipStepConditionsStatus, stepIndexCandidate)) {
    // if the step is skipped, we need to find the pssible next non skipped step
    const nextStepIndex = computedDefaultNextStepMap[stepIndexCandidate]

    // if there is no next step, we return the current step index (we don't want to skip the last step)
    if (nextStepIndex === undefined) {
      return stepIndexCandidate
    }

    // if there is a next step, we check if it is skipped until we find a non skipped step
    return getNextNonSkippedStepIndex(
      nextStepIndex,
      skipStepConditionsStatus,
      computedDefaultNextStepMap
    )
  }

  return stepIndexCandidate
}

/**
 * Register the step visibility status.
 */
const registerSkipStepConditionsStatus = (
  stepVisibilityStatus: SkipStepConditionsStatus,
  targetStepIndex: number,
  _targetStepId: string,
  triggerStepIndex: number,
  triggerBlockName: string,
  skipSatus: boolean,
  logicId?: string
) => {
  if (!stepVisibilityStatus[targetStepIndex]) {
    stepVisibilityStatus[targetStepIndex] = {}
  }

  stepVisibilityStatus[targetStepIndex][
    `${triggerStepIndex}/${triggerBlockName}${logicId ? `/${logicId}` : ''}`
  ] = skipSatus
}

/**
 * Evaluate the condition.
 */
const evaluateCondition = async (
  condition: LogicCondition,
  blockState: unknown,
  conditionResult: boolean
) => {
  return (await isConditionTrue(condition, blockState)) === conditionResult
    ? true
    : false
}
