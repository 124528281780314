import type { TileLayoutSection } from '@epilot/journey-logic-commons'
import { useLayoutEffect } from 'react'
import type { MutableRefObject } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useTileLayoutContext } from '../TileLayoutContext'

export const useTileSectionHeight = (
  ref: MutableRefObject<HTMLDivElement | null>,
  tileLayoutSection: TileLayoutSection
) => {
  const [heightMap, setHeightMap] = useTileLayoutContext()

  const handleResize = useDebouncedCallback<ResizeObserverCallback>(
    () => {
      const height = ref.current?.clientHeight

      setHeightMap((prevHeightMap) => {
        const sectionHeightMap = prevHeightMap.get(tileLayoutSection)
        const sectionHeight = sectionHeightMap?.get(ref)

        // Do not update heightMap if height is the same
        if (sectionHeight === height) {
          return prevHeightMap
        }

        const heightMap = new Map(prevHeightMap)
        const sectionMap = heightMap.get(tileLayoutSection) ?? new Map()

        sectionMap.set(ref, height)
        heightMap.set(tileLayoutSection, sectionMap)

        return heightMap
      })
    },
    1000,
    { leading: true }
  )

  useLayoutEffect(() => {
    if (!ref.current || !window.ResizeObserver) return

    const observer = new window.ResizeObserver(handleResize)

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [ref, handleResize])

  const sectionHeightMap = heightMap.get(tileLayoutSection)
  const sectionHeights = sectionHeightMap?.values()
  const minHeight = sectionHeights ? Math.max(...sectionHeights) : 0

  return minHeight
}
