import { BodyText, Grid } from '@epilot/journey-elements'
import type {
  Price,
  BillingPeriod,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import { useStyles } from './styles'

interface CartTotalRecurrenceProps {
  t: TFunction
  showTrailingDecimalZeros?: boolean
  currency?: string
  shouldHideRecurrence?: boolean
  recurrence: RecurrenceAmount
  showProvisional: boolean
}

export const CartTotalRecurrence = ({
  recurrence,
  t,
  showTrailingDecimalZeros,
  currency,
  shouldHideRecurrence,
  showProvisional
}: CartTotalRecurrenceProps) => {
  const classes = useStyles()

  const totalText = showProvisional ? t('provisional_total') : t('total')

  return (
    <Grid container item>
      <Grid item xs={8}>
        {!shouldHideRecurrence && (
          <BodyText color="primary">
            <strong>
              {t(
                getPriceType(
                  recurrence.type as Price['type'],
                  recurrence.billing_period as BillingPeriod
                )
              )}
            </strong>
          </BodyText>
        )}
        {shouldHideRecurrence && (
          <BodyText color="primary">
            <strong>{totalText}</strong>
          </BodyText>
        )}
      </Grid>
      <Grid
        className={classes.priceContainer}
        item
        justifyContent="flex-end"
        xs={4}
      >
        <BodyText color="primary" data-testid="cart-header-line-amount">
          <strong>
            {/** TODO: if the items have different currencies, what we do? */}

            {getDisplayPrice(
              t,
              shouldShowDecimals(recurrence.amount_total)
                ? recurrence.amount_total_decimal
                : recurrence.amount_total,
              undefined,
              showTrailingDecimalZeros,
              currency
            )}
          </strong>
        </BodyText>
      </Grid>
    </Grid>
  )
}
