import { Typography as ConcordeTypography } from '@epilot/concorde-elements'
import { BodyText, Typography } from '@epilot/journey-elements'
import type { BillingPeriod, Price } from '@epilot/journey-logic-commons'
import {
  getPriceDisplayText,
  getPriceType
} from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'

import { useNewDesignStyles, usePriceItemStyles } from './styles'

export interface PriceItemProps {
  priceType?: Price['type']
  billingPeriod?: BillingPeriod
  priceDisplayInJourneys?: Price['price_display_in_journeys']
  amount?: number | string
  discountAmount?: number | string
  beforeDiscountAmountToDisplay?: number | string
  inline?: boolean
  currency?: string
  size?: 'large' | 'medium' | 'small'
  hideOneTimeLabel?: boolean
  isPriceRecurringTotal?: boolean
}

export const ProductPriceItem = (props: PriceItemProps) => {
  const { actions, config, useNewDesign } = useTileContext()
  const {
    amount,
    discountAmount,
    beforeDiscountAmountToDisplay,
    priceType,
    currency,
    billingPeriod,
    priceDisplayInJourneys,
    size = 'large',
    hideOneTimeLabel,
    isPriceRecurringTotal
  } = props

  const classes = usePriceItemStyles({
    type: 'normal',
    inline: Boolean(props.inline),
    size
  })

  const newDesignClasses = useNewDesignStyles()

  const {
    prefix,
    formattedPrice,
    formatedPriceDiscount,
    formatedPriceBeforeDiscount
  } = getPriceDisplayText({
    t: actions.t,
    amount,
    discountAmount,
    beforeDiscountAmountToDisplay,
    priceDisplayInJourneys,
    showTrailingDecimalZeros:
      config.generalTileConfiguration.showTrailingDecimalZeros,
    currency
  })
  const displayType = getPriceType(priceType, billingPeriod)

  const isOneTimePrice = priceType === 'one_time' || !priceType
  const shouldHideLabel = isOneTimePrice && hideOneTimeLabel

  const priceClassName = isPriceRecurringTotal
    ? newDesignClasses.priceRecurringTotal
    : newDesignClasses.price
  const priceTag = isPriceRecurringTotal ? 'h5' : 'h3'

  return (
    <>
      {useNewDesign ? (
        <div className={newDesignClasses.container}>
          <div className={newDesignClasses.priceSection}>
            <ConcordeTypography as={priceTag} className={priceClassName}>
              {prefix && <span>{prefix} </span>}
              {formattedPrice !== undefined && <span>{formattedPrice}</span>}
            </ConcordeTypography>

            <ConcordeTypography as="p">
              {formatedPriceBeforeDiscount !== undefined && (
                <span className={classes.priceBeforeDiscount}>
                  {formatedPriceBeforeDiscount}
                </span>
              )}
              {formatedPriceDiscount !== undefined && (
                <span className={classes.discount}>
                  -{formatedPriceDiscount}
                </span>
              )}
            </ConcordeTypography>
          </div>
          {priceDisplayInJourneys !== 'show_as_on_request' &&
            !shouldHideLabel && (
              <ConcordeTypography as="h6" className={priceClassName}>
                {actions.t(displayType)}
              </ConcordeTypography>
            )}
        </div>
      ) : (
        <div className={classes.itemContainer}>
          <div>
            {prefix && (
              <Typography className={classes.prefix} display="inline">
                {prefix}
              </Typography>
            )}
            {formattedPrice !== undefined && (
              <Typography className={classes.price} display="inline">
                {formattedPrice}
              </Typography>
            )}
            <br />
            {formatedPriceBeforeDiscount !== undefined && (
              <Typography
                className={classes.priceBeforeDiscount}
                display="inline"
              >
                {formatedPriceBeforeDiscount}
              </Typography>
            )}
            {formatedPriceDiscount !== undefined && (
              <Typography className={classes.discount} display="inline">
                -{formatedPriceDiscount}
              </Typography>
            )}
          </div>
          {priceDisplayInJourneys !== 'show_as_on_request' &&
            !shouldHideLabel && (
              <BodyText className={classes.title}>
                {actions.t(displayType)}
              </BodyText>
            )}
        </div>
      )}
    </>
  )
}
