import { Grid } from '@epilot/journey-elements'
import type { RecurrenceAmount, Price } from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { Fragment } from 'react'

import type { PriceItemWithBlockConfiguration } from '../../types'

import { CartTotalRecurrence } from './CartTotalRecurrence'
import { CartTotalRecurrenceDiscount } from './CartTotalRecurrenceDiscount'
import { useStyles } from './styles'

type CartTotalsProps = {
  t: TFunction
  showTrailingDecimalZeros?: boolean
  sortedAggregatedPrices: RecurrenceAmount[]
  currency?: string
  items: PriceItemWithBlockConfiguration[]
}

export const CartTotals = ({
  sortedAggregatedPrices,
  t,
  showTrailingDecimalZeros,
  currency,
  items
}: CartTotalsProps) => {
  const classes = useStyles()

  const hasOnlyOnetimeRecurrences = sortedAggregatedPrices.every(
    (recurrence) => recurrence.type === 'one_time' || !recurrence.type
  )

  const showProvisional = (items as Price[]).some(
    (item) =>
      item?.price_display_in_journeys === 'show_as_on_request' ||
      item?.price_display_in_journeys === 'show_as_starting_price'
  )

  return (
    <Grid className={classes.container} container>
      {sortedAggregatedPrices.map((recurrence) => (
        <Fragment
          key={`aggregated-recurrence-${
            recurrence.billing_period || recurrence.type
          }`}
        >
          {typeof recurrence.discount_amount !== 'undefined' &&
            !showProvisional && (
              <CartTotalRecurrenceDiscount
                currency={currency}
                recurrence={recurrence}
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                t={t}
              />
            )}
          <CartTotalRecurrence
            currency={currency}
            recurrence={recurrence}
            shouldHideRecurrence={
              hasOnlyOnetimeRecurrences &&
              (recurrence.type === 'one_time' || !recurrence.type)
            }
            showProvisional={showProvisional}
            showTrailingDecimalZeros={showTrailingDecimalZeros}
            t={t}
          />
        </Fragment>
      ))}
    </Grid>
  )
}
