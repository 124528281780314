import { Hidden, LargeText, UploadPanel, Icon } from '@epilot/journey-elements'
import type {
  S3File,
  UploadPanelProps,
  UploadFileHandlerCallback
} from '@epilot/journey-elements'
import { UploadFileTypesMapper } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { uploadFile } from '../../../api'
import { useConfig, useJourneyContext, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { isUploadPanelControlOptions } from './types.guard'

export function UploadPanelControlPure({
  uischema,
  schema,
  data,
  handleChange,
  path,
  errors,
  required,
  visible
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const { t } = useTranslation()
  const { context, sessionIdGetter } = useJourneyContext()
  const orgId = context.journey.organizationId
  const [uploadedFiles, setUploadedFiles] = useState<S3File[]>(data || [])
  const { FILE_API_URL } = useConfig()
  const options = uischema.options

  if (!isUploadPanelControlOptions(options)) {
    // eslint-disable-next-line no-console
    console.error(
      'UploadPanelControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )
    throw new Error('Invalid uischema.options detected')
  }

  const supportedTypes = useMemo(() => {
    let supportedTypes: string = Array.from(
      UploadFileTypesMapper.values()
    ).join(',')

    if (options?.restricted && options?.supportedTypes) {
      const allowedTypes = options?.supportedTypes.split(',')

      supportedTypes = allowedTypes
        .map((item: string) => UploadFileTypesMapper.get(item))
        .join(',')
    }

    return supportedTypes
  }, [options?.restricted, options?.supportedTypes])

  const uploadFileHandler: UploadFileHandlerCallback = async (
    formData: FormData,
    config
  ) => {
    const file = formData.get('file') as File
    const journeySessionId = sessionIdGetter?.()
    let metadata: Record<string, string> | undefined
    let indexTag: string | undefined

    // If a journey session id is available, use it as the index tag
    // and metadata for the uploaded file, which helps to associate the submitted file
    // with a given journey session/submission if we ever need to
    if (journeySessionId) {
      indexTag = journeySessionId
      metadata = { journey_session_id: journeySessionId }
    }

    const s3File = await uploadFile({
      ...config,
      file,
      orgId,
      indexTag,
      metadata,
      fileApiUrl: FILE_API_URL
    })

    s3File._tags = options?.tags

    setUploadedFiles((prev) => prev.concat(s3File))
  }

  const deleteFileHandler = (deletedFile: S3File) => {
    setUploadedFiles((prev) =>
      prev.filter(
        (file: S3File) => file.original_name !== deletedFile.original_name
      )
    )
  }

  // broadcast the changes to the parent component every time the list of uploaded files is changed
  useEffect(
    () =>
      void handleChange(
        path,
        // cast empty array to undefined so that the "required" rule actually works
        uploadedFiles.length ? uploadedFiles : undefined
      ),
    [handleChange, path, uploadedFiles]
  )

  const panelProps: UploadPanelProps = {
    labels: {
      Progress: t('uploading', 'Uploading...'),
      Successful: t('complete', 'Complete'),
      Failed: t('failed', 'Failed'),
      Cancelled: t('cancelled', 'Cancelled')
    },
    filesTableProps: {
      labels: {
        fileName: t('file_name')
      }
    },
    uploadZoneProps: {
      acceptedFilesMIME: supportedTypes,
      uploadMessage: (
        <div>
          <LargeText>{`${
            options?.zoneLabel ?? t(`Drag your files here or Browse files`)
          }${required ? '*' : ''}`}</LargeText>
        </div>
      ),
      uploadIcon: <Icon color="inherit" customColor="#C5D0DB" name="image" />,
      maxQuantity: options?.maxQuantity,
      minSize: 1 // empty files are not allowed
    },
    uploadFileHandler,
    deleteFileHandler,
    showLoadingBar: options?.showLoadingBar ?? true,
    uploadedFiles,
    error: errors ? (schema.errorMessage ?? t('field_required')) : undefined,
    limitReachedText: t('upload_limit_reached_text', {
      qty: options?.maxQuantity
    }),
    fileOfTypeText: t('upload_file_of_type_text'),
    fileNotSupportedText: t('upload_file_not_supported_text'),
    emptyFilesNotAllowedText: t('upload_empty_files_not_allowed_text'),
    networkErrorText: t('network_issue_when_uploading'),
    tooManyFilesText: t('too_many_files_to_upload', {
      qty: options?.maxQuantity
    }),
    id: stepBlockId
  }

  return (
    <Hidden xsUp={!visible}>
      <UploadPanel {...panelProps} />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: UploadPanelControlPure }) as never
)
