import { renderers } from '@epilot/json-renderers'
import type { JsonFormsRendererRegistryEntry } from '@jsonforms/core'
import { vanillaRenderers } from '@jsonforms/vanilla-renderers'

// choosing the renderes
export const allRenderers: JsonFormsRendererRegistryEntry[] = [
  // use vanillaRenderers that uses browser implementation as fall back
  ...vanillaRenderers,
  // register custom renderers
  ...renderers
]
