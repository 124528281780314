import { datadogRum } from '@datadog/browser-rum'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { SpinnerPage } from './components/SpinnerPage'
import { ConfigCheckWrapper } from './ConfigCheckWrapper'
import { AnalyticsProvider } from './context/AnalyticsContext'
import { initDatadog } from './services/datadog'
import './index.css'
import { createShadowFormElement } from './shadow-form/shadow-form'
import { env, TIME_TO_FIRST_RENDER_METRIC } from './utils/config'
import { TRACE_KEYS, generateTraceId } from './utils/trace'
import 'iframe-resizer/js/iframeResizer.contentWindow'

/**
 * WARNING: This should be always called and should happen before anything else,
 * thus making sure we can trace all sessions, including those that fail early.
 *
 * NOTE: This is supposed to be disabled for the local/test environments.
 */
initDatadog({
  disabled: ['test', 'dev'].includes(env('REACT_APP_STAGE'))
})

/**
 * Marks the beginning of times :)
 *
 * We wan't this metric to include the time spent in all operations that preceed
 * the rendering of the first step, thus we should start it as soon as possible.
 */
datadogRum.startDurationVital(TIME_TO_FIRST_RENDER_METRIC, {
  description:
    'Time spent by the Journey App up until the first render, \
    which is when the user is able to see the actual content of the first step.'
})

/**
 * Generates an unique trace id for this App's session.
 *
 * NOTE: This should be called before anything else, otherwise we might loose the ability
 * to trace the session to underlying errors/resources.
 */
generateTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

/**
 * Render App
 */
ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <ConfigCheckWrapper>
        <Suspense fallback={<SpinnerPage />}>
          <App />
        </Suspense>
      </ConfigCheckWrapper>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

createShadowFormElement()
