import type { IbanResponse } from '@epilot/journey-elements'
import {
  IbanInput,
  BooleanControl,
  StackSpacing,
  TextField
} from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useState } from 'react'
import type { ChangeEvent } from 'react'

import { useJourneyContext } from '../../utils'

import type { SEPAProps } from './types'

export function Sepa(props: SEPAProps) {
  const {
    accountOwnerName,
    accountOwnerNameLabel,
    accountOwnerNameHelper: _aonh,
    iban: initialIban,
    ibanLabel = 'IBAN',
    ibanHelper,
    ibanResponse: initialIbanResponse,
    bic,
    bicLabel = 'BIC',
    bicHelper,
    bankName,
    bankNameLabel,
    bankNameHelper,
    onChange,
    apiBaseUrl,
    consent: initialConsent,
    consentLabel = '',
    value,
    scale = 2,
    error,
    required,
    id,
    ...rest
  } = props
  const [iban, setIban] = useState<string>(initialIban || value?.iban || '')
  const [ibanResponse, setIbanResponse] = useState<IbanResponse | undefined>(
    initialIbanResponse || { bic_number: '', bank_name: '' }
  )
  const [name, setName] = useState<string>(
    accountOwnerName || value?.fullname || ''
  )
  const [consent, setConsent] = useState(
    initialConsent || value?.consent || false
  )

  const { context } = useJourneyContext()
  const publicToken = context.journey.settings?.publicToken as string

  const handleChange = (
    newValue: string,
    field: 'Name' | 'IBAN' | 'CONSENT',
    newIbanResponse?: IbanResponse
  ) => {
    // set the value in state
    switch (field) {
      case 'Name': {
        setName(newValue)

        // pass data to the caller if there is not errors
        if (onChange) {
          onChange({
            fullname: newValue,
            iban: iban,
            consent: consent,
            ...ibanResponse
          })
        }
        break
      }
      case 'IBAN': {
        setIban(newValue)
        setIbanResponse(newIbanResponse)

        // pass data to the caller if there is not errors
        if (onChange) {
          onChange({
            fullname: name,
            iban: newValue,
            consent: consent,
            ...newIbanResponse
          })
        }
        break
      }
      case 'CONSENT': {
        const newConsent = !consent

        setConsent(newConsent)

        // pass data to the caller if there is not errors
        if (onChange) {
          onChange({
            fullname: name,
            iban: iban,
            consent: newConsent,
            ...ibanResponse
          })
        }
      }
    }
  }

  return (
    <StackSpacing scale={scale} {...rest}>
      <TextField
        error={error && !name ? true : false}
        fullWidth
        helperText={error && !name ? error : ''}
        id={appendStepBlockId(id, 'Account Holder')}
        label={accountOwnerNameLabel}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.value, 'Name')
        }
        required={required}
        value={name}
      />
      <IbanInput
        apiBaseUrl={apiBaseUrl}
        bankName={bankName}
        bankNameHelper={bankNameHelper}
        bankNameLabel={bankNameLabel}
        bic={bic}
        bicHelper={bicHelper}
        bicLabel={bicLabel}
        error={
          error && error.length > 0 && !ibanResponse?.bic_number ? true : false
        }
        iban={iban}
        ibanHelper={ibanHelper}
        ibanLabel={ibanLabel}
        id={appendStepBlockId(id, 'IBAN')}
        onChange={(validIban: string, ibanResponse: IbanResponse) =>
          handleChange(validIban, 'IBAN', ibanResponse)
        }
        publicToken={publicToken}
        required={required}
      />
      {consentLabel && (
        <BooleanControl
          errorMessage={error && !consent ? error : ''}
          id={appendStepBlockId(id, 'Consent')}
          label={consentLabel}
          onChange={() => handleChange('', 'CONSENT')}
          required={required}
          uiType="checkbox"
          value={consent}
        />
      )}
    </StackSpacing>
  )
}
