import {
  AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY,
  CONTROL_NAME,
  CROSS_SELL_FILTER_SELECTION,
  DEFAULT_FILTER_VALUES_CONFIG_NAME
} from '@epilot/journey-logic-commons'
import type {
  ProductControlOptions,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useCallback, useEffect, useMemo } from 'react'

import { useStepBlockId } from '../../../utils'
import { useJourneyContext } from '../../../utils/context/JourneyContext'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'
import { useSearchPrices } from '../../../utils/services/product-service'

import { ProductSelectionControlComponent as ProductSelectionControlComponentCommon } from './components/ProductSelectionControlComponent'
import type { ProductSelection, SelectedProduct } from './types'
import { getAllCrossSellProducts } from './utils/product-selection-control-utils'

export function ProductSelectionControlComponent({
  handleChange,
  ...props
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(props.path)

  const isRequired = useMemo(
    () => Boolean(props.rootSchema?.required?.includes(props.label)),
    [props.label, props.rootSchema?.required]
  )

  const { context } = useJourneyContext()
  const orgId = context.journey.organizationId
  const publicToken = context.journey.settings?.publicToken as string

  const availabilityFilter: string[] =
    props.uischema?.options?.[DEFAULT_FILTER_VALUES_CONFIG_NAME]?.[
      AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY
    ]?.['availableProducts']

  const crossSellFilter = props.uischema?.options?.[CROSS_SELL_FILTER_SELECTION]

  const allCrossSellProductsIds =
    crossSellFilter && getAllCrossSellProducts(crossSellFilter)

  const { products: journeyPrices = [] } =
    (props.uischema?.options as ProductControlOptions | undefined) ?? {}

  const {
    isLoading,
    results,
    hits: resultsHits
  } = useSearchPrices({
    orgId,
    journeyPrices,
    crossSellProductIds: allCrossSellProductsIds,
    availabilityProductIds: availabilityFilter,
    publicToken
  })

  const isProductSellable = useCallback(
    (selectedItem: SelectedProduct) =>
      results.some(
        ({ _id, type }) =>
          _id === selectedItem.selectedProductId && type === 'product'
      ),
    [results]
  )

  useEffect(() => {
    if (props.data && results?.length) {
      const isMultiSelection = Array.isArray(props.data)
      const data: ProductSelection[] = isMultiSelection
        ? props.data
        : [props.data]
      const filteredData = data?.filter(
        (selectedItem) =>
          selectedItem.product && isProductSellable(selectedItem.product)
      )

      handleChange(
        props.path,
        isMultiSelection ? filteredData : filteredData?.[0]
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProductSellable, results])

  const blockPath = useMemo(
    () =>
      [
        context._navigationInfo.currentStepIndex !== null
          ? context._navigationInfo.currentStepIndex + 1
          : 1,
        props.path,
        CONTROL_NAME.PRODUCT_SELECTION_CONTROL
      ].join('/'),
    [props.path, context._navigationInfo.currentStepIndex]
  )

  return (
    <ProductSelectionControlComponentCommon
      {...props}
      blockPath={blockPath}
      handleChange={handleChange}
      id={stepBlockId}
      isLoading={isLoading}
      isRequired={isRequired}
      results={results}
      resultsHits={resultsHits}
    />
  )
}

ProductSelectionControlComponent.displayName =
  'ProductSelectionControlComponent'

export const ProductSelectionControl = withQueryProvider<EpilotControlProps>(
  ProductSelectionControlComponent
)

export default withJsonFormsControlProps(
  includeCommon({
    component: ProductSelectionControl
  }) as never
)
