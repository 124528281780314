import { BodyText, Grid } from '@epilot/journey-elements'
import type { RecurrenceAmount } from '@epilot/journey-logic-commons'
import { getDisplayPrice } from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import { useStyles } from './styles'

type CartTotalRecurrenceDiscountProps = {
  recurrence: RecurrenceAmount
  t: TFunction
  showTrailingDecimalZeros?: boolean
  currency?: string
}

export const CartTotalRecurrenceDiscount = ({
  recurrence,
  t,
  showTrailingDecimalZeros,
  currency
}: CartTotalRecurrenceDiscountProps) => {
  const classes = useStyles()

  const discountAmount = getDisplayPrice(
    t,
    showTrailingDecimalZeros
      ? recurrence.discount_amount_decimal
      : recurrence.discount_amount,
    undefined,
    showTrailingDecimalZeros,
    currency
  )

  return (
    <Grid className={classes.discountWrapper} container item>
      <Grid item xs={8}>
        <BodyText>
          <strong>{t('recurrenceDiscountTitle', 'Product Discount')}</strong>
        </BodyText>
      </Grid>
      <Grid
        className={classes.priceContainer}
        item
        justifyContent="flex-end"
        xs={4}
      >
        <BodyText>
          <strong>-{discountAmount}</strong>
        </BodyText>
      </Grid>
    </Grid>
  )
}
