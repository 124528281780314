import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

type CatalogProductTilesStyleProps = {
  justifyContent?: string
}

export const useProductTilesControl = makeStyles<
  Theme,
  CatalogProductTilesStyleProps
>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 35,
      justifyContent: (props) => props.justifyContent ?? 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      },
      marginTop: '40px',
      alignItems: 'center'
    },
    panel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      flexDirection: 'column',
      background: '#fff'
    }
  })
)

type ProductTileStyleProps = {
  highlightTile: boolean
  content?: string
  featuredColor?: string
  featuredLabelColor?: string
}

export const useProductTileStyles = makeStyles<Theme, ProductTileStyleProps>(
  (theme) =>
    createStyles({
      tileWrapper: {
        maxWidth: '100%',
        height: '100%',
        flexBasis: '100%',
        border: '3px solid',
        borderColor: (props) =>
          props.highlightTile ? theme.palette.primary.main : 'transparent',
        borderRadius: '8px',
        [theme.breakpoints.up('sm')]: {
          maxWidth: '350px'
        }
      },
      tileContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        borderRadius: `min(${theme.shape.borderRadius}px, 20px)`,
        /**
         * Ensure that bellow xs breakpoint the tile height is not normalized
         */
        [theme.breakpoints.down('xs')]: {
          '& div': {
            minHeight: 'initial !important'
          }
        },
        position: 'relative',
        overflow: 'visible',
        marginTop: 4
      },
      featuredProductContainer: {
        border: (props) =>
          `4px solid ${
            props.featuredColor || theme.palette.secondary.main
          } !important`,
        boxShadow: (props) =>
          `0px -30px ${
            props.featuredColor || theme.palette.secondary.main
          } !important`,
        marginTop: `0px !important`,
        '&:before': {
          content: (props: ProductTileStyleProps) => `"${props.content || ''}"`,
          textAlign: 'center',
          top: '-32px',
          justifyContent: 'center',
          position: 'absolute',
          color: (props: ProductTileStyleProps) =>
            props.featuredLabelColor || 'white',
          fontWeight: 700,
          paddingBlock: '8px',
          left: 0,
          right: 0,
          fontSize: '1rem'
        }
      }
    })
)

export const useProductQuantityStyles = makeStyles((theme) =>
  createStyles({
    stepperInput: {
      fontSize: '14px'
    },
    stepperButton: {
      color: theme.palette.primary.main
    }
  })
)
