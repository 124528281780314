import type { EpilotTheme } from '@epilot/journey-elements'
import {
  Card,
  CardContent,
  PrivateJourneyError,
  ScopedCssBaseline,
  ThemeProvider
} from '@epilot/journey-elements'
import { JOURNEY_ACCESS_MODE } from '@epilot/journey-logic-commons'
import type { Journey, StepState } from '@epilot/journey-logic-commons'
import type {
  ControlElement,
  JsonFormsRendererRegistryEntry
} from '@jsonforms/core'
import { JsonForms } from '@jsonforms/react'
import isEqual from 'fast-deep-equal/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { makeJourneyEmptyStepValues } from '../../utils'
import { useJourneyContext } from '../../utils/context/JourneyContext'
import type {
  JsonFormsOnChangeValue,
  JsonFormsOnChange,
  JsonFormsOnChangeValueError
} from '../../utils/types'

import { EmbeddedStepTitle } from './EmbeddedStepTitle'
import recursivelyRenameObjectKeys from './recursivelyRenameObjectKeys'
import { useTabContentStyles } from './styles'
import { useJourney } from './useJourney'

export type JourneyLauncherTabContentProps = {
  data: StepState
  journeyId: string
  renderers: JsonFormsRendererRegistryEntry[]
  onChange: JsonFormsOnChange
  uiSchema?: ControlElement
}

const useNestedThemeOptions = (
  journeyTheme: EpilotTheme,
  classPrefix: string
): EpilotTheme =>
  useMemo<EpilotTheme>(
    () => ({
      ...journeyTheme,
      breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1200, xl: 1536 } },
      muiOverrides: recursivelyRenameObjectKeys(
        (journeyTheme?.muiOverrides ?? {}) as NonNullable<
          EpilotTheme['muiOverrides']
        >,
        (key) =>
          /\.Mui/g.test(key) ? key.replace(/Mui/g, `${classPrefix}-Mui`) : key
      )
    }),
    [classPrefix, journeyTheme]
  )

const getJourneyStepErrors = (
  value: JsonFormsOnChangeValue
): JsonFormsOnChangeValueError[] => {
  if (!value) {
    return []
  }

  if (value.errors?.length) {
    return value.errors
  }

  if (value.data) {
    return Object.keys(value.data)
      .filter((key) => value.data[key]?._isValid === false)
      .map<JsonFormsOnChangeValueError>((key) => {
        /**
         * @todo Object does not conform entirely with ErrorObject, refactor
         */
        return {
          instancePath: `/${key}`,
          message: 'Not valid'
        } as JsonFormsOnChangeValueError
      })
  }

  return []
}

export const JourneyLauncherTabContent = ({
  journeyId,
  renderers,
  uiSchema,
  data,
  onChange
}: JourneyLauncherTabContentProps) => {
  const { t } = useTranslation()

  const {
    updateContext,
    context: { errorValidationMode }
  } = useJourneyContext()

  const { data: journey, isLoading } = useJourney<Journey>(journeyId)

  useEffect(() => {
    if (journey) {
      updateContext((value) => ({
        ...value,
        _linkedJourneyMap: {
          ...value._linkedJourneyMap,
          [journey.journeyId]: journey
        },
        /**
         * If the journeyStepStateMap doesn't hold
         * a state array for this journey, give it one
         * @todo Consider refactors here, such as doing this within JourneyPage
         */
        journeyStepStateMap: {
          ...value.journeyStepStateMap,
          [journey.journeyId]:
            value.journeyStepStateMap[journey.journeyId] ??
            makeJourneyEmptyStepValues(journey)
        }
      }))
    }
  }, [journey, updateContext])

  /**
   * We always want to render the first step of the journey within the journey launcher
   */
  const [firstStep] = journey?.steps || []

  const classPrefix = `linked-journey-${journeyId}`
  const theme = useNestedThemeOptions(journey?.design?.theme ?? {}, classPrefix)

  const handleChange: JsonFormsOnChange = (value) => {
    if (!isEqual(value.data, data)) {
      const errors = getJourneyStepErrors(value)

      onChange({ ...value, errors })
    }
  }

  /**
   * Theme might arrive with overrides to Mui classNames, however,
   * these do not consider that nested journey classNames contain a prefix.
   * We need to prefix these classNames with the prefix we use for the linked journey.
   */
  if (isLoading || !firstStep) return null

  if (journey?.settings?.accessMode === JOURNEY_ACCESS_MODE.PRIVATE) {
    return (
      <ThemeProvider classPrefix={classPrefix} theme={theme}>
        <ScopedCssBaseline>
          <TabContentWrapper showPaper={uiSchema?.options?.showPaper}>
            <PrivateJourneyError
              embedMode="inline"
              i18n={{
                heading: t('page_not_found.title'),
                content: t('page_not_found.message')
              }}
            />
          </TabContentWrapper>
        </ScopedCssBaseline>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider classPrefix={classPrefix} theme={theme}>
      <ScopedCssBaseline>
        <TabContentWrapper showPaper={uiSchema?.options?.showPaper}>
          <EmbeddedStepTitle firstStep={firstStep} />
          <JsonForms
            data={data}
            onChange={handleChange}
            renderers={renderers}
            schema={firstStep.schema}
            uischema={firstStep.uischema}
            validationMode={errorValidationMode}
          />
        </TabContentWrapper>
      </ScopedCssBaseline>
    </ThemeProvider>
  )
}

const TabContentWrapper: React.FC<{ showPaper: boolean }> = ({
  showPaper,
  children
}) => {
  const classes = useTabContentStyles()

  if (!showPaper) {
    return <>{children}</>
  }

  return (
    <Card className={classes.paper}>
      <CardContent className={classes.container}>{children}</CardContent>
    </Card>
  )
}
