import type { Theme } from '@epilot/journey-elements'
import { makeStyles, createStyles } from '@epilot/journey-elements'
import type { ReactElement } from 'react'
import React from 'react'

export type ProgressBarProps = {
  remaining?: number
  current: number
}

const useProgressBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      background: theme.palette.grey[50],
      height: 8,
      width: '100%',
      margin: `${theme.spacing(0.5)}px 0`
    },
    bar: {
      height: 8,
      background: theme.palette.primary.main,
      transition: 'width 500ms cubic-bezier(0.64, 0, 0.27, 1)',
      width: ({ progress }: { progress?: number }) =>
        typeof progress === 'number' ? `${progress}%` : 0
    }
  })
)

const getProgress = (remaining: number, current: number) =>
  (current / (current + remaining)) * 100

export const ProgressBar: React.FC<ProgressBarProps> = ({
  remaining,
  current
}: ProgressBarProps): ReactElement => {
  const progress =
    typeof remaining === 'number' ? getProgress(remaining, current) : undefined
  const classes = useProgressBarStyles({ progress })

  return (
    <div className={classes.wrap}>
      <div className={classes.bar}></div>
    </div>
  )
}
