import { Card as ConcordeCard } from '@epilot/concorde-elements'
import { Card, clsx } from '@epilot/journey-elements'

import { useTileContext } from '../../utils/tile-context-utils'
import { TileFooter } from '../logicComponents/TileFooter'

import { NormalizedSectionContainer } from './NormalizedSectionContainer'
import { useProductTileStyles } from './styles'
import { TileBody } from './TileBody'
import { TileHeader } from './TileHeader'

interface CatalogProductTileHtmlProps {
  className?: string
}

export const CatalogProductTileHtml = ({
  className
}: CatalogProductTileHtmlProps) => {
  const {
    useNewDesign,
    config: {
      isFeatured,
      featuredLabel,
      featuredColor,
      featuredLabelColor,
      generalTileConfiguration,
      isSelected
    }
  } = useTileContext()

  const classes = useProductTileStyles({
    highlightTile:
      generalTileConfiguration.highlightSelected &&
      isSelected &&
      !generalTileConfiguration.ctaConfig.disabled,
    content: featuredLabel,
    featuredColor,
    featuredLabelColor
  })

  const renderTileContent = (
    <>
      <TileHeader />
      <TileBody />
      <TileFooter />
    </>
  )

  return (
    <section aria-labelledby="tileTitle" className={classes.tileWrapper}>
      {useNewDesign ? (
        <NormalizedSectionContainer sectionName="concordeTile">
          <ConcordeCard
            className={classes.tileContent}
            featuredColor={featuredColor}
            featuredLabelColor={featuredLabelColor}
            featuredText={featuredLabel}
            isFeatured={isFeatured}
          >
            {renderTileContent}
          </ConcordeCard>
        </NormalizedSectionContainer>
      ) : (
        <Card
          className={clsx(
            classes.tileContent,
            isFeatured && classes.featuredProductContainer,
            className
          )}
          elevation={2}
        >
          <NormalizedSectionContainer sectionName="tile">
            {renderTileContent}
          </NormalizedSectionContainer>
        </Card>
      )}
    </section>
  )
}
