import {
  appendStepBlockId,
  addDate,
  findNextAvailableDay,
  findInitialValues,
  isBlockDataValid,
  isValidLocale
} from '@epilot/journey-logic-commons'
import type {
  DatePickerControlData,
  DatePickerControlOptions,
  DatePickerFormValues,
  RendererReturnValue
} from '@epilot/journey-logic-commons'
import { add } from 'date-fns'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker } from '../../components'

import styles from './DatePickerForm.module.scss'
import { getLimitDate } from './utils'

interface DatePickerFormProps {
  path: string
  data: DatePickerControlData
  hasError: boolean
  isRequired: boolean
  handleChange: (
    path: string,
    value: RendererReturnValue<DatePickerFormValues>
  ) => void
  id: string
  options: DatePickerControlOptions
}

export const DatePickerForm = (props: DatePickerFormProps) => {
  const {
    hasError,
    handleChange,
    path,
    data,
    isRequired,
    id,
    options: { disableDays, fields, showTime, timeIntervals }
  } = props

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substring(
    0,
    navigator.language.indexOf('-')
  )

  // set locale. Default to de
  const locale = isValidLocale(localeDetected) ? localeDetected : 'de'

  // get initial values for form reset considering prefilling
  const initialValues = useMemo(() => {
    const initialStart = findInitialValues(
      data?.startDate,
      fields.startDate.prefillByDays,
      disableDays
    )
    const initialEnd = findInitialValues(
      data?.endDate,
      fields.endDate?.prefillByDays,
      disableDays
    )

    const isValid = isBlockDataValid(
      fields,
      isRequired,
      initialStart,
      initialEnd
    )

    return {
      startDate: initialStart,
      endDate: initialEnd,
      _isValid: isValid
    }
  }, [data?.endDate, data?.startDate, disableDays, fields, isRequired])

  const [formValues, setFormValues] = useState(initialValues)

  const { t } = useTranslation()

  const updateFormValues = (name: string, value: Date | null) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
      _isValid: isBlockDataValid(
        fields,
        isRequired,
        formValues.startDate,
        formValues.endDate
      )
    }))
    handleChange(path, { ...formValues, [name]: value })
  }

  useEffect(() => {
    if (typeof fields.startDate.prefillByDays === 'number') {
      const newStartDate = findNextAvailableDay(
        add(addDate(), { days: fields.startDate.prefillByDays }),
        disableDays
      )

      updateFormValues('startDate', newStartDate)
    }
  }, [fields.startDate.prefillByDays])

  useEffect(() => {
    if (typeof fields.endDate?.prefillByDays === 'number') {
      const newEndDate = findNextAvailableDay(
        add(addDate(), { days: fields.endDate.prefillByDays }),
        disableDays
      )

      updateFormValues('endDate', newEndDate)
    }
  }, [fields.endDate?.prefillByDays])

  return (
    <div className={styles.container}>
      <DatePicker
        date={formValues.startDate ? new Date(formValues.startDate) : null}
        disableDays={disableDays}
        id={appendStepBlockId(id, 'Start Date')}
        inputProps={{
          helperText: hasError ? t('field_required') : ''
        }}
        isError={hasError}
        isRequired={isRequired}
        isTimeSelectVisible={showTime}
        label={fields.startDate.label}
        locale={locale}
        maxDate={getLimitDate(fields.startDate.limits?.maxByDays)}
        minDate={getLimitDate(fields.startDate.limits?.minByDays)}
        onChange={(date) => updateFormValues('startDate', date)}
        prefillByDays={fields.startDate.prefillByDays}
        timeIntervals={timeIntervals}
      />
      {fields.endDate && (
        <DatePicker
          date={formValues.endDate ? new Date(formValues.endDate) : null}
          disableDays={disableDays}
          id={appendStepBlockId(id, 'End Date')}
          inputProps={{
            helperText: hasError ? t('field_required') : ''
          }}
          isError={hasError}
          isRequired={isRequired}
          isTimeSelectVisible={showTime}
          label={fields.endDate.label}
          locale={locale}
          maxDate={getLimitDate(fields.endDate.limits?.maxByDays)}
          minDate={getLimitDate(fields.endDate.limits?.minByDays)}
          onChange={(date) => updateFormValues('endDate', date)}
          prefillByDays={fields.endDate.prefillByDays}
          timeIntervals={timeIntervals}
        />
      )}
    </div>
  )
}
