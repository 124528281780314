/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */

import type {
  DatePickerControlOptions,
  DatePickerControlData
} from './datePicker'

export function isDatePickerControlData(
  obj: any,
  _argumentName?: string
): obj is DatePickerControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.startDate === 'undefined' ||
        obj.startDate === null ||
        obj.startDate instanceof Date) &&
      (typeof obj.endDate === 'undefined' ||
        obj.endDate === null ||
        obj.endDate instanceof Date))
  )
}

export function isDatePickerControlOptions(
  obj: any,
  _argumentName?: string
): obj is DatePickerControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    ((((obj.fields.startDate !== null &&
      typeof obj.fields.startDate === 'object') ||
      typeof obj.fields.startDate === 'function') &&
      (typeof obj.fields.startDate.label === 'undefined' ||
        typeof obj.fields.startDate.label === 'string') &&
      (typeof obj.fields.startDate.errorMessage === 'undefined' ||
        typeof obj.fields.startDate.errorMessage === 'string') &&
      (typeof obj.fields.startDate.placeholder === 'undefined' ||
        typeof obj.fields.startDate.placeholder === 'string') &&
      (typeof obj.fields.startDate.disabled === 'undefined' ||
        obj.fields.startDate.disabled === false ||
        obj.fields.startDate.disabled === true) &&
      (typeof obj.fields.startDate.defaultValue === 'undefined' ||
        obj.fields.startDate.defaultValue instanceof Date)) ||
      (((obj.fields.startDate !== null &&
        typeof obj.fields.startDate === 'object') ||
        typeof obj.fields.startDate === 'function') &&
        (typeof obj.fields.startDate.prefillByDays === 'undefined' ||
          typeof obj.fields.startDate.prefillByDays === 'number') &&
        (typeof obj.fields.startDate.limits === 'undefined' ||
          (((obj.fields.startDate.limits !== null &&
            typeof obj.fields.startDate.limits === 'object') ||
            typeof obj.fields.startDate.limits === 'function') &&
            (typeof obj.fields.startDate.limits.minByDays === 'undefined' ||
              typeof obj.fields.startDate.limits.minByDays === 'number') &&
            (typeof obj.fields.startDate.limits.maxByDays === 'undefined' ||
              typeof obj.fields.startDate.limits.maxByDays === 'number') &&
            (typeof obj.fields.startDate.limits.disablePast === 'undefined' ||
              obj.fields.startDate.limits.disablePast === false ||
              obj.fields.startDate.limits.disablePast === true))))) &&
    (typeof obj.fields.endDate === 'undefined' ||
      (((obj.fields.endDate !== null &&
        typeof obj.fields.endDate === 'object') ||
        typeof obj.fields.endDate === 'function') &&
        (typeof obj.fields.endDate.label === 'undefined' ||
          typeof obj.fields.endDate.label === 'string') &&
        (typeof obj.fields.endDate.errorMessage === 'undefined' ||
          typeof obj.fields.endDate.errorMessage === 'string') &&
        (typeof obj.fields.endDate.placeholder === 'undefined' ||
          typeof obj.fields.endDate.placeholder === 'string') &&
        (typeof obj.fields.endDate.disabled === 'undefined' ||
          obj.fields.endDate.disabled === false ||
          obj.fields.endDate.disabled === true) &&
        (typeof obj.fields.endDate.defaultValue === 'undefined' ||
          obj.fields.endDate.defaultValue instanceof Date)) ||
      (((obj.fields.endDate !== null &&
        typeof obj.fields.endDate === 'object') ||
        typeof obj.fields.endDate === 'function') &&
        (typeof obj.fields.endDate.prefillByDays === 'undefined' ||
          typeof obj.fields.endDate.prefillByDays === 'number') &&
        (typeof obj.fields.endDate.limits === 'undefined' ||
          (((obj.fields.endDate.limits !== null &&
            typeof obj.fields.endDate.limits === 'object') ||
            typeof obj.fields.endDate.limits === 'function') &&
            (typeof obj.fields.endDate.limits.minByDays === 'undefined' ||
              typeof obj.fields.endDate.limits.minByDays === 'number') &&
            (typeof obj.fields.endDate.limits.maxByDays === 'undefined' ||
              typeof obj.fields.endDate.limits.maxByDays === 'number') &&
            (typeof obj.fields.endDate.limits.disablePast === 'undefined' ||
              obj.fields.endDate.limits.disablePast === false ||
              obj.fields.endDate.limits.disablePast === true))))) &&
    (typeof obj.disableDays === 'undefined' ||
      (Array.isArray(obj.disableDays) &&
        obj.disableDays.every(
          (e: any) =>
            e === 0 ||
            e === 1 ||
            e === 2 ||
            e === 3 ||
            e === 4 ||
            e === 5 ||
            e === 6
        ))) &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}
