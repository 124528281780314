import { getClient } from '@epilot/address-suggestions-client'

// Create the client instance
const client = getClient()

const getAddressSuggestionsClient = (
  addressSuggestionsApiUrl: string,
  orgId: string,
  publicToken?: string
) => {
  client.defaults.baseURL = addressSuggestionsApiUrl

  client.interceptors.request.use((request) => {
    if (request?.headers) {
      request.headers['x-epilot-org-id'] = orgId

      if (publicToken) {
        request.headers['Authorization'] = `Bearer ${publicToken}`
      }
    }

    return request
  })

  return client
}

export default getAddressSuggestionsClient
