import { makeStyles } from '@epilot/journey-elements'
import { useTranslation } from 'react-i18next'

import NoProductsIcon from './NoProductsIcon'

export const NoProducts = () => {
  const classes = useStyles()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  return (
    <div className={classes.panel}>
      <div className={classes.panelInner}>
        <NoProductsIcon />
      </div>
      <div>
        <h2>{t('No Products Available')}</h2>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  panel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    flexDirection: 'column',
    background: '#fff'
  },
  panelInner: {
    height: '135px',
    marginTop: '-15px'
  }
}))
