import { useMemo } from 'react'

import type { Page, PaginationLocales } from './types'
import { PAGE_TYPE } from './types'

const PAGES_BREAKDOWN_BREAKPOINT = 7

export const getPages = (limit: number, activePage: number): Page[] => {
  if (limit < PAGES_BREAKDOWN_BREAKPOINT) {
    return Array.from(Array(limit + 1).keys())
      .slice(1)
      .map((pageNumber) => ({ value: pageNumber, type: PAGE_TYPE.NUMBER }))
  }

  if ([1, 2, 3].includes(activePage)) {
    return [
      {
        value: 1,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: 2,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: 3,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: 4,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: 5,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: 6,
        type: PAGE_TYPE.DOTS
      },
      {
        value: limit,
        type: PAGE_TYPE.NUMBER
      }
    ]
  }

  if ([limit, limit - 1, limit - 2].includes(activePage)) {
    return [
      {
        value: 1,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: limit - 5,
        type: PAGE_TYPE.DOTS
      },
      {
        value: limit - 4,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: limit - 3,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: limit - 2,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: limit - 1,
        type: PAGE_TYPE.NUMBER
      },
      {
        value: limit,
        type: PAGE_TYPE.NUMBER
      }
    ]
  }

  return [
    {
      value: 1,
      type: PAGE_TYPE.NUMBER
    },
    {
      value: activePage - 2,
      type: PAGE_TYPE.DOTS
    },
    {
      value: activePage - 1,
      type: PAGE_TYPE.NUMBER
    },
    {
      value: activePage,
      type: PAGE_TYPE.NUMBER
    },
    {
      value: activePage + 1,
      type: PAGE_TYPE.NUMBER
    },
    {
      value: activePage + 2,
      type: PAGE_TYPE.DOTS
    },
    {
      value: limit,
      type: PAGE_TYPE.NUMBER
    }
  ]
}

const DEFAULT_LOCALES: PaginationLocales = {
  pageInputLabel: 'Page',
  countText: ({ from, to, total }) => `${from} - ${to} of ${total}`
}

export const useLocales = (locales: Partial<PaginationLocales>) =>
  useMemo(
    () => (locales ? { ...DEFAULT_LOCALES, ...locales } : DEFAULT_LOCALES),
    [locales]
  )
