import { Typography } from '@epilot/concorde-elements'
import { BodyText, createStyles, makeStyles } from '@epilot/journey-elements'

import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      textAlign: 'center'
    }
  })
)

export const ProductDescription = () => {
  const { product, config, useNewDesign } = useTileContext()
  const { description } = product
  const classes = useStyles()

  if (config.generalTileConfiguration.displayProductDescription === false) {
    return null
  }

  const TextComponent = useNewDesign ? Typography : BodyText

  return (
    <NormalizedSectionContainer
      className={classes.container}
      sectionName={useNewDesign ? 'concordeDescription' : 'description'}
    >
      <TextComponent as="p">{description}</TextComponent>
    </NormalizedSectionContainer>
  )
}
