/* eslint-disable @typescript-eslint/no-explicit-any */

import type { NestedUiSchema, UiSchema } from '../../types'
import { isLayoutUiSchema, isNestedUiSchema } from '../../types'

import type {
  BlockFindingOperation,
  SearchOptions,
  StepExtended
} from './types'

type Operation = BlockFindingOperation | 'ungroup'
type OperationStatus = 'removed' | 'added' | 'updated' | 'not-found' | 'found'

export function findBlockUischema(
  searchItem: NestedUiSchema | undefined,
  searchCriteria: { name?: string },
  searchOptions?: SearchOptions,
  operation?: Operation,
  callerBlock?: UiSchema
): {
  uischema: NestedUiSchema | undefined
  zoneIndex?: number
  parent?: {
    scope?: string
    type?: string
  }
  operationStatus: OperationStatus
}
export function findBlockUischema(
  searchItem: UiSchema | undefined,
  searchCriteria: { name?: string },
  searchOptions?: SearchOptions,
  operation?: Operation,
  callerBlock?: UiSchema | NestedUiSchema
): {
  uischema: UiSchema | undefined
  parent?: {
    scope?: string
    type?: string
  }
  operationStatus: OperationStatus
}
export function findBlockUischema(
  searchItem: StepExtended['uischema'] | undefined,
  searchCriteria: { name?: string },
  searchOptions?: SearchOptions,
  operation?: Operation,
  callerBlock?: UiSchema | NestedUiSchema
): {
  uischema: StepExtended['uischema'] | undefined
  zoneIndex?: number
  parent?: {
    scope?: string
    type?: string
  }
  operationStatus: OperationStatus
} {
  if (searchItem) {
    const scope = searchItem?.scope
    // item after last slash is the block name
    const blockName = scope?.split('/').pop()
    const { name } = searchCriteria

    const { replaceBy, representBy, insertIndex } = searchOptions || {}

    if (operation === 'ungroup' && representBy !== 'full') {
      // eslint-disable-next-line no-console
      console.error(
        'The ungroup operation only supports a fully represented uischema. Make sure to pass the correct searchOptions'
      )

      return { uischema: undefined, operationStatus: 'not-found' }
    }

    // check if block found by comparing the name
    if (blockName && blockName === name) {
      const parent = {
        scope: callerBlock?.scope,
        type: callerBlock?.type
      }
      const { name: replaceByName, uischema: replaceByUischema } =
        replaceBy || {}

      // overwrite only if one of replacements exist
      if (
        (replaceByName || replaceByUischema) &&
        (operation === 'update' || operation === 'rename')
      ) {
        let data = { ...searchItem }

        if (replaceByUischema) {
          data = Object.assign(data, replaceByUischema)
        }

        // check if scope needs to be replaced
        if (replaceByName && scope) {
          // replace scope by new name
          data = Object.assign(data, {
            scope: scope
              .split('/')
              .map((item) => {
                return item === name ? replaceBy?.name : item
              })
              .join('/')
          })
        }

        // return modified item
        return { uischema: data, parent: parent, operationStatus: 'updated' }
      } else if (operation === 'remove') {
        return { uischema: undefined, operationStatus: 'removed' }
      }

      // block found and not modified, return it
      return { uischema: searchItem, parent: parent, operationStatus: 'found' }
      // if not, check if searchItem has elements and search deeper in array
    } else if ('elements' in searchItem) {
      if (isNestedUiSchema(searchItem)) {
        // go through elements and check recursively per zone
        const { elements } = searchItem

        for (let zoneIndex = 0; zoneIndex < elements.length; zoneIndex++) {
          const zoneElement = elements[zoneIndex]

          for (let index = 0; index < zoneElement.length; index++) {
            const {
              uischema: block,
              parent,
              operationStatus
            } = findBlockUischema(
              zoneElement[index],
              searchCriteria,
              searchOptions,
              operation,
              searchItem
            )

            if (block) {
              // add item to found block before merging
              if (
                isLayoutUiSchema(block) &&
                operation === 'add' &&
                replaceBy?.uischema
              ) {
                block['elements'] = insert(
                  block.elements,
                  insertIndex,
                  replaceBy?.uischema
                )
              }

              if (representBy === 'full') {
                if (isLayoutUiSchema(block) && operation === 'ungroup') {
                  return {
                    uischema: {
                      ...searchItem,
                      elements: searchItem.elements.map((item, zoneMapIndex) =>
                        zoneMapIndex === zoneIndex
                          ? [
                              // part of the array before the block
                              ...item.slice(0, index),
                              // add elements to level
                              ...(block.elements?.map((element) => {
                                return {
                                  ...element,
                                  options: {
                                    ...element.options,
                                    isNested: false,
                                    halfWidth: false
                                  }
                                }
                              }) || []),
                              // part of the array after the block
                              ...item.slice(index + 1)
                            ]
                          : item
                      )
                    },
                    operationStatus
                  }
                }

                // merge found block into elements, in case it was updated
                return {
                  uischema: {
                    ...searchItem,
                    elements: searchItem.elements.map((item, zoneMapIndex) =>
                      zoneMapIndex === zoneIndex
                        ? item.map((el, i) => (i === index ? block : el))
                        : item
                    )
                  },
                  parent,
                  operationStatus
                }
              }

              return { uischema: block, zoneIndex, parent, operationStatus }
            } else if (operationStatus === 'removed') {
              if (representBy === 'full') {
                // remove item from elements
                return {
                  uischema: {
                    ...searchItem,
                    elements: searchItem.elements.map((item, zoneMapIndex) =>
                      zoneMapIndex === zoneIndex
                        ? item.filter((_, i) => i !== index)
                        : item
                    )
                  },
                  operationStatus
                }
              }

              return { uischema: undefined, operationStatus }
            }
          }
        }
      } else if (isLayoutUiSchema(searchItem)) {
        const { elements } = searchItem

        // go through elements and check recursively
        for (let index = 0; index < elements.length; index++) {
          const {
            uischema: block,
            parent,
            operationStatus
          } = findBlockUischema(
            elements[index],
            searchCriteria,
            searchOptions,
            operation,
            searchItem
          )

          if (block) {
            // add item to found block before merging
            if (
              isLayoutUiSchema(block) &&
              operation === 'add' &&
              replaceBy?.uischema
            ) {
              block['elements'] = insert(
                block.elements,
                insertIndex,
                replaceBy?.uischema
              )
            }

            if (representBy === 'full') {
              if (isLayoutUiSchema(block) && operation === 'ungroup') {
                // Find the index of the block in the elements array
                const blockIndex = searchItem.elements.findIndex(
                  (element) => element.scope === block.scope
                )

                return {
                  uischema: {
                    ...searchItem,
                    elements: [
                      // part of the array before the block
                      ...searchItem.elements.slice(0, blockIndex),
                      // add elements to level
                      ...(block.elements?.map((element) => {
                        return {
                          ...element,
                          options: {
                            ...element.options,
                            isNested: false,
                            halfWidth: false
                          }
                        }
                      }) || []),
                      // part of the array after the block
                      ...searchItem.elements.slice(blockIndex + 1)
                    ]
                  },
                  operationStatus
                }
              }

              // merge found block into elements, in case it was updated
              return {
                uischema: {
                  ...searchItem,
                  elements: searchItem.elements.map((item, i) =>
                    i === index ? block : item
                  )
                },
                parent,
                operationStatus
              }
            }

            return { uischema: block, parent, operationStatus }
          } else if (operationStatus === 'removed') {
            if (representBy === 'full') {
              // remove item from elements
              return {
                uischema: {
                  ...searchItem,
                  elements: searchItem.elements.filter((_, i) => i !== index)
                },
                operationStatus
              }
            }

            return { uischema: undefined, operationStatus }
          }
        }
      }
    }
    // neither block found, nor elements array given
  }

  return { uischema: undefined, operationStatus: 'not-found' }
}

/**
 * Helper function to insert an item into an array at given index
 * @param elements array to insert item into
 * @param insertIndex index where to insert item into. If not given, adds item at the end
 * @param newItem item to be inserted
 * @returns updated array
 */
function insert(
  elements: UiSchema[] = [],
  insertIndex: number | undefined,
  newItem: UiSchema
): UiSchema[] {
  const index =
    typeof insertIndex === 'number' ? insertIndex : elements.length - 1

  return [
    // part of the array before the specified index
    ...elements.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...elements.slice(index)
  ]
}
