import {
  Divider,
  Grid,
  MuiPickersUtilsProvider,
  DateFnsUtils
} from '@epilot/journey-elements'
import {
  appendStepBlockId,
  getLocaleDateString,
  LOCALE_MAP
} from '@epilot/journey-logic-commons'
import { regExpStatments } from '@epilot/validators'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { isAnyInObject, matchesValue } from '../../../utils/helper'
import { FieldSelect } from '../../FieldAutocomplete'
import { FieldDatePicker } from '../../FieldDatePicker'
import { FieldText } from '../../FieldText'
import { FieldToggleButton } from '../../FieldToggleButton'
import { FieldCustomerType } from '../FieldCustomerType'

import { useStyles } from './styles'
import type { ContactFormFieldsProps } from './types'
import { showSalutationOptionsButton } from './utils'

export const ContactFormFields = (
  props: ContactFormFieldsProps
): ReactElement => {
  const { fields, control, hasError, path, resetField, fieldValues, id } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const format = getLocaleDateString(navigator.language)

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substr(
    0,
    navigator.language.indexOf('-')
  )
  // set locale. Default to de
  const locale = ['de', 'en', 'fr'].includes(localeDetected)
    ? localeDetected
    : 'de'

  return (
    <>
      {(fields.salutation || fields.title) && (
        <>
          <Grid item md={6} sm={12} xs={12}>
            {fields.salutation &&
              (!fields.salutation?.options ? (
                <FieldToggleButton
                  control={control}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Salutation')}
                  name="salutation"
                  options={
                    fields.salutation.genderType !== 'GENDER3'
                      ? ['Mr.', 'Ms. / Mrs.']
                      : ['Mr.', 'Ms. / Mrs.', 'Other']
                  }
                  optionsLabels={
                    fields.salutation.genderType !== 'GENDER3'
                      ? [t('Mr.'), t('Mrs')]
                      : [t('Mr.'), t('Mrs'), t('Others')]
                  }
                  {...fields.salutation}
                  label=""
                />
              ) : showSalutationOptionsButton(fields.salutation?.options) ? (
                <FieldToggleButton
                  control={control}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Salutation')}
                  name="salutation"
                  options={fields.salutation.options}
                  optionsLabels={fields.salutation.options.map((opt) => {
                    return opt
                      ? t(`salutation.options.${opt.replace(/\./g, '·')}`, opt)
                      : opt
                  })}
                  {...fields.salutation}
                  label=""
                />
              ) : (
                <FieldSelect
                  control={control}
                  defaultValue={fields.salutation.defaultValue || ''}
                  hasError={hasError}
                  name="salutation"
                  options={fields.salutation.options}
                  optionsLabels={fields.salutation.options.map((opt) => {
                    return opt
                      ? t(`salutation.options.${opt.replace(/\./g, '·')}`, opt)
                      : opt
                  })}
                  {...fields.salutation}
                  id={appendStepBlockId(id, 'Salutation')}
                  label={t('salutation.label')}
                />
              ))}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            {fields.title && (
              <FieldToggleButton
                control={control}
                hasError={hasError}
                name="title"
                options={['Dr.', 'Prof.', 'Prof. Dr.']}
                {...fields.title}
                id={appendStepBlockId(id, 'Title')}
                label=""
              />
            )}
          </Grid>
        </>
      )}
      {fields.firstName && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.firstName.defaultValue || ''}
            hasError={hasError}
            name="firstName"
            path={path}
            rules={{
              pattern: new RegExp(regExpStatments.name.regex)
            }}
            {...fields.firstName}
            errorMessage={fields.firstName.errorMessage || t('name_error')}
            id={appendStepBlockId(id, 'First Name')}
            label={t('firstName')}
          />
        </Grid>
      )}
      {fields.lastName && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.lastName.defaultValue || ''}
            hasError={hasError}
            name="lastName"
            path={path}
            rules={{
              pattern: new RegExp(regExpStatments.name.regex)
            }}
            {...fields.lastName}
            errorMessage={fields.lastName.errorMessage || t('name_error')}
            id={appendStepBlockId(id, 'Last Name')}
            label={t('lastName')}
          />
        </Grid>
      )}
      {isAnyInObject(fields, [
        'salutation',
        'title',
        'firstName',
        'lastName'
      ]) &&
        isAnyInObject(fields, ['birthDate', 'email', 'telephone']) && (
          <Grid className={classes.dividerContainer} item xs={12}>
            <Divider className={classes.divider} orientation="horizontal" />
          </Grid>
        )}
      {fields.email && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.email.defaultValue || ''}
            errorMessage={fields.email.errorMessage || t('email_error')}
            hasError={hasError}
            name="email"
            path={path}
            rules={{
              pattern: new RegExp(regExpStatments.email.regex)
            }}
            {...fields.email}
            id={appendStepBlockId(id, 'Email')}
            label={t('email')}
          />
        </Grid>
      )}
      {fields.telephone && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.telephone.defaultValue || ''}
            errorMessage={fields.telephone.errorMessage || t('telephone_error')}
            hasError={hasError}
            name="telephone"
            path={path}
            rules={{
              pattern: new RegExp(regExpStatments.telephone_de.regex)
            }}
            {...fields.telephone}
            id={appendStepBlockId(id, 'Telephone')}
            label={t('telephone')}
          />
        </Grid>
      )}
      {fields.birthDate && (
        <Grid item md={6} sm={12} xs={12}>
          <MuiPickersUtilsProvider
            locale={LOCALE_MAP[locale as 'de' | 'en']}
            utils={DateFnsUtils}
          >
            <FieldDatePicker
              control={control}
              defaultValue={fields.birthDate.defaultValue}
              disableFuture
              errorMessage={
                fields.birthDate.errorMessage || t('birthDateError')
              }
              format={format}
              hasError={hasError}
              id={appendStepBlockId(id, 'Birth Date')}
              label={t('birthDate')}
              name="birthDate"
              path={path}
              rules={{
                validate: (value) => {
                  // dont validate further if no value. Other, internal rules still validate
                  if (!value) return true

                  return new Date(value).getTime() < new Date().getTime()
                }
              }}
              {...fields.birthDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {isAnyInObject(fields, [
        'customerType',
        'companyName',
        'registryCourt',
        'registerNumber'
      ]) &&
        isAnyInObject(fields, ['birthDate', 'email', 'telephone']) && (
          <Grid className={classes.dividerContainer} item xs={12}>
            <Divider className={classes.divider} orientation="horizontal" />
          </Grid>
        )}
      {fields.customerType && (
        <Grid item xs={12}>
          <FieldCustomerType
            control={control}
            formProps={{ resetField }}
            hasError={hasError}
            id={appendStepBlockId(id, 'Customer Type')}
            {...fields.customerType}
          />
        </Grid>
      )}
      {fields.companyName &&
        (!fields.companyName.showIf ||
          matchesValue(fields.companyName.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.companyName.defaultValue || ''}
              hasError={hasError}
              name="companyName"
              path={path}
              {...fields.companyName}
              id={appendStepBlockId(id, 'Company Name')}
              label={t('companyName')}
            />
          </Grid>
        )}
      {fields.registryCourt &&
        (!fields.registryCourt.showIf ||
          matchesValue(fields.registryCourt.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.registryCourt.defaultValue || ''}
              hasError={hasError}
              name="registryCourt"
              path={path}
              {...fields.registryCourt}
              id={appendStepBlockId(id, 'Registry Court')}
              label={t('registryCourt')}
            />
          </Grid>
        )}
      {fields.registerNumber &&
        (!fields.registerNumber.showIf ||
          matchesValue(fields.registerNumber.showIf, fieldValues)) && (
          <Grid item md={6} sm={12} xs={12}>
            <FieldText
              control={control}
              defaultValue={fields.registerNumber.defaultValue || ''}
              hasError={hasError}
              name="registerNumber"
              path={path}
              {...fields.registerNumber}
              id={appendStepBlockId(id, 'Register Number')}
              label={t('registerNumber')}
            />
          </Grid>
        )}
    </>
  )
}
