import type { DigitalSignatureProps } from '@epilot/journey-elements'
import { DigitalSignature } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { useStepBlockId } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type DigitalSignatureControlOptions = DigitalSignatureProps

function DigitalSignatureControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  schema
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)

  const { t } = useTranslation()
  const handleOnChange = (value: string) => {
    handleChange(path, value)
  }

  const { label = '' } = uischema || {}

  const { errorMessage } = schema

  const { clearText, closeText, saveText, ...props } =
    uischema.options as DigitalSignatureControlOptions

  return (
    <DigitalSignature
      clearText={clearText || t('digital_signature.clear', 'Clear')}
      closeText={closeText || t('digital_signature.close', 'Close')}
      data-testid="digital-signature"
      disabled={!enabled}
      error={
        errors
          ? errorMessage && typeof errorMessage
            ? errorMessage
            : t('field_required')
          : undefined
      }
      id={stepBlockId}
      onSave={handleOnChange}
      saveText={saveText || t('digital_signature.save', 'Save')}
      title={label as string}
      value={data}
      {...props}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: DigitalSignatureControl }) as never
)
