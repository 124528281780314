import type { Provider } from '@epilot/journey-elements'
import { Hidden, PreviousProvider } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { useConfig, useJourneyContext, useStepBlockId } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

import type { PreviousProviderControlOptions } from './types'

function PreviousProviderControl({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors,
  enabled,
  required
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const { PREVIOUS_PROVIDER_URL } = useConfig()
  const { context } = useJourneyContext()
  const publicToken = context.journey.settings?.publicToken as string

  const handleOnChange = (provider?: Provider) => {
    handleChange(path, provider || undefined)
  }
  const { t } = useTranslation()

  const { label, ...props } = uischema.options as PreviousProviderControlOptions

  return (
    <Hidden xsUp={!visible}>
      <PreviousProvider
        disabled={!enabled}
        error={
          errors
            ? t('previous_provider_required', 'Eingabe ist erforderlich')
            : undefined
        }
        label={(label || '') + (required ? ' *' : '')}
        onChange={handleOnChange as never}
        previousProviderEndpoint={PREVIOUS_PROVIDER_URL}
        publicToken={publicToken}
        value={data}
        {...props}
        id={stepBlockId}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: PreviousProviderControl }) as never
)
