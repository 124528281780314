import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useState } from 'react'
import type { ComponentType } from 'react'

import { Button, Icon } from '../../../../components'
import classes from '../../styles/ProductDetails.module.scss'
import type { ProductDetailsModalProps } from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

export const ProductDetails = ({
  ProductDetailsModalComponent
}: {
  ProductDetailsModalComponent: ComponentType<ProductDetailsModalProps>
}) => {
  const {
    actions,
    id,
    config: {
      generalTileConfiguration: { showProductDetails }
    },
    tileVariantsEnabled
  } = useTileContext()
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  function toggleDetailsModal() {
    setShowDetailsModal((showDetailsModal) => !showDetailsModal)
  }

  const detailsButtonId = appendStepBlockId(id, 'Product Details Button')

  if (!tileVariantsEnabled && !showProductDetails) {
    return null
  }

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.container}>
        <Button
          className={classes.detailsButton}
          id={detailsButtonId}
          label={actions.t('product_details.toggle_modal', 'More Details')}
          onClick={toggleDetailsModal}
          rightIcon={<Icon name="expand_content" />}
          variant="ghost"
        />
        {/* TODO: Move to concorde-elements */}
        {showDetailsModal && (
          <ProductDetailsModalComponent
            isOpen={showDetailsModal}
            onClose={() => setShowDetailsModal(false)}
          />
        )}
      </div>
    </div>
  )
}
