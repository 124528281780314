import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

import type { PriceItemProps } from './ProductPriceItem'

export const useFeatureCheckStyles = makeStyles<Theme, { showIcon?: boolean }>(
  () =>
    createStyles({
      icon: {
        minWidth: '32px',
        marginTop: '4px',
        visibility: ({ showIcon }) => (showIcon ? 'visible' : 'hidden')
      }
    })
)

export const useFeatureTitleStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%'
    },
    title: {
      fontWeight: 600
    }
  })
)

export const usePriceItemStyles = makeStyles<Theme, PriceItemStyleProps>(
  (theme) =>
    createStyles({
      itemContainer: {
        minHeight: ({ size }) => (size === 'large' ? '2.5rem' : 0),
        textAlign: 'center',
        display: 'flex',
        flexDirection: ({ inline }) => (inline ? 'row' : 'column'),
        alignItems: 'center',
        gap: ({ inline }) => (inline ? theme.spacing(2) : 0),
        marginTop: ({ size }) => (size === 'small' ? '0 !important' : undefined)
      },

      prefix: {
        color: ({ size, type }) =>
          getPriceItemColor(size === 'large', type, theme),
        textDecoration: ({ type }) =>
          type === 'saving' ? 'line-through' : 'none',
        fontSize: ({ size }) => {
          switch (size) {
            case 'large':
              return 32
            case 'medium':
              return 24
            case 'small':
              return 12
            default:
              return 24
          }
        },
        fontWeight: 600,
        '&::after': {
          content: '"\xa0"'
        }
      },
      price: {
        color: (props) =>
          getPriceItemColor(props.size === 'large', props.type, theme),
        textDecoration: (props) =>
          props.type === 'saving' ? 'line-through' : 'none',
        fontSize: ({ size }) => {
          switch (size) {
            case 'large':
              return 32
            case 'medium':
              return 24
            case 'small':
              return 12
            default:
              return 24
          }
        },
        fontWeight: 600
      },
      priceBeforeDiscount: {
        textDecoration: 'line-through',
        opacity: 0.5,
        fontWeight: theme.typography.fontWeightBold as never
      },
      discount: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold as never
      },
      title: {
        fontSize: ({ size }) => {
          switch (size) {
            case 'large':
              return 14
            case 'medium':
              return 14
            case 'small':
              return 12
            default:
              return 24
          }
        },
        fontWeight: ({ size }) => {
          switch (size) {
            case 'small':
              return 400
            default:
              return 600
          }
        },
        textTransform: 'lowercase'
      }
    })
)

export const useNewDesignStyles = makeStyles<Theme>((theme) =>
  createStyles({
    price: {
      color: theme.palette.text.primary,
      margin: '0'
    },
    priceRecurringTotal: {
      color: theme.palette.text.secondary,
      margin: '0'
    },
    billingPeriod: {
      fontSize: '0.75rem'
    },
    container: {
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column'
    },
    priceSection: {
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: 'center',
      flexDirection: 'column'
    },
    priceBeforeDiscount: {
      textDecoration: 'line-through',
      opacity: 0.5,
      fontWeight: theme.typography.fontWeightBold as never
    },
    discount: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold as never
    }
  })
)

type PriceDisplayType = 'normal' | 'negative' | 'saving'

type PriceItemStyleProps = Pick<PriceItemProps, 'size' | 'inline'> & {
  type: PriceDisplayType
}

function getPriceItemColor(
  isMainPrice: boolean | undefined,
  priceType: PriceDisplayType,
  theme: Theme
) {
  const errorColorTypes: Array<PriceDisplayType> = ['negative', 'saving']

  if (isMainPrice) return theme.palette.text.primary

  if (priceType && errorColorTypes.includes(priceType))
    return theme.palette.error.main

  return theme.palette.text.secondary
}

export const useProductPricesStyles = makeStyles(
  createStyles({
    concordeContainer: {
      display: 'flex',
      gap: '4px',
      flexDirection: 'column'
    }
  })
)
