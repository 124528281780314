import { CatalogProductTile as ConcordeCatalogProductTile } from '@epilot/concorde-elements'
import {
  computePriceDisplayInJourneys,
  withDefaultConfigurationValues,
  getDefaultQuantity,
  getNewQuantity
} from '@epilot/journey-logic-commons'
import type {
  GeneralTileConfiguration,
  ProductTileData,
  SelectionType,
  TileControlConfiguration,
  SelectedProductTile
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'

import { ProductDetailsModal } from '../../../../../components/CatalogProductTile/ProductDetails'
import { ComponentMappingDataGraduated } from '../../../../../components/Product'
import {
  FeatureFlags,
  useConfig,
  useFlags,
  useComputePricingDetails,
  useJourneyContext
} from '../../../../../utils'
import { CatalogTileContext } from '../../utils/tile-context-utils'
import type { TileContext } from '../../utils/tile-context-utils'
import { CatalogProductTileHtml } from '../htmlComponents/CatalogProductTileHtml'
import { NormalizedSectionContainer } from '../htmlComponents/NormalizedSectionContainer'
import { GetAGPreview } from '../htmlComponents/TileBody/GetAGPreview'

export type CatalogProductTileProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  'onChange'
> &
  ProductTileData & {
    config: {
      generalTileConfiguration?: GeneralTileConfiguration
      tileControlConfiguration?: TileControlConfiguration
    }
    t: TFunction
    onChange?: (value?: SelectedProductTile | SelectedProductTile[]) => void
    selectedProductTile?: SelectedProductTile | SelectedProductTile[]
    id: string
    tileVariantsEnabled: boolean
  }

export const CatalogProductTile = ({
  selectedProductTile,
  config,
  product,
  price,
  t,
  className,
  onChange,
  id,
  coupons,
  tileVariantsEnabled
}: CatalogProductTileProps) => {
  const defaultQuantity = getDefaultQuantity({
    selectedProductTile,
    minValue: config.generalTileConfiguration?.quantityConfig?.minValue,
    selectionType: config.selectionType,
    productId: product._id,
    priceId: price._id
  })

  const concordeProductCardFF = useFlags()[FeatureFlags.CONCORDE_PRODUCT_CARD]
  const IMAGE_PREVIEW_API_URL = useConfig().IMAGE_PREVIEW_API_URL

  const { context: journeyContext } = useJourneyContext()

  const [quantity, setQuantity] = useState(defaultQuantity)
  const priceDisplayInJourneys = useMemo(
    () => computePriceDisplayInJourneys(price, quantity),
    [price, quantity]
  )

  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity])

  const updateQuantityHandler = (
    quantity: number,
    selectionType: SelectionType
  ) => {
    const newQuantity = getNewQuantity(
      context.config.generalTileConfiguration.quantityConfig,
      quantity
    )

    setQuantity(newQuantity)
    if (onChange) {
      const shouldDeselectProductTile = newQuantity <= 0

      const newTile = {
        quantity: newQuantity,
        product: {
          selectedProductId: product._id,
          selectedPriceId: price._id,
          selectedCouponIds: coupons?.map((coupon) => coupon._id),
          selectionMetadata: {
            selectedCoupons: coupons,
            selectedProduct: product,
            selectedPrice: price,
            blockConfiguration: {
              isRequired: Boolean(
                config.generalTileConfiguration?.isBlockRequired
              ),
              showQuantity: Boolean(
                config.generalTileConfiguration?.quantityConfig?.show
              ),
              blockPath: config.generalTileConfiguration?.blockPath || ''
            }
          }
        }
      }

      if (selectionType === 'single') {
        if (shouldDeselectProductTile) {
          onChange(undefined)

          return
        }

        onChange(newTile)
      } else {
        if (shouldDeselectProductTile) {
          onChange(filterSelectedProductTiles(product._id, price._id))

          return
        }

        const selectedProductTiles = Array.isArray(selectedProductTile)
          ? selectedProductTile
          : []
        const updatedProductTile = selectedProductTiles.find(
          (productTile) =>
            productTile.product.selectedPriceId === price?._id &&
            productTile.product.selectedProductId === product?._id
        )

        if (updatedProductTile) {
          onChange(
            selectedProductTiles.map((tile) =>
              tile === updatedProductTile
                ? { ...tile, quantity: newQuantity }
                : tile
            )
          )
        } else {
          onChange([...selectedProductTiles, newTile])
        }
      }
    }
  }

  const selectProductTileHandler = (
    productTile: SelectedProductTile,
    selectionType: SelectionType
  ) => {
    let newQuantity = productTile.quantity

    if (quantity === 0) {
      newQuantity = getNewQuantity(
        context.config.generalTileConfiguration.quantityConfig,
        1
      )

      setQuantity(newQuantity)
      productTile.quantity = newQuantity
    }

    if (onChange) {
      if (selectionType === 'single') {
        onChange(productTile)
      } else {
        const selectedProductTiles = selectedProductTile
          ? (selectedProductTile as SelectedProductTile[])
          : []

        onChange([...selectedProductTiles, productTile])
      }
    }
  }

  const deselectProductTileHandler = (
    {
      unselectedProductId,
      unselectedPriceId
    }: {
      unselectedProductId?: string
      unselectedPriceId?: string
    },
    selectionType: SelectionType
  ) => {
    const minQuantityValue =
      context.config.generalTileConfiguration.quantityConfig.minValue

    setQuantity(minQuantityValue)

    if (onChange) {
      if (selectionType === 'single') {
        onChange(undefined)
      } else {
        onChange(
          filterSelectedProductTiles(unselectedProductId, unselectedPriceId)
        )
      }
    }
  }

  function filterSelectedProductTiles(
    unselectedProductId: string | undefined,
    unselectedPriceId: string | undefined
  ) {
    const selectedProductTiles = selectedProductTile
      ? (selectedProductTile as SelectedProductTile[])
      : []

    const filteredProductTiles = selectedProductTiles.filter(
      (productTile) =>
        productTile.product.selectedProductId !== unselectedProductId ||
        productTile.product.selectedPriceId !== unselectedPriceId
    )

    return filteredProductTiles.length > 0 ? filteredProductTiles : undefined
  }

  const { pricingDetails, blockMappings, externalFeesMappings } =
    useComputePricingDetails({ price })

  const context: TileContext = {
    config: withDefaultConfigurationValues(config),
    product: product,
    price: price,
    coupons: config.coupons,
    pricingDetails,
    blockMappings,
    externalFeesMappings,
    useNewDesign: journeyContext.journey.settings?.useNewDesign,
    displayCtaButtonUnderPrice:
      config.generalTileConfiguration?.displayCtaButtonUnderPrice || false,
    actions: {
      setQuantity: (quantity: number) =>
        updateQuantityHandler(quantity, context.config.selectionType),
      increaseQuantity: () => {
        updateQuantityHandler(quantity + 1, context.config.selectionType)
      },
      decreaseQuantity: () => {
        updateQuantityHandler(quantity - 1, context.config.selectionType)
      },
      selectProductTile: (productTile: SelectedProductTile) =>
        selectProductTileHandler(productTile, context.config.selectionType),
      deselectProductTile: () =>
        deselectProductTileHandler(
          {
            unselectedProductId: product?._id,
            unselectedPriceId: price?._id
          },
          context.config.selectionType
        ),
      t
    },
    tileState: {
      quantity,
      priceDisplayInJourneys
    },
    id,
    tileVariantsEnabled
  }

  /**
   * @todo This runs on every render. Add dependency array to avoid this,
   * or reconsider usage of state here.
   */
  useEffect(() => {
    if (context.config.selectionType === 'single') {
      const currentSelectedProductTile = selectedProductTile
        ? (selectedProductTile as SelectedProductTile)
        : undefined

      if (
        onChange &&
        currentSelectedProductTile?.product?.selectedProductId !==
          context.product?._id &&
        currentSelectedProductTile?.product?.selectedPriceId !==
          context.price?._id
      ) {
        setQuantity(
          config?.generalTileConfiguration?.quantityConfig?.minValue ?? 0
        )
      }
    }
  })

  return (
    <CatalogTileContext.Provider value={context}>
      {concordeProductCardFF &&
      journeyContext.journey.settings?.useNewDesign ? (
        <ConcordeCatalogProductTile
          ComponentMappingDataGraduated={ComponentMappingDataGraduated}
          GetAgComponent={GetAGPreview}
          IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL}
          NormalizedSectionContainer={NormalizedSectionContainer}
          ProductDetailsModalComponent={ProductDetailsModal}
          context={context}
        />
      ) : (
        <CatalogProductTileHtml className={className} />
      )}
    </CatalogTileContext.Provider>
  )
}

CatalogProductTile.displayName = 'CatalogProductTile'
