import { Divider, Grid } from '@epilot/journey-elements'
import type {
  AddressSuggestionsSource,
  ListedFields,
  Address,
  Contact
} from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type {
  Control,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ContactFormFields } from '../../../../components/contact/ContactFormFields'
import { FieldText } from '../../../../components/FieldText'
import { isAnyInObject } from '../../../../utils/helper'
import type { AddressControlData } from '../types'

import { FieldStreetInput } from './FieldStreetInput'
import { FieldSuburbInput } from './FieldSuburbInput'
import { FieldZipCityInput } from './FieldZipCityInput'
import { useStyles } from './styles'

interface ListedAddressFormProps {
  hasError: boolean
  disableAddressSuggestions: boolean
  acceptSuggestedOnly: boolean
  addressCountryCode: string
  addressFreeText?: boolean
  addressSuggestionsSource?:
    | AddressSuggestionsSource
    | AddressSuggestionsSource[]
  path: string
  injectedData: Partial<AddressControlData>
  setAddressDown: ((value: boolean) => void) | undefined
  fieldValues: Address
  control: Control<Address>
  register: UseFormRegister<Address>
  resetField: UseFormResetField<Address>
  setValue: UseFormSetValue<Address>
  fields: ListedFields
  keepAutoCompleteClosed?: boolean
  id: string
}

export const ListedAddressForm = ({
  path,
  addressSuggestionsSource,
  addressCountryCode,
  hasError,
  disableAddressSuggestions,
  fieldValues,
  control,
  addressFreeText,
  injectedData,
  register,
  resetField,
  setValue,
  setAddressDown,
  fields,
  acceptSuggestedOnly,
  keepAutoCompleteClosed,
  id
}: ListedAddressFormProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container spacing={3}>
      {fields.zipCity && (
        <Grid
          item
          md={fields.zipCity?.layout?.md || 6}
          sm={fields.zipCity?.layout?.sm || 12}
          xs={fields.zipCity?.layout?.xs || 12}
        >
          <FieldZipCityInput
            acceptSuggestedOnly={acceptSuggestedOnly}
            addressCountryCode={addressCountryCode}
            addressSuggestionsSource={addressSuggestionsSource}
            control={control}
            defaultValue={fields.zipCity.defaultValue || ''}
            disableAddressSuggestions={disableAddressSuggestions}
            fieldValues={fieldValues}
            freeSolo={addressFreeText}
            hasError={hasError}
            name="zipCode"
            path={path}
            register={register}
            setAddressDown={setAddressDown}
            setValue={setValue}
            {...fields.zipCity}
            disabled={injectedData?.zipCode ? true : fields.zipCity.disabled}
            id={appendStepBlockId(id, 'City / Postcode')}
            keepAutoCompleteClosed={keepAutoCompleteClosed}
            label={t('City / Postcode')}
          />
        </Grid>
      )}
      {fields.suburb && (
        <Grid
          item
          md={fields.suburb?.layout?.md || 6}
          sm={fields.suburb?.layout?.sm || 12}
          xs={fields.suburb?.layout?.xs || 12}
        >
          <FieldSuburbInput
            acceptSuggestedOnly={acceptSuggestedOnly}
            addressSuggestionsSource={addressSuggestionsSource}
            control={control}
            defaultValue={fields.suburb?.defaultValue || ''}
            disableAddressSuggestions={disableAddressSuggestions}
            fieldValues={fieldValues}
            freeSolo={addressFreeText}
            hasError={hasError}
            name="suburb"
            path={path}
            {...fields.suburb}
            disabled={injectedData?.suburb ? true : fields.suburb?.disabled}
            id={appendStepBlockId(id, 'District')}
            keepAutoCompleteClosed={keepAutoCompleteClosed}
            label={t('District')}
          />
        </Grid>
      )}
      {fields.streetName && (
        <Grid
          item
          md={fields.streetName?.layout?.md || 6}
          sm={fields.streetName?.layout?.sm || 12}
          xs={fields.streetName?.layout?.xs || 12}
        >
          <FieldStreetInput
            acceptSuggestedOnly={acceptSuggestedOnly}
            addressSuggestionsSource={addressSuggestionsSource}
            control={control}
            defaultValue={fields.streetName.defaultValue || ''}
            disableAddressSuggestions={disableAddressSuggestions}
            fieldValues={fieldValues}
            freeSolo={addressFreeText}
            hasError={hasError}
            name="streetName"
            path={path}
            {...fields.streetName}
            disabled={
              injectedData?.streetName ? true : fields.streetName.disabled
            }
            id={appendStepBlockId(id, 'Street')}
            keepAutoCompleteClosed={keepAutoCompleteClosed}
            label={t('Street')}
          />
        </Grid>
      )}

      {fields.houseNumber && (
        <Grid
          item
          md={fields.houseNumber?.layout?.md || 6}
          sm={fields.houseNumber?.layout?.sm || 12}
          xs={fields.houseNumber?.layout?.xs || 12}
        >
          <FieldText
            control={control}
            defaultValue={fields.houseNumber.defaultValue || ''}
            hasError={hasError}
            name="houseNumber"
            path={path}
            {...fields.houseNumber}
            disabled={
              injectedData?.houseNumber ? true : fields.houseNumber.disabled
            }
            id={appendStepBlockId(id, 'House Number')}
            label={t('houseNumber')}
          />
        </Grid>
      )}
      {fields.extention && (
        <Grid
          item
          md={fields.extention?.layout?.md || 6}
          sm={fields.extention?.layout?.sm || 12}
          xs={fields.extention?.layout?.xs || 12}
        >
          <FieldText
            control={control}
            defaultValue={fields.extention.defaultValue || ''}
            hasError={hasError}
            name="extention"
            path={path}
            {...fields.extention}
            id={appendStepBlockId(id, 'Extention')}
            label={t('extention')}
          />
        </Grid>
      )}
      {isAnyInObject(fields, [
        'salutation',
        'title',
        'firstName',
        'lastName',
        'birthDate',
        'email',
        'telephone'
      ]) && (
        <Grid className={classes.dividerContainer} item xs={12}>
          <Divider className={classes.divider} orientation="horizontal" />
        </Grid>
      )}
      <ContactFormFields
        control={control as any}
        fieldValues={fieldValues}
        fields={fields}
        hasError={hasError}
        id={id}
        path={path}
        resetField={resetField as UseFormResetField<Contact>}
      />
    </Grid>
  )
}
