import type { StepId, StepMap } from './types'

/**
 * Given a map of possible next steps for each step, compute the maximum length
 * of a chain of steps that can be taken from a given step.
 * @example For { 'step1': ['step2', 'step3'], 'step2': ['step3'], 'step3': [] } and 'step1', returns 2
 * @example For { 'step1': ['step2', 'step3'], 'step2': ['step3'], 'step3': [] } and 'step2', returns 1
 */
export const computeMaxLengthOfStepChains = (
  possibleNextStepsForStepMap: StepMap,
  currentStepId: StepId
): number => {
  const stepMaxLengthMap: Record<StepId, number> = {}

  const traverseStepChain = (
    currentStepId: StepId,
    visited: StepId[]
  ): number => {
    if (visited.includes(currentStepId)) {
      return 0
    }

    if (stepMaxLengthMap[currentStepId]) {
      return stepMaxLengthMap[currentStepId]
    }

    visited.push(currentStepId)
    const nextSteps = possibleNextStepsForStepMap[currentStepId] ?? []
    let maxLength = 1

    for (const nextStepId of nextSteps) {
      maxLength = Math.max(
        maxLength,
        1 + traverseStepChain(nextStepId, [...visited])
      )
    }

    stepMaxLengthMap[currentStepId] = maxLength

    return maxLength
  }

  return traverseStepChain(currentStepId, [])
}
