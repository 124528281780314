import {
  parseLogicActionType,
  parseLogicConditionOperator,
  parseLogicConditionSource,
  parseLogicConditionTrigerEvent,
  parseLogicConditionValue,
  parseLogicConditionValueFromInternalIntegration,
  parseLogicConditionValueFromPath,
  parseLogicLiveValueFromPath
} from './logicRegEx'
import type { LogicCondition } from './types'

import type {
  Logic,
  LogicAction,
  LogicActionString,
  LogicConditionString,
  LogicString
} from '.'
import {
  parseLogicActionCondition,
  parseLogicActionSource,
  parseLogicActionTarget
} from '.'

export function stringToCondition(condition: string): LogicCondition {
  const conditionValue = parseLogicConditionValue(condition)
  const conditionValueFromPath = parseLogicConditionValueFromPath(condition)
  const conditionValueFromInternalIntegration =
    parseLogicConditionValueFromInternalIntegration(condition)
  const liveValueFromPath = parseLogicLiveValueFromPath(condition)

  return {
    conditionOperator: `::${parseLogicConditionOperator(condition)}::`,
    ...(conditionValue && { conditionValue: `%%${conditionValue}%%` }),
    conditionSource: `$$${parseLogicConditionSource(condition)}$$` as never,
    event: `||${parseLogicConditionTrigerEvent(condition)}||` as never,
    ...(conditionValueFromPath && {
      conditionValueFromPath: `##${conditionValueFromPath}##` as never
    }),
    ...(conditionValueFromInternalIntegration && {
      conditionValueFromInternalIntegration:
        `@@${conditionValueFromInternalIntegration}@@` as never
    }),
    ...(liveValueFromPath && {
      liveValueFromPath: `==${liveValueFromPath}==` as never
    })
  }
}

export function stringToAction(action: string): LogicAction {
  const actionSource = parseLogicActionSource(action)

  return {
    actionType: `::${parseLogicActionType(action)}::` as never,
    source: `$$${actionSource ?? ''}$$` as never,
    target: `##${parseLogicActionTarget(action)}##` as never,
    conditionResult: parseLogicActionCondition(action) as never
  }
}

export function logicStringsToObjects(logs: LogicString[] = []) {
  return logs.map<Logic>((log, i) => {
    const conditions = log.conditions.map((l) => stringToCondition(l))
    const actions = log.actions.map((a) => stringToAction(a))

    return {
      ...(log.autoGeneratedId && { autoGeneratedId: log.autoGeneratedId }),
      _tempId:
        conditions[0] && actions[0]
          ? conditions[0].conditionSource + actions[0].actionType + i
          : '' + i,
      conditions: conditions,
      actions: actions
    }
  })
}

export function conditionToString(
  condition: LogicCondition
): LogicConditionString {
  const {
    conditionSource,
    conditionOperator,
    conditionValue,
    event,
    conditionValueFromPath,
    liveValueFromPath,
    conditionValueFromInternalIntegration
  } = condition

  return `${conditionSource || ''}${conditionOperator || ''}${
    conditionValue || ''
  }${event || ''}${conditionValueFromPath || ''}${liveValueFromPath || ''}${
    conditionValueFromInternalIntegration || ''
  }`
}

export function actionToString(action: LogicAction): LogicActionString {
  const { actionType, source, target, conditionResult } = action

  return `${conditionResult === false ? '!FALSE!' : '!TRUE!'}${
    actionType || ''
  }${source || ''}${target || ''}`
}

export function logicsToString(logics: Logic[]) {
  return logics.map<LogicString>((logic) => ({
    ...(logic.autoGeneratedId && { autoGeneratedId: logic.autoGeneratedId }),
    conditions:
      logic.conditions?.map<LogicConditionString>((con) =>
        conditionToString(con)
      ) ?? [],
    actions:
      logic.actions?.map<LogicActionString>((act) => actionToString(act)) ?? []
  }))
}
