import { makeStyles } from '@epilot/journey-elements'

export const useProductCategoryControlStyles = makeStyles({
  panel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    flexDirection: 'column'
  }
})
