import { Divider, createStyles, makeStyles } from '@epilot/journey-elements'
import type { File } from '@epilot/journey-logic-commons'

import { ProductDetails } from '../../../../../../components/CatalogProductTile/ProductDetails'
import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'
import { ProductPricesComponents } from '../ProductPricesComponents'

import { PriceTierDetails } from './PriceTierDetails'
import { ProductCollapsedData } from './ProductCollapsedData'
import { ProductDownloads } from './ProductDownloads'
import { ProductFootnote } from './ProductFootnote'

interface TileFooterProps {
  shouldDisplayPriceComponents: boolean
  shouldDisplayTiersDetails: boolean
  shouldDisplayDownloads: boolean
  downloads: File[]
}

export const TileFooterHtml = ({
  shouldDisplayDownloads,
  shouldDisplayPriceComponents,
  shouldDisplayTiersDetails,
  downloads
}: TileFooterProps) => {
  const classes = useTileFooterStyles()
  const { config, useNewDesign, pricingDetails } = useTileContext()
  const priceItem = pricingDetails?.items?.[0]

  if (!priceItem) {
    return null
  }

  return (
    <NormalizedSectionContainer
      sectionName={useNewDesign ? 'concordeFooter' : 'footer'}
    >
      <footer className={classes.container} data-testid="footer">
        <div className={classes.footerData}>
          {config.generalTileConfiguration.showProductDetails ? (
            <ProductDetails />
          ) : (
            <>
              {!useNewDesign && (
                <>
                  <ProductFootnote />
                  {(shouldDisplayPriceComponents ||
                    shouldDisplayDownloads ||
                    shouldDisplayTiersDetails) && (
                    <ProductCollapsedData>
                      {shouldDisplayPriceComponents && (
                        <ProductPricesComponents />
                      )}
                      {shouldDisplayDownloads && (
                        <ProductDownloads downloads={downloads} />
                      )}
                      {shouldDisplayTiersDetails && (
                        <PriceTierDetails priceItem={priceItem} />
                      )}
                    </ProductCollapsedData>
                  )}
                </>
              )}
              {useNewDesign && shouldDisplayDownloads && (
                <ProductDownloads downloads={downloads} />
              )}
            </>
          )}
        </div>
        {!useNewDesign && <Divider className={classes.divider} />}
      </footer>
    </NormalizedSectionContainer>
  )
}

const useTileFooterStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    divider: {
      width: '100%',
      marginBottom: 'auto'
    },
    footerData: {
      /**
       * Used to hide the tile footer when there is no data to be rendered.
       * For this to work, the container order must be reversed and
       * the footerData div must be the first child.
       *
       * :has pseudo-selector could be used on the container element instead
       * but isn't broadly supported at the moment.
       */
      ['&:empty ~ *, &:empty']: {
        display: 'none'
      }
    }
  })
)
