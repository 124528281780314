import {
  Link as ConcordeLink,
  Icon as ConcordeIcon
} from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import {
  BodyText,
  createStyles,
  Icon,
  Link,
  makeStyles,
  StackSpacing
} from '@epilot/journey-elements'
import type { File } from '@epilot/journey-logic-commons'
import { appendStepBlockId, getPublicUrl } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../../renderers/controls/ProductSelectionControl/utils/tile-context-utils'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4)
    },
    download: {
      display: 'inline-flex',
      gap: theme.spacing(4)
    },
    downloadText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(3)
    },
    icon: {
      fontSize: '24px !important'
    }
  })
)

type ProductDetailsDownloadsProps = {
  downloads: File[]
}

function getDownloadLinkId(id: string, title?: string) {
  return appendStepBlockId(id, `Product Downloads ${title}`)
}

export const ProductDetailsDownloads = ({
  downloads
}: ProductDetailsDownloadsProps) => {
  const { useNewDesign, id } = useTileContext()

  const classes = useStyles()

  return (
    <StackSpacing scale={1}>
      <div className={classes.container}>
        {useNewDesign ? (
          <>
            {downloads.map((download) => (
              <div className={classes.download} key={download._id}>
                <ConcordeIcon name="download" />
                <ConcordeLink
                  href={getPublicUrl(download)}
                  id={getDownloadLinkId(id, download._title)}
                  target="_blank"
                >
                  {download.filename}
                </ConcordeLink>
              </div>
            ))}
          </>
        ) : (
          <>
            {downloads.map((download) => (
              <Link
                className={classes.download}
                href={getPublicUrl(download)}
                id={getDownloadLinkId(id, download._title)}
                key={download._id}
                target="_blank"
              >
                <Icon
                  className={classes.icon}
                  color="secondary"
                  name="download"
                />
                <BodyText
                  className={classes.downloadText}
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {download.filename}
                </BodyText>
              </Link>
            ))}
          </>
        )}
      </div>
    </StackSpacing>
  )
}
