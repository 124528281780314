import {
  CircularProgress as MuiCircularProgress,
  LinearProgress
} from '@material-ui/core'

import type { CircularProgressProps } from './types'

export function CircularProgress(props: CircularProgressProps) {
  const { ...rest } = props

  return <MuiCircularProgress {...rest} />
}
export { LinearProgress }
