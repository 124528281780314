import { CONTROL_NAME } from '@epilot/journey-logic-commons'

export const toBeHashedControllerList = [
  CONTROL_NAME.PERSONAL_INFORMATION_CONTROL,
  CONTROL_NAME.PAYMENT_CONTROL,
  CONTROL_NAME.ADDRESS_BLOCK
]

const crypto = window.crypto

const sha256 = async (text: string): Promise<string> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(text)
  const hash = await crypto.subtle.digest('SHA-256', data)

  return Array.from(new Uint8Array(hash))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
}

export const hashValue = async (value: string | Date): Promise<string> => {
  if (typeof value === 'undefined' || JSON.stringify(value) === '{}') {
    return ''
  }

  const valueToHash = value instanceof Date ? value.toISOString() : value

  const hashedOne = await sha256(valueToHash)

  return hashedOne
}

export const prepareTrackingData = async (updatedState: any, steps: any) => {
  const mainState = structuredClone(updatedState)

  const processedMainState: any = {}

  for (let i = 0; i < mainState?.length; i++) {
    const state = mainState[i]
    const index = i
    const stateKeys = Object.keys(state)

    if (stateKeys.length === 0) {
      continue
    }

    const step = steps[index]

    const stepName = step.name
    const elements = step.uischema?.elements || []

    const flattenElements = elements.flat() // Flatten the elements array if it is an array of arrays

    for (let j = 0; j < stateKeys.length; j++) {
      const key = stateKeys[j]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let matchingElement: any = undefined

      for (let index = 0; index < flattenElements.length; index++) {
        const element = flattenElements[index]

        if (element?.scope?.endsWith(key)) {
          matchingElement = element
          break
        } else if (Array.isArray(element?.elements)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          matchingElement = element.elements.find((item: any) =>
            item?.scope?.endsWith(key)
          )

          if (matchingElement) {
            break
          }
        }
      }

      if (matchingElement) {
        if (toBeHashedControllerList.includes(matchingElement.type)) {
          if (state[key] instanceof Date) {
            state[key] = await hashValue(state[key].toISOString())
          } else if (typeof state[key] === 'object') {
            state[key] = await recursivelyHashValues(state[key])
          } else {
            state[key] = await hashValue(state[key])
          }
        }
      }
    }

    processedMainState[stepName] = state
  }

  return processedMainState
}

export const recursivelyHashValues = async (obj: any) => {
  if (typeof obj !== 'object') {
    return await hashValue(obj)
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = await recursivelyHashValues(obj[key])
    }
  }

  return obj
}
