import { CircularProgress, makeStyles } from '@epilot/journey-elements'

export const Loading = () => {
  const classes = useStyles()

  return (
    <div className={classes.panel}>
      <CircularProgress color="inherit" size={40} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  panel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    flexDirection: 'column',
    background: '#fff'
  }
}))
