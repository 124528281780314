import type { Theme } from '@material-ui/core'
import {
  FormControlLabel,
  Radio as MuiRadio,
  makeStyles,
  createStyles,
  SvgIcon,
  useTheme
} from '@material-ui/core'

import type { RadioProps } from './types'

const useStyles = makeStyles<Theme, { radioPlacement: string }>(() =>
  createStyles({
    input: ({ radioPlacement }) => ({
      alignSelf:
        radioPlacement === 'top'
          ? 'flex-start'
          : radioPlacement === 'bottom'
            ? 'flex-end'
            : ''
    })
  })
)

export default function Radio(props: RadioProps) {
  const {
    label,
    labelPlacement,
    radioPlacement = 'top',
    value,
    checkedIcon,
    color,
    size = 'small',
    containerStyle,
    id,
    ...rest
  } = props

  const classes = useStyles({ radioPlacement })
  const theme = useTheme()

  return (
    <FormControlLabel
      control={
        <MuiRadio
          checkedIcon={
            checkedIcon ? (
              checkedIcon
            ) : (
              <SvgIcon fontSize={size === 'small' ? 'small' : 'default'}>
                <circle
                  cx="12"
                  cy="12"
                  fill="transparent"
                  r={size === 'medium' ? 7 : 6.5}
                  stroke={
                    color === 'secondary'
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main
                  }
                  strokeWidth="5"
                />
              </SvgIcon>
            )
          }
          className={classes.input}
          color={color}
          id={id}
          size={size}
          {...rest}
        />
      }
      htmlFor={id}
      label={label}
      labelPlacement={labelPlacement}
      style={containerStyle}
      value={value}
    />
  )
}
