import { Icon, Typography, Button, clsx } from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { ReactNode } from 'react'

import { useEnumButtonStyles } from '../../EnumButtonControl/styles/useEnumButtonStyles'
import useResizeDirection from '../../EnumButtonControl/useResizeDirection'
import { useSecondaryActionButtonStyles } from '../styles'
import type { SecondaryActionBarProps } from '../types'
import { startsWithHttp } from '../utils'

type Option = {
  value: string
  label: string
}

export const SecondaryActionButton = ({
  options,
  label,
  id
}: SecondaryActionBarProps) => {
  const classes = useSecondaryActionButtonStyles()
  const buttonStyles = useEnumButtonStyles()
  const [direction, buttonWrapRef] = useResizeDirection()

  const buttons: ReactNode[] = options.map((option: Option, index: number) => {
    const label = option.label
    const value = option.value

    const buttonId = appendStepBlockId(id, label)

    return (
      <Button
        className={buttonStyles.button}
        color="primary"
        href={startsWithHttp(value) ? value : `https://${value}`}
        id={buttonId}
        key={index}
        rel="noopener"
        size="large"
        target="_blank"
        variant="outlined"
      >
        <div className={classes.iconContainer}>
          <div className="buttonText">{label}</div>
          <Icon className={classes.icon} color="primary" name="launch" />
        </div>
      </Button>
    )
  })

  return (
    <div className={label ? '' : buttonStyles.containerWithoutLabel}>
      <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
        {label}
      </Typography>

      <div
        className={clsx(buttonStyles.buttonWrap, {
          [buttonStyles.columnDirection]: direction === 'column'
        })}
        ref={buttonWrapRef}
      >
        {buttons}
      </div>
    </div>
  )
}
