import { Spacing } from '@epilot/concorde-elements'
import type { BillingPeriod, Price } from '@epilot/journey-logic-commons'
import { computePrice, shouldShowDecimals } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'

import { GetAGPreview } from './GetAGPreview'
import { ProductPriceItem } from './ProductPriceItem'
import { useProductPricesStyles } from './styles'

export const ProductPrices = () => {
  const {
    config,
    price,
    pricingDetails,
    actions: { t },
    tileState: { priceDisplayInJourneys },
    useNewDesign
  } = useTileContext()

  const classes = useProductPricesStyles()

  if (!price) return null

  const shouldDisplayRecurringTotal =
    config.generalTileConfiguration.displayRecurringTotal
  const recurringTotalUnitOption = shouldDisplayRecurringTotal
    ? config.generalTileConfiguration.recurringTotalUnitOption
    : undefined
  const isGetAGProductPreview =
    config.generalTileConfiguration.isPreviewMode && config.isGetAgPrice

  const {
    discountAmount,
    discountAmountDecimal,
    beforeDiscountTotalAmount,
    beforeDiscountTotalAmountDecimal,
    amountTotal,
    amountTotalDecimal,
    oneTimeTotal,
    oneTimeTotalDecimal,
    earliestRecurrence,
    computedRecurringPriceTotal
  } = computePrice({
    pricingDetails,
    billingPeriod: recurringTotalUnitOption
  })

  const totalAmountToDisplay = shouldShowDecimals(amountTotal)
    ? amountTotalDecimal
    : amountTotal

  const discountAmountToDisplay = shouldShowDecimals(discountAmount)
    ? discountAmountDecimal
    : discountAmount

  const beforeDiscountTotalAmountToDisplay = shouldShowDecimals(
    beforeDiscountTotalAmount
  )
    ? beforeDiscountTotalAmountDecimal
    : beforeDiscountTotalAmount

  const oneTimeAmountToDisplay = shouldShowDecimals(oneTimeTotal)
    ? oneTimeTotalDecimal
    : oneTimeTotal

  const shouldShowRecurringTotal =
    shouldDisplayRecurringTotal &&
    earliestRecurrence !== null &&
    priceDisplayInJourneys !== 'show_as_on_request'

  const shouldShowEarliestRecurrence =
    earliestRecurrence !== null &&
    priceDisplayInJourneys !== 'show_as_on_request'

  return (
    <NormalizedSectionContainer
      className={useNewDesign ? classes.concordeContainer : ''}
      data-testid="product-prices-section"
      sectionName={useNewDesign ? 'concordePrice' : 'price'}
    >
      <Spacing alignItems="center" scale={useNewDesign ? 2 : 6} variant="stack">
        <>
          {isGetAGProductPreview && <GetAGPreview t={t} />}

          {(!price.is_composite_price && (
            <ProductPriceItem
              amount={totalAmountToDisplay}
              beforeDiscountAmountToDisplay={beforeDiscountTotalAmountToDisplay}
              billingPeriod={price.billing_period}
              currency={price.unit_amount_currency}
              discountAmount={discountAmountToDisplay}
              hideOneTimeLabel={true}
              inline={shouldDisplayRecurringTotal}
              priceDisplayInJourneys={priceDisplayInJourneys}
              priceType={price.type}
              size="large"
            />
          )) || (
            <>
              {(oneTimeAmountToDisplay !== undefined ||
                priceDisplayInJourneys === 'show_as_on_request') && (
                <ProductPriceItem
                  amount={oneTimeAmountToDisplay}
                  billingPeriod="one_time"
                  currency={price.unit_amount_currency}
                  hideOneTimeLabel={
                    !shouldShowRecurringTotal && !shouldShowEarliestRecurrence
                  }
                  inline={shouldDisplayRecurringTotal}
                  priceDisplayInJourneys={priceDisplayInJourneys}
                  priceType="one_time"
                  size="large"
                />
              )}
              {shouldShowEarliestRecurrence && (
                <ProductPriceItem
                  amount={earliestRecurrence?.amount_total}
                  billingPeriod={
                    earliestRecurrence?.billing_period as BillingPeriod
                  }
                  currency={price.unit_amount_currency}
                  inline={shouldDisplayRecurringTotal}
                  priceDisplayInJourneys={priceDisplayInJourneys}
                  priceType={earliestRecurrence?.type as Price['type']}
                  size={!oneTimeTotal ? 'large' : undefined}
                />
              )}
            </>
          )}

          {shouldShowRecurringTotal && (
            <ProductPriceItem
              amount={computedRecurringPriceTotal?.amount_total}
              billingPeriod={computedRecurringPriceTotal?.billing_period}
              currency={price.unit_amount_currency}
              inline={shouldDisplayRecurringTotal}
              isPriceRecurringTotal={true}
              priceDisplayInJourneys={priceDisplayInJourneys}
              priceType={computedRecurringPriceTotal?.type as Price['type']}
              size="small"
            />
          )}
        </>
      </Spacing>
    </NormalizedSectionContainer>
  )
}
