import type { EpilotTheme } from '..'
import { AutocompleteX, TextField, createStyles, makeStyles } from '..'

import type { EnumControlProps } from './EnumControl'

export type EnumAutocompleteControlProps = EnumControlProps

export function EnumAutocompleteControl({
  errorMessage,
  label,
  onChange,
  options = [],
  optionsLabels,
  placeholder,
  value,
  variant,
  size,
  defaultSelection: initial,
  customBackgroundColor,
  noOptionsText,
  required,
  id
}: EnumAutocompleteControlProps) {
  const labels = optionsLabels ? optionsLabels : options
  const defaultSelection = optionsLabels
    ? optionsLabels[options.findIndex((e) => e === initial)]
    : initial
  // the value of autocomplete should consistent with option list in Autocomplete
  // otherwise the Autocomplete does not show correctly
  const labelIndex = options && options.indexOf(value)
  const autoCompleteValue = value
    ? labelIndex >= 0
      ? labels[labelIndex]
      : value
    : defaultSelection

  const handleOnChange = (
    _event: React.ChangeEvent,
    newValue: string | string[] | null
  ) => {
    if (newValue) {
      // find the index of the label
      const labelIndex = labels.indexOf(newValue)

      // if the index was found, make the call back and pass the option (NOT the label to it)
      if (labelIndex !== -1) {
        onChange(options[labelIndex])
      }
    }
  }
  const classes = useStyles()

  const displayLabel = required ? (label ? label : '*') : label

  return (
    <AutocompleteX
      classes={{ paper: classes.paper }}
      fullWidth
      id={id}
      noOptionsText={noOptionsText}
      onChange={handleOnChange as never}
      options={labels}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ htmlFor: id }}
          customBackgroundColor={customBackgroundColor}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          label={displayLabel}
          placeholder={placeholder}
          variant={variant}
        />
      )}
      size={size}
      value={autoCompleteValue}
    />
  )
}

export const useStyles = makeStyles<EpilotTheme>((theme) =>
  createStyles({
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)
