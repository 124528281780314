import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemIcon as MuiListItemIcon,
  ListItemSecondaryAction as MuiListItemSecondaryAction,
  ListSubheader as MuiListSubheader
} from '@material-ui/core'

import type {
  ListProps,
  ListItemProps,
  ListItemContentProps,
  ListItemPrefixProps,
  ListItemSufixProps,
  ListSubheaderProps
} from './types'

export function List(props: ListProps) {
  return <MuiList component="ul" disablePadding={true} {...props} />
}

export function ListItem(props: ListItemProps) {
  const { button = false, ...rest } = props

  // Using `any` as a workaround for a ts issue: https://github.com/mui-org/material-ui/issues/14971
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <MuiListItem button={button as any} {...rest} />
}

export function ListItemContent(props: ListItemContentProps) {
  return <MuiListItemText {...props} />
}

export function ListItemPrefix(props: ListItemPrefixProps) {
  return <MuiListItemIcon {...props} />
}

export function ListItemSufix(props: ListItemSufixProps) {
  return <MuiListItemSecondaryAction {...props} />
}

export function ListSubheader(props: ListSubheaderProps) {
  return <MuiListSubheader {...props} />
}
