import type { Product } from '../../types'

export function aggregateUniqueCategories(
  products: Product[] | undefined,
  orderByProductIds?: string[]
): string[] {
  if (!products) return []

  // Reduces the 'products' array by accumulating unique categories
  const aggregatedCategories: string[] = products.reduce(
    (accumulator: string[], result: Product) => {
      const resultCategories: string[] = result.categories || []

      // Filters out categories that are already in the accumulator and pushes the unique ones
      resultCategories.forEach((category: string) => {
        if (!accumulator.includes(category)) {
          accumulator.push(category)
        }
      })

      return accumulator
    },
    []
  )

  if (orderByProductIds) {
    // Sort the aggregated categories array based on the order of product ids
    aggregatedCategories.sort((a, b) => {
      const productA = products.find((product) =>
        product?.categories?.includes(a)
      )

      const productB = products.find((product) =>
        product?.categories?.includes(b)
      )

      if (productA?._id && productB?._id) {
        const indexA = orderByProductIds.indexOf(productA._id)
        const indexB = orderByProductIds.indexOf(productB._id)

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB
        }
      }

      // If product information or the order id is not found for comparison, maintain the original order
      return 0
    })
  }

  return aggregatedCategories
}
