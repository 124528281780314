import InputMask from 'react-input-mask'

import { TextField } from './TextField'
import type { MaskedTextFieldProps } from './types'

export function MaskedTextField(props: MaskedTextFieldProps) {
  const { textFieldProps, required, inputRef, ...rest } = props
  const {
    onChange,
    onMouseDown,
    onFocus,
    onBlur,
    value,
    disabled,
    ...otherTextFieldProps
  } = textFieldProps

  return (
    <InputMask
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      value={value as string | number | undefined}
      {...rest}
    >
      {
        (() => (
          <TextField
            inputRef={inputRef}
            required={required}
            {...otherTextFieldProps}
          />
        )) as unknown as React.ReactNode
      }
    </InputMask>
  )
}
