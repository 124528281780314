/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import type {
  AddressControlData,
  ZipCitySuggestionData,
  StreetSuggestionData,
  SuburbSuggestionData,
  ZipCitySuggestion
} from './types'

export function isAddressControlData(
  obj: any,
  _argumentName?: string
): obj is AddressControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.zipCode === 'undefined' || typeof obj.zipCode === 'string') &&
      (typeof obj.city === 'undefined' || typeof obj.city === 'string') &&
      (typeof obj.streetName === 'undefined' ||
        typeof obj.streetName === 'string') &&
      (typeof obj.houseNumber === 'undefined' ||
        typeof obj.houseNumber === 'string') &&
      (typeof obj.extention === 'undefined' ||
        typeof obj.extention === 'string') &&
      typeof obj.countryCode === 'string' &&
      (typeof obj.meta === 'undefined' ||
        (((obj.meta !== null && typeof obj.meta === 'object') ||
          typeof obj.meta === 'function') &&
          typeof obj.meta.manualData === 'boolean'))) ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.salutation === 'undefined' ||
        obj.salutation === null ||
        typeof obj.salutation === 'string') &&
      (typeof obj.title === 'undefined' ||
        obj.title === null ||
        typeof obj.title === 'string') &&
      (typeof obj.firstName === 'undefined' ||
        typeof obj.firstName === 'string') &&
      (typeof obj.lastName === 'undefined' ||
        typeof obj.lastName === 'string') &&
      (typeof obj.birthDate === 'undefined' ||
        obj.birthDate === null ||
        obj.birthDate instanceof Date) &&
      (typeof obj.email === 'undefined' || typeof obj.email === 'string') &&
      (typeof obj.telephone === 'undefined' ||
        typeof obj.telephone === 'string') &&
      (typeof obj.customerType === 'undefined' ||
        obj.customerType === 'Private' ||
        obj.customerType === 'Business') &&
      (typeof obj.companyName === 'undefined' ||
        typeof obj.companyName === 'string') &&
      (typeof obj.registryCourt === 'undefined' ||
        typeof obj.registryCourt === 'string') &&
      (typeof obj.registerNumber === 'undefined' ||
        typeof obj.registerNumber === 'string'))
  )
}

export function isZipCitySuggestion(
  obj: any,
  _argumentName?: string
): obj is ZipCitySuggestion {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    typeof obj.postal_code === 'string' &&
    typeof obj.city === 'string' &&
    (typeof obj.uuid === 'undefined' || typeof obj.uuid === 'string')
  )
}

export function isZipCitySuggestionData(
  obj: any,
  _argumentName?: string
): obj is ZipCitySuggestionData {
  return (
    Array.isArray(obj) &&
    obj.every((e: any) => isZipCitySuggestion(e) as boolean)
  )
}

export function isStreetSuggestionData(
  obj: any,
  _argumentName?: string
): obj is StreetSuggestionData {
  return (
    Array.isArray(obj) &&
    obj.every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        (typeof e.uuid === 'undefined' || typeof e.uuid === 'string') &&
        (typeof e.postal_code === 'undefined' ||
          typeof e.postal_code === 'string') &&
        (typeof e.city === 'undefined' || typeof e.city === 'string') &&
        typeof e.street === 'string' &&
        (typeof e.house_number === 'undefined' ||
          typeof e.house_number === 'string')
    )
  )
}

export function isSuburbSuggestionData(
  obj: any,
  _argumentName?: string
): obj is SuburbSuggestionData {
  return (
    Array.isArray(obj) &&
    obj.every(
      (e: any) =>
        ((e !== null && typeof e === 'object') || typeof e === 'function') &&
        (typeof e.uuid === 'undefined' || typeof e.uuid === 'string') &&
        typeof e.postal_code === 'string' &&
        typeof e.city === 'string' &&
        typeof e.district === 'string'
    )
  )
}
