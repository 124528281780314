import type { File } from '@epilot/journey-logic-commons'

import { Image } from '../../../../../components'
import classes from '../../../styles/ProductImage.module.scss'

export type ImageFile = File & {
  id: string
  src: string
}
interface ProductImageProps {
  image: ImageFile
}

export const OPTIMAL_IMAGE_WIDTH = '350'

export const ProductImage = ({ image }: ProductImageProps) => {
  return (
    <Image alt={image.filename} className={classes.image} src={image.src} />
  )
}
