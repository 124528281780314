// / <reference types="@types/google.maps" />
import {
  ActionBarControlTester,
  AddressControlTester,
  AvailabilityCheckControlTester,
  BooleanTester,
  ConfirmationMessageControlTester,
  ConsentsControlTester,
  ContactControlTester,
  CustomBlockControlTester,
  DatePickerControlTester,
  DigitalSignatureControlTester,
  EntityAttributeControlTester,
  EntityLookupControlTester,
  EnumAutocompleteTester,
  EnumToggleTester,
  GroupLayoutTester,
  ImageControlTester,
  InputCalculatorControlTester,
  JourneyLauncherControlTester,
  LabelRendererTester,
  MainContentCartLayoutTester,
  MainLinearLayoutTester,
  MeterReadingControlTester,
  MeterReadingDynamicControlTester,
  MultichoiceControlTester,
  NumberInputControlTester,
  PaymentControlTester,
  PreviousProviderControlTester,
  ProductCategoryControlTester,
  ProductSelectionControlTester,
  PvRoofPlannerControlTester,
  RadioTester,
  SecondaryActionBarControlTester,
  SelectTester,
  ShopCartControlTester,
  StackSpacingLayoutTester,
  SummaryControlTester,
  PdfSummaryControlTester,
  TextFieldTester,
  UploadPanelControlTester
} from '@epilot/journey-logic-commons'
import type { JsonFormsRendererRegistryEntry } from '@jsonforms/core'

import { MaterialSymbol } from './components/MaterialSymbol'
import ActionBarControl from './renderers/controls/ActionBarControl'
import AddressControl, {
  mapToAddressOptions
} from './renderers/controls/AddressControl'
import AvailabilityCheckControl from './renderers/controls/AvailabilityCheckControl'
import { default as BooleanCheckboxControl } from './renderers/controls/BooleanControl'
import ConfirmationMessageControl from './renderers/controls/ConfirmationMessageControl'
import ConsentsControl from './renderers/controls/ConsentsControl'
import ContactControl, {
  mapToContactOptions
} from './renderers/controls/ContactControl'
import CustomBlockControl from './renderers/controls/CustomBlockControl'
import DatePickerControl from './renderers/controls/DatePickerControl'
import DigitalSignatureControl from './renderers/controls/DigitalSignatureControl'
import EntityAttributeControl from './renderers/controls/EntityAttributeControl'
import type { EntityAttributeControlOptions } from './renderers/controls/EntityAttributeControl/types'
import EntityLookupControl from './renderers/controls/EntityLookupControl'
import type { EntityLookupControlOptions } from './renderers/controls/EntityLookupControl/types'
import { default as EnumAutocompleteControl } from './renderers/controls/EnumAutocompleteControl'
import EnumButtonControl, {
  EnumButtonControlTester
} from './renderers/controls/EnumButtonControl'
import EnumImageButtonControl, {
  EnumImageButtonControlTester
} from './renderers/controls/EnumImageButtonControl'
import { default as EnumRadioControl } from './renderers/controls/EnumRadioControl'
import { default as EnumSelectControl } from './renderers/controls/EnumSelectControl'
import { default as EnumToggleControl } from './renderers/controls/EnumToggleControl'
import ImageControl from './renderers/controls/ImageControl'
import InputCalculatorControl from './renderers/controls/InputCalculatorControl'
import type { InputCalculatorControlData } from './renderers/controls/InputCalculatorControl/types'
import { isInputCalculatorControlData } from './renderers/controls/InputCalculatorControl/types.guard'
import JourneyLauncherControl from './renderers/controls/JourneyLauncherControl/JourneyLauncherControl'
import type { JourneyLauncherControlOptions } from './renderers/controls/JourneyLauncherControl/JourneyLauncherControl'
import {
  MeterReadingControl,
  isECP
} from './renderers/controls/MeterReadingControl'
import type {
  MeterReadingControlDataMeters,
  MeterReadingControlData,
  MeterReadingFormValues
} from './renderers/controls/MeterReadingControl/types'
import { MeterReadingDynamicControl } from './renderers/controls/MeterReadingDynamicControl'
import type { DynamicMeterReadingFormValues } from './renderers/controls/MeterReadingDynamicControl/types'
import { MultichoiceControlRenderer } from './renderers/controls/MultichoiceControl'
import NumberInputControl from './renderers/controls/NumberInputControl'
import PaymentControl from './renderers/controls/PaymentControl'
import PdfSummaryControlRenderer from './renderers/controls/PdfSummaryControl/PdfSummaryControl'
import PreviousProviderControl from './renderers/controls/PreviousProviderControl'
import ProductCategoryControl from './renderers/controls/ProductCategoryControl'
import ProductSelectionControl from './renderers/controls/ProductSelectionControl'
import PvRoofPlannerControl from './renderers/controls/PvRoofPlannerControl'
import SecondaryActionBarControl from './renderers/controls/SecondaryActionBarControl/SecondaryActionBarControl'
import ShopCartControl from './renderers/controls/ShopCartControl'
import SummaryControlRenderer from './renderers/controls/SummaryControl/SummaryControl'
import TextFieldControl from './renderers/controls/TextFieldControl'
import type { PreviousProviderControlOptions } from './renderers/controls/types'
import UploadPanelControl from './renderers/controls/UploadPanelControl/UploadPanelControl'
import LabelRenderer from './renderers/display/LabelRenderer'
import type { MainContentCartZones } from './renderers/display/MainContentCartLayout'
import MainContentCartLayout from './renderers/display/MainContentCartLayout'
import MainLinearLayout from './renderers/display/MainLinearLayout'
import StackSpacingLayout from './renderers/display/StackSpacingLayout'
import GroupLayout from './renderers/layouts/GroupLayout'

export {
  MainContentCartLayout,
  MainLinearLayout,
  ConsentsControl,
  CustomBlockControl,
  ActionBarControl,
  ConfirmationMessageControl,
  ContactControl,
  mapToContactOptions,
  LabelRenderer,
  StackSpacingLayout,
  TextFieldControl,
  EnumToggleControl,
  EnumSelectControl,
  EnumRadioControl,
  EnumAutocompleteControl,
  EnumButtonControl,
  EnumImageButtonControl,
  BooleanCheckboxControl,
  ProductSelectionControl,
  ProductCategoryControl,
  PreviousProviderControl,
  PaymentControl,
  DigitalSignatureControl,
  AddressControl,
  mapToAddressOptions,
  UploadPanelControl,
  SummaryControlRenderer,
  PdfSummaryControlRenderer,
  MultichoiceControlRenderer,
  ShopCartControl,
  DatePickerControl,
  NumberInputControl,
  InputCalculatorControl,
  isInputCalculatorControlData,
  AvailabilityCheckControl,
  ImageControl,
  GroupLayout,
  MeterReadingControl,
  MeterReadingDynamicControl,
  JourneyLauncherControl,
  SecondaryActionBarControl,
  EntityAttributeControl,
  EntityLookupControl,
  PvRoofPlannerControl,
  MaterialSymbol,
  isECP
}

export type {
  MainContentCartZones,
  EntityLookupControlOptions,
  EntityAttributeControlOptions,
  JourneyLauncherControlOptions,
  DynamicMeterReadingFormValues,
  MeterReadingControlDataMeters,
  MeterReadingFormValues,
  MeterReadingControlData,
  InputCalculatorControlData,
  PreviousProviderControlOptions
}

// exporting types and the other needed utils
export * from './utils'
export { FACTOR_DICTIONARY } from './renderers/controls/InputCalculatorControl/utils'
export * from './renderers/controls/ActionBarControl/types'
export { MultichoiceControl } from './renderers/controls/MultichoiceControl'

export type { MultichoiceControlComponentProps } from './renderers/controls/MultichoiceControl/types'

export { formatDeviceConsumptionStr } from './renderers/controls/SummaryControl'

// exporting the controls
export const renderers: (JsonFormsRendererRegistryEntry & {
  featureFlag?: boolean
})[] = [
  // controls
  { tester: TextFieldTester, renderer: TextFieldControl },
  { tester: BooleanTester, renderer: BooleanCheckboxControl },
  { tester: EnumButtonControlTester, renderer: EnumButtonControl },
  { tester: EnumImageButtonControlTester, renderer: EnumImageButtonControl },
  { tester: SelectTester, renderer: EnumSelectControl },
  { tester: EnumToggleTester, renderer: EnumToggleControl },
  { tester: EnumAutocompleteTester, renderer: EnumAutocompleteControl },
  { tester: RadioTester, renderer: EnumRadioControl },
  { tester: PaymentControlTester, renderer: PaymentControl },
  { tester: AddressControlTester, renderer: AddressControl },
  { tester: UploadPanelControlTester, renderer: UploadPanelControl },
  { tester: ProductSelectionControlTester, renderer: ProductSelectionControl },
  { tester: ProductCategoryControlTester, renderer: ProductCategoryControl },
  { tester: DigitalSignatureControlTester, renderer: DigitalSignatureControl },
  { tester: PreviousProviderControlTester, renderer: PreviousProviderControl },
  {
    tester: SummaryControlTester,
    renderer: SummaryControlRenderer
  },
  {
    tester: PdfSummaryControlTester,
    renderer: PdfSummaryControlRenderer
  },
  {
    tester: ConfirmationMessageControlTester,
    renderer: ConfirmationMessageControl
  },
  {
    tester: ActionBarControlTester,
    renderer: ActionBarControl
  },
  {
    tester: SecondaryActionBarControlTester,
    renderer: SecondaryActionBarControl
  },
  {
    tester: ConsentsControlTester,
    renderer: ConsentsControl
  },
  {
    tester: CustomBlockControlTester,
    renderer: CustomBlockControl
  },
  {
    tester: MeterReadingControlTester,
    renderer: MeterReadingControl
  },
  {
    tester: MeterReadingDynamicControlTester,
    renderer: MeterReadingDynamicControl
  },
  {
    tester: ShopCartControlTester,
    renderer: ShopCartControl
  },
  {
    tester: DatePickerControlTester,
    renderer: DatePickerControl
  },
  {
    tester: NumberInputControlTester,
    renderer: NumberInputControl
  },
  {
    tester: InputCalculatorControlTester,
    renderer: InputCalculatorControl
  },
  {
    tester: AvailabilityCheckControlTester,
    renderer: AvailabilityCheckControl
  },
  {
    tester: MultichoiceControlTester,
    renderer: MultichoiceControlRenderer
  },
  {
    tester: JourneyLauncherControlTester,
    renderer: JourneyLauncherControl
  },
  {
    tester: EntityAttributeControlTester,
    renderer: EntityAttributeControl
  },
  {
    tester: EntityLookupControlTester,
    renderer: EntityLookupControl
  },

  // layouts
  { tester: StackSpacingLayoutTester, renderer: StackSpacingLayout },
  { tester: MainContentCartLayoutTester, renderer: MainContentCartLayout },
  { tester: MainLinearLayoutTester, renderer: MainLinearLayout },
  { tester: GroupLayoutTester, renderer: GroupLayout },

  // display
  { tester: LabelRendererTester, renderer: LabelRenderer },
  {
    tester: ContactControlTester,
    renderer: ContactControl
  },
  { tester: ImageControlTester, renderer: ImageControl },
  { tester: PvRoofPlannerControlTester, renderer: PvRoofPlannerControl }
]
