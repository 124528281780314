import type { InputCalculatorDevice } from '../InputCalculatorControl/types'

export function formatDeviceConsumptionStr(
  device: InputCalculatorDevice,
  unitLabel: string
): string {
  const name = device.otherName
    ? `${device.name} (${device.otherName})`
    : device.name

  return `${name}: ${device.quantity} x ${formatConsumptionToLocaleString(
    device.unitaryConsumption
  )} ${unitLabel} = ${formatConsumptionToLocaleString(
    device.consumption
  )} ${unitLabel}`
}

export const formatConsumptionToLocaleString = (
  num: number | string | undefined,
  decimalPlaces = 10
) => {
  const parsedNumber = Number(num)

  if (isNaN(parsedNumber)) return num

  return parsedNumber.toLocaleString(navigator.language || 'de-DE', {
    maximumFractionDigits: decimalPlaces
  })
}
