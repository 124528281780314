import { makeStyles } from '@epilot/journey-elements'

export const useSecondaryActionButtonStyles = makeStyles({
  icon: {
    height: '24px',
    width: '24px',
    userSelect: 'none',
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    whiteSpace: 'normal'
  }
})
