import type { ComponentType } from 'react'

import { TileLayoutContainer } from '../../TileLayoutContainer'
import type { ComponentMappingDataGraduatedProps } from '../../types'
import { ProductPricesComponents } from '../TileBody/ProductPricesComponents'

import classes from './TileSections.module.scss'

type TilePriceComponentsProps = {
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
}

export const TilePriceComponents = ({
  ComponentMappingDataGraduated
}: TilePriceComponentsProps) => {
  return (
    <TileLayoutContainer
      className={classes.priceComponentsContainer}
      tileLayoutSection="price-components"
    >
      <ProductPricesComponents
        ComponentMappingDataGraduated={ComponentMappingDataGraduated}
      />
    </TileLayoutContainer>
  )
}
