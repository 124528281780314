import type { JourneyPrice } from '../types'

export type GetJourneyPricesToQueryOptions = {
  journeyPrices: JourneyPrice[]
  availableProductIds?: string[]
}

export const getJourneyPricesToQuery = ({
  journeyPrices,
  availableProductIds
}: GetJourneyPricesToQueryOptions): JourneyPrice[] => {
  if (!availableProductIds) {
    return journeyPrices
  }

  if (!availableProductIds.length) {
    return []
  }

  return journeyPrices.filter(({ productId }) =>
    availableProductIds.some(
      (availableProductId) => availableProductId === productId
    )
  )
}
