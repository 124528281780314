import { CircularProgress } from '@epilot/journey-elements'
import { Loader } from '@googlemaps/js-api-loader'
import { useMediaQuery } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'

import {
  useConfig,
  useJourneyContext,
  assignNewMarker,
  getCoordinatesFromAddress,
  getGoogleMapsApiKey
} from '../../../../utils'
import type { NumberInputFormValues } from '../../NumberInputControl/types'
import type {
  AvailabilityBlockData,
  PvRoofPlannerControlData,
  SolarPotential
} from '../types'
import {
  MAP_SETTINGS,
  getClosestBuildingInsights,
  getHouseAddress,
  getSolarPanelsUserCount
} from '../utils'

import { NoDataWidget } from './NoDataWidget'
import { RooftopDataWidget } from './RooftopDataWidget'
import { SolarPanelsDataWidget } from './SolarPanelsDataWidget'
import { useMapStyles } from './styles'

export const Map = ({
  relatedNumberBlock,
  relatedAvailabilityBlock,
  panelLifetimeYears,
  path,
  handleChange,
  id
}: {
  relatedNumberBlock: string | undefined
  relatedAvailabilityBlock: string | undefined
  panelLifetimeYears: number | undefined
  path: string
  handleChange(path: string, value: PvRoofPlannerControlData): void
  id: string
}) => {
  const classes = useMapStyles()
  const { context } = useJourneyContext()
  const isMobile = useMediaQuery('(max-width: 750px)')
  const { GOOGLE_MAPS_API_URL } = useConfig()

  const mapRef = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [isMapLoading, setIsMapLoading] = useState(true)
  const pinRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)

  const [solarPotential, setSolarPotential] = useState<
    undefined | SolarPotential | null
  >(null)

  const [coordinates, setCoordinates] = useState<
    undefined | google.maps.LatLng | google.maps.LatLngLiteral
  >()

  const [solarPanelsUserCount, setSolarPanelsUserCount] = useState<
    undefined | number
  >()

  const relatedNumberBlockData = context._stepsHistoryStateObject?.[
    relatedNumberBlock ?? ''
  ] as NumberInputFormValues

  const userYearlyConsumption = relatedNumberBlockData?.numberInput
  const maxYearlyConsumption =
    solarPotential?.solarPanelConfigs?.[
      solarPotential?.solarPanelConfigs.length - 1
    ].yearlyEnergyDcKwh

  const relatedAvailabilityBlockData = context._stepsHistoryStateObject?.[
    relatedAvailabilityBlock ?? ''
  ] as AvailabilityBlockData

  const address = getHouseAddress(relatedAvailabilityBlockData)
  const publicToken = context.journey.settings?.publicToken as string

  useEffect(() => {
    getGoogleMapsApiKey(GOOGLE_MAPS_API_URL, publicToken)
      .then((key: string) => {
        const loader = new Loader({
          apiKey: key,
          version: 'weekly'
        })

        loader.importLibrary('maps').then(() => {
          if (mapRef.current) {
            const newMap = new google.maps.Map(mapRef.current, MAP_SETTINGS)

            setMap(newMap)
          }
        })
        setIsMapLoading(false)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Google Maps API error: ${error}`)
        setIsMapLoading(false)
      })
  }, [GOOGLE_MAPS_API_URL, publicToken])

  useEffect(() => {
    if (map && address) {
      getCoordinatesFromAddress(GOOGLE_MAPS_API_URL, address, publicToken)
        .then((location) => {
          if (location !== undefined) {
            setCoordinates(location)

            assignNewMarker({ pinRef, coordinates: location, map })

            map.setCenter(location)
            map.setZoom(19)
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Fetching address coordinates error: ${error}`)
          setCoordinates(undefined)
        })
    }
  }, [map, address, isMapLoading, GOOGLE_MAPS_API_URL, publicToken])

  useEffect(() => {
    if (coordinates) {
      getClosestBuildingInsights(GOOGLE_MAPS_API_URL, coordinates, publicToken)
        .then((solarPotential) => {
          setSolarPotential(solarPotential)
          if (
            solarPotential !== undefined &&
            solarPotential.solarPanelConfigs
          ) {
            const solarPanelsUserCount = userYearlyConsumption
              ? getSolarPanelsUserCount({
                  solarPanelConfigs: solarPotential.solarPanelConfigs,
                  yearlyConsumption: userYearlyConsumption
                })
              : undefined

            setSolarPanelsUserCount(solarPanelsUserCount)
            handleChange(path, {
              solarPanelsUserCount,
              maxSunshineHoursPerYear: solarPotential?.maxSunshineHoursPerYear
                ? parseFloat(
                    solarPotential?.maxSunshineHoursPerYear?.toFixed(0)
                  )
                : undefined,
              maxArrayAreaMeters2: solarPotential?.maxArrayAreaMeters2
                ? parseFloat(solarPotential?.maxArrayAreaMeters2.toFixed(2))
                : undefined,
              panelLifetimeYears: solarPotential?.panelLifetimeYears,
              coordinates: `${coordinates.lat}, ${coordinates.lng}`
            })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Fetching solar api data error: ${error}`)
          setSolarPotential(undefined)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, GOOGLE_MAPS_API_URL])

  return (
    <>
      {isMapLoading ? (
        <CircularProgress size={50} />
      ) : (
        <div className={isMobile ? classes.containerMobile : classes.container}>
          <div
            className={isMobile ? classes.mapMobile : classes.map}
            data-testid="google-maps-test-id"
            id={id}
            ref={mapRef}
          />
          <div
            className={
              isMobile
                ? classes.widgetsContainerMobile
                : classes.widgetsContainerDesktop
            }
          >
            {solarPotential === undefined ? (
              <NoDataWidget />
            ) : (
              <>
                <RooftopDataWidget solarPotential={solarPotential} />
                {relatedNumberBlock && (
                  <SolarPanelsDataWidget
                    maxYearlyConsumption={maxYearlyConsumption}
                    panelLifetimeYears={
                      panelLifetimeYears || solarPotential?.panelLifetimeYears
                    }
                    solarPanelUserCount={solarPanelsUserCount}
                    userYearlyConsumption={userYearlyConsumption}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}
