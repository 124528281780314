import type { JourneyAnalyticsPlugin } from './types'

export const addAnalyticsEventListeners = (
  analytics: JourneyAnalyticsPlugin
) => {
  window.addEventListener('beforeunload', analytics?.endSession)
}

export const removeAnalyticsEventListeners = (
  analytics: JourneyAnalyticsPlugin
) => {
  window.removeEventListener('beforeunload', analytics?.endSession)
}
