import type { Feature } from '@epilot/journey-logic-commons'

import {
  Typography,
  List,
  ListItem,
  ListItemAdornment,
  ListItemContent,
  Icon
} from '../../../../components'
import { useTileContext } from '../../utils/tile-context-utils'

type ProductFeatureProps = { feature: Feature & { showIcon: boolean } }

export const ProductFeatures = () => {
  const { product, config, actions } = useTileContext()

  const { showFeaturesIcon: showIcon } = config.generalTileConfiguration
  const title = config.content.features?.title
    ? actions.t(config.content.features.title)
    : ' '
  const featuresAmountLimit =
    config.generalTileConfiguration.featuresAmountLimit ??
    product.feature?.length
  const features = product.feature?.slice(0, featuresAmountLimit)
  const shouldRenderTitle = (features?.length || 0) > 0
  const showFeaturesSection = config.showFeaturesSection

  if (!showFeaturesSection) {
    return null
  }

  return (
    <>
      {shouldRenderTitle && <Typography as="p">{title}</Typography>}
      <List>
        {features?.map((feature, index) => (
          <ProductFeature
            feature={{
              ...feature,
              showIcon
            }}
            key={index}
          />
        ))}
      </List>
    </>
  )
}

const ProductFeature = ({ feature }: ProductFeatureProps) => {
  return (
    <ListItem>
      <ListItemAdornment>
        {feature.showIcon && (
          <Icon
            color="var(concorde-primary-color)"
            name="check"
            size="medium"
          />
        )}
      </ListItemAdornment>
      <ListItemContent>
        <Typography>{feature.feature}</Typography>
      </ListItemContent>
    </ListItem>
  )
}
