import type { Price, PriceItem } from '@epilot/journey-logic-commons'
import { getDisplayPrice } from '@epilot/journey-logic-commons'
import { formatPriceUnit } from '@epilot/pricing'
import type { TFunction } from 'i18next'
import { useMemo } from 'react'

import { getFormattedQuantity } from '../../../utils/products/quantity-utils'

export const ComponentMappingDataGetAg = ({
  item,
  showTrailingDecimalZeros,
  t
}: {
  item: PriceItem
  showTrailingDecimalZeros: boolean
  t: TFunction
}) => {
  const formattedQuantity = getFormattedQuantity(item._price as Price, t)
  const formattedPriceUnit = formatPriceUnit(item._price?.unit)

  const displayUnitAmount = useMemo(() => {
    return getDisplayPrice(
      t,
      item?.unit_amount_gross_decimal,
      item._price?.price_display_in_journeys,
      showTrailingDecimalZeros,
      item.currency
    )
  }, [
    item._price?.price_display_in_journeys,
    item.currency,
    item?.unit_amount_gross_decimal,
    showTrailingDecimalZeros,
    t
  ])

  const isWorkPrice = item._price?.get_ag?.type === 'work_price'

  return (
    <>
      {`${isWorkPrice && formattedQuantity ? `${formattedQuantity} | ` : ''}
      ${displayUnitAmount}${
        isWorkPrice ? `/${t(formattedPriceUnit, formattedPriceUnit)}` : ''
      }`}
    </>
  )
}
