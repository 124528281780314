import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  SmallText
} from '@epilot/journey-elements'
import type { EpilotTheme } from '@epilot/journey-elements'
import type { RecurrenceAmount } from '@epilot/journey-logic-commons'
import type { PricingDetails } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import type { PriceItemWithBlockConfiguration } from '../../types'
import { CartTotals } from '../CartTotals'

import { CartItem } from './CartItem'

export type CartPricingDetails = {
  items: PriceItemWithBlockConfiguration[]
  amount_subtotal: PricingDetails['amount_subtotal']
  amount_total: PricingDetails['amount_total']
  total_details: PricingDetails['total_details']
}

type CartBodyProps = {
  items: PriceItemWithBlockConfiguration[]
  t: TFunction
  displayPriceComponents?: boolean
  displayUnitaryAverage?: boolean
  showTrailingDecimalZeros?: boolean
  forceExpandedItemDetails?: boolean
  sortedAggregatedPrices: RecurrenceAmount[]
  currency?: string
}

const useStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    cartContent: {
      padding: '20px 0',
      gap: '25px'
    },
    cartEmptyItem: {
      background: theme?.palette?.grey[20],
      borderRadius: '4px',
      padding: '2rem 1.2rem',
      textAlign: 'center'
    },
    divider: {
      marginBottom: '1rem'
    }
  })
)

export const CartBody = ({
  items,
  t,
  sortedAggregatedPrices,
  currency,
  showTrailingDecimalZeros,
  ...itemProps
}: CartBodyProps) => {
  const classes = useStyles()

  if (!items.length) {
    return (
      <Grid item xs={12}>
        <div className={classes.cartEmptyItem}>
          <SmallText color="textSecondary" display="inline">
            {t('empty_cart', 'Your cart is empty. Grab some products!')}
          </SmallText>
        </div>
      </Grid>
    )
  }

  return (
    <>
      <Grid className={classes.cartContent} container spacing={0}>
        {items.map((item, index) => (
          <CartItem
            item={item}
            key={`${item.product_id}:${item.price_id}:${index}`}
            showTrailingDecimalZeros={showTrailingDecimalZeros}
            t={t}
            {...itemProps}
          />
        ))}
      </Grid>
      <Divider className={classes.divider} />
      <CartTotals
        currency={currency}
        items={items}
        showTrailingDecimalZeros={showTrailingDecimalZeros}
        sortedAggregatedPrices={sortedAggregatedPrices}
        t={t}
      />
    </>
  )
}
