import type { PricingDetails } from '@epilot/pricing'
import {
  BillingPeriods,
  normalizeTimeFrequency,
  toDinero
} from '@epilot/pricing'

import type { BillingPeriod, RecurrenceAmount } from '../../utils/types'

function getEarliestRecurrence(recurringBillings: RecurrenceAmount[]) {
  for (const billingPeriod of BillingPeriods) {
    const earliestRecurrence = recurringBillings.find(
      (recurringBilling) => recurringBilling.billing_period === billingPeriod
    )

    if (earliestRecurrence) {
      return earliestRecurrence
    }
  }

  return null
}

type ComputedPriceDetails = {
  discountAmount?: number
  discountAmountDecimal?: string
  amountTotal: number
  amountTotalDecimal: string
  beforeDiscountTotalAmount?: number
  beforeDiscountTotalAmountDecimal?: string
  oneTimeTotal?: number
  oneTimeTotalDecimal?: string
  earliestRecurrence?: RecurrenceAmount | null
  computedRecurringPriceTotal?: RecurrenceAmount | null
}

const computeRecurringPriceTotal = (
  recurrence: RecurrenceAmount,
  billingPeriod: Exclude<BillingPeriod, 'one_time'>
): RecurrenceAmount => {
  const normalized = normalizeTimeFrequency(
    recurrence.amount_total_decimal,
    /**
     * @todo Handle scenario in which the billing period arrives as undefined
     */
    recurrence.billing_period!,
    billingPeriod,
    12
  )

  const normalizedAmountDecimal = toDinero(normalized.toString()).toFormat(
    '0.00'
  )
  const normalizedAmount = toDinero(normalized.toString())
    .convertPrecision(2)
    .getAmount()

  return {
    ...recurrence,
    amount_total_decimal: normalizedAmountDecimal,
    amount_total: normalizedAmount,
    billing_period: billingPeriod
  }
}

export const computePrice = ({
  pricingDetails,
  billingPeriod
}: {
  pricingDetails: PricingDetails
  billingPeriod?: Exclude<BillingPeriod, 'one_time'>
}): ComputedPriceDetails => {
  const recurrences = pricingDetails.total_details?.breakdown?.recurrences ?? []
  const firstItem = pricingDetails.items?.[0]
  const oneTimeRecurrence = recurrences.find(({ type }) => type === 'one_time')

  const oneTimeTotal = oneTimeRecurrence?.amount_total
  const oneTimeTotalDecimal = oneTimeRecurrence?.amount_total_decimal

  const amountTotal = firstItem?.amount_total || 0
  const amountTotalDecimal = firstItem?.amount_total_decimal || '0'

  const recurringBillings = recurrences.filter(
    ({ type }) => type === 'recurring'
  )

  const earliestRecurrence = getEarliestRecurrence(recurringBillings)

  const computedRecurringPriceTotal =
    earliestRecurrence && billingPeriod
      ? computeRecurringPriceTotal(earliestRecurrence, billingPeriod)
      : null

  const discountAmount = firstItem?.discount_amount
  const discountAmountDecimal = firstItem?.discount_amount_decimal

  const beforeDiscountTotalAmount = firstItem?.before_discount_amount_total
  const beforeDiscountTotalAmountDecimal =
    firstItem?.before_discount_amount_total_decimal

  return {
    discountAmount,
    discountAmountDecimal,
    amountTotal,
    amountTotalDecimal,
    beforeDiscountTotalAmount,
    beforeDiscountTotalAmountDecimal,
    oneTimeTotal,
    oneTimeTotalDecimal,
    earliestRecurrence,
    computedRecurringPriceTotal
  }
}
