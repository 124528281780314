import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductCTA } from '../TileBody/ProductCTA'

import classes from './TileSections.module.scss'

export const TileCTA = () => {
  return (
    <TileLayoutContainer
      className={classes.ctaContainer}
      tileLayoutSection="CTA"
    >
      <ProductCTA />
    </TileLayoutContainer>
  )
}
