export type GetAvailableProductIdsOptions = {
  crossSellProductIds?: string[]
  availabilityProductIds?: string[]
}

/**
 * Gets productIds of all products available to sell given the Cross Sell Filtering and Product Availability Filtering
 */
export const getAvailableProductIds = ({
  crossSellProductIds,
  availabilityProductIds
}: GetAvailableProductIdsOptions): string[] | undefined => {
  if (!crossSellProductIds && !availabilityProductIds) {
    return
  }

  let result: string[] = []

  if (crossSellProductIds && Array.isArray(crossSellProductIds)) {
    // If there are availabilityProductIds, we need to filter the crossSellProductIds
    if (availabilityProductIds && Array.isArray(availabilityProductIds)) {
      const availableCrossSellProducts = crossSellProductIds.filter(
        (productId) => availabilityProductIds.includes(productId)
      )

      return result.concat(availableCrossSellProducts)
    }

    result = result.concat(crossSellProductIds)
  }

  if (availabilityProductIds && Array.isArray(availabilityProductIds)) {
    return result.concat(availabilityProductIds)
  } else {
    return result
  }
}
