import type {
  Price,
  CompositePrice,
  Tax,
  PriceWithBlockMappings
} from '@epilot/journey-logic-commons'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

export type TaxWithTaxInclusivityBehaviour = Tax & { inclusive: boolean }

/**
 * @private Only needed for isPriceTaxATaxArray
 */
const deduplicatedTaxesWithInclusivityBehaviour = (
  taxes: TaxWithTaxInclusivityBehaviour[]
): TaxWithTaxInclusivityBehaviour[] =>
  uniqBy(taxes, (tax) => `${tax._id}${String(tax.inclusive)}`)

/**
 * @private Only needed for isPriceTaxATaxArray
 */
const isPriceTaxATaxArray = (tax: Price['tax']): tax is Tax[] =>
  !!tax && !('$relation' in tax)

const isCompositePrice = (
  price: Price | CompositePrice
): price is CompositePrice => !!price.is_composite_price

export const extractPriceTaxes = (
  price: PriceWithBlockMappings
): TaxWithTaxInclusivityBehaviour[] => {
  let taxes: TaxWithTaxInclusivityBehaviour[]

  if (isCompositePrice(price)) {
    taxes = ((price.price_components ?? []) as Price[])
      .map(extractTaxesFromPrice)
      .flatMap((taxes) => taxes)
      .filter((tax) => tax)
  } else {
    taxes = extractTaxesFromPrice(price)
  }

  const deduplicatedTaxes = deduplicatedTaxesWithInclusivityBehaviour(taxes)
  const orderedTaxes = sortBy(deduplicatedTaxes, (tax) => tax.rate)

  return orderedTaxes
}

const extractTaxesFromPrice = (
  price: Price
): TaxWithTaxInclusivityBehaviour[] => {
  const taxes = price.tax ?? []

  if (!isPriceTaxATaxArray(taxes)) {
    return []
  }

  return taxes.map<TaxWithTaxInclusivityBehaviour>((tax) => ({
    ...tax,
    inclusive: !!price.is_tax_inclusive
  }))
}
