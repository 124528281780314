import type { CheckboxProps } from '../'
import {
  List,
  ListItem,
  ListSubheader,
  ListItemPrefix,
  Icon,
  ListItemContent
} from '../'

import { EnumAutocompleteControl } from './EnumAutocompleteControl'
import { EnumRadioControl } from './EnumRadioControl'
import { EnumSelectControl } from './EnumSelectControl'
import { EnumToggleButtonsControl } from './EnumToggleButtonsControl'
import type { EpilotCommonControlProps } from './types'

export type EnumUiTypes =
  | 'radio'
  | 'toggle'
  | 'autocompelete'
  | 'select'
  | 'tabs'
  | 'switcher'
export type EnumControlProps = EpilotCommonControlProps & {
  /**
   * the type of the displayed enum
   * @default 'select'
   */
  uiType: EnumUiTypes

  /**
   * values used by the switcher
   */
  switcherFrom?: EnumUiTypes
  switcherTo?: EnumUiTypes
  /**
   * @default 3
   */
  switcherThreshold?: number

  /**
   * the options to be used in the enum
   * @default []
   */
  options: any[]

  /**
   * the labels of the options, if it was passed empty, the options themselfs will be used
   */
  optionsLabels?: any[]

  /**
   * color of the inputs
   * @default "primary"
   */
  color?: 'primary' | 'secondary' | 'default'

  variant?: 'filled' | 'standard' | 'outlined' | undefined

  /**
   * size of the control
   */
  size?: CheckboxProps['size']
  /**
   * default selection value
   */
  defaultSelection?: string
  /**
   * whether the components should stretch or not
   * @default false
   */
  stretch?: boolean

  /**
   * allow overwriting the default background color for TextField, Select, AutoComplete
   */
  customBackgroundColor?: string

  /**
   * If set, removes the first, empty option
   */
  removeEmptyOption?: boolean

  /**
   * no options text, used in the autocomplete deopdown
   */
  noOptionsText?: string

  /**
   * id of the field
   */
  id?: string
}

export function EnumControl(props: EnumControlProps) {
  const { displayMode = 'input' } = props

  if (displayMode === 'display') {
    return renderDisplayMode(props)
  }
  const Component = getComponentByUiType(props.uiType)

  return <Component {...props} />
}

function getComponentByUiType(ui?: EnumUiTypes) {
  switch (ui) {
    case 'autocompelete':
      return renderAutoCompelete
    case 'radio':
      return renderRadio
    case 'switcher':
      return renderSwitcher
    case 'toggle':
      return renderToggle
    default:
      return renderSelect
  }
}

function renderDisplayMode({
  label,
  options = [],
  optionsLabels,
  value,
  id
}: EnumControlProps) {
  const labels = optionsLabels ? optionsLabels : options

  return (
    <List id={id} subheader={<ListSubheader>{label}</ListSubheader>}>
      {options.map((item, index) => {
        return (
          <ListItem key={item}>
            <ListItemPrefix>
              {item === value ? <Icon name="send" /> : ''}
            </ListItemPrefix>
            <ListItemContent primary={labels[index]} />
          </ListItem>
        )
      })}
    </List>
  )
}

function renderSelect(props: EnumControlProps) {
  return <EnumSelectControl {...props} />
}

function renderToggle(props: EnumControlProps) {
  return <EnumToggleButtonsControl {...props} />
}

function renderRadio(props: EnumControlProps) {
  return <EnumRadioControl {...props} />
}

function renderAutoCompelete(props: EnumControlProps) {
  return <EnumAutocompleteControl {...props} />
}

function renderSwitcher({
  switcherFrom,
  switcherTo,
  switcherThreshold = 3,
  ...rest
}: EnumControlProps) {
  const FromComponent = getComponentByUiType(switcherFrom)
  const ToComponent = getComponentByUiType(switcherTo)

  if (rest.options.length > switcherThreshold) {
    return <ToComponent {...rest} />
  }

  return <FromComponent {...rest} />
}
