import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductFeatures } from '../TileBody/ProductFeatures'

import classes from './TileSections.module.scss'

export const TileFeatures = () => {
  return (
    <TileLayoutContainer
      className={classes.featuresContainer}
      tileLayoutSection="features"
    >
      <ProductFeatures />
    </TileLayoutContainer>
  )
}
