import Warning from '@epilot360/icons/react/Warning'
import { createStyles, makeStyles } from '@material-ui/core'

import { Typography, LargeText } from '../Typography'

import type { PrivateJourneyErrorProps } from './types'

const useStyles = makeStyles<undefined, PrivateJourneyErrorProps>(
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      height: (props) =>
        `calc(${props.embedMode === 'inline' ? '100%' : '100vh'} - 32px)`,
      padding: 16,
      fontFamily:
        'ProximaNova, Open Sans, Helvetica, Arial, sans-serif !important',
      color: 'rgb(0, 24, 73)',
      textAlign: 'center'
    },
    heading: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      textWrap: 'wrap',
      gap: 8,
      fontSize: 32
    },
    content: {}
  })
)

export function PrivateJourneyError(props: PrivateJourneyErrorProps) {
  const classes = useStyles(props)

  // inline styles should be ok here, this component will block all other processes
  return (
    <div className={classes.container}>
      <Typography className={classes.heading} variant="h4">
        <Warning size={48} />
        {props.i18n?.heading ??
          "Oops! We can't find the page you're looking for."}
      </Typography>
      <LargeText className={classes.content}>
        {props.i18n?.content ??
          'Looks like you have followed a broken link or entered a URL that does not exist on this site'}
      </LargeText>
    </div>
  )
}
