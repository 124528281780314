import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      cursor: 'pointer',
      alignItems: 'center',
      minWidth: '200px',
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%'
      },
      [theme.breakpoints.up('xs')]: {
        flexBasis: '45%'
      },
      [theme.breakpoints.up('md')]: {
        flexBasis: '30%'
      },
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px',
      border: `1px solid ${
        theme.palette.primary.main.includes('#')
          ? theme.palette.primary.main.substring(0, 7) + '80'
          : theme.palette.primary.main.split(')')[0] + ',0.5)'
      }`,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '& span:first-of-type': {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    unsetFlexBasis: {
      [theme.breakpoints.up('md')]: {
        flexBasis: 'unset'
      }
    },
    buttonActive: {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`
      }
    },
    buttonIcon: {
      minWidth: '0px',
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%'
      },
      [theme.breakpoints.up('xs')]: {
        flexBasis: '30%'
      }
    },
    infographicContainer: {
      padding: '0 !important',
      aspectRatio: '3/2',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    iconContainer: {
      aspectRatio: '1/1',
      width: '116px',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      padding: '0 !important',
      marginBottom: '10px'
    },
    cardContent: {
      padding: '5px',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    infographicImage: {
      height: 'auto',
      width: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain'
    },
    iconImage: {
      width: '116px',
      height: '116px',
      objectFit: 'cover'
    }
  })
)
