import type { EnumControlProps } from '@epilot/journey-elements'
import { EnumControl, Hidden } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { stringToHTML, useStepBlockId } from '../../utils'
import { getEnumOptions } from '../../utils/helper'
import { includeCommon } from '../../utils/includeCommon'

export type EnumRadioControlOptions = EnumControlProps & {
  clearPathOnChange?: string[]
  optionsValue?: string[]
}

export function EnumRadioControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible,
  id
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path, id)
  const { errorMessage, examples, default: defaultSelect } = schema
  const options = getEnumOptions(schema, uischema)
  const { t } = useTranslation()
  const label = uischema?.options?.label || uischema?.label
  const {
    optionsLabels = options,
    clearPathOnChange = [],
    optionsValue
  } = (uischema.options as EnumRadioControlOptions) || {}

  const [selected, setSelected] = useState(data)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      // skip the effect on initial mount to prevent setting the value to schema.default when the journey goes back
      isInitialMount.current = false
    } else {
      setSelected(defaultSelect)
    }
  }, [defaultSelect])

  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
        setSelected(undefined)
      })
    }
    handleChange(path, value)
    setSelected(value)
  }

  const newLabels = optionsLabels.map((item) =>
    stringToHTML(item, {
      isPlainText: true // for now the enum radio control is only used for plain text options
    })
  )

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        defaultSelection={''}
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : t('Please select one of the options')
            : ''
        }
        id={stepBlockId}
        label={typeof label === 'string' ? label.trim() : ''}
        onChange={handleOnChange}
        options={optionsValue ? optionsValue : options}
        optionsLabels={newLabels}
        placeholder={placeholder}
        required={required}
        uiType="radio"
        value={selected || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EnumRadioControlPure }) as never
)
