import { clsx, makeStyles } from '@epilot/journey-elements'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'

type DottedLineProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>

export const DottedLine = ({ className, ...props }: DottedLineProps) => {
  const classes = useDottedLineStyles()

  return (
    <span
      aria-hidden
      className={clsx(classes.dottedLine, className)}
      {...props}
    />
  )
}

const useDottedLineStyles = makeStyles({
  dottedLine: {
    display: 'inline-flex',
    marginTop: -1,
    borderStyle: 'dotted',
    borderColor: 'inherit',
    borderWidth: 0,
    borderBottomWidth: 1,
    flex: 1,
    height: 16,
    margin: '0 8px'
  }
})
