import type { Theme } from '@epilot/journey-elements'
import { makeStyles, createStyles } from '@epilot/journey-elements'

export const useEnumButtonStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      transition: 'none',
      height: 'auto',
      minHeight: '48px',
      minWidth: '200px',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'unset',
      padding: '0 8px',
      '&.Mui-focusVisible': {
        border: `2px solid ${theme.palette.primary.main}`
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: 'initial'
      },
      '& > span': {
        display: 'initial',
        width: '100%'
      },
      '& span:first-of-type': {
        display: 'flex'
      }
    },
    buttonActive: {
      border: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`
      }
    },
    buttonWrap: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '12px',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flex: 1,
        gap: '12px',
        flexDirection: 'column',
        flexWrap: 'nowrap'
      }
    },
    buttonImageWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      gap: '12px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    noWrapImageContainer: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    dummyDiv: {
      flexGrow: 1,
      minWidth: '200px',
      [theme.breakpoints.up('xs')]: {
        flexBasis: '45%'
      },
      [theme.breakpoints.up('md')]: {
        flexBasis: '30%'
      }
    },
    containerWithoutLabel: {
      textAlign: 'right'
    },
    columnDirection: {
      flexDirection: 'column'
    }
  })
})
