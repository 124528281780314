import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import {
  createStyles,
  Divider,
  Grid,
  makeStyles
} from '@epilot/journey-elements'
import type {
  CompositePriceItem,
  PriceItem
} from '@epilot/journey-logic-commons'

import { useComputePricingDetails } from '../../../../utils'
import type { sortItemRecurrenceDetails } from '../../../../utils/products/sortItemsRecurrenceDetails'
import {
  ProductDetailsPriceInformationItemComponent,
  ProductDetailsPriceInformationItemList,
  ProductDetailsPriceInformationTotals
} from '../../../Product'

type Recurrence = ReturnType<typeof sortItemRecurrenceDetails>[0]

type CartItemDetailsProps = {
  item: PriceItem | CompositePriceItem
  isExpanded: boolean
  componentDetails: Recurrence[]
  displayPriceComponents?: boolean
  displayUnitaryAverage?: boolean
  showTrailingDecimalZeros?: boolean
  hasOnlyOnetimeRecurrences?: boolean
}

const useStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    cartComponents: {
      background: theme?.palette?.grey[20],
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      padding: '0px 12px 10px',
      marginTop: '-22px',
      display: 'flex'
    },
    divider: {
      backgroundColor: theme.palette?.text.secondary,
      width: '100%',
      margin: '8px auto',
      opacity: '0.3'
    },
    cartComponentItem: {
      padding: 0
    },
    totals: {
      width: '100%',
      marginTop: theme.spacing(2)
    }
  })
)

export const CartItemDetails = ({
  item,
  isExpanded,
  componentDetails,
  showTrailingDecimalZeros,
  displayUnitaryAverage,
  hasOnlyOnetimeRecurrences
}: CartItemDetailsProps) => {
  const classes = useStyles()
  const { pricingDetails } = useComputePricingDetails({ price: item })

  if (!isExpanded) {
    return null
  }

  const itemComponents =
    (item.is_composite_price && (item as CompositePriceItem).item_components) ||
    []

  return (
    <Grid
      className={classes.cartComponents}
      container
      data-testid={`cart-item-components-${item._product?._id}:${item._price?._id}`}
      item
      spacing={2}
    >
      <Divider className={classes.divider} />
      <ProductDetailsPriceInformationItemList>
        {itemComponents.map((component) => (
          <ProductDetailsPriceInformationItemComponent
            component={component}
            displayUnitaryAverage={displayUnitaryAverage}
            key={`${item.product_id}:${item.price_id}:${component._price?._id}`}
            shouldHideRecurrence={
              hasOnlyOnetimeRecurrences &&
              (component._price?.type === 'one_time' || !component._price?.type)
            }
            showTrailingDecimalZeros={showTrailingDecimalZeros}
          />
        ))}
        {!itemComponents.length && (
          <ProductDetailsPriceInformationItemComponent
            component={item as PriceItem}
            displayUnitaryAverage={displayUnitaryAverage}
            shouldHideRecurrence={
              hasOnlyOnetimeRecurrences &&
              ((item as PriceItem)?.type === 'one_time' ||
                !(item as PriceItem)?.type)
            }
            showTrailingDecimalZeros={showTrailingDecimalZeros}
          />
        )}
      </ProductDetailsPriceInformationItemList>
      <ProductDetailsPriceInformationTotals
        className={classes.totals}
        item={item}
        pricingDetails={pricingDetails}
        quantity={item.quantity ?? 1}
        recurrenceDetails={componentDetails}
        showTrailingDecimalZeros={showTrailingDecimalZeros}
      />
    </Grid>
  )
}
