import type { BrowserDetails } from './types'

export const fetchBrowserDetails = async (): Promise<BrowserDetails> => {
  const userAgent = navigator.userAgent
  const deviceType = /Mobi|Android/i.test(userAgent) ? 'Mobile' : 'Desktop'

  const osMap = [
    { name: 'Windows', pattern: /Win/i },
    { name: 'MacOS', pattern: /Mac/i },
    { name: 'Linux', pattern: /Linux/i },
    { name: 'Android', pattern: /Android/i },
    { name: 'iOS', pattern: /like Mac/i }
  ]

  const osType =
    osMap.find((os) => os.pattern.test(userAgent))?.name || 'Unknown OS'
  const browserTypeAndVersion = userAgent
  const screenResolution = `${screen.width}x${screen.height}`
  const viewportSize = `${window.innerWidth}x${window.innerHeight}`
  const colorDepth = screen.colorDepth
  const languagePreference = navigator.language

  // TODO: Implement IP address fetching
  const ip = ''

  return {
    deviceType,
    osType,
    browserTypeAndVersion,
    screenResolution,
    viewportSize,
    colorDepth,
    languagePreference,
    ip
  }
}
