/**
 * Mimicks behaviour of 'clsx' package without the bloat (https://github.com/lukeed/clsx)
 * Expects a list of classes as arguments but gracefully handles boolean/nullish values,
 * and concatenates all classes into a single string which can be passed to `className`.
 * @param args - list of classes, possibly with boolean/nullish values
 * @returns single `className` string
 */
export const clsx = (
  ...args: (string | boolean | undefined | null)[]
): string => args.filter(Boolean).join(' ')
