import type { Client as PricingClient } from '@epilot/pricing-client'
import * as PricingSDK from '@epilot/pricing-client'

let client: PricingClient

export type ProductAvailabilityUserFiltersValue = {
  zipCode: string
  city: string
  streetName: string
  streetNumber: string
}

export type CheckAvailability = (
  productIds: string[],
  userFiltersValue?: ProductAvailabilityUserFiltersValue
) => Promise<string[]>

export function initClient(
  serviceUrl: string,
  orgId: string,
  publicToken: string
) {
  client = PricingSDK.getClient()
  client.defaults.baseURL = serviceUrl
  client.defaults.headers['X-Ivy-Org-ID'] = orgId
  client.defaults.headers['Authorization'] = `Bearer ${publicToken}`
}

/**
 * Check product availability for a set of product Ids.
 *
 * @param organizationId The organization id.
 * @param productIds The product ids to check.
 * @param liveValue The live value (filters) to perform a product availability check.
 * @returns The set of product ids available, given the provided filters.
 */
export const productAvailabilityCheck: CheckAvailability = async (
  productIds,
  userFiltersValue
) => {
  if (!client) {
    throw Error('PricingClient has not been initialized!')
  }

  if (!productIds) {
    return []
  }

  // TODO - We should infer from live value the avail. dimension: Location, consumption, avail. date.
  // Currently HardCoded to location
  const location = {
    postal_code: userFiltersValue?.zipCode || '%',
    city: userFiltersValue?.city || '%',
    street: userFiltersValue?.streetName || '%',
    street_number: userFiltersValue?.streetNumber || '%'
  }

  const { data } = await client.$availabilityCheck(undefined, {
    products: productIds,
    filters: {
      location
    }
  })

  return data.available_products
}
