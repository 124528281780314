import { BodyText, Grid, SmallText } from '@epilot/journey-elements'
import isDate from 'lodash/isDate'

import { toDateString } from '../../../../utils/helper'
export type AttributeItemProps = {
  label: string
  value?: string
  slug?: string
  displayMode?: 'simple' | 'table'
}

export const SimpleAttributeItem = ({
  label,
  value,
  displayMode
}: AttributeItemProps) =>
  displayMode === 'simple' ? (
    <BodyText>
      {label}: {value}
    </BodyText>
  ) : (
    <Grid item sm={3} xs={12}>
      <span>
        <SmallText>{(label || '').toUpperCase()}</SmallText>
      </span>
      <span>
        <BodyText>
          {value ? (isDate(value) ? toDateString(value) : value) : '-'}
        </BodyText>
      </span>
    </Grid>
  )
