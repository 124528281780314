import { BooleanControl, Grid } from '@epilot/journey-elements'
import type { ConsentBox } from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { mdToHTML } from '../../../utils'

import type { Consents } from './types'
import { getConsentsMap } from './utils'

type ConsentBlocksProps = {
  error?: string
  consents: ConsentBox[]
  data: Consents | undefined
  onChange: (data: Consents, consent: string) => void
  id: string
}

const ConsentBlocks = ({
  consents,
  data,
  error,
  onChange,
  id
}: ConsentBlocksProps) => {
  const { t } = useTranslation()
  const dataMap = getConsentsMap(data)
  const handleChange = (value: boolean, consent: string) => {
    onChange({ ...data, [consent]: value }, consent)
  }

  return (
    <>
      {consents.map(
        (consent) =>
          consent.isVisible && (
            <Grid item key={consent.name} xs={12}>
              <BooleanControl
                errorMessage={
                  error && consent.isRequired && !dataMap.get(consent.name)
                    ? t('field_required')
                    : undefined
                }
                id={appendStepBlockId(id, `CTA Consent ${consent.name}`)}
                label={mdToHTML(consent.text)}
                onChange={(v) => handleChange(v, consent.name)}
                required={consent.isRequired}
                uiType="checkbox"
                value={dataMap.get(consent.name)}
              />
            </Grid>
          )
      )}
    </>
  )
}

export default ConsentBlocks
