import { useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'

import { useJourney } from '../../../components/JourneyLauncherTabs/useJourney'
import { useConfig } from '../../../utils'
import {
  REACT_QUERY_JOURNEY_KEY,
  getJourney
} from '../../../utils/journeyLauncherUtils'

export const useLoadFirstJourneyAndPreloadFollowing = (
  journeyIds: string[]
) => {
  const [firstJourneyId] = journeyIds
  const queryClient = useQueryClient()
  const { data } = useJourney(firstJourneyId)
  const { JOURNEYS_API_BASE_URL } = useConfig()

  const prefetchRemainingJourneys = useCallback(
    (ids: string[]) =>
      Promise.all(
        ids.map((id) =>
          queryClient.prefetchQuery([REACT_QUERY_JOURNEY_KEY, id], () =>
            getJourney(id, JOURNEYS_API_BASE_URL)
          )
        )
      ),
    [queryClient, JOURNEYS_API_BASE_URL]
  )

  useEffect(() => {
    /* If the first journey has been loaded, trigger loading the remaining journeys */
    if (data) {
      /**
       * Destructuring here to ensure the dependencies array does not change on every render
       */
      const [_, ...remainingJourneyIds] = journeyIds

      prefetchRemainingJourneys(remainingJourneyIds).catch(() => {
        console.log('Failed loading remaining journey')
      })
    }
  }, [data, prefetchRemainingJourneys, journeyIds])
}
