import {
  Box,
  Icon,
  InputAdornment,
  TextField,
  Tooltip as BaseTooltip,
  Typography
} from '@epilot/journey-elements'
import type { FieldOption } from '@epilot/journey-logic-commons'
import { useEffect, useRef, useState } from 'react'
import type {
  Control,
  Path,
  FieldValues,
  UseFormSetValue
} from 'react-hook-form'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUnitaryDemandFieldStyles } from '../styles'
import type { InputCalculatorFormValues, HydratedDeviceOption } from '../types'
import { containsFixedUnitaryDemand } from '../utils'

type UnitaryDemandAdornmentProps = {
  unit?: string
  isMobile?: boolean
  tooltip?: string
}

type UnitaryDemandFieldProps<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  path: string
  unit?: string
  index: number
  isMobile?: boolean
  consumptionOptions: HydratedDeviceOption[]
  setValue: UseFormSetValue<InputCalculatorFormValues>
  id: string
} & FieldOption

export const UnitaryDemandField = ({
  hasError,
  control,
  name,
  label,
  required,
  isMobile,
  unit,
  index,
  consumptionOptions,
  setValue,
  id
}: UnitaryDemandFieldProps<InputCalculatorFormValues>) => {
  const { t } = useTranslation()
  const classes = useUnitaryDemandFieldStyles()

  const watchedDevice = useWatch({
    control: control,
    name: `devices.${index}.name`
  })

  const factorInfo = consumptionOptions.find((op) => op.name === watchedDevice)
  const disabled = containsFixedUnitaryDemand(factorInfo?.factorMap)

  const isFirstRender = useRef(true)

  // resetting the field value if the device is changed
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setValue(name, '')
    }
  }, [watchedDevice])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <TextField
            InputLabelProps={{ htmlFor: id }}
            InputProps={{
              endAdornment: (
                <UnitaryDemandAdornment
                  isMobile={isMobile}
                  tooltip={
                    !factorInfo?.type
                      ? ''
                      : t(
                          'input_calculator.with_factor_tooltip',
                          'This device has a non-linear consumption calculation'
                        )
                  }
                  unit={unit === 'noUnit' ? '' : unit}
                />
              ),
              ...(disabled && {
                classes: {
                  disabled: classes.disabled
                }
              })
            }}
            customBackgroundColor={!disabled ? 'transparent' : undefined}
            disabled={disabled}
            error={hasError && Boolean(fieldState.error)}
            fullWidth
            helperText={
              hasError &&
              fieldState.error &&
              (fieldState.error?.message || t('field_required'))
            }
            id={id}
            inputProps={{ inputMode: 'decimal' }}
            label={label || t(name)}
            placeholder="0"
            required={required}
            type="number"
            {...field}
          />
        )
      }}
      rules={{
        required,
        validate: {
          higherThanZero: (value) => {
            if (parseFloat((value || '0') as string) <= 0) {
              return t('input_calculator.demand_unit_min_error', {
                unit: t(
                  `input_calculator.unitary_consumption_title.${
                    unit ? unit : 'noUnit'
                  }`
                )
              })
            }

            return true
          }
        }
      }}
    />
  )
}

const UnitaryDemandAdornment = ({
  unit,
  isMobile,
  tooltip
}: UnitaryDemandAdornmentProps) => {
  const classes = useUnitaryDemandFieldStyles()

  return isMobile ? (
    <InputAdornment
      className={classes.adornmentMobile}
      disableTypography
      position="end"
    >
      <Box alignItems="center" display="flex" gridGap={'2px'}>
        {unit || null}
        {tooltip ? <Tooltip tooltip={tooltip} /> : null}
      </Box>
    </InputAdornment>
  ) : (
    <InputAdornment position="end">
      <Box alignItems="center" display="flex" gridGap={'2px'}>
        {unit ? (
          <Typography className={classes.adornment}>{unit}</Typography>
        ) : null}
        {tooltip ? <Tooltip tooltip={tooltip} /> : null}
      </Box>
    </InputAdornment>
  )
}

const Tooltip = ({ tooltip }: { tooltip: string }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <BaseTooltip
      onClose={handleClose}
      open={open}
      placement="bottom"
      title={tooltip}
    >
      <span>
        <Icon
          color="primary"
          fontSize="small"
          name="info_outline"
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onTouchStart={handleOpen}
        />
      </span>
    </BaseTooltip>
  )
}
