import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles((theme) => ({
  numberInput: {
    fontWeight: 'bold',
    color: '#C5D0DB'
  },
  adornmentContainer: {
    paddingRight: 8
  },
  inputAdornment: {
    color: theme.palette.text.primary,
    margin: '0 0 0 8px'
  },
  inputContainer: {
    '& .MuiFilledInput-adornedStart': {
      paddingLeft: '0 !important'
    }
  },
  paper: {
    borderRadius:
      theme.shape?.borderRadius !== undefined
        ? `min(${theme.shape.borderRadius}px, 20px)`
        : '4px'
  }
}))
