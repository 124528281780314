import { useEffect, useState } from 'react'

import { handleMessage } from './handleMessage'
import type { JourneyEmbedOptions } from './types'

export const useMessageHandler = (id: string | undefined) => {
  const [embedOptions, setEmbedOptions] = useState<JourneyEmbedOptions | null>(
    null
  )

  useEffect(() => {
    if (id) {
      window.addEventListener(
        'message',
        handleMessage(setEmbedOptions, id),
        false
      )

      return () =>
        window.removeEventListener(
          'message',
          handleMessage(setEmbedOptions, id)
        )
    }
  }, [id])

  return embedOptions
}
