/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import type { ContactControlData } from './types'

export function isContactControlData(
  obj: any,
  _argumentName?: string
): obj is ContactControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.salutation === 'undefined' ||
        obj.salutation === null ||
        typeof obj.salutation === 'string') &&
      (typeof obj.title === 'undefined' ||
        obj.title === null ||
        typeof obj.title === 'string') &&
      (typeof obj.firstName === 'undefined' ||
        typeof obj.firstName === 'string') &&
      (typeof obj.lastName === 'undefined' ||
        typeof obj.lastName === 'string') &&
      (typeof obj.birthDate === 'undefined' ||
        obj.birthDate === null ||
        obj.birthDate instanceof Date) &&
      (typeof obj.email === 'undefined' || typeof obj.email === 'string') &&
      (typeof obj.telephone === 'undefined' ||
        typeof obj.telephone === 'string') &&
      (typeof obj.customerType === 'undefined' ||
        obj.customerType === 'Private' ||
        obj.customerType === 'Business') &&
      (typeof obj.companyName === 'undefined' ||
        typeof obj.companyName === 'string') &&
      (typeof obj.registryCourt === 'undefined' ||
        typeof obj.registryCourt === 'string') &&
      (typeof obj.registerNumber === 'undefined' ||
        typeof obj.registerNumber === 'string'))
  )
}
