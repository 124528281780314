import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

export const DEFAULT_REACT_QUERY_OPTIONS = {
  cacheTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  refetchOnWindowFocus: false,
  refetchOnMount: false
} as const

export const REACT_QUERY_JOURNEY_KEY = 'journey'

/* Given a type, ensure certain properties are not nullable  */
export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: NonNullable<Type[Property]>
}

type AxiosCallApiOptions = WithRequiredProperty<
  Omit<AxiosRequestConfig, 'data' | 'params'>,
  'method' | 'url'
> & {
  path: string
  data?: Record<string, unknown>
  params?: Record<string, unknown>
  customHeaders?: Record<string, string>
  bearerToken?: string
}

export const makeApiCall = async <T = unknown>({
  path,
  url,
  params = {},
  customHeaders = {},
  bearerToken,
  ...options
}: AxiosCallApiOptions): Promise<T> =>
  axios
    .request<T>({
      ...options,
      url: `${url}${path}`,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
        ...(bearerToken && { Authorization: `Bearer ${bearerToken}` })
      }
    })
    .then(({ data }) => data)
    .catch(({ response }) => response)

/**
 * @function getJourney
 * @param {string} journeyId
 * @returns {Promise<Value | undefined>}
 * @description Get a journey configuration by its id
 * It takes a genirc type which allows for the consuming library
 * to optionally type the response, defaults to unknown
 */
export const getJourney = async <Value = unknown>(
  journeyId: string,
  journeyApiUrl: string
): Promise<Value | undefined> => {
  const data = await makeApiCall<Value>({
    method: 'GET',
    url: journeyApiUrl,
    path: `/configuration/${journeyId}`
  })

  return data
}
