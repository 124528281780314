import type { EpilotTheme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useListLayoutStyles = makeStyles(() => {
  return createStyles({
    title: {
      whiteSpace: 'pre-wrap'
    },
    prefixItem: {
      minWidth: (props: { headMinWidth?: number }) => props.headMinWidth
    }
  })
})
interface StyleProps {
  stickyHeight: number
}

export const useMainLinearLayoutStyles = makeStyles<EpilotTheme, StyleProps>({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1
  },
  withPadding: {
    paddingBottom: (props) => `${props.stickyHeight}px`
  }
})

export const useMainContentCartLayoutStyles = makeStyles<
  EpilotTheme,
  StyleProps
>((theme) => {
  return createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: theme?.palette?.background.paper,
      marginTop: 10
    },
    card: {
      borderRadius: 0
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flex: 1
    },
    withPadding: {
      paddingBottom: (props) => `${props?.stickyHeight}px`
    }
  })
})

export const useStickyZoneStyles = makeStyles((theme) => {
  return createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: theme?.palette?.background.paper,
      marginTop: 10
    },
    card: {
      borderRadius: 0
    }
  })
})

export const useGridLayoutStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundColor: (props: { color: string }) => props.color
    }
  })
})

export const useLabelRendererStyles = makeStyles(() => {
  return createStyles({
    container: {
      wordBreak: 'break-word',

      '& .nested-list': {
        listStyleType: 'none'
      }
    }
  })
})
