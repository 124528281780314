import { useMutation } from 'react-query'

import { useConfig, useJourneyContext } from '../../../../utils'
import getJourneyClient from '../../../../utils/clients/journey-client'

interface GenerateFileConfig {
  url: string
  orgId: string
  token: string
}
interface GenerateFile {
  fileId: string
  data?: Record<string, unknown>
}

const generateFile = (
  config: GenerateFileConfig,
  fileId: string,
  data?: Record<string, unknown>
) => {
  const { url, orgId, token } = config
  const client = getJourneyClient(url, orgId, token)

  if (!fileId) {
    throw new Error('fileId is required')
  }

  return client.generateDocument(
    {},
    {
      file_id: fileId,
      context_data: data || {},
      language: 'de'
    }
  )
}

export const useGenerateFile = () => {
  const { JOURNEYS_API_URL } = useConfig()
  const { context } = useJourneyContext()

  const {
    journey: { organizationId: orgId, settings }
  } = context
  const publicToken = settings?.publicToken as string

  const { mutateAsync, data, isLoading, error } = useMutation(
    ({ fileId, data }: GenerateFile) => {
      return generateFile(
        {
          url: JOURNEYS_API_URL,
          orgId,
          token: publicToken
        },
        fileId,
        data
      )
    }
  )

  return {
    data,
    isLoading,
    error,
    generateFile: mutateAsync
  }
}
