import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { useConfig } from '../../utils'
import {
  DEFAULT_REACT_QUERY_OPTIONS,
  REACT_QUERY_JOURNEY_KEY,
  getJourney
} from '../../utils/journeyLauncherUtils'

export const useJourney = <Value = unknown>(journeyId: string) => {
  const { JOURNEYS_API_BASE_URL } = useConfig()

  const result = useQuery(
    [REACT_QUERY_JOURNEY_KEY, journeyId],
    () => getJourney<Value>(journeyId, JOURNEYS_API_BASE_URL),
    DEFAULT_REACT_QUERY_OPTIONS
  )

  useEffect(() => {
    if (result.error) {
      console.error(
        `There was an error loading journey with id: ${journeyId}`,
        result.error
      )
    }
  }, [result.error, journeyId])

  return result
}
