import { clsx, makeStyles } from '@epilot/journey-elements'
import {
  DYNAMIC_PRICING_VALUES_PROPERTY,
  getBlockNameByScope
} from '@epilot/journey-logic-commons'
import type {
  ProductControlOptions,
  EpilotControlProps,
  Price,
  Product,
  CompositePrice,
  GeneralTileConfiguration,
  ProductTileData,
  ProductTileDataConfig,
  Coupon
} from '@epilot/journey-logic-commons'
import { PricingModel } from '@epilot/pricing'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FeatureFlags, useFlags, useJourneyContext } from '../../../../utils'
import type { CatalogSearchResult } from '../../../../utils/services/product-service'
import { useGetAgProviders } from '../hooks/useGetAgProviders'
import { useProductCategories } from '../hooks/useProductCategories'
import type { SelectedProduct } from '../types'
import { getInjectedData } from '../utils/getInjectedData'
import {
  getInjectedAddressData,
  isCompositePriceEntity
} from '../utils/product-selection-control-utils'

import { AdditionalAddressGetAgForm } from './AdditionalAddressGetAgForm'
import { Loading } from './Loading'
import { CatalogProductTilesControlWithFilters } from './logicComponents/CatalogProductTilesControlWithFilters'
import { NoProducts } from './NoProducts'

type ProductSelectionControlProps = EpilotControlProps & {
  isLoading: boolean
  results: NonNullable<CatalogSearchResult['results']>
  resultsHits: number
  isRequired: boolean
  blockPath: string
  id: string
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '20px'
  },
  featuredContainer: {
    marginTop: '20px'
  },
  newDesignFeaturedContainer: {
    marginTop: '30px'
  }
}))

type ProductTileDataWithPriceId = ProductTileData & {
  priceId?: string
}

export function ProductSelectionControlComponent({
  handleChange,
  data,
  path,
  uischema,
  isLoading,
  resultsHits,
  results,
  isRequired,
  blockPath,
  id
}: ProductSelectionControlProps) {
  const classes = useStyles()
  const { context } = useJourneyContext()

  const {
    products = [],
    ctaTextOption,
    blockMappings: blockMappingsConfig
  } = (uischema.options || {}) as ProductControlOptions

  const flags = useFlags()

  const isNumberInjectionEnabled =
    flags?.[FeatureFlags.PRODUCT_NUMBER_INJECTION]

  const numberInputBlocksData = useMemo(
    () =>
      isNumberInjectionEnabled &&
      getInjectedData({
        blockMappingsConfig,
        _stepsHistoryStateObject: context?._stepsHistoryStateObject,
        steps: context?.journey?.steps
      }),
    [
      isNumberInjectionEnabled,
      context._stepsHistoryStateObject,
      context?.journey?.steps,
      blockMappingsConfig
    ]
  )

  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const isPreviewMode = context._isPreview

  const entitiesMap = useMemo(
    () => Object.fromEntries(results.map((entity) => [entity._id!, entity])),
    [results]
  )

  const productTiles = useMemo(
    () =>
      products
        .map<ProductTileDataWithPriceId | null>(
          ({
            productId,
            priceId,
            isFeatured,
            featuredLabel,
            featuredColor,
            featuredLabelColor
          }) => {
            const product = entitiesMap[productId] as Product | undefined
            const price = entitiesMap[priceId] as
              | Price
              | CompositePrice
              | undefined

            if (!product || !price) {
              return null
            }

            const couponsForPrice = Object.values(entitiesMap).filter(
              (entity): entity is Coupon =>
                entity._schema === 'coupon' &&
                entity.prices?.some(
                  ({ _id }: { _id: string }) => price._id === _id
                )
            )

            const tile = {
              product,
              config: {} as ProductTileDataConfig,
              priceId: price._id,
              isFeatured,
              featuredLabel,
              featuredColor,
              featuredLabelColor,
              coupons: couponsForPrice
            }

            if (isCompositePriceEntity(price)) {
              const { price_components: priceComponents } = price

              if (!Array.isArray(priceComponents) || !priceComponents?.length) {
                return null
              }

              const getAgPriceComponent = priceComponents.find(
                (comp) => comp.pricing_model === PricingModel.externalGetAG
              )
              const getAgConfigPriceComponent = getAgPriceComponent?.get_ag

              return {
                ...tile,
                price: {
                  ...price,
                  _coupons: couponsForPrice,
                  price_components: priceComponents.filter(
                    (priceComponent) =>
                      typeof priceComponent.unit_amount === 'number'
                  )
                },
                isGetAgPrice: !!getAgPriceComponent,
                // Get the first billing period from the price components
                billingPeriod: getAgPriceComponent?.billing_period || 'yearly',
                getAgCategory: getAgConfigPriceComponent?.category || 'power',
                getAGConsumptionType:
                  getAgConfigPriceComponent?.consumption_type || 'household',
                getAGConcessionType: getAgConfigPriceComponent?.concession_type,
                getAGMeterType: getAgConfigPriceComponent?.meter_type
              }
            }

            return {
              ...tile,
              price: {
                ...price,
                _coupons: couponsForPrice
              },
              isGetAgPrice: price.pricing_model === PricingModel.externalGetAG,
              billingPeriod: price.billing_period || 'yearly',
              getAgCategory: price.get_ag?.category || 'power',
              getAGConsumptionType:
                price.get_ag?.consumption_type || 'household',
              getAGConcessionType: price.get_ag?.concession_type,
              getAGMeterType: price.get_ag?.meter_type
            }
          }
        )
        .filter((item): item is NonNullable<typeof item> => {
          if (!item) {
            return false
          }
          /**
           * @todo item should be properly typed
           */
          const priceOptions = item.product.price_options as Price[]

          /**
           * Product tile should only be displayed if priceId is present
           * in the priceOptions for the product
           */
          return (
            item.price &&
            priceOptions.some((option) => option._id === item.priceId)
          )
        }),
    [entitiesMap, products]
  )

  const injectedAddress = useMemo(
    () => getInjectedAddressData(context, uischema.options),
    [context, uischema.options]
  )

  const {
    isLoadingGetAGProviders,
    hasGetAGProducts,
    showAdditionalAddress,
    getAgAdditionalAddressData,
    getAgPowerUniqueProvider,
    getAgGasUniqueProvider
  } = useGetAgProviders({
    injectedAddress,
    productTiles,
    blockPath,
    context
  })

  const blockName = getBlockNameByScope(uischema.scope)

  const selectedCategories = useProductCategories(blockName)

  const selectedProductTiles = useMemo(() => {
    if (!Array.isArray(data)) {
      return data
    }

    // if data exists in the block data, while the journey context is still empty, do nothing!
    // later once the context was updated, now we process!
    if (data && !context._allProductSelectors[blockPath]) {
      return data
    }

    return data
      .map((item) => {
        const products = context._allProductSelectors[blockPath]

        if (Array.isArray(products)) {
          const quantity = products.find(
            ({ product }) =>
              product?.selectedPriceId === item?.product?.selectedPriceId &&
              product?.selectedProductId === item?.product?.selectedProductId
          )?.quantity

          if (!quantity) {
            return
          }

          return {
            ...item,
            quantity
          }
        }
      })
      .filter(Boolean)
  }, [blockPath, context._allProductSelectors, data])

  const featuresAmountLimit = useMemo(() => {
    const featuresLimit = uischema.options?.featuresLimit?.trim()

    return typeof featuresLimit === 'string' && !isNaN(Number(featuresLimit))
      ? Number(featuresLimit)
      : undefined
  }, [uischema.options?.featuresLimit])

  const generalTileConfiguration = useMemo<GeneralTileConfiguration>(
    () => ({
      orgId: context.journey.organizationId || '',
      ctaConfig: {
        label: ctaTextOption,
        undoActionLabel: ctaTextOption ? `${ctaTextOption}_undo` : undefined,
        disabled: uischema.options?.disabled
      },
      quantityConfig: {
        show: uischema.options?.showQuantity,
        minValue: uischema.options?.quantityMinValue,
        maxValue: uischema.options?.quantityMaxValue
      },
      featuresAmountLimit,
      showImages: uischema.options?.showImages,
      highlightSelected: uischema.options?.highlightSelectedProduct,
      showFeaturesIcon: uischema.options?.showFeaturesIcon,
      isBlockRequired: isRequired,
      blockPath,
      getAgMetadata: {
        hasGetAGProducts,
        injectedAddress,
        getAgPowerUniqueProvider,
        getAgGasUniqueProvider,
        isLoadingGetAGProviders,
        showAdditionalAddress
      },
      justifyContent: uischema.options?.justifyContent,
      blockMappings: isNumberInjectionEnabled
        ? numberInputBlocksData
        : uischema.options?.[DYNAMIC_PRICING_VALUES_PROPERTY],
      displayPriceComponents: uischema.options?.displayPriceComponents,
      displayProductDescription: uischema.options?.displayProductDescription,
      displayRecurringTotal: uischema.options?.displayRecurringTotal,
      displayUnitaryAverage: uischema.options?.displayUnitaryAverage,
      recurringTotalUnitOption: uischema.options?.recurringTotalUnitOption,
      showProductDetails: uischema.options?.showProductDetails,
      showTrailingDecimalZeros: Boolean(
        uischema.options?.showTrailingDecimalZeros
      ),
      isPreviewMode,
      displayCtaButtonUnderPrice: uischema.options?.displayCtaButtonUnderPrice,
      tileLayoutVariant: uischema.options?.tileLayoutVariant
    }),
    [
      isNumberInjectionEnabled,
      context.journey.organizationId,
      ctaTextOption,
      uischema.options,
      featuresAmountLimit,
      isRequired,
      blockPath,
      hasGetAGProducts,
      injectedAddress,
      isLoadingGetAGProviders,
      getAgPowerUniqueProvider,
      getAgGasUniqueProvider,
      isPreviewMode,
      showAdditionalAddress,
      numberInputBlocksData
    ]
  )

  if (
    isLoading ||
    (resultsHits && !Object.keys(entitiesMap).length) ||
    (!showAdditionalAddress && isLoadingGetAGProviders)
  ) {
    return <Loading />
  }

  if (resultsHits === 0) {
    return <NoProducts />
  }

  const isPlacedOnCard: boolean = uischema.options?.showPaper
  const hasFeaturedProducts: boolean = uischema.options?.products.some(
    (product: SelectedProduct) => product.isFeatured
  )
  const useNewDesign = context.journey.settings?.useNewDesign

  const addExtraClass = isPlacedOnCard && hasFeaturedProducts

  const featuredProductsContainerClass = useNewDesign
    ? addExtraClass && classes.newDesignFeaturedContainer
    : addExtraClass && classes.featuredContainer

  return (
    <div className={clsx(classes.container, featuredProductsContainerClass)}>
      {showAdditionalAddress && (
        <AdditionalAddressGetAgForm
          blockPath={blockPath}
          data={getAgAdditionalAddressData}
          id={id}
          injectedAddress={injectedAddress}
          label={t(
            'getag_additional_address_label',
            'Provide additional address information to find products near you.'
          )}
          path={path}
        />
      )}
      <CatalogProductTilesControlWithFilters
        _filterValues={uischema.options?._filterValues}
        blockMappings={uischema.options?.blockMappings}
        data={productTiles}
        filters={uischema.options?.filters}
        generalTileConfiguration={generalTileConfiguration}
        id={id}
        onChange={(value) => handleChange(path, value)}
        selectedCategories={selectedCategories}
        selectedProductTiles={selectedProductTiles}
        selectionType={uischema.options?.selectionType}
        t={t}
      />
    </div>
  )
}
