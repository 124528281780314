import type { Theme } from '@epilot/journey-elements'
import { useTheme } from '@epilot/journey-elements'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export function IsMobile() {
  const theme = useTheme<Theme>()

  return useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  })
}

export function IsTablet() {
  const theme = useTheme<Theme>()

  return useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true
  })
}
