import type { InjectFilterLogic } from '../inject'
import {
  blockController,
  CONTROL_NAME,
  logicActionTypes,
  logicOperators,
  LogicTriggerEventName,
  DYNAMIC_PRICING_VALUES_PROPERTY
} from '../utils'
import type { Step, BlockLocatorDetails, PricingBlockMapping } from '../utils'

const getNumberInputBlockForStep = (steps: Step[], stepIndex: number) => {
  const numberInputBlocks = blockController
    .findBlocks(steps, { type: CONTROL_NAME.NUMBER_INPUT_CONTROL })
    .filter((block) => block.stepIndex === stepIndex)

  const inputCalculatorBlocks = blockController
    .findBlocks(steps, { type: CONTROL_NAME.INPUT_CALCULATOR_CONTROL })
    .filter((block) => block.stepIndex === stepIndex)

  return [...numberInputBlocks, ...inputCalculatorBlocks]
}

const getDynamicPriceLogic = (
  block: BlockLocatorDetails,
  blockMapping: PricingBlockMapping
): InjectFilterLogic => {
  return {
    actions: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target:
          `##${blockMapping.metadata?.configuratorStepId}/${blockMapping.metadata?.configuratorBlockName}##` as `##${string}##`
      }
    ],
    actionsIncludesFilter: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target:
          `##${blockMapping.metadata?.configuratorStepId}/${blockMapping.metadata?.configuratorBlockName}##` as `##${string}##`,
        targetBlockName: `${blockMapping.metadata?.configuratorBlockName}`,
        targetStepIndex: blockMapping.metadata?.configuratorStepIndex as number,
        targetStepId: blockMapping.metadata?.configuratorStepId as string
      }
    ],
    blockName: block.name,
    stepId: block.stepId,
    conditions: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    conditionsRelatedToStep: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    injectedFilterValueName: DYNAMIC_PRICING_VALUES_PROPERTY
  }
}

const getDynamicPriceLogicsForBlock = (
  block: BlockLocatorDetails,
  blockMappings: PricingBlockMapping[]
): InjectFilterLogic[] => {
  return blockMappings.reduce(
    (
      blockLogics: InjectFilterLogic[],
      currentBlockMapping: PricingBlockMapping
    ) => {
      if (
        blockLogics.find(
          (blockMapping: InjectFilterLogic) =>
            blockMapping.actionsIncludesFilter[0].targetBlockName ===
              currentBlockMapping.metadata?.configuratorBlockName &&
            blockMapping.actionsIncludesFilter[0].targetStepId ===
              currentBlockMapping.metadata?.configuratorStepId
        )
      ) {
        return blockLogics
      }

      return [...blockLogics, getDynamicPriceLogic(block, currentBlockMapping)]
    },
    []
  )
}

export function getDynamicPriceLogicsForStep(
  steps: Array<Step>,
  stepIndex: number
): (InjectFilterLogic | [])[] {
  const productSelectionBlocks = blockController.findBlocks(steps, {
    type: CONTROL_NAME.PRODUCT_SELECTION_CONTROL
  })

  const stepBlockMappings: PricingBlockMapping[] = productSelectionBlocks
    .flatMap((block) => {
      const blockMappings = block.uischema?.options
        ?.blockMappings as PricingBlockMapping[]

      if (blockMappings && blockMappings.length > 0) {
        blockMappings.forEach((blockMapping) => {
          if (blockMapping.type === 'pricing') {
            blockMapping.metadata = {
              ...(blockMapping.metadata || {}),
              configuratorBlockName: block.name,
              configuratorStepIndex: block.stepIndex,
              configuratorStepName: block.stepName,
              configuratorStepId:
                blockMapping.metadata?.configuratorStepId || block.stepId
            }
          }
        })
      }

      return blockMappings
    })
    .filter(
      (block: PricingBlockMapping) =>
        block && block.stepId === steps[stepIndex].stepId
    )

  const numberInputBlocksInStep = getNumberInputBlockForStep(steps, stepIndex)

  if (numberInputBlocksInStep) {
    return numberInputBlocksInStep.flatMap((block) => {
      const blockMappings = stepBlockMappings.filter(
        (mapping) => mapping.blockName === block.name
      )

      return getDynamicPriceLogicsForBlock(block, blockMappings)
    })
  }

  return []
}
