import { BodyText } from '@epilot/journey-elements'
import {
  type PriceItem,
  getDisplayPrice,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { useMemo } from 'react'

import { useCartItemStyles } from '../CatalogShoppingCart/components/CartBody/styles'
import { formatPercentage } from '../CatalogShoppingCart/components/CartBody/utils'

type CartItemDetailsLineDiscountProps = {
  item: PriceItem
  t: TFunction
  showTrailingDecimalZeros?: boolean
}

export const CartItemDetailsLineDiscount = ({
  item,
  t,
  showTrailingDecimalZeros
}: CartItemDetailsLineDiscountProps) => {
  const classes = useCartItemStyles()

  const { discountAmountToDisplay, beforeDiscountAmountTotalToDisplay } =
    useMemo(() => {
      const formatPrice = (value: number | string) =>
        getDisplayPrice(
          t,
          value,
          undefined,
          showTrailingDecimalZeros,
          item.currency
        )

      const discountAmount = shouldShowDecimals(item.discount_amount)
        ? item.discount_amount_decimal
        : item.discount_amount

      const beforeDiscountAmountTotal = shouldShowDecimals(
        item.before_discount_amount_total
      )
        ? item.before_discount_amount_total_decimal
        : item.before_discount_amount_total

      const discountPercentage = item.discount_percentage

      return {
        discountAmountToDisplay:
          typeof discountPercentage !== 'undefined'
            ? formatPercentage(discountPercentage)
            : typeof discountAmount !== 'undefined'
              ? formatPrice(discountAmount)
              : undefined,
        beforeDiscountAmountTotalToDisplay:
          typeof beforeDiscountAmountTotal !== 'undefined'
            ? formatPrice(beforeDiscountAmountTotal)
            : undefined
      }
    }, [
      item.before_discount_amount_total,
      item.before_discount_amount_total_decimal,
      item.currency,
      item.discount_amount,
      item.discount_amount_decimal,
      item.discount_percentage,
      showTrailingDecimalZeros,
      t
    ])

  return (
    <div className={classes.discountInnerContainer}>
      {beforeDiscountAmountTotalToDisplay !== undefined && (
        <BodyText
          className={classes.priceBeforeDiscount}
          color="textSecondary"
          display="block"
        >
          {beforeDiscountAmountTotalToDisplay}
        </BodyText>
      )}
      {discountAmountToDisplay !== undefined && (
        <BodyText
          className={classes.discount}
          color="textSecondary"
          display="block"
        >
          -{discountAmountToDisplay}
        </BodyText>
      )}
    </div>
  )
}
