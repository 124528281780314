import type {
  BlockMappingsValues,
  BlockMappingValue,
  ProductControlOptions,
  Step
} from '@epilot/journey-logic-commons'

import type { JourneyContextValue } from '../../../../utils'

type getInjectedDataProps = {
  blockMappingsConfig?: ProductControlOptions['blockMappings']
  _stepsHistoryStateObject?: JourneyContextValue['_stepsHistoryStateObject']
  steps?: Step[]
}
export function getInjectedData({
  blockMappingsConfig,
  _stepsHistoryStateObject,
  steps
}: getInjectedDataProps) {
  if (blockMappingsConfig && _stepsHistoryStateObject && steps) {
    const numberInputBlocksData = blockMappingsConfig?.reduce(
      (acc: Record<string, unknown>, blockMapping) => {
        const { blockName, stepId } = blockMapping
        const stepIndex = steps.findIndex((j) => j.stepId === stepId)
        const stepNumber = stepIndex + 1
        const blockKey = Object.keys(_stepsHistoryStateObject).find((k) =>
          // the keys are like StepNumber/BlockName/NumberInputControl
          // ex. 1/Nummerneingabe/NumberInputControl
          k.startsWith(`${stepNumber}/${blockName}/`)
        )

        const blockData =
          stepNumber > 0 &&
          blockKey &&
          (_stepsHistoryStateObject?.[blockKey] as BlockMappingValue)

        if (blockData) {
          acc[`${stepId}/${blockName}`] = blockData
        }

        return acc
      },
      {}
    )

    return Object.keys(numberInputBlocksData).length > 0
      ? (numberInputBlocksData as BlockMappingsValues)
      : undefined
  }

  return undefined
}
