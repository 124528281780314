import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type {
  CompositePriceItem,
  PriceItem
} from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { useTileContext } from '../../../../../renderers/controls/ProductSelectionControl/utils/tile-context-utils'
import { useComputePricingDetails } from '../../../../../utils'
import { sortItemRecurrenceDetails } from '../../../../../utils/products/sortItemsRecurrenceDetails'
import type { PriceItemWithBlockConfiguration } from '../../../../CatalogShoppingCart/types'
import {
  ProductDetailsPriceInformationItemList,
  ProductDetailsPriceInformationItemComponent,
  ProductDetailsPriceInformationTotals
} from '../../../../Product'

import { ProductDetailsPriceInformationTaxes } from './ProductDetailsPriceInformationTaxes'
import { extractPriceTaxes } from './utils'

export const ProductDetailsPriceInformation = () => {
  const {
    price,
    pricingDetails,
    blockMappings,
    config: {
      generalTileConfiguration: {
        showTrailingDecimalZeros,
        displayUnitaryAverage
      }
    },
    tileState: { quantity, priceDisplayInJourneys }
  } = useTileContext()
  const classes = useStyles()

  const parentQuantity = Math.max(quantity, 1)
  const { items: priceItems = [] } = pricingDetails

  const {
    pricingDetails: { items: itemsWithQuantity = [] }
  } = useComputePricingDetails({
    price,
    quantity: parentQuantity,
    overrides: {
      blockMappings
    }
  })

  const firstItemWithQuantity = itemsWithQuantity?.[0] as
    | PriceItemWithBlockConfiguration
    | undefined

  const sortedRecurrenceDetails = useMemo(
    () =>
      firstItemWithQuantity
        ? sortItemRecurrenceDetails(firstItemWithQuantity)
        : [],
    [firstItemWithQuantity]
  )

  /* In case every component uses the same tax, the indexes should be omitted */
  const displayTaxIndexes = useMemo(
    () =>
      !priceItems?.every((item) => {
        /* If it's a simple price, we should always omit the tax indexes */
        if (!item.is_composite_price || !('item_components' in item))
          return true

        const [firstComponentTaxIds, ...otherComponentTaxIds] =
          item.item_components?.map(
            (component) =>
              component.taxes
                ?.map(({ tax: { _id } = {} }) => _id)
                .filter((v): v is string => !!v) ?? []
          ) ?? []

        /* Check if every component uses the same taxes */
        return otherComponentTaxIds.every(
          (ids) =>
            ids.length === firstComponentTaxIds.length &&
            ids.every((tax) => firstComponentTaxIds.includes(tax))
        )
      }),
    [priceItems]
  )

  const taxes = useMemo(() => extractPriceTaxes(price), [price])

  const displayItemList = Boolean(
    (priceDisplayInJourneys === 'show_price' ||
      priceDisplayInJourneys === 'show_as_starting_price') &&
      priceItems.length
  )

  const hasOnlyOnetimeRecurrences = useMemo(
    () =>
      priceItems.every(
        (item) =>
          (isCompositePriceItem(item) &&
            item.item_components?.every(
              (component) =>
                component._price?.type === 'one_time' || !component._price?.type
            )) ||
          (!isCompositePriceItem(item) &&
            (item._price?.type === 'one_time' || !item._price?.type))
      ),
    [priceItems]
  )

  return (
    <div
      aria-labelledby="price-information-heading"
      className={classes.wrapper}
    >
      {(priceDisplayInJourneys === 'show_price' ||
        priceDisplayInJourneys === 'show_as_starting_price') &&
        priceItems.map((item) => (
          <ProductDetailsPriceInformationItemList key={item._id}>
            {(isCompositePriceItem(item) &&
              item.item_components?.map((component) => (
                <ProductDetailsPriceInformationItemComponent
                  component={component}
                  displayUnitaryAverage={displayUnitaryAverage}
                  key={component.price_id}
                  shouldHideRecurrence={
                    hasOnlyOnetimeRecurrences &&
                    (component._price?.type === 'one_time' ||
                      !component._price?.type)
                  }
                  showTrailingDecimalZeros={showTrailingDecimalZeros}
                  taxes={displayTaxIndexes ? taxes : []}
                />
              ))) || (
              <ProductDetailsPriceInformationItemComponent
                component={item as PriceItem}
                displayUnitaryAverage={displayUnitaryAverage}
                shouldHideRecurrence={
                  hasOnlyOnetimeRecurrences &&
                  (item._price?.type === 'one_time' || !item._price?.type)
                }
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                taxes={displayTaxIndexes ? taxes : []}
              />
            )}
          </ProductDetailsPriceInformationItemList>
        ))}
      {sortedRecurrenceDetails && (
        <ProductDetailsPriceInformationTotals
          className={displayItemList ? classes.spaced : undefined}
          item={price}
          pricingDetails={pricingDetails}
          quantity={parentQuantity}
          recurrenceDetails={sortedRecurrenceDetails}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
        />
      )}
      {(priceDisplayInJourneys === 'show_price' ||
        priceDisplayInJourneys === 'show_as_starting_price') &&
        !!taxes.length && (
          <ProductDetailsPriceInformationTaxes
            className={displayItemList ? classes.spaced : undefined}
            displayIndexes={displayTaxIndexes}
            taxes={taxes}
          />
        )}
    </div>
  )
}

const useStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(2),
      width: '100%',
      padding: theme.spacing(3),
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px',
      backgroundColor: theme.palette.grey[30]
    },
    spaced: {
      margin: theme.spacing(0, 3)
    }
  })
)

const isCompositePriceItem = (
  price: PriceItem | CompositePriceItem
): price is CompositePriceItem => !!price.is_composite_price
