import { Grid, createStyles, makeStyles } from '@epilot/journey-elements'

import type { BaseEntity } from '../../../../utils/clients/entity-client'
import type { EntityLookupControlOptions } from '../types'

import { AttributeItem } from './AttributeItem'
import { AttributesHeader } from './AttributesHeader'

const useStyles = makeStyles(() =>
  createStyles({
    headerStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '5px'
    }
  })
)

type AttributesLabelProps = {
  tableAttributes: EntityLookupControlOptions['tableAttributes']
  entity: BaseEntity
  slug: string
  showIcon?: boolean
}
export function AttributesLabel({
  tableAttributes,
  entity,
  slug,
  showIcon
}: AttributesLabelProps) {
  const styles = useStyles()
  const Attributes = (
    <Grid container>
      {tableAttributes?.map((attr, i) => (
        <AttributeItem
          key={'column_attr' + i}
          label={attr.label}
          slug={slug}
          value={entity[attr.name]}
        />
      ))}
    </Grid>
  )

  return showIcon ? (
    <div className={styles.headerStyle}>
      <AttributesHeader showIcon={showIcon} slug={slug} />
      {Attributes}
    </div>
  ) : (
    <div className={styles.headerStyle}>{Attributes}</div>
  )
}
