import { LargeText, useTheme } from '@epilot/journey-elements'
import AvgTime from '@epilot360/icons/react/AvgTime'
import EnergySavingsLeaf from '@epilot360/icons/react/EnergySavingsLeaf'
import Solar from '@epilot360/icons/react/Solar'
import { useTranslation } from 'react-i18next'

import { useWidgetStyles } from './styles'
import { WidgetContainer } from './WidgetContainer'

export const SolarPanelsDataWidget = ({
  userYearlyConsumption,
  solarPanelUserCount,
  panelLifetimeYears,
  maxYearlyConsumption
}: {
  userYearlyConsumption: string | null | undefined
  solarPanelUserCount: number | undefined
  panelLifetimeYears: number | undefined
  maxYearlyConsumption: number | undefined
}) => {
  const classes = useWidgetStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const isUserInputBiggerThanMaxYearlyConsumption =
    Number(userYearlyConsumption) > Number(maxYearlyConsumption)

  // if a user enters an yearly consumption value that is higher than the maxYearlyConsumption
  // than we show maxYearlyConsumption/userYearlyConsumption otherwise we show userYearlyConsumption
  const yearlyConsumptionValue =
    userYearlyConsumption && maxYearlyConsumption
      ? isUserInputBiggerThanMaxYearlyConsumption
        ? `${maxYearlyConsumption}\u200B/\u200B${parseFloat(
            parseFloat(userYearlyConsumption).toFixed(2)
          )}`
        : parseFloat(parseFloat(userYearlyConsumption).toFixed(2))
      : '-'

  const solarPanelCountValue = solarPanelUserCount
    ? isUserInputBiggerThanMaxYearlyConsumption
      ? `Max. ${solarPanelUserCount}`
      : solarPanelUserCount
    : '-'

  return (
    <WidgetContainer>
      <LargeText className={classes.title}>
        {t('rooftop_planner.consumption_title')}
      </LargeText>
      <div className={classes.line}>
        <div className={classes.textContainer}>
          <Solar fill={theme.palette.primary.main} />
          <span>{t('rooftop_planner.amount_of_panels')}</span>
        </div>
        <span className={classes.dataCell}>{solarPanelCountValue}</span>
      </div>
      <div className={classes.line}>
        <div className={classes.textContainer}>
          <EnergySavingsLeaf fill={theme.palette.primary.main} />
          <span>{t('rooftop_planner.yearly_output')}</span>
        </div>
        <span className={classes.yearlyOutputCell}>
          {yearlyConsumptionValue}
        </span>
      </div>
      <div className={classes.line}>
        <div className={classes.textContainer}>
          <AvgTime fill={theme.palette.primary.main} />
          <span>{t('rooftop_planner.panels_lifespan')}</span>
        </div>
        <span className={classes.dataCell}>{panelLifetimeYears ?? '-'}</span>
      </div>
      {isUserInputBiggerThanMaxYearlyConsumption &&
      maxYearlyConsumption &&
      userYearlyConsumption ? (
        <span className={classes.additionalNote}>
          <span className={classes.note}>
            {t('rooftop_planner.additional_note_first_part', {
              solarPanelCount: solarPanelUserCount
            })}
          </span>
          <span className={classes.percentage}>
            {(
              (Number(maxYearlyConsumption) / Number(userYearlyConsumption)) *
              100
            ).toFixed(0)}
            %
          </span>
          <span className={classes.note}>
            {t('rooftop_planner.additional_note_second_part')}
          </span>
        </span>
      ) : (
        <span className={classes.note}>{t('rooftop_planner.note')}</span>
      )}
    </WidgetContainer>
  )
}
