import type { ComponentProps } from 'react'
import { createContext, useContext, useMemo } from 'react'

import type { Action } from '../../renderers/controls/ActionBarControl/types'

export type NavigationContextValue = {
  onAction: (action: Action) => void
}

const NavigationContext = createContext<NavigationContextValue | undefined>(
  undefined
)

export const useNavigationContext = () => {
  const context = useContext(NavigationContext)

  if (!context) {
    throw new Error(
      'useNavigationContext should only be used inside a <NavigationContext.Provider>'
    )
  }

  return context
}

type NavigationProviderProps = Omit<
  ComponentProps<typeof NavigationContext.Provider>,
  'value'
> & {
  onAction: NavigationContextValue['onAction']
}

export const NavigationProvider = ({
  onAction,
  ...props
}: NavigationProviderProps) => {
  const value = useMemo(() => ({ onAction }), [onAction])

  return <NavigationContext.Provider {...props} value={value} />
}

NavigationProvider.displayName = 'NavigationProvider'
