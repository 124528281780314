import classNames from 'classnames'

import { Typography } from '../../../../components'
import classes from '../../styles/ProductTitle.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

export const ProductTitle = () => {
  const { product, tileVariantsEnabled } = useTileContext()

  return (
    <div className={classNames(!tileVariantsEnabled && classes.container)}>
      <Typography as="h3" className={classes.title} id="tileTitle">
        {product.name}
      </Typography>
    </div>
  )
}
