import { journeyStorage } from '@epilot/journey-logic-commons'
import type { Client } from '@epilot/metering-client'
import { getClient } from '@epilot/metering-client'
import type { AxiosRequestHeaders } from 'axios'

let MeteringApiClient: Client | null = null
const getToken = (): string | null => journeyStorage.getItem('token')

const getMeteringClient = (apiUrl: string) => {
  if (MeteringApiClient) return MeteringApiClient

  MeteringApiClient = getClient()
  MeteringApiClient.defaults.baseURL = apiUrl

  if ((MeteringApiClient.interceptors.request as any)['handlers'].length > 3) {
    console.error(
      'Sanity check: This client already has more than 3 request interceptors, refusing to add more!',
      {
        baseURL: MeteringApiClient.defaults.baseURL,
        interceptors: MeteringApiClient.interceptors
      }
    )
  } else {
    MeteringApiClient.interceptors.request.use(async (request) => {
      const token = getToken()

      if (!request.headers) {
        request.headers = {} as AxiosRequestHeaders
      }

      if (token) {
        request.headers['authorization'] = `Bearer ${token}`
      }

      return request
    })
  }

  return MeteringApiClient
}

export default getMeteringClient
