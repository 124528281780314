import type { IconsId } from '@epilot/journey-elements'
import {
  BodyText,
  H1,
  StackSpacing,
  Button,
  makeStyles,
  useTheme
} from '@epilot/journey-elements'
import CheckCircle from '@epilot360/icons/react/CheckCircle'

import { JOURNEY_EXIT_EVENT } from '../../../../utils/constants/EventConstant'
import type { EventDetailType } from '../../../../utils/constants/types'

export type ConfirmationMessageComponentProps = {
  icon?: IconsId
  title?: string
  text?: string
  closeButtonText?: string
  showCloseButton?: boolean
  id: string
}

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: 110,
    minHeight: 110
  }
}))

export function ConfirmationMessageComponent({
  title = '',
  text = '',
  closeButtonText = 'schließen',
  showCloseButton = true,
  id
}: ConfirmationMessageComponentProps) {
  const styles = useStyles()
  const theme = useTheme()
  const handleOnExitButton = () => {
    const event = new CustomEvent<EventDetailType>(JOURNEY_EXIT_EVENT, {
      detail: {
        payload: { isCleanJourneyData: true }
      }
    })

    document.dispatchEvent(event)
  }

  return (
    <StackSpacing alignItems="center" scale={2}>
      <CheckCircle
        className={styles.icon}
        fill={theme.palette.primary.main}
        variant="filled"
      />
      <H1 color="textPrimary">{title}</H1>
      <BodyText align="center" color="textSecondary">
        {text}
      </BodyText>
      {showCloseButton && (
        <Button
          color="primary"
          id={id}
          onClick={() => handleOnExitButton()}
          size="large"
          variant="contained"
        >
          {closeButtonText}
        </Button>
      )}
    </StackSpacing>
  )
}
