import type { BlockLocatorDetails, Step } from '@epilot/journey-logic-commons'
import { CONTROL_NAME, blockController } from '@epilot/journey-logic-commons'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import { useEffect, useMemo, useReducer } from 'react'

import { useJourneyContext } from '../../../../utils'

const UPDATE_SELECTED_CATEGORIES = 'UPDATE_SELECTED_CATEGORIES'

const reducer = (
  state: string[] | undefined,
  action: {
    type: string
    payload: string[]
  }
): string[] | undefined => {
  switch (action.type) {
    case UPDATE_SELECTED_CATEGORIES:
      if (!isEqual(state, action.payload)) {
        return action.payload
      } else {
        return state
      }
    default:
      return state
  }
}

/**
 * Custom hook to retrieve the unique selected product categories related to the current block.
 * @param currentBlockName The name of the current block.
 * @returns An array of unique selected product categories.
 * @todo Understand why this uses a reducer rather than memoizing the value
 */
export const useProductCategories = (
  currentBlockName: string | undefined
): string[] | undefined => {
  const { context } = useJourneyContext()

  const [selectedCategories, dispatch] = useReducer(reducer, [])

  const {
    _stepsStateArray,
    _navigationInfo,
    journey: { steps }
  } = context
  const { currentStepIndex } = _navigationInfo

  const stepsBeforeCurrent = steps.slice(0, currentStepIndex)

  const currentStepId = steps[currentStepIndex]?.stepId

  // get product category blocks which are configured as a relation to the current product selection block
  const relatedProductCategoryBlocks = useMemo(
    () =>
      findRelatedProductCategoryBlocks(
        stepsBeforeCurrent,
        currentStepId,
        currentBlockName
      ),
    [currentBlockName, currentStepId, stepsBeforeCurrent]
  )

  // compile all selected categories
  useEffect(() => {
    const relatedCategories = relatedProductCategoryBlocks?.reduce<string[]>(
      (accumulator, block) => {
        // Check if the block is related to the current product selection block
        if (
          block.uischema?.options?.relatedProductSelection ===
          `${currentStepId}/${currentBlockName}`
        ) {
          const values = _stepsStateArray?.[block.stepIndex]?.[block.name]

          // Check if the values exist, are an array, and every value is of type string
          if (
            values &&
            Array.isArray(values) &&
            values.every((value): value is string => typeof value === 'string')
          ) {
            // Push the values into the accumulator array
            accumulator.push(...values)
          }
        }

        return accumulator
      },
      []
    )
    const uniqueSelectedCategories = uniq(relatedCategories)

    dispatch({
      type: UPDATE_SELECTED_CATEGORIES,
      payload: uniqueSelectedCategories
    })
  }, [
    currentBlockName,
    currentStepId,
    relatedProductCategoryBlocks,
    _stepsStateArray
  ])

  return selectedCategories
}

/**
 * Find product category blocks related to the current product selection block.
 * @param stepsBeforeCurrent The steps before the current step.
 * @param currentStepId The ID of the current step.
 * @param currentBlockName The name of the current block.
 * @returns The related product category blocks.
 */
function findRelatedProductCategoryBlocks(
  stepsBeforeCurrent: Step[],
  currentStepId: string,
  currentBlockName: string | undefined
): BlockLocatorDetails[] {
  // find product category filter blocks
  const productCategoryBlocks = blockController.findBlocks(stepsBeforeCurrent, {
    type: CONTROL_NAME.PRODUCT_CATEGORY_CONTROL
  })

  // filter product category blocks related to the current product selection block
  return productCategoryBlocks.filter((block) => {
    const { relatedProductSelection } = block.uischema?.options || {}

    return relatedProductSelection === `${currentStepId}/${currentBlockName}`
  })
}
