import { createStyles, makeStyles } from '@material-ui/core'
import { TabList as MuiTabList } from '@material-ui/lab'
import classNames from 'classnames'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import type { EpilotTheme } from '../'

import type { TabsProps, TabListProps } from './types'

export { Tab } from '@material-ui/core'
export { useTabContext, TabContext, TabPanel, TabList } from '@material-ui/lab'

const useStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    buttonsFlat: {
      '& > div': {
        '& > div': {
          minHeight: 40,
          '& > *': {
            fontWeight: 400,
            fontSize: 16,
            color: theme.palette?.text?.secondary,
            boxShadow: `inset 0px -1px 0px 0px ${theme.palette?.divider}`,
            minHeight: 40,
            '&.Mui-selected': {
              color: theme.palette?.text?.primary,
              fontWeight: 600
            },
            '&:hover': {
              backgroundColor: theme.palette?.action.hover
            }
          }
        }
      }
    },
    buttons3d: {
      /**
       * @todo
       * This is just a hack for overflow:hidden
       */
      marginLeft: -8,
      overflow: 'visible',
      '& > div': {
        '& > span': {
          backgroundColor: 'transparent'
        },
        '& > div': {
          minHeight: 56,
          overflow: 'visible',
          /**
           * @todo
           * This is just a hack for overflow:hidden
           */
          paddingLeft: 8,
          '& > *': {
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '24px',
            color: theme.palette?.text?.primary,
            borderRadius: '8px 8px 0px 0px',
            boxShadow: `inset 0px 0px 8px 0px #ffffff3d`,
            marginTop: '2px',
            minHeight: 56,
            marginRight: 4,
            backgroundColor: `${theme.palette?.grey[10]}CC !important`,
            opacity: 1,
            overflow: 'visible',
            '&.Mui-selected': {
              fontWeight: 600,
              color: theme.palette?.text?.primary,
              overflow: 'visible',
              backgroundColor: '#ffffff !important',
              boxShadow: `0px 2px 2px rgba(89, 103, 117, 0.2)`
            }
          }
        }
      }
    }
  })
)

export const Tabs = forwardRef<
  ElementRef<typeof MuiTabList>,
  PropsWithoutRef<TabsProps>
>(({ className, buttonsVariant = 'flat', ...props }, ref) => {
  const classes = useStyles()

  return (
    <MuiTabList
      className={classNames(
        buttonsVariant === '3d' ? classes.buttons3d : classes.buttonsFlat,
        className
      )}
      ref={ref}
      {...props}
    />
  )
})

Tabs.displayName = 'Tabs'

const useTabsStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    buttonsFlat: {
      '& > div': {
        '& > div': {
          minHeight: 40,
          '& > *': {
            minHeight: 40,
            fontWeight: 400,
            fontSize: 16,
            color: theme.palette?.grey[200],
            boxShadow: `inset 0px -2px 0px ${theme.palette?.grey[70]}`,
            minWidth: 0,
            '&.Mui-selected': {
              color: theme.palette?.text?.primary,
              fontWeight: 600,
              backgroundColor: 'transparent !important'
            }
          }
        }
      }
    }
  })
)

export const PrimaryTabs = forwardRef<
  ElementRef<typeof MuiTabList>,
  PropsWithoutRef<TabListProps>
>(({ className, ...props }, ref) => {
  const classes = useTabsStyles()

  return (
    <MuiTabList
      className={classNames(classes.buttonsFlat, className)}
      ref={ref}
      {...props}
    />
  )
})

PrimaryTabs.displayName = 'PrimaryTabs'
