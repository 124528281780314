import type { SerializedBlockSummary, SummaryField } from './types'

export function parseBlockSummary(value: SerializedBlockSummary): SummaryField {
  const parts = value.split('/')

  return {
    display: parts[0] === 'false' ? false : true,
    customDisplayname: parts[3],
    originalBlockName: parts[2],
    stepIndex: parts[1] ? +parts[1] : 0,
    stepName: ''
  }
}
