import { Typography } from '@epilot/journey-elements'
import type { Address } from '@epilot/journey-logic-commons'
import Map from '@epilot360/icons/react/Map'
import { Loader } from '@googlemaps/js-api-loader'
import { useMediaQuery } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import type { UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useConfig,
  useJourneyContext,
  assignNewMarker,
  getGoogleMapsApiKey
} from '../../utils'

import { useMapStyles } from './styles'
import type { AddressMapData, HouseNumberKey } from './utils'
import {
  MAP_SETTINGS,
  convertStringToLatLngObject,
  fetchCoordinatesData,
  getAddressFromCoordinates,
  getHouseAddress,
  validateAndSetValues
} from './utils'

interface AddressFinderMapProps {
  isRepositioningAllowed?: boolean
  addressData: AddressMapData | undefined | null
  setValue: UseFormSetValue<Address>
  setIsResetForbidden: (isResetForbidden: boolean) => void
  isResetForbidden: boolean
  houseNumberKey?: HouseNumberKey
  hideMapIfUnlisted?: boolean
  isPinReset?: boolean
  id?: string
}

export const AddressFinderMap = ({
  isRepositioningAllowed = false,
  addressData,
  setValue,
  setIsResetForbidden,
  isResetForbidden,
  houseNumberKey = 'streetNumber',
  hideMapIfUnlisted = false,
  isPinReset = false,
  id
}: AddressFinderMapProps) => {
  const { t } = useTranslation()
  const classes = useMapStyles()
  const isMobile = useMediaQuery('(max-width: 750px)')
  const address = addressData ? getHouseAddress(addressData) : undefined
  const location = addressData?.coordinates

  const mapRef = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [error, setError] = useState(false)

  const { context: _context } = useJourneyContext()
  const publicToken = _context.journey.settings?.publicToken as string

  const { GOOGLE_MAPS_API_URL } = useConfig()

  const controllerRef = useRef(new AbortController())

  const [addressFromCoordinates, setAddressFromCoordinates] =
    useState<AddressMapData>()

  const pinRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)

  // this useEffect is for initializing the map
  useEffect(() => {
    getGoogleMapsApiKey(GOOGLE_MAPS_API_URL, publicToken)
      .then((key: string) => {
        const loader = new Loader({
          apiKey: key,
          version: 'weekly'
        })

        loader.importLibrary('maps').then(() => {
          if (mapRef.current) {
            const newMap = new google.maps.Map(mapRef.current, MAP_SETTINGS)

            setMap(newMap)
          }
        })
        setError(false)
      })
      .catch((error) => {
        setError(true)
        console.error(`Error loading Google Maps: ${error}`)
      })
  }, [hideMapIfUnlisted, GOOGLE_MAPS_API_URL, publicToken])

  // this useEffect updates the pin and center when the journey goes back, and when the address is injected
  useEffect(() => {
    if (!location || !map) return
    if (isPinReset) return

    const coordinates = convertStringToLatLngObject(location)

    assignNewMarker({ pinRef, coordinates, map })

    map.setCenter(coordinates)
    map.setZoom(
      addressData?.houseNumber ? 19 : addressData?.streetName ? 16 : 14
    )
  }, [location, map, isPinReset])

  // this useEffect updates the pin and center the map when address changes
  useEffect(() => {
    if (isResetForbidden) return

    const controller = controllerRef.current

    if (map && address) {
      fetchCoordinatesData({
        address,
        controller,
        map,
        controllerRef,
        pinRef,
        addressData,
        setValue,
        googleMapsApiUrl: GOOGLE_MAPS_API_URL,
        publicToken
      })
    }

    return () => {
      // cleanup: abort the ongoing request when component unmounts or dependency changes
      controller.abort()
    }
  }, [address, isPinReset, map, GOOGLE_MAPS_API_URL, publicToken])

  // this useEffect allows repositioning the pin on the map
  useEffect(() => {
    if (!isRepositioningAllowed || !map) return

    const clickListener = map.addListener(
      'click',
      (e: google.maps.MapMouseEvent) => {
        const clickedLocation = e.latLng

        if (clickedLocation) {
          const lat = clickedLocation.lat()
          const lng = clickedLocation.lng()

          assignNewMarker({ pinRef, coordinates: clickedLocation, map })
          getAddressFromCoordinates(
            { lat, lng },
            GOOGLE_MAPS_API_URL,
            publicToken
          ).then((address: AddressMapData) => {
            setAddressFromCoordinates({
              ...address,
              coordinates: `${lat}, ${lng}`
            })
          })
        }
      }
    )

    return () => {
      google.maps.event.removeListener(clickListener)
    }
  }, [map, isRepositioningAllowed, GOOGLE_MAPS_API_URL, publicToken])

  // this useEffect resets the pin when Unlisted addresses toggle is on in a journey
  useEffect(() => {
    if (!map || !pinRef.current) return
    if (!isPinReset) return

    pinRef.current = null
  }, [isPinReset])

  // this useEffect is for setting the address input fields when a user repins the map
  useEffect(() => {
    if (!addressFromCoordinates) return
    setIsResetForbidden(true)

    validateAndSetValues({
      addressData,
      addressFromCoordinates,
      setValue,
      houseNumberKey
    })

    // this is a way to change this flag after all the fields are updated properly
    setTimeout(() => setIsResetForbidden(false), 0)
  }, [addressFromCoordinates, setValue])

  return (
    <>
      {hideMapIfUnlisted ? (
        <></>
      ) : (
        <div className={classes.container}>
          {error ? (
            <Typography className={classes.error}>
              <Map className={classes.iconMap} fill={'#43474E'} />
              <span className={classes.errorText}>
                {t('address_finder_map_error')}
              </span>
            </Typography>
          ) : (
            <>
              {isRepositioningAllowed && (
                <Typography className={classes.text}>
                  {t('address_finder_map_text')}
                </Typography>
              )}
              <div
                className={isMobile ? classes.mapMobile : classes.map}
                data-testid="address-finder-google-maps-test-id"
                id={id}
                ref={mapRef}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}
