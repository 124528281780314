import { functionalValidators, IBAN_Specifications } from '@epilot/validators'
import type { ChangeEvent } from 'react'
import { useEffect, useRef, useState } from 'react'

import { CircularProgress, Hidden, MaskedTextField, TextField } from '../'
import { usePrevious } from '../hooks/usePrevious'

import type { IbanInputProps, IbanResponse } from './types'
import {
  MASK_PLACEHOLDER,
  getAutoData,
  getIbanPlaceholder,
  getMask,
  getSanitizedIban
} from './utils'

export function IbanInput(props: IbanInputProps) {
  const {
    iban: initialIban,
    ibanLabel = 'IBAN',
    ibanHelper,
    bic,
    bicLabel = 'BIC',
    bicHelper,
    bankName,
    bankNameLabel,
    bankNameHelper,
    apiBaseUrl,
    onChange,
    disabled = false,
    error,
    required = false,
    onChangeNoValidation,
    variant = 'filled',
    alwaysShowMask = false,
    id,
    publicToken
  } = props
  const [iban, setIban] = useState<string>(initialIban || '')
  const [loading, setLoading] = useState(false)
  const [ibanError, setIbanError] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { placeholder, maskLength: initialMaskLength } = getIbanPlaceholder(
    navigator.language
  )

  const [maskLength, setMaskLength] = useState(initialMaskLength)
  const [applyMask, setApplyMask] = useState<boolean>(false)
  const prevApplyMask = usePrevious(applyMask)
  const [ibanSyntaxValid, setIbanSyntaxValid] = useState(false)
  const [autoData, setAutoData] = useState<IbanResponse>({
    bic_number: bic || '',
    bank_name: bankName || ''
  })

  useEffect(() => {
    setApplyMask(iban.length > 1)

    // focus the input element whenever the input component changes and it was initially typed
    if (
      inputRef.current &&
      typeof prevApplyMask !== 'undefined' &&
      applyMask !== prevApplyMask
    ) {
      inputRef.current.focus()
    }
  }, [iban, applyMask, prevApplyMask])

  useEffect(() => {
    if (initialIban) {
      const sanitizedIban = getSanitizedIban(iban)

      if (sanitizedIban.length > 1) setMaskLength(sanitizedIban.length)
      load(initialIban)
      setIban(sanitizedIban)
      setIbanError(false)
      setIbanSyntaxValid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIban])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    const sanitizedIban = getSanitizedIban(newValue)

    if (sanitizedIban.length > 1) setMaskLength(sanitizedIban.length)

    setIban(sanitizedIban.toUpperCase())

    // give the value to the callback
    if (onChangeNoValidation) {
      onChangeNoValidation(sanitizedIban)
    }

    // check the iban for errors
    const ibanValid = functionalValidators.iban.callback(sanitizedIban)

    // set the mask length
    if (sanitizedIban.length > 1) {
      const spec =
        IBAN_Specifications[sanitizedIban.substring(0, 2).toUpperCase()]

      if (spec) {
        setMaskLength(spec.length)
      }
    }

    // autofill if a url is provided
    // and there is not syntax in the iban
    if (apiBaseUrl && ibanValid) {
      // set to loading
      setLoading(true)
      setIbanSyntaxValid(true)
      load(sanitizedIban)
    } else {
      setIbanError(true)
      setIbanSyntaxValid(false)
      // pass data to the caller as empty
      if (onChange) {
        onChange('', {})
      }
    }
  }

  const load = (newValue: string) => {
    // fill
    getAutoData(newValue, apiBaseUrl, publicToken).then((data) => {
      if (data) {
        setAutoData(data)
        setIbanError(false)

        // pass data to the caller if there is no errors
        if (onChange) {
          onChange(newValue, data)
        }
      } else {
        setAutoData({})
        setIbanError(true)
        // pass data to the caller as empty
        if (onChange) {
          onChange('', {})
        }
      }

      setLoading(false)
    })
  }

  return (
    <>
      {applyMask ? (
        <MaskedTextField
          alwaysShowMask={alwaysShowMask}
          inputRef={inputRef}
          mask={getMask(maskLength)}
          maskPlaceholder={MASK_PLACEHOLDER}
          required={required}
          textFieldProps={{
            disabled,
            error:
              error ||
              ibanError ||
              (iban.length > 2 && iban.indexOf('_') === -1 && !ibanSyntaxValid),
            fullWidth: true,
            helperText:
              error ||
              ibanError ||
              (iban.length > 2 && iban.indexOf('_') === -1 && !ibanSyntaxValid)
                ? ibanHelper
                : '',
            label: ibanLabel,
            onChange: handleChange,
            placeholder: placeholder,
            value: iban,
            variant: variant,
            InputLabelProps: { htmlFor: id },
            id
          }}
        />
      ) : (
        <TextField
          InputLabelProps={{ htmlFor: id }}
          disabled={disabled}
          error={error || ibanError || (!ibanSyntaxValid && iban.length > 2)}
          fullWidth={true}
          helperText={
            error || ibanError || (!ibanSyntaxValid && iban.length > 2)
              ? ibanHelper
              : ''
          }
          id={id}
          inputRef={inputRef}
          label={ibanLabel}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          value={iban}
          variant={variant}
        />
      )}
      <Hidden xsUp={ibanError || !ibanSyntaxValid}>
        <TextField
          InputProps={{
            endAdornment: loading ? <CircularProgress size={20} /> : undefined
          }}
          disabled
          fullWidth
          helperText={bicHelper}
          label={bicLabel}
          value={autoData?.bic_number}
          variant={variant}
        />
      </Hidden>
      <Hidden xsUp={ibanError || !ibanSyntaxValid}>
        <TextField
          InputProps={{
            endAdornment: loading ? <CircularProgress size={20} /> : undefined
          }}
          disabled
          fullWidth
          helperText={bankNameHelper}
          label={bankNameLabel}
          value={autoData?.bank_name}
          variant={variant}
        />
      </Hidden>
    </>
  )
}
