import { DatePickerForm as ConcordeDatePickerForm } from '@epilot/concorde-elements'
import { Hidden } from '@epilot/journey-elements'
import {
  isDatePickerControlOptions,
  isDatePickerControlData
} from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../..'
import { FeatureFlags, useFlags, useJourneyContext } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { DatePickerForm } from './components/DatePickerForm'

export function DatePickerControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  visible,
  required,
  id
}: EpilotControlProps) {
  const options = uischema.options
  const hasError = !!errors
  const isConcordeDateBlockFF = useFlags()[FeatureFlags.CONCORDE_DATE_BLOCK]
  const stepBlockId = useStepBlockId(path, id)
  const { context } = useJourneyContext()
  const useNewDesign = context.journey.settings?.useNewDesign

  if (!isDatePickerControlOptions(options)) {
    // eslint-disable-next-line no-console
    console.error(
      'DatePickerControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isDatePickerControlData(data)) {
    // eslint-disable-next-line no-console
    console.error(
      'DatePickerControl -> Invalid data detected. Current data is:',
      {
        data
      }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      {isConcordeDateBlockFF && useNewDesign ? (
        <ConcordeDatePickerForm
          data={data}
          handleChange={handleChange}
          hasError={hasError}
          id={stepBlockId}
          isRequired={!!required}
          options={options}
          path={path}
        />
      ) : (
        <DatePickerForm
          data={data}
          handleChange={handleChange}
          hasError={hasError}
          id={stepBlockId}
          isRequired={!!required}
          path={path}
          {...options}
        />
      )}
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: DatePickerControlPure
  }) as never
)
