import {
  DateFnsUtils,
  FormControlLabel,
  Grid,
  MuiPickersUtilsProvider,
  Radio,
  RadioGroup
} from '@epilot/journey-elements'
import {
  appendStepBlockId,
  getLocaleDateString,
  LOCALE_MAP
} from '@epilot/journey-logic-commons'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldDatePicker } from '../../../components/FieldDatePicker'
import { FieldText } from '../../../components/FieldText'
import { useFormHandler } from '../../../utils/hooks/forms/useFormHandler'
import { useResetFieldsIfOneChanged } from '../../../utils/hooks/forms/useResetFieldsIfOneChanged'

import { FieldNumberInput } from './FieldNumberInput'
import { useStyles } from './styles'
import type { MeterReadingProps, MeterReadingFormValues } from './types'

export const MeterReadingForm = (props: MeterReadingProps) => {
  const { fields, handleChange, locale, isRequired, data, path, hasError, id } =
    props
  const { t } = useTranslation()
  const classes = useStyles()

  const { control, resetField } = useFormHandler<MeterReadingFormValues>({
    handleChange,
    path,
    data,
    isRequired: isRequired,
    fields,
    formProps: {
      defaultValues: {
        ...data
      }
    }
  })

  useResetFieldsIfOneChanged<MeterReadingFormValues>(resetField, {
    name: 'meterType',
    watch: data?.meterType,
    fieldsToReset: [
      { name: 'readingValue', value: undefined },
      { name: 'feedInValue', value: undefined },
      { name: 'feedOutValue', value: undefined },
      { name: 'ntValue', value: undefined },
      { name: 'htValue', value: undefined }
    ]
  })

  return (
    <Grid container spacing={3}>
      {fields?.maloId && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.maloId.defaultValue || ''}
            hasError={hasError}
            id={appendStepBlockId(id, 'Malo Id')}
            name="maloId"
            path={props.path}
            type="number"
            {...fields.maloId}
          />
        </Grid>
      )}

      {fields?.meterId && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.meterId.defaultValue || ''}
            hasError={hasError}
            id={appendStepBlockId(id, 'Meter Id')}
            name="meterId"
            path={props.path}
            {...fields.meterId}
          />
        </Grid>
      )}

      {!fields?.meterType && (
        <Grid item md={6} sm={12} xs={12}>
          <Grid item md={12} sm={12} xs={12}>
            <FieldNumberInput
              control={control}
              fields={fields}
              hasError={hasError}
              id={appendStepBlockId(id, 'Reading Value')}
              label={t('reading_value')}
              name="readingValue"
              placeholder={t('reading_value')}
              {...fields.readingValue}
            />
          </Grid>
        </Grid>
      )}

      {fields?.meterType && (
        <Grid item md={12} sm={12} xs={12}>
          <Controller
            control={control}
            name="meterType"
            render={({ field }) => {
              return (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  className={data?.meterType === '' ? '' : classes.container}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                >
                  {fields?.meterType?.showOneDirectional && (
                    <FormControlLabel
                      control={
                        <Radio
                          id={appendStepBlockId(id, 'One Tariff Counter')}
                        />
                      }
                      label={t('one_tariff')}
                      value="one_tariff"
                    />
                  )}
                  {fields?.meterType?.showTwoDirectional && (
                    <FormControlLabel
                      control={
                        <Radio
                          id={appendStepBlockId(id, 'Two Tariff Counter')}
                        />
                      }
                      label={t('two_tariff')}
                      value="two_tariff"
                    />
                  )}
                  {fields?.meterType?.showBiDirectional && (
                    <FormControlLabel
                      control={
                        <Radio
                          id={appendStepBlockId(id, 'Bi Directional Counter')}
                        />
                      }
                      label={t('bi_directional')}
                      value="bi_directional"
                    />
                  )}
                </RadioGroup>
              )
            }}
          />

          {data?.meterType === 'one_tariff' && (
            <Grid item md={12} sm={12} xs={12}>
              <FieldNumberInput
                control={control}
                fields={fields}
                hasError={hasError}
                id={appendStepBlockId(id, 'Reading Value')}
                label={t('reading_value')}
                name="readingValue"
                placeholder={t('reading_value')}
                {...fields.readingValue}
              />
            </Grid>
          )}
          {data?.meterType === 'bi_directional' && (
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <FieldNumberInput
                  control={control}
                  fields={fields}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Reading Value Feed In')}
                  label={t('reading_value_feed_in')}
                  name="feedInValue"
                  placeholder={t('reading_value_feed_in')}
                  {...fields.readingValue}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <FieldNumberInput
                  control={control}
                  fields={fields}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Reading Value Feed Out')}
                  label={t('reading_value_feed_out')}
                  name="feedOutValue"
                  placeholder={t('reading_value_feed_out')}
                  {...fields.readingValue}
                />
              </Grid>
            </Grid>
          )}

          {data?.meterType === 'two_tariff' && (
            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={6}>
                <FieldNumberInput
                  control={control}
                  fields={fields}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Reading Value NT Counter')}
                  label={t('reading_value_nt')}
                  name="ntValue"
                  placeholder={t('reading_value_nt')}
                  {...fields.readingValue}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <FieldNumberInput
                  control={control}
                  fields={fields}
                  hasError={hasError}
                  id={appendStepBlockId(id, 'Reading Value HT Counter')}
                  label={t('reading_value_ht')}
                  name="htValue"
                  placeholder={t('reading_value_ht')}
                  {...fields.readingValue}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}

      {fields?.readingDate && (
        <Grid item md={6} sm={12} xs={12}>
          <MuiPickersUtilsProvider
            locale={LOCALE_MAP[locale as 'de' | 'en']}
            utils={DateFnsUtils}
          >
            <FieldDatePicker
              control={control}
              defaultValue={fields.readingDate.defaultValue || new Date()}
              disableFuture
              format={getLocaleDateString(navigator.language)}
              hasError={hasError}
              id={appendStepBlockId(id, 'Reading Date')}
              label={t('ReadingDate')}
              name="readingDate"
              path={props.path}
              rules={{
                validate: (value) => {
                  // dont validate further if no value. Other, internal rules still validate
                  if (!value) return true

                  return new Date(value).getTime() < new Date().getTime()
                }
              }}
              shouldContainTime
              {...fields.readingDate}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}

      {fields?.readBy && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.readBy.defaultValue || ''}
            hasError={hasError}
            id={appendStepBlockId(id, 'Read By')}
            name="readBy"
            path={props.path}
            {...fields.readBy}
          />
        </Grid>
      )}

      {fields?.reason && (
        <Grid item md={6} sm={12} xs={12}>
          <FieldText
            control={control}
            defaultValue={fields.reason.defaultValue || ''}
            hasError={hasError}
            id={appendStepBlockId(id, 'Reason')}
            name="reason"
            path={props.path}
            {...fields.reason}
          />
        </Grid>
      )}
    </Grid>
  )
}
