import { makeStyles } from '@epilot/journey-elements'

export const useBlockGroupStyles = makeStyles({
  blockHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  divider: {
    width: '100%'
  }
})
