import type { ComponentProps } from 'react'
import JSONPretty from 'react-json-pretty'

const JSONPretty1337 = {
  main: 'line-height:1.3;color:#f8f8f2;background:#1e1e1e;overflow:auto;',
  error: 'line-height:1.3;color:#f8f8f2;background:#1e1e1e;overflow:auto;',
  key: 'color:#ff5e5e;',
  string: 'color:#e9fdac;',
  value: 'color:#fdb082;',
  boolean: 'color:#69c;'
}

export const DisplayJson = (props: ComponentProps<typeof JSONPretty>) => (
  <JSONPretty theme={JSONPretty1337} {...props} />
)
