import { STATIC_ASSETS_URL } from '../config'

const proximaNova = {
  fontFamily: 'ProximaNova',
  fontStyle: 'normal',
  fontDisplay: 'swap'
}
const proximaNovaRegular = {
  ...proximaNova,
  fontWeight: 400,
  src: `local('Proxima Nova Regular'), local('ProximaNova-Regular'), url('${STATIC_ASSETS_URL}/fonts/ProximaNova-Regular.woff')`
}
const proximaNovaSemiBold = {
  ...proximaNova,
  fontWeight: 600,
  src: `local('Proxima Nova Semibold'), local('ProximaNova-Semibold'), url('${STATIC_ASSETS_URL}/fonts/ProximaNova-Semibold.woff')`
}
const proximaNovaBold800 = {
  ...proximaNova,
  fontWeight: 800,
  src: `local('Proxima Nova Bold'), local('ProximaNova-Bold'), url('${STATIC_ASSETS_URL}/fonts/ProximaNova-Bold.woff')`
}
const proximaNovaBold = {
  ...proximaNova,
  fontWeight: 'bold',
  src: `local('Proxima Nova Bold'), local('ProximaNova-Bold'), url('${STATIC_ASSETS_URL}/fonts/ProximaNova-Bold.woff')`
}

const materialIcons = {
  fontFamily: 'Material Icons',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `local('Material Icons'), url('${STATIC_ASSETS_URL}/icons-font/material-icons.woff2'), url('https://fonts.googleapis.com/icon?family=Material+Icons')`
}

export default [
  proximaNovaRegular,
  proximaNovaSemiBold,
  proximaNovaBold800,
  proximaNovaBold,
  materialIcons
]
