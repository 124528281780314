/**
 * Mimics lodash's once function.
 * @see https://youmightnotneed.com/lodash#once
 */
export const once = <
  T extends (...args: unknown[]) => unknown | Promise<unknown>
>(
  fn: (...args: Parameters<T>) => unknown
) => {
  let called = false
  let result: unknown

  return (...args: Parameters<T>) => {
    if (!called) {
      result = fn(...args)
      called = true
    }

    return result
  }
}
