import type { InjectFilterLogic } from '../inject'
import type { BlockLocatorDetails, Step } from '../utils'

import { getAvailabilityInjectionLogicsForStep } from './availabilityInjection'
import { getCrossSellLogicsForStep } from './crossSell'
import { getDynamicPriceLogicsForStep } from './dynamicPrice'

export const getProductSelectionBlocksInStep = (
  productSelectionBlocks: BlockLocatorDetails[],
  stepIndex: number
) => {
  return productSelectionBlocks.filter(
    (block: BlockLocatorDetails) => block.stepIndex === stepIndex
  )
}

export const getAutoInjectionLogics = (
  steps: Array<Step>
): Array<Array<InjectFilterLogic>> => {
  return steps.map((_step, index) => {
    const crossSellLogics = getCrossSellLogicsForStep(steps, index)
    const dynamicPriceLogics = getDynamicPriceLogicsForStep(steps, index)
    const availabilityInjectionLogics = getAvailabilityInjectionLogicsForStep(
      steps,
      index
    )

    const allLogics = [
      crossSellLogics,
      dynamicPriceLogics,
      availabilityInjectionLogics
    ]
      .filter(Boolean)
      .flat() as InjectFilterLogic[]

    return allLogics
  })
}
