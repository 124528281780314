import type { InjectFilterLogic } from '../inject'
import type { Step, AddressControlOptions } from '../utils'
import {
  blockController,
  CONTROL_NAME,
  logicActionTypes,
  logicOperators,
  LogicTriggerEventName,
  AVAILABILITY_CHECK_VALUES_PROPERTY
} from '../utils'

export const getAvailabilityInjectionLogicsForStep = (
  steps: Step[],
  stepIndex: number
) => {
  const logics: InjectFilterLogic[] = []
  const currentStepId = steps[stepIndex].stepId

  // get all address blocks with valid/enabled availability injection related to this step
  const allStepRelatedAddressBlocks = (
    blockController.findBlocks(steps, {
      type: CONTROL_NAME.ADDRESS_BLOCK
    }) || []
  ).filter((block) => {
    const addressOptions = block.uischema?.options as AddressControlOptions

    return (
      addressOptions &&
      addressOptions.isInjectAddressEnabled &&
      addressOptions.injectAddressSettings?.relatedBlock?.split('/')?.[0] ===
        currentStepId
    )
  })

  // for each address block with availability injection, create a logic
  allStepRelatedAddressBlocks.forEach((block) => {
    const injectAddressSettings = (
      block.uischema?.options as AddressControlOptions
    )?.injectAddressSettings

    if (!injectAddressSettings?.relatedBlock) {
      return
    }

    const availabilityBlockParts = injectAddressSettings.relatedBlock.split('/')

    if (availabilityBlockParts?.length !== 2) {
      return
    }

    const newLogic = getAvailabilityInjectionLogic(
      {
        stepId: availabilityBlockParts?.[0],
        name: availabilityBlockParts?.[1]
      },
      {
        stepId: block.stepId,
        blockName: block.name,
        stepIndex: block.stepIndex
      }
    )

    newLogic && logics.push(newLogic)
  })

  return logics
}

const getAvailabilityInjectionLogic = (
  blockSource: {
    stepId: string
    name: string
  },
  blockTarget: {
    stepId: string
    blockName: string
    stepIndex: number
  }
): InjectFilterLogic => {
  return {
    actions: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target:
          `##${blockTarget.stepId}/${blockTarget.blockName}##` as `##${string}##`
      }
    ],
    actionsIncludesFilter: [
      {
        actionType: logicActionTypes.InjectFilter,
        conditionResult: true,
        source: '$$undefined$$' as `$$${string}$$`,
        target:
          `##${blockTarget.stepId}/${blockTarget.blockName}##` as `##${string}##`,
        targetBlockName: `${blockTarget.blockName}`,
        targetStepIndex: blockTarget.stepIndex as number,
        targetStepId: blockTarget.stepId as string
      }
    ],
    blockName: blockSource.name,
    stepId: blockSource.stepId,
    conditions: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    conditionsRelatedToStep: [
      {
        conditionOperator: logicOperators.NotEmpty,
        event: LogicTriggerEventName.NEXT
      }
    ],
    injectedFilterValueName: AVAILABILITY_CHECK_VALUES_PROPERTY
  }
}
