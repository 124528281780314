import { useCallback, useMemo, useState } from 'react'
import { ErrorCode } from 'react-dropzone'

import { BodyText, makeStyles } from '../'
import type { EnrichedFile, UploadZoneProps } from '../UploadZone'
import { UploadZone } from '../UploadZone'

import { FilesTable } from './FilesTable'
import { FileUpload } from './FileUpload'
import type { UploadPanelProps } from './types'
import { FileUploadStatus } from './types'

/**
 * Prepares an error message by extracting the name and extension from a file name and appending
 * a given type translation string, while also handling cases where the file name has no extension
 * or exceeds the maximum length of 25 characters.
 *
 * @param fileName The name of the file.
 * @param ofTypeTranslation The string to append after the file name and before the extension.
 * maxLength(internal): The maximum length of the file name. Defaults to 25.
 * ellipsis(internal):  The string to use to represent the truncation of the file name. Defaults to '...'.
 * @returns The prepared error message with the format: `name + ofTypeTranslation + extension`.
 * If the file name has no extension, the message will not include any extension.
 */
export const prepareErrorMessage = (
  fileName: string,
  ofTypeTranslation = 'Of a Type:'
): string => {
  const MAX_LENGTH = 25
  const ellipsis = '...'

  const lastDotIndex = fileName.lastIndexOf('.')
  const hasExtension = lastDotIndex !== -1

  const name = fileName.slice(0, hasExtension ? lastDotIndex : fileName.length)
  const truncatedName =
    name.length > MAX_LENGTH ? name.slice(0, MAX_LENGTH) + ellipsis : name
  const ext = hasExtension ? fileName.slice(lastDotIndex) : undefined

  return `${truncatedName} ${ofTypeTranslation}${ext ? ' ' + ext : ''}`
}

// TODO: Rework errors into a single state object and remove unnecessary error handlers
export function UploadPanel({
  uploadZoneProps,
  filesTableProps,
  uploadFileHandler,
  deleteFileHandler,
  labels,
  showLoadingBar,
  uploadedFiles = [],
  error,
  limitReachedText = 'File Upload limit reached',
  fileOfTypeText,
  fileNotSupportedText,
  emptyFilesNotAllowedText = 'File is empty or too small',
  largeFilesNotAllowedText = 'File is too big',
  networkErrorText = 'Failed to upload due to network issues',
  tooManyFilesText = 'Too many files',
  id
}: UploadPanelProps) {
  const [workingFiles, setWorkingFiles] = useState<EnrichedFile[]>([])
  const [uploadErrors, setUploadErrors] = useState<Record<string, string>>({})
  const filesWithErrors = useMemo<string[]>(
    () => Object.keys(uploadErrors),
    [uploadErrors]
  )
  const [tooManyFilesError, setTooManyFilesError] = useState<boolean>(false)

  /**
   * Removes a specific file from the list of files to upload.
   * You should do this when an error happens, when upload is finished, etc.
   */
  const removeFromFiles = (file: EnrichedFile) => {
    setWorkingFiles((prev) =>
      prev.filter((prevFile) => {
        if (prevFile.tempId && file.tempId) {
          return prevFile.tempId !== file.tempId
        }

        return prevFile.name !== file.name
      })
    )
  }

  const onProgressFinished = (
    file: EnrichedFile,
    progress?: Exclude<FileUploadStatus, FileUploadStatus.Progress>,
    error?: Error
  ) => {
    window.setTimeout(
      () => void removeFromFiles(file),
      // remove from the list of pending files, either immediately(ish) or after some time
      progress === FileUploadStatus.Cancelled ? 5000 : 100
    )

    if (
      progress === FileUploadStatus.Failed &&
      error?.message === 'Network Error'
    ) {
      setUploadErrors((prev) => ({
        ...prev,
        [file.tempId ?? file.name]: `(${file.name}) ${networkErrorText}`
      }))
    }
  }

  // the call back that will be fired in the dropzone once files are dropped
  const onDrop = useCallback<Exclude<UploadZoneProps['onDrop'], undefined>>(
    (acceptedFiles) => {
      // reset the error states after every new file drop
      setTooManyFilesError(false)
      setUploadErrors({})
      setWorkingFiles((prev) => [...prev, ...acceptedFiles])
    },
    []
  )

  const onDropRejected: UploadZoneProps['onDropRejected'] = (
    fileRejections
  ) => {
    let newTooManyFilesError = false

    const newUploadErrors = fileRejections.reduce<typeof uploadErrors>(
      (acc, rejection) => {
        const code = rejection.errors[0].code

        switch (code) {
          case ErrorCode.TooManyFiles:
            // handles separately to avoid multiple error messages
            newTooManyFilesError = true
            break

          case ErrorCode.FileTooSmall:
            acc[rejection.file.tempId ?? rejection.file.name] =
              `(${rejection.file.name}) ${emptyFilesNotAllowedText}`
            break

          case ErrorCode.FileTooLarge:
            acc[rejection.file.tempId ?? rejection.file.name] =
              `(${rejection.file.name}) ${largeFilesNotAllowedText}`
            break

          default:
            acc[rejection.file.tempId ?? rejection.file.name] =
              `${prepareErrorMessage(
                rejection.file.name,
                fileOfTypeText as string
              )} ${fileNotSupportedText}`
            break
        }

        return acc
      },
      {}
    )

    setUploadErrors((prev) => ({ ...prev, ...newUploadErrors }))
    setTooManyFilesError(newTooManyFilesError)
  }

  const uploadLimitReached = useMemo(() => {
    return (
      !!uploadZoneProps?.maxQuantity &&
      uploadZoneProps?.maxQuantity <= uploadedFiles.length
    )
  }, [uploadZoneProps?.maxQuantity, uploadedFiles.length])

  const classes = useStyles()

  return (
    <>
      {uploadLimitReached ? (
        <div className={classes.container}>
          <BodyText color="primary">{limitReachedText}</BodyText>
        </div>
      ) : (
        <UploadZone
          {...uploadZoneProps}
          error={!!error}
          id={id}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
        />
      )}
      {filesWithErrors && filesWithErrors.length > 0 && (
        <div className={classes.container}>
          {filesWithErrors.map((filename: string, index: number) => {
            const reason = uploadErrors[filename]

            return reason ? (
              <BodyText color="error" key={index}>
                {reason}
              </BodyText>
            ) : null
          })}
        </div>
      )}

      {/* Shows an outermost error or a generic too many files error */}
      {(error || tooManyFilesError) && (
        <div className={classes.container}>
          <BodyText color="error">{error ?? tooManyFilesText}</BodyText>
        </div>
      )}

      {/* Indicates the file/status/progress of each file being uploaded */}
      {workingFiles.map((file, index) => {
        return (
          <FileUpload
            file={file}
            key={file.tempId ?? index}
            labels={labels}
            onProgressFinished={(progress, error) =>
              onProgressFinished(file, progress, error)
            }
            showLoadingBar={showLoadingBar || true}
            uploadFileHandler={uploadFileHandler}
          />
        )
      })}

      {uploadedFiles && Object.keys(uploadedFiles).length > 0 && (
        <FilesTable
          {...filesTableProps}
          files={uploadedFiles}
          onDeleteFile={deleteFileHandler}
        />
      )}
    </>
  )
}

const useStyles = makeStyles({
  container: {
    paddingBottom: '12px',
    paddingTop: '12px'
  }
})
