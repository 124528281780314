export const LogicConditionSourceRegex = /\$\$(.*)\$\$/g
export const parseLogicConditionSource = (condition: string) => {
  const parts = LogicConditionSourceRegex.exec(condition)

  LogicConditionSourceRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicConditionOperatorRegex = /::(.*)::/g
export const parseLogicConditionOperator = (condition: string) => {
  const parts = LogicConditionOperatorRegex.exec(condition)

  LogicConditionOperatorRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicConditionValueRegex = /%%(.*)%%/g
export const parseLogicConditionValue = (condition: string | undefined) => {
  if (typeof condition !== 'string') {
    return undefined
  }

  const parts = LogicConditionValueRegex.exec(condition)

  LogicConditionValueRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicConditionValueFromPathRegex = /##(.*)##/g
export const parseLogicConditionValueFromPath = (condition: string) => {
  const parts = LogicConditionValueFromPathRegex.exec(condition)

  LogicConditionValueFromPathRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}
export const LogicConditionValueFromInternalIntegrationPathRegex = /@@(.*)@@/g
export const parseLogicConditionValueFromInternalIntegration = (
  condition: string
) => {
  const parts =
    LogicConditionValueFromInternalIntegrationPathRegex.exec(condition)

  LogicConditionValueFromInternalIntegrationPathRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicLiveValueFromPathFromPathRegex = /==(.*)==/g
export const parseLogicLiveValueFromPath = (condition: string) => {
  const parts = LogicLiveValueFromPathFromPathRegex.exec(condition)

  LogicLiveValueFromPathFromPathRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicConditionTrigerEventRegex = /\|\|(.*)\|\|/g
export const parseLogicConditionTrigerEvent = (condition: string) => {
  const parts = LogicConditionTrigerEventRegex.exec(condition)

  LogicConditionTrigerEventRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicActionConditionRegex = /!([^!]+)!/g
export const parseLogicActionCondition = (action: string) => {
  const parts = LogicActionConditionRegex.exec(action)

  LogicActionConditionRegex.lastIndex = 0

  return parts && parts[1] ? parts[1].toLowerCase() === 'true' : true
}

export const LogicActionTypeRegex = /::(.*)::/g
export const parseLogicActionType = (action: string) => {
  const parts = LogicActionTypeRegex.exec(action)

  LogicActionTypeRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicActionSourceRegex = /\$\$(.*)\$\$/g
export const parseLogicActionSource = (action: string) => {
  const parts = LogicActionSourceRegex.exec(action)

  LogicActionSourceRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}

export const LogicActionTargetRegex = /##(.*)##/g
export const parseLogicActionTarget = (action: string) => {
  const parts = LogicActionTargetRegex.exec(action)

  LogicActionTargetRegex.lastIndex = 0

  return parts && parts[1] ? parts[1] : undefined
}
