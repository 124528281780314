import type {
  InputCalculatorControlOptions,
  FactorPresetValues
} from '@epilot/journey-logic-commons'
import Big from 'big.js'

import { GAS_FACTOR, POWER_FACTOR, WATER_HEATING_FACTOR } from './constants'
import type { HydratedDeviceOption, InputCalculatorControlData } from './types'

export function parseInputCalculatorData(
  data: InputCalculatorControlData,
  options: InputCalculatorControlOptions
): InputCalculatorControlData {
  return {
    ...data,
    numberInput: data?.numberInput,
    numberUnit:
      options.deviceUnit === 'noUnit' ? undefined : options.deviceUnit,
    frequencyUnit: options.frequencyUnit,
    devices: data?.devices || []
  }
}

const getLocaleDecimalSeparator = () => {
  const numberWithDecimal = 1.1

  return numberWithDecimal
    .toLocaleString(navigator.language || 'de-DE')
    .substring(1, 2)
}

export const formatConsumptionToLocaleString = (
  consumption: string | undefined,
  decimals?: number
) => {
  if (!consumption) return ''
  const [integerPart, decimalPart] = consumption.split('.')

  const localeFormattedInteger = BigInt(integerPart).toLocaleString(
    navigator.language || 'de-DE'
  )
  const decimalSeparator = getLocaleDecimalSeparator()

  if (decimalPart) {
    // Parse to integer to check if it's not just zeros
    const decimalValue = parseInt(decimalPart, 10)

    if (decimalValue === 0) {
      return localeFormattedInteger
    } else {
      // Remove trailing zeros and ensure it has at least 2 digits (but no more than 2)
      const roundedDecimal = decimalPart
        ? parseFloat(`0.${decimalPart}`).toFixed(decimals ?? 2)
        : '0.00'
      const decimalValue = roundedDecimal.split('.')[1]
      const formattedDecimal = decimalValue.replace(/0+$/, '')

      return `${localeFormattedInteger}${decimalSeparator}${formattedDecimal}`
    }
  }

  return localeFormattedInteger
}

export const calculateDeviceConsumption = (
  initialQuantity: string,
  unitaryConsumption: string,
  hydratedDeviceOption: HydratedDeviceOption | undefined
): {
  total: string
  fixedUnitaryConsumption: number | undefined
} => {
  const isLinear = hydratedDeviceOption?.type === undefined
  const factorMap = hydratedDeviceOption?.factorMap

  const quantity = new Big(initialQuantity)

  // linear consumption
  if (isLinear || !factorMap || factorMap.length === 0) {
    return {
      total: quantity.times(new Big(unitaryConsumption)).toString(),
      fixedUnitaryConsumption: undefined
    }
  }

  // non-linear consumption (single factor or multiple factors)
  if (factorMap.length === 1) {
    const multiplicationFactor = factorMap[0].multiplicationFactor
    const fixedUnitaryConsumption = factorMap[0].unitaryDemand

    return {
      total: quantity
        .times(
          fixedUnitaryConsumption
            ? fixedUnitaryConsumption
            : new Big(unitaryConsumption)
        )
        .times(multiplicationFactor === undefined ? 1 : multiplicationFactor)
        .round(2)
        .toString(),
      fixedUnitaryConsumption: fixedUnitaryConsumption
    }
  } else {
    const factor = factorMap.find(
      (item) => item.quantity === quantity.toNumber()
    )
    const multiplicationFactor = !factor
      ? factorMap[factorMap.length - 1]?.multiplicationFactor
      : factor?.multiplicationFactor

    const fixedUnitaryConsumption = !factor
      ? factorMap[factorMap.length - 1]?.unitaryDemand
      : factor?.unitaryDemand

    return {
      total: quantity
        .times(
          fixedUnitaryConsumption
            ? fixedUnitaryConsumption
            : new Big(unitaryConsumption)
        )
        .times(multiplicationFactor === undefined ? 1 : multiplicationFactor)
        .round(2)
        .toString(),
      fixedUnitaryConsumption: fixedUnitaryConsumption
    }
  }
}

export const FACTOR_DICTIONARY: Map<string, FactorPresetValues[]> = new Map([
  ['water_heater', [...WATER_HEATING_FACTOR]],
  ['power', [...POWER_FACTOR]],
  ['gas', [...GAS_FACTOR]]
])

export const containsFixedUnitaryDemand = (
  factorMap: FactorPresetValues[] | undefined
) => {
  return factorMap?.some((item) => item.unitaryDemand !== undefined) || false
}
