import { Divider, createStyles, makeStyles } from '@epilot/journey-elements'

const useStyles = makeStyles(() =>
  createStyles({
    headerStyle: {
      marginTop: 32,
      marginBottom: 32,
      width: '100%'
    }
  })
)

export function MeterCardDivider() {
  const styles = useStyles()

  return (
    <div className={styles.headerStyle}>
      <Divider />
    </div>
  )
}
