import {
  Typography as ConcordeTypography,
  List as ConcordeList,
  ListItem as ConcordeListItem,
  ListItemAdornment as ConcordeListItemAdornment,
  ListItemContent as ConcordeListItemContent,
  Icon as ConcordeIcon
} from '@epilot/concorde-elements'
import {
  BodyText,
  Icon,
  List,
  ListItem,
  ListItemContent,
  ListItemPrefix,
  Typography,
  useTheme
} from '@epilot/journey-elements'
import type { Feature } from '@epilot/journey-logic-commons'

import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'

import { useFeatureCheckStyles, useFeatureTitleStyles } from './styles'

type ProductFeatureProps = { feature: Feature & { showIcon: boolean } }

export const ProductFeatures = () => {
  const { product, config, actions, useNewDesign } = useTileContext()
  const classes = useFeatureTitleStyles()

  const { showFeaturesIcon: showIcon } = config.generalTileConfiguration
  const title = config.content.features?.title
    ? actions.t(config.content.features.title)
    : ' '
  const featuresAmountLimit =
    config.generalTileConfiguration.featuresAmountLimit ??
    product.feature?.length
  const features = product.feature?.slice(0, featuresAmountLimit)
  const shouldRenderTitle = (features?.length || 0) > 0

  return (
    <>
      {useNewDesign ? (
        <NormalizedSectionContainer sectionName="concordeFeatures">
          {shouldRenderTitle && (
            <ConcordeTypography as="p">{title}</ConcordeTypography>
          )}
          <ConcordeList>
            {features?.map((feature, index) => (
              <ConcordeProductFeature
                feature={{
                  ...feature,
                  showIcon
                }}
                key={index}
              />
            ))}
          </ConcordeList>
        </NormalizedSectionContainer>
      ) : (
        <NormalizedSectionContainer
          className={classes.container}
          sectionName="features"
        >
          {shouldRenderTitle && (
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          )}
          <List>
            {features?.map((feature, index) => (
              <ProductFeature
                feature={{
                  ...feature,
                  showIcon
                }}
                key={index}
              />
            ))}
          </List>
        </NormalizedSectionContainer>
      )}
    </>
  )
}

const ProductFeature = ({ feature }: ProductFeatureProps) => {
  const theme = useTheme()
  const classes = useFeatureCheckStyles({ showIcon: feature.showIcon })

  return (
    <ListItem alignItems="flex-start" disableGutters>
      <ListItemPrefix className={classes.icon}>
        <Icon
          customColor={theme.palette.primary.main}
          fontSize="small"
          name="check"
        />
      </ListItemPrefix>

      <ListItemContent>
        <BodyText>{feature.feature}</BodyText>
      </ListItemContent>
    </ListItem>
  )
}

const ConcordeProductFeature = ({ feature }: ProductFeatureProps) => {
  const theme = useTheme()

  return (
    <ConcordeListItem>
      <ConcordeListItemAdornment>
        {feature.showIcon && (
          <ConcordeIcon
            color={theme.palette.primary.main}
            name="check"
            size="medium"
          />
        )}
      </ConcordeListItemAdornment>
      <ConcordeListItemContent>
        <ConcordeTypography>{feature.feature}</ConcordeTypography>
      </ConcordeListItemContent>
    </ConcordeListItem>
  )
}
