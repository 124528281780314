import { Card, CardContent, Hidden } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { getOptimizedImageURL } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Image } from '../../../components/Image'
import { useConfig, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { useStyles } from './styles'

const OPTIMAL_IMAGE_WIDTH = '1200'

const ImageControl = ({ uischema, visible, path }: EpilotControlProps) => {
  const stepBlockId = useStepBlockId(path)
  const { url, altText, width, id } = uischema?.options || {}
  const [isMobile, setIsMobile] = useState<boolean>(
    window.matchMedia('(max-width: 500px)').matches
  )
  const { t } = useTranslation()
  const classes = useStyles({ width })
  const { IMAGE_PREVIEW_API_URL } = useConfig()

  const optimizedImageURL = useMemo(
    () =>
      getOptimizedImageURL(IMAGE_PREVIEW_API_URL, url, id, OPTIMAL_IMAGE_WIDTH),
    [id, url, IMAGE_PREVIEW_API_URL]
  )

  // Image Load

  useEffect(() => {
    window
      .matchMedia('(max-width: 500px)')
      .addEventListener('change', (e) => setIsMobile(e.matches))
  }, [])

  const image = (
    <Image
      alt={altText}
      className={classes.image}
      data-testid="image-control"
      id={stepBlockId}
      src={optimizedImageURL}
    />
  )

  return (
    <Hidden xsUp={!visible}>
      {optimizedImageURL ? (
        <div className={classes.container}>
          <div className={isMobile ? classes.mobileDiv : classes.nonMobileDiv}>
            {image}
          </div>
        </div>
      ) : (
        <Card>
          <CardContent className={classes.cardContent}>
            {t('no_image_text')}
            {image}
          </CardContent>
        </Card>
      )}
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ImageControl }) as never
)
