import { useMemo } from 'react'

import { getToken } from '../../../utils/clients/customer-portal-client'
import { parseJwt } from '../../../utils/jwt'

export function usePortalCheck() {
  const token = getToken()

  const isECPPortal = useMemo(() => {
    return isECP(token || '')
  }, [token])

  return { isECPPortal }
}

export function isECP(token: string) {
  const origin = parseJwt(token)?.['custom:origin']

  return origin ? true : false
}
