import { BodyText, Button, Grid } from '@epilot/journey-elements'
import type {
  BlockConfiguration,
  BlockMappingData,
  PriceItem,
  Price,
  BillingPeriod
} from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceItemDisplayInJourneys,
  getPriceType,
  priceItemHasComponents,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { useMemo, useState } from 'react'

import { sortItemRecurrenceDetails } from '../../../../utils/products/sortItemsRecurrenceDetails'
import { ExpandIcon } from '../../../Product/ProductExpandIcon'

import { CartItemDetails } from './CartItemDetails'
import { CartItemImage } from './CartItemImage'
import { useCartItemStyles } from './styles'
import { formatPercentage } from './utils'

type CartItemProps = {
  item: PriceItem & {
    blockConfiguration?: BlockConfiguration
    blockMappingData?: BlockMappingData
  }
  t: TFunction
  displayPriceComponents?: boolean
  displayUnitaryAverage?: boolean
  showTrailingDecimalZeros?: boolean
  forceExpandedItemDetails?: boolean
}

export const CartItem = ({
  item,
  t,
  displayPriceComponents,
  displayUnitaryAverage,
  showTrailingDecimalZeros,
  forceExpandedItemDetails
}: CartItemProps) => {
  const classes = useCartItemStyles()
  const [isExpanded, setIsExpanded] = useState(
    forceExpandedItemDetails || false
  )
  const priceDisplayInJourneys = getPriceItemDisplayInJourneys(item)

  const shouldDisplayDetails = useMemo(() => {
    if (priceItemHasComponents(item)) {
      return (
        displayPriceComponents &&
        priceDisplayInJourneys !== 'show_as_on_request'
      )
    }

    return (
      priceDisplayInJourneys !== 'show_as_on_request' &&
      item._price?.variable_price &&
      item.blockMappingData?.numberInput !== undefined
    )
  }, [displayPriceComponents, item, priceDisplayInJourneys])

  const sortedRecurrenceDetails = sortItemRecurrenceDetails(item)

  const hasOnlyOnetimeRecurrences = sortedRecurrenceDetails.every(
    (recurrence) => recurrence.type === 'one_time' || !recurrence.type
  )

  const {
    amountToDisplay,
    discountAmountToDisplay,
    beforeDiscountAmountTotalToDisplay,
    priceType
  } = useMemo(() => {
    /* Placed in a standalone function which will later be used to format discounts as well */
    const formatPrice = (
      value: number | string,
      displayInJourneys?: Price['price_display_in_journeys']
    ) =>
      getDisplayPrice(
        t,
        value,
        displayInJourneys,
        showTrailingDecimalZeros,
        item.currency
      )

    const [mainPrice] = sortedRecurrenceDetails

    const amount = shouldShowDecimals(mainPrice?.amount_total)
      ? mainPrice?.amount_total_decimal
      : mainPrice?.amount_total

    const discountAmount = shouldShowDecimals(mainPrice?.discount_amount)
      ? mainPrice?.discount_amount_decimal
      : mainPrice?.discount_amount

    const beforeDiscountAmountTotal = shouldShowDecimals(
      mainPrice?.before_discount_amount_total
    )
      ? mainPrice?.before_discount_amount_total_decimal
      : mainPrice?.before_discount_amount_total

    const discountPercentage = mainPrice?.discount_percentage

    const priceDisplayInJourneysForDiscounts =
      priceDisplayInJourneys !== 'show_as_starting_price'
        ? priceDisplayInJourneys
        : undefined

    return {
      amountToDisplay: formatPrice(amount, priceDisplayInJourneys),
      discountAmountToDisplay:
        typeof discountPercentage !== 'undefined'
          ? formatPercentage(discountPercentage)
          : typeof discountAmount !== 'undefined'
            ? formatPrice(discountAmount, priceDisplayInJourneysForDiscounts)
            : undefined,
      beforeDiscountAmountTotalToDisplay:
        typeof beforeDiscountAmountTotal !== 'undefined'
          ? formatPrice(
              beforeDiscountAmountTotal,
              priceDisplayInJourneysForDiscounts
            )
          : undefined,
      priceType: !hasOnlyOnetimeRecurrences
        ? t(
            getPriceType(
              mainPrice?.type as Price['type'],
              mainPrice?.billing_period as BillingPeriod
            )
          )
        : undefined
    }
  }, [
    hasOnlyOnetimeRecurrences,
    item.currency,
    priceDisplayInJourneys,
    showTrailingDecimalZeros,
    sortedRecurrenceDetails,
    t
  ])

  return (
    <>
      <Grid
        className={classes.cartItem}
        container
        data-testid={`cart-item-${item._product?._id}:${item._price?._id}`}
        item
        spacing={2}
      >
        <Grid className={classes.imageContainer} item md={3} xl={2} xs={12}>
          <CartItemImage product={item._product} />
        </Grid>
        <Grid item md={6} xs={8}>
          <div>
            <BodyText
              className={classes.productTitle}
              color="textPrimary"
              display="block"
              title={item._product?.name}
            >
              <strong>{item._product?.name}</strong>
            </BodyText>
            <BodyText
              className={classes.priceDescription}
              color="textSecondary"
              title={item._price?.description}
            >
              {item._price?.description}
            </BodyText>
          </div>
        </Grid>
        <Grid item justifyContent="flex-end" md={3} xl={4} xs={4}>
          <div className={classes.priceContainer}>
            <div className={classes.priceInnerContainer}>
              {shouldDisplayDetails && (
                <OpenComponentsButton
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                />
              )}
              <BodyText color="textPrimary" data-testid="cart-item-amount">
                <strong>{amountToDisplay}</strong>
              </BodyText>
            </div>
            <div className={classes.discountInnerContainer}>
              {beforeDiscountAmountTotalToDisplay !== undefined && (
                <BodyText
                  className={classes.priceBeforeDiscount}
                  color="textSecondary"
                  display="block"
                >
                  {beforeDiscountAmountTotalToDisplay}
                </BodyText>
              )}
              {discountAmountToDisplay !== undefined && (
                <BodyText
                  className={classes.discount}
                  color="textSecondary"
                  display="block"
                >
                  -{discountAmountToDisplay}
                </BodyText>
              )}
            </div>
            {typeof priceType !== 'undefined' && (
              <BodyText color="textSecondary" display="block">
                {priceType}
              </BodyText>
            )}
          </div>
        </Grid>
      </Grid>
      <CartItemDetails
        componentDetails={sortedRecurrenceDetails}
        displayPriceComponents={displayPriceComponents}
        displayUnitaryAverage={displayUnitaryAverage}
        hasOnlyOnetimeRecurrences={hasOnlyOnetimeRecurrences}
        isExpanded={isExpanded}
        item={item}
        showTrailingDecimalZeros={showTrailingDecimalZeros}
      />
    </>
  )
}

function OpenComponentsButton({
  isExpanded,
  setIsExpanded
}: {
  isExpanded: boolean
  setIsExpanded: (value: boolean) => void
}) {
  const classes = useCartItemStyles()

  return (
    <Button
      className={classes.openComponentsButton}
      data-testid={'cart-collapse-button'}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <ExpandIcon expanded={isExpanded} />
    </Button>
  )
}
