import {
  Badge,
  BodyText,
  Grid,
  Icon,
  withStyles
} from '@epilot/journey-elements'
import type { TFunction } from 'i18next'
import React from 'react'

import { useStyles } from './styles'

interface CartHeaderProps {
  title?: string
  itemsInCart?: number
  extraComponents?: React.ReactNode[]
  t: TFunction
}

const StyledBadge = withStyles((theme) => ({
  root: {
    marginTop: '-3px',
    marginLeft: '2px'
  },
  badge: {
    right: -15,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    marginRight: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 4,
      marginRight: 0
    }
  }
}))(Badge)

export const CartHeader = ({
  title,
  itemsInCart,
  extraComponents,
  t
}: CartHeaderProps) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" spacing={1}>
      <Grid
        className={classes.badgeContainer}
        container
        item
        justifyContent="space-between"
      >
        <BodyText color="primary" display="inline" paragraph>
          <strong>{title || t('cart.title', 'Shopping Cart')}</strong>
        </BodyText>
        {Boolean(itemsInCart) && (
          <StyledBadge badgeContent={itemsInCart} color="secondary">
            <Icon color="primary" name="shopping_cart" />
          </StyledBadge>
        )}
      </Grid>
      {extraComponents?.map((component, index) => (
        <Grid item key={`header_${index}`}>
          {component}
        </Grid>
      ))}
    </Grid>
  )
}
