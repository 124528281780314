import type { ComponentType } from 'react'

import { Card } from '../../components'

import { TileBody } from './components/TileBody'
import { TileFooter } from './components/TileFooter'
import { TileHeader } from './components/TileHeader'
import { TileSections } from './components/TileSections'
import classes from './styles/CatalogProductTile.module.scss'
import type {
  ComponentMappingDataGraduatedProps,
  GetAgComponentProps,
  ProductDetailsModalProps,
  NormalizedSectionContainerProps
} from './types'
import { CatalogTileContext, useTileContext } from './utils/tile-context-utils'
import type { TileContext } from './utils/tile-context-utils'

interface CatalogProductTileProps {
  GetAgComponent: ComponentType<GetAgComponentProps>
  ProductDetailsModalComponent: ComponentType<ProductDetailsModalProps>
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
  NormalizedSectionContainer: ComponentType<NormalizedSectionContainerProps>
  context: TileContext
  IMAGE_PREVIEW_API_URL: string
}

const ProductTileWithoutContext = ({
  GetAgComponent,
  ProductDetailsModalComponent,
  ComponentMappingDataGraduated,
  NormalizedSectionContainer,
  IMAGE_PREVIEW_API_URL
}: CatalogProductTileProps) => {
  const {
    config: { isFeatured, featuredLabel, featuredColor, featuredLabelColor },
    tileVariantsEnabled
  } = useTileContext()

  return (
    <section aria-labelledby="tileTitle" className={classes.tileWrapper}>
      {tileVariantsEnabled ? (
        <Card
          className={classes.tileLayoutCard}
          featuredColor={featuredColor}
          featuredLabelColor={featuredLabelColor}
          featuredText={featuredLabel}
          isFeatured={isFeatured}
        >
          <TileSections
            ComponentMappingDataGraduated={ComponentMappingDataGraduated}
            GetAgComponent={GetAgComponent}
            IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL}
            ProductDetailsModalComponent={ProductDetailsModalComponent}
          />
        </Card>
      ) : (
        <NormalizedSectionContainer sectionName="concordeTile">
          <Card
            className={classes.tileContent}
            featuredColor={featuredColor}
            featuredLabelColor={featuredLabelColor}
            featuredText={featuredLabel}
            isFeatured={isFeatured}
          >
            <>
              <TileHeader
                IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL}
                NormalizedSectionContainer={NormalizedSectionContainer}
              />
              <TileBody
                ComponentMappingDataGraduated={ComponentMappingDataGraduated}
                GetAgComponent={GetAgComponent}
                NormalizedSectionContainer={NormalizedSectionContainer}
              />
              <TileFooter
                NormalizedSectionContainer={NormalizedSectionContainer}
                ProductDetailsModalComponent={ProductDetailsModalComponent}
              />
            </>
          </Card>
        </NormalizedSectionContainer>
      )}
    </section>
  )
}

export const CatalogProductTile = ({
  GetAgComponent,
  ProductDetailsModalComponent,
  ComponentMappingDataGraduated,
  context,
  NormalizedSectionContainer,
  IMAGE_PREVIEW_API_URL
}: CatalogProductTileProps) => {
  return (
    <CatalogTileContext.Provider value={context}>
      <ProductTileWithoutContext
        ComponentMappingDataGraduated={ComponentMappingDataGraduated}
        GetAgComponent={GetAgComponent}
        IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL}
        NormalizedSectionContainer={NormalizedSectionContainer}
        ProductDetailsModalComponent={ProductDetailsModalComponent}
        context={context}
      />
    </CatalogTileContext.Provider>
  )
}

CatalogProductTile.displayName = 'CatalogProductTile'
