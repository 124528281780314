/**
 * Clean options from enum.
 */
export function cleanOptionsFromEnum(
  options: any[] | undefined, // this can be actually any type in the array
  enumOptions: string[] | undefined
) {
  return options?.filter((_label, idx) => enumOptions?.[idx])
}

/**
 * Appends a string to the field id
 */
export function appendToId(id?: string, text?: string) {
  return `${id || ''}.${text?.trim().replace(/\s/g, '_').replace(/\./g, '')}`
}
