import {
  Card,
  CardContent,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import type {
  PriceInputMappings,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  getExternalFeesMappings,
  getPriceMappings
} from '@epilot/journey-logic-commons'
import { computeAggregatedAndPriceTotals } from '@epilot/pricing'
import type { TFunction } from 'i18next'
import { useMemo } from 'react'

import { sortRecurrences } from '../../utils/products/sortItemsRecurrenceDetails'

import { CartBody } from './components/CartBody'
import { CartFooter } from './components/CartFooter'
import { CartHeader } from './components/CartHeader'
import type {
  CatalogShoppingCartProps,
  PriceItemWithBlockConfiguration,
  ShoppingCartData
} from './types'

const useStyles = makeStyles((theme) =>
  createStyles({
    cart: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '350px',
        padding: '0.75rem'
      },
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    cartContent: {
      [theme.breakpoints.down('sm')]: {
        padding: 12
      }
    }
  })
)

/**
 * Gets the cart items to display. The returned quantity is the one comming from the tile. The number input block value is not considered for visualization.
 */
const getDisplayableShoppingCartItems = (
  items?: PriceItemWithBlockConfiguration[],
  data?: ShoppingCartData
): PriceItemWithBlockConfiguration[] => {
  if (!items || !data) {
    return []
  }

  return items.map((item) => {
    const tileInputData = data?.find((productData) => {
      return (
        productData?.price._id &&
        productData?.price._id === item?._price?._id &&
        productData?.product?._id &&
        productData?.product._id === item?._product?._id
      )
    })

    return {
      ...item,
      quantity: tileInputData?.quantity || 1
    }
  })
}

const fallbackTranslate: TFunction = ((key, defaultValue) =>
  defaultValue || key) as TFunction

const DEFAULT_RECURRENCE_AMOUNTS: Array<RecurrenceAmount> = []

export const CatalogShoppingCart = (props: CatalogShoppingCartProps) => {
  const classes = useStyles()
  const t = props.t ?? fallbackTranslate

  const {
    enabled: closeButtonEnabled = false,
    onClickHandler: closeButtonOnClickHandler,
    label: closeButtonLabel = ''
  } = props.config?.closeButtonConfig || {}

  const itemsCount: number =
    props.data?.reduce((count, { quantity }) => count + quantity, 0) ?? 0

  const aggregatedPrices = useMemo(() => {
    const shoppingCartItems = props.data?.map((item) => {
      const blockMappings: PriceInputMappings = getPriceMappings(item)
      const externalFeesMappings = getExternalFeesMappings(item.price)

      return {
        ...item.price,
        quantity: item.quantity || 1,
        _price: item.price,
        _product: item.product,
        _coupons: item.coupons,
        blockConfiguration: item.blockConfiguration,
        ...(blockMappings.length > 0 && { price_mappings: blockMappings }),
        ...(externalFeesMappings.length > 0 && {
          external_fees_mappings: externalFeesMappings
        })
      }
    })

    return computeAggregatedAndPriceTotals(shoppingCartItems ?? [])
  }, [props.data])

  const sortedAggregatedPricesRecurrencesWithCurrencies = useMemo(
    () =>
      aggregatedPrices.total_details?.breakdown?.recurrences?.sort(
        sortRecurrences
      ) ?? DEFAULT_RECURRENCE_AMOUNTS,
    [aggregatedPrices]
  )

  const displayableShoppingCartItems = useMemo(
    () =>
      getDisplayableShoppingCartItems(
        aggregatedPrices.items as PriceItemWithBlockConfiguration[],
        props.data
      ),
    [aggregatedPrices?.items, props.data]
  )

  return (
    <Card className={classes.cart}>
      <CardContent className={classes.cartContent}>
        <CartHeader
          extraComponents={props.config?.content?.header?.injectComponents}
          itemsInCart={itemsCount}
          t={t}
          title={props.config?.content?.header?.title}
        />
        <CartBody
          currency={aggregatedPrices.currency}
          displayPriceComponents={props.config?.displayPriceComponents ?? true}
          displayUnitaryAverage={props.config?.displayUnitaryAverage ?? true}
          forceExpandedItemDetails={props.config?.forceExpandedItemDetails}
          items={displayableShoppingCartItems}
          showTrailingDecimalZeros={props.config?.showTrailingDecimalZeros}
          sortedAggregatedPrices={
            sortedAggregatedPricesRecurrencesWithCurrencies
          }
          t={t}
        />
        <CartFooter
          closeButtonEnabled={closeButtonEnabled}
          closeButtonLabel={closeButtonLabel}
          closeButtonOnClickHandler={closeButtonOnClickHandler}
          extraComponents={props.config?.content?.footer?.injectComponents}
          t={t}
          title={props.config?.content?.footer?.title}
        />
      </CardContent>
    </Card>
  )
}
