import {
  Button,
  CardContent,
  Drawer,
  Hidden,
  Typography,
  makeStyles
} from '@epilot/journey-elements'
import type {
  ShoppingCartDataItem,
  Price,
  Product,
  CompositePrice,
  SelectedProductTile
} from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { Layout, LayoutProps } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsControlProps } from '@jsonforms/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CatalogShoppingCart from '../../components/CatalogShoppingCart'
import { useStepBlockId } from '../../utils'
import { useJourneyContext } from '../../utils/context/JourneyContext'
import { IsMobile } from '../../utils/helper'

const generateProductPackage = (productInfo: SelectedProductTile[]) => {
  const productPackage: Product[] = []

  productInfo &&
    productInfo.forEach((item) => {
      const quantity = item?.quantity
      const product = item?.product

      if (product && quantity) {
        const productImages = (product as any)?.selectionMetadata
          ?.selectedProduct?.product_images
        const productImage = productImages
          ? productImages[0]?.public_url
          : undefined

        for (let i = 1; i <= quantity; i++) {
          productPackage.push({ ...product, productImage: productImage })
        }
      }
    })

  return productPackage
}

export type ShopCartControlOption = {
  cartTitle: string
  cartFootnote: string
  stickyOnMobile?: boolean
  stickyOnMobileIndex?: number
  collapsedString?: string
  collapsedCloseString?: string
  displayPriceComponents?: boolean
  displayUnitaryAverage?: boolean
  showTrailingDecimalZeros?: boolean
}

function ShopCartControl({
  visible,
  uischema,
  enabled,
  renderers,
  path,
  schema
}: LayoutProps) {
  const [expanded, setExpanded] = useState(false)

  const stepBlockId = useStepBlockId(path)
  const { elements } = (uischema as Layout) || {}
  const { t: defaultT } = useTranslation()
  const {
    cartTitle,
    stickyOnMobile = false,
    collapsedString = defaultT(
      'show_products_in_cart_num',
      'Produkte im Warenkorb ansehen (%s)'
    ) as string,
    collapsedCloseString = defaultT('close', 'Schließen') as string,
    cartFootnote,
    displayPriceComponents,
    displayUnitaryAverage,
    showTrailingDecimalZeros
  } = (uischema?.options as ShopCartControlOption) || {}

  const { context } = useJourneyContext()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const data = context._shoppingCart as Record<
    string,
    SelectedProductTile | SelectedProductTile[]
  >

  const products = useMemo(
    () =>
      (data ? Object.values(data) : [])
        .flatMap((value) => value)
        .filter(Boolean),
    [data]
  )

  const catalogShoppingCartData = useMemo(
    () =>
      products.map<ShoppingCartDataItem>((product) => ({
        _id: product.product?.selectedPriceId || '',
        quantity: product.quantity,
        product: product.product?.selectionMetadata?.selectedProduct,
        price: product.product?.selectionMetadata?.selectedPrice as
          | Price
          | CompositePrice,
        coupons: product.product.selectionMetadata?.selectedCoupons,
        blockConfiguration:
          product.product?.selectionMetadata?.blockConfiguration
      })),
    [products]
  )

  const selectedProducts = useMemo(
    () => generateProductPackage(products),
    [products]
  )

  const mobile = IsMobile()
  const classes = useStyles()

  if (uischema.options?.display === false) return null

  const cartFooterComponents = elements?.map((e, index) => (
    <JsonFormsDispatch
      enabled={enabled}
      key={index}
      path={path}
      renderers={renderers}
      schema={schema}
      uischema={e}
    />
  ))

  const CollapsedMenu = ({
    children,
    shouldBeVisible
  }: React.PropsWithChildren<{ shouldBeVisible: boolean }>) => {
    if (!shouldBeVisible) {
      return <>{children}</>
    }

    return (
      <>
        <Button
          fullWidth
          id={appendStepBlockId(stepBlockId, 'Expand Product Cart')}
          onClick={() => setExpanded(true)}
          size="large"
        >
          {collapsedString.replace('%s', '' + selectedProducts.length)}
        </Button>
        <Drawer
          anchor="bottom"
          onClose={() => setExpanded(false)}
          open={expanded}
        >
          <CardContent>{children}</CardContent>
          <Button
            fullWidth
            id={appendStepBlockId(stepBlockId, 'Collapse Product Cart')}
            onClick={() => setExpanded(false)}
            size="large"
          >
            <Typography className={classes.title} color="primary">
              <b>{collapsedCloseString}</b>
            </Typography>
          </Button>
        </Drawer>
      </>
    )
  }

  return (
    <Hidden xsUp={!visible}>
      <CollapsedMenu shouldBeVisible={mobile && stickyOnMobile}>
        <CatalogShoppingCart
          config={{
            content: {
              header: {
                title: cartTitle
              },
              footer: {
                title: cartFootnote,
                injectComponents: cartFooterComponents
              }
            },
            closeButtonConfig: {
              label: collapsedCloseString,
              onClickHandler: () => setExpanded(false)
            },
            displayPriceComponents,
            displayUnitaryAverage,
            showTrailingDecimalZeros
          }}
          data={catalogShoppingCartData}
          t={t}
        />
      </CollapsedMenu>
    </Hidden>
  )
}

ShopCartControl.displayName = 'ShopCartControl'

export default withJsonFormsControlProps(ShopCartControl as never)

const useStyles = makeStyles({
  title: {
    textDecorationLine: 'underline'
  }
})
