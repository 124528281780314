import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'

import type { ConfirmationMessageComponentProps } from './components/ConfirmationMessageComponent'
import { ConfirmationMessageComponent } from './components/ConfirmationMessageComponent'

export type ConfirmationMessageControlOptions =
  ConfirmationMessageComponentProps

function ConfirmationMessageControl({ uischema, path }: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const props = (uischema?.options || {}) as ConfirmationMessageControlOptions

  if (uischema.options?.display === false) return null

  return <ConfirmationMessageComponent {...props} id={stepBlockId} />
}

export default withJsonFormsControlProps(ConfirmationMessageControl as never)
