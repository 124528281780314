import type {
  Client as AddressSuggestionsClient,
  Paths
} from '@epilot/address-suggestions-client'
import * as AddressSuggestionsSDK from '@epilot/address-suggestions-client'

let client: AddressSuggestionsClient

export type AvailabilityUserFiltersValue = {
  countryCode: string
  zipCode: string
  city: string
  streetName: string
  streetNumber: string
}

type CheckAvailabilityParams = Paths.CheckAvailability.QueryParameters

export type CheckAvailability = (
  fileId: string,
  userFiltersValue?: AvailabilityUserFiltersValue
) => Promise<boolean>

export function initClient(
  serviceUrl: string,
  orgId: string,
  publicToken: string
) {
  client = AddressSuggestionsSDK.getClient()
  client.defaults.baseURL = serviceUrl
  client.defaults.headers['X-Ivy-Org-ID'] = orgId
  client.defaults.headers['X-Epilot-Org-ID'] = orgId
  client.defaults.headers['Authorization'] = `Bearer ${publicToken}`
}

/**
 * Check availability for a specific address.
 *
 * @param organizationId The organization id.
 * @param fileId The file id with the available addresses.
 * @param liveValue The live value (filters) to perform an availability check.
 * @returns If the address is available, given the provided filters.
 */
export const availabilityCheck: CheckAvailability = async (
  fileId,
  userFiltersValue
) => {
  if (!client) {
    throw Error('AddressSuggestionsClient has not been initialized!')
  }

  if (!fileId || !userFiltersValue?.zipCode) {
    return false
  }

  const countryCode = userFiltersValue?.countryCode || 'DE'
  const city = userFiltersValue?.city ? ` ${userFiltersValue?.city}` : ''
  const postalCode = userFiltersValue?.zipCode
    ? `${userFiltersValue?.zipCode}${city}`
    : ''
  const street =
    postalCode && userFiltersValue?.streetName
      ? userFiltersValue?.streetName
      : ''
  const streetNumber =
    postalCode && street && userFiltersValue?.streetNumber
      ? userFiltersValue?.streetNumber
      : ''

  const params: CheckAvailabilityParams = {
    files: fileId,
    countryCode,
    postalCode,
    street,
    streetNumber
  }

  try {
    const { data } = await client.checkAvailability(
      Object.entries(params).map(([key, value]) => ({
        name: key,
        value,
        in: 'query'
      }))
    )

    return data.fileId === fileId
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`availabilityCheck failed with error`, error)

    return false
  }
}
