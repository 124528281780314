import type { Price, PriceItem } from '@epilot/journey-logic-commons'
import { PricingModel } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { ComponentMappingDataGraduated } from '../ComponentMappingDataGraduated'

import { ComponentMappingDataFlatFee } from './ComponentMappingDataFlatFee'
import { ComponentMappingDataGetAg } from './ComponentMappingDataGetAg'
import { ComponentMappingDataPerUnit } from './ComponentMappingDataPerUnit'
import { ComponentMappingDataVolume } from './ComponentMappingDataVolume'

interface ComponentMappingDataProps {
  displayUnitaryAverage?: boolean
  itemQuantity: number | undefined
  component: PriceItem
  t: TFunction
  showTrailingDecimalZeros?: boolean
}

export const ComponentMappingData = ({
  displayUnitaryAverage,
  itemQuantity,
  component,
  t,
  showTrailingDecimalZeros
}: ComponentMappingDataProps) => {
  return (
    <>
      {itemQuantity && itemQuantity > 1 && <span>{itemQuantity} * </span>}
      {component.pricing_model === PricingModel.perUnit && (
        <ComponentMappingDataPerUnit
          item={component}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
        />
      )}
      {component.pricing_model === PricingModel.externalGetAG && (
        <ComponentMappingDataGetAg
          item={component}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
        />
      )}
      {component.pricing_model === PricingModel.tieredGraduated && (
        <ComponentMappingDataGraduated
          displayUnitaryAverage={displayUnitaryAverage ?? true}
          price={component._price as Price}
          quantity={itemQuantity}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
        />
      )}
      {component.pricing_model === PricingModel.tieredVolume && (
        <ComponentMappingDataVolume
          item={component}
          quantity={itemQuantity}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
        />
      )}
      {component.pricing_model === PricingModel.tieredFlatFee && (
        <ComponentMappingDataFlatFee
          item={component}
          /**
           * @todo itemQuantity passed by parent could be undefined,
           * should ensure in parent that it is always a number,
           * as it is necessary for computation of price
           */
          quantity={itemQuantity!}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
        />
      )}
    </>
  )
}
