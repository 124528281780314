import type { datadogRum as _datadogRum } from '@datadog/browser-rum'
import areEqual from 'fast-deep-equal'
import type { To } from 'history'
import { createMemoryHistory } from 'history'
import { useEffect, useState } from 'react'

import { usePrevious } from '../usePrevious'

import {
  dispatchUserEvent,
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE
} from './dispatchUserEvent'
import { prepareTrackingData } from './prepareTrackingData'
import type {
  HistoryLocationState,
  HistoryStack,
  HistoryStackState
} from './types'
import { getIndex, getIsTrackingDisabled, mergeStack, toPath } from './utils'

const history = createMemoryHistory()

/**
 * Wrapped history.push method dash-casing passed path
 */
const push: HistoryStackState['push'] = (
  to: To,
  state: HistoryLocationState
) => {
  return history.push(toPath(to), state)
}

/**
 * Wrapped history.replace method dash-casing passed path
 */
const replace: HistoryStackState['replace'] = (
  to: To,
  state: HistoryLocationState
) => {
  return history.replace(toPath(to), state)
}

/**
 * Wrapped history.go method taking the wanted index, instead of the delta
 */
const go = (desiredStepIndex: number) => {
  const historyStack = (history.location?.state as HistoryLocationState)?.stack
  const currentIndex = historyStack?.length

  if (typeof currentIndex !== 'number') {
    console.error(
      'useHistoryStack -> Could not retrieve current index from history. No action executed'
    )

    return
  }

  // NOTE: calculating the delta can NOT be simply: delta = (currentIndex - desiredStepIndex) * -1
  // due of branching, the desired step index may not reflect the actual index in the histpry stack
  // also note that currentIndex != currentSTEPindex

  const desiredStepIndexInStack = historyStack.findIndex(
    (his) => his.stepIndex === desiredStepIndex
  )
  const delta = (currentIndex - desiredStepIndexInStack) * -1

  if (!delta) {
    // delta = 0 -> stay on step, no history change required
    return
  }

  return history.go(delta)
}

/**
 * Hook to provide navigation. Internally uses browser history API with the differences:
 * - Does not expose forward method
 * - All methods exposed take a string, and internally transforms it to dash-case in lowercase
 * - Expose a stack history.
 */
export const useHistoryStack = (
  initialValues: {
    stepName: string
    stepIndex: number
  },
  scrollToThePageTop: () => void,
  journeyId: string,
  initialHistoryStack?: HistoryStack[],
  options?: {
    scrollIntoView?: boolean
    forceInitialize?: boolean
    isTrackingDisabled?: boolean
  },
  datadogRum?: typeof _datadogRum
): HistoryStackState => {
  const [stack, setStack] = useState<HistoryStack[]>(
    Array.isArray(initialHistoryStack) && initialHistoryStack.length > 0
      ? initialHistoryStack
      : []
  )

  const initialHistoryStackPrev = usePrevious(initialHistoryStack)

  useEffect(() => {
    if (
      initialHistoryStack &&
      initialHistoryStack.length > 0 &&
      !areEqual(initialHistoryStackPrev, initialHistoryStack)
    ) {
      setStack(initialHistoryStack)
    }
  }, [initialHistoryStack, initialHistoryStackPrev])

  const {
    scrollIntoView,
    forceInitialize = false,
    isTrackingDisabled
  } = options || {}

  const initialValuesPrev = usePrevious(initialValues)
  const initialIsTrackingDisabled = usePrevious(isTrackingDisabled)

  // sets up event listener reacting to location changes in order to modify stack
  useEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      // do not execute if journey hasnt loaded yet
      if (!journeyId && !forceInitialize) return

      const index = getIndex(location)
      const isTrackingDisabled = getIsTrackingDisabled(location)

      if (typeof index !== 'number') {
        console.error(
          'useHistoryStack -> invalid state value passed. Expected index to be of type number'
        )

        return
      }
      // Log Data to DataDog
      datadogRum?.startView(location.pathname)

      if (!isTrackingDisabled) {
        const historyStata = location.state as HistoryLocationState

        prepareTrackingData(historyStata.userValues, historyStata.steps).then(
          (data: any) => {
            dispatchUserEvent(
              EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PAGE_VIEW,
              journeyId,
              {
                path: `epilot-journey/${journeyId}/step-${index + 1}-${
                  location.pathname
                }`,
                userValues: data || {}
              }
            )
          }
        )
      }

      if (action === 'PUSH' && scrollIntoView) {
        // Scroll to the top on loading NEW step
        scrollToThePageTop()
      }

      // if item found, and there are items later in the list, remove them
      setStack((stackCurrent) => {
        return mergeStack(stackCurrent, location, index)
      })
    })

    return () => unlisten()
  }, [scrollIntoView, journeyId])

  // set initial value
  useEffect(() => {
    if (
      (initialValues && !areEqual(initialValues, initialValuesPrev)) ||
      isTrackingDisabled !== initialIsTrackingDisabled
    ) {
      replace(initialValues.stepName, {
        stepIndex: initialValues.stepIndex,
        isTrackingDisabled
      })
    }
  }, [
    initialValues,
    initialValuesPrev,
    isTrackingDisabled,
    initialIsTrackingDisabled
  ])

  return {
    ...history,
    push,
    replace,
    go,
    stack,
    currentIndex: getIndex(history.location) || 0
  }
}
