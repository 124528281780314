import { Grid, Rating, Icon, InlineSpacing } from '@epilot/journey-elements'
import { useEffect, useMemo, useState } from 'react'

import { IsMobile } from '../../../../utils/helper'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import type { NumberInputFormProps, NumberInputFormValues } from '../types'
import { generatePlaceholderBasedOnFormat } from '../utils'

import { PureNumberInput } from './PureNumberInput'

export const NumberInputForm = ({
  hasError,
  handleChange,
  path,
  data,
  fields,
  isRequired,
  suggestions,
  iconInputName,
  dataTestId,
  input_icon,
  id
}: NumberInputFormProps) => {
  const [iconIndex, setIconIndex] = useState(-1)
  const { control, setValue } = useFormHandler<NumberInputFormValues>({
    handleChange,
    path,
    fields,
    data,
    isRequired,
    formProps: {
      defaultValues: data?.numberInput
        ? {
            numberInput: data?.numberInput
          }
        : undefined
    }
  })

  const mobile = IsMobile()

  useEffect(() => {
    suggestions.length > 0 &&
      setIconIndex(
        suggestions.findIndex(
          (ss) =>
            ss?.value?.replace(',', '.') ===
            data?.numberInput?.replace(',', '.')
        )
      )
  }, [suggestions, data?.numberInput])

  const placeholder = useMemo(
    () =>
      generatePlaceholderBasedOnFormat(
        fields.numberInput.format?.digitsBeforeDecimalPoint,
        fields.numberInput.format?.decimalPlaces
      ),
    [
      fields.numberInput.format?.decimalPlaces,
      fields.numberInput.format?.digitsBeforeDecimalPoint
    ]
  )

  const PureInput = (
    <PureNumberInput
      control={control}
      fields={fields}
      hasError={hasError}
      id={id}
      input_icon={input_icon}
      isRequired={isRequired}
      path={path}
      placeholder={placeholder}
      suggestions={suggestions}
    />
  )

  if (suggestions.length > 0) {
    const rating = iconInputName && (
      <Rating
        component={<Icon fontSize="large" name={iconInputName} />}
        labels={suggestions.map((e) => e.label)}
        max={suggestions.length <= 5 ? suggestions.length : 5}
        onIconClick={(index: number) => {
          setValue('numberInput', suggestions[index].value)
          setValue('numberUnit', data?.numberUnit)
          handleChange(path, {
            numberInput: suggestions[index].value,
            numberUnit: data?.numberUnit,
            _isValid: true
          })
          setIconIndex(index)
        }}
        selectedIndex={iconIndex}
      />
    )

    return (
      <Grid container data-testid={dataTestId}>
        {mobile ? (
          <>
            <Grid item xs={12}>
              {rating}
            </Grid>
            <Grid item xs={12}>
              {PureInput}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <InlineSpacing alignItems="center" scale={2}>
              {rating}
              {PureInput}
            </InlineSpacing>
          </Grid>
        )}
      </Grid>
    )
  } else {
    return (
      <Grid container>
        <Grid item xs={12}>
          {PureInput}
        </Grid>
      </Grid>
    )
  }
}
