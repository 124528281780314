/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import {
  Link,
  LinkProps,
  Typography,
  TypographyProps
} from '@epilot/journey-elements'
import {
  CONTROL_NAME,
  getParagraphBlockString,
  ParagraphBlockStringType
} from '@epilot/journey-logic-commons'
import type { Source } from '@epilot/journey-logic-commons'
import { formatPriceUnit } from '@epilot/pricing'
import { createHeadlessEditor } from '@lexical/headless'
import { $generateHtmlFromNodes } from '@lexical/html'
import { LinkNode, AutoLinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import parse, { domToReact, Element, Text } from 'html-react-parser'
import type { HTMLReactParserOptions, DOMNode } from 'html-react-parser'
import type { TFunction } from 'i18next'
import { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import type { TransformOptions } from 'react-markdown/src/ast-to-react'

import { getBlockDataFormat } from './getBlockDataFormat'
import { toDateString } from './helper'
import { MentionNode } from './nodes/MentionNode'

/**
 * String to HTML
 * @param str the source string
 * @returns either a string or a ReactComponent for the MD
 */
export function stringToHTML(
  str: string,
  options?: StringToHtmlOptions,
  replacementArray?: Record<string, any>[],
  journeySources?: Source[],
  t?: TFunction
) {
  if (options?.isPlainText) {
    return str
  }

  // if not plain text -> validate and apply base64 transformations
  const par = getParagraphBlockString(str)

  if (par.type === ParagraphBlockStringType.BASE64_MARKDOWN) {
    try {
      const decoded = par.value

      return <>{mdToHTML(decoded, options)}</>
    } catch (err) {
      return str
    }
  } else if (par.type === ParagraphBlockStringType.BASE64_LEXICAL) {
    const editor = createHeadlessEditor({
      namespace: 'MyEditor',
      onError: console.error,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        MentionNode,
        LinkNode,
        AutoLinkNode,
        QuoteNode
      ]
    })

    editor.setEditorState(editor.parseEditorState(JSON.parse(par.value)))
    let htmlString = ''

    try {
      htmlString = editor
        .getEditorState()
        .read(() => $generateHtmlFromNodes(editor, null))
    } catch (err) {
      htmlString = ''
    }

    // using html-react-parser to parse the html string
    // it is safer than using dangerouslySetInnerHTML
    return (
      <>
        {parse(
          htmlString,
          getHtmlParserOptions(options, replacementArray, journeySources, t)
        )}
      </>
    )
  }

  return str
}

/*
 * this one is considered legacy, older journey used to have MD encoded in base64 in the paragraph block string
 * therefore do not remove this function
 */
export function mdToHTML(str: string, options?: StringToHtmlOptions) {
  return (
    <ReactMarkdown components={getComponentsMapping(options)}>
      {str}
    </ReactMarkdown>
  )
}

export type StringToHtmlOptions = {
  allowParagraphs?: boolean
  isPlainText?: boolean
}

/*
 * this one is considered legacy, older journey used to have MD encoded in base64 in the paragraph block string
 * therefore do not remove this function
 */
function getComponentsMapping(
  options = { allowParagraphs: false } as StringToHtmlOptions
): TransformOptions['components'] {
  return {
    ...(options.allowParagraphs
      ? {}
      : { p: ({ ...props }) => <span {...(props as any)} /> }),
    a: ({ node: _n, ...props }) => (
      <Link target="_blank" {...(props as LinkProps)} />
    ),
    h1: ({ node: _n, ...props }) => (
      <Typography variant="h4" {...(props as TypographyProps)} />
    ),
    h2: ({ node: _n, ...props }) => (
      <Typography variant="h5" {...(props as TypographyProps)} />
    ),
    h3: ({ node: _n, ...props }) => (
      <Typography variant="h6" {...(props as TypographyProps)} />
    ),
    h4: ({ node: _n, ...props }) => (
      <Typography variant="h4" {...(props as TypographyProps)} />
    ),
    h5: ({ node: _n, ...props }) => (
      <Typography variant="h5" {...(props as TypographyProps)} />
    ),
    h6: ({ node: _n, ...props }) => (
      <Typography variant="h6" {...(props as TypographyProps)} />
    )
  }
}

function getHtmlParserOptions(
  options = { allowParagraphs: false } as StringToHtmlOptions,
  replacementArray?: Record<string, any>[],
  journeySources?: Source[],
  t?: TFunction
): HTMLReactParserOptions {
  const replace = (domNode: DOMNode) => {
    {
      if (domNode instanceof Element) {
        if (domNode?.name === 'p') {
          const children = domNode?.children.map((child, index) => {
            if (child instanceof Element) {
              return (
                <Fragment key={index}>
                  {renderReplacement(
                    child,
                    replacementArray,
                    journeySources,
                    t
                  )}
                </Fragment>
              )
            }

            return null
          })

          const attribs = { ...domNode.attribs } as Record<string, unknown>

          try {
            attribs.style = convertStringToJSS(attribs.style as string)
          } catch {
            attribs.style = {}
          }

          return options.allowParagraphs ? (
            <p {...attribs}>{children}</p>
          ) : (
            <span {...attribs}>{children}</span>
          )
        }

        if (domNode.name === 'ul' || domNode.name === 'ol') {
          // Check the children of the ul/ol elements for listItems with nested lists
          const children = domNode?.children.map((child, index) => {
            if (child instanceof Element) {
              if (child.name === 'li') {
                const hasNestedList = child.children.some(
                  (nestedChild) =>
                    nestedChild instanceof Element &&
                    (nestedChild.name === 'ul' || nestedChild.name === 'ol')
                )

                if (hasNestedList) {
                  // Attach class with scoped styling to remove marker for paragraph block
                  return (
                    <li className="nested-list" key={index}>
                      {domToReact(child.children, { replace })}
                    </li>
                  )
                }
              }

              return (
                <Fragment key={index}>
                  {renderReplacement(
                    child,
                    replacementArray,
                    journeySources,
                    t
                  )}
                </Fragment>
              )
            }

            return null
          })

          return <domNode.name>{children}</domNode.name>
        }

        return renderReplacement(domNode, replacementArray, journeySources, t)
      }

      return domNode
    }
  }

  return {
    replace
  }
}

function convertStringToJSS(styleString: string) {
  const jssObject: Record<string, string> = {}

  // Helper function to convert kebab-case to camelCase
  function toCamelCase(str: string) {
    return str.replace(/-([a-z])/g, (_match, letter) => letter.toUpperCase())
  }

  // Split the styleString by semicolon and iterate through the style declarations
  styleString.split(';').forEach((declaration) => {
    // Split each declaration by colon to separate property and value
    const [property, value] = declaration.split(':').map((str) => str.trim())

    // If both property and value exist, add them to the jssObject with camelCase property names
    if (property && value) {
      const camelCaseProperty = toCamelCase(property)

      jssObject[camelCaseProperty] = value
    }
  })

  return jssObject
}

function renderReplacement(
  domNode: Element,
  replacementArray?: Record<string, any>[],
  journeySources?: Source[],
  t?: TFunction
): JSX.Element {
  // deal with mention
  if (
    domNode?.name === 'span' &&
    domNode?.attribs?.['data-lexical-mention'] === 'true'
  ) {
    return parseMatchFromDOMNode(domNode, replacementArray, journeySources, t)
  }

  const children = domNode?.children.map((child) => {
    if (child instanceof Element) {
      return renderReplacement(child, replacementArray, journeySources, t)
    }

    return null
  })

  if (domNode?.name === 'a') {
    return (
      <Link target="_blank" {...domNode.attribs}>
        {children}
      </Link>
    )
  }

  if (domNode?.name === 'h1') {
    return <Typography variant="h4">{children}</Typography>
  }

  if (domNode?.name === 'h2') {
    return <Typography variant="h5">{children}</Typography>
  }

  if (domNode?.name === 'h3') {
    return <Typography variant="h6">{children}</Typography>
  }

  if (domNode?.name === 'h4') {
    return <Typography variant="caption">{children}</Typography>
  }

  if (domNode.name === 'ol') {
    return <ol>{children}</ol>
  }

  if (domNode.name === 'ul') {
    return <ul>{children}</ul>
  }

  if (domNode.name === 'li') {
    return <li {...domNode.attribs}>{children}</li>
  }

  return domToReact([domNode]) as JSX.Element
}

function parseMatchFromDOMNode(
  domNode: Element,
  replacementArray?: Record<string, any>[],
  journeySources?: Source[],
  t?: TFunction
) {
  const match =
    domNode.children[0] instanceof Text ? domNode.children[0]?.data : ''
  const info = match?.split('/')
  // using replace here to keep backward compatibility
  const stepNumber = info?.[0]?.replace('{{', '')
  const stepIndex = stepNumber ? +stepNumber - 1 : 0
  const blockname = info?.[1]?.replace('}}', '')
  const fieldName = info?.[2]?.replace('}}', '')
  const blockValue = replacementArray?.[stepIndex]?.[blockname]

  if (blockname && blockValue) {
    // if there is a field, then replace using the field, otherwise replace with the block
    // the address block zipCity is a special case
    let fieldValue =
      fieldName === 'zipCity'
        ? `${blockValue?.['zipCode'] || ''} ${blockValue?.['city'] || ''}`
        : blockValue?.[fieldName]

    const blockSource = journeySources?.find(
      (js) => js.stepIndex === stepIndex && js.name === blockname
    )

    const blockTypeForRender = blockSource?.controlNameId

    if (blockTypeForRender === CONTROL_NAME.NUMBER_INPUT_CONTROL) {
      const unit = formatPriceUnit(blockValue?.numberUnit, true)
      const numberInput = blockValue?.numberInput || 0
      const unitSuffix = numberInput > 1 ? '_plural' : ''

      blockValue.numberUnit = t
        ? t(`units.${unit}${unitSuffix}`, unit)
        : blockValue?.numberUnit
    }

    if (fieldName && fieldValue) {
      // check if the value of fieldValue is a valid date
      if (blockTypeForRender === CONTROL_NAME.DATE_PICKER) {
        try {
          fieldValue = `${toDateString(fieldValue)}`
        } catch {
          fieldValue = ''
        }
      }

      return <span>{`${fieldValue}`}</span>
    } else if (!fieldName) {
      return <span>{getBlockDataFormat(blockValue, blockTypeForRender)}</span>
    }
  }

  return <></>
}
