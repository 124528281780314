import {
  Grid,
  IconButton,
  Divider,
  Icon,
  Typography
} from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import React from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'

import { useConsumptionEntryStyles } from '../styles'
import type { InputCalculatorFormValues, HydratedDeviceOption } from '../types'

import { ConsumptionCalculation } from './ConsumptionCalculation'
import { DeviceSelectionField } from './DeviceSelectionField'
import { QuantityField } from './QuantityField'
import { UnitaryDemandField } from './UnitaryDemandField'

type ConsumptionEntryProps = {
  hasError: boolean
  path: string
  t: TFunction
  unit: string
  index: number
  setValue: UseFormSetValue<InputCalculatorFormValues>
  isMobile?: boolean
  remove: (index: number) => void
  control: Control<InputCalculatorFormValues>
  consumptionOptions: HydratedDeviceOption[]
  digitsAfterDecimalPoint?: number
  id: string
  showDeleteButton: boolean
}

export const ConsumptionEntry = ({
  control,
  consumptionOptions,
  setValue,
  hasError,
  index,
  isMobile,
  t,
  remove,
  unit,
  path,
  digitsAfterDecimalPoint,
  id,
  showDeleteButton
}: ConsumptionEntryProps) => {
  const classes = useConsumptionEntryStyles()

  const deviceName = consumptionOptions[index]?.name

  return (
    <React.Fragment>
      <Grid item xs={isMobile ? 12 : 4}>
        <DeviceSelectionField
          control={control}
          hasError={hasError}
          id={appendStepBlockId(id, `Devices ${index + 1} ${deviceName}`)}
          index={index}
          label={t('input_calculator.device_title', 'Device')}
          options={consumptionOptions.map((option) => option.name)}
          required={true}
        />
      </Grid>
      <Grid item xs={isMobile ? 5 : 2}>
        <QuantityField
          control={control}
          hasError={hasError}
          id={appendStepBlockId(id, `Quantity ${index + 1} ${deviceName}`)}
          label={t('input_calculator.quantity_title', 'Quantity')}
          name={`devices.${index}.quantity`}
          path={path}
          required={true}
        />
      </Grid>
      <Grid item xs="auto">
        <Typography
          className={classes.separator}
          color="textPrimary"
          variant="body2"
        >
          X
        </Typography>
      </Grid>
      <Grid item xs={isMobile ? true : 3}>
        <UnitaryDemandField
          consumptionOptions={consumptionOptions}
          control={control}
          hasError={hasError}
          id={appendStepBlockId(
            id,
            `UnitaryConsumption ${index + 1} ${deviceName}`
          )}
          index={index}
          isMobile={isMobile}
          label={t(
            `input_calculator.unitary_consumption_title.${
              unit ? unit : 'noUnit'
            }`
          )}
          name={`devices.${index}.unitaryConsumption`}
          path={path}
          required={true}
          setValue={setValue}
          unit={unit}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : true}>
        <div
          className={classes[isMobile ? 'mobileContainer' : 'desktopContainer']}
        >
          <ConsumptionCalculation
            consumptionOptions={consumptionOptions}
            control={control}
            digitsAfterDecimalPoint={digitsAfterDecimalPoint}
            index={index}
            setValue={setValue}
            unit={unit}
          />
          {showDeleteButton && (
            <IconButton
              id={appendStepBlockId(id, `Delete ${index + 1} ${deviceName}`)}
              onClick={() => remove(index)}
            >
              <Icon color="secondary" fontSize="small" name="delete" />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </React.Fragment>
  )
}
