import type { EpilotTheme } from '@epilot/journey-elements'
import { makeStyles, createStyles } from '@epilot/journey-elements'

export const useMapStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%',
      marginTop: '12px'
    },
    map: {
      width: '100%',
      height: '550px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    mapMobile: {
      width: '100vw',
      height: '90vw',
      maxWidth: '100%',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    text: {
      color: theme.palette?.text.disabled
    },
    error: {
      backgroundColor: '#E0E2EC',
      padding: '16px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? theme.shape?.borderRadius
          : '16px',
      minHeight: '64px',
      display: 'flex',
      gap: '8px'
    },
    errorText: {
      color: '#43474E'
    },
    iconMap: {
      minWidth: '20px',
      minHeight: '20px'
    }
  })
)
