import {
  DateFnsUtils,
  Grid,
  Icon,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TextField
} from '@epilot/journey-elements'
import {
  appendStepBlockId,
  getLocaleDateString,
  LOCALE_MAP
} from '@epilot/journey-logic-commons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { MeterReadingDynamicFields } from './types'

type MeterReadingOptionalFieldsProps = {
  fields: MeterReadingDynamicFields
  handleChange: (value: Record<string, unknown>) => void
  value: Record<string, unknown>
  hasError?: boolean
  id: string
}
export function MeterReadingOptionalFields({
  fields,
  value,
  handleChange,
  hasError,
  id
}: MeterReadingOptionalFieldsProps) {
  const { t } = useTranslation()
  const [fieldValues, setFieldValues] = useState<Record<string, unknown>>(
    value || {}
  )

  const onChange = (newFieldValues: Record<string, unknown>) => {
    setFieldValues(newFieldValues)
    handleChange(newFieldValues)
  }

  const localeDetected = navigator.language.substr(
    0,
    navigator.language.indexOf('-')
  )
  // set locale. Default to de
  const locale = ['de', 'en', 'fr'].includes(localeDetected)
    ? localeDetected
    : 'de'

  if (!fieldValues.readingDate) {
    onChange({ ...fieldValues, readingDate: new Date() })
  }

  return (
    <>
      {fields?.readingDate && (
        <Grid item md={6} sm={12} xs={12}>
          <MuiPickersUtilsProvider
            locale={LOCALE_MAP[locale as 'de' | 'en']}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              autoOk
              disableFuture={true}
              disableToolbar
              /**
               * @todo Value passed below always evaluates to false, remove or always pass false
               */
              error={hasError && Boolean(false)}
              format={getLocaleDateString(navigator.language)}
              fullWidth
              id={appendStepBlockId(id, 'Reading Date')}
              keyboardIcon={<Icon color="secondary" name="event" />}
              label={`${t('ReadingDate')}${
                fields?.readingDate?.required ? ' *' : ''
              }`}
              margin="none"
              onChange={(value) => {
                onChange({ ...fieldValues, readingDate: value })
              }}
              value={fieldValues.readingDate as any}
              variant="inline"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {fields?.readBy && (
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            error={hasError && fields?.readBy?.required && !fieldValues.readBy}
            fullWidth
            helperText={
              hasError &&
              fields?.readBy?.required &&
              !fieldValues.readBy &&
              t('field_required')
            }
            id={appendStepBlockId(id, 'Read By')}
            label={`${t('readBy')}${fields?.readBy?.required ? ' *' : ''}`}
            name="readBy"
            onChange={(e) =>
              onChange({ ...fieldValues, readBy: e.target.value })
            }
            value={fieldValues.readBy}
          />
        </Grid>
      )}

      {fields?.reason && (
        <Grid item md={6} sm={12} xs={12}>
          <TextField
            error={hasError && fields?.reason?.required && !fieldValues.reason}
            fullWidth
            helperText={
              hasError &&
              fields?.reason?.required &&
              !fieldValues.reason &&
              t('field_required')
            }
            id={appendStepBlockId(id, 'Reason')}
            label={`${t('reason')}${fields?.reason?.required ? ' *' : ''}`}
            name="reason"
            onChange={(e) =>
              onChange({ ...fieldValues, reason: e.target.value })
            }
            value={fieldValues.reason}
          />
        </Grid>
      )}
    </>
  )
}
