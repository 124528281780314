import type { SpacingProps } from '@epilot/journey-elements'
import { Hidden, StackSpacing } from '@epilot/journey-elements'
import type { LayoutProps, Layout } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'

export type StackSpacingLayoutOptions = SpacingProps

const StackSpacingLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const layout = uischema as Layout
  const {
    scale = 2,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as StackSpacingLayoutOptions) || {}

  return (
    <Hidden xsUp={!visible}>
      <StackSpacing alignItems={alignItems} scale={scale} {...rest}>
        {layout.elements.map((child, index) => (
          <JsonFormsDispatch
            enabled={enabled}
            key={index}
            path={path}
            renderers={renderers}
            schema={schema}
            uischema={child}
          />
        ))}
      </StackSpacing>
    </Hidden>
  )
}

export default withJsonFormsLayoutProps(StackSpacingLayout)
