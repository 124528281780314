import type { EpilotTheme } from '@epilot/journey-elements'
import type { Journey } from '@epilot/journey-logic-commons'
import {
  assignIdsToAllSteps,
  isLinearJourney,
  journeyStorage,
  logicStringsToObjects
} from '@epilot/journey-logic-commons'
import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { getJourney } from '../../services/journey-service'
import { isLocalOriginEventAllowed, env } from '../../utils/config'
import getJourneyParams from '../../utils/getJourneyParams'
import { setManifest as setPwaManifest } from '../../utils/setManifest'

import {
  initialReducerState,
  reducer,
  setConfigJourneyErrorAction,
  setConfigJourneySuccessAction,
  setLoadingJourneyAction,
  setMultipleJourneyStateAction,
  useDocumentLanguage
} from './utils'

const useGetJourney = () => {
  const [configState, dispatchConfigStateChange] = useReducer(
    reducer,
    initialReducerState
  )
  const {
    id: journeyId,
    preview,
    debug: debugParam,
    topBar: topBarParam,
    mode: modeParam,
    contextData,
    journeyLanguage,
    isEmbedded
  } = getJourneyParams()

  useDocumentLanguage(journeyLanguage)

  useEffect(() => {
    if (!preview && !journeyId) {
      console.error(
        'Error when getting Journey. When not in preview mode you need to pass the journeyId parameter to fetch the journey configuration.'
      )
    }
  }, [preview, journeyId])

  const handleMessage = (event: MessageEvent) => {
    /**
     * @todo originalEvent is not a property of MessageEvent,
     * it should be removed (was probably taken from example
     * in which function is passed to jQuery's on())
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const origin = event.origin || (event as any).originalEvent.origin

    if (
      origin !== env('REACT_APP_JOURNEY_BUILDER_URL') &&
      !isLocalOriginEventAllowed(origin)
    ) {
      return
    }
    const data = event.data

    if (
      typeof data == 'object' &&
      data !== null &&
      data?.detail?.eventCaller === 'JournyBuilder'
    ) {
      // Do something with event.data.value;
      const {
        stepIndex,
        journey,
        debug,
        initialState,
        blocksDisplaySettings,
        token
      } = data.detail.payload

      if (journey) {
        const decodedData = decodeURIComponent(atob(journey))

        if (token) {
          journeyStorage.setItem('token', token)
        }

        // only parse data if not undefined, or if not string of undefined (due to decoding)
        const journeyConfig =
          !decodedData || decodedData === 'undefined'
            ? undefined
            : JSON.parse(decodedData)

        dispatchConfigStateChange(
          setMultipleJourneyStateAction({
            journeyConfig,
            isLoading: false,
            debug,
            stepIndex,
            ...(Array.isArray(initialState) && { initialState: initialState }),
            blocksDisplaySettings
          })
        )
      }
    }
  }

  const loadJourney = useCallback(
    async (id) => {
      try {
        dispatchConfigStateChange(setLoadingJourneyAction(true))
        const journey = await getJourney(id)

        if (journey) {
          dispatchConfigStateChange(setConfigJourneySuccessAction(journey))
        } else {
          dispatchConfigStateChange(setConfigJourneyErrorAction())
        }
      } catch (err) {
        dispatchConfigStateChange(setLoadingJourneyAction(false))
        console.error(err)
      }
    },
    [dispatchConfigStateChange]
  )

  useEffect(() => {
    if (journeyId) {
      loadJourney(journeyId)
    }
  }, [journeyId, loadJourney])

  useEffect(() => {
    if (configState?.journeyConfig?.name) {
      setPwaManifest(
        configState?.journeyConfig?.name,
        configState?.journeyConfig?.design?.logoUrl,
        (configState?.journeyConfig?.design?.theme as EpilotTheme)?.palette
          ?.primary?.main,
        (configState?.journeyConfig?.design?.theme as EpilotTheme)?.palette
          ?.background?.default
      )
    }
  }, [configState?.journeyConfig])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  const setJourney = useCallback(
    (newJourney: Journey) =>
      dispatchConfigStateChange(setConfigJourneySuccessAction(newJourney)),
    [dispatchConfigStateChange]
  )

  const journey = useMemo(() => {
    const data = configState.journeyConfig

    return data?.steps ? assignIdsToAllSteps(data) : data
  }, [configState.journeyConfig])

  // WARNING: expensive operation, shouldn't run on every render
  const isLinear = useMemo(
    () =>
      isLinearJourney(journey?.steps, logicStringsToObjects(journey?.logics)),
    [journey?.steps, journey?.logics]
  )

  return {
    isLoading: configState.isLoading,
    debug: debugParam || configState.debug,
    stepIndex: configState.stepIndex ? Number(configState.stepIndex) : 0,
    journey,
    initialState: configState.initialState,
    preview,
    journeyId,
    contextData,
    isLinearJourney: isLinear,
    dispatch: dispatchConfigStateChange,
    setJourney,
    topBarParam,
    modeParam,
    journeyLanguage,
    isEmbedded
  }
}

export default useGetJourney
