import { useTheme } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'
import type { IconContainerProps } from '@material-ui/lab/Rating'
import MuiRating from '@material-ui/lab/Rating'
import React from 'react'

import type { Theme } from '..'
import { makeStyles, createStyles, SmallText } from '..'

import type { RatingProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ratingContainer: {
      alignItems: 'flex-end'
    },
    houseDescription: {
      margin: '0 auto',
      display: 'table',
      color: `${theme.palette.common.black}`,
      textAlign: 'center'
    }
  })
)

// currently not used

export function Rating(props: RatingProps) {
  const theme = useTheme()
  const {
    scaled,
    selectedIndex,
    inactiveColor = theme.palette.action.disabled,
    activeColor = theme.palette.primary.main,
    component,
    emptyComponent,
    onIconClick,
    labeled,
    labels,
    iconSpacing,
    iconTooltipLabels: _itl,
    size = 'medium',
    max,
    ...rest
  } = props

  const classes = useStyles()

  const icons =
    getIcons(
      max,
      component,
      size,
      emptyComponent,
      selectedIndex,
      scaled,
      labels,
      inactiveColor,
      activeColor,
      onIconClick,
      labeled,
      classes,
      iconSpacing
    ) || []

  const IconContainer = (props: IconContainerProps) => {
    const { value } = props

    return <span>{icons[value - 1]}</span>
  }

  return (
    <MuiRating
      {...rest}
      IconContainerComponent={IconContainer}
      className={classes.ratingContainer}
      max={max}
    />
  )
}

const getIcons = (
  max: number,
  component: React.ReactElement,
  size: string,
  emptyComponent?: React.ReactElement,
  selectedIndex?: number,
  scaled?: boolean,
  labels?: string[],
  inactiveColor?: string,
  activeColor?: string,
  onIconClick?: (index: number) => void,
  labeled?: boolean,
  classes?: any,
  iconSpacing?: string
) => {
  return Array.from({ length: max }).map((_v, i) => {
    let renderComponent = component

    if (emptyComponent != null && (!selectedIndex || i >= selectedIndex))
      renderComponent = emptyComponent

    const scaleInitial = size === 'large' ? 1.5 : size === 'small' ? 0.7 : 1
    const scaleValue = scaled ? (i * 0.15 + 0.5) * scaleInitial : scaleInitial

    return (
      <MuiTooltip
        arrow
        key={i}
        placement="top"
        title={labels && labels[i] ? labels[i] : ''}
      >
        <span
          style={{
            display: 'block',
            marginRight: iconSpacing || `${1 + (scaled ? i * 5 : 10)}px`
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

              transform: `scale(${scaleValue})`,
              position: 'relative',
              top: scaled ? `${10 - i * 2.5}px` : 0
            }}
          >
            {React.cloneElement(renderComponent, {
              style: { cursor: 'pointer' },
              color:
                typeof selectedIndex === 'undefined' || i > selectedIndex
                  ? inactiveColor
                  : activeColor,
              fill:
                typeof selectedIndex === 'undefined' || i > selectedIndex
                  ? inactiveColor
                  : activeColor,
              customColor:
                typeof selectedIndex === 'undefined' || i > selectedIndex
                  ? inactiveColor
                  : activeColor,
              htmlColor:
                typeof selectedIndex === 'undefined' || i > selectedIndex
                  ? inactiveColor
                  : activeColor,
              onClick: () => onIconClick?.(i)
            })}
          </div>
          {labeled ? (
            <SmallText className={classes.houseDescription}>
              {labels && labels[i] ? labels[i] : ''}
            </SmallText>
          ) : null}
        </span>
      </MuiTooltip>
    )
  })
}
