import type { Theme } from '@epilot/journey-elements'
import {
  Tooltip,
  Typography,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import Info from '@epilot360/icons/react/Info'
import type { TFunction } from 'i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    title: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.text.primary}`,
      padding: '3px 3px',
      borderRadius: '4px',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      gap: 4
    },
    icon: {
      width: '16px',
      height: '16px'
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

type GetAGPreviewProps = {
  t: TFunction
}

export const GetAGPreview = ({ t }: GetAGPreviewProps) => {
  const classes = useStyles()

  return (
    <div className={classes.container} data-testid="getag-preview">
      <div className={classes.title}>
        <Typography color="textPrimary" variant="caption">
          <strong>{t('getag_preview_text', 'GetAG Preview')}</strong>
        </Typography>
        <Tooltip
          arrow
          title={
            <>
              {t(
                'getag_preview_tooltip',
                'This price will be computed on demand based on address / consumption information.'
              )}
            </>
          }
        >
          <div className={classes.iconContainer}>
            <Info className={classes.icon} variant="outlined" />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
