import type { MultichoiceControlComponentProps } from '../types'

import { MultichoiceButton } from './MultichoiceButton'
import { MultichoiceCheckbox } from './MultichoiceCheckbox'
import { MultichoiceImageButton } from './MultichoiceImageButton'

export function MultichoiceControl(props: MultichoiceControlComponentProps) {
  switch (props.uiType) {
    case 'checkbox':
      return <MultichoiceCheckbox {...props} />
    case 'imageButton':
      return <MultichoiceImageButton {...props} />
    default:
      return <MultichoiceButton {...props} />
  }
}
