import {
  DEFAULT_CURRENCY,
  PricingModel,
  computeQuantities,
  formatAmount,
  formatAmountFromString,
  getDisplayTiersByQuantity
} from '@epilot/pricing'
import type { PriceTierDisplayMode, Currency } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import type {
  BlockMappingData,
  ShoppingCartDataItem,
  BillingPeriod,
  CompositePrice,
  Price,
  PriceItem,
  CompositePriceItem,
  TypeGetAg,
  PriceTier,
  ComputePrice,
  PriceWithBlockMappings
} from '../types'

type HydratedCompositePrice = CompositePrice & {
  price_components: Price[]
}

type PriceWithTieredPricingModel = Omit<Price, 'pricing_model'> & {
  tiers: PriceTier[]
  pricing_model:
    | PricingModel.tieredFlatFee
    | PricingModel.tieredVolume
    | PricingModel.tieredGraduated
}

/**
 * Removes trailing double-zeros from a price
 * @example 10.00 => 10, 10.20 => 10.20
 */
export const omitTrailingDoubleDecimalZeros = (price: string) => {
  /*
    These Regexes captures 2 groups, the first being the trailing zeros
    and the second what could be after it (spaces and/or currency symbols or a slash)
    These Regexes captures 2 groups, the first being the trailing zeros
    and the second what could be after it (spaces and/or currency symbols or a slash)

    We then use the replace function to remove the first group
  */
  const trailingZerosWithDot = /(\.{1}00)(\s.*)?$/
  const trailingZerosWithComma = /(,{1}00)(\s.*)?$/

  // Added to handle Tiered Pricing prices that contain a slash at the end. e.g. €10.00/Stück
  const trailingZerosWithDotBeforeSlash = /(\.{1}00)(\/[\w\W]*)$/
  const trailingZerosWithCommaBeforeSlash = /(,{1}00)(\/[\w\W]*)$/

  return price
    .replace(trailingZerosWithDot, '$2')
    .replace(trailingZerosWithComma, '$2')
    .replace(trailingZerosWithDotBeforeSlash, '$2')
    .replace(trailingZerosWithCommaBeforeSlash, '$2')
}

const formatPrice = (
  amount: string | number,
  currency: Currency,
  showTrailingDecimalZeros = false
): string => {
  const formattedAmount =
    typeof amount === 'number'
      ? formatAmount({
          amount,
          currency,
          locale: navigator.language,
          enableSubunitDisplay: true
        })
      : formatAmountFromString({
          decimalAmount: amount,
          currency,
          locale: navigator.language,
          useRealPrecision: false,
          enableSubunitDisplay: true
        })

  if (showTrailingDecimalZeros) return formattedAmount

  return omitTrailingDoubleDecimalZeros(formattedAmount)
}

export function getDisplayPrice(
  t: TFunction,
  unitAmount?: string | number,
  priceDisplayInJourneys?: Price['price_display_in_journeys'],
  showTrailingDecimalZeros = false,
  currency = DEFAULT_CURRENCY
): string {
  if (priceDisplayInJourneys === 'show_as_on_request') {
    return t('show_as_on_request')
  }

  const priceAmount = formatPrice(
    unitAmount || 0,
    currency as Currency,
    showTrailingDecimalZeros
  )

  if (priceDisplayInJourneys === 'show_as_starting_price') {
    return `${t('show_as_starting_price')} ${priceAmount}`
  }

  return priceAmount
}

export function getPriceDisplayText({
  t,
  amount = 0,
  discountAmount,
  beforeDiscountAmountToDisplay,
  priceDisplayInJourneys,
  showTrailingDecimalZeros = false,
  currency = DEFAULT_CURRENCY
}: {
  t: TFunction
  amount?: string | number
  discountAmount?: string | number
  beforeDiscountAmountToDisplay?: string | number
  priceDisplayInJourneys?: Price['price_display_in_journeys']
  showTrailingDecimalZeros?: boolean
  currency?: string
}): {
  prefix?: string
  formattedPrice?: string
  formatedPriceDiscount?: string
  formatedPriceBeforeDiscount?: string
} {
  if (priceDisplayInJourneys === 'show_as_on_request') {
    return { prefix: t('show_as_on_request') }
  }

  const priceAmount = formatPrice(
    amount,
    currency as Currency,
    showTrailingDecimalZeros
  )

  const priceDiscountAmount = discountAmount
    ? formatPrice(
        discountAmount,
        currency as Currency,
        showTrailingDecimalZeros
      )
    : undefined

  const priceBeforeDiscountAmount = beforeDiscountAmountToDisplay
    ? formatPrice(
        beforeDiscountAmountToDisplay,
        currency as Currency,
        showTrailingDecimalZeros
      )
    : undefined

  return {
    ...(priceDisplayInJourneys === 'show_as_starting_price' && {
      prefix: t('show_as_starting_price')
    }),
    formattedPrice: priceAmount,
    formatedPriceDiscount: priceDiscountAmount,
    formatedPriceBeforeDiscount: priceBeforeDiscountAmount
  }
}

type PriceType = NonNullable<Price['type']>
type PriceTypeWithBillingPeriod = `${PriceType}.${BillingPeriod}`

const AVAILABLE_PRICE_TYPES: Set<PriceType> = new Set(['one_time', 'recurring'])

const isValidPriceType = (priceType?: string): priceType is PriceType =>
  AVAILABLE_PRICE_TYPES.has(priceType as PriceType)

/**
 * Generates a price type string, potentially including a billing period.
 * @param {PriceType | undefined} priceType - The type of the price.
 * @param {BillingPeriod} [billingPeriod='monthly'] - The billing period.
 * @returns {PriceType | PriceTypeWithBillingPeriod} A PriceType or a PriceType.BillingPeriod combination.
 */
export function getPriceType(
  priceType: PriceType | undefined,
  billingPeriod: BillingPeriod = 'monthly'
): PriceType | PriceTypeWithBillingPeriod {
  if (!isValidPriceType(priceType) || !billingPeriod) {
    return 'one_time'
  }

  if (priceType === 'recurring') {
    return `${priceType}.${billingPeriod}`
  }

  return priceType
}

export const priceHasComponents = (
  price?: Price | CompositePrice
): price is HydratedCompositePrice => {
  if (!price || !priceIsComposite(price)) return false

  if (
    'price_components' in price &&
    Array.isArray(price?.price_components) &&
    price?.price_components.length
  ) {
    return true
  }

  return false
}

export const priceIsComposite = (
  price: Price | CompositePrice
): price is CompositePrice => {
  return price?.is_composite_price === true
}

export const priceItemIsComposite = (
  price: PriceItem | CompositePriceItem
): price is CompositePriceItem => {
  if (!price?.is_composite_price) return false

  if ('item_components' in price && Array.isArray(price?.item_components)) {
    return true
  }

  return false
}

export const priceItemHasComponents = (
  priceItem: PriceItem | CompositePriceItem
) => {
  return (
    priceItemIsComposite(priceItem) &&
    Boolean(priceItem.item_components?.length)
  )
}

export const getPriceMappings = ({
  price
}: ShoppingCartDataItem | PriceWithBlockMappings) =>
  !price
    ? []
    : [
        {
          priceId: price._id,
          ...(price.blockMappingData as BlockMappingData)
        },
        ...((price.price_components as Price[]) ?? []).map(
          ({ _id: priceId, blockMappingData }) => ({
            priceId,
            ...blockMappingData
          })
        )
      ]
        .filter((mapping) => typeof mapping.numberInput === 'number')
        .map(
          ({
            priceId: price_id,
            numberInput: value,
            frequencyUnit: frequency_unit
          }) => ({ price_id, value, frequency_unit })
        )

export const getExternalFeesMappings = (price: PriceWithBlockMappings) => {
  if (!price || !price.getag_price) {
    return []
  }

  if (priceIsComposite(price) && Array.isArray(price.price_components)) {
    return price.price_components.map(({ _id, get_ag }: Price) =>
      toExternalFeesMapping(
        _id,
        get_ag?.type,
        price.getag_price,
        get_ag?.tariff_type
      )
    )
  } else {
    return [
      toExternalFeesMapping(
        price._id,
        price.get_ag?.type,
        price.getag_price,
        price.get_ag?.tariff_type
      )
    ]
  }
}

const toExternalFeesMapping = (
  priceId: string | undefined,
  type: TypeGetAg = 'base_price',
  getagPrice: ComputePrice,
  tariffType: 'HT' | 'NT' = 'HT'
) => {
  const amountTotal =
    type === 'work_price'
      ? tariffType === 'NT'
        ? getagPrice.amount_variable_nt
        : getagPrice.amount_variable_ht
      : getagPrice.amount_static

  const amountTotalDecimal =
    type === 'work_price'
      ? tariffType === 'NT'
        ? getagPrice.amount_variable_decimal_nt
        : getagPrice.amount_variable_decimal_ht
      : getagPrice.amount_static_decimal

  return {
    price_id: priceId,
    amount_total: amountTotal,
    amount_total_decimal: amountTotalDecimal,
    frequency_unit: getagPrice.billing_period
  }
}

export const hasPricingModel = (
  pricingModel: PricingModel,
  price: Price | CompositePrice | HydratedCompositePrice
): boolean => {
  if (!price) {
    return false
  }

  return price.pricing_model === pricingModel
}

export const hasTieredPricingModel = (
  price?: Price | CompositePrice
): price is PriceWithTieredPricingModel => {
  if (!price) return false

  return (
    hasPricingModel(PricingModel.tieredVolume, price) ||
    hasPricingModel(PricingModel.tieredGraduated, price) ||
    hasPricingModel(PricingModel.tieredFlatFee, price)
  )
}

export const getPriceItemDisplayInJourneys = (
  item?: PriceItem | CompositePriceItem
): Price['price_display_in_journeys'] => {
  if (!item) return 'show_price'

  const isPriceCompositeAndHasOnRequestComponent =
    priceItemIsComposite(item) &&
    item?.item_components?.some(
      (priceComponent) =>
        priceComponent?._price?.price_display_in_journeys ===
        'show_as_on_request'
    )

  const priceDisplayInJourneys = isPriceCompositeAndHasOnRequestComponent
    ? 'show_as_on_request'
    : item?._price?.price_display_in_journeys

  return priceDisplayInJourneys
}

const getPriceDisplayInJourneys = (
  price?: Price | CompositePrice,
  tiersDisplayModes?: PriceTierDisplayMode[]
): Price['price_display_in_journeys'] => {
  if (!price) return 'show_price'

  const isPriceCompositeAndHasOnRequestComponent =
    priceIsComposite(price) &&
    Array.isArray(price.price_components) &&
    price.price_components?.some(
      (priceComponent) =>
        priceComponent.price_display_in_journeys === 'show_as_on_request'
    )

  const isPriceCompositeShowAsStartingPrice =
    priceIsComposite(price) &&
    Array.isArray(price.price_components) &&
    price.price_components?.some(
      (priceComponent) =>
        priceComponent.price_display_in_journeys === 'show_as_starting_price'
    )

  const priceDisplayInJourneys: Price['price_display_in_journeys'] =
    isPriceCompositeAndHasOnRequestComponent
      ? 'show_as_on_request'
      : isPriceCompositeShowAsStartingPrice
        ? 'show_as_starting_price'
        : price.price_display_in_journeys

  const hasOnRequestTier = tiersDisplayModes?.some(
    (tierDisplayMode) => tierDisplayMode === 'on_request'
  )

  if (hasOnRequestTier) {
    return 'show_as_on_request'
  }

  return priceDisplayInJourneys
}

export const computePriceDisplayInJourneys = (
  price: PriceWithBlockMappings,
  quantity: number
): Price['price_display_in_journeys'] => {
  if (!price) return 'show_price'

  if (priceHasComponents(price)) {
    const displayTiers = price.price_components
      .filter((priceComponent) => hasTieredPricingModel(priceComponent))
      .flatMap((priceComponent) => {
        const { quantityToSelectTier } = computeQuantities(
          priceComponent,
          quantity,
          {
            frequency_unit: priceComponent.blockMappingData?.frequencyUnit,
            value: priceComponent.blockMappingData?.numberInput
          }
        )

        return getDisplayTiersByQuantity(
          priceComponent.tiers || [],
          quantityToSelectTier,
          priceComponent.pricing_model
        )
      })
      .filter((tier) => Boolean(tier?.display_mode))
      .map((tier) => tier?.display_mode as PriceTierDisplayMode)

    return getPriceDisplayInJourneys(price, displayTiers)
  }

  if (!hasTieredPricingModel(price)) {
    return getPriceDisplayInJourneys(price)
  }

  const { quantityToSelectTier } = computeQuantities(price, quantity, {
    frequency_unit: price.blockMappingData?.frequencyUnit,
    value: price.blockMappingData?.numberInput
  })

  const matchingTiers = getDisplayTiersByQuantity(
    price.tiers || [],
    quantityToSelectTier,
    price.pricing_model
  )

  const displayTiers = matchingTiers
    ?.filter((tier) => Boolean(tier.display_mode))
    ?.map((tier) => tier.display_mode as PriceTierDisplayMode)

  return getPriceDisplayInJourneys(price, displayTiers)
}
