import type { Filter } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { applyFilters } from '../../../../../utils/withFilters/applyFilters'
import { filterValuesByProductCategories } from '../../../../../utils/withFilters/filterValuesByProductCategories'
import { PRODUCT_FILTER_MATCH_KEYS } from '../../utils/product-tiles-control-utils'

import type { CatalogProductTilesControlProps } from './CatalogProductTilesControl'
import { CatalogProductTilesControl } from './CatalogProductTilesControl'

type CatalogProductTilesControlWithFiltersProps =
  CatalogProductTilesControlProps & {
    /**
     * uischema.options._filterValues; added to config on runtime; contains users selected values
     */
    _filterValues?: Record<string, unknown>

    /**
     * uischema.options.filters
     */
    filters?: Filter[]

    /**
     * List of user selected product categories, if any
     */
    selectedCategories?: string[]

    /**
     * ID of component
     */
    id: string
  }

export const CatalogProductTilesControlWithFilters = ({
  filters,
  _filterValues,
  selectedCategories,
  data: initialValues,
  ...remainingProps
}: CatalogProductTilesControlWithFiltersProps) => {
  const values = useMemo(() => {
    const initialValuesFiltered = filterValuesByProductCategories(
      initialValues,
      selectedCategories
    )

    if (!initialValuesFiltered) {
      return initialValues
    }

    const filteredValues = applyFilters(
      filters,
      _filterValues,
      initialValuesFiltered,
      PRODUCT_FILTER_MATCH_KEYS
    )

    return filteredValues
  }, [_filterValues, filters, initialValues, selectedCategories])

  return <CatalogProductTilesControl {...remainingProps} data={values} />
}
