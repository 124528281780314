import {
  AppBar,
  Toolbar,
  useTheme,
  IconButton,
  Icon,
  rgbToHex
} from '@epilot/journey-elements'
import type { CSSProperties } from 'react'
import React from 'react'

import classes from './styles/TopBar.module.scss'
import { getIconColor } from './utils'

export type TopBarProps = {
  /**
   * Logo to be shown.
   */
  logoUrl?: string
  /**
   * The title of the bar. This will only be shown if logo is not present.
   */
  title?: string
  /**
   * Back button callback.
   */
  onBack?: () => void
  /**
   * Close button callback.
   */
  onClose?: () => void
  /**
   * Whether its mobile or tablet
   */
  isMobile: boolean
  /**
   * Whether to show the top bar or not
   */
  isTopBarEnabled: boolean
  /**
   * Whether to attach the backButton to the progress bar
   */
  isProgressBar?: boolean
}

const TopBar = (props: TopBarProps) => {
  const {
    logoUrl,
    title,
    onClose,
    onBack,
    isMobile,
    isTopBarEnabled,
    isProgressBar,
    ...rest
  } = props
  const theme = useTheme()

  const rawBackgroundColor = (
    '' +
    rgbToHex(
      (theme?.overrides?.MuiAppBar?.colorPrimary as CSSProperties)
        ?.backgroundColor || theme.palette.primary.main
    )
  ).slice(0, 7)

  const backgroundColor = rawBackgroundColor
    ? rawBackgroundColor.length > 7
      ? parseInt(rawBackgroundColor.slice(-2), 16) > 60
        ? rawBackgroundColor.slice(0, -2)
        : '#ffffff'
      : rawBackgroundColor
    : rgbToHex(theme.palette.primary.main).slice(0, 7)

  const iconColor = getIconColor({
    backgroundColor,
    mainColor: theme.palette.primary.main
  })

  if (!isTopBarEnabled && onBack) {
    if (isProgressBar) {
      return (
        // this inline style is used for inline journeys
        <IconButton onClick={onBack} style={{ position: 'static' }}>
          <Icon customColor={iconColor} name="arrow_back" />
        </IconButton>
      )
    } else if (!isMobile) {
      return (
        // this inline style is used for inline journeys
        <IconButton onClick={onBack} style={{ position: 'static' }}>
          <Icon
            className={classes.arrow}
            customColor={theme.palette.primary.main}
            name="arrow_back"
          />
        </IconButton>
      )
    }
  }

  if (!isTopBarEnabled) return null

  return (
    <AppBar color="primary" elevation={1} position="static" square {...rest}>
      <Toolbar className={classes.toolbar} variant="dense">
        {onBack ? (
          <IconButton onClick={onBack}>
            <Icon
              className={classes.arrow}
              customColor={iconColor}
              name="arrow_back"
            />
          </IconButton>
        ) : (
          <div className={classes.dummyDiv} /* to align the logo */ />
        )}

        {logoUrl ? (
          <img alt="Logo" className={classes.logo} src={logoUrl} />
        ) : (
          title
        )}
        {onClose ? (
          <IconButton onClick={onClose}>
            <Icon customColor={iconColor} name="close" />
          </IconButton>
        ) : (
          <div></div>
        )}
      </Toolbar>
    </AppBar>
  )
}

export const TopBarMemoized = React.memo(TopBar)
