import { Hidden } from '@epilot/journey-elements'
import { isInputCalculatorControlOptions } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { InputCalculatorForm } from './components/InputCalculatorForm'
import type { InputCalculatorControlData } from './types'
import { isInputCalculatorControlData } from './types.guard'
import { parseInputCalculatorData } from './utils'

function InputCalculatorControl({
  uischema,
  handleChange,
  path,
  data,
  errors,
  visible,
  required
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)

  const options = uischema.options

  const hasError = !!errors

  if (!isInputCalculatorControlOptions(options)) {
    console.error(
      'InputCalculatorControl -> Invalid uischema.options detected. Passed options are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isInputCalculatorControlData(data)) {
    console.error(
      'InputCalculatorControl -> Invalid data detected. Current data is:',
      { data }
    )
  }

  const handleFormChange = (
    fieldPath: string,
    data: InputCalculatorControlData
  ) => {
    handleChange(fieldPath, data)
  }

  return (
    <Hidden xsUp={!visible}>
      <InputCalculatorForm
        data={parseInputCalculatorData(data, options)}
        handleChange={handleFormChange}
        hasError={hasError}
        id={stepBlockId}
        isRequired={!!required}
        path={path}
        uischema={uischema}
        {...options}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: InputCalculatorControl
  }) as never
)
