import type { AvailabilityCheck } from '@epilot/journey-logic-commons'

/**
 * Clean availability data object (remove empty values
 * @param {AvailabilityCheck} availability
 * @returns {void}
 */
export const cleanAvailabilityCheckValues = (
  availability: AvailabilityCheck | null | undefined
) => {
  if (availability) {
    Object.keys(availability).forEach((key) => {
      if (
        key in availability &&
        !availability[key as keyof AvailabilityCheck] &&
        typeof availability[key as keyof AvailabilityCheck] !== 'boolean'
      ) {
        delete availability[key as keyof AvailabilityCheck]
      }
    })
  }
}
