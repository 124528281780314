import { Hidden } from '@epilot/journey-elements'
import { isAddressControlOptions } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { AddressForm } from './components/AddressForm'
import { mapToAddressOptions } from './mapToAddressOptions'
import type { AddressControlOptionsDto, AddressFormProps } from './types'
import { isAddressControlData } from './types.guard'
import { withDataInjection } from './withDataInjection'
import type { WithDataInjectionProps } from './withDataInjection'

export function AddressControlPure({
  data,
  handleChange,
  path,
  errors,
  uischema,
  required,
  visible,
  disabledFields,
  id
}: EpilotControlProps) {
  const hasError = !!errors

  const stepBlockId = useStepBlockId(path, id)

  const { t } = useTranslation()
  // map configurator dto to props the ContactForm can understand easily
  const [addressOptions, setAddressOptions] = useState(() =>
    mapToAddressOptions(
      uischema.options as AddressControlOptionsDto,
      t,
      Array.isArray(disabledFields) ? disabledFields : undefined
    )
  )

  useEffect(() => {
    setAddressOptions(
      mapToAddressOptions(
        uischema.options as AddressControlOptionsDto,
        t,
        Array.isArray(disabledFields) ? disabledFields : undefined
      )
    )
  }, [uischema.options, path, disabledFields])

  if (!isAddressControlOptions(addressOptions)) {
    console.error(
      'AddressControl -> Invalid uischema.options detected. Passed options are:',
      { options: addressOptions }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isAddressControlData(data)) {
    console.error('AddressControl -> Invalid data detected. Current data is:', {
      data
    })

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <AddressFormWithInjectedData
        data={data}
        handleChange={handleChange}
        hasError={hasError}
        id={stepBlockId}
        isRequired={!!required}
        path={path}
        {...addressOptions}
      />
    </Hidden>
  )
}

const AddressFormWithInjectedData = withDataInjection(
  (props: AddressFormProps & WithDataInjectionProps) => (
    <AddressForm {...props} />
  )
)

export default withJsonFormsControlProps(
  includeCommon({ component: AddressControlPure }) as never
)
