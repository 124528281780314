import { isObject } from '../helper'

/**
 * Iterates through passed initial values, and modifies the keys to only contain the blockname
 * @param _filterValuesInitial initial values where keys might contain step name
 * @returns initial values with keys only containing block name
 */
export const getFilterValues = (
  _filterValuesInitial: Record<string, unknown>
): Record<string, unknown> => {
  const _filterValues: Record<string, unknown> = {}

  Object.keys(_filterValuesInitial).forEach((key) => {
    const value = _filterValuesInitial[key]
    const blockName = key.split('/').pop()

    if (!blockName) {
      console.error('getFilterValues --> blockName could not be identified')

      return
    }

    Object.assign(_filterValues, { [blockName]: value })
  })

  return _filterValues
}

/**
 * Finds index of items by attempting to match given value against the array elements
 * @param items array of values
 * @param matchKey key in element of values, which should be used to compare matchedValue against
 * @param matchedValue value to compare against
 */
export const getItemIndex = (
  items: unknown[],
  matchKeys: string[],
  id: string
): number => {
  if (typeof id !== 'string') {
    console.error(
      'applyFilters --> detected value could not be filtered. Likely the matchKey provided does not return an item of type string'
    )

    return -1
  }

  const separatedId = id.split('/')

  // Old case with only priceId
  if (separatedId.length === 1) {
    const matchKey = matchKeys[1]

    return items.findIndex((item) => {
      if (isObject(item) && matchKey in item) {
        const v = item[matchKey]

        if (typeof v === 'string') {
          return id === v
        }
      }

      console.error(
        'applyFilters --> detected value could not be filtered. Likely the matchKey provided does not return an item of type string'
      )

      return false
    })
  }

  // New case with product and price ids
  const idDict: Record<string, string> = {}

  for (const idx in matchKeys) {
    idDict[matchKeys[idx]] = separatedId[idx]
  }

  return items.findIndex((item) => {
    if (isObject(item)) {
      for (const key of matchKeys) {
        // If any of the keys is not in item, return false
        if (!(key in item)) {
          return false
        }

        const v = item[key]

        // If the id value is different to the item value, return false
        if (typeof v === 'string' && idDict[key] !== v) {
          return false
        }
      }

      // If we make it out of the for without returning, item matched
      return true
    }

    console.error(
      'applyFilters --> detected value could not be filtered. Likely the matchKey provided does not return an item of type string'
    )

    return false
  })
}
