import { makeStyles, TextField } from '@epilot/journey-elements'
import { toDashCase } from '@epilot/journey-logic-commons'
import type { FieldOption } from '@epilot/journey-logic-commons'
import type {
  Control,
  Path,
  UnpackNestedValue,
  PathValue,
  UseControllerProps,
  FieldValues
} from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FieldTextProps<T extends FieldValues> = {
  type?: React.InputHTMLAttributes<unknown>['type']
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>
  path?: string
  rules?: UseControllerProps['rules']
  id?: string
} & FieldOption

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldText = <T extends Record<string, any>>(
  props: FieldTextProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    path,
    placeholder,
    errorMessage,
    defaultValue,
    required,
    rules,
    disabled,
    type = 'text'
  } = props

  const id = props.id || `${toDashCase(path || '')}-${name}`

  const { t } = useTranslation()
  const classes = useFieldTextStyles()

  return (
    <Controller<T>
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        const fieldError = Boolean(fieldState.error)
        const showError = hasError && fieldError

        return (
          <TextField
            InputLabelProps={{ htmlFor: id }}
            disabled={disabled}
            error={showError}
            fullWidth
            helperText={
              hasError &&
              fieldState.error &&
              (errorMessage || t('field_required'))
            }
            {...(disabled && {
              InputProps: {
                classes: {
                  disabled: classes.disabled
                }
              }
            })}
            id={id}
            label={label || t(name)}
            placeholder={placeholder}
            required={required}
            type={type}
            {...field}
          />
        )
      }}
      rules={
        {
          required,
          ...rules
        } as never
      }
    />
  )
}

const useFieldTextStyles = makeStyles({
  disabled: {
    color: 'currentColor'
  }
})
