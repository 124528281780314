import {
  BodyText,
  createStyles,
  makeStyles,
  useTheme
} from '@epilot/journey-elements'

import { SchemaIcon } from '../../../../components/SchemaIcon'

const useStyles = makeStyles(() =>
  createStyles({
    headerStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 24,
      margin: 6
    },
    iconContainer: {
      marginRight: 8,
      marginTop: 6
    }
  })
)

type MeterCardHeaderProps = {
  slug: string
  title?: string
}
export function MeterCardHeader(props: MeterCardHeaderProps) {
  const { title, slug } = props
  const theme = useTheme()
  const styles = useStyles(props)

  return (
    <div className={styles.headerStyle}>
      <span className={styles.iconContainer}>
        <SchemaIcon
          iconProps={{ fill: theme.palette.primary.main, size: 24 }}
          schemaSlug={slug}
        />
      </span>

      <BodyText>{title || ''}</BodyText>
    </div>
  )
}
