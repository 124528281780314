import {
  IconButton,
  createStyles,
  makeStyles,
  Paper,
  ButtonBase
} from '@material-ui/core'
import { useContext } from 'react'

import { ClassPrefixContext } from '../context/ClassPrefixContext'
import type { Theme } from '../styles'
import type { EpilotTheme } from '../ThemeProvider'

import type { ElevatedIconButtonProps, IconButtonProps } from './types'

const useIconButtonClasses = (classPrefix: string) =>
  makeStyles((theme: EpilotTheme) =>
    createStyles({
      root: {
        width: 35,
        height: 35,

        [`& .${classPrefix}MuiIcon-root`]: {
          fontSize: '1.4rem',
          color: theme.palette?.secondary.main
        },

        '&:disabled': {
          [`& .${classPrefix}MuiIcon-root`]: {
            color: theme.palette?.grey[100]
          }
        },

        '&:hover': {
          backgroundColor: 'rgba(23, 43, 77, 0.04)'
        }
      },
      sizeSmall: {
        width: 24,
        height: 24,

        [`& .${classPrefix}MuiIcon-root`]: {
          fontSize: '1rem'
        }
      },
      colorPrimary: {
        border: `1px solid ${theme.palette?.primary.main}`,
        backgroundColor: `${theme.palette?.primary.main}`,

        [`& .${classPrefix}MuiIcon-root`]: {
          color: 'white'
        },

        '&:hover': {
          backgroundColor: 'rgb(2, 139, 206)',
          borderColor: 'rgb(2, 139, 206)'
        },

        '&:disabled': {
          backgroundColor: theme.palette?.grey[40],
          borderColor: theme.palette?.grey[40]
        }
      },
      colorSecondary: {
        border: `1px solid ${theme.palette?.grey[70]}`,

        '&:hover': {
          backgroundColor: 'rgba(23, 43, 77, 0.04)'
        }
      }
    })
  )

const StyledIconButton = (props: IconButtonProps) => {
  const { children, ...rest } = props
  const classPrefix = useContext(ClassPrefixContext)
  const classes = useIconButtonClasses(classPrefix)()

  return (
    <IconButton classes={{ ...classes, ...props.classes }} {...rest}>
      {children}
    </IconButton>
  )
}

export { StyledIconButton as IconButton }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonStyle: ({ variant }: ElevatedIconButtonProps) => ({
      borderRadius:
        variant === 'circle'
          ? '50%'
          : variant === 'square'
            ? 0
            : theme.shape.borderRadius,
      padding: 4,
      justifyContent: 'center',
      display: 'inline-flex',
      cursor: 'pointer'
    })
  })
)

// TODO: Add proper "focused" styles
export function ElevatedIconButton(props: ElevatedIconButtonProps) {
  const { variant: _v = 'circle', children, ...rest } = props
  const classes = useStyles(props)

  return (
    <ButtonBase tabIndex={0}>
      <Paper className={classes.buttonStyle} {...rest}>
        {children}
      </Paper>
    </ButtonBase>
  )
}
