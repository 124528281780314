import { Hidden, BooleanControl } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useRef, useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { stringToHTML, useJourneyContext, useStepBlockId } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

type BooleanControlOptions = {
  clearPathOnChange?: string[]
  plainTextLabel?: boolean
}

export function BooleanControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible,
  id
}: EpilotControlProps) {
  const { context } = useJourneyContext()
  const { t } = useTranslation()
  const [checked, setChecked] = useState(data)
  const isInitialMount = useRef(true)

  const stepBlockId = useStepBlockId(path, id)

  useEffect(() => {
    if (isInitialMount.current) {
      // skip the effect on initial mount to prevent setting the value to schema.default when the journey goes back
      isInitialMount.current = false
    } else {
      setChecked(schema.default || false)
    }
  }, [schema.default])

  const { clearPathOnChange = [] } =
    (uischema.options as BooleanControlOptions) || {}
  const handleOnChange = (_event: ChangeEvent<HTMLInputElement>) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    if (required) {
      data ? handleChange(path, null) : handleChange(path, true)
    } else {
      handleChange(path, !data)
    }
    setChecked(!checked)
  }

  const { errorMessage } = schema
  const { options = {} } = uischema
  const label = options.label || uischema.label

  return (
    <Hidden xsUp={!visible}>
      <BooleanControl
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage === 'string'
              ? errorMessage
              : t('field_required')
            : ''
        }
        hideRequiredAsterisk={options?.hideRequiredAsterisk}
        id={stepBlockId}
        label={stringToHTML(
          label as string,
          {
            allowParagraphs: false
          },
          context._stepsStateArray,
          context._journeySources
        )}
        onChange={handleOnChange}
        required={required || options?.showRequiredAsterisk}
        typographyVariant={options?.typographyVariant || 'body1'}
        uiType={options.toggle ? 'switch' : 'checkbox'}
        value={checked ? true : false}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: BooleanControlPure
  }) as never
)
