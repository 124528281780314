import Warning from '@epilot360/icons/react/Warning'
import { useTranslation } from 'react-i18next'

import { useWidgetStyles } from './styles'
import { WidgetContainer } from './WidgetContainer'

export const NoDataWidget = () => {
  const classes = useWidgetStyles()
  const { t } = useTranslation()

  return (
    <WidgetContainer>
      <div className={classes.noDataTextContainer}>
        <Warning className={classes.warningIcon} />
        {t('rooftop_planner.no_data')}
      </div>
    </WidgetContainer>
  )
}
