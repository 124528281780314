import type { Step } from '../../types'

import { blockController } from './blockController'
import type { BlockLocatorDetails, Source, WithRequiredProperty } from './types'

/**
 * string source is considered a legacy one, but its format is used in multiple places
 * @todo Consider embeding this value in Source object itself
 */
export const getStringSourceFromObjectSource = (source: Source) =>
  `${source.stepIndex + 1}/${source.name}/${source.type}`

type BlockWithTypeAndUiSchema = WithRequiredProperty<
  BlockLocatorDetails,
  'type' | 'uischema'
>

/**
 * Get blocks, filter out those which do not have a name, type, controlNameId, or uiSchema
 * map them to sources
 */
export const generateSources = (
  sourceControl: string,
  steps: Step[],
  excludeList: string[] = []
): Source[] =>
  blockController
    .findBlocks(steps, { type: sourceControl })
    .filter(
      (block): block is BlockWithTypeAndUiSchema =>
        !!(
          block.controlNameId &&
          block.scope &&
          block.uischema &&
          block.type &&
          // filter those which are excluded by param
          !excludeList.includes(block.type)
        )
    )
    // map block locators to sources
    .map<Source>((block) => ({
      stepNumber: block.stepNumber,
      stepIndex: block.stepIndex,
      stepName: block.stepName,
      stepId: block.stepId,
      name: block.name,
      uischema: block.uischema,
      key: block.name,
      type: block.type,
      possibleValues: block.possibleValues,
      controlNameId: block.controlNameId,
      required: block.required,
      parent: block.parent
    }))
