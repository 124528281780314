import { useEffect, useRef, useState } from 'react'

export const useStickyHeight = (mobile: boolean) => {
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const [stickyHeight, setStickyHeight] = useState<number>(80)

  /*
   * This effect runs as a fallback to handle setting the sticky height on first load
   */
  useEffect(() => {
    setStickyHeight(stickyRef.current ? stickyRef.current.offsetHeight : 80)
  }, [stickyRef.current?.offsetHeight])

  useEffect(() => {
    const stickyElement = stickyRef.current

    const updateStickyHeight = () => {
      if (mobile) {
        setStickyHeight(stickyElement ? stickyElement.offsetHeight : 80)
      }
    }

    let observer: ResizeObserver | null = null

    try {
      observer = new ResizeObserver(updateStickyHeight)
      if (stickyElement) {
        observer.observe(stickyElement)
      }
    } catch (error) {
      console.error('ResizeObserver not supported', error)
    }

    return () => {
      if (observer && stickyElement) {
        observer.disconnect()
      }
    }
  }, [mobile])

  return { stickyRef, stickyHeight }
}
