import { Hidden } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect } from 'react'

import { useStepBlockId } from '../../..'
import { includeCommon } from '../../../utils/includeCommon'

import { MultichoiceControl } from './components/MultichoiceControl'
import type { MultichoiceControlComponentProps } from './types'

export function MultichoiceControlPure({
  data,
  handleChange,
  path,
  schema,
  uischema,
  errors,
  visible,
  required,
  id
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path, id)

  const options = (uischema.options || {}) as MultichoiceControlComponentProps
  const label = options.label || uischema.label || ''
  const defaultSelection = schema.default
  const handleOnChange = (value: unknown[]) => {
    handleChange(
      path,
      Array.isArray(value) && value.length > 0 ? value : undefined
    )
  }

  useEffect(() => {
    if (
      !data &&
      Array.isArray(defaultSelection) &&
      defaultSelection.length > 0
    ) {
      handleOnChange(defaultSelection)
    }
  }, [])

  return (
    <Hidden xsUp={!visible}>
      <MultichoiceControl
        {...options}
        errorMessage={errors}
        hasError={!!errors}
        id={stepBlockId}
        label={typeof label === 'string' ? label.trim() : ''}
        onChange={(v) => handleOnChange(v)}
        required={required}
        value={data}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: MultichoiceControlPure
  }) as never
)
