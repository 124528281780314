import type { Step } from '@epilot/journey-logic-commons'
import { CONTROL_NAME, blockController } from '@epilot/journey-logic-commons'
import type { Client } from '@epilot/metering-client'
import { useEffect, useMemo, useState } from 'react'

import { useConfig } from '../../../utils'
import type {
  BaseEntity,
  EntityClient,
  MeterEntityWithValidation,
  MeterEntity,
  MeterReadingValidation,
  ContractEntityWithValidation
} from '../../../utils/clients/entity-client'
import { useEntityClient } from '../../../utils/clients/entity-client'
import getMeteringClient from '../../../utils/clients/metering-client'
import type { EntityLookupControlData } from '../EntityLookupControl'

const STATIC_CONTRACTS = [
  {
    _id: 'XXXX',
    _schema: 'contract',
    contract_name:
      'Vertrag AA-BB-Eco (Es wird mit der Benutzerauswahl gefüllt)',
    contract_number: '343254',
    type: 'recurring',
    meters: [
      {
        _schema: 'meter',
        _id: 'YYY',
        counters: [
          {
            _id: 'MMMM',
            _schema: 'meter_counter',
            _org: '739224',
            obis_number: 'ER-99',
            direction: 'feed-out',
            tariff_type: 'one_tariff'
          }
        ],
        meter_number: 'XXXX-YYY-000 (Es wird mit der Benutzerauswahl gefüllt)',
        meter_type: 'alternating-current-meter',
        calibration_date: '2023-01-01T11:38:00.000Z',
        sector: 'power',
        direction: 'feed-in',
        tariff_type: 'et',
        validation: [
          {
            counter_id: 'MMMM',
            min_value: 0,
            max_value: 0
          }
        ]
      }
    ]
  }
] as unknown as ContractEntityWithValidation[]

type useGetContractsProps = {
  shouldCheck: boolean
  journeySteps: Step[]
  stepsHistoryStateArray: Record<string, unknown>[]
  contextEntities: Record<string, BaseEntity>
  isPreview?: boolean
}

type useGetContractsData = {
  contracts: ContractEntityWithValidation[]
  isLoading: boolean
}

export function useGetContracts({
  shouldCheck,
  journeySteps,
  stepsHistoryStateArray,
  contextEntities,
  isPreview
}: useGetContractsProps): useGetContractsData {
  const { client, isPortal } = useEntityClient()
  const { METERING_API_URL } = useConfig()

  const meteringApiClient = getMeteringClient(METERING_API_URL)

  const [state, setState] = useState<useGetContractsData>(
    isPreview
      ? { contracts: STATIC_CONTRACTS, isLoading: false }
      : {
          contracts: [],
          isLoading: false
        }
  )

  // those contracts are collected from all the entity lookup blocks in the journey which are of type contract
  // each block might include a single or multiple contracts
  const contracts = useMemo(() => {
    if (shouldCheck && !isPreview) {
      const contractLookupBlocks = blockController.findBlocks(journeySteps, {
        type: CONTROL_NAME.ENTITY_LOOKUP_CONTROL
      })

      const blockContracts = contractLookupBlocks.flatMap((block) => {
        // block data might be single or an array
        const lookupData = stepsHistoryStateArray[block.stepIndex]?.[
          block.name
        ] as EntityLookupControlData | EntityLookupControlData[]

        if (Array.isArray(lookupData)) {
          return lookupData.map((data) => data?.entity).filter(Boolean)
        } else {
          return lookupData?.entity ? [lookupData?.entity] : []
        }
      })

      const contextContracts = Object.values(contextEntities).filter(
        (entity) => {
          return entity._schema === 'contract'
        }
      )

      return [...blockContracts, ...contextContracts]
    }

    return []
  }, [shouldCheck, journeySteps, stepsHistoryStateArray, isPreview])

  useEffect(() => {
    if (
      shouldCheck &&
      state.contracts.length !== contracts.length &&
      !isPreview &&
      !state.isLoading
    ) {
      setState((prev) => ({ ...prev, isLoading: true }))
      // fetching meters
      Promise.all(
        contracts.map(async (singleContract) => {
          if (isPortal) {
            // return meters from portal
            const res = await meteringApiClient
              .getMetersByContractId(singleContract._id)
              .then(({ data }) => data.data)
            const meters = (res || []) as MeterEntity[]

            return {
              ...singleContract,
              meters: meters.map((meter: MeterEntity) =>
                filterOutCountersWithoutId(meter)
              )
            } as BaseEntity
          } else {
            // return meters from 360
            const res = await (client as EntityClient).searchEntities(null, {
              q: `_schema:meter AND contract.$relation.entity_id:"${singleContract._id}"`,
              size: 20,
              sort: '_created_at:asc',
              hydrate: true
            })

            const results = (res?.data?.results || []) as MeterEntity[]

            return {
              ...singleContract,
              meters: results.map((meter: MeterEntity) =>
                filterOutCountersWithoutId(meter)
              )
            } as BaseEntity
          }
        })
      ).then((contractWithMeters) => {
        Promise.all(
          contractWithMeters.map(async (contract) => {
            const metersWithValidations = await Promise.all(
              contract.meters.map(async (meter: MeterEntity) => {
                const validation = await getReadingValidation(
                  meteringApiClient,
                  meter._id
                )

                return {
                  ...meter,
                  validation
                } as MeterEntityWithValidation
              })
            )

            return {
              ...contract,
              meters: metersWithValidations
            } as ContractEntityWithValidation
          })
        ).then((contractsWithValidation) => {
          setState({ contracts: contractsWithValidation, isLoading: false })
        })
      })
    }
  }, [
    contracts,
    isPortal,
    shouldCheck,
    client,
    state?.contracts?.length,
    isPreview,
    state.isLoading,
    meteringApiClient
  ])

  return state
}

function filterOutCountersWithoutId(meter: MeterEntity) {
  const counters = meter.counters.filter((counter) => counter._id)

  return {
    ...meter,
    counters
  }
}

async function getReadingValidation(
  meteringApiClient: Client,
  meterId: string
): Promise<MeterReadingValidation[] | undefined> {
  const res = await meteringApiClient
    .getAllowedReadingForMeter({
      meter_id: meterId
    })
    .then(({ data }) => data.data)

  return res
}
