import { clsx, Typography } from '@epilot/journey-elements'
import Big from 'big.js'
import type { TFunction } from 'i18next'
import { useEffect, useMemo } from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'
import { useWatch } from 'react-hook-form'

import { useTotalConsumptionStyles } from '../styles'
import type { InputCalculatorFormValues } from '../types'
import { formatConsumptionToLocaleString } from '../utils'

type TotalConsumptionCalculationProps = {
  control: Control<InputCalculatorFormValues>
  unit: string
  setValue: UseFormSetValue<InputCalculatorFormValues>
  t: TFunction
  digitsAfterDecimalPoint?: number
  isMobile?: boolean
  showDeleteButton: boolean
}

export const TotalConsumptionCalculation = ({
  control,
  unit: initialUnit,
  setValue,
  t,
  digitsAfterDecimalPoint,
  isMobile,
  showDeleteButton
}: TotalConsumptionCalculationProps) => {
  const items = useWatch({ control, name: 'devices' })
  const unit = initialUnit === 'noUnit' ? '' : initialUnit
  const classes = useTotalConsumptionStyles()

  const totalSumConsumption = useMemo(() => {
    if (!items || items?.length === 0) {
      return undefined
    }

    const sum = items.reduce(
      (acc, item) => acc.plus(new Big(item.consumption || '0')),
      new Big(0)
    )

    return sum.round(digitsAfterDecimalPoint ?? 2).toString()
  }, [items])

  useEffect(() => {
    setValue(`numberInput`, totalSumConsumption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSumConsumption])

  return (
    <Typography
      className={clsx(
        classes.container,
        !isMobile && classes.desktopContainer,
        !showDeleteButton && classes.noDeleteButton
      )}
      color="inherit"
      variant="body2"
    >
      {totalSumConsumption !== undefined ? (
        <b>
          {`${t(
            'input_calculator.total',
            'Total'
          )} ${formatConsumptionToLocaleString(
            totalSumConsumption,
            digitsAfterDecimalPoint
          )} ${t(`units.${unit}`, unit)}`}
        </b>
      ) : (
        ''
      )}
    </Typography>
  )
}
