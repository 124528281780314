import classNames from 'classnames'
import { forwardRef, useState } from 'react'
import type { FocusEvent } from 'react'

import classes from './TextField.module.scss'
import type { TextFieldCSSProperties, TextFieldProps } from './types'

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {
      label,
      startAdornment,
      endAdornment,
      containerProps,
      className,
      isRequired,
      isDisabled,
      isError,
      helperText,
      color,
      backgroundColor,
      borderColor,
      errorColor,
      style,
      id,
      onBlur,
      onFocus,
      ...inputProps
    } = props

    const [isFocused, setIsFocused] = useState(false)

    const customColors: TextFieldCSSProperties = {
      '--text-field-color': color,
      '--text-field-background-color': backgroundColor,
      '--text-field-border-color': borderColor,
      '--text-field-error-color': errorColor
    }

    const customStyles = {
      ...style,
      ...customColors
    }

    function handleFocus(event: FocusEvent<HTMLInputElement>) {
      if (isDisabled) {
        event.preventDefault()

        return
      }
      setIsFocused(true)
      if (onFocus) onFocus(event)
    }

    function handleBlur(event: FocusEvent<HTMLInputElement>) {
      if (isDisabled) {
        event.preventDefault()

        return
      }
      setIsFocused(false)
      if (onBlur) onBlur(event)
    }

    return (
      <div
        {...containerProps}
        className={classNames(
          'Concorde-TextField',
          classes.root,
          containerProps?.className
        )}
        style={customStyles}
      >
        <div
          className={classNames(
            'Concorde-TextField__Input-Root',
            classes['input-root'],
            startAdornment && classes['input-root-start'],
            endAdornment && classes['input-root-end'],
            isDisabled && classes['input-root-disabled'],
            isError && classes['input-root-error'],
            isFocused && classes['input-root-focused']
          )}
        >
          {startAdornment && (
            <div
              className={classNames(
                'Concorde-TextField__Adornment',
                'Concorde-TextField__Adornment-Start',
                classes['adornment'],
                classes['adornment-start']
              )}
            >
              {startAdornment}
            </div>
          )}
          <div className={classes['input-container']}>
            {
              <label
                className={classNames(
                  'Concorde-TextField__Label',
                  classes['label'],
                  startAdornment && classes['label-start'],
                  endAdornment && classes['label-end'],
                  isDisabled && classes['label-disabled']
                )}
                htmlFor={id}
              >
                {label}
                {isRequired && (
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isError && !isDisabled && classes['error']
                    )}
                  >
                    &thinsp;*
                  </span>
                )}
              </label>
            }
            <input
              {...inputProps}
              aria-hidden={isDisabled}
              className={classNames(
                'Concorde-TextField__Input',
                classes['input'],
                label && classes['input-label'],
                isError && !isFocused && classes['input-error'],
                isDisabled && classes['input-disabled'],
                className
              )}
              disabled={isDisabled}
              id={id}
              onBlur={handleBlur}
              onFocus={handleFocus}
              ref={ref}
            />
          </div>
          {endAdornment && (
            <div
              className={classNames(
                'Concorde-TextField__Adornment',
                'Concorde-TextField__Adornment-End',
                classes['adornment'],
                classes['adornment-end']
              )}
            >
              {endAdornment}
            </div>
          )}
        </div>
        {helperText && (
          <p
            className={classNames(
              'Concorde-TextField__HelperText',
              classes['helper-text'],
              isError && !isDisabled && classes['error']
            )}
          >
            {helperText}
          </p>
        )}
      </div>
    )
  }
)

TextField.displayName = 'TextField'
