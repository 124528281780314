import type { Components, Client } from '@epilot/entity-client'
import { getClient } from '@epilot/entity-client'
import { journeyStorage } from '@epilot/journey-logic-commons'
import type { AxiosInstance, AxiosRequestHeaders } from 'axios'
import { useMemo } from 'react'

import { usePortalCheck } from '../../renderers/controls/MeterReadingControl/usePortalCheck'
import { AUTH_TOKEN_OVERRIDE } from '../../utils/services/config'
import { useConfig } from '../context'

import getCustomerClient from './customer-portal-client'
import type { CustomerPortalClient } from './customer-portal-client'

interface AxiosClientOpts {
  baseURL?: string
  allowXOrgSharing?: boolean
}

const configureClient = <ClientType extends AxiosInstance>(
  client: ClientType,
  opts: AxiosClientOpts = {}
) => {
  // set API url
  if (opts.baseURL) {
    client.defaults.baseURL = opts.baseURL
  }

  // add authorization header
  // TODO remove the static token
  client.interceptors.request.use(async (request) => {
    const token = journeyStorage.getItem('token')

    if (!request.headers) {
      request.headers = {} as AxiosRequestHeaders
    }

    request.headers['authorization'] = `Bearer ${token}`

    if (AUTH_TOKEN_OVERRIDE) {
      request.headers['authorization'] = `Bearer ${AUTH_TOKEN_OVERRIDE}`
    }

    return request
  })

  return client
}

function getEntityClient(apiUrl: string): Client {
  const client = getClient()

  return configureClient(client, {
    baseURL: apiUrl,
    allowXOrgSharing: true
  })
}

export async function getSchemaBySlug(schemaSlug: string, client: Client) {
  return await client.getSchema({
    slug: schemaSlug
  })
}

export const useEntityClient = (): {
  client: EntityClient | CustomerPortalClient
  isPortal: boolean
} => {
  const { isECPPortal } = usePortalCheck()
  const { CUSTOMER_PORTAL_API_URL, ENTITY_API_URL } = useConfig()

  return useMemo(() => {
    if (isECPPortal) {
      return {
        client: getCustomerClient(CUSTOMER_PORTAL_API_URL),
        isPortal: true
      }
    } else {
      return {
        client: getEntityClient(ENTITY_API_URL),
        isPortal: false
      }
    }
  }, [isECPPortal, CUSTOMER_PORTAL_API_URL, ENTITY_API_URL])
}

export type EntityClient = Client
export type TaxonomyClassification = Components.Schemas.TaxonomyClassification
export type ClassificationId = Components.Schemas.ClassificationId
export type EntitySearchParams = Components.Schemas.EntitySearchParams
export type EntityItem = Components.Schemas.EntityItem
export type BaseEntity = Components.Schemas.BaseEntity
export type EntityAttribute = Components.Schemas.Attribute & {
  id?: string
}
export type StatusAttribute = EntityAttribute &
  Components.Schemas.StatusAttribute
export type SelectAttribute = EntityAttribute &
  Components.Schemas.SelectAttribute

export type MultiSelectAttribute = EntityAttribute &
  Components.Schemas.MultiSelectAttribute

export type MeterEntity = Components.Schemas.EntityItem & {
  ma_lo_id: string
  meter_number: string
  _id: string
  counters: MeterCounterEntity[]
}

export type ContractEntityWithValidation = BaseEntity & {
  meters: MeterEntityWithValidation[]
}
export type MeterReadingValidation = {
  counter_id?: string
  min_value?: number
  max_value?: number
}

export type MeterEntityWithValidation = MeterEntity & {
  validation: MeterReadingValidation[]
}

export type MeterCounterEntity = Components.Schemas.EntityItem & {
  tariff_type: 'ht' | 'nt' | 'et'
  direction: 'feed-in' | 'feed-out'
  unit?: string
}
