import { formatDiscount } from '@epilot/journey-logic-commons'
import classNames from 'classnames'
import { useMemo } from 'react'
import type { ReactNode } from 'react'

import { Icon, Typography, Spacing } from '../../../../../components'
import classes from '../../../styles/ProductCoupon.module.scss'
import { useTileContext } from '../../../utils/tile-context-utils'

export const ProductCoupon = () => {
  const {
    config: { coupons },
    pricingDetails
  } = useTileContext()

  const coupon = useMemo(() => {
    const [firstItem] = pricingDetails.items ?? []

    /**
     * Coupon should only be displayed if it results in an actual discount being applied
     * (note that in an initial phase composite prices don't get discounts applied)
     */
    if (
      typeof firstItem?.discount_amount === 'number' &&
      firstItem?.discount_amount > 0
    ) {
      const [firstCoupon] = coupons

      return firstCoupon ?? null
    }

    return null
  }, [coupons, pricingDetails.items])

  if (!coupon) return null

  return (
    <div
      className={classNames(classes.couponContainer, classes.couponPosition)}
    >
      <Spacing alignItems="center" scale={2} variant="inline">
        {coupon.type === 'percentage' && (
          <Icon
            className={classes.discountSymbol}
            isFilled
            name="sell"
            size="16"
          />
        )}
        <Typography className={classes.discountText}>
          {formatDiscount(coupon)}
        </Typography>
      </Spacing>
    </div>
  )
}

type ProductCouponWrapperProps = {
  children: ReactNode
}

export const ProductCouponWrapper = ({
  children
}: ProductCouponWrapperProps) => {
  return (
    <div className={classes.container}>
      <ProductCoupon />
      {children}
    </div>
  )
}
