import { Typography } from '@epilot/journey-elements'
import Big from 'big.js'
import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import type { Control, UseFormSetValue } from 'react-hook-form'

import { useDisplayConsumptionStyles } from '../styles'
import type { InputCalculatorFormValues, HydratedDeviceOption } from '../types'
import {
  calculateDeviceConsumption,
  formatConsumptionToLocaleString
} from '../utils'

type ConsumptionCalculationProps = {
  control: Control<InputCalculatorFormValues>
  unit: string
  index: number
  setValue: UseFormSetValue<InputCalculatorFormValues>
  consumptionOptions: HydratedDeviceOption[]
  digitsAfterDecimalPoint?: number
}

export const ConsumptionCalculation = ({
  index,
  control,
  setValue,
  unit,
  consumptionOptions,
  digitsAfterDecimalPoint
}: ConsumptionCalculationProps) => {
  const watchedItem = useWatch({
    control,
    name: `devices.${index}`
  })

  useEffect(() => {
    const deviceName = watchedItem.name
    const quantity = watchedItem.quantity || '0'
    const unitaryDemand = watchedItem.unitaryConsumption || '0'
    const hydratedDeviceOption = consumptionOptions.find(
      (option) => option.name === deviceName
    )

    const { total, fixedUnitaryConsumption } = calculateDeviceConsumption(
      quantity,
      unitaryDemand,
      hydratedDeviceOption
    )

    if (
      typeof fixedUnitaryConsumption === 'number' &&
      new Big(fixedUnitaryConsumption).round(2) !==
        new Big(unitaryDemand).round(2)
    ) {
      setValue(
        `devices.${index}.unitaryConsumption`,
        quantity ? fixedUnitaryConsumption.toString() : '0'
      )
    }
    setValue(`devices.${index}.consumption`, total)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedItem.name, watchedItem.quantity, watchedItem.unitaryConsumption])

  return (
    <DisplayConsumption
      consumption={watchedItem.consumption}
      digitsAfterDecimalPoint={digitsAfterDecimalPoint}
      unit={unit}
    />
  )
}

const DisplayConsumption = ({
  consumption,
  unit,
  digitsAfterDecimalPoint
}: {
  consumption?: string
  unit: string
  digitsAfterDecimalPoint?: number
}) => {
  const classes = useDisplayConsumptionStyles()

  return (
    <Typography className={classes.consumption} color="textPrimary">
      {consumption !== undefined
        ? `${formatConsumptionToLocaleString(
            consumption,
            digitsAfterDecimalPoint
          )} ${unit === 'noUnit' ? '' : unit}`
        : ''}
    </Typography>
  )
}
