import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  image: {
    width: '100%',
    borderRadius: '5px'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    fontStyle: 'italic',
    color: '#8C9DAD'
  },
  mobileDiv: {
    flexBasis: '100%'
  },
  nonMobileDiv: (props: { width: string }) => ({
    flexBasis: props.width
  })
})
