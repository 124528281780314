import type { NumberInputControlData, NumberInputControlOptions } from './types'

/**
 * Form field identifier.
 */
export const NUMBER_INPUT_FIELD_NAME = 'numberInput'

/**
 * Convert the number data.
 * @param data
 * @param options
 * @returns
 */
export function convertNumberInputData(
  data: NumberInputControlData,
  options: NumberInputControlOptions
): NumberInputControlData {
  return {
    numberInput: data?.numberInput?.replace(',', '.'),
    numberUnit: options?.fields?.numberInput?.unit?.label,
    frequencyUnit: options?.fields?.numberInput?.frequencyUnit,
    _isValid: data?._isValid
  }
}

/**
 * Parse number data.
 * @returns
 */
export function parseNumberInputData(
  data: NumberInputControlData,
  options: NumberInputControlOptions
): NumberInputControlData {
  if (data?.numberInput) {
    return {
      numberInput: data?.numberInput?.replace('.', ','),
      numberUnit: options.fields.numberInput.unit?.label
    }
  }

  return data
}

/**
 * Generates placeholder based on format.
 */
export function generatePlaceholderBasedOnFormat(
  digitsBeforeDecimalPoint = 1,
  decimalPlaces = 0
) {
  const placeholder: string[] = []

  for (let i = 0; i < digitsBeforeDecimalPoint; i++) {
    placeholder.push('0')
  }

  if (decimalPlaces > 0) {
    placeholder.push(',')

    for (let i = 0; i < decimalPlaces; i++) {
      placeholder.push('0')
    }
  }

  return placeholder.join('')
}

export const formatNumberToStr = (value?: number) => {
  return value?.toString()?.replace('.', ',')
}
