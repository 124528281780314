import { useState, useEffect, useRef } from 'react'

const useResizeDirection = () => {
  const [direction, setDirection] = useState('row')
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function checkOverflow() {
      if (!containerRef.current) return
      const buttons = containerRef.current.querySelectorAll('button')

      if (!buttons.length) return

      for (const button of buttons) {
        const textDiv = button.querySelector('.buttonText')

        if (textDiv && textDiv.scrollWidth + 40 > button.offsetWidth) {
          setDirection('column')
          break
        } else {
          setDirection('row')
        }
      }
    }

    checkOverflow()

    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  return [direction, containerRef]
}

export default useResizeDirection
