import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      width: '100%',
      // make the label full width
      '& .MuiFormControlLabel-root': {
        width: '100%',
        alignItems: 'center',
        // make second span child full width
        '& > span:nth-child(2)': {
          width: '100%'
        }
      },
      padding: `${theme.spacing(2)}px`,
      '&:hover': {
        borderColor: theme.palette.primary.main
      }
    },
    selectedCard: {
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      boxShadow:
        '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)'
    },
    radio_group: {
      width: '100%'
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '20px'
    },
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)
