import { createStyles, makeStyles, clsx } from '@epilot/journey-elements'
import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import type { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'

const useMarkdownRendererStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    content: {
      whiteSpace: 'break-spaces',
      '& ul, & ol': {
        paddingLeft: theme.spacing(4)
      },
      '& ul, & ol, & p': {
        marginTop: 0,
        marginBottom: theme.spacing(4)
      }
    }
  })
)

export const MarkdownRenderer = ({
  className,
  ...props
}: ComponentProps<typeof ReactMarkdown>) => {
  const classes = useMarkdownRendererStyles()

  return (
    <ReactMarkdown
      className={clsx(classes.content, className)}
      /* Ensure h1 is not abused */
      components={{ h1: 'h2' }}
      {...props}
    />
  )
}
