import {
  Grid,
  Button,
  CircularProgress,
  useTheme,
  Icon,
  clsx
} from '@epilot/journey-elements'
import type {
  ActionGoBack,
  ActionGoNext,
  ActionSubmitAndGoNext
} from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useEffect, useRef, useState } from 'react'

import { useNavigationContext } from '../../../utils/context'
import { useResize } from '../../../utils/hooks/useResize'

import { useLoadingListener } from './hooks'
import { useStyles } from './styles'

type ActionButtonsProps = {
  ctaButton?: ActionGoNext | ActionSubmitAndGoNext
  goBackButton?: ActionGoBack
  id: string
}

const ActionButtons = ({ ctaButton, goBackButton, id }: ActionButtonsProps) => {
  const loading = useLoadingListener()

  const { onAction } = useNavigationContext()
  const [isNavigatingDisabled, setIsNavigatingDisabled] = useState(true)

  // used to position ctaButton depending on containers width.
  // I.e. will be fullWidth by default in the sidebar
  const ref = useRef<null | HTMLDivElement>(null)
  const { width: containerWidth } = useResize(ref)
  const { breakpoints } = useTheme()
  const isMobile = containerWidth < breakpoints.values.sm
  const classes = useStyles()

  /**
   * @todo Remove setTimeout within useEffect, might cause subtle bugs
   */
  useEffect(() => {
    setTimeout(() => {
      setIsNavigatingDisabled(false)
    }, 200)
  }, [])

  return (
    <div className={classes.container} ref={ref}>
      {containerWidth > 0 && (
        <Grid
          alignItems="center"
          container
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent={!goBackButton?.isVisible ? 'flex-end' : 'flex-start'}
          spacing={1}
        >
          <>
            {goBackButton?.isVisible && (
              <Grid item xs={isMobile ? 12 : 8}>
                <Button
                  className="js-next"
                  color="primary"
                  disabled={loading}
                  id={appendStepBlockId(id, 'Go Back Button')}
                  onClick={() => {
                    if (!isNavigatingDisabled) {
                      onAction(goBackButton)
                    }
                  }}
                  size="large"
                  startIcon={<Icon name="arrow_back" />}
                >
                  {goBackButton.label}
                </Button>
              </Grid>
            )}
            {ctaButton?.isVisible && (
              <Grid container item xs={isMobile ? 12 : 4}>
                <Button
                  className={clsx('js-next', classes.ctaButton)}
                  color="primary"
                  disabled={loading}
                  endIcon={
                    loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null
                  }
                  id={appendStepBlockId(id, 'CTA Button')}
                  onClick={() => {
                    if (!isNavigatingDisabled) {
                      onAction(ctaButton)
                    }
                  }}
                  size="large"
                  variant="contained"
                >
                  {ctaButton.label}
                </Button>
              </Grid>
            )}
          </>
        </Grid>
      )}
    </div>
  )
}

export default ActionButtons
