import type { Client, Components } from '@epilot/customer-portal-client'
import { getClient } from '@epilot/customer-portal-client'
import { journeyStorage } from '@epilot/journey-logic-commons'
import type { AxiosRequestHeaders } from 'axios'

export const getToken = (): string | null => journeyStorage.getItem('token')

export const getPortalId = (): string | null =>
  journeyStorage.getItem('PORTAL_ID_LS_KEY')

type ExtendedClient = Client & {
  searchEntities: Client['searchPortalUserEntities']
}
let CustomerApiClient: ExtendedClient | null = null

const getCustomerClient = (apiUrl: string) => {
  if (CustomerApiClient) return CustomerApiClient

  CustomerApiClient = getClient() as ExtendedClient
  CustomerApiClient.defaults.baseURL = apiUrl

  CustomerApiClient.searchEntities = CustomerApiClient.searchPortalUserEntities

  const handlers = (CustomerApiClient.interceptors.request as any)['handlers']

  if (handlers.length > 3) {
    console.error(
      'Sanity check: This client already has more than 3 request interceptors, refusing to add more!',
      {
        baseURL: CustomerApiClient.defaults.baseURL,
        interceptors: CustomerApiClient.interceptors
      }
    )
  } else {
    CustomerApiClient.interceptors.request.use((request) => {
      const token = getToken()

      const portalId = getPortalId()

      const isNotConfigRequest = !request.url?.endsWith('/public/config')

      if (!request.headers) {
        request.headers = {} as AxiosRequestHeaders
      }

      if (portalId && isNotConfigRequest) {
        request.headers['x-portal-id'] = portalId
      }

      if (token) {
        request.headers['authorization'] = `Bearer ${token}`
      }

      return request
    })
  }

  return CustomerApiClient
}

export default getCustomerClient
export type CustomerPortalClient = Client
export type EntitySlug = Components.Schemas.EntitySlug
