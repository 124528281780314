import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Theme } from '@epilot/journey-elements'
import {
  areProductImagesHydrated,
  getPublicUrl
} from '@epilot/journey-logic-commons'
import type { Product } from '@epilot/journey-logic-commons'

import { DefaultProductImage } from '../../../../components/Product'

interface CartItemImageProps {
  product?: Product
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      height: 'auto',
      aspectRatio: '3/2',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
      [theme.breakpoints.down('md')]: {
        maxHeight: '200px'
      }
    }
  })
)

export const CartItemImage = ({ product }: CartItemImageProps) => {
  const classes = useStyles()

  if (
    !areProductImagesHydrated(product) ||
    product.product_images.filter((image) => image.access_control !== 'private')
      .length === 0
  ) {
    return <DefaultProductImage className={classes.image} />
  }

  const images = product.product_images.filter(
    (image) => image.access_control !== 'private'
  )

  const [image] = images

  return (
    <img
      alt={image.filename}
      className={classes.image}
      src={getPublicUrl(image)}
    />
  )
}
