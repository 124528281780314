import { v4 as uuidv4 } from 'uuid'

import { env } from '../utils/config'

import { getAnalyticsClient } from './client'
import type {
  AnalyticsEvent,
  CreateSessionPayload,
  SessionDetails
} from './types'
import { fetchBrowserDetails } from './utils'

const analyticsCategoryPayload = {
  category: 'journey',
  categoryDetails: {}
}

const EVENT_QUEUE_THRESHOLD = 5
const EVENTS_FLUSH_DURATION = 15000

let currentSession: SessionDetails
let eventQueue: AnalyticsEvent[] = []
let journeyToken: string
let timeoutId

export const setJourneyToken = (token: string) => {
  journeyToken = token
}

export const createSession = async (
  createSessionPayload: CreateSessionPayload
): Promise<void> => {
  try {
    const { embeddedIn, journeyId, sessionStartTime, journeyName } =
      createSessionPayload
    const analyticsClient = getAnalyticsClient(journeyToken)
    const browserDetails = await fetchBrowserDetails()

    const sessionDetails = {
      ...browserDetails,
      embeddedIn,
      journeyName
    }

    const session = {
      id: uuidv4(),
      startTime: sessionStartTime.toISOString(),
      details: sessionDetails
    }

    analyticsCategoryPayload.categoryDetails = { journeyId }

    const createSessionRequestBody = {
      ...analyticsCategoryPayload,
      session
    }

    await analyticsClient.createSession({}, createSessionRequestBody)

    currentSession = session
  } catch (err) {
    console.error(`Failed to create session: ${err}`)
  }
}

const processEventsQueue = async () => {
  try {
    const { id } = currentSession

    if (!id) {
      console.error('Cannot add events without a session')

      return
    }

    const analyticsClient = getAnalyticsClient(journeyToken)
    const addEventsRequestBody = {
      ...analyticsCategoryPayload,
      events: [...eventQueue]
    }

    await analyticsClient.addEvents({ sessionId: id }, addEventsRequestBody)

    eventQueue = []
  } catch (err) {
    console.error(`Failed to add events: ${err}`)
  } finally {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

export const addEvents = (events: AnalyticsEvent[]) => {
  try {
    eventQueue.push(...events)

    if (eventQueue.length >= EVENT_QUEUE_THRESHOLD) {
      processEventsQueue()
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(processEventsQueue, EVENTS_FLUSH_DURATION)
    }
  } catch (err) {
    console.error(`Failed to queue events: ${err}`)
  }
}

export const endSession = () => {
  try {
    const { id } = currentSession

    if (!id) {
      console.error('Cannot terminate session without an existing session')

      return
    }

    const addEventsRequestBody = {
      ...analyticsCategoryPayload,
      events: [
        ...eventQueue,
        {
          type: 'journey_exit',
          details: {},
          timestamp: new Date().toISOString()
        }
      ]
    }

    const updateSessionRequestBody = {
      ...analyticsCategoryPayload,
      session: {
        ...currentSession,
        endTime: new Date().toISOString()
      }
    }

    // fetch is used instead of analyticsClient to leverage the keepalive option on page unload
    fetch(
      `${env(
        'REACT_APP_ANALYTICS_API_BASE_URL'
      )}/v1/analytics/session/${id}/events`,
      {
        method: 'POST',
        body: JSON.stringify(addEventsRequestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${journeyToken}`
        },
        keepalive: true
      }
    )

    fetch(
      `${env('REACT_APP_ANALYTICS_API_BASE_URL')}/v1/analytics/session/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(updateSessionRequestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${journeyToken}`
        },
        keepalive: true
      }
    )
  } catch (err) {
    console.error(`Failed to end session: ${err}`)
  }
}
