import type {
  StreetSuggestion as StreetSuggestionDto,
  GridProps
} from '@epilot/journey-elements'
import type {
  AddressControlOptionsDto as AddressControlOptionsDtoInitial,
  AddressControlOptionsLegacy as AddressControlOptionsLegacyInitial,
  AddressControlOptions,
  Address,
  RendererReturnValue
} from '@epilot/journey-logic-commons'
import type { UseControllerProps } from 'react-hook-form'

export type AddressControlOptionsDto =
  AddressControlOptionsDtoInitial<GridProps>
export type AddressControlOptionsLegacy =
  AddressControlOptionsLegacyInitial<GridProps>

/** @see {isAddressControlData} ts-auto-guard:type-guard */
export type AddressControlData = Address | undefined | null

export type AddressFormProps = AddressControlOptions & {
  path: string
  data: AddressControlData
  hasError: boolean
  isRequired: boolean
  handleChange: (path: string, value: RendererReturnValue<Address>) => void
  id: string
}

/** @see {isZipCitySuggestion} ts-auto-guard:type-guard */
export type ZipCitySuggestion = {
  postal_code: string
  city: string
  uuid?: string
}

export type SuburbSuggestion = {
  uuid?: string
  postal_code: string
  city: string
  district: string
}

/** @see {isZipCitySuggestionData} ts-auto-guard:type-guard */
export type ZipCitySuggestionData = ZipCitySuggestion[]

/** @see {isStreetSuggestionData} ts-auto-guard:type-guard */
export type StreetSuggestionData = StreetSuggestionDto[]

/** @see {isSuburbSuggestionData} ts-auto-guard:type-guard */
export type SuburbSuggestionData = SuburbSuggestion[]

export enum FeedBackType {
  STREETS = 'streets',
  POSTAL_CODE_OR_CITY = 'postalcode-cities'
}

export type CustomValidationRules = {
  rules?: UseControllerProps['rules']
  errorMessages?: Record<string, string>
}
