import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles, Typography } from '@epilot/journey-elements'
import type { Price } from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  hasPricingModel,
  hasTieredPricingModel
} from '@epilot/journey-logic-commons'
import { formatPriceUnit, PricingModel } from '@epilot/pricing'
import { useMemo } from 'react'

import { useTileContext } from '../../../utils/tile-context-utils'

import { PriceTierDetails } from './PriceTierDetails'

interface PriceItemComponentProps {
  priceComponent: Price
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    priceComponent: {
      color: theme.palette.text.secondary,
      fontSize: 12
    }
  })
)

export const PriceItemComponent = ({
  priceComponent
}: PriceItemComponentProps) => {
  const { actions, config } = useTileContext()

  const classes = useStyles()

  const displayUnitAmount = useMemo(() => {
    if (priceComponent.pricing_model === PricingModel.externalGetAG) {
      return getDisplayPrice(
        actions.t,
        priceComponent?.unit_amount_gross_decimal,
        priceComponent.price_display_in_journeys,
        config.generalTileConfiguration.showTrailingDecimalZeros,
        priceComponent.unit_amount_currency
      )
    }

    return getDisplayPrice(
      actions.t,
      priceComponent?.unit_amount_gross_decimal,
      priceComponent._price.price_display_in_journeys,
      config.generalTileConfiguration.showTrailingDecimalZeros,
      priceComponent._price.unit_amount_currency
    )
  }, [
    priceComponent.pricing_model,
    priceComponent?.unit_amount_gross_decimal,
    priceComponent._price.price_display_in_journeys,
    priceComponent._price.unit_amount_currency,
    priceComponent.price_display_in_journeys,
    priceComponent.unit_amount_currency,
    actions.t,
    config.generalTileConfiguration.showTrailingDecimalZeros
  ])

  const billingPeriod =
    !priceComponent._price.variable_price &&
    priceComponent._price.type !== 'one_time' &&
    getPriceType(priceComponent.type, priceComponent.billing_period)
  const unit = `/${actions.t(
    formatPriceUnit(priceComponent._price.unit),
    formatPriceUnit(priceComponent._price.unit)
  )}`

  return (
    <Typography
      className={classes.priceComponent}
      data-testid="price-item-amount"
    >
      {hasPricingModel(PricingModel.perUnit, priceComponent) && (
        <>
          <b>
            {displayUnitAmount}
            {priceComponent._price.variable_price && unit}
          </b>
          {billingPeriod
            ? ` ${actions.t(billingPeriod).toLocaleLowerCase()} - `
            : ' - '}
          {priceComponent.description}
        </>
      )}
      {hasTieredPricingModel(priceComponent) && (
        <PriceTierDetails priceItem={priceComponent} />
      )}
      {hasPricingModel(PricingModel.externalGetAG, priceComponent) && (
        <>
          <b>
            {displayUnitAmount}
            {priceComponent.get_ag?.type === 'work_price' && unit}
          </b>
          {billingPeriod
            ? ` ${actions.t(billingPeriod).toLocaleLowerCase()} - `
            : ' - '}
          {priceComponent.description}
        </>
      )}
    </Typography>
  )
}
