import type { Theme } from '@material-ui/core'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  makeStyles,
  createStyles
} from '@material-ui/core'

import type { CheckboxProps } from './types'

const useStyles = makeStyles<Theme, { checkboxPlacement: string }>(() =>
  createStyles({
    labelRoot: {
      marginLeft: 0,
      marginRight: 0
    },
    input: ({ checkboxPlacement }) => ({
      alignSelf:
        checkboxPlacement === 'top'
          ? 'flex-start'
          : checkboxPlacement === 'bottom'
            ? 'flex-end'
            : ''
    })
  })
)

export function Checkbox(props: CheckboxProps) {
  const { label, labelPlacement, checkboxPlacement = 'top', ...rest } = props

  const classes = useStyles({ checkboxPlacement })

  return (
    <FormControlLabel
      classes={{ root: classes.labelRoot }}
      control={<MuiCheckbox className={classes.input} {...rest}></MuiCheckbox>}
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}
