import {
  Hidden,
  StackSpacing,
  Link,
  makeStyles
} from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { useJourneyContext } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'

import { useGenerateFile } from './hooks/useGenerateFile'
import { getVariablesData } from './utils/getVariablesData'
export interface Variable {
  path: string
  block: {
    stepIndex: number
    stepId: string
    blockId: string
    blockName: string
  }
}

export const usePdfSummaryControlStyles = makeStyles({
  link: {
    cursor: 'pointer'
  },
  disabledLink: {
    pointerEvents: 'none',
    color: 'gray'
  }
})

export function PdfSummaryControlComponent({
  uischema,
  visible
}: EpilotControlProps) {
  const { t } = useTranslation()
  const classes = usePdfSummaryControlStyles()
  const {
    context: { _stepsHistoryStateObject }
  } = useJourneyContext()
  const { generateFile, isLoading } = useGenerateFile()

  const { templateId, variables } = uischema?.options || {}
  const data = getVariablesData(_stepsHistoryStateObject, variables)

  const downloadFile = async () => {
    try {
      const response = await generateFile({ fileId: templateId, data })

      const fileUrl = response.data?.pdf_output?.preview_url

      if (!fileUrl) {
        throw new Error('No file url found')
      }

      const existingLink = document.querySelector(
        'a[download="journey-summary.pdf"]'
      )

      if (existingLink) {
        document.body.removeChild(existingLink)
      }

      const link = document.createElement('a')

      link.href = fileUrl

      link.setAttribute('download', 'journey-summary.pdf')
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading file', error)
      // Handle error
    }
  }

  if (!templateId) {
    return null
  }

  return (
    <Hidden xsUp={!visible}>
      <StackSpacing alignItems="stretch" scale={2}>
        <Link
          className={isLoading ? classes.disabledLink : classes.link}
          onClick={downloadFile}
        >
          {t('Download journey summary', 'Download journey summary')}
        </Link>
      </StackSpacing>
    </Hidden>
  )
}

export const PdfSummaryControl = withQueryProvider<EpilotControlProps>(
  PdfSummaryControlComponent
)

export default withJsonFormsControlProps(
  includeCommon({ component: PdfSummaryControl }) as never
)
