import type { TileLayoutSection } from '@epilot/journey-logic-commons'
import { useRef } from 'react'
import type {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren
} from 'react'

import { useTileSectionHeight } from './hooks/useTileSectionHeight'

type TileLayoutContainerProps = PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> & {
  tileLayoutSection: TileLayoutSection
}

export const TileLayoutContainer = ({
  tileLayoutSection,
  className,
  children
}: TileLayoutContainerProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const minHeight = useTileSectionHeight(ref, tileLayoutSection)

  return (
    <div
      className={className}
      data-testid={`tile-section-${tileLayoutSection}`}
      style={{ minHeight, margin: minHeight === 0 ? 0 : undefined }}
    >
      <div ref={ref}>{children}</div>
    </div>
  )
}
