import { Hidden, StackSpacing } from '@epilot/journey-elements'
import {
  controlsForDisplay,
  CONTROL_NAME,
  parseBlockSummary
} from '@epilot/journey-logic-commons'
import type {
  SummaryControlRendererOptions,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { useJourneyContext, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { BlockGroup } from './BlockGroup'
import { getBlockDataRender } from './getBlockDataRender'
import { getPreviewData } from './getPreviewData'
import type { OutputRenderType } from './types'

// get Control Type, e.g PersonalInformationControl is type of '2/Contact Info/PersonalInformationControl'
function getTypeFromKey(key: string):
  | {
      blockType: CONTROL_NAME
      stepNumber: number
      blockName: string
    }
  | undefined {
  const split = key.split('/')

  if (split.length > 1)
    return {
      blockType: split[2] as CONTROL_NAME,
      stepNumber: Number(split[0]),
      blockName: split[1]
    }
  else return undefined
}

const excludedTypes = [
  CONTROL_NAME.PRODUCT_SELECTION_CONTROL,
  CONTROL_NAME.PV_ROOF_PLANNER_CONTROL,
  ...controlsForDisplay
]

function SummaryControlRenderer({
  visible,
  data: initialData,
  uischema,
  path
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const renderOutput: OutputRenderType[] = []
  const { t } = useTranslation()
  const { context } = useJourneyContext()
  const data = initialData || context._stepsHistoryStateObject
  const currentStepIndex = context._navigationInfo.currentStepIndex
  const options = uischema.options as SummaryControlRendererOptions
  const fields = options.fields || []
  const journeySources = structuredClone(context._journeySources || [])
  const stack = context._navigationInfo.stack
  const _isPreview = context._isPreview

  if (_isPreview) {
    renderOutput.push(
      ...getPreviewData(journeySources, currentStepIndex, fields)
    )
  }

  if (data && !_isPreview) {
    Object.keys(data)
      .sort(
        (a, b) =>
          journeySources?.findIndex(
            (src) =>
              // match step number and block name
              src.stepNumber === +a.split('/')[0] &&
              src.name === a.split('/')[1]
          ) -
          journeySources?.findIndex(
            (src) =>
              // match step number and block name
              src.stepNumber === +b.split('/')[0] &&
              src.name === b.split('/')[1]
          )
      )
      .forEach((key) => {
        let rawData = data[key]
        const typres = getTypeFromKey(key)

        if (typres && typeof rawData !== 'undefined' && rawData !== null) {
          const { blockType, stepNumber, blockName } = typres

          const source = journeySources.find(
            (src) => src.stepNumber === stepNumber && src.name === blockName
          )

          // if the block is in an injected step assign -1 as index so it does not have an edit button
          const isBlockInInjectedStep = stack.find(
            (his) => his.stepIndex === stepNumber - 1
          )?.isInjected

          if (isBlockInInjectedStep && source) {
            source.stepIndex = -1
          }

          // finding if there is a config for displaying the block
          const fieldConfigStr = fields.find((ff) =>
            ff.includes(`${stepNumber - 1}/${blockName}/`)
          )
          const fieldConfig =
            fieldConfigStr && parseBlockSummary(fieldConfigStr)

          // remove the _isValid from being displayed in the summary
          if (!Array.isArray(rawData) && typeof rawData === 'object') {
            rawData = { ...rawData }
            delete rawData['_isValid']
          }

          if (
            // do not display the block if it is display block or product selector
            !excludedTypes.includes(blockType) &&
            // do not display the block if it is in the same step as the summary block
            currentStepIndex + 1 !== stepNumber &&
            // do not display the block if it is configured to not be displayed
            fieldConfig?.display !== false
          ) {
            // render each block based on mapped and ordered Block Control data
            renderOutput.push({
              blockDisplayName: fieldConfig?.customDisplayname || blockName,
              blockRenderData: getBlockDataRender(rawData, t, source),
              source
            })
          }
        }
      })
  }

  return (
    <Hidden xsUp={!visible}>
      <StackSpacing alignItems="stretch" scale={2}>
        {renderOutput.map((item, index) => {
          return (
            <BlockGroup
              blockDisplayName={item.blockDisplayName}
              blockRenderData={item.blockRenderData}
              id={stepBlockId}
              key={`${item.blockDisplayName}-${index}`}
              source={item.source}
            />
          )
        })}
      </StackSpacing>
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: SummaryControlRenderer }) as never
)
