import { Paper } from '@epilot/journey-elements'
import { useMediaQuery } from '@material-ui/core'
import type { ReactNode } from 'react'

import { useWidgetStyles } from './styles'

export const WidgetContainer = ({ children }: { children: ReactNode }) => {
  const classes = useWidgetStyles()
  const isMobile = useMediaQuery('(max-width: 750px)')

  return (
    <Paper className={isMobile ? classes.paperMobile : classes.paper}>
      {children}
    </Paper>
  )
}
