import type {
  AddressSuggestionsSource,
  FieldOption,
  Address
} from '@epilot/journey-logic-commons'
import { useMemo } from 'react'
import type {
  Control,
  Path,
  UseControllerProps,
  FieldValues,
  PathValue
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldAutocomplete } from '../../../../components/FieldAutocomplete'
import { useJourneyContext } from '../../../../utils'
import type { SuburbSuggestion } from '../types'
import { isSuburbSuggestionData } from '../types.guard'

import { useAddressSuggestions } from './hooks'

export type FieldSuburbInputProps<T extends FieldValues> = {
  addressSuggestionsSource:
    | AddressSuggestionsSource
    | AddressSuggestionsSource[]
    | undefined
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: PathValue<T, Path<T>>
  path: string
  rules?: UseControllerProps['rules']
  fieldValues: Address
  acceptSuggestedOnly?: boolean
  freeSolo?: boolean
  disableAddressSuggestions?: boolean
  keepAutoCompleteClosed?: boolean
  id: string
} & FieldOption

const MIN_CHARS = 2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldSuburbInput = <T extends Record<string, any>>(
  props: FieldSuburbInputProps<T>
) => {
  const {
    addressSuggestionsSource,
    label,
    required,
    fieldValues,
    disableAddressSuggestions,
    keepAutoCompleteClosed,
    ...autocompleteProps
  } = props

  const { t } = useTranslation()
  const { context } = useJourneyContext()
  const {
    journey: { organizationId: orgId, settings }
  } = context
  const publicToken = settings?.publicToken as string

  const { getSuggestions, options, isLoading } = useAddressSuggestions(
    'suburb',
    disableAddressSuggestions,
    fieldValues,
    addressSuggestionsSource,
    publicToken,
    settings?.addressSuggestionsFileUrl,
    orgId,
    settings?.addressSuggestionsFileId
  )

  const suburbSuggestions: string[] = useMemo(() => {
    if (!options || !isSuburbSuggestionData(options)) {
      return []
    }

    return options.map((item) => item.district).filter(Boolean)
  }, [options])

  return (
    <FieldAutocomplete<T, string, SuburbSuggestion>
      {...autocompleteProps}
      getSuggestions={
        getSuggestions as (
          value: string
        ) => Promise<SuburbSuggestion[]> | undefined
      }
      keepAutoCompleteClosed={keepAutoCompleteClosed}
      label={label + (required ? ' *' : '')}
      loading={isLoading}
      minChars={MIN_CHARS}
      noOptionsText={t('no_suburb')}
      onChange={(data, field) => {
        if (typeof data === 'string' && isSuburbSuggestionData(options)) {
          return field.onChange(data)
        } else {
          return field.onChange('')
        }
      }}
      options={suburbSuggestions || []}
      required={required}
    />
  )
}
