import type {
  LogicTriggerEventName,
  LogicActionTypesValues,
  LogicOperatorsValues
} from '../staticLists'

export const BLOCK_LOCATOR_SEPARATOR = '/'

export const getLogicConditionSource = (value: string) =>
  `$$${value}$$` as LogicConditionSource
export const getLogicActionSource = (value: string) =>
  `$$${value}$$` as LogicActionSource
export const getLogicActionTarget = (value: string) =>
  `##${value}##` as LogicActionTarget
export const getLogicConditionOperatorValue = (value: string | string[]) => {
  if (typeof value === 'string') return `%%${value}%%`

  return `%%${value.join('///')}%%`
}

export type StringBlockLocator =
  `${string}${typeof BLOCK_LOCATOR_SEPARATOR}${string}`
export type StepLocator = string

export type LogicConditionValueFromPath = `##${string}##`
export type LogicLiveValueFromPath = `==${string}==`
export type LogicConditionOperatorValue = `%%${string}%%`
export type LogicConditionOperator = LogicOperatorsValues
export type LogicConditionSource = `$$${StringBlockLocator | StepLocator}$$`

export type LogicActionType = LogicActionTypesValues
export type LogicActionSource = `$$${
  | StringBlockLocator
  | StepLocator
  | string}$$`
export type LogicActionTarget = `##${StringBlockLocator | string}##`
export type LogicActionCondition = `!${'TRUE' | 'FALSE'}!`

export type LogicConditionString = `${LogicConditionSource | ''}${
  | LogicConditionOperator
  | ''}${LogicConditionOperatorValue | ''}${LogicTriggerEventName | ''}${
  | LogicConditionValueFromPath
  | ''}${LogicLiveValueFromPath | ''}`

export type LogicActionString = `${
  | LogicActionCondition
  | ''}${LogicActionType}${LogicActionSource | ''}${LogicActionTarget | ''}`

export type LogicString = {
  _tempId?: string
  autoGeneratedId?: string
  conditions: LogicConditionString[]
  actions: LogicActionString[]
}

export const DEFAULT_FILTER_VALUES_CONFIG_NAME = '_filterValues'
export const CROSS_SELL_FILTER_SELECTION = '_crossSellFilterSelection'
export const AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY = 'availability'
export const DYNAMIC_PRICING_VALUES_PROPERTY = '_dynamicPricingValues'
export const AVAILABILITY_CHECK_VALUES_PROPERTY = `_availabilityCheckValues`
