import {
  FormHelperText,
  Typography,
  Icon,
  clsx,
  Button
} from '@epilot/journey-elements'
import type { IconConfig } from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { IconLabel } from '../../../../components/IconLabel'
import { IconPlaceholder } from '../../../../components/IconPlaceholder'
import { useEnumButtonStyles } from '../styles/useEnumButtonStyles'
import type { EnumButtonProps } from '../types'
import useResizeDirection from '../useResizeDirection'

import { useStyles } from './styles'

type Option =
  | string
  | {
      value: string
      label: string
      icon?: IconConfig
    }

export const EnumButton = (props: EnumButtonProps) => {
  const {
    handleChange,
    path,
    data,
    options,
    hasError,
    label,
    isRequired,
    defaultSelect,
    id
  } = props
  const [direction, buttonWrapRef] = useResizeDirection()
  const { t } = useTranslation()
  const buttonStyles = useEnumButtonStyles()
  const classes = useStyles()
  const [selected, setSelected] = useState(data)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      // skip the effect on initial mount to prevent setting the value to schema.default when the journey goes back
      isInitialMount.current = false
    } else {
      setSelected(defaultSelect)
    }
  }, [defaultSelect])

  const handleOnChange = (value: string | undefined) => {
    const newValue = !value && defaultSelect ? defaultSelect : value

    handleChange(path, newValue)
    setSelected(newValue)
  }

  const buttons: ReactNode[] = options.map((option: Option) => {
    const label = typeof option === 'string' ? option : option.label
    const value = typeof option === 'string' ? option : option.value

    const fieldId = appendStepBlockId(id, label)
    const isSelected = selected === value

    return (
      <Button
        className={clsx(
          buttonStyles.button,
          isSelected && buttonStyles.buttonActive
        )}
        color="primary"
        id={fieldId}
        key={value}
        onClick={() => handleOnChange(value === selected ? undefined : value)}
        size="large"
        variant="outlined"
      >
        <IconPlaceholder>
          {isSelected ? <Icon color="primary" name="check" /> : null}
        </IconPlaceholder>
        <div className="buttonText">
          <IconLabel
            iconConfig={typeof option === 'string' ? undefined : option.icon}
            label={label}
          />
        </div>
        <IconPlaceholder />
      </Button>
    )
  })

  return (
    <div className={label ? '' : classes.containerWithoutLabel}>
      <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
        <span>{label}</span>
        <span className={label ? '' : classes.asterisk}>
          {isRequired ? '*' : ''}
        </span>
      </Typography>

      <div
        className={clsx(buttonStyles.buttonWrap, {
          [buttonStyles.columnDirection]: direction === 'column'
        })}
        ref={buttonWrapRef}
      >
        {buttons}
      </div>

      {hasError && (
        <FormHelperText error>
          {t('Please select one of the options')}
        </FormHelperText>
      )}
    </div>
  )
}
