import { createInstance } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from './de.json'
import en from './en.json'
import fr from './fr.json'

export const instance18n = createInstance().use(LanguageDetector)

instance18n.init({
  resources: {
    en: {
      translation: en
    },
    de: {
      translation: de
    },
    fr: {
      translation: fr
    }
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'locale'
  },
  // Intentionally using process.env as REACT_APP_IS_JEST is a builtime config, for tests
  lng: process.env.REACT_APP_IS_JEST ? 'cimode' : 'de',
  fallbackLng: process.env.REACT_APP_IS_JEST ? 'cimode' : 'en',
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true
  }
})

export const getString = (name: string, params = {}) => {
  return instance18n.t(name, params)
}

export const changeLanguage = (lang: string) => {
  instance18n.changeLanguage(lang)
}

export const loadRemoteNamespace = async (language: string) => {
  try {
    const namespace = 'entity'

    const response = await fetch(
      `https://portal.epilot.cloud/locales/${language}/${namespace}.json`
    )
    const translationData = await response.json()

    // Add the translations to the i18next instance
    instance18n.addResourceBundle(
      language,
      namespace,
      translationData,
      true,
      true
    )
  } catch (error) {
    console.error('Error loading remote namespace:', error)
  }
}
