import type { StreetSuggestion } from '@epilot/journey-elements'
import type { Address } from '@epilot/journey-logic-commons'

import type { FeedBackType, ZipCitySuggestion } from './types'

const URL_RAW_STREET = (addressApiUrl: string) =>
  addressApiUrl +
  '/v1/integration/location/%country%/streets?postal_code=%zip%&city=%city%&street=%street%'
const URL_RAW_SUBURB = (addressApiUrl: string) =>
  addressApiUrl +
  '/v1/integration/location/%country%/districts?postal_code=%zip%&city=%city%&district=%district%'
const URL_RAW_ZIP_CITY = (addressApiUrl: string) =>
  addressApiUrl +
  '/v1/integration/location/%country%/postalcode-cities?postalcode_city=%postalcode_city%'
const URL_RAW_FEEDBACK = (addressApiUrl: string) =>
  addressApiUrl +
  '/v1/integration/location/%country%/%type%/send-feedback?uuid=%uuid%'

export function constructStreetUrl(addressApiUrl: string, address?: Address) {
  return URL_RAW_STREET(addressApiUrl)
    .replace(
      '%country%',
      address && address.countryCode ? address.countryCode : 'de'
    )
    .replace(
      '%street%',
      address && address.streetName ? address.streetName : ''
    )
    .replace('%city%', address && address.city ? address.city : '')
    .replace('%zip%', address && address.zipCode ? address.zipCode : '')
    .replace(
      '%postalcode_city%',
      address && address.zipCode
        ? address.zipCode
        : address && address.city
          ? address.city
          : ''
    )
}

export function constructSuburbUrl(addressApiUrl: string, address?: Address) {
  return URL_RAW_SUBURB(addressApiUrl)
    .replace(
      '%country%',
      address && address.countryCode ? address.countryCode : 'de'
    )
    .replace('%district%', address && address.suburb ? address.suburb : '')
    .replace('%city%', address && address.city ? address.city : '')
    .replace('%zip%', address && address.zipCode ? address.zipCode : '')
}

export function constructZipCityUrl(
  addressApiUrl: string,
  country: string,
  zipcity: string
) {
  return URL_RAW_ZIP_CITY(addressApiUrl)
    .replace('%country%', country)
    .replace('%postalcode_city%', zipcity)
}

function constructFeedbackURL(
  addressApiUrl: string,
  country: string,
  feedbackType: FeedBackType,
  uuid?: string
) {
  return URL_RAW_FEEDBACK(addressApiUrl)
    .replace('%type%', feedbackType)
    .replace('%country%', country.toLowerCase())
    .replace('%uuid%', uuid ? uuid : '')
}

export function sendFeedback(
  addressApiUrl: string,
  feedbackType: FeedBackType,
  country: string,
  suggestion: StreetSuggestion | ZipCitySuggestion | undefined,
  token: string
) {
  if (suggestion?.uuid) {
    const url = constructFeedbackURL(
      addressApiUrl,
      country,
      feedbackType,
      suggestion.uuid
    )

    const headers = token
      ? { headers: { Authorization: `Bearer ${token}` } }
      : undefined

    fetch(url, headers)
  }
}

export const regexZipCodeCityByCountry = (countryCode: string) => {
  switch (countryCode.toUpperCase()) {
    case 'DE':
      return /^[0-9]{5}[ ][^0-9!“§$%=?`;:_#+*^°´@{}"[\]<>±|\\]{1,}$/
    default:
      return /^[0-9]{4}[ ][^0-9!“§$%=?`;:_#+*^°´@{}"[\]<>±|\\]{1,}$/
  }
}

export const zipCodeExamplesByCountry = (countryCode: string) => {
  switch (countryCode.toUpperCase()) {
    case 'DE':
      return '12345'
    default:
      return '1234'
  }
}

export const removeDiacritics = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
