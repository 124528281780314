/*
 * Generated type guards for "sharedTypes.ts".
 * WARNING: Do not manually change this file.
 */
import type { LiveValue } from './sharedTypes'

export function isValidLiveValue(
  obj: any,
  _argumentName?: string
): obj is LiveValue {
  return (
    typeof obj === 'string' ||
    typeof obj === 'number' ||
    obj === false ||
    obj === true ||
    (Array.isArray(obj) && obj.every((e: any) => typeof e === 'string')) ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      Object.entries<any>(obj).every(
        ([key, _value]) => typeof key === 'string'
      ))
  )
}
