import { apiCall } from '../api'

export async function getGoogleMapsApiKey(
  googleMapsApiUrl: string,
  publicToken: string
): Promise<string> {
  try {
    const response = await apiCall({
      method: 'GET',
      url: `${googleMapsApiUrl}/v1/google-maps-api-key`,
      headers: {
        authorization: `Bearer ${publicToken}`
      }
    })

    return response.data
  } catch (error) {
    console.error('Failed to retrieve Google Maps API key:', error)
    throw error
  }
}

export async function getCoordinatesFromAddress(
  googleMapsApiUrl: string,
  address: string,
  publicToken: string,
  signal?: AbortSignal
): Promise<google.maps.LatLng | google.maps.LatLngLiteral | undefined> {
  try {
    const response = await apiCall({
      method: 'GET',
      url: `${googleMapsApiUrl}/v1/geocode-api/coordinates?address=${address}`,
      headers: {
        authorization: `Bearer ${publicToken}`
      },
      signal
    })
    const coordinates:
      | google.maps.LatLng
      | google.maps.LatLngLiteral
      | undefined = response.data

    return coordinates
  } catch (error) {
    console.error('Failed to retrieve coordinates from Google Maps API:', error)
    throw error
  }
}

export async function importMarkerLibrary(): Promise<
  typeof google.maps.marker.AdvancedMarkerElement
> {
  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    'marker'
  )) as google.maps.MarkerLibrary

  return AdvancedMarkerElement
}

export const assignNewMarker = ({
  pinRef,
  coordinates,
  map
}: {
  pinRef: React.MutableRefObject<google.maps.marker.AdvancedMarkerElement | null>
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral
  map: google.maps.Map
}) => {
  importMarkerLibrary().then((AdvancedMarkerElement) => {
    if (pinRef.current) {
      pinRef.current.position = coordinates
    } else {
      pinRef.current = new AdvancedMarkerElement({
        position: coordinates,
        map: map
      })
    }
  })
}

export const GOOGLE_MAP_ID = '71e627b4c502849a'
