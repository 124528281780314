import { Typography } from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Price } from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  hasPricingModel,
  hasTieredPricingModel
} from '@epilot/journey-logic-commons'
import { formatPriceUnit, PricingModel } from '@epilot/pricing'
import { useMemo } from 'react'

import { useTileContext } from '../../../utils/tile-context-utils'

import { PriceTierDetails } from './PriceTierDetails'

interface PriceItemComponentProps {
  priceComponent: Price
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    text: {
      fontSize: 12,
      maxWidth: '150px',
      lineHeight: 1.5
    },
    price: {
      fontSize: 12,
      lineHeight: 1.5,
      maxWidth: '75px',
      wordBreak: 'break-all'
    }
  })
)

export const ConcordePriceComponentsItem = ({
  priceComponent
}: PriceItemComponentProps) => {
  const { actions, config } = useTileContext()

  const classes = useStyles()

  const displayUnitAmount = useMemo(() => {
    if (priceComponent.pricing_model === PricingModel.externalGetAG) {
      return getDisplayPrice(
        actions.t,
        priceComponent?.unit_amount_gross_decimal,
        priceComponent.price_display_in_journeys,
        config.generalTileConfiguration.showTrailingDecimalZeros,
        priceComponent.unit_amount_currency
      )
    }

    return getDisplayPrice(
      actions.t,
      priceComponent?.unit_amount_gross_decimal,
      priceComponent._price.price_display_in_journeys,
      config.generalTileConfiguration.showTrailingDecimalZeros,
      priceComponent._price.unit_amount_currency
    )
  }, [
    priceComponent.pricing_model,
    priceComponent?.unit_amount_gross_decimal,
    priceComponent._price.price_display_in_journeys,
    priceComponent._price.unit_amount_currency,
    priceComponent.price_display_in_journeys,
    priceComponent.unit_amount_currency,
    actions.t,
    config.generalTileConfiguration.showTrailingDecimalZeros
  ])

  const title =
    !priceComponent._price.variable_price &&
    priceComponent._price.type !== 'one_time' &&
    getPriceType(priceComponent.type, priceComponent.billing_period)
  const unit = `/${actions.t(
    formatPriceUnit(priceComponent._price.unit),
    formatPriceUnit(priceComponent._price.unit)
  )}`

  return (
    <>
      {hasPricingModel(PricingModel.perUnit, priceComponent) && (
        <>
          <Typography className={classes.text} variant="secondary">
            {priceComponent.description}
          </Typography>
          <Typography className={classes.price}>
            {displayUnitAmount}
            {priceComponent._price.variable_price && unit}
          </Typography>
        </>
      )}
      {hasTieredPricingModel(priceComponent) && (
        <PriceTierDetails priceItem={priceComponent} />
      )}
      {hasPricingModel(PricingModel.externalGetAG, priceComponent) && (
        <>
          <Typography className={classes.text} variant="secondary">
            {priceComponent.description}
          </Typography>
          <Typography className={classes.price}>
            {displayUnitAmount}
            {priceComponent.get_ag?.type === 'work_price' && unit}
            {title && !unit && actions.t(title).toLocaleLowerCase()}
          </Typography>
        </>
      )}
    </>
  )
}
