import type { Variable } from '../PdfSummaryControl'

export function getVariablesData(
  _stepsHistoryStateObject: Record<string, unknown>,
  variables: Variable[]
): Record<string, unknown> | undefined {
  if (!_stepsHistoryStateObject) {
    return
  }

  const data = variables?.reduce(
    (acc: Record<string, unknown>, variable: Variable) => {
      const {
        path,
        block: { blockName, stepIndex }
      } = variable || {}
      const stepNumber = stepIndex + 1
      const blockKey = Object.keys(_stepsHistoryStateObject).find((k) =>
        k.startsWith(`${stepNumber}/${blockName}/`)
      )

      const blockData = blockKey && _stepsHistoryStateObject?.[blockKey]

      if (blockData && path) {
        acc[`${path}`] = blockData
      }

      return acc
    },
    {}
  )

  return Object.keys(data || {}).length > 0 ? data : undefined
}
