import {
  FormHelperText,
  Typography,
  Icon,
  clsx
} from '@epilot/journey-elements'
import type { IconConfig } from '@epilot/journey-logic-commons'
import {
  getOptimizedImageURL,
  appendStepBlockId
} from '@epilot/journey-logic-commons'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconLabel } from '../../../components/IconLabel'
import { IconPlaceholder } from '../../../components/IconPlaceholder'
import { ImageButton } from '../../../components/ImageButton/ImageButton'
import { useConfig } from '../../../utils'
import { useEnumButtonStyles } from '../EnumButtonControl/styles/useEnumButtonStyles'

const OPTIMAL_IMAGE_WIDTH = '400'
const OPTIMAL_ICON_WIDTH = '120'

type ImageObject = {
  id: string
  url: string
}

type Option = {
  value: string
  label: string
  image: ImageObject
  icon?: IconConfig
}

type EnumImageButtonProps = {
  path: string
  data: string | undefined
  options: Option[]
  hasError: boolean
  label: string
  isRequired: boolean
  imageType?: 'infographic' | 'icon'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange: (path: string, value: any) => void
  defaultSelect: string
  id: string
}

export const EnumImageButton = (props: EnumImageButtonProps) => {
  const {
    handleChange,
    path,
    data,
    options,
    hasError,
    label,
    isRequired,
    imageType,
    defaultSelect,
    id
  } = props

  const buttonStyles = useEnumButtonStyles()
  const { t } = useTranslation()
  const { IMAGE_PREVIEW_API_URL } = useConfig()

  const handleOnChange = (value: string | undefined) => {
    handleChange(path, value)
    setSelected(value)
  }

  const [selected, setSelected] = useState(data)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      // skip the effect on initial mount to prevent setting the value to schema.default when the journey goes back
      isInitialMount.current = false
    } else {
      setSelected(defaultSelect)
    }
  }, [defaultSelect])

  // Kind of "dirty" trick to force buttons to use the minimal amount
  // of space when "alone" on a row. Looking for better solution.
  const dummyDiv = <div className={buttonStyles.dummyDiv} />
  const unsetFlexBasis = options.length < 3

  const buttons = options.map((option: Option) => {
    const isInfographic = imageType === 'infographic'
    const label = option.label
    const value = option.value
    const imageId = option.image?.id
    const url = option.image?.url

    const optimizedImageURL = getOptimizedImageURL(
      IMAGE_PREVIEW_API_URL,
      url,
      imageId,
      isInfographic ? OPTIMAL_IMAGE_WIDTH : OPTIMAL_ICON_WIDTH
    )

    const fieldId = appendStepBlockId(id, label)
    const isSelected = selected === value

    return (
      <ImageButton
        checked={isSelected}
        id={fieldId}
        imageType={imageType}
        imageURL={optimizedImageURL}
        key={value}
        onClick={() => {
          handleOnChange(value === selected ? undefined : value)
        }}
        unsetFlexBasis={unsetFlexBasis}
      >
        <IconPlaceholder>
          {isSelected ? <Icon color="primary" name="check" /> : null}
        </IconPlaceholder>
        <IconLabel iconConfig={option.icon} label={label} />
        <IconPlaceholder />
      </ImageButton>
    )
  })

  return (
    <div className={label ? '' : buttonStyles.containerWithoutLabel}>
      <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
        {`${label}${isRequired ? '*' : ''}`}
      </Typography>
      <div
        className={clsx(
          buttonStyles.buttonImageWrap,
          imageType !== 'infographic' && buttonStyles.noWrapImageContainer
        )}
      >
        {buttons}
        {imageType === 'infographic' && !unsetFlexBasis && (
          <>
            {dummyDiv}
            {dummyDiv}
          </>
        )}
      </div>

      {hasError && (
        <FormHelperText error>
          {t('Please select one of the options')}
        </FormHelperText>
      )}
    </div>
  )
}
