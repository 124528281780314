import { cleanOptionsFromEnum, Hidden } from '@epilot/journey-elements'
import { CONTROL_NAME } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import {
  rankWith,
  isEnumControl,
  and,
  optionIs,
  or,
  uiTypeIs
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../..'
import { includeCommon } from '../../../utils/includeCommon'

import { EnumImageButton } from './EnumImageButton'

export function EnumImageButtonControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  schema,
  visible,
  required,
  id
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path, id)

  const { enum: opts } = schema
  const enumOptions = (uischema?.options?.options || opts) as string[]
  const label = uischema?.options?.label || uischema?.label
  const hasError = !!errors

  const imageType = uischema.options?.imageType || 'infographic'
  // Remove empty options
  const options = enumOptions?.filter(Boolean) || []
  // Remove respective labels from empty options
  const optionsLabels = cleanOptionsFromEnum(
    uischema?.options?.optionsLabels,
    enumOptions
  )
  // Remove respective images from empty options
  const optionsImages = cleanOptionsFromEnum(
    uischema.options?.optionsImages,
    enumOptions
  )

  const optionsIcons = uischema.options?.optionsIcons || []

  const items = options
    ? options.map((option, index) => ({
        value: option,
        label: optionsLabels?.[index] || option,
        image: optionsImages?.[index],
        icon: optionsIcons?.[index] || undefined
      }))
    : []

  return (
    <Hidden xsUp={!visible}>
      <EnumImageButton
        data={data}
        defaultSelect={schema.default}
        handleChange={handleChange}
        hasError={hasError}
        id={stepBlockId}
        imageType={imageType}
        isRequired={!!required}
        label={typeof label === 'string' ? label.trim() : ''}
        options={items}
        path={path}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: EnumImageButtonControlPure
  }) as never
)

export const EnumImageButtonControlTester = rankWith(
  4,
  or(
    and(isEnumControl, optionIs('imageButton', true)),
    and(uiTypeIs(CONTROL_NAME.ENUM_CONTROL), optionIs('imageButton', true))
  )
)
