import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { CSSProperties } from 'react'

export const useUnitaryDemandFieldStyles = makeStyles({
  disabled: {
    color: 'currentColor'
  },
  adornment: {
    fontWeight: 'bold',
    color: '#C5D0DB'
  },
  adornmentMobile: {
    fontWeight: 'bold',
    color: '#C5D0DB',
    display: 'flow-root'
  }
})

export const useDisplayConsumptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    consumption: {
      height:
        (theme?.overrides?.MuiInputBase?.input as CSSProperties)?.height ||
        '48px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center'
    }
  })
)

export const useAddDeviceButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    addDeviceButton: {
      padding: 0,
      display: 'flex',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
      }
    }
  })
)

export const useConsumptionFieldsStyles = makeStyles({
  noDevicesContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  totalContainer: {
    height: 32,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  }
})

export const useTotalConsumptionStyles = makeStyles({
  container: {
    marginLeft: 'auto'
  },
  desktopContainer: {
    marginRight: '40px'
  },
  noDeleteButton: {
    marginRight: '0'
  }
})

export const useConsumptionEntryStyles = makeStyles((theme) => ({
  desktopContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  mobileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  separator: {
    height:
      (theme?.overrides?.MuiInputBase?.input as CSSProperties)?.height ||
      '48px',
    textAlign: 'center',
    padding: '0 5px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  }
}))
