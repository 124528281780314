import type { Journey } from '@epilot/journey-logic-commons'
import { blockController, CONTROL_NAME } from '@epilot/journey-logic-commons'
import type { HistoryStack } from '@epilot/json-renderers'
import {
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE,
  dispatchUserEvent,
  EventNames
} from '@epilot/json-renderers'
import { useEffect, useRef } from 'react'

export const useInitiateProductSelection = (
  journey: Journey,
  currentStepIndex: number,
  stack: HistoryStack[]
) => {
  // used to send InitiateProductSelection event to website
  const productSelectionIndexesRef = useRef<number[]>([])

  useEffect(() => {
    productSelectionIndexesRef.current = blockController
      .findBlocks(journey.steps, {
        type: CONTROL_NAME.PRODUCT_SELECTION_CONTROL
      })
      ?.map((item) => item.stepIndex)
  }, [journey])

  useEffect(() => {
    if (
      typeof currentStepIndex === 'number' &&
      productSelectionIndexesRef.current.includes(currentStepIndex)
    ) {
      const path = stack.find(
        (item) => item.stepIndex === currentStepIndex
      )?.pathname

      if (path) {
        dispatchUserEvent(
          EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PROGRESS,
          journey.journeyId,
          {
            eventName: EventNames.INIT_PRODUCT_SELECTION,
            path: `epilot-journey/${journey.journeyId}/step-${
              currentStepIndex + 1
            }-${path}`
          }
        )
      }
    }
  }, [currentStepIndex, journey.journeyId, stack])
}
