import {
  getPublicUrl,
  appendStepBlockId,
  areProductDownloadsHydrated
} from '@epilot/journey-logic-commons'

import { Link, Icon } from '../../../../components'
import classes from '../../styles/ProductDownloads.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

function getDownloadLinkId(id: string, title?: string) {
  return appendStepBlockId(id, `Product Downloads ${title}`)
}

export const ProductDownloads = () => {
  const { id, product } = useTileContext()

  const downloads = areProductDownloadsHydrated(product)
    ? product.product_downloads.filter(
        (download) => download.access_control !== 'private'
      )
    : []

  const shouldDisplayDownloads = Boolean(downloads.length)

  if (!shouldDisplayDownloads) {
    return null
  }

  return (
    <div className={classes.container}>
      {downloads.map((download) => (
        <div className={classes.linkContainer} key={download._id}>
          <Icon name="download" />
          <Link
            className={classes.link}
            href={getPublicUrl(download)}
            id={getDownloadLinkId(id, download._title)}
            target="_blank"
          >
            {download.filename}
          </Link>
        </div>
      ))}
    </div>
  )
}
