import type { Journey, StepState } from '@epilot/journey-logic-commons'

/**
 * Given a journey, returns an array of empty step values,
 * one for each step in the journey.
 */
export const makeJourneyEmptyStepValues = (
  journey: Journey,
  initialState?: Record<string, unknown>[]
): StepState[] =>
  Array.from({ length: journey.steps.length }, (_, i) =>
    initialState?.[i] ? initialState[i] : {}
  )

export const makeJourneyEmptyStepValuesWithDefaults = (
  journey: Journey,
  initialState?: Record<string, unknown>[]
): StepState[] => {
  return Array.from({ length: journey.steps.length }, (_, stepIndex) => {
    const currentStep = journey.steps[stepIndex]
    const stepState = initialState?.[stepIndex] ? initialState[stepIndex] : {}

    if (currentStep?.schema?.properties) {
      for (const [key, property] of Object.entries(
        currentStep?.schema?.properties || {}
      )) {
        if ('default' in property && !(key in Object.keys(stepState))) {
          stepState[key] = property.default
        }
      }
    }

    return stepState
  })
}
