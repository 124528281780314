import { Button, Grid, SmallText } from '@epilot/journey-elements'
import type { TFunction } from 'i18next'
import React from 'react'

interface CartFooterProps {
  title?: string
  extraComponents?: React.ReactNode[]
  closeButtonLabel?: string
  closeButtonEnabled?: boolean
  closeButtonOnClickHandler?: React.MouseEventHandler
  t: TFunction
}

export const CartFooter: React.FC<CartFooterProps> = ({
  title,
  extraComponents,
  closeButtonLabel,
  closeButtonEnabled,
  closeButtonOnClickHandler,
  t
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      {title && (
        <Grid item>
          <SmallText>{title}</SmallText>
        </Grid>
      )}
      {extraComponents?.map((component, index) => (
        <Grid item key={`footer_${index}`}>
          {component}
        </Grid>
      ))}
      {closeButtonEnabled && (
        <Grid item>
          <Button
            fullWidth
            onClick={closeButtonOnClickHandler}
            size="medium"
            variant="outlined"
          >
            {closeButtonLabel || t('cart.close_button', 'Close Cart')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
