import type { ComponentType } from 'react'

import { TileLayoutContainer } from '../../TileLayoutContainer'
import type { ProductDetailsModalProps } from '../../types'
import { ProductDetails } from '../TileFooter/ProductDetails'

import classes from './TileSections.module.scss'

type TileDetailsProps = {
  ProductDetailsModalComponent: ComponentType<ProductDetailsModalProps>
}

export const TileDetails = ({
  ProductDetailsModalComponent
}: TileDetailsProps) => {
  return (
    <TileLayoutContainer
      className={classes.expandContainer}
      tileLayoutSection="expand"
    >
      <ProductDetails
        ProductDetailsModalComponent={ProductDetailsModalComponent}
      />
    </TileLayoutContainer>
  )
}
