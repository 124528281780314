import { getClient } from '@epilot/file-client'
import type { S3File } from '@epilot/journey-elements'
import type {
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import axios from 'axios'

import { getBase64 } from '../utils/helper'

type AxiosRequestConfigWithBrowserProgress<D = any> = Omit<
  AxiosRequestConfig<D>,
  'onUploadProgress' | 'onDownloadProgress'
> & {
  onUploadProgress?: (progress: ProgressEvent) => void
  onDownloadProgress?: (progress: ProgressEvent) => void
}

// TODO: Review callers to make sure types are not breaking
export const apiCall = <D, R = any>(
  params: Omit<AxiosRequestConfigWithBrowserProgress<D>, 'method'> & {
    method: 'GET' | 'POST' | 'PUT'
  }
): Promise<AxiosResponse<R>> => {
  const onProgressFactory =
    (cb: (progress: ProgressEvent) => void) => (progress: AxiosProgressEvent) =>
      cb(progress.event)

  const { onUploadProgress, onDownloadProgress, ...rest } = params

  return axios({
    ...rest,
    onUploadProgress: onUploadProgress && onProgressFactory(onUploadProgress),
    onDownloadProgress:
      onDownloadProgress && onProgressFactory(onDownloadProgress)
  })
}

type UploadFileArgs = Pick<
  AxiosRequestConfigWithBrowserProgress,
  'signal' | 'onUploadProgress'
> & {
  /**
   * The file to be uploaded.
   */
  file: File
  /**
   * Which Organization this file belongs to.
   */
  orgId?: string
  /**
   * An index tag that will be used to prefix the file key at rest.
   */
  indexTag?: string
  /**
   * Additional metadata to be added to the uploaded file at rest.
   *
   * NOTE: To keep things consistent, please prefer snake_case for the keys.
   */
  metadata?: Record<string, string>
  /**
   * The URL to the file API.
   */
  fileApiUrl: string
}

export const uploadFile = async ({
  file,
  orgId,
  signal,
  onUploadProgress,
  metadata,
  indexTag,
  fileApiUrl
}: UploadFileArgs): Promise<S3File> => {
  const client = getClient()

  if (orgId) {
    client.defaults.headers.common['x-ivy-org-id'] = orgId
  }

  client.defaults.baseURL = fileApiUrl

  try {
    const response = await client.uploadFilePublic(
      {},
      {
        filename: file.name,
        mime_type: file.type,
        index_tag: indexTag,
        metadata
      }
    )
    const { data } = response
    const base64File = await getBase64(file)

    if (typeof base64File !== 'string') {
      throw Error('Could not get the base64 representation of the file')
    }

    if (!data.upload_url) {
      throw Error('Upload URL not defined')
    }

    if (!data.s3ref) {
      throw Error('S3 Ref not defined')
    }

    const base64data = base64File.split(',')[1]
    const buffer = Buffer.from(base64data, 'base64')

    await putUploadPresignedUrl<void>(buffer, data.upload_url, file.type, {
      signal,
      onUploadProgress
    })

    const currentKey = data.s3ref.key
    const fileName = currentKey.includes('/')
      ? currentKey.substring(currentKey.lastIndexOf('/') + 1)
      : currentKey

    const fileNameArray = file.name.split('.')
    const fileType = fileNameArray[fileNameArray.length - 1]

    return {
      file_name: fileName,
      file_size: file.size,
      original_name: file.name,
      file_type: fileType,
      s3ref: data.s3ref
    }

    // Upload file to s3
  } catch (e) {
    console.error('uploadFile failure -->', e)

    throw e
  }
}

export const putUploadPresignedUrl = <R = any>(
  data: Buffer,
  presignedUrl: string,
  contentType: string,
  options?: Pick<
    AxiosRequestConfigWithBrowserProgress<Buffer>,
    'signal' | 'onUploadProgress'
  >
) => {
  return apiCall<typeof data, R>({
    method: 'PUT',
    url: presignedUrl,
    data,
    headers: {
      'Content-Type': contentType,
      'Content-Encoding': 'base64'
    },
    ...options
  })
}
