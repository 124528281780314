import type {
  Price,
  Product,
  SelectedProductTile,
  PriceWithBlockMappings,
  withDefaultConfigurationValues
} from '@epilot/journey-logic-commons'
import type { PricingDetails } from '@epilot/pricing'
import type { TFunction } from 'i18next'
import { createContext, useContext } from 'react'

type DefaultConfigurationValues = ReturnType<
  typeof withDefaultConfigurationValues
>

export interface TileContext {
  config: DefaultConfigurationValues
  product: Product
  price: PriceWithBlockMappings
  pricingDetails: PricingDetails
  blockMappings: Price['price_mappings']
  externalFeesMappings: Price['external_fees_mappings']
  actions: {
    setQuantity: (quantity: number) => void
    increaseQuantity: () => void
    decreaseQuantity: () => void
    selectProductTile: (productTile: SelectedProductTile) => void
    deselectProductTile: (productTile: {
      productId?: string
      priceId?: string
    }) => void
    t: TFunction
  }
  tileState: {
    priceDisplayInJourneys: Price['price_display_in_journeys']
    quantity: number
  }
  useNewDesign?: boolean
  displayCtaButtonUnderPrice: boolean
  id: string
  tileVariantsEnabled: boolean
}

export const CatalogTileContext = createContext<TileContext | null>(null)

export const useTileContext = () => {
  const tileContext = useContext(CatalogTileContext)

  if (!tileContext) {
    throw new Error(
      `You must call useTileContext() inside of a <CatalogProductTile />`
    )
  }

  return tileContext
}
