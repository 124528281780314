import { useState } from 'react'
import type { ChangeEvent } from 'react'

import { Autocomplete } from '../'

import type { Provider, PreviousProviderProps } from './types'

export function PreviousProvider({
  label,
  required,
  onChange,
  previousProviderEndpoint,
  value,
  acceptOnlySuggestedProviders,
  publicToken,
  id,
  ...rest
}: PreviousProviderProps) {
  const [options, setOptions] = useState<Provider[]>([])
  const [provider, setProvider] = useState<Provider>(
    typeof value === 'string'
      ? {
          company_name: value,
          bdew_code: ''
        }
      : value
  )

  const handleOnChange = (_event: ChangeEvent, value: string | Provider) => {
    // get suggestions
    const searchTerm = typeof value === 'string' ? value : value?.company_name

    if (previousProviderEndpoint) {
      if (searchTerm) {
        getProvidersSuggestions(
          previousProviderEndpoint,
          searchTerm,
          publicToken
        ).then((data) => {
          if (Array.isArray(data)) {
            setOptions(data)
          }
        })
      } else {
        setOptions([])
      }
    }

    // set the provider
    setProvider(
      typeof value === 'string'
        ? {
            company_name: value
          }
        : value
    )

    // trigger the callback
    // if acceptOnlySuggestedProviders is true, only pass the value if the selected it, otherwide undefined
    if (onChange) {
      onChange(
        typeof value === 'string'
          ? acceptOnlySuggestedProviders || value === ''
            ? undefined
            : {
                company_name: value
              }
          : value
      )
    }
  }

  return (
    <Autocomplete
      freeSolo
      fullWidth
      getOptionLabel={(provider: Provider) => provider?.company_name || ''}
      id={id}
      inputValue={provider?.company_name || ''}
      label={label + (required ? ' *' : '')}
      multiple={false}
      onBlur={() => {
        if (options && options.length === 1 && acceptOnlySuggestedProviders) {
          setProvider(options[0])
          onChange?.(options[0])
        }
      }}
      onChange={handleOnChange as never}
      onInputChange={handleOnChange as never}
      options={options}
      value={provider}
      {...rest}
    />
  )
}

async function getProvidersSuggestions(
  baseUrl: string,
  searchValue: string,
  publicToken: string
) {
  try {
    const providers = (await (
      await fetch(baseUrl.replace('%value%', searchValue), {
        headers: {
          authorization: `Bearer ${publicToken}`
        }
      })
    ).json()) as Provider[]

    return providers
  } catch {
    return null
  }
}
