import type { Theme } from '@epilot/journey-elements'
import {
  createStyles,
  InsetSpacing,
  makeStyles,
  Typography
} from '@epilot/journey-elements'

import { useTileContext } from '../../../utils/tile-context-utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      display: 'block',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'pre-wrap'
    }
  })
)

export const ProductFootnote = () => {
  const { config } = useTileContext()
  const classes = useStyles()

  const footnote = config?.content?.footer?.footnote

  if (!footnote) return null

  return (
    <InsetSpacing scale={4}>
      <Typography className={classes.caption} variant="caption">
        {footnote}
      </Typography>
    </InsetSpacing>
  )
}
