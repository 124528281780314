import { Grid } from '@epilot/journey-elements'
import { useFieldArray } from 'react-hook-form'
import type { Control, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IsMobileOrTablet } from '../../../../utils/helper'
import { useConsumptionFieldsStyles } from '../styles'
import type { InputCalculatorFormValues, HydratedDeviceOption } from '../types'

import { AddDeviceButton } from './AddDeviceButton'
import { ConsumptionEntry } from './ConsumptionEntry'
import { TotalConsumptionCalculation } from './TotalConsumptionCalculation'

type ConsumptionFieldsProps = {
  hasError: boolean
  path: string
  unit: string
  isRequired: boolean
  consumptionOptions: HydratedDeviceOption[]
  control: Control<InputCalculatorFormValues>
  setValue: UseFormSetValue<InputCalculatorFormValues>
  digitsAfterDecimalPoint?: number
  id: string
  showAddDeviceButton?: boolean
  preselectedOptionsLength?: number
  showDeleteButton: boolean
}

export const ConsumptionFields = ({
  hasError,
  path,
  isRequired,
  consumptionOptions = [],
  unit,
  setValue,
  control,
  digitsAfterDecimalPoint,
  id,
  showAddDeviceButton,
  showDeleteButton
}: ConsumptionFieldsProps) => {
  const { t } = useTranslation()
  const isMobile = IsMobileOrTablet()
  const classes = useConsumptionFieldsStyles()
  const {
    fields: consumptions,
    append,
    remove
  } = useFieldArray({
    control: control,
    name: 'devices',
    rules: {
      required: isRequired
        ? t(
            'input_calculator.devices_required',
            'Please add at least one device'
          )
        : undefined
    }
  })

  return (
    <>
      <Grid container spacing={2}>
        {!consumptions?.length ? (
          <Grid item xs={12}>
            <div className={classes.noDevicesContainer}>
              {t('input_calculator.no_devices', 'No devices')}
              {isRequired ? '*' : ''}
            </div>
          </Grid>
        ) : (
          consumptions?.map((consumption, index) => (
            <ConsumptionEntry
              consumptionOptions={consumptionOptions}
              control={control}
              digitsAfterDecimalPoint={digitsAfterDecimalPoint}
              hasError={hasError}
              id={id}
              index={index}
              isMobile={isMobile}
              key={consumption.id}
              path={path}
              remove={remove}
              setValue={setValue}
              showDeleteButton={showDeleteButton}
              t={t}
              unit={t(`units.${unit}`, unit)}
            />
          ))
        )}
      </Grid>

      <Grid className={classes.totalContainer}>
        {showAddDeviceButton && (
          <AddDeviceButton
            id={id}
            onClick={() =>
              append({
                name: null,
                quantity: '1',
                unitaryConsumption: ''
              })
            }
          />
        )}
        <TotalConsumptionCalculation
          control={control}
          digitsAfterDecimalPoint={digitsAfterDecimalPoint}
          isMobile={isMobile}
          setValue={setValue}
          showDeleteButton={showDeleteButton}
          t={t}
          unit={unit}
        />
      </Grid>
    </>
  )
}
