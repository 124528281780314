import {
  CONTROL_NAME,
  controlsForDisplay,
  parseBlockSummary
} from '@epilot/journey-logic-commons'
import type {
  SerializedBlockSummary,
  Source,
  SummaryField
} from '@epilot/journey-logic-commons'

import type { OutputRenderType } from './types'

// this function return data to be used by the summary block in preview mode
export function getPreviewData(
  journeySources: Source[],
  currentStepIndex: number,
  fields: SerializedBlockSummary[]
) {
  const output = journeySources
    .map((b) => {
      const fieldConfigStr = fields.find((ff) =>
        ff.includes(`${b.stepIndex}/${b.name}/`)
      )

      const fieldConfig = fieldConfigStr && parseBlockSummary(fieldConfigStr)

      return shouldDisplayBlock(
        currentStepIndex,
        b.stepIndex,
        b.controlNameId,
        fieldConfig
      )
        ? {
            blockDisplayName: fieldConfig?.customDisplayname || b.name,
            blockRenderData: [
              ['Alle Eingaben der Nutzer werden hier zusammengefasst.']
            ],
            source: { ...b, controlNameId: CONTROL_NAME.LABEL_CONTROL }
          }
        : undefined
    })
    .filter(Boolean)

  return output as OutputRenderType[]
}

function shouldDisplayBlock(
  currentStepIndex: number,
  blockStepIndex: number,
  controlName?: CONTROL_NAME,
  fieldConfig?: SummaryField
) {
  // exclude the sources which are in the same step and are for display
  if (blockStepIndex === currentStepIndex) {
    return false
  }

  // do not display the block if it is display block, product selector, or PV roof planner
  if (
    controlName &&
    [
      ...controlsForDisplay,
      CONTROL_NAME.PRODUCT_SELECTION_CONTROL,
      CONTROL_NAME.PV_ROOF_PLANNER_CONTROL
    ].includes(controlName)
  ) {
    return false
  }

  // do not display the block if it is configured to not be displayed
  if (fieldConfig?.display === false) {
    return false
  }

  return true
}
