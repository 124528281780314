import { Typography, Spacing } from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles, H5 } from '@epilot/journey-elements'
import { useMemo } from 'react'

import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'
import { ProductImageRenderer } from '../ProductImageRenderer'

import { ProductImageWithCoupon } from './ProductImageWithCoupon'
import { ProductTileCoupon } from './ProductTileCoupon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      display: 'grid',
      gridAutoRows: 'auto minmax(auto, 242px)'
    },
    nameContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    title: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontWeight: 600,
      margin: '4px 0'
    },
    concordeTitle: {
      textAlign: 'center',
      color: theme.palette.text.primary,
      margin: '12px 0'
    }
  })
)

export const TileHeader = () => {
  const classes = useStyles()
  const {
    product,
    useNewDesign,
    config: { coupons },
    pricingDetails,
    config
  } = useTileContext()

  const coupon = useMemo(() => {
    const [firstItem] = pricingDetails.items ?? []

    /**
     * Coupon should only be displayed if it results in an actual discount being applied
     * (note that in an initial phase composite prices don't get discounts applied)
     */
    if (
      typeof firstItem?.discount_amount === 'number' &&
      firstItem?.discount_amount > 0
    ) {
      const [firstCoupon] = coupons

      return firstCoupon ?? null
    }

    return null
  }, [coupons, pricingDetails.items])

  const ProductNameComponent = useNewDesign ? Typography : H5

  return (
    <header className={useNewDesign ? '' : classes.headerContainer}>
      <NormalizedSectionContainer
        className={useNewDesign ? '' : classes.nameContainer}
        sectionName={useNewDesign ? 'concordeTitle' : 'title'}
      >
        <Spacing alignItems="center" scale={3} variant="inline">
          <ProductNameComponent
            as="h3"
            className={useNewDesign ? classes.concordeTitle : classes.title}
            id="tileTitle"
          >
            {product.name}
          </ProductNameComponent>
        </Spacing>
        {coupon?.type === 'percentage' &&
          !config.generalTileConfiguration.showImages && (
            <ProductTileCoupon coupon={coupon} />
          )}
      </NormalizedSectionContainer>
      {(coupon && <ProductImageWithCoupon coupon={coupon} />) || (
        <ProductImageRenderer />
      )}
    </header>
  )
}
