import { ButtonGroup as MuiButtonGroup } from '@material-ui/core'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import type { ButtonGroupProps } from './types'

export const ButtonGroup = forwardRef<
  ElementRef<typeof MuiButtonGroup>,
  PropsWithoutRef<ButtonGroupProps>
>((props, ref) => <MuiButtonGroup disableElevation ref={ref} {...props} />)

ButtonGroup.displayName = 'ButtonGroup'
