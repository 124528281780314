import { useContext, createContext, useEffect } from 'react'

import { DefaultConcordeTheme } from './DefaultConcordeTheme'
import type { ConcordeTheme, ThemeProviderProps } from './types'

import '../../global.css'

const ThemeContext = createContext<ConcordeTheme | undefined>(undefined)

export function useTheme() {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}

export function ThemeProvider({
  children,
  theme = DefaultConcordeTheme
}: ThemeProviderProps) {
  useEffect(() => {
    const cssVariables = {
      '--concorde-primary-color': theme.palette.primary,
      '--concorde-secondary-color': theme.palette.secondary,
      '--concorde-disabled-color': theme.palette.disabled,
      '--concorde-error-color': theme.palette.error,
      '--concorde-font-family': theme.typography.fontFamily,
      '--concorde-border-radius': `${theme.shape.borderRadius}px`,
      '--concorde-spacing': `${theme.spacing}px`,
      '--concorde-primary-text': theme.palette.typography.primary,
      '--concorde-secondary-text': theme.palette.typography.secondary,
      '--concorde-disabled-text': theme.palette.typography.disabled
    }

    Object.entries(cssVariables).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value)
    })

    return () => {
      Object.keys(cssVariables).forEach((key) => {
        document.documentElement.style.removeProperty(key)
      })
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={DefaultConcordeTheme}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.displayName = 'ThemeProvider'
