import type { Config } from '@epilot/journey-logic-commons'
import type { ReactNode } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

type ConfigProviderProps = {
  config: Config
  children: ReactNode
}

// Create a context for the configuration with a default empty object
const ConfigContext = createContext<Config | undefined>(undefined)

// Custom hook to use the Config context
export const useConfig = (): Config => {
  const context = useContext(ConfigContext)

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}

// Provider component to wrap your app and provide the config
export const ConfigProvider: React.FC<ConfigProviderProps> = ({
  config,
  children
}) => {
  const memoizedConfig = useMemo(() => config, [config])

  return (
    <ConfigContext.Provider value={memoizedConfig}>
      {children}
    </ConfigContext.Provider>
  )
}
