import { Spacing, Typography } from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Coupon } from '@epilot/journey-logic-commons'
import { clsx, formatDiscount } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'

const useProductTileCouponStyles = makeStyles((theme: Theme) =>
  createStyles({
    couponContainer: {
      backgroundColor: theme.palette.error.main,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 2),
      display: 'inline-flex'
    },
    discountText: {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold as never
    },
    discountSymbol: {
      color: theme.palette.primary.contrastText
    }
  })
)

type ProductTileCouponProps = ComponentProps<'div'> & {
  coupon: Coupon
}

export const ProductTileCoupon = ({
  coupon,
  className,
  ...props
}: ProductTileCouponProps) => {
  const classes = useProductTileCouponStyles()

  return (
    <div className={clsx(classes.couponContainer, className)} {...props}>
      <Spacing alignItems="center" scale={2} variant="inline">
        <Typography className={classes.discountText}>
          {formatDiscount(coupon)}
        </Typography>
      </Spacing>
    </div>
  )
}
