import {
  getOptimizedImageURL,
  areProductImagesHydrated,
  appendStepBlockId,
  getPublicUrl
} from '@epilot/journey-logic-commons'
import { useMemo, type ComponentProps } from 'react'

import { ImageStepper } from '../../../../../components'
import classes from '../../../styles/ProductImageRenderer.module.scss'
import { useTileContext } from '../../../utils/tile-context-utils'

import { DefaultProductImage } from './DefaultProductImage'
import type { ImageFile } from './ProductImage'
import { OPTIMAL_IMAGE_WIDTH, ProductImage } from './ProductImage'

const ProductImageWrapper = (props: ComponentProps<'figure'>) => {
  return <figure className={classes.container} {...props} />
}

const DEFAULT_IMAGES: Array<ImageFile> = []

export const ProductImageRenderer = ({
  IMAGE_PREVIEW_API_URL
}: {
  IMAGE_PREVIEW_API_URL: string
}) => {
  const {
    product,
    config,
    id,
    product: { name },
    tileVariantsEnabled
  } = useTileContext()

  const shouldRender =
    tileVariantsEnabled || config.generalTileConfiguration.showImages

  const optimizedImages = useMemo(() => {
    /* Avoid computing the optimized image urls if nothing is going to get rendered */
    if (!areProductImagesHydrated(product) || !shouldRender) {
      return DEFAULT_IMAGES
    }

    /* Omit images with private access control, map them into optimized images */
    return product.product_images
      .filter(({ access_control }) => access_control !== 'private')
      .map<ImageFile>((image) => ({
        ...image,
        id: image._id,
        src: getOptimizedImageURL(
          IMAGE_PREVIEW_API_URL,
          getPublicUrl(image),
          image._id,
          OPTIMAL_IMAGE_WIDTH
        )
      }))
  }, [IMAGE_PREVIEW_API_URL, product, shouldRender])

  if (!shouldRender) {
    return null
  }

  if (optimizedImages.length === 0) {
    return (
      <ProductImageWrapper>
        <DefaultProductImage />
      </ProductImageWrapper>
    )
  }

  if (optimizedImages.length === 1) {
    const [image] = optimizedImages

    return (
      <ProductImageWrapper>
        <ProductImage image={image} />
      </ProductImageWrapper>
    )
  }

  return (
    <ProductImageWrapper>
      <ImageStepper
        id={appendStepBlockId(id, `${name} Image Stepper`)}
        images={optimizedImages}
      />
    </ProductImageWrapper>
  )
}
