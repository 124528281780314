import type { Components } from '@epilot/address-suggestions-client'
import type {
  Address,
  AddressSuggestionsSource
} from '@epilot/journey-logic-commons'
import { convertToAddressSuggestionsSourceType } from '@epilot/journey-logic-commons'
import throttle from 'lodash/throttle'
import uniqBy from 'lodash/uniqBy'
import { useMemo, useState } from 'react'

import { apiCall } from '../../../../api'
import { useConfig } from '../../../../utils'
import getAddressSuggestionsClient from '../../../../utils/clients/address-suggestions-client'
import { searchGetAgStreets } from '../../../../utils/services/product-service'
import type { StreetSuggestionData, ZipCitySuggestionData } from '../types'
import { isStreetSuggestionData, isZipCitySuggestionData } from '../types.guard'
import {
  constructStreetUrl,
  constructSuburbUrl,
  constructZipCityUrl,
  removeDiacritics
} from '../utils'

type AddressSuggestions = Components.Schemas.AddressSuggestions

const MIN_CHARS = 2

async function fetchAddressSuggestions(
  config: {
    addressApiUrl: string
    addressSuggestionsApiUrl: string
    pricingApiUrl: string
  },
  sources: AddressSuggestionsSource[],
  {
    countryCode,
    postalCodeTerm,
    city,
    streetTerm,
    suburbTerm
  }: {
    countryCode: string | undefined
    postalCodeTerm: string | undefined
    city: string | undefined
    streetTerm?: string
    suburbTerm?: string
  },
  publicToken: string,
  /**
   * @deprecated Use `fileId` instead
   */
  s3FileUrl?: string,
  orgId?: string,
  field?: string,
  fileId?: string
): Promise<AddressSuggestions> {
  const headers: Record<string, string> = publicToken
    ? { Authorization: `Bearer ${publicToken}` }
    : {}

  let combinedResults: AddressSuggestions = []

  for (const source of sources) {
    let sourceResults: AddressSuggestions = []

    if (source === 'deutschePostService') {
      if (field === 'street' && countryCode && postalCodeTerm && streetTerm) {
        const dpServiceRes = await apiCall<AddressSuggestions>({
          method: 'GET',
          url: constructStreetUrl(config.addressApiUrl, {
            countryCode,
            city,
            zipCode: postalCodeTerm,
            streetName: streetTerm
          }),
          headers
        })

        sourceResults = dpServiceRes.data || []
      }

      if (
        !sourceResults.length &&
        field === 'suburb' &&
        countryCode &&
        postalCodeTerm &&
        suburbTerm
      ) {
        const dpServiceRes = await apiCall<AddressSuggestions>({
          method: 'GET',
          url: constructSuburbUrl(config.addressApiUrl, {
            countryCode,
            city,
            zipCode: postalCodeTerm,
            suburb: suburbTerm
          }),
          headers
        })

        sourceResults = dpServiceRes.data || []
      }

      if (!sourceResults.length && countryCode && postalCodeTerm) {
        const dpServiceRes = await apiCall<AddressSuggestions>({
          method: 'GET',
          url: constructZipCityUrl(
            config.addressApiUrl,
            countryCode,
            postalCodeTerm
          ),
          headers
        })

        sourceResults = dpServiceRes.data || []
      }
    }

    if (source === 'customAddressesFile' && (s3FileUrl || fileId) && orgId) {
      const getAddressesRes = await getAddressSuggestionsClient(
        config.addressSuggestionsApiUrl,
        orgId,
        publicToken
      ).getAddresses({
        ...(s3FileUrl ? { s3FileUrl } : {}),
        ...(fileId ? { fileId } : {}),
        countryCodeSearchTerm: countryCode,
        postalCodeSearchTerm:
          field === 'zipCity'
            ? postalCodeTerm
            : `${postalCodeTerm}${city ? ` ${city}` : ''}`,
        streetSearchTerm: streetTerm
      } as never)

      sourceResults = getAddressesRes.data
    }

    if (source === 'getAgService') {
      const response = await searchGetAgStreets(
        config.pricingApiUrl,
        orgId || '',
        {
          postalCode: postalCodeTerm || '',
          city: city || ''
        }
      )

      sourceResults = response.data.filter(({ street }) =>
        removeDiacritics(street.toLowerCase()).includes(
          removeDiacritics(streetTerm?.toLowerCase() || '')
        )
      )
    }

    combinedResults = [...combinedResults, ...sourceResults]
  }

  // remove duplicates when combining results from multiple sources by postal_code, city and street
  return uniqBy(
    combinedResults,
    (item) => `${item.postal_code}-${item.city}-${item.street}`
  )
}

export function useAddressSuggestions(
  field: 'street' | 'zipCity' | 'suburb',
  disableAddressSuggestions: boolean | undefined,
  fieldValues: Address,
  initialSources: AddressSuggestionsSource[] | string | undefined,
  publicToken: string,
  /**
   * @deprecated Use `fileId` instead
   */
  s3FileUrl?: string,
  orgId?: string,
  fileId?: string
) {
  const [options, setOptions] = useState<
    ZipCitySuggestionData | StreetSuggestionData
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [addressDown, setAddressDown] = useState<boolean>(false)
  const [metaValue, setMetaValue] = useState<
    { manualData: boolean } | undefined
  >()

  const sources: AddressSuggestionsSource[] =
    convertToAddressSuggestionsSourceType(initialSources)

  const { ADDRESS_API_URL, ADDRESS_SUGGESTIONS_API_URL, PRICING_API_URL } =
    useConfig()

  const getSuggestions = useMemo(
    () =>
      throttle(async (value: string) => {
        try {
          setIsLoading(true)
          const countryCode = fieldValues.countryCode
          const city = fieldValues.city
          const postalCodeTerm =
            field === 'zipCity' ? value : fieldValues.zipCode
          const streetTerm = field === 'street' ? value : fieldValues.streetName
          const suburbTerm = field === 'suburb' ? value : fieldValues.suburb

          if (
            disableAddressSuggestions ||
            !value ||
            value.length <= MIN_CHARS
          ) {
            setIsLoading(false)
            setOptions([])

            return []
          }

          const data = await fetchAddressSuggestions(
            {
              addressApiUrl: ADDRESS_API_URL,
              addressSuggestionsApiUrl: ADDRESS_SUGGESTIONS_API_URL,
              pricingApiUrl: PRICING_API_URL
            },
            sources,
            {
              countryCode,
              postalCodeTerm,
              city,
              streetTerm,
              suburbTerm
            },
            publicToken,
            s3FileUrl,
            orgId,
            field,
            fileId
          )

          if (!isZipCitySuggestionData(data) && !isStreetSuggestionData(data)) {
            throw Error(
              `Invalid API Response received of ${JSON.stringify(data)}`
            )
          }

          setIsLoading(false)
          setOptions(data)
          setAddressDown?.(false)
          setMetaValue(undefined)

          return data
        } catch (e) {
          setAddressDown?.(true)
          setIsLoading(false)
          setMetaValue?.({ manualData: true })
          console.error('Error when fetching Address Options', { error: e })

          return []
        }
      }, 500),
    [
      disableAddressSuggestions,
      s3FileUrl,
      orgId,
      sources,
      fieldValues,
      setMetaValue,
      setIsLoading,
      setAddressDown,
      setOptions,
      field,
      ADDRESS_API_URL,
      ADDRESS_SUGGESTIONS_API_URL,
      PRICING_API_URL,
      fileId
    ]
  )

  return {
    addressDown,
    getSuggestions,
    isLoading,
    options,
    metaValue
  }
}
