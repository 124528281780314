import { Button, Typography, Grid, Icon } from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { useAddDeviceButtonStyles } from '../styles'

export const AddDeviceButton = ({
  onClick,
  id
}: {
  onClick: () => void
  id: string
}) => {
  const { t } = useTranslation()
  const classes = useAddDeviceButtonStyles()

  return (
    <Grid item>
      <Button
        className={classes.addDeviceButton}
        color="secondary"
        id={appendStepBlockId(id, 'Add Device')}
        onClick={onClick}
        variant="text"
      >
        <Icon color="secondary" name="add" />
        <Typography color="secondary">
          {t('input_calculator.add_device', 'Add Device')}
        </Typography>
      </Button>
    </Grid>
  )
}
