import { EnumControl } from '@epilot/journey-elements'
import type { FieldOption } from '@epilot/journey-logic-commons'
import type {
  Control,
  Path,
  PathValue,
  UseControllerProps,
  FieldValues
} from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FieldSelectProps<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: PathValue<T, Path<T>>
  rules?: UseControllerProps['rules']
  options: string[]
  size?: 'small' | 'medium'
  optionsLabels?: string[]
  customBackground?: string
  id?: string
} & FieldOption

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldSelect = <T extends Record<string, any>>(
  props: FieldSelectProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    errorMessage,
    defaultValue,
    options,
    optionsLabels,
    required,
    rules,
    customBackground,
    size,
    id
  } = props

  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <EnumControl
            customBackgroundColor={customBackground}
            errorMessage={
              hasError && fieldState.invalid
                ? errorMessage || t('field_required')
                : undefined
            }
            id={id}
            label={label}
            options={options}
            optionsLabels={optionsLabels}
            placeholder={''}
            required={required}
            size={size ?? 'medium'}
            uiType="select"
            {...field}
          />
        )
      }}
      rules={{
        required,
        ...(rules && { rules })
      }}
    />
  )
}
