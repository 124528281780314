import { createStyles, makeStyles } from '@epilot/journey-elements'
import type { Theme } from '@epilot/journey-elements'
import ExpandMore from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles<Theme, ExpandIconProps>((theme) =>
  createStyles({
    icon: {
      transform: (props) =>
        props.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
      marginLeft: 'auto',
      height: 33,
      color: theme.palette.primary.main
    }
  })
)

interface ExpandIconProps {
  expanded: boolean
}

export const ExpandIcon = (props: ExpandIconProps) => {
  const classes = useStyles(props)

  return <ExpandMore className={classes.icon} name="chevron-down" />
}
