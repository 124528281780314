import { MobileStepper as MuiMobileStepper } from '@material-ui/core'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import type { MobileStepperProps } from './types'

export const MobileStepper = forwardRef<
  ElementRef<typeof MuiMobileStepper>,
  PropsWithoutRef<MobileStepperProps>
>((props, ref) => <MuiMobileStepper ref={ref} {...props} />)

MobileStepper.displayName = 'MobileStepper'
