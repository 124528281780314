import { makeStyles, createStyles } from '@epilot/journey-elements'

export const useDeviceSelectionStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      gap: '8px'
    },
    field: {
      width: '50%',
      flexGrow: 1
    }
  })
)
