export { Typography } from '@material-ui/core'
import { Typography as MuiTypography } from '@material-ui/core'

import type { TypographyProps } from './types'

/**
 * @private Should not be exported
 */
const CustomColoredTypography = ({
  style,
  customColor,
  ...props
}: TypographyProps) => (
  <MuiTypography style={{ color: customColor, ...style }} {...props} />
)

CustomColoredTypography.displayName = 'CustomColoredTypography'

export const H1 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h1" {...props} />
)

H1.displayName = 'H1'

export const H2 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h2" {...props} />
)

H2.displayName = 'H2'

export const H3 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h3" {...props} />
)

H3.displayName = 'H3'

export const H4 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h4" {...props} />
)

H4.displayName = 'H4'

export const H5 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h5" {...props} />
)

H5.displayName = 'H5'

export const H6 = (props: TypographyProps) => (
  <CustomColoredTypography variant="h6" {...props} />
)

H6.displayName = 'H6'

export const DisplayText = (props: TypographyProps) => <H6 {...props} />

DisplayText.defaultName = 'DisplayText'

export const LargeText = (props: TypographyProps) => (
  <CustomColoredTypography variant="body1" {...props} />
)

LargeText.displayName = 'LargeText'

export const BodyText = (props: TypographyProps) => (
  <CustomColoredTypography variant="body2" {...props} />
)

BodyText.displayName = 'BodyText'

export const SmallText = (props: TypographyProps) => (
  <CustomColoredTypography variant="caption" {...props} />
)

SmallText.displayName = 'SmallText'
