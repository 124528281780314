import type { EpilotTheme } from '@epilot/journey-elements'
import { makeStyles, createStyles } from '@epilot/journey-elements'

export const useWidgetStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    paper: {
      width: '300px',
      padding: '12px 20px 16px',
      fontFamily: theme.typography?.body1?.fontFamily,
      fontSize: theme.typography?.body1?.fontSize,
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    paperMobile: {
      width: '100%',
      padding: '12px 20px 16px',
      fontFamily: theme.typography?.body1?.fontFamily,
      fontSize: theme.typography?.body1?.fontSize,
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    line: {
      display: 'flex',
      alignItems: 'center',
      margin: '5px 0',
      justifyContent: 'space-between'
    },
    textContainer: {
      gap: '8px',
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      fontWeight: 700,
      size: '16px'
    },
    note: {
      fontSize: '12px',
      lineHeight: 'normal'
    },
    percentage: {
      fontWeight: 700,
      fontSize: '12px'
    },
    additionalNote: {
      display: 'inline',
      lineHeight: 'normal'
    },
    warningIcon: {
      minHeight: '24px',
      minWidth: '24px',
      color: theme.palette?.warning?.main
    },
    noDataTextContainer: {
      gap: '8px',
      display: 'flex'
    },
    dataCell: {
      textAlign: 'right'
    },
    yearlyOutputCell: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'right'
    }
  })
)

export const useMapStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%'
    },
    containerMobile: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    widgetsContainerDesktop: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      position: 'absolute',
      top: '10px',
      left: '10px'
    },
    widgetsContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    map: {
      width: '100%',
      height: '550px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    },
    mapMobile: {
      width: '90vw',
      height: '90vw',
      maxWidth: '100%',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)
