import type { Price, PriceItem } from '@epilot/journey-logic-commons'
import { getDisplayPrice } from '@epilot/journey-logic-commons'
import { formatPriceUnit } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { getFormattedQuantity } from '../../../utils/products/quantity-utils'

export const ComponentMappingDataPerUnit = ({
  item,
  showTrailingDecimalZeros,
  t
}: {
  item: PriceItem
  showTrailingDecimalZeros: boolean
  t: TFunction
}) => {
  const formattedQuantity = getFormattedQuantity(item._price as Price, t)
  const formattedPriceUnit = formatPriceUnit(item._price?.unit)

  return (
    <>
      {`${formattedQuantity ? `${formattedQuantity} | ` : ''}
      ${getDisplayPrice(
        t,
        item.unit_amount_gross_decimal,
        item._price?.price_display_in_journeys,
        showTrailingDecimalZeros,
        item.currency
      )}${
        item._price?.variable_price
          ? `/${t(formattedPriceUnit, formattedPriceUnit)}`
          : ''
      }`}
    </>
  )
}
