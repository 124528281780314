import {
  List,
  ListItem,
  ListItemContent,
  ListItemPrefix,
  Typography
} from '@epilot/journey-elements'

import { useStyles } from './styles'
import type { BankTransferProps } from './types'
export function BankTransfer(props: BankTransferProps) {
  const { bankDetails, ...rest } = props
  const classes = useStyles()

  return (
    <List>
      {bankDetails &&
        bankDetails.map((item) => {
          return (
            <ListItem key={item.title}>
              <ListItemPrefix className={classes.itemPrefix}>
                <Typography className={classes.title} variant="body1" {...rest}>
                  {item.title}
                </Typography>
              </ListItemPrefix>
              <ListItemContent>
                <Typography className={classes.title} variant="body1" {...rest}>
                  {item.value}
                </Typography>
              </ListItemContent>
            </ListItem>
          )
        })}
    </List>
  )
}
