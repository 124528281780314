import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import type { ComponentProps, MutableRefObject } from 'react'

type SectionId = string
type ElementRef = MutableRefObject<HTMLElement | null>
type NormalizedHeightMap = Record<SectionId, Map<ElementRef, number>>
type MaximumHeightMap = Record<SectionId, number>
type NormalizedSectionContextValue = {
  maximumHeightMap: MaximumHeightMap
  setElementHeight: (
    sectionId: SectionId,
    elementRef: ElementRef,
    height: number
  ) => void
}

const NormalizedSectionContext = createContext<
  undefined | NormalizedSectionContextValue
>(undefined)

type NormalizedSectionProviderProps = Omit<
  ComponentProps<typeof NormalizedSectionContext.Provider>,
  'value'
>

export const NormalizedSectionProvider = (
  props: NormalizedSectionProviderProps
) => {
  const [normalizedHeightMap, setNormalizedHeightMap] =
    useState<NormalizedHeightMap>({})

  const maximumHeightMap = useMemo<MaximumHeightMap>(
    () =>
      Object.fromEntries(
        Object.entries(normalizedHeightMap).map(([sectionId, elementMap]) => [
          sectionId,
          Math.max(...elementMap.values())
        ])
      ),
    [normalizedHeightMap]
  )

  const setElementHeight = useCallback<
    NormalizedSectionContextValue['setElementHeight']
  >(
    (sectionId, elementId, height) =>
      setNormalizedHeightMap((prev) => ({
        ...prev,
        [sectionId]: new Map([...(prev[sectionId] ?? []), [elementId, height]])
      })),
    []
  )

  const value = useMemo<NormalizedSectionContextValue>(
    () => ({ maximumHeightMap, setElementHeight }),
    [maximumHeightMap, setElementHeight]
  )

  return <NormalizedSectionContext.Provider {...props} value={value} />
}

export const useNormalizedSectionContext = () => {
  const context = useContext(NormalizedSectionContext)

  if (!context) {
    throw new Error(
      'useNormalizedSectionContext must be used within a NormalizedSectionProvider'
    )
  }

  return context
}
