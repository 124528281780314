import { Button as ButtonConcorde } from '@epilot/concorde-elements'
import { Button, makeStyles } from '@epilot/journey-elements'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'

import { useTileContext } from '../../utils/tile-context-utils'

type ProductActionProps = ComponentProps<typeof Button> & {
  onSelect?: () => void
}

export const ProductAction = ({ onSelect, ...props }: ProductActionProps) => {
  const {
    config,
    actions,
    product,
    price,
    tileState,
    useNewDesign,
    id,
    coupons
  } = useTileContext()
  const {
    isSelected,
    generalTileConfiguration: { ctaConfig }
  } = config
  const { label, undoActionLabel, disabled } = ctaConfig
  const ctaLabel = actions.t(isSelected ? undoActionLabel : label)
  const variant = isSelected ? 'outlined' : 'contained'

  const handleOnClick: ProductActionProps['onClick'] = () => {
    if (isSelected) {
      actions.deselectProductTile({
        productId: product._id,
        priceId: price._id
      })
    } else {
      actions.selectProductTile({
        product: {
          selectedProductId: product._id,
          selectedPriceId: price._id,
          selectedCouponIds: coupons?.map((coupon) => coupon._id),
          selectionMetadata: {
            selectedCoupons: coupons,
            selectedProduct: product,
            selectedPrice: price,
            blockConfiguration: {
              isRequired: Boolean(
                config.generalTileConfiguration?.isBlockRequired
              ),
              showQuantity: Boolean(
                config.generalTileConfiguration?.quantityConfig.show
              ),
              blockPath: config.generalTileConfiguration.blockPath || ''
            }
          }
        },
        quantity: tileState.quantity
      })
      onSelect?.()
    }
  }

  const classes = useStyles()

  const buttonId = appendStepBlockId(id, 'Add To Cart Button')

  return (
    <>
      {useNewDesign ? (
        <ButtonConcorde
          isDisabled={disabled}
          onClick={handleOnClick}
          {...props}
          className={classes.button}
          id={buttonId}
          label={ctaLabel}
          tabIndex={0}
          variant={isSelected ? 'ghost' : 'primary'}
        />
      ) : (
        <Button
          color="primary"
          disabled={disabled}
          id={buttonId}
          onClick={handleOnClick}
          size="large"
          variant={variant}
          {...props}
        >
          {ctaLabel}
        </Button>
      )}
    </>
  )
}

const useStyles = makeStyles({
  button: {
    width: `min(200px, 100%)`,
    justifyContent: 'center'
  }
})
