import { createStyles, makeStyles } from '@material-ui/core/styles'

import type { DigitalSignatureProps } from './types'

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      width: (props: Partial<DigitalSignatureProps>) => props.picWidth,
      height: (props: Partial<DigitalSignatureProps>) => props.picHeight,
      background: (props: Partial<DigitalSignatureProps>) =>
        props.backgroundColor,
      borderBottom: 'double',
      margin: 5,
      borderColor: (props: Partial<DigitalSignatureProps>) =>
        props.error && 'red'
    },
    errorContainer: {
      width: (props: Partial<DigitalSignatureProps>) => props.picWidth,
      display: 'flex',
      justifyContent: 'space-between'
    },
    cardContent: {
      background: (props: Partial<DigitalSignatureProps>) =>
        props.backgroundColor
    },
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)
