import { Grid, Hidden, Typography, clsx } from '@epilot/journey-elements'
import type {
  ActionBarControlOptions,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useJourneyContext, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import ActionButtons from './ActionButtons'
import ConsentBlocks from './ConsentBlocks'
import { useStyles } from './styles'
import type { Consents } from './types'
import { getRequiredConsents } from './utils'

function ActionBarControl(props: EpilotControlProps) {
  const { data, handleChange, path, uischema, visible, errors } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const { ctaButton, consents, goBackButton } =
    uischema.options as ActionBarControlOptions
  const [consentsData, setConsentsData] = useState<Consents | undefined>(data)
  const { context } = useJourneyContext()

  const stepBlockId = useStepBlockId(path)

  const requiredFields = getRequiredConsents(consents)

  const handleOnChange = (data: Consents, _consent: string) => {
    setConsentsData(data)
    let tempErrors = {}

    requiredFields &&
      Object.keys(requiredFields).forEach((k) => {
        if (
          requiredFields?.[k] &&
          (data?.[k] === undefined || data?.[k] === false)
        ) {
          tempErrors = { ...tempErrors, [k]: true }
        }
      })

    if (handleChange) {
      Object.keys(tempErrors).length === 0
        ? handleChange(path, data)
        : handleChange(path, { ...data, _isValid: false })
    }
  }

  const currentStepErrors = useMemo(
    () =>
      context._errors[context._navigationInfo.currentStepIndex]?.filter(
        Boolean
      ) ?? [],
    [context._errors, context._navigationInfo.currentStepIndex]
  )

  const translatedErrorMessage = useMemo(
    () =>
      t(context.errorMessageTranslationKey || 'generic_journey_error', {
        /**
         * @todo Analyse if this redundancy can be removed
         */
        defaultValue: t('generic_journey_error', {
          defaultValue: 'This page has errors. Please fix before proceeding'
        })
      }),
    [context.errorMessageTranslationKey, t]
  )

  const translatedSubmissionError = t(context.submissionErrorKey || '')

  const shouldShowError =
    context._navigationInfo.currentStepIndex === context.erroredStepIndex &&
    (!context.erroredJourneyId ||
      context.erroredJourneyId === context._activeLinkedJourneyId)

  return (
    <Hidden xsUp={!visible}>
      <Grid container spacing={1}>
        <ConsentBlocks
          consents={consents}
          data={consentsData}
          error={errors}
          id={stepBlockId}
          onChange={handleOnChange}
        />
        <Typography
          className={clsx(
            classes.errorMessage,
            (shouldShowError && currentStepErrors.length > 0) ||
              context.submissionErrorKey
              ? classes.displayBlock
              : classes.displayNone
          )}
          color="error"
          gutterBottom
        >
          {context.submissionErrorKey
            ? translatedSubmissionError
            : translatedErrorMessage}
        </Typography>
        <ActionButtons
          ctaButton={ctaButton}
          goBackButton={goBackButton}
          id={stepBlockId}
        />
      </Grid>
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ActionBarControl }) as never
)
