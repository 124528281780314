import type {
  OptionalPriceComponentBlockMappingMetadata,
  PricingBlockMapping,
  BlockMappingData,
  Product,
  Price,
  CompositePrice,
  PriceWithBlockMappings,
  BlockMappingsValues,
  ProductTileData,
  SelectionType,
  SelectedProductTile
} from '@epilot/journey-logic-commons'
import isEqual from 'lodash/isEqual'

export const computeSelectedProductTilesBlockMappings = (
  updatedProductTiles: Array<ProductTileData>,
  selectedProductTiles: Array<SelectedProductTile>
): Array<SelectedProductTile> => {
  if (selectedProductTiles.length === 0) return selectedProductTiles

  const selectedTilesBlockMappings = selectedProductTiles.flatMap<
    BlockMappingData[]
  >((selectedTile) => {
    const selectedPrice = selectedTile.product?.selectionMetadata?.selectedPrice

    if (!selectedPrice) {
      return []
    } else if (selectedPrice.is_composite_price) {
      return (selectedPrice.price_components ?? []).map(
        (priceComponent: PriceWithBlockMappings) =>
          priceComponent.blockMappingData
      )
    } else {
      return selectedPrice.blockMappingData
        ? [selectedPrice.blockMappingData]
        : []
    }
  })

  const updatedTilesBlockMappings = updatedProductTiles.flatMap<
    BlockMappingData[]
  >((updatedTile) => {
    const isTileSelected = selectedProductTiles.some(
      (selectedTile) =>
        updatedTile.price._id === selectedTile.product.selectedPriceId &&
        updatedTile.product._id === selectedTile.product.selectedProductId
    )

    const updatedPrice = updatedTile.price

    if (!isTileSelected) {
      return []
    } else if (updatedPrice.is_composite_price) {
      return (updatedPrice.price_components ?? []).map(
        (priceComponent: PriceWithBlockMappings) =>
          priceComponent.blockMappingData
      )
    } else {
      return updatedTile.price?.blockMappingData
        ? [updatedTile.price?.blockMappingData]
        : []
    }
  })

  if (isEqual(selectedTilesBlockMappings, updatedTilesBlockMappings)) {
    return selectedProductTiles
  }

  return selectedProductTiles.map((selectedTile) => {
    const updatedPrice = updatedProductTiles.find(
      (updatedTile) =>
        updatedTile.price._id === selectedTile.product.selectedPriceId &&
        updatedTile.product._id === selectedTile.product.selectedProductId
    )?.price

    return {
      ...selectedTile,
      product: {
        ...selectedTile.product,
        selectionMetadata: {
          ...selectedTile.product.selectionMetadata,
          selectedPrice: {
            ...updatedPrice
          }
        }
      }
    }
  })
}

export const computeSingleSelectedProductTileBlockMappings = (
  updatedProductTiles: Array<ProductTileData>,
  selectedProductTile: SelectedProductTile
): SelectedProductTile => {
  if (!selectedProductTile) return selectedProductTile

  const updatedPrice = updatedProductTiles.find(
    (updatedTile) =>
      updatedTile.price._id === selectedProductTile.product.selectedPriceId &&
      updatedTile.product._id === selectedProductTile.product.selectedProductId
  )?.price

  if (
    isEqual(
      updatedPrice,
      selectedProductTile.product?.selectionMetadata?.selectedPrice
    )
  ) {
    return selectedProductTile
  }

  return {
    ...selectedProductTile,
    product: {
      ...selectedProductTile.product,
      selectionMetadata: {
        ...selectedProductTile.product.selectionMetadata,
        selectedPrice: {
          ...updatedPrice
        }
      }
    }
  }
}

export function getTileBlockMappingMetadata(
  blockMappingsMetadata?: PricingBlockMapping[],
  productId?: string,
  priceId?: string,
  priceComponentId?: string
) {
  if (!blockMappingsMetadata || !productId || !priceId) {
    return
  }

  return blockMappingsMetadata?.find(({ metadata }) => {
    if (!metadata?.priceId || !metadata?.productId) {
      return false
    }

    return (
      metadata?.productId === productId &&
      metadata?.priceId === priceId &&
      metadata?.priceComponentId === priceComponentId
    )
  })
}

export function getBlockMappingValue(
  blockMappingsMetadata?: PricingBlockMapping[],
  blockMappingsValue?: BlockMappingsValues,
  productId?: string,
  priceId?: string,
  priceComponentId?: string
): BlockMappingData {
  if (!blockMappingsMetadata || !blockMappingsValue || !productId || !priceId) {
    return {
      numberInput: undefined,
      frequencyUnit: undefined
    }
  }

  const blockMappingMetadata = getTileBlockMappingMetadata(
    blockMappingsMetadata,
    productId,
    priceId,
    priceComponentId
  )

  const key = `${blockMappingMetadata?.stepId}/${blockMappingMetadata?.blockName}`

  return {
    numberInput: blockMappingsValue?.[key]?.numberInput
      ? Number(blockMappingsValue?.[key]?.numberInput)
      : undefined,
    frequencyUnit: blockMappingsValue?.[key]?.frequencyUnit
  }
}

export const extractProductTileState = (
  omittedPriceComponents: ReadonlyArray<OptionalPriceComponentBlockMappingMetadata>,
  productTilesData: ReadonlyArray<ProductTileData>,
  inputFromBlockMappings?: BlockMappingsValues,
  blockMappingsMetadata?: PricingBlockMapping[]
): ProductTileData[] => {
  return productTilesData
    .map<ProductTileData>((tileItemData) => {
      if (!tileItemData.price.is_composite_price) {
        const blockMappingData = getBlockMappingValue(
          blockMappingsMetadata,
          inputFromBlockMappings,
          tileItemData.product?._id,
          tileItemData.price?._id
        )

        return {
          ...tileItemData,
          price: {
            ...tileItemData.price,
            ...(blockMappingData && { blockMappingData })
          }
        }
      } else {
        const price_components = tileItemData.price.price_components
          .filter(
            (priceComponent: Price) =>
              !(
                priceComponent._id &&
                omittedPriceComponents?.some(
                  ({ productId, priceId, priceComponentId }) =>
                    productId === tileItemData.product._id &&
                    priceId === tileItemData.price._id &&
                    priceComponentId === priceComponent._id
                )
              )
          )
          .map((priceComponent: Price) => {
            const blockMappingData = getBlockMappingValue(
              blockMappingsMetadata,
              inputFromBlockMappings,
              tileItemData.product?._id,
              tileItemData.price?._id,
              priceComponent._id
            )

            return {
              ...priceComponent,
              ...(blockMappingData && { blockMappingData })
            }
          })

        return {
          ...tileItemData,
          price: {
            ...tileItemData.price,
            price_components
          }
        }
      }
    })
    .filter((tile) => {
      if (tile.price.is_composite_price) {
        return tile.price.price_components.length > 0
      }

      return true
    })
}

export function isProductTileSelected(
  productTile: { product: Product; price: Price | CompositePrice },
  selectedProductTile: SelectedProductTile | SelectedProductTile[] | undefined,
  selectionType: SelectionType = 'single'
): boolean {
  if (!selectedProductTile || !productTile) return false

  if (selectionType === 'single') {
    if (isSelectedProductTile(selectedProductTile)) {
      return (
        selectedProductTile.product.selectedProductId ===
          productTile.product._id &&
        selectedProductTile.product.selectedPriceId === productTile.price._id
      )
    }

    return false
  } else {
    const selectedProductTiles = isSelectedProductTileArray(selectedProductTile)
      ? selectedProductTile
      : []

    return selectedProductTiles.some(
      (tile) =>
        tile.product.selectedProductId === productTile.product._id &&
        tile.product.selectedPriceId === productTile.price._id
    )
  }
}

export const filterValidSelection = (
  visibleProductTiles: Array<ProductTileData> | undefined,
  selectedProductTiles: SelectedProductTile | Array<SelectedProductTile>
): SelectedProductTile | SelectedProductTile[] | undefined => {
  if (!visibleProductTiles) {
    return undefined
  }

  const isTileVisible = (tile: SelectedProductTile) =>
    visibleProductTiles.some(
      (data) =>
        data.price._id === tile.product.selectedPriceId &&
        data.product._id === tile.product.selectedProductId
    )

  if (Array.isArray(selectedProductTiles)) {
    return selectedProductTiles.filter((selection) => isTileVisible(selection))
  } else {
    return isTileVisible(selectedProductTiles)
      ? selectedProductTiles
      : undefined
  }
}

function isSelectedProductTile(
  tile: SelectedProductTile | SelectedProductTile[]
): tile is SelectedProductTile {
  return !Array.isArray(tile)
}

function isSelectedProductTileArray(
  tile: SelectedProductTile | SelectedProductTile[]
): tile is SelectedProductTile[] {
  return Array.isArray(tile)
}

export const PRODUCT_FILTER_MATCH_KEYS = ['productId', 'priceId']
