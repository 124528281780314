import { createStyles, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'

import { clsx } from '../.'

import type { SpacingProps } from './types'

const useStyles = makeStyles<Theme, SpacingProps>((theme: Theme) =>
  createStyles({
    stackSpacing: {
      alignItems: ({ alignItems }: SpacingProps) => alignItems,
      display: 'flex',
      flexDirection: 'column',
      '& > * + *': {
        margin: ({ scale }: SpacingProps) =>
          `${theme.spacing(scale)}px 0 0 !important`
      }
    },
    inlineSpacing: {
      display: 'flex',
      alignItems: ({ alignItems }: SpacingProps) => alignItems,
      '& > * + *': {
        margin: ({ scale }: SpacingProps) =>
          `0 0 0 ${theme.spacing(scale)}px !important`
      }
    },
    insetSpacing: {
      padding: ({ scale }: SpacingProps) => theme.spacing(scale)
    }
  })
)

export function InsetSpacing({ scale, ...props }: SpacingProps) {
  const classes = useStyles({ scale })

  return <div className={classes.insetSpacing} {...props} />
}

export function StackSpacing({
  alignItems = 'flex-start',
  scale,
  className,
  ...props
}: SpacingProps) {
  const classes = useStyles({ alignItems, scale })

  return <div className={clsx(classes.stackSpacing, className)} {...props} />
}

export function InlineSpacing({
  alignItems = 'flex-start',
  scale,
  className,
  ...props
}: SpacingProps) {
  const classes = useStyles({ alignItems, scale })

  return <div className={clsx(classes.inlineSpacing, className)} {...props} />
}
