import { Hidden, EnumControl } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { includeCommon } from '../../utils/includeCommon'

export type EnumSelectControlOptions = {
  optionsLabels?: string[]
  clearPathOnChange?: string[]
}
function EnumSelectControl({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible
}: EpilotControlProps) {
  const { enum: options = [], errorMessage, examples } = schema
  const label = uischema?.options?.label || uischema?.label
  const { optionsLabels, clearPathOnChange = [] } =
    (uischema.options as EnumSelectControlOptions) || {}
  const placeholder =
    Array.isArray(examples) && examples[0] ? examples[0] : label

  const handleOnChange = (value: string) => {
    if (clearPathOnChange && clearPathOnChange.length > 0) {
      clearPathOnChange.forEach((path) => {
        handleChange(path, undefined)
      })
    }
    handleChange(path, value)
  }

  return (
    <Hidden xsUp={!visible}>
      <EnumControl
        defaultSelection={''}
        disabled={!enabled}
        errorMessage={
          errors
            ? errorMessage && typeof errorMessage
              ? errorMessage
              : errors
            : ''
        }
        label={label.trim() ? `${label.trim()}${required ? '*' : ''}` : ''}
        onChange={handleOnChange}
        options={options}
        optionsLabels={optionsLabels}
        placeholder={placeholder}
        required={required}
        uiType="select"
        value={data || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EnumSelectControl }) as never
)
