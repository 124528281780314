/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */

import { isEnumButtonControlData } from './sharedBlocksTypes'
import type {
  ContactControlOptions,
  PvRoofPlannerOptions,
  AvailabilityCheckControlOptions,
  InputCalculatorControlOptions,
  NumberInputControlOptions,
  AddressControlOptions,
  MeterReadingControlOptions,
  AvailabilityCheckControlData
} from './sharedControlOptionsTypes'

import { AvailabilityMode, FactorPreset } from '.'

export function isContactControlOptions(
  obj: any,
  _argumentName?: string
): obj is ContactControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.label === 'undefined' || typeof obj.label === 'string') &&
    (typeof obj.purposeLabels === 'undefined' ||
      (Array.isArray(obj.purposeLabels) &&
        obj.purposeLabels.every((e: any) => typeof e === 'string'))) &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    (typeof obj.fields.salutation === 'undefined' ||
      (((obj.fields.salutation !== null &&
        typeof obj.fields.salutation === 'object') ||
        typeof obj.fields.salutation === 'function') &&
        (typeof obj.fields.salutation.label === 'undefined' ||
          typeof obj.fields.salutation.label === 'string') &&
        (typeof obj.fields.salutation.errorMessage === 'undefined' ||
          typeof obj.fields.salutation.errorMessage === 'string') &&
        (typeof obj.fields.salutation.placeholder === 'undefined' ||
          typeof obj.fields.salutation.placeholder === 'string') &&
        (typeof obj.fields.salutation.required === 'undefined' ||
          obj.fields.salutation.required === false ||
          obj.fields.salutation.required === true) &&
        (typeof obj.fields.salutation.disabled === 'undefined' ||
          obj.fields.salutation.disabled === false ||
          obj.fields.salutation.disabled === true) &&
        (typeof obj.fields.salutation.defaultValue === 'undefined' ||
          typeof obj.fields.salutation.defaultValue === 'string')) ||
      (((obj.fields.salutation !== null &&
        typeof obj.fields.salutation === 'object') ||
        typeof obj.fields.salutation === 'function') &&
        (typeof obj.fields.salutation.genderType === 'undefined' ||
          obj.fields.salutation.genderType === 'GENDER3'))) &&
    (typeof obj.fields.title === 'undefined' ||
      (((obj.fields.title !== null && typeof obj.fields.title === 'object') ||
        typeof obj.fields.title === 'function') &&
        (typeof obj.fields.title.label === 'undefined' ||
          typeof obj.fields.title.label === 'string') &&
        (typeof obj.fields.title.errorMessage === 'undefined' ||
          typeof obj.fields.title.errorMessage === 'string') &&
        (typeof obj.fields.title.placeholder === 'undefined' ||
          typeof obj.fields.title.placeholder === 'string') &&
        (typeof obj.fields.title.required === 'undefined' ||
          obj.fields.title.required === false ||
          obj.fields.title.required === true) &&
        (typeof obj.fields.title.disabled === 'undefined' ||
          obj.fields.title.disabled === false ||
          obj.fields.title.disabled === true) &&
        (typeof obj.fields.title.defaultValue === 'undefined' ||
          typeof obj.fields.title.defaultValue === 'string'))) &&
    (typeof obj.fields.firstName === 'undefined' ||
      (((obj.fields.firstName !== null &&
        typeof obj.fields.firstName === 'object') ||
        typeof obj.fields.firstName === 'function') &&
        (typeof obj.fields.firstName.label === 'undefined' ||
          typeof obj.fields.firstName.label === 'string') &&
        (typeof obj.fields.firstName.errorMessage === 'undefined' ||
          typeof obj.fields.firstName.errorMessage === 'string') &&
        (typeof obj.fields.firstName.placeholder === 'undefined' ||
          typeof obj.fields.firstName.placeholder === 'string') &&
        (typeof obj.fields.firstName.required === 'undefined' ||
          obj.fields.firstName.required === false ||
          obj.fields.firstName.required === true) &&
        (typeof obj.fields.firstName.disabled === 'undefined' ||
          obj.fields.firstName.disabled === false ||
          obj.fields.firstName.disabled === true) &&
        (typeof obj.fields.firstName.defaultValue === 'undefined' ||
          typeof obj.fields.firstName.defaultValue === 'string'))) &&
    (typeof obj.fields.lastName === 'undefined' ||
      (((obj.fields.lastName !== null &&
        typeof obj.fields.lastName === 'object') ||
        typeof obj.fields.lastName === 'function') &&
        (typeof obj.fields.lastName.label === 'undefined' ||
          typeof obj.fields.lastName.label === 'string') &&
        (typeof obj.fields.lastName.errorMessage === 'undefined' ||
          typeof obj.fields.lastName.errorMessage === 'string') &&
        (typeof obj.fields.lastName.placeholder === 'undefined' ||
          typeof obj.fields.lastName.placeholder === 'string') &&
        (typeof obj.fields.lastName.required === 'undefined' ||
          obj.fields.lastName.required === false ||
          obj.fields.lastName.required === true) &&
        (typeof obj.fields.lastName.disabled === 'undefined' ||
          obj.fields.lastName.disabled === false ||
          obj.fields.lastName.disabled === true) &&
        (typeof obj.fields.lastName.defaultValue === 'undefined' ||
          typeof obj.fields.lastName.defaultValue === 'string'))) &&
    (typeof obj.fields.birthDate === 'undefined' ||
      (((obj.fields.birthDate !== null &&
        typeof obj.fields.birthDate === 'object') ||
        typeof obj.fields.birthDate === 'function') &&
        (typeof obj.fields.birthDate.label === 'undefined' ||
          typeof obj.fields.birthDate.label === 'string') &&
        (typeof obj.fields.birthDate.errorMessage === 'undefined' ||
          typeof obj.fields.birthDate.errorMessage === 'string') &&
        (typeof obj.fields.birthDate.placeholder === 'undefined' ||
          typeof obj.fields.birthDate.placeholder === 'string') &&
        (typeof obj.fields.birthDate.required === 'undefined' ||
          obj.fields.birthDate.required === false ||
          obj.fields.birthDate.required === true) &&
        (typeof obj.fields.birthDate.disabled === 'undefined' ||
          obj.fields.birthDate.disabled === false ||
          obj.fields.birthDate.disabled === true) &&
        (typeof obj.fields.birthDate.defaultValue === 'undefined' ||
          obj.fields.birthDate.defaultValue instanceof Date))) &&
    (typeof obj.fields.email === 'undefined' ||
      (((obj.fields.email !== null && typeof obj.fields.email === 'object') ||
        typeof obj.fields.email === 'function') &&
        (typeof obj.fields.email.label === 'undefined' ||
          typeof obj.fields.email.label === 'string') &&
        (typeof obj.fields.email.errorMessage === 'undefined' ||
          typeof obj.fields.email.errorMessage === 'string') &&
        (typeof obj.fields.email.placeholder === 'undefined' ||
          typeof obj.fields.email.placeholder === 'string') &&
        (typeof obj.fields.email.required === 'undefined' ||
          obj.fields.email.required === false ||
          obj.fields.email.required === true) &&
        (typeof obj.fields.email.disabled === 'undefined' ||
          obj.fields.email.disabled === false ||
          obj.fields.email.disabled === true) &&
        (typeof obj.fields.email.defaultValue === 'undefined' ||
          typeof obj.fields.email.defaultValue === 'string'))) &&
    (typeof obj.fields.telephone === 'undefined' ||
      (((obj.fields.telephone !== null &&
        typeof obj.fields.telephone === 'object') ||
        typeof obj.fields.telephone === 'function') &&
        (typeof obj.fields.telephone.label === 'undefined' ||
          typeof obj.fields.telephone.label === 'string') &&
        (typeof obj.fields.telephone.errorMessage === 'undefined' ||
          typeof obj.fields.telephone.errorMessage === 'string') &&
        (typeof obj.fields.telephone.placeholder === 'undefined' ||
          typeof obj.fields.telephone.placeholder === 'string') &&
        (typeof obj.fields.telephone.required === 'undefined' ||
          obj.fields.telephone.required === false ||
          obj.fields.telephone.required === true) &&
        (typeof obj.fields.telephone.disabled === 'undefined' ||
          obj.fields.telephone.disabled === false ||
          obj.fields.telephone.disabled === true) &&
        (typeof obj.fields.telephone.defaultValue === 'undefined' ||
          typeof obj.fields.telephone.defaultValue === 'string'))) &&
    (typeof obj.fields.customerType === 'undefined' ||
      (((obj.fields.customerType !== null &&
        typeof obj.fields.customerType === 'object') ||
        typeof obj.fields.customerType === 'function') &&
        (typeof obj.fields.customerType.label === 'undefined' ||
          typeof obj.fields.customerType.label === 'string') &&
        (typeof obj.fields.customerType.errorMessage === 'undefined' ||
          typeof obj.fields.customerType.errorMessage === 'string') &&
        (typeof obj.fields.customerType.placeholder === 'undefined' ||
          typeof obj.fields.customerType.placeholder === 'string') &&
        (typeof obj.fields.customerType.required === 'undefined' ||
          obj.fields.customerType.required === false ||
          obj.fields.customerType.required === true) &&
        (typeof obj.fields.customerType.disabled === 'undefined' ||
          obj.fields.customerType.disabled === false ||
          obj.fields.customerType.disabled === true) &&
        (typeof obj.fields.customerType.defaultValue === 'undefined' ||
          obj.fields.customerType.defaultValue === 'Private' ||
          obj.fields.customerType.defaultValue === 'Business')) ||
      (((obj.fields.customerType !== null &&
        typeof obj.fields.customerType === 'object') ||
        typeof obj.fields.customerType === 'function') &&
        (typeof obj.fields.customerType.options === 'undefined' ||
          (Array.isArray(obj.fields.customerType.options) &&
            obj.fields.customerType.options.every(
              (e: any) =>
                ((e !== null && typeof e === 'object') ||
                  typeof e === 'function') &&
                (e.value === 'Private' || e.value === 'Business') &&
                (typeof e.label === 'undefined' || typeof e.label === 'string')
            ))))) &&
    (typeof obj.fields.companyName === 'undefined' ||
      (((obj.fields.companyName !== null &&
        typeof obj.fields.companyName === 'object') ||
        typeof obj.fields.companyName === 'function') &&
        (typeof obj.fields.companyName.label === 'undefined' ||
          typeof obj.fields.companyName.label === 'string') &&
        (typeof obj.fields.companyName.errorMessage === 'undefined' ||
          typeof obj.fields.companyName.errorMessage === 'string') &&
        (typeof obj.fields.companyName.placeholder === 'undefined' ||
          typeof obj.fields.companyName.placeholder === 'string') &&
        (typeof obj.fields.companyName.required === 'undefined' ||
          obj.fields.companyName.required === false ||
          obj.fields.companyName.required === true) &&
        (typeof obj.fields.companyName.disabled === 'undefined' ||
          obj.fields.companyName.disabled === false ||
          obj.fields.companyName.disabled === true) &&
        (typeof obj.fields.companyName.defaultValue === 'undefined' ||
          typeof obj.fields.companyName.defaultValue === 'string')) ||
      (((obj.fields.companyName !== null &&
        typeof obj.fields.companyName === 'object') ||
        typeof obj.fields.companyName === 'function') &&
        (typeof obj.fields.companyName.showIf === 'undefined' ||
          (((obj.fields.companyName.showIf !== null &&
            typeof obj.fields.companyName.showIf === 'object') ||
            typeof obj.fields.companyName.showIf === 'function') &&
            obj.fields.companyName.showIf.customerType === 'Business')))) &&
    (typeof obj.fields.registryCourt === 'undefined' ||
      (((obj.fields.registryCourt !== null &&
        typeof obj.fields.registryCourt === 'object') ||
        typeof obj.fields.registryCourt === 'function') &&
        (typeof obj.fields.registryCourt.label === 'undefined' ||
          typeof obj.fields.registryCourt.label === 'string') &&
        (typeof obj.fields.registryCourt.errorMessage === 'undefined' ||
          typeof obj.fields.registryCourt.errorMessage === 'string') &&
        (typeof obj.fields.registryCourt.placeholder === 'undefined' ||
          typeof obj.fields.registryCourt.placeholder === 'string') &&
        (typeof obj.fields.registryCourt.required === 'undefined' ||
          obj.fields.registryCourt.required === false ||
          obj.fields.registryCourt.required === true) &&
        (typeof obj.fields.registryCourt.disabled === 'undefined' ||
          obj.fields.registryCourt.disabled === false ||
          obj.fields.registryCourt.disabled === true) &&
        (typeof obj.fields.registryCourt.defaultValue === 'undefined' ||
          typeof obj.fields.registryCourt.defaultValue === 'string')) ||
      (((obj.fields.registryCourt !== null &&
        typeof obj.fields.registryCourt === 'object') ||
        typeof obj.fields.registryCourt === 'function') &&
        (typeof obj.fields.registryCourt.showIf === 'undefined' ||
          (((obj.fields.registryCourt.showIf !== null &&
            typeof obj.fields.registryCourt.showIf === 'object') ||
            typeof obj.fields.registryCourt.showIf === 'function') &&
            obj.fields.registryCourt.showIf.customerType === 'Business')))) &&
    (typeof obj.fields.registerNumber === 'undefined' ||
      (((obj.fields.registerNumber !== null &&
        typeof obj.fields.registerNumber === 'object') ||
        typeof obj.fields.registerNumber === 'function') &&
        (typeof obj.fields.registerNumber.label === 'undefined' ||
          typeof obj.fields.registerNumber.label === 'string') &&
        (typeof obj.fields.registerNumber.errorMessage === 'undefined' ||
          typeof obj.fields.registerNumber.errorMessage === 'string') &&
        (typeof obj.fields.registerNumber.placeholder === 'undefined' ||
          typeof obj.fields.registerNumber.placeholder === 'string') &&
        (typeof obj.fields.registerNumber.required === 'undefined' ||
          obj.fields.registerNumber.required === false ||
          obj.fields.registerNumber.required === true) &&
        (typeof obj.fields.registerNumber.disabled === 'undefined' ||
          obj.fields.registerNumber.disabled === false ||
          obj.fields.registerNumber.disabled === true) &&
        (typeof obj.fields.registerNumber.defaultValue === 'undefined' ||
          typeof obj.fields.registerNumber.defaultValue === 'string')) ||
      (((obj.fields.registerNumber !== null &&
        typeof obj.fields.registerNumber === 'object') ||
        typeof obj.fields.registerNumber === 'function') &&
        (typeof obj.fields.registerNumber.showIf === 'undefined' ||
          (((obj.fields.registerNumber.showIf !== null &&
            typeof obj.fields.registerNumber.showIf === 'object') ||
            typeof obj.fields.registerNumber.showIf === 'function') &&
            obj.fields.registerNumber.showIf.customerType === 'Business'))))
  )
}

export function isPvRoofPlannerOptions(
  obj: any,
  _argumentName?: string
): obj is PvRoofPlannerOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.relatedAddressBlock === 'undefined' ||
      typeof obj.relatedAddressBlock === 'string') &&
    (typeof obj.relatedNumberBlock === 'undefined' ||
      typeof obj.relatedNumberBlock === 'string') &&
    (typeof obj.showRecommendAmountOfPanels === 'undefined' ||
      obj.showRecommendAmountOfPanels === false ||
      obj.showRecommendAmountOfPanels === true)
  )
}

export function isAvailabilityCheckControlOptions(
  obj: any,
  _argumentName?: string
): obj is AvailabilityCheckControlOptions {
  return (
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      ((obj.fields !== null && typeof obj.fields === 'object') ||
        typeof obj.fields === 'function') &&
      ((((obj.fields.zipCode !== null &&
        typeof obj.fields.zipCode === 'object') ||
        typeof obj.fields.zipCode === 'function') &&
        (typeof obj.fields.zipCode.label === 'undefined' ||
          typeof obj.fields.zipCode.label === 'string') &&
        (typeof obj.fields.zipCode.errorMessage === 'undefined' ||
          typeof obj.fields.zipCode.errorMessage === 'string') &&
        (typeof obj.fields.zipCode.placeholder === 'undefined' ||
          typeof obj.fields.zipCode.placeholder === 'string') &&
        (typeof obj.fields.zipCode.required === 'undefined' ||
          obj.fields.zipCode.required === false ||
          obj.fields.zipCode.required === true) &&
        (typeof obj.fields.zipCode.disabled === 'undefined' ||
          obj.fields.zipCode.disabled === false ||
          obj.fields.zipCode.disabled === true) &&
        (typeof obj.fields.zipCode.defaultValue === 'undefined' ||
          typeof obj.fields.zipCode.defaultValue === 'string')) ||
        (((obj.fields.zipCode !== null &&
          typeof obj.fields.zipCode === 'object') ||
          typeof obj.fields.zipCode === 'function') &&
          (typeof obj.fields.zipCode.layout === 'undefined' ||
            (((obj.fields.zipCode.layout !== null &&
              typeof obj.fields.zipCode.layout === 'object') ||
              typeof obj.fields.zipCode.layout === 'function') &&
              (typeof obj.fields.zipCode.layout.xs === 'undefined' ||
                obj.fields.zipCode.layout.xs === false ||
                obj.fields.zipCode.layout.xs === true ||
                obj.fields.zipCode.layout.xs === 'auto' ||
                obj.fields.zipCode.layout.xs === 1 ||
                obj.fields.zipCode.layout.xs === 2 ||
                obj.fields.zipCode.layout.xs === 3 ||
                obj.fields.zipCode.layout.xs === 4 ||
                obj.fields.zipCode.layout.xs === 5 ||
                obj.fields.zipCode.layout.xs === 6 ||
                obj.fields.zipCode.layout.xs === 7 ||
                obj.fields.zipCode.layout.xs === 8 ||
                obj.fields.zipCode.layout.xs === 9 ||
                obj.fields.zipCode.layout.xs === 10 ||
                obj.fields.zipCode.layout.xs === 11 ||
                obj.fields.zipCode.layout.xs === 12) &&
              (typeof obj.fields.zipCode.layout.sm === 'undefined' ||
                obj.fields.zipCode.layout.sm === false ||
                obj.fields.zipCode.layout.sm === true ||
                obj.fields.zipCode.layout.sm === 'auto' ||
                obj.fields.zipCode.layout.sm === 1 ||
                obj.fields.zipCode.layout.sm === 2 ||
                obj.fields.zipCode.layout.sm === 3 ||
                obj.fields.zipCode.layout.sm === 4 ||
                obj.fields.zipCode.layout.sm === 5 ||
                obj.fields.zipCode.layout.sm === 6 ||
                obj.fields.zipCode.layout.sm === 7 ||
                obj.fields.zipCode.layout.sm === 8 ||
                obj.fields.zipCode.layout.sm === 9 ||
                obj.fields.zipCode.layout.sm === 10 ||
                obj.fields.zipCode.layout.sm === 11 ||
                obj.fields.zipCode.layout.sm === 12) &&
              (typeof obj.fields.zipCode.layout.md === 'undefined' ||
                obj.fields.zipCode.layout.md === false ||
                obj.fields.zipCode.layout.md === true ||
                obj.fields.zipCode.layout.md === 'auto' ||
                obj.fields.zipCode.layout.md === 1 ||
                obj.fields.zipCode.layout.md === 2 ||
                obj.fields.zipCode.layout.md === 3 ||
                obj.fields.zipCode.layout.md === 4 ||
                obj.fields.zipCode.layout.md === 5 ||
                obj.fields.zipCode.layout.md === 6 ||
                obj.fields.zipCode.layout.md === 7 ||
                obj.fields.zipCode.layout.md === 8 ||
                obj.fields.zipCode.layout.md === 9 ||
                obj.fields.zipCode.layout.md === 10 ||
                obj.fields.zipCode.layout.md === 11 ||
                obj.fields.zipCode.layout.md === 12) &&
              (typeof obj.fields.zipCode.layout.lg === 'undefined' ||
                obj.fields.zipCode.layout.lg === false ||
                obj.fields.zipCode.layout.lg === true ||
                obj.fields.zipCode.layout.lg === 'auto' ||
                obj.fields.zipCode.layout.lg === 1 ||
                obj.fields.zipCode.layout.lg === 2 ||
                obj.fields.zipCode.layout.lg === 3 ||
                obj.fields.zipCode.layout.lg === 4 ||
                obj.fields.zipCode.layout.lg === 5 ||
                obj.fields.zipCode.layout.lg === 6 ||
                obj.fields.zipCode.layout.lg === 7 ||
                obj.fields.zipCode.layout.lg === 8 ||
                obj.fields.zipCode.layout.lg === 9 ||
                obj.fields.zipCode.layout.lg === 10 ||
                obj.fields.zipCode.layout.lg === 11 ||
                obj.fields.zipCode.layout.lg === 12) &&
              (typeof obj.fields.zipCode.layout.xl === 'undefined' ||
                obj.fields.zipCode.layout.xl === false ||
                obj.fields.zipCode.layout.xl === true ||
                obj.fields.zipCode.layout.xl === 'auto' ||
                obj.fields.zipCode.layout.xl === 1 ||
                obj.fields.zipCode.layout.xl === 2 ||
                obj.fields.zipCode.layout.xl === 3 ||
                obj.fields.zipCode.layout.xl === 4 ||
                obj.fields.zipCode.layout.xl === 5 ||
                obj.fields.zipCode.layout.xl === 6 ||
                obj.fields.zipCode.layout.xl === 7 ||
                obj.fields.zipCode.layout.xl === 8 ||
                obj.fields.zipCode.layout.xl === 9 ||
                obj.fields.zipCode.layout.xl === 10 ||
                obj.fields.zipCode.layout.xl === 11 ||
                obj.fields.zipCode.layout.xl === 12))))) &&
      ((obj.productAvailabilityServiceFields !== null &&
        typeof obj.productAvailabilityServiceFields === 'object') ||
        typeof obj.productAvailabilityServiceFields === 'function') &&
      (typeof obj.productAvailabilityServiceFields.zipCode === 'undefined' ||
        (((obj.productAvailabilityServiceFields.zipCode !== null &&
          typeof obj.productAvailabilityServiceFields.zipCode === 'object') ||
          typeof obj.productAvailabilityServiceFields.zipCode === 'function') &&
          (typeof obj.productAvailabilityServiceFields.zipCode.label ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.zipCode.label ===
              'string') &&
          (typeof obj.productAvailabilityServiceFields.zipCode.errorMessage ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.zipCode.errorMessage ===
              'string') &&
          (typeof obj.productAvailabilityServiceFields.zipCode.placeholder ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.zipCode.placeholder ===
              'string') &&
          (typeof obj.productAvailabilityServiceFields.zipCode.required ===
            'undefined' ||
            obj.productAvailabilityServiceFields.zipCode.required === false ||
            obj.productAvailabilityServiceFields.zipCode.required === true) &&
          (typeof obj.productAvailabilityServiceFields.zipCode.disabled ===
            'undefined' ||
            obj.productAvailabilityServiceFields.zipCode.disabled === false ||
            obj.productAvailabilityServiceFields.zipCode.disabled === true) &&
          (typeof obj.productAvailabilityServiceFields.zipCode.defaultValue ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.zipCode.defaultValue ===
              'string')) ||
        (((obj.productAvailabilityServiceFields.zipCode !== null &&
          typeof obj.productAvailabilityServiceFields.zipCode === 'object') ||
          typeof obj.productAvailabilityServiceFields.zipCode === 'function') &&
          (typeof obj.productAvailabilityServiceFields.zipCode.layout ===
            'undefined' ||
            (((obj.productAvailabilityServiceFields.zipCode.layout !== null &&
              typeof obj.productAvailabilityServiceFields.zipCode.layout ===
                'object') ||
              typeof obj.productAvailabilityServiceFields.zipCode.layout ===
                'function') &&
              (typeof obj.productAvailabilityServiceFields.zipCode.layout.xs ===
                'undefined' ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs ===
                  false ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs ===
                  true ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs ===
                  'auto' ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 1 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 2 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 3 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 4 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 5 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 6 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 7 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 8 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 9 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 10 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs === 11 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xs ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.zipCode.layout.sm ===
                'undefined' ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm ===
                  false ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm ===
                  true ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm ===
                  'auto' ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 1 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 2 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 3 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 4 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 5 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 6 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 7 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 8 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 9 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 10 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm === 11 ||
                obj.productAvailabilityServiceFields.zipCode.layout.sm ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.zipCode.layout.md ===
                'undefined' ||
                obj.productAvailabilityServiceFields.zipCode.layout.md ===
                  false ||
                obj.productAvailabilityServiceFields.zipCode.layout.md ===
                  true ||
                obj.productAvailabilityServiceFields.zipCode.layout.md ===
                  'auto' ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 1 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 2 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 3 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 4 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 5 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 6 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 7 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 8 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 9 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 10 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md === 11 ||
                obj.productAvailabilityServiceFields.zipCode.layout.md ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.zipCode.layout.lg ===
                'undefined' ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg ===
                  false ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg ===
                  true ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg ===
                  'auto' ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 1 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 2 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 3 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 4 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 5 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 6 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 7 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 8 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 9 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 10 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg === 11 ||
                obj.productAvailabilityServiceFields.zipCode.layout.lg ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.zipCode.layout.xl ===
                'undefined' ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl ===
                  false ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl ===
                  true ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl ===
                  'auto' ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 1 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 2 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 3 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 4 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 5 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 6 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 7 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 8 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 9 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 10 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl === 11 ||
                obj.productAvailabilityServiceFields.zipCode.layout.xl ===
                  12))))) &&
      (typeof obj.productAvailabilityServiceFields.streetName === 'undefined' ||
        (((obj.productAvailabilityServiceFields.streetName !== null &&
          typeof obj.productAvailabilityServiceFields.streetName ===
            'object') ||
          typeof obj.productAvailabilityServiceFields.streetName ===
            'function') &&
          (typeof obj.productAvailabilityServiceFields.streetName.label ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetName.label ===
              'string') &&
          (typeof obj.productAvailabilityServiceFields.streetName
            .errorMessage === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetName
              .errorMessage === 'string') &&
          (typeof obj.productAvailabilityServiceFields.streetName
            .placeholder === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetName
              .placeholder === 'string') &&
          (typeof obj.productAvailabilityServiceFields.streetName.required ===
            'undefined' ||
            obj.productAvailabilityServiceFields.streetName.required ===
              false ||
            obj.productAvailabilityServiceFields.streetName.required ===
              true) &&
          (typeof obj.productAvailabilityServiceFields.streetName.disabled ===
            'undefined' ||
            obj.productAvailabilityServiceFields.streetName.disabled ===
              false ||
            obj.productAvailabilityServiceFields.streetName.disabled ===
              true) &&
          (typeof obj.productAvailabilityServiceFields.streetName
            .defaultValue === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetName
              .defaultValue === 'string')) ||
        (((obj.productAvailabilityServiceFields.streetName !== null &&
          typeof obj.productAvailabilityServiceFields.streetName ===
            'object') ||
          typeof obj.productAvailabilityServiceFields.streetName ===
            'function') &&
          (typeof obj.productAvailabilityServiceFields.streetName.layout ===
            'undefined' ||
            (((obj.productAvailabilityServiceFields.streetName.layout !==
              null &&
              typeof obj.productAvailabilityServiceFields.streetName.layout ===
                'object') ||
              typeof obj.productAvailabilityServiceFields.streetName.layout ===
                'function') &&
              (typeof obj.productAvailabilityServiceFields.streetName.layout
                .xs === 'undefined' ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  false ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  true ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  1 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  2 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  3 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  4 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  5 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  6 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  7 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  8 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  9 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  10 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  11 ||
                obj.productAvailabilityServiceFields.streetName.layout.xs ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetName.layout
                .sm === 'undefined' ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  false ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  true ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  1 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  2 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  3 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  4 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  5 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  6 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  7 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  8 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  9 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  10 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  11 ||
                obj.productAvailabilityServiceFields.streetName.layout.sm ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetName.layout
                .md === 'undefined' ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  false ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  true ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  1 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  2 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  3 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  4 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  5 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  6 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  7 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  8 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  9 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  10 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  11 ||
                obj.productAvailabilityServiceFields.streetName.layout.md ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetName.layout
                .lg === 'undefined' ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  false ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  true ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  1 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  2 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  3 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  4 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  5 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  6 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  7 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  8 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  9 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  10 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  11 ||
                obj.productAvailabilityServiceFields.streetName.layout.lg ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetName.layout
                .xl === 'undefined' ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  false ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  true ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  1 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  2 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  3 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  4 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  5 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  6 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  7 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  8 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  9 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  10 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  11 ||
                obj.productAvailabilityServiceFields.streetName.layout.xl ===
                  12))))) &&
      (typeof obj.productAvailabilityServiceFields.streetNumber ===
        'undefined' ||
        (((obj.productAvailabilityServiceFields.streetNumber !== null &&
          typeof obj.productAvailabilityServiceFields.streetNumber ===
            'object') ||
          typeof obj.productAvailabilityServiceFields.streetNumber ===
            'function') &&
          (typeof obj.productAvailabilityServiceFields.streetNumber.label ===
            'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetNumber.label ===
              'string') &&
          (typeof obj.productAvailabilityServiceFields.streetNumber
            .errorMessage === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetNumber
              .errorMessage === 'string') &&
          (typeof obj.productAvailabilityServiceFields.streetNumber
            .placeholder === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetNumber
              .placeholder === 'string') &&
          (typeof obj.productAvailabilityServiceFields.streetNumber.required ===
            'undefined' ||
            obj.productAvailabilityServiceFields.streetNumber.required ===
              false ||
            obj.productAvailabilityServiceFields.streetNumber.required ===
              true) &&
          (typeof obj.productAvailabilityServiceFields.streetNumber.disabled ===
            'undefined' ||
            obj.productAvailabilityServiceFields.streetNumber.disabled ===
              false ||
            obj.productAvailabilityServiceFields.streetNumber.disabled ===
              true) &&
          (typeof obj.productAvailabilityServiceFields.streetNumber
            .defaultValue === 'undefined' ||
            typeof obj.productAvailabilityServiceFields.streetNumber
              .defaultValue === 'string')) ||
        (((obj.productAvailabilityServiceFields.streetNumber !== null &&
          typeof obj.productAvailabilityServiceFields.streetNumber ===
            'object') ||
          typeof obj.productAvailabilityServiceFields.streetNumber ===
            'function') &&
          (typeof obj.productAvailabilityServiceFields.streetNumber.layout ===
            'undefined' ||
            (((obj.productAvailabilityServiceFields.streetNumber.layout !==
              null &&
              typeof obj.productAvailabilityServiceFields.streetNumber
                .layout === 'object') ||
              typeof obj.productAvailabilityServiceFields.streetNumber
                .layout === 'function') &&
              (typeof obj.productAvailabilityServiceFields.streetNumber.layout
                .xs === 'undefined' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  false ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  true ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  1 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  2 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  3 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  4 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  5 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  6 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  7 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  8 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  9 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  10 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  11 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xs ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetNumber.layout
                .sm === 'undefined' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  false ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  true ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  1 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  2 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  3 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  4 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  5 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  6 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  7 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  8 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  9 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  10 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  11 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.sm ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetNumber.layout
                .md === 'undefined' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  false ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  true ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  1 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  2 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  3 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  4 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  5 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  6 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  7 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  8 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  9 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  10 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  11 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.md ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetNumber.layout
                .lg === 'undefined' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  false ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  true ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  1 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  2 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  3 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  4 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  5 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  6 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  7 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  8 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  9 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  10 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  11 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.lg ===
                  12) &&
              (typeof obj.productAvailabilityServiceFields.streetNumber.layout
                .xl === 'undefined' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  false ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  true ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  'auto' ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  1 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  2 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  3 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  4 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  5 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  6 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  7 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  8 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  9 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  10 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  11 ||
                obj.productAvailabilityServiceFields.streetNumber.layout.xl ===
                  12))))) &&
      (typeof obj.allowedPostalCodesCommaSeparated === 'undefined' ||
        obj.allowedPostalCodesCommaSeparated === null ||
        typeof obj.allowedPostalCodesCommaSeparated === 'string') &&
      typeof obj.countryCode === 'string' &&
      (typeof obj.availabilityMode === 'undefined' ||
        obj.availabilityMode === AvailabilityMode.PostalCode ||
        obj.availabilityMode ===
          AvailabilityMode.ProductAvailabilityService)) ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.autoGeneratedLogicsIds === 'undefined' ||
        (Array.isArray(obj.autoGeneratedLogicsIds) &&
          obj.autoGeneratedLogicsIds.every(
            (e: any) => typeof e === 'string'
          ))) &&
      (typeof obj.autoGeneratedStepIds === 'undefined' ||
        (Array.isArray(obj.autoGeneratedStepIds) &&
          obj.autoGeneratedStepIds.every((e: any) => typeof e === 'string'))))
  )
}

export function isInputCalculatorControlOptions(
  obj: any,
  _argumentName?: string
): obj is InputCalculatorControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.deviceUnit === 'undefined' ||
      typeof obj.deviceUnit === 'string') &&
    (typeof obj.deviceOptions === 'undefined' ||
      (Array.isArray(obj.deviceOptions) &&
        obj.deviceOptions.every(
          (e: any) =>
            ((e !== null && typeof e === 'object') ||
              typeof e === 'function') &&
            typeof e.value === 'string' &&
            (typeof e.factor === 'undefined' ||
              (((e.factor !== null && typeof e.factor === 'object') ||
                typeof e.factor === 'function') &&
                (typeof e.factor.preset === 'undefined' ||
                  e.factor.preset === FactorPreset.Gas ||
                  e.factor.preset === FactorPreset.Power ||
                  e.factor.preset === FactorPreset.WaterHeater ||
                  e.factor.preset === FactorPreset.Custom) &&
                (typeof e.factor.custom_mappings === 'undefined' ||
                  (Array.isArray(e.factor.custom_mappings) &&
                    e.factor.custom_mappings.every(
                      (e: any) =>
                        ((e !== null && typeof e === 'object') ||
                          typeof e === 'function') &&
                        typeof e.quantity === 'number' &&
                        (typeof e.unitaryDemand === 'undefined' ||
                          typeof e.unitaryDemand === 'number') &&
                        (typeof e.multiplicationFactor === 'undefined' ||
                          typeof e.multiplicationFactor === 'number')
                    )))))
        ))) &&
    (typeof obj.frequencyUnit === 'undefined' ||
      typeof obj.frequencyUnit === 'string') &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}

export function isNumberInputControlOptions<T>(
  obj: any,
  _argumentName?: string
): obj is NumberInputControlOptions<T> {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    ((((obj.fields.numberInput !== null &&
      typeof obj.fields.numberInput === 'object') ||
      typeof obj.fields.numberInput === 'function') &&
      (isEnumButtonControlData(obj.fields.numberInput.label) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.errorMessage
      ) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.placeholder
      ) as boolean) &&
      (typeof obj.fields.numberInput.required === 'undefined' ||
        obj.fields.numberInput.required === false ||
        obj.fields.numberInput.required === true) &&
      (typeof obj.fields.numberInput.disabled === 'undefined' ||
        obj.fields.numberInput.disabled === false ||
        obj.fields.numberInput.disabled === true) &&
      (isEnumButtonControlData(
        obj.fields.numberInput.defaultValue
      ) as boolean)) ||
      (((obj.fields.numberInput !== null &&
        typeof obj.fields.numberInput === 'object') ||
        typeof obj.fields.numberInput === 'function') &&
        (typeof obj.fields.numberInput.required === 'undefined' ||
          obj.fields.numberInput.required === false ||
          obj.fields.numberInput.required === true) &&
        (isEnumButtonControlData(
          obj.fields.numberInput.frequencyUnit
        ) as boolean) &&
        (typeof obj.fields.numberInput.unit === 'undefined' ||
          (((obj.fields.numberInput.unit !== null &&
            typeof obj.fields.numberInput.unit === 'object') ||
            typeof obj.fields.numberInput.unit === 'function') &&
            (typeof obj.fields.numberInput.unit.show === 'undefined' ||
              obj.fields.numberInput.unit.show === false ||
              obj.fields.numberInput.unit.show === true) &&
            (isEnumButtonControlData(
              obj.fields.numberInput.unit.label
            ) as boolean))) &&
        (typeof obj.fields.numberInput.format === 'undefined' ||
          (((obj.fields.numberInput.format !== null &&
            typeof obj.fields.numberInput.format === 'object') ||
            typeof obj.fields.numberInput.format === 'function') &&
            (typeof obj.fields.numberInput.format.show === 'undefined' ||
              obj.fields.numberInput.format.show === false ||
              obj.fields.numberInput.format.show === true) &&
            (typeof obj.fields.numberInput.format.validate === 'undefined' ||
              obj.fields.numberInput.format.validate === false ||
              obj.fields.numberInput.format.validate === true) &&
            (typeof obj.fields.numberInput.format.digitsBeforeDecimalPoint ===
              'undefined' ||
              typeof obj.fields.numberInput.format.digitsBeforeDecimalPoint ===
                'number') &&
            (typeof obj.fields.numberInput.format.decimalPlaces ===
              'undefined' ||
              typeof obj.fields.numberInput.format.decimalPlaces ===
                'number'))) &&
        (typeof obj.fields.numberInput.range === 'undefined' ||
          (((obj.fields.numberInput.range !== null &&
            typeof obj.fields.numberInput.range === 'object') ||
            typeof obj.fields.numberInput.range === 'function') &&
            (typeof obj.fields.numberInput.range.min === 'undefined' ||
              typeof obj.fields.numberInput.range.min === 'number') &&
            (typeof obj.fields.numberInput.range.max === 'undefined' ||
              typeof obj.fields.numberInput.range.max === 'number'))))) &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}

export function isAddressControlOptions(
  obj: any,
  _argumentName?: string
): obj is AddressControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.label === 'undefined' || typeof obj.label === 'string') &&
    (typeof obj.acceptSuggestedOnly === 'undefined' ||
      obj.acceptSuggestedOnly === false ||
      obj.acceptSuggestedOnly === true) &&
    (typeof obj.isBilling === 'undefined' ||
      obj.isBilling === false ||
      obj.isBilling === true) &&
    (typeof obj.isDelivery === 'undefined' ||
      obj.isDelivery === false ||
      obj.isDelivery === true) &&
    (typeof obj.labels === 'undefined' ||
      (Array.isArray(obj.labels) &&
        obj.labels.every((e: any) => typeof e === 'string'))) &&
    ((((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
      (typeof obj.fields.zipCity === 'undefined' ||
        (((obj.fields.zipCity !== null &&
          typeof obj.fields.zipCity === 'object') ||
          typeof obj.fields.zipCity === 'function') &&
          (typeof obj.fields.zipCity.label === 'undefined' ||
            typeof obj.fields.zipCity.label === 'string') &&
          (typeof obj.fields.zipCity.errorMessage === 'undefined' ||
            typeof obj.fields.zipCity.errorMessage === 'string') &&
          (typeof obj.fields.zipCity.placeholder === 'undefined' ||
            typeof obj.fields.zipCity.placeholder === 'string') &&
          (typeof obj.fields.zipCity.required === 'undefined' ||
            obj.fields.zipCity.required === false ||
            obj.fields.zipCity.required === true) &&
          (typeof obj.fields.zipCity.disabled === 'undefined' ||
            obj.fields.zipCity.disabled === false ||
            obj.fields.zipCity.disabled === true) &&
          (typeof obj.fields.zipCity.defaultValue === 'undefined' ||
            typeof obj.fields.zipCity.defaultValue === 'string')) ||
        (((obj.fields.zipCity !== null &&
          typeof obj.fields.zipCity === 'object') ||
          typeof obj.fields.zipCity === 'function') &&
          (typeof obj.fields.zipCity.layout === 'undefined' ||
            (((obj.fields.zipCity.layout !== null &&
              typeof obj.fields.zipCity.layout === 'object') ||
              typeof obj.fields.zipCity.layout === 'function') &&
              (typeof obj.fields.zipCity.layout.xs === 'undefined' ||
                obj.fields.zipCity.layout.xs === false ||
                obj.fields.zipCity.layout.xs === true ||
                obj.fields.zipCity.layout.xs === 'auto' ||
                obj.fields.zipCity.layout.xs === 1 ||
                obj.fields.zipCity.layout.xs === 2 ||
                obj.fields.zipCity.layout.xs === 3 ||
                obj.fields.zipCity.layout.xs === 4 ||
                obj.fields.zipCity.layout.xs === 5 ||
                obj.fields.zipCity.layout.xs === 6 ||
                obj.fields.zipCity.layout.xs === 7 ||
                obj.fields.zipCity.layout.xs === 8 ||
                obj.fields.zipCity.layout.xs === 9 ||
                obj.fields.zipCity.layout.xs === 10 ||
                obj.fields.zipCity.layout.xs === 11 ||
                obj.fields.zipCity.layout.xs === 12) &&
              (typeof obj.fields.zipCity.layout.sm === 'undefined' ||
                obj.fields.zipCity.layout.sm === false ||
                obj.fields.zipCity.layout.sm === true ||
                obj.fields.zipCity.layout.sm === 'auto' ||
                obj.fields.zipCity.layout.sm === 1 ||
                obj.fields.zipCity.layout.sm === 2 ||
                obj.fields.zipCity.layout.sm === 3 ||
                obj.fields.zipCity.layout.sm === 4 ||
                obj.fields.zipCity.layout.sm === 5 ||
                obj.fields.zipCity.layout.sm === 6 ||
                obj.fields.zipCity.layout.sm === 7 ||
                obj.fields.zipCity.layout.sm === 8 ||
                obj.fields.zipCity.layout.sm === 9 ||
                obj.fields.zipCity.layout.sm === 10 ||
                obj.fields.zipCity.layout.sm === 11 ||
                obj.fields.zipCity.layout.sm === 12) &&
              (typeof obj.fields.zipCity.layout.md === 'undefined' ||
                obj.fields.zipCity.layout.md === false ||
                obj.fields.zipCity.layout.md === true ||
                obj.fields.zipCity.layout.md === 'auto' ||
                obj.fields.zipCity.layout.md === 1 ||
                obj.fields.zipCity.layout.md === 2 ||
                obj.fields.zipCity.layout.md === 3 ||
                obj.fields.zipCity.layout.md === 4 ||
                obj.fields.zipCity.layout.md === 5 ||
                obj.fields.zipCity.layout.md === 6 ||
                obj.fields.zipCity.layout.md === 7 ||
                obj.fields.zipCity.layout.md === 8 ||
                obj.fields.zipCity.layout.md === 9 ||
                obj.fields.zipCity.layout.md === 10 ||
                obj.fields.zipCity.layout.md === 11 ||
                obj.fields.zipCity.layout.md === 12) &&
              (typeof obj.fields.zipCity.layout.lg === 'undefined' ||
                obj.fields.zipCity.layout.lg === false ||
                obj.fields.zipCity.layout.lg === true ||
                obj.fields.zipCity.layout.lg === 'auto' ||
                obj.fields.zipCity.layout.lg === 1 ||
                obj.fields.zipCity.layout.lg === 2 ||
                obj.fields.zipCity.layout.lg === 3 ||
                obj.fields.zipCity.layout.lg === 4 ||
                obj.fields.zipCity.layout.lg === 5 ||
                obj.fields.zipCity.layout.lg === 6 ||
                obj.fields.zipCity.layout.lg === 7 ||
                obj.fields.zipCity.layout.lg === 8 ||
                obj.fields.zipCity.layout.lg === 9 ||
                obj.fields.zipCity.layout.lg === 10 ||
                obj.fields.zipCity.layout.lg === 11 ||
                obj.fields.zipCity.layout.lg === 12) &&
              (typeof obj.fields.zipCity.layout.xl === 'undefined' ||
                obj.fields.zipCity.layout.xl === false ||
                obj.fields.zipCity.layout.xl === true ||
                obj.fields.zipCity.layout.xl === 'auto' ||
                obj.fields.zipCity.layout.xl === 1 ||
                obj.fields.zipCity.layout.xl === 2 ||
                obj.fields.zipCity.layout.xl === 3 ||
                obj.fields.zipCity.layout.xl === 4 ||
                obj.fields.zipCity.layout.xl === 5 ||
                obj.fields.zipCity.layout.xl === 6 ||
                obj.fields.zipCity.layout.xl === 7 ||
                obj.fields.zipCity.layout.xl === 8 ||
                obj.fields.zipCity.layout.xl === 9 ||
                obj.fields.zipCity.layout.xl === 10 ||
                obj.fields.zipCity.layout.xl === 11 ||
                obj.fields.zipCity.layout.xl === 12))))) &&
      (typeof obj.fields.streetName === 'undefined' ||
        (((obj.fields.streetName !== null &&
          typeof obj.fields.streetName === 'object') ||
          typeof obj.fields.streetName === 'function') &&
          (typeof obj.fields.streetName.label === 'undefined' ||
            typeof obj.fields.streetName.label === 'string') &&
          (typeof obj.fields.streetName.errorMessage === 'undefined' ||
            typeof obj.fields.streetName.errorMessage === 'string') &&
          (typeof obj.fields.streetName.placeholder === 'undefined' ||
            typeof obj.fields.streetName.placeholder === 'string') &&
          (typeof obj.fields.streetName.required === 'undefined' ||
            obj.fields.streetName.required === false ||
            obj.fields.streetName.required === true) &&
          (typeof obj.fields.streetName.disabled === 'undefined' ||
            obj.fields.streetName.disabled === false ||
            obj.fields.streetName.disabled === true) &&
          (typeof obj.fields.streetName.defaultValue === 'undefined' ||
            typeof obj.fields.streetName.defaultValue === 'string')) ||
        (((obj.fields.streetName !== null &&
          typeof obj.fields.streetName === 'object') ||
          typeof obj.fields.streetName === 'function') &&
          (typeof obj.fields.streetName.layout === 'undefined' ||
            (((obj.fields.streetName.layout !== null &&
              typeof obj.fields.streetName.layout === 'object') ||
              typeof obj.fields.streetName.layout === 'function') &&
              (typeof obj.fields.streetName.layout.xs === 'undefined' ||
                obj.fields.streetName.layout.xs === false ||
                obj.fields.streetName.layout.xs === true ||
                obj.fields.streetName.layout.xs === 'auto' ||
                obj.fields.streetName.layout.xs === 1 ||
                obj.fields.streetName.layout.xs === 2 ||
                obj.fields.streetName.layout.xs === 3 ||
                obj.fields.streetName.layout.xs === 4 ||
                obj.fields.streetName.layout.xs === 5 ||
                obj.fields.streetName.layout.xs === 6 ||
                obj.fields.streetName.layout.xs === 7 ||
                obj.fields.streetName.layout.xs === 8 ||
                obj.fields.streetName.layout.xs === 9 ||
                obj.fields.streetName.layout.xs === 10 ||
                obj.fields.streetName.layout.xs === 11 ||
                obj.fields.streetName.layout.xs === 12) &&
              (typeof obj.fields.streetName.layout.sm === 'undefined' ||
                obj.fields.streetName.layout.sm === false ||
                obj.fields.streetName.layout.sm === true ||
                obj.fields.streetName.layout.sm === 'auto' ||
                obj.fields.streetName.layout.sm === 1 ||
                obj.fields.streetName.layout.sm === 2 ||
                obj.fields.streetName.layout.sm === 3 ||
                obj.fields.streetName.layout.sm === 4 ||
                obj.fields.streetName.layout.sm === 5 ||
                obj.fields.streetName.layout.sm === 6 ||
                obj.fields.streetName.layout.sm === 7 ||
                obj.fields.streetName.layout.sm === 8 ||
                obj.fields.streetName.layout.sm === 9 ||
                obj.fields.streetName.layout.sm === 10 ||
                obj.fields.streetName.layout.sm === 11 ||
                obj.fields.streetName.layout.sm === 12) &&
              (typeof obj.fields.streetName.layout.md === 'undefined' ||
                obj.fields.streetName.layout.md === false ||
                obj.fields.streetName.layout.md === true ||
                obj.fields.streetName.layout.md === 'auto' ||
                obj.fields.streetName.layout.md === 1 ||
                obj.fields.streetName.layout.md === 2 ||
                obj.fields.streetName.layout.md === 3 ||
                obj.fields.streetName.layout.md === 4 ||
                obj.fields.streetName.layout.md === 5 ||
                obj.fields.streetName.layout.md === 6 ||
                obj.fields.streetName.layout.md === 7 ||
                obj.fields.streetName.layout.md === 8 ||
                obj.fields.streetName.layout.md === 9 ||
                obj.fields.streetName.layout.md === 10 ||
                obj.fields.streetName.layout.md === 11 ||
                obj.fields.streetName.layout.md === 12) &&
              (typeof obj.fields.streetName.layout.lg === 'undefined' ||
                obj.fields.streetName.layout.lg === false ||
                obj.fields.streetName.layout.lg === true ||
                obj.fields.streetName.layout.lg === 'auto' ||
                obj.fields.streetName.layout.lg === 1 ||
                obj.fields.streetName.layout.lg === 2 ||
                obj.fields.streetName.layout.lg === 3 ||
                obj.fields.streetName.layout.lg === 4 ||
                obj.fields.streetName.layout.lg === 5 ||
                obj.fields.streetName.layout.lg === 6 ||
                obj.fields.streetName.layout.lg === 7 ||
                obj.fields.streetName.layout.lg === 8 ||
                obj.fields.streetName.layout.lg === 9 ||
                obj.fields.streetName.layout.lg === 10 ||
                obj.fields.streetName.layout.lg === 11 ||
                obj.fields.streetName.layout.lg === 12) &&
              (typeof obj.fields.streetName.layout.xl === 'undefined' ||
                obj.fields.streetName.layout.xl === false ||
                obj.fields.streetName.layout.xl === true ||
                obj.fields.streetName.layout.xl === 'auto' ||
                obj.fields.streetName.layout.xl === 1 ||
                obj.fields.streetName.layout.xl === 2 ||
                obj.fields.streetName.layout.xl === 3 ||
                obj.fields.streetName.layout.xl === 4 ||
                obj.fields.streetName.layout.xl === 5 ||
                obj.fields.streetName.layout.xl === 6 ||
                obj.fields.streetName.layout.xl === 7 ||
                obj.fields.streetName.layout.xl === 8 ||
                obj.fields.streetName.layout.xl === 9 ||
                obj.fields.streetName.layout.xl === 10 ||
                obj.fields.streetName.layout.xl === 11 ||
                obj.fields.streetName.layout.xl === 12))))) &&
      (typeof obj.fields.houseNumber === 'undefined' ||
        (((obj.fields.houseNumber !== null &&
          typeof obj.fields.houseNumber === 'object') ||
          typeof obj.fields.houseNumber === 'function') &&
          (typeof obj.fields.houseNumber.label === 'undefined' ||
            typeof obj.fields.houseNumber.label === 'string') &&
          (typeof obj.fields.houseNumber.errorMessage === 'undefined' ||
            typeof obj.fields.houseNumber.errorMessage === 'string') &&
          (typeof obj.fields.houseNumber.placeholder === 'undefined' ||
            typeof obj.fields.houseNumber.placeholder === 'string') &&
          (typeof obj.fields.houseNumber.required === 'undefined' ||
            obj.fields.houseNumber.required === false ||
            obj.fields.houseNumber.required === true) &&
          (typeof obj.fields.houseNumber.disabled === 'undefined' ||
            obj.fields.houseNumber.disabled === false ||
            obj.fields.houseNumber.disabled === true) &&
          (typeof obj.fields.houseNumber.defaultValue === 'undefined' ||
            typeof obj.fields.houseNumber.defaultValue === 'string')) ||
        (((obj.fields.houseNumber !== null &&
          typeof obj.fields.houseNumber === 'object') ||
          typeof obj.fields.houseNumber === 'function') &&
          (typeof obj.fields.houseNumber.layout === 'undefined' ||
            (((obj.fields.houseNumber.layout !== null &&
              typeof obj.fields.houseNumber.layout === 'object') ||
              typeof obj.fields.houseNumber.layout === 'function') &&
              (typeof obj.fields.houseNumber.layout.xs === 'undefined' ||
                obj.fields.houseNumber.layout.xs === false ||
                obj.fields.houseNumber.layout.xs === true ||
                obj.fields.houseNumber.layout.xs === 'auto' ||
                obj.fields.houseNumber.layout.xs === 1 ||
                obj.fields.houseNumber.layout.xs === 2 ||
                obj.fields.houseNumber.layout.xs === 3 ||
                obj.fields.houseNumber.layout.xs === 4 ||
                obj.fields.houseNumber.layout.xs === 5 ||
                obj.fields.houseNumber.layout.xs === 6 ||
                obj.fields.houseNumber.layout.xs === 7 ||
                obj.fields.houseNumber.layout.xs === 8 ||
                obj.fields.houseNumber.layout.xs === 9 ||
                obj.fields.houseNumber.layout.xs === 10 ||
                obj.fields.houseNumber.layout.xs === 11 ||
                obj.fields.houseNumber.layout.xs === 12) &&
              (typeof obj.fields.houseNumber.layout.sm === 'undefined' ||
                obj.fields.houseNumber.layout.sm === false ||
                obj.fields.houseNumber.layout.sm === true ||
                obj.fields.houseNumber.layout.sm === 'auto' ||
                obj.fields.houseNumber.layout.sm === 1 ||
                obj.fields.houseNumber.layout.sm === 2 ||
                obj.fields.houseNumber.layout.sm === 3 ||
                obj.fields.houseNumber.layout.sm === 4 ||
                obj.fields.houseNumber.layout.sm === 5 ||
                obj.fields.houseNumber.layout.sm === 6 ||
                obj.fields.houseNumber.layout.sm === 7 ||
                obj.fields.houseNumber.layout.sm === 8 ||
                obj.fields.houseNumber.layout.sm === 9 ||
                obj.fields.houseNumber.layout.sm === 10 ||
                obj.fields.houseNumber.layout.sm === 11 ||
                obj.fields.houseNumber.layout.sm === 12) &&
              (typeof obj.fields.houseNumber.layout.md === 'undefined' ||
                obj.fields.houseNumber.layout.md === false ||
                obj.fields.houseNumber.layout.md === true ||
                obj.fields.houseNumber.layout.md === 'auto' ||
                obj.fields.houseNumber.layout.md === 1 ||
                obj.fields.houseNumber.layout.md === 2 ||
                obj.fields.houseNumber.layout.md === 3 ||
                obj.fields.houseNumber.layout.md === 4 ||
                obj.fields.houseNumber.layout.md === 5 ||
                obj.fields.houseNumber.layout.md === 6 ||
                obj.fields.houseNumber.layout.md === 7 ||
                obj.fields.houseNumber.layout.md === 8 ||
                obj.fields.houseNumber.layout.md === 9 ||
                obj.fields.houseNumber.layout.md === 10 ||
                obj.fields.houseNumber.layout.md === 11 ||
                obj.fields.houseNumber.layout.md === 12) &&
              (typeof obj.fields.houseNumber.layout.lg === 'undefined' ||
                obj.fields.houseNumber.layout.lg === false ||
                obj.fields.houseNumber.layout.lg === true ||
                obj.fields.houseNumber.layout.lg === 'auto' ||
                obj.fields.houseNumber.layout.lg === 1 ||
                obj.fields.houseNumber.layout.lg === 2 ||
                obj.fields.houseNumber.layout.lg === 3 ||
                obj.fields.houseNumber.layout.lg === 4 ||
                obj.fields.houseNumber.layout.lg === 5 ||
                obj.fields.houseNumber.layout.lg === 6 ||
                obj.fields.houseNumber.layout.lg === 7 ||
                obj.fields.houseNumber.layout.lg === 8 ||
                obj.fields.houseNumber.layout.lg === 9 ||
                obj.fields.houseNumber.layout.lg === 10 ||
                obj.fields.houseNumber.layout.lg === 11 ||
                obj.fields.houseNumber.layout.lg === 12) &&
              (typeof obj.fields.houseNumber.layout.xl === 'undefined' ||
                obj.fields.houseNumber.layout.xl === false ||
                obj.fields.houseNumber.layout.xl === true ||
                obj.fields.houseNumber.layout.xl === 'auto' ||
                obj.fields.houseNumber.layout.xl === 1 ||
                obj.fields.houseNumber.layout.xl === 2 ||
                obj.fields.houseNumber.layout.xl === 3 ||
                obj.fields.houseNumber.layout.xl === 4 ||
                obj.fields.houseNumber.layout.xl === 5 ||
                obj.fields.houseNumber.layout.xl === 6 ||
                obj.fields.houseNumber.layout.xl === 7 ||
                obj.fields.houseNumber.layout.xl === 8 ||
                obj.fields.houseNumber.layout.xl === 9 ||
                obj.fields.houseNumber.layout.xl === 10 ||
                obj.fields.houseNumber.layout.xl === 11 ||
                obj.fields.houseNumber.layout.xl === 12))))) &&
      (typeof obj.fields.extention === 'undefined' ||
        (((obj.fields.extention !== null &&
          typeof obj.fields.extention === 'object') ||
          typeof obj.fields.extention === 'function') &&
          (typeof obj.fields.extention.label === 'undefined' ||
            typeof obj.fields.extention.label === 'string') &&
          (typeof obj.fields.extention.errorMessage === 'undefined' ||
            typeof obj.fields.extention.errorMessage === 'string') &&
          (typeof obj.fields.extention.placeholder === 'undefined' ||
            typeof obj.fields.extention.placeholder === 'string') &&
          (typeof obj.fields.extention.required === 'undefined' ||
            obj.fields.extention.required === false ||
            obj.fields.extention.required === true) &&
          (typeof obj.fields.extention.disabled === 'undefined' ||
            obj.fields.extention.disabled === false ||
            obj.fields.extention.disabled === true) &&
          (typeof obj.fields.extention.defaultValue === 'undefined' ||
            typeof obj.fields.extention.defaultValue === 'string')) ||
        (((obj.fields.extention !== null &&
          typeof obj.fields.extention === 'object') ||
          typeof obj.fields.extention === 'function') &&
          (typeof obj.fields.extention.layout === 'undefined' ||
            (((obj.fields.extention.layout !== null &&
              typeof obj.fields.extention.layout === 'object') ||
              typeof obj.fields.extention.layout === 'function') &&
              (typeof obj.fields.extention.layout.xs === 'undefined' ||
                obj.fields.extention.layout.xs === false ||
                obj.fields.extention.layout.xs === true ||
                obj.fields.extention.layout.xs === 'auto' ||
                obj.fields.extention.layout.xs === 1 ||
                obj.fields.extention.layout.xs === 2 ||
                obj.fields.extention.layout.xs === 3 ||
                obj.fields.extention.layout.xs === 4 ||
                obj.fields.extention.layout.xs === 5 ||
                obj.fields.extention.layout.xs === 6 ||
                obj.fields.extention.layout.xs === 7 ||
                obj.fields.extention.layout.xs === 8 ||
                obj.fields.extention.layout.xs === 9 ||
                obj.fields.extention.layout.xs === 10 ||
                obj.fields.extention.layout.xs === 11 ||
                obj.fields.extention.layout.xs === 12) &&
              (typeof obj.fields.extention.layout.sm === 'undefined' ||
                obj.fields.extention.layout.sm === false ||
                obj.fields.extention.layout.sm === true ||
                obj.fields.extention.layout.sm === 'auto' ||
                obj.fields.extention.layout.sm === 1 ||
                obj.fields.extention.layout.sm === 2 ||
                obj.fields.extention.layout.sm === 3 ||
                obj.fields.extention.layout.sm === 4 ||
                obj.fields.extention.layout.sm === 5 ||
                obj.fields.extention.layout.sm === 6 ||
                obj.fields.extention.layout.sm === 7 ||
                obj.fields.extention.layout.sm === 8 ||
                obj.fields.extention.layout.sm === 9 ||
                obj.fields.extention.layout.sm === 10 ||
                obj.fields.extention.layout.sm === 11 ||
                obj.fields.extention.layout.sm === 12) &&
              (typeof obj.fields.extention.layout.md === 'undefined' ||
                obj.fields.extention.layout.md === false ||
                obj.fields.extention.layout.md === true ||
                obj.fields.extention.layout.md === 'auto' ||
                obj.fields.extention.layout.md === 1 ||
                obj.fields.extention.layout.md === 2 ||
                obj.fields.extention.layout.md === 3 ||
                obj.fields.extention.layout.md === 4 ||
                obj.fields.extention.layout.md === 5 ||
                obj.fields.extention.layout.md === 6 ||
                obj.fields.extention.layout.md === 7 ||
                obj.fields.extention.layout.md === 8 ||
                obj.fields.extention.layout.md === 9 ||
                obj.fields.extention.layout.md === 10 ||
                obj.fields.extention.layout.md === 11 ||
                obj.fields.extention.layout.md === 12) &&
              (typeof obj.fields.extention.layout.lg === 'undefined' ||
                obj.fields.extention.layout.lg === false ||
                obj.fields.extention.layout.lg === true ||
                obj.fields.extention.layout.lg === 'auto' ||
                obj.fields.extention.layout.lg === 1 ||
                obj.fields.extention.layout.lg === 2 ||
                obj.fields.extention.layout.lg === 3 ||
                obj.fields.extention.layout.lg === 4 ||
                obj.fields.extention.layout.lg === 5 ||
                obj.fields.extention.layout.lg === 6 ||
                obj.fields.extention.layout.lg === 7 ||
                obj.fields.extention.layout.lg === 8 ||
                obj.fields.extention.layout.lg === 9 ||
                obj.fields.extention.layout.lg === 10 ||
                obj.fields.extention.layout.lg === 11 ||
                obj.fields.extention.layout.lg === 12) &&
              (typeof obj.fields.extention.layout.xl === 'undefined' ||
                obj.fields.extention.layout.xl === false ||
                obj.fields.extention.layout.xl === true ||
                obj.fields.extention.layout.xl === 'auto' ||
                obj.fields.extention.layout.xl === 1 ||
                obj.fields.extention.layout.xl === 2 ||
                obj.fields.extention.layout.xl === 3 ||
                obj.fields.extention.layout.xl === 4 ||
                obj.fields.extention.layout.xl === 5 ||
                obj.fields.extention.layout.xl === 6 ||
                obj.fields.extention.layout.xl === 7 ||
                obj.fields.extention.layout.xl === 8 ||
                obj.fields.extention.layout.xl === 9 ||
                obj.fields.extention.layout.xl === 10 ||
                obj.fields.extention.layout.xl === 11 ||
                obj.fields.extention.layout.xl === 12)))))) ||
      (((obj.fields !== null && typeof obj.fields === 'object') ||
        typeof obj.fields === 'function') &&
        (typeof obj.fields.salutation === 'undefined' ||
          (((obj.fields.salutation !== null &&
            typeof obj.fields.salutation === 'object') ||
            typeof obj.fields.salutation === 'function') &&
            (typeof obj.fields.salutation.label === 'undefined' ||
              typeof obj.fields.salutation.label === 'string') &&
            (typeof obj.fields.salutation.errorMessage === 'undefined' ||
              typeof obj.fields.salutation.errorMessage === 'string') &&
            (typeof obj.fields.salutation.placeholder === 'undefined' ||
              typeof obj.fields.salutation.placeholder === 'string') &&
            (typeof obj.fields.salutation.required === 'undefined' ||
              obj.fields.salutation.required === false ||
              obj.fields.salutation.required === true) &&
            (typeof obj.fields.salutation.disabled === 'undefined' ||
              obj.fields.salutation.disabled === false ||
              obj.fields.salutation.disabled === true) &&
            (typeof obj.fields.salutation.defaultValue === 'undefined' ||
              typeof obj.fields.salutation.defaultValue === 'string')) ||
          (((obj.fields.salutation !== null &&
            typeof obj.fields.salutation === 'object') ||
            typeof obj.fields.salutation === 'function') &&
            (typeof obj.fields.salutation.genderType === 'undefined' ||
              obj.fields.salutation.genderType === 'GENDER3'))) &&
        (typeof obj.fields.title === 'undefined' ||
          (((obj.fields.title !== null &&
            typeof obj.fields.title === 'object') ||
            typeof obj.fields.title === 'function') &&
            (typeof obj.fields.title.label === 'undefined' ||
              typeof obj.fields.title.label === 'string') &&
            (typeof obj.fields.title.errorMessage === 'undefined' ||
              typeof obj.fields.title.errorMessage === 'string') &&
            (typeof obj.fields.title.placeholder === 'undefined' ||
              typeof obj.fields.title.placeholder === 'string') &&
            (typeof obj.fields.title.required === 'undefined' ||
              obj.fields.title.required === false ||
              obj.fields.title.required === true) &&
            (typeof obj.fields.title.disabled === 'undefined' ||
              obj.fields.title.disabled === false ||
              obj.fields.title.disabled === true) &&
            (typeof obj.fields.title.defaultValue === 'undefined' ||
              typeof obj.fields.title.defaultValue === 'string'))) &&
        (typeof obj.fields.firstName === 'undefined' ||
          (((obj.fields.firstName !== null &&
            typeof obj.fields.firstName === 'object') ||
            typeof obj.fields.firstName === 'function') &&
            (typeof obj.fields.firstName.label === 'undefined' ||
              typeof obj.fields.firstName.label === 'string') &&
            (typeof obj.fields.firstName.errorMessage === 'undefined' ||
              typeof obj.fields.firstName.errorMessage === 'string') &&
            (typeof obj.fields.firstName.placeholder === 'undefined' ||
              typeof obj.fields.firstName.placeholder === 'string') &&
            (typeof obj.fields.firstName.required === 'undefined' ||
              obj.fields.firstName.required === false ||
              obj.fields.firstName.required === true) &&
            (typeof obj.fields.firstName.disabled === 'undefined' ||
              obj.fields.firstName.disabled === false ||
              obj.fields.firstName.disabled === true) &&
            (typeof obj.fields.firstName.defaultValue === 'undefined' ||
              typeof obj.fields.firstName.defaultValue === 'string'))) &&
        (typeof obj.fields.lastName === 'undefined' ||
          (((obj.fields.lastName !== null &&
            typeof obj.fields.lastName === 'object') ||
            typeof obj.fields.lastName === 'function') &&
            (typeof obj.fields.lastName.label === 'undefined' ||
              typeof obj.fields.lastName.label === 'string') &&
            (typeof obj.fields.lastName.errorMessage === 'undefined' ||
              typeof obj.fields.lastName.errorMessage === 'string') &&
            (typeof obj.fields.lastName.placeholder === 'undefined' ||
              typeof obj.fields.lastName.placeholder === 'string') &&
            (typeof obj.fields.lastName.required === 'undefined' ||
              obj.fields.lastName.required === false ||
              obj.fields.lastName.required === true) &&
            (typeof obj.fields.lastName.disabled === 'undefined' ||
              obj.fields.lastName.disabled === false ||
              obj.fields.lastName.disabled === true) &&
            (typeof obj.fields.lastName.defaultValue === 'undefined' ||
              typeof obj.fields.lastName.defaultValue === 'string'))) &&
        (typeof obj.fields.birthDate === 'undefined' ||
          (((obj.fields.birthDate !== null &&
            typeof obj.fields.birthDate === 'object') ||
            typeof obj.fields.birthDate === 'function') &&
            (typeof obj.fields.birthDate.label === 'undefined' ||
              typeof obj.fields.birthDate.label === 'string') &&
            (typeof obj.fields.birthDate.errorMessage === 'undefined' ||
              typeof obj.fields.birthDate.errorMessage === 'string') &&
            (typeof obj.fields.birthDate.placeholder === 'undefined' ||
              typeof obj.fields.birthDate.placeholder === 'string') &&
            (typeof obj.fields.birthDate.required === 'undefined' ||
              obj.fields.birthDate.required === false ||
              obj.fields.birthDate.required === true) &&
            (typeof obj.fields.birthDate.disabled === 'undefined' ||
              obj.fields.birthDate.disabled === false ||
              obj.fields.birthDate.disabled === true) &&
            (typeof obj.fields.birthDate.defaultValue === 'undefined' ||
              obj.fields.birthDate.defaultValue instanceof Date))) &&
        (typeof obj.fields.email === 'undefined' ||
          (((obj.fields.email !== null &&
            typeof obj.fields.email === 'object') ||
            typeof obj.fields.email === 'function') &&
            (typeof obj.fields.email.label === 'undefined' ||
              typeof obj.fields.email.label === 'string') &&
            (typeof obj.fields.email.errorMessage === 'undefined' ||
              typeof obj.fields.email.errorMessage === 'string') &&
            (typeof obj.fields.email.placeholder === 'undefined' ||
              typeof obj.fields.email.placeholder === 'string') &&
            (typeof obj.fields.email.required === 'undefined' ||
              obj.fields.email.required === false ||
              obj.fields.email.required === true) &&
            (typeof obj.fields.email.disabled === 'undefined' ||
              obj.fields.email.disabled === false ||
              obj.fields.email.disabled === true) &&
            (typeof obj.fields.email.defaultValue === 'undefined' ||
              typeof obj.fields.email.defaultValue === 'string'))) &&
        (typeof obj.fields.telephone === 'undefined' ||
          (((obj.fields.telephone !== null &&
            typeof obj.fields.telephone === 'object') ||
            typeof obj.fields.telephone === 'function') &&
            (typeof obj.fields.telephone.label === 'undefined' ||
              typeof obj.fields.telephone.label === 'string') &&
            (typeof obj.fields.telephone.errorMessage === 'undefined' ||
              typeof obj.fields.telephone.errorMessage === 'string') &&
            (typeof obj.fields.telephone.placeholder === 'undefined' ||
              typeof obj.fields.telephone.placeholder === 'string') &&
            (typeof obj.fields.telephone.required === 'undefined' ||
              obj.fields.telephone.required === false ||
              obj.fields.telephone.required === true) &&
            (typeof obj.fields.telephone.disabled === 'undefined' ||
              obj.fields.telephone.disabled === false ||
              obj.fields.telephone.disabled === true) &&
            (typeof obj.fields.telephone.defaultValue === 'undefined' ||
              typeof obj.fields.telephone.defaultValue === 'string'))) &&
        (typeof obj.fields.customerType === 'undefined' ||
          (((obj.fields.customerType !== null &&
            typeof obj.fields.customerType === 'object') ||
            typeof obj.fields.customerType === 'function') &&
            (typeof obj.fields.customerType.label === 'undefined' ||
              typeof obj.fields.customerType.label === 'string') &&
            (typeof obj.fields.customerType.errorMessage === 'undefined' ||
              typeof obj.fields.customerType.errorMessage === 'string') &&
            (typeof obj.fields.customerType.placeholder === 'undefined' ||
              typeof obj.fields.customerType.placeholder === 'string') &&
            (typeof obj.fields.customerType.required === 'undefined' ||
              obj.fields.customerType.required === false ||
              obj.fields.customerType.required === true) &&
            (typeof obj.fields.customerType.disabled === 'undefined' ||
              obj.fields.customerType.disabled === false ||
              obj.fields.customerType.disabled === true) &&
            (typeof obj.fields.customerType.defaultValue === 'undefined' ||
              obj.fields.customerType.defaultValue === 'Private' ||
              obj.fields.customerType.defaultValue === 'Business')) ||
          (((obj.fields.customerType !== null &&
            typeof obj.fields.customerType === 'object') ||
            typeof obj.fields.customerType === 'function') &&
            (typeof obj.fields.customerType.options === 'undefined' ||
              (Array.isArray(obj.fields.customerType.options) &&
                obj.fields.customerType.options.every(
                  (e: any) =>
                    ((e !== null && typeof e === 'object') ||
                      typeof e === 'function') &&
                    (e.value === 'Private' || e.value === 'Business') &&
                    (typeof e.label === 'undefined' ||
                      typeof e.label === 'string')
                ))))) &&
        (typeof obj.fields.companyName === 'undefined' ||
          (((obj.fields.companyName !== null &&
            typeof obj.fields.companyName === 'object') ||
            typeof obj.fields.companyName === 'function') &&
            (typeof obj.fields.companyName.label === 'undefined' ||
              typeof obj.fields.companyName.label === 'string') &&
            (typeof obj.fields.companyName.errorMessage === 'undefined' ||
              typeof obj.fields.companyName.errorMessage === 'string') &&
            (typeof obj.fields.companyName.placeholder === 'undefined' ||
              typeof obj.fields.companyName.placeholder === 'string') &&
            (typeof obj.fields.companyName.required === 'undefined' ||
              obj.fields.companyName.required === false ||
              obj.fields.companyName.required === true) &&
            (typeof obj.fields.companyName.disabled === 'undefined' ||
              obj.fields.companyName.disabled === false ||
              obj.fields.companyName.disabled === true) &&
            (typeof obj.fields.companyName.defaultValue === 'undefined' ||
              typeof obj.fields.companyName.defaultValue === 'string')) ||
          (((obj.fields.companyName !== null &&
            typeof obj.fields.companyName === 'object') ||
            typeof obj.fields.companyName === 'function') &&
            (typeof obj.fields.companyName.showIf === 'undefined' ||
              (((obj.fields.companyName.showIf !== null &&
                typeof obj.fields.companyName.showIf === 'object') ||
                typeof obj.fields.companyName.showIf === 'function') &&
                obj.fields.companyName.showIf.customerType === 'Business')))) &&
        (typeof obj.fields.registryCourt === 'undefined' ||
          (((obj.fields.registryCourt !== null &&
            typeof obj.fields.registryCourt === 'object') ||
            typeof obj.fields.registryCourt === 'function') &&
            (typeof obj.fields.registryCourt.label === 'undefined' ||
              typeof obj.fields.registryCourt.label === 'string') &&
            (typeof obj.fields.registryCourt.errorMessage === 'undefined' ||
              typeof obj.fields.registryCourt.errorMessage === 'string') &&
            (typeof obj.fields.registryCourt.placeholder === 'undefined' ||
              typeof obj.fields.registryCourt.placeholder === 'string') &&
            (typeof obj.fields.registryCourt.required === 'undefined' ||
              obj.fields.registryCourt.required === false ||
              obj.fields.registryCourt.required === true) &&
            (typeof obj.fields.registryCourt.disabled === 'undefined' ||
              obj.fields.registryCourt.disabled === false ||
              obj.fields.registryCourt.disabled === true) &&
            (typeof obj.fields.registryCourt.defaultValue === 'undefined' ||
              typeof obj.fields.registryCourt.defaultValue === 'string')) ||
          (((obj.fields.registryCourt !== null &&
            typeof obj.fields.registryCourt === 'object') ||
            typeof obj.fields.registryCourt === 'function') &&
            (typeof obj.fields.registryCourt.showIf === 'undefined' ||
              (((obj.fields.registryCourt.showIf !== null &&
                typeof obj.fields.registryCourt.showIf === 'object') ||
                typeof obj.fields.registryCourt.showIf === 'function') &&
                obj.fields.registryCourt.showIf.customerType ===
                  'Business')))) &&
        (typeof obj.fields.registerNumber === 'undefined' ||
          (((obj.fields.registerNumber !== null &&
            typeof obj.fields.registerNumber === 'object') ||
            typeof obj.fields.registerNumber === 'function') &&
            (typeof obj.fields.registerNumber.label === 'undefined' ||
              typeof obj.fields.registerNumber.label === 'string') &&
            (typeof obj.fields.registerNumber.errorMessage === 'undefined' ||
              typeof obj.fields.registerNumber.errorMessage === 'string') &&
            (typeof obj.fields.registerNumber.placeholder === 'undefined' ||
              typeof obj.fields.registerNumber.placeholder === 'string') &&
            (typeof obj.fields.registerNumber.required === 'undefined' ||
              obj.fields.registerNumber.required === false ||
              obj.fields.registerNumber.required === true) &&
            (typeof obj.fields.registerNumber.disabled === 'undefined' ||
              obj.fields.registerNumber.disabled === false ||
              obj.fields.registerNumber.disabled === true) &&
            (typeof obj.fields.registerNumber.defaultValue === 'undefined' ||
              typeof obj.fields.registerNumber.defaultValue === 'string')) ||
          (((obj.fields.registerNumber !== null &&
            typeof obj.fields.registerNumber === 'object') ||
            typeof obj.fields.registerNumber === 'function') &&
            (typeof obj.fields.registerNumber.showIf === 'undefined' ||
              (((obj.fields.registerNumber.showIf !== null &&
                typeof obj.fields.registerNumber.showIf === 'object') ||
                typeof obj.fields.registerNumber.showIf === 'function') &&
                obj.fields.registerNumber.showIf.customerType ===
                  'Business')))))) &&
    (typeof obj.countryAddressSettings === 'undefined' ||
      (((obj.countryAddressSettings !== null &&
        typeof obj.countryAddressSettings === 'object') ||
        typeof obj.countryAddressSettings === 'function') &&
        (typeof obj.countryAddressSettings.countryCode === 'undefined' ||
          typeof obj.countryAddressSettings.countryCode === 'string') &&
        (typeof obj.countryAddressSettings.enableAutoComplete === 'undefined' ||
          obj.countryAddressSettings.enableAutoComplete === false ||
          obj.countryAddressSettings.enableAutoComplete === true) &&
        (typeof obj.countryAddressSettings.enableFreeText === 'undefined' ||
          obj.countryAddressSettings.enableFreeText === false ||
          obj.countryAddressSettings.enableFreeText === true)))
  )
}

export function isMeterReadingControlOptions(
  obj: any,
  _argumentName?: string
): obj is MeterReadingControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    ((obj.fields !== null && typeof obj.fields === 'object') ||
      typeof obj.fields === 'function') &&
    (typeof obj.fields.maloId === 'undefined' ||
      (((obj.fields.maloId !== null && typeof obj.fields.maloId === 'object') ||
        typeof obj.fields.maloId === 'function') &&
        (isEnumButtonControlData(obj.fields.maloId.label) as boolean) &&
        (isEnumButtonControlData(obj.fields.maloId.errorMessage) as boolean) &&
        (isEnumButtonControlData(obj.fields.maloId.placeholder) as boolean) &&
        (typeof obj.fields.maloId.required === 'undefined' ||
          obj.fields.maloId.required === false ||
          obj.fields.maloId.required === true) &&
        (typeof obj.fields.maloId.disabled === 'undefined' ||
          obj.fields.maloId.disabled === false ||
          obj.fields.maloId.disabled === true) &&
        (isEnumButtonControlData(
          obj.fields.maloId.defaultValue
        ) as boolean))) &&
    (typeof obj.fields.meterId === 'undefined' ||
      (((obj.fields.meterId !== null &&
        typeof obj.fields.meterId === 'object') ||
        typeof obj.fields.meterId === 'function') &&
        (isEnumButtonControlData(obj.fields.meterId.label) as boolean) &&
        (isEnumButtonControlData(obj.fields.meterId.errorMessage) as boolean) &&
        (isEnumButtonControlData(obj.fields.meterId.placeholder) as boolean) &&
        (typeof obj.fields.meterId.required === 'undefined' ||
          obj.fields.meterId.required === false ||
          obj.fields.meterId.required === true) &&
        (typeof obj.fields.meterId.disabled === 'undefined' ||
          obj.fields.meterId.disabled === false ||
          obj.fields.meterId.disabled === true) &&
        (isEnumButtonControlData(
          obj.fields.meterId.defaultValue
        ) as boolean))) &&
    (typeof obj.fields.meterType === 'undefined' ||
      (((obj.fields.meterType !== null &&
        typeof obj.fields.meterType === 'object') ||
        typeof obj.fields.meterType === 'function') &&
        (isEnumButtonControlData(obj.fields.meterType.label) as boolean) &&
        (isEnumButtonControlData(
          obj.fields.meterType.errorMessage
        ) as boolean) &&
        (isEnumButtonControlData(
          obj.fields.meterType.placeholder
        ) as boolean) &&
        (typeof obj.fields.meterType.required === 'undefined' ||
          obj.fields.meterType.required === false ||
          obj.fields.meterType.required === true) &&
        (typeof obj.fields.meterType.disabled === 'undefined' ||
          obj.fields.meterType.disabled === false ||
          obj.fields.meterType.disabled === true) &&
        (isEnumButtonControlData(
          obj.fields.meterType.defaultValue
        ) as boolean)) ||
      (((obj.fields.meterType !== null &&
        typeof obj.fields.meterType === 'object') ||
        typeof obj.fields.meterType === 'function') &&
        (typeof obj.fields.meterType.showOneDirectional === 'undefined' ||
          obj.fields.meterType.showOneDirectional === false ||
          obj.fields.meterType.showOneDirectional === true) &&
        (typeof obj.fields.meterType.showTwoDirectional === 'undefined' ||
          obj.fields.meterType.showTwoDirectional === false ||
          obj.fields.meterType.showTwoDirectional === true) &&
        (typeof obj.fields.meterType.showBiDirectional === 'undefined' ||
          obj.fields.meterType.showBiDirectional === false ||
          obj.fields.meterType.showBiDirectional === true) &&
        (isEnumButtonControlData(obj.fields.meterType.value) as boolean))) &&
    (typeof obj.fields.readBy === 'undefined' ||
      (((obj.fields.readBy !== null && typeof obj.fields.readBy === 'object') ||
        typeof obj.fields.readBy === 'function') &&
        (isEnumButtonControlData(obj.fields.readBy.label) as boolean) &&
        (isEnumButtonControlData(obj.fields.readBy.errorMessage) as boolean) &&
        (isEnumButtonControlData(obj.fields.readBy.placeholder) as boolean) &&
        (typeof obj.fields.readBy.required === 'undefined' ||
          obj.fields.readBy.required === false ||
          obj.fields.readBy.required === true) &&
        (typeof obj.fields.readBy.disabled === 'undefined' ||
          obj.fields.readBy.disabled === false ||
          obj.fields.readBy.disabled === true) &&
        (isEnumButtonControlData(
          obj.fields.readBy.defaultValue
        ) as boolean))) &&
    (typeof obj.fields.readingDate === 'undefined' ||
      (((obj.fields.readingDate !== null &&
        typeof obj.fields.readingDate === 'object') ||
        typeof obj.fields.readingDate === 'function') &&
        (isEnumButtonControlData(obj.fields.readingDate.label) as boolean) &&
        (isEnumButtonControlData(
          obj.fields.readingDate.errorMessage
        ) as boolean) &&
        (isEnumButtonControlData(
          obj.fields.readingDate.placeholder
        ) as boolean) &&
        (typeof obj.fields.readingDate.required === 'undefined' ||
          obj.fields.readingDate.required === false ||
          obj.fields.readingDate.required === true) &&
        (typeof obj.fields.readingDate.disabled === 'undefined' ||
          obj.fields.readingDate.disabled === false ||
          obj.fields.readingDate.disabled === true) &&
        (typeof obj.fields.readingDate.defaultValue === 'undefined' ||
          obj.fields.readingDate.defaultValue instanceof Date))) &&
    ((((obj.fields.readingValue !== null &&
      typeof obj.fields.readingValue === 'object') ||
      typeof obj.fields.readingValue === 'function') &&
      (isEnumButtonControlData(obj.fields.readingValue.label) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.readingValue.errorMessage
      ) as boolean) &&
      (isEnumButtonControlData(
        obj.fields.readingValue.placeholder
      ) as boolean) &&
      (typeof obj.fields.readingValue.required === 'undefined' ||
        obj.fields.readingValue.required === false ||
        obj.fields.readingValue.required === true) &&
      (typeof obj.fields.readingValue.disabled === 'undefined' ||
        obj.fields.readingValue.disabled === false ||
        obj.fields.readingValue.disabled === true) &&
      (isEnumButtonControlData(
        obj.fields.readingValue.defaultValue
      ) as boolean)) ||
      (((obj.fields.readingValue !== null &&
        typeof obj.fields.readingValue === 'object') ||
        typeof obj.fields.readingValue === 'function') &&
        (typeof obj.fields.readingValue.required === 'undefined' ||
          obj.fields.readingValue.required === false ||
          obj.fields.readingValue.required === true) &&
        (isEnumButtonControlData(
          obj.fields.readingValue.frequencyUnit
        ) as boolean) &&
        (typeof obj.fields.readingValue.unit === 'undefined' ||
          (((obj.fields.readingValue.unit !== null &&
            typeof obj.fields.readingValue.unit === 'object') ||
            typeof obj.fields.readingValue.unit === 'function') &&
            (typeof obj.fields.readingValue.unit.show === 'undefined' ||
              obj.fields.readingValue.unit.show === false ||
              obj.fields.readingValue.unit.show === true) &&
            (isEnumButtonControlData(
              obj.fields.readingValue.unit.label
            ) as boolean))) &&
        (typeof obj.fields.readingValue.format === 'undefined' ||
          (((obj.fields.readingValue.format !== null &&
            typeof obj.fields.readingValue.format === 'object') ||
            typeof obj.fields.readingValue.format === 'function') &&
            (typeof obj.fields.readingValue.format.show === 'undefined' ||
              obj.fields.readingValue.format.show === false ||
              obj.fields.readingValue.format.show === true) &&
            (typeof obj.fields.readingValue.format.validate === 'undefined' ||
              obj.fields.readingValue.format.validate === false ||
              obj.fields.readingValue.format.validate === true) &&
            (typeof obj.fields.readingValue.format.digitsBeforeDecimalPoint ===
              'undefined' ||
              typeof obj.fields.readingValue.format.digitsBeforeDecimalPoint ===
                'number') &&
            (typeof obj.fields.readingValue.format.decimalPlaces ===
              'undefined' ||
              typeof obj.fields.readingValue.format.decimalPlaces ===
                'number'))) &&
        (typeof obj.fields.readingValue.range === 'undefined' ||
          (((obj.fields.readingValue.range !== null &&
            typeof obj.fields.readingValue.range === 'object') ||
            typeof obj.fields.readingValue.range === 'function') &&
            (typeof obj.fields.readingValue.range.min === 'undefined' ||
              typeof obj.fields.readingValue.range.min === 'number') &&
            (typeof obj.fields.readingValue.range.max === 'undefined' ||
              typeof obj.fields.readingValue.range.max === 'number'))))) &&
    (typeof obj.fields.reason === 'undefined' ||
      (((obj.fields.reason !== null && typeof obj.fields.reason === 'object') ||
        typeof obj.fields.reason === 'function') &&
        (isEnumButtonControlData(obj.fields.reason.label) as boolean) &&
        (isEnumButtonControlData(obj.fields.reason.errorMessage) as boolean) &&
        (isEnumButtonControlData(obj.fields.reason.placeholder) as boolean) &&
        (typeof obj.fields.reason.required === 'undefined' ||
          obj.fields.reason.required === false ||
          obj.fields.reason.required === true) &&
        (typeof obj.fields.reason.disabled === 'undefined' ||
          obj.fields.reason.disabled === false ||
          obj.fields.reason.disabled === true) &&
        (isEnumButtonControlData(
          obj.fields.reason.defaultValue
        ) as boolean))) &&
    (typeof obj.required === 'undefined' ||
      obj.required === false ||
      obj.required === true)
  )
}

export function isAvailabilityCheckControlData(
  obj: any,
  _argumentName?: string
): obj is AvailabilityCheckControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.zipCode === 'undefined' || typeof obj.zipCode === 'string') &&
      (typeof obj.city === 'undefined' || typeof obj.city === 'string') &&
      typeof obj.countryCode === 'string' &&
      (typeof obj.streetName === 'undefined' ||
        typeof obj.streetName === 'string') &&
      (typeof obj.streetNumber === 'undefined' ||
        typeof obj.streetNumber === 'string'))
  )
}
