export type InputCalculatorOptionDevice = {
  readonly value: string
  readonly factor?: InputCalculatorFactor
  readonly id?: string
  readonly preSelect?: boolean
}

export type InputCalculatorFactor = {
  readonly preset?: FactorPreset
  readonly custom_mappings?: FactorPresetValues[]
}

export enum FactorPreset {
  Gas = 'gas',
  Power = 'power',
  WaterHeater = 'water_heater',
  Custom = 'custom'
}

export type FactorPresetValues = {
  readonly quantity: number
  readonly unitaryDemand?: number
  readonly multiplicationFactor?: number
}
