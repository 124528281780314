import { Paper } from '../'

import type { FileStatusNotificationProps } from './types'

export function FileStatusNotification({
  message,
  icon
}: FileStatusNotificationProps) {
  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20
      }}
    >
      {message}
      {icon}
    </Paper>
  )
}
