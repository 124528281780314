import { createContext, useContext } from 'react'

type OrganizationSettings = {
  settings?: { [name: string]: boolean }
}

const OrganizationSettingsContext = createContext<OrganizationSettings>(
  {} as OrganizationSettings
)

export const useOrganizationSettingsContext = () => {
  const context = useContext(OrganizationSettingsContext)

  if (!context) {
    throw new Error(
      'useOrganizationSettingsContext should only be used inside a <OrganizationSettingsContext.Provider>'
    )
  }

  return context
}

export const OrganizationSettingsContextProvider = ({
  children,
  settings
}: {
  settings?: OrganizationSettings['settings']
  children?: React.ReactNode
}) => {
  return (
    <OrganizationSettingsContext.Provider
      value={{
        settings
      }}
    >
      {children}
    </OrganizationSettingsContext.Provider>
  )
}

OrganizationSettingsContextProvider.displayName =
  'OrganizationSettingsContextProvider'
