import { makeStyles, createStyles } from '@material-ui/core'

import type { TextFieldProps } from './types'

export const useTextFieldStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: (props: TextFieldProps) =>
        `${props.customBackgroundColor} !important`,

      '&:hover, &:active, &:focus': {
        backgroundColor: (props: TextFieldProps) =>
          `${props.customBackgroundColor} !important`
      }
    }
  })
)
