import { Typography, makeStyles } from '@epilot/journey-elements'
import type {
  AddressSuggestionsSource,
  Address
} from '@epilot/journey-logic-commons'
import Info from '@epilot360/icons/react/Info'
import { useDebouncedCallback } from 'use-debounce'

import { useJourneyContext } from '../../../../utils'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { ListedAddressForm } from '../../AddressControl/components/ListedAddressForm'

type AdditionalAddressGetAgFormProps = {
  path: string
  label?: string
  injectedAddress?: Address
  data?: Partial<Address>
  blockPath: string
  id: string
}

export const AdditionalAddressGetAgForm = ({
  path,
  label,
  data,
  injectedAddress,
  blockPath,
  id
}: AdditionalAddressGetAgFormProps) => {
  const { updateContext } = useJourneyContext()
  const classes = useStyles()

  const handleDebouncedChange = useDebouncedCallback((values: Address) => {
    updateContext((value) => ({
      ...value,
      _productsAdditionalAddresses: {
        ...(value._productsAdditionalAddresses || {}),
        [blockPath]: values
      }
    }))
  }, 300)

  const { control, register, resetField, setValue } = useFormHandler<Address>({
    handleChange: (_path, value) => {
      if (!value || value.streetName === null) return
      handleDebouncedChange(value)
    },
    path,
    fields: {
      extension: {},
      houseNumber: {},
      streetName: {
        required: true
      },
      zipCity: {}
    },
    data,
    formProps: {
      defaultValues: {
        countryCode: 'DE',
        ...data
      }
    }
  })

  const listAddressFromProps = {
    control,
    register,
    resetField,
    setValue,
    fieldValues: {
      zipCode: injectedAddress?.zipCode,
      city: injectedAddress?.city,
      countryCode: 'DE',
      extension: '',
      houseNumber: '',
      streetName: ''
    },
    fields: {
      houseNumber: {},
      streetName: {
        required: true
      }
    },
    acceptSuggestedOnly: true,
    addressCountryCode: 'DE',
    addressFreeText: false,
    addressSuggestionsSource: 'getAgService' as AddressSuggestionsSource,
    disableAddressSuggestions: false,
    hasError: false,
    injectedData: null,
    path,
    setAddressDown: () => {
      return null
    }
  }

  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.label}>
          <Info className={classes.icon} variant="outlined" />
          <Typography color="textSecondary" variant="caption">
            {label}
          </Typography>
        </div>
      )}
      <ListedAddressForm id={id} {...listAddressFromProps} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '8px'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  icon: {
    width: '16px',
    height: '16px'
  }
}))
