import type { Theme } from '@epilot/journey-elements'
import { useTheme } from '@epilot/journey-elements'
import type { UiSchema } from '@epilot/journey-logic-commons'
import { getLocaleDateString } from '@epilot/journey-logic-commons'
import type { JsonSchema7 } from '@jsonforms/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { format, isDate, isValid, parseISO } from 'date-fns'

export function IsMobile() {
  const theme = useTheme<Theme>()

  return useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true
  })
}

export function IsMobileOrTablet() {
  const theme = useTheme<Theme>()

  return useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  })
}

export const getBase64 = (file: File) =>
  new Promise((resolve) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => {
      console.error('Error: ', error)
    }
  })

export const isAnyInObject = (
  hayStack: Record<string, unknown>,
  needle: string[]
): boolean => {
  const keys = Object.keys(hayStack)

  return keys.some((key) => needle.includes(key) && hayStack[key] !== undefined)
}

/**
 * Checks if a dependent fields value matches the required value
 * @param matchIf Object with keys as identifies and values as what value to match
 * @param fieldValues form fields key/values
 * @param condition OR => any value matches, else every value matches
 */
export const matchesValue = (
  matchIf: { [key: string]: unknown } | undefined,
  fieldValues: { [key: string]: unknown },
  condition = 'OR'
): boolean => {
  if (!matchIf) return false

  const check = (key: string) =>
    fieldValues[key] && fieldValues[key] === matchIf[key]

  const keys = Object.keys(matchIf)

  return condition === 'OR' ? keys.some(check) : keys.every(check)
}

export const isObject = (arg: unknown): arg is Record<string, unknown> => {
  return (
    typeof arg === 'object' &&
    !Array.isArray(arg) &&
    arg !== null &&
    !!Object.keys(arg)
  )
}

export const toDateString = (date: Date | string | undefined): string => {
  if (date && date !== null) {
    const formatClient = getLocaleDateString(navigator.language)

    if (typeof date === 'string') {
      const dateObject = parseISO(date)

      if (isValid(dateObject)) {
        return format(dateObject, formatClient)
      } else {
        console.error(
          'toDateString -> detected incorrect ISO date value. Current value is',
          { date }
        )
      }
    } else if (isDate(date)) {
      return format(date, formatClient)
    }

    console.error(
      'toDateString -> detected incorrect date value. Current value is',
      { date }
    )
  }

  return ''
}

/**
 * Gets availabile string values by first attempting to check for schema.enum property,
 * and then falls back to find those values in the uischema options
 */
export const getEnumOptions = (
  schema: JsonSchema7,
  uischema: UiSchema
): string[] => {
  if (Array.isArray(uischema?.options?.options)) {
    const options = uischema?.options?.options.map((option) =>
      option === undefined || option === null ? '' : option
    )

    if (options && options.every((option) => typeof option === 'string')) {
      return options
    }
  }

  if (schema?.enum) {
    return schema.enum
  }

  return []
}
