import type { Step } from '@epilot/journey-logic-commons'

/**
 * a function that checkes the journey config if it includes steps with the same stepId
 * @param steps the journey steps that will be checked for duplicate stepIds
 * @returns an array of duplicate stepIds
 */
export function findDuplicateStepId(steps: Step[]) {
  const stepIds = steps.map((step) => step.stepId)

  return stepIds.filter((stepId, index) => stepIds.indexOf(stepId) !== index)
}
