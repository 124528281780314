import {
  schemaTypeIs,
  rankWith,
  uiTypeIs,
  or,
  and,
  isEnumControl,
  isStringControl,
  optionIs
} from '@jsonforms/core'

import { CONTROL_NAME } from './types'

export const ActionBarControlTester = rankWith(5, uiTypeIs('ActionBarControl'))
export const AddressControlTester = rankWith(
  5,
  and(schemaTypeIs('object'), uiTypeIs('AddressControl'))
)

export const AvailabilityCheckControlTester = rankWith(
  5,
  and(schemaTypeIs('object'), uiTypeIs(CONTROL_NAME.AVAILABILITY_CHECK))
)

export const BooleanTester = rankWith(
  5,
  or(schemaTypeIs('boolean'), and(isEnumControl, schemaTypeIs('boolean')))
)

export const ConfirmationMessageControlTester = rankWith(
  5,
  uiTypeIs('ConfirmationMessageControl')
)

export const ConsentsControlTester = rankWith(5, uiTypeIs('ConsentsControl'))

export const ContactControlTester = rankWith(
  5,
  and(schemaTypeIs('object'), uiTypeIs('PersonalInformationControl'))
)

export const CustomBlockControlTester = rankWith(
  5,
  uiTypeIs(CONTROL_NAME.CUSTOM_BLOCK_CONTROL)
)

export const DatePickerControlTester = rankWith(
  2,
  and(schemaTypeIs('object'), uiTypeIs('DatePickerControl'))
)

export const DigitalSignatureControlTester = rankWith(
  5,
  or(
    and(isStringControl, optionIs('digital_signature', true)),
    uiTypeIs('DigitalSignatureControl')
  )
)

export const EntityAttributeControlTester = rankWith(
  5,
  uiTypeIs(CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL)
)

export const EntityLookupControlTester = rankWith(
  5,
  uiTypeIs(CONTROL_NAME.ENTITY_LOOKUP_CONTROL)
)

export const EnumAutocompleteTester = rankWith(
  5,
  or(
    and(isEnumControl, optionIs('autocomplete', true)),
    and(uiTypeIs(CONTROL_NAME.ENUM_CONTROL), optionIs('autocomplete', true))
  )
)

export const EnumToggleTester = rankWith(
  5,
  or(
    and(isEnumControl, optionIs('toggle', true)),
    and(uiTypeIs(CONTROL_NAME.ENUM_CONTROL), optionIs('button', true))
  )
)

export const GroupLayoutTester = rankWith(2, uiTypeIs('GroupLayout'))

export const ImageControlTester = rankWith(2, uiTypeIs('ImageControl'))

export const InputCalculatorControlTester = rankWith(
  2,
  and(schemaTypeIs('object'), uiTypeIs(CONTROL_NAME.INPUT_CALCULATOR_CONTROL))
)
export const JourneyLauncherControlTester = rankWith(
  5,
  uiTypeIs('JourneyLauncherControl')
)

export const LabelRendererTester = rankWith(2, uiTypeIs('Label'))

export const MainContentCartLayoutTester = rankWith(
  2,
  uiTypeIs('MainContentCartLayout')
)

export const MainLinearLayoutTester = rankWith(2, uiTypeIs('MainLinearLayout'))

export const MeterReadingControlTester = rankWith(
  5,
  uiTypeIs(CONTROL_NAME.METER_READING_CONTROL)
)

export const MeterReadingDynamicControlTester = rankWith(
  5,
  uiTypeIs(CONTROL_NAME.METER_READING_DYNAMIC_CONTROL)
)

export const MultichoiceControlTester = rankWith(
  4,
  uiTypeIs('MultichoiceControl')
)

export const NumberInputControlTester = rankWith(
  2,
  and(schemaTypeIs('object'), uiTypeIs(CONTROL_NAME.NUMBER_INPUT_CONTROL))
)

export const PaymentControlTester = rankWith(
  6,
  or(
    and(schemaTypeIs('object'), optionIs('payment', true)),
    uiTypeIs('PaymentControl')
  )
)

export const PreviousProviderControlTester = rankWith(
  4,
  uiTypeIs('PreviousProviderControl')
)

export const ProductCategoryControlTester = rankWith(
  5,
  and(schemaTypeIs('array'), uiTypeIs('ProductCategoryControl'))
)

export const ProductSelectionControlTester = rankWith(
  4,
  uiTypeIs('ProductSelectionControl')
)

export const PvRoofPlannerControlTester = rankWith(
  4,
  and(uiTypeIs(CONTROL_NAME.PV_ROOF_PLANNER_CONTROL))
)

export const RadioTester = rankWith(
  5,
  or(
    and(isEnumControl, optionIs('radio', true)),
    and(uiTypeIs(CONTROL_NAME.ENUM_CONTROL), optionIs('radio', true))
  )
)
export const SecondaryActionBarControlTester = rankWith(
  4,
  and(uiTypeIs(CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL))
)

export const SelectTester = rankWith(
  4,
  or(isEnumControl, uiTypeIs(CONTROL_NAME.ENUM_CONTROL))
)

export const ShopCartControlTester = rankWith(4, uiTypeIs('ShopCartControl'))

export const StackSpacingLayoutTester = rankWith(
  2,
  uiTypeIs('StackSpacingLayout')
)
export const SummaryControlTester = rankWith(3, uiTypeIs('SummaryControl'))

export const PdfSummaryControlTester = rankWith(
  3,
  uiTypeIs('PdfSummaryControl')
)

export const TextFieldTester = rankWith(3, schemaTypeIs('string'))

export const UploadPanelControlTester = rankWith(
  4,
  uiTypeIs('UploadPanelControl')
)
