// the url of an image looks like this
// https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/44e532c6-ef0e-4e76-9a22-0a3af72438b6/Screenshot%25202023-08-07%2520at%252018.16.08.png
// orgId number has the prefix of amazonaws.com/ and followed by /

// the following regex extracts it
const orgIdRegex = /amazonaws.com\/(\d+)\//

export function getOptimizedImageURL(
  apiUrl: string,
  imageURL: string,
  fileId?: string,
  width?: string
) {
  const orgId = imageURL?.match(orgIdRegex)?.[1]

  return fileId && orgId
    ? getOptimizedFileIdURL(apiUrl, orgId, fileId, width)
    : imageURL
}

// used in journey-app-v3
export function getOptimizedFileIdURL(
  apiUrl: string,
  orgId: string,
  fileId: string,
  width?: string,
  height?: string
) {
  return `${apiUrl}/v1/files/public/${fileId}/preview?org_id=${orgId}${
    width ? '&w=' + width : ''
  }${height ? '&h=' + height : ''}`
}
