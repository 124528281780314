import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles } from '@epilot/journey-elements'

export const useCartItemStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    cartItem: {
      background: theme.palette?.grey[20],
      borderRadius: '4px',
      minHeight: '70px',
      padding: '8px 0px 8px 8px'
    },
    productTitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingTop: 6,
      [theme.breakpoints?.down?.('sm') || 'temp']: {
        maxWidth: '15ch'
      }
    },
    priceDescription: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    unitContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '0.5rem'
    },
    quantityContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    iconButton: {
      marginLeft: '4px'
    },
    icon: {
      fontSize: '16px'
    },
    stepperInputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    imageContainer: {
      marginTop: 10
    },
    priceContainer: {
      textAlign: 'end',
      paddingRight: 8
    },
    priceInnerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 2
    },
    openComponentsButton: {
      padding: 0,
      minWidth: 0,
      maxHeight: 24
    },
    discountInnerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    priceBeforeDiscount: {
      textDecoration: 'line-through',
      opacity: 0.5,
      fontWeight: theme.typography.fontWeightBold as never
    },
    discount: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold as never
    }
  })
)
