import type { DehydratedEntityRelation, Product, File } from '../types'

type ProductWithHydratedImages = Product & {
  product_images: File[]
}

export const areProductImagesHydrated = (
  product?: Product
): product is ProductWithHydratedImages => {
  const images = product?.product_images

  if (
    !product ||
    !images ||
    !Array.isArray(images) ||
    (images as DehydratedEntityRelation[]).length === 0
  )
    return false

  const hasFilename = 'filename' in images[0]
  const hasPublicUrl =
    'public_url' in images[0] || !!images[0].versions?.[0]?.public_url

  return hasFilename && hasPublicUrl
}
