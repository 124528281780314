import type { PopperProps } from '@material-ui/core'
import { Popper as MuiPopper } from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef, useEffect, useState } from 'react'

import { Icon } from '../Icon'
import { TextField } from '../TextField'

import { useAutocompleteStyles } from './styles'
import type { AutocompleteProps } from './types'

const DropdownPopper = ({ modifiers, ...props }: PopperProps) => (
  <MuiPopper
    {...props}
    modifiers={{
      ...modifiers,
      flip: { enabled: false },
      preventOverflow: { enabled: false }
    }}
  />
)

export const Autocomplete = forwardRef<
  ElementRef<typeof MuiAutocomplete>,
  PropsWithoutRef<AutocompleteProps>
>((props, ref) => {
  const {
    error,
    label,
    multiple = true,
    popupIcon = <ExpandMore color="secondary" />,
    placeholder,
    value,
    disableOpenOnFocus,
    id,
    required,
    variant = 'filled',
    flipPopperEnabled = false,
    customBackgroundColor,
    textfieldProps,
    ...rest
  } = props
  const { clearIconOverwrite, paper } = useAutocompleteStyles()
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (!disableOpenOnFocus) {
      setOpen(true)
    }
  }, [disableOpenOnFocus])

  const handleOpen = () => {
    if (inputValue.length) {
      setOpen(true)
    }
  }

  const handleInputChange: AutocompleteProps['onInputChange'] = (_, value) => {
    setInputValue(value)
    setOpen(!!value.length)
  }

  return (
    <MuiAutocomplete
      ChipProps={{ deleteIcon: <Icon name="close" /> }}
      PopperComponent={flipPopperEnabled ? MuiPopper : DropdownPopper}
      className={clearIconOverwrite}
      classes={{ paper: paper }}
      id={id}
      inputValue={inputValue}
      multiple={multiple}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      onOpen={disableOpenOnFocus ? handleOpen : undefined}
      open={disableOpenOnFocus ? open : undefined}
      popupIcon={popupIcon}
      ref={ref}
      renderInput={(params) => (
        <TextField
          {...params}
          customBackgroundColor={customBackgroundColor}
          error={!!error}
          helperText={error}
          label={
            typeof label === 'string' ? label + (required ? ' *' : '') : label
          }
          placeholder={placeholder}
          variant={variant}
          {...textfieldProps}
        />
      )}
      value={value}
      {...rest}
    />
  )
})

Autocomplete.displayName = 'Autocomplete'
