import { CircularProgress } from '@epilot/journey-elements'
import {
  DEFAULT_FILTER_VALUES_CONFIG_NAME,
  AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY,
  blockController,
  aggregateUniqueCategories
} from '@epilot/journey-logic-commons'
import type {
  ProductControlOptions,
  EpilotControlProps,
  Product
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useJourneyContext, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'
import { useSearchPrices } from '../../../utils/services/product-service'
import { MultichoiceButton } from '../MultichoiceControl/components/MultichoiceButton'

import { useProductCategoryControlStyles } from './styles'

const ProductCategoryControl = withQueryProvider<EpilotControlProps>(
  (props: EpilotControlProps) => {
    // Extract props
    const { data, uischema, path, handleChange } = props
    const { relatedProductSelection } = uischema.options || {}

    const stepBlockId = useStepBlockId(path)

    const { t } = useTranslation()

    const { context: _context } = useJourneyContext()

    const steps = _context.journey.steps
    const orgId = _context.journey.organizationId
    const publicToken = _context.journey.settings?.publicToken as string

    // Custom styles for the control
    const classes = useProductCategoryControlStyles()

    // Extract stepId and blockName from the relatedProductSelection
    const [stepId, blockName] = relatedProductSelection?.split('/') || []

    // Get the index of the step where the related product selection is configured
    const relatedProductSelectionStepIndex = steps.findIndex(
      (step) => step.stepId === stepId
    )

    // Get the related product selection block based on the step index and block name
    const relatedProductSelectionBlock = useMemo(() => {
      if (relatedProductSelectionStepIndex !== -1 && blockName) {
        return blockController.findBlock(
          {
            ...steps[relatedProductSelectionStepIndex],
            stepIndex: relatedProductSelectionStepIndex
          },
          { name: blockName }
        )
      }

      return
    }, [blockName, steps, relatedProductSelectionStepIndex])

    // Extract products from the related product selection block options
    const { products: journeyPrices = [] } =
      (relatedProductSelectionBlock?.uischema?.options as
        | ProductControlOptions
        | undefined) ?? {}

    // Extract the availability filter from the related product selection block options
    const availabilityFilter: string[] | undefined =
      relatedProductSelectionBlock?.uischema?.options?.[
        DEFAULT_FILTER_VALUES_CONFIG_NAME
      ]?.[AVAILABILITY_CHECK_FILTER_VALUE_PROPERTY]?.['availableProducts']

    // Fetch prices using a custom hook

    const { results, isLoading } = useSearchPrices({
      orgId,
      journeyPrices,
      crossSellProductIds: undefined,
      availabilityProductIds: availabilityFilter,
      publicToken
    })

    // Filter out products from the results (contains products + prices)
    const products = results.filter(
      (item): item is Product =>
        item.type === 'product' || item.type === 'service'
    )

    const orderByProductIds = journeyPrices.map((item) => item.productId)

    // Aggregate unique categories from the products
    const categories = aggregateUniqueCategories(products, orderByProductIds)

    const onChange = (value: string[]) => {
      handleChange(
        path,
        Array.isArray(value) && value.length > 0 ? value : undefined
      )
    }

    // Render loading indicator if still loading
    if (isLoading) {
      return (
        <div className={classes.panel}>
          <CircularProgress color="inherit" size={40} />
        </div>
      )
    }

    // Render the control with the MultichoiceButton component
    return (
      <>
        {categories.length ? (
          <div>
            <MultichoiceButton
              id={stepBlockId}
              label=""
              onChange={onChange}
              options={categories}
              uiType="button"
              value={data}
            />
          </div>
        ) : (
          <div className={classes.panel}>
            <div>
              <h2>
                {t(
                  'prduct_category.no_available_categories',
                  'No products categories are available'
                )}
              </h2>
            </div>
          </div>
        )}
      </>
    )
  }
)

// Wrap the ProductCategoryControl component with higher-order components
export default withJsonFormsControlProps(
  includeCommon({ component: ProductCategoryControl }) as never
)
