import { useTranslation } from 'react-i18next'

import EmptyIllustration from '../EmptyIllustration'

import { useEmptyLauncherStyles } from './styles'

export const EmptyJourneyLauncher = () => {
  const { t } = useTranslation()
  const classes = useEmptyLauncherStyles()

  return (
    <div className={classes.emptyContainer}>
      <EmptyIllustration className={classes.icon} hideCount />
      <div className={classes.textContainer}>
        {t('journeyLauncher.emptyState', 'No Journeys connected yet')}
      </div>
    </div>
  )
}
