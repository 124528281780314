import type { BlockDisplaySetting } from './context'

/**
 * a function that takes the journey blockDisplaySettings from context, then it will determine if the block is disabled or not
 * if a block is disabled, it will return the fields that will be disabled if exists or true, otherwise it will return false
 * @param blocksDisplaySettings as taken from context
 * @param currentStepIndex as taken from context
 * @param blockScope as taken from uischema
 * @returns returns false if the block is not disabled, true if the block is disabled without fields, or an array of fields that will be disabled
 */
export function determineBlockDisabledStatus(
  blocksDisplaySettings: BlockDisplaySetting[],
  currentStepIndex: number,
  blockScope: string
): boolean | string[] {
  const display = blocksDisplaySettings?.find(
    (setting) =>
      setting.blockName.toLowerCase() ===
        blockScope.split('/').pop()?.toLowerCase() &&
      setting.stepIndex === currentStepIndex
  )

  return display ? (display.blockFields ? display.blockFields : true) : false
}
