export enum EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE {
  EXIT_FULL_SCREEN = 'EPILOT/EXIT_FULLSCREEN',
  ENTER_FULL_SCREEN = 'EPILOT/ENTER_FULLSCREEN',
  FORM_EVENT = 'EPILOT/FORM_EVENT',
  JOURNEY_LOADED = 'EPILOT/JOURNEY_LOADED',
  USER_EVENT_PAGE_VIEW = 'EPILOT/USER_EVENT/PAGE_VIEW',
  USER_EVENT_PROGRESS = 'EPILOT/USER_EVENT/PROGRESS',
  CLOSE_JOURNEY = 'EPILOT/CLOSE_JOURNEY',
  /**
   * Legacy event type, maintained for compatibility
   */
  LEGACY_EXIT_FULL_SCREEN = 'ExitFullScreen'
}

type DispatchUserEventType =
  | EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PAGE_VIEW
  | EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PROGRESS

export enum EventNames {
  INIT_PRODUCT_SELECTION = 'InitiateProductSelection',
  INIT_CHECKOUT = 'InitiateCheckout',
  JOURNEY_SUBMIT = 'JourneySubmit'
}

export const publishEmbeddedJourneyMessage = (
  type: EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE,
  journeyId: string,
  payload: Record<string, unknown> = {},
  /**
   * @deprecated Pass necessary information as part of the payload
   */
  additionalData: Record<string, unknown> = {}
) => {
  window.parent.postMessage(
    {
      type,
      journeyId,
      payload,
      ...additionalData
    },
    '*'
  )
}

type DispatchUserEventPayload = {
  path?: string
  eventName?: `${EventNames}`
  eventData?: unknown
  userValues?: unknown
}

export const dispatchUserEvent = (
  type: DispatchUserEventType,
  journeyId: string,
  payload: DispatchUserEventPayload
) => publishEmbeddedJourneyMessage(type, journeyId, payload)
