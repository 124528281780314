import type {
  TypedPaymentMethod,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  PaymentInformation,
  BankTransfer,
  Sepa
} from '../../components/PaymentInformation'
import type {
  PaymentInformationValue,
  SEPAProps
} from '../../components/PaymentInformation'
import {
  CustomPayment,
  stringToHTML,
  useJourneyContext,
  useStepBlockId
} from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

export type PaymentControlProps = {
  implementations: TypedPaymentMethod<React.FC<SEPAProps>>[]
}

export type PaymentControlOptions = PaymentControlProps & {
  initialType?: string
}
export enum PAYMENT_METHOD {
  SEPA = 'SEPA',
  TRANSFER = 'BankTransfer'
}
function PaymentControl({
  handleChange,
  uischema,
  path,
  schema,
  errors,
  enabled,
  data,
  required
}: EpilotControlProps) {
  const { implementations, initialType } =
    uischema.options as PaymentControlOptions
  const { context } = useJourneyContext()
  const { errorMessage } = schema || {}
  const { t } = useTranslation()
  const [paymentInfo, setPaymentInfo] = useState(data)

  const stepBlockId = useStepBlockId(path)

  const filledImplementations = implementations.map(
    (item: TypedPaymentMethod<React.FC<SEPAProps>>) => {
      switch (item.type) {
        case 'SEPA': {
          return {
            ...item,
            informationComponent: Sepa,
            componentProps: {
              ...item.componentProps,
              scale: 3,
              consentLabel: stringToHTML(
                item.componentProps?.consentLabel,
                { allowParagraphs: false },
                context._stepsStateArray,
                context._journeySources
              ),
              accountOwnerNameLabel: t('account_holder'),
              ibanHelper: t('iban_helper')
            }
          }
        }
        case 'BankTransfer': {
          return { ...item, informationComponent: BankTransfer }
        }
        default: {
          return { ...item, informationComponent: CustomPayment }
        }
      }
    }
  )

  const handleOnChange = (
    value: PaymentInformationValue | undefined,
    hasError?: boolean
  ) => {
    let values

    if (
      value?.type === 'payment_sepa' &&
      typeof value?.data?.iban === 'string'
    ) {
      values = {
        ...value,
        data: {
          ...(value?.data || {}),
          iban: value?.data?.iban?.replace(/ /g, '').split('_').join('')
        }
      }
    } else {
      values = value
    }

    setPaymentInfo(values)
    handleChange(path, { ...value, _isValid: !(hasError && required) })
  }

  return (
    <PaymentInformation
      disabled={!enabled}
      error={
        errors
          ? errorMessage && typeof errorMessage === 'string'
            ? errorMessage
            : t('field_required')
          : ''
      }
      id={stepBlockId}
      initialType={initialType}
      onPaymentMethodSelected={handleOnChange}
      paymentMethods={filledImplementations}
      required={required}
      uiType={uischema?.options?.uiType}
      value={paymentInfo}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: PaymentControl }) as never
)
