import { Hidden } from '@epilot/journey-elements'
import type {
  ConsentData,
  ConsentsControlOptions,
  ConsentTopic,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useState } from 'react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { ConsentsList } from './ConsentsList'

function ConsentsControl({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors
}: EpilotControlProps) {
  const { configs = [] } = uischema.options as ConsentsControlOptions
  const [consentsData, setConsentsData] = useState<
    Record<ConsentTopic, ConsentData>
  >(data || {})

  const stepBlockId = useStepBlockId(path)

  const handleOnChange = (newData: Record<ConsentTopic, ConsentData>) => {
    setConsentsData(newData)
    const allRequiredTopics = configs
      .filter((conf) => conf.required)
      .map((conf) => conf.topics)
      .flat() as ConsentTopic[]
    const valid =
      allRequiredTopics.length === 0 ||
      (newData && Object.keys(newData).length > 0
        ? allRequiredTopics.filter((top) => newData[top] && newData[top].agreed)
            .length === allRequiredTopics.length
        : false)

    handleChange(path, { ...newData, _isValid: valid })
  }

  return (
    <Hidden xsUp={!visible}>
      <ConsentsList
        configs={configs}
        error={errors}
        id={stepBlockId}
        onChange={handleOnChange}
        value={consentsData}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ConsentsControl }) as never
)
