import { findStepIndexById } from '../navigation'
import type { Logic, LogicAction, LogicCondition, Step } from '../utils'
import {
  BLOCK_LOCATOR_SEPARATOR,
  logicActionTypes,
  parseLogicActionTarget
} from '../utils'

export type DisplayBlockLogic = Logic & {
  blockName: string
  conditionsRelatedToStep: LogicCondition[]
  actionsIncludesDisplay: (LogicAction & {
    targetStepIndex: number
    targetBlockName: string
  })[]
}

export function getLogicsDisplayBlock(
  steps?: Step[],
  logics?: Logic[]
): DisplayBlockLogic[][] {
  if (!steps || !logics) {
    return []
  }
  const logicsPerStepIndex: DisplayBlockLogic[][] = new Array(steps.length).map(
    () => []
  )

  steps.forEach((step, i) => {
    const logsInStep: DisplayBlockLogic[] = []

    logics.forEach((log) => {
      let blockName = ''
      // One of the conditions includes a block in the Step
      const condSourceStep = log.conditions?.filter((cond) => {
        const parts = cond.conditionSource?.split(BLOCK_LOCATOR_SEPARATOR)

        if (parts && parts[0] === `$$${step.stepId}`) {
          blockName = parts[1]
            ? parts[1].endsWith('$$')
              ? parts[1].slice(0, -2)
              : parts[1]
            : ''

          return true
        }

        return false
      })

      if (Array.isArray(condSourceStep) && condSourceStep.length > 0) {
        // One of the actions include the Inject Filter action type
        const actFilter = log.actions
          ?.filter((act) =>
            logicActionTypes.DisplayBlock.includes(act.actionType)
          )
          .map((e) => {
            const target = parseLogicActionTarget(e.target)
            const targetParts = target?.split(BLOCK_LOCATOR_SEPARATOR)

            return {
              ...e,
              targetBlockName: targetParts?.[1],
              targetStepIndex: findStepIndexById(
                steps,
                targetParts?.[0] || '',
                0
              )
            }
          })

        if (Array.isArray(actFilter) && actFilter.length > 0) {
          logsInStep.push({
            ...log,
            blockName: blockName,
            conditionsRelatedToStep: condSourceStep,
            actionsIncludesDisplay: actFilter as never
          })
        }
      }
    })

    logicsPerStepIndex[i] = logsInStep
  })

  return logicsPerStepIndex
}
