import { Hidden, cleanOptionsFromEnum } from '@epilot/journey-elements'
import { CONTROL_NAME } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import {
  rankWith,
  isEnumControl,
  and,
  optionIs,
  or,
  uiTypeIs
} from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { getEnumOptions } from '../../../utils/helper'
import { includeCommon } from '../../../utils/includeCommon'

import { EnumButton } from './components/EnumButton'
import {
  isEnumButtonControlData,
  isEnumButtonControlOptions,
  isEnumOptions
} from './types.guard'

export function EnumButtonControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  schema,
  visible,
  required,
  id
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path, id)

  const enumOptions = getEnumOptions(schema, uischema)
  const label = uischema?.options?.label || uischema?.label
  const hasError = !!errors
  // Remove empty options
  const options = enumOptions?.filter(Boolean) || []
  // Remove respective labels from empty options
  const optionsLabels = cleanOptionsFromEnum(
    uischema?.options?.optionsLabels,
    enumOptions
  )

  if (!isEnumOptions(options)) {
    console.error(
      'EnumButtonControl -> Invalid enum values detected. Passed values are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isEnumButtonControlOptions(uischema.options)) {
    console.error(
      'EnumButtonControl -> Invalid uischema.options detected. Passed options are:',
      { options: uischema.options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isEnumButtonControlData(data)) {
    console.error(
      'EnumButtonControl -> Invalid data detected. Current data is:',
      { data }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  const optionsIcons = uischema.options?.optionsIcons || []

  const items = options.map((option, index) => ({
    value: option,
    label: optionsLabels?.[index] || option,
    icon: optionsIcons?.[index] || undefined
  }))

  return (
    <Hidden xsUp={!visible}>
      <EnumButton
        data={data}
        defaultSelect={schema.default}
        handleChange={handleChange}
        hasError={hasError}
        id={stepBlockId}
        isRequired={!!required}
        label={typeof label === 'string' ? label.trim() : ''}
        options={items}
        path={path}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: EnumButtonControlPure
  }) as never
)

export const EnumButtonControlTester = rankWith(
  4,
  or(
    and(isEnumControl, optionIs('button', true)),
    and(uiTypeIs(CONTROL_NAME.ENUM_CONTROL), optionIs('button', true))
  )
)
