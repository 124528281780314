import { Hidden } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { SecondaryActionButton } from './components/SecondaryActionButton'
import {
  isSecondaryActionBarControlOptions,
  isSecondaryActionBarOptions
} from './types.guard'

// renderer of the block in jb preview
function SecondaryActionBarControl({
  uischema,
  schema,
  visible,
  path
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)

  const { enum: notFilteredOptions } = schema
  const { label } = uischema || {}

  // remove all buttons with links that were not filled by the user
  const options = notFilteredOptions?.filter(Boolean)

  if (!isSecondaryActionBarOptions(options)) {
    console.error(
      'SecondaryActionBarControl -> Invalid enum values detected. Passed values are:',
      { options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isSecondaryActionBarControlOptions(uischema.options)) {
    console.error(
      'SecondaryActionBarControl -> Invalid uischema.options detected. Passed options are:',
      { options: uischema.options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  const optionsLabels = uischema.options.optionsLabels

  const items = options
    ? options.map((option, index) => ({
        value: option,
        label: optionsLabels ? optionsLabels[index] : ''
      }))
    : options

  return (
    <Hidden xsUp={!visible}>
      <SecondaryActionButton
        id={stepBlockId}
        label={typeof label === 'string' ? label : ''}
        options={items}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: SecondaryActionBarControl
  }) as any
)
