/**
 * This function extracts UUIDs from a given object of parameters.
 * Example: data-123e4567-e89b-12d3-a456-426614174000-extra --> 123e4567-e89b-12d3-a456-426614174000
 */
export const extractUUIDsFromParams = (params: Record<string, unknown>) => {
  const uuidRegex =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/

  const sanitizedParams: Record<string, unknown> = {}

  Object.keys(params).forEach((key) => {
    const value = params[key]

    if (typeof value !== 'string') {
      sanitizedParams[key] = value

      return
    }

    const match = value.match(uuidRegex)

    // If a UUID is found, use it; otherwise, use the original value
    sanitizedParams[key] = match ? match[0] : value
  })

  return sanitizedParams
}
