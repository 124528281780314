import ContactIcon from '@epilot360/icons/react/Contact'
import ContractIcon from '@epilot360/icons/react/Contract'
import MeterIcon from '@epilot360/icons/react/Meter'
import OpportunityIcon from '@epilot360/icons/react/Opportunity'
import OrderIcon from '@epilot360/icons/react/Order'
import type { IconPropsReact } from '@epilot360/icons/react/types'

type SchemaIconProps = {
  schemaSlug: string
  iconProps?: IconPropsReact
}

export function SchemaIcon({ schemaSlug, iconProps }: SchemaIconProps) {
  switch (schemaSlug) {
    case 'opportunity':
      return <OpportunityIcon {...iconProps} />
    case 'contact':
      return <ContactIcon {...iconProps} />
    case 'contract':
      return <ContractIcon {...iconProps} />
    case 'order':
      return <OrderIcon {...iconProps} />
    case 'meter':
      return <MeterIcon {...iconProps} />
    default:
      return <ContractIcon {...iconProps} />
  }
}
