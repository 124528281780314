import type {
  GridProps,
  SpacingProps,
  AppBarProps,
  Theme,
  BoxProps
} from '@epilot/journey-elements'
import {
  Grid,
  StackSpacing,
  AppBar,
  Card,
  makeStyles,
  createStyles,
  clsx
} from '@epilot/journey-elements'
import type { LayoutProps, Layout, UISchemaElement } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import { forwardRef, useMemo } from 'react'

import { IsMobile } from '../../utils/helper'
import { useStickyHeight } from '../../utils/hooks/useStickyHeight'

import { useMainContentCartLayoutStyles, useStickyZoneStyles } from './styles'

export type MainContentCartLayoutOptions = GridProps & {
  scale?: SpacingProps['scale']
  alignItems?: SpacingProps['alignItems']
}

export enum MainContentCartZones {
  'Header',
  'Footer',
  'Content',
  'Side'
}

export const useWrapperStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapperStyles: {
      padding: theme.spacing(4),
      paddingBottom: 0,
      maxWidth: 1256,
      margin: 'auto',
      [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: 936
      },
      flex: 1,
      width: '100%'
    }
  })
})

const MainContentCartLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled
}: LayoutProps) => {
  const { wrapperStyles } = useWrapperStyles()

  const layout = uischema as Layout
  /**
   * @todo layout.elements is typed as UISchemaElement[] but used as UISchemaElement[][],
   * fix this typing or refactor it.
   */
  const elements = (layout.elements as any[]) || []
  const {
    spacing = 4,
    scale = 3,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as MainContentCartLayoutOptions) || {}

  const mobile = IsMobile()

  const sticky: UISchemaElement[] = useMemo(() => {
    const full = elements
      .slice(0, 4)
      .map((element) => getStickyItems(element))
      .flatMap((value) => value)

    full.sort(stickySorter)

    return full
  }, [elements])

  const { stickyRef, stickyHeight } = useStickyHeight(mobile)
  const classes = useMainContentCartLayoutStyles({ stickyHeight })

  // first element is the main content
  const content = useMemo(
    () =>
      renderZone(
        elements[0],
        'content',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile,
        Array.isArray(elements[1]) && elements[1].length > 0 ? 7 : 12
      ),
    [mobile, elements[0]]
  )

  // second element is the side (ex. shopping cart)
  const side = useMemo(
    () =>
      renderZone(
        elements[1],
        'side',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile,
        Array.isArray(elements[0]) && elements[0].length > 0 ? 5 : 12
      ),
    [mobile, elements[1]]
  )

  // third element is the header
  const header = useMemo(
    () =>
      renderZone(
        elements[2],
        'header',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile
      ),
    [mobile, elements[2]]
  )

  // forth element is the footer
  const footer = useMemo(
    () =>
      renderZone(
        elements[3],
        'footer',
        {
          renderers,
          schema,
          path,
          enabled
        } as LayoutProps,
        alignItems,
        scale,
        mobile
      ),
    [mobile, elements[3]]
  )

  return (
    <div className={clsx(classes.container, mobile && classes.withPadding)}>
      <div className={wrapperStyles}>
        <Grid container spacing={spacing} {...rest}>
          {header}
          {content}
          {side}
          {footer}
        </Grid>
      </div>
      {mobile && sticky.length > 0 && (
        <StickyZone ref={stickyRef}>
          {sticky.map((child: UISchemaElement | undefined, index: number) => (
            <JsonFormsDispatch
              enabled={enabled}
              key={'sticky' + index}
              path={path}
              renderers={renderers}
              schema={schema}
              uischema={child}
            />
          ))}
        </StickyZone>
      )}
    </div>
  )
}

function renderZone(
  elements: UISchemaElement[],
  name: string,
  { renderers, schema, path, enabled }: LayoutProps,
  alignItems: BoxProps['alignItems'],
  scale: number,
  mobile?: boolean,
  md?: GridProps['md']
) {
  if (Array.isArray(elements) && elements.length > 0) {
    return (
      <Grid item md={md} xs={12}>
        <StackSpacing alignItems={alignItems} scale={scale}>
          {elements.map((child: UISchemaElement, index: number) => {
            if (child.options?.stickyOnMobile && mobile) {
              return null
            } else {
              return (
                <JsonFormsDispatch
                  enabled={enabled}
                  key={name + index}
                  path={path}
                  renderers={renderers}
                  schema={schema}
                  uischema={child}
                />
              )
            }
          })}
        </StackSpacing>
      </Grid>
    )
  }

  return <></>
}

export const StickyZone = forwardRef(
  ({ children, ...rest }: AppBarProps, ref) => {
    const classes = useStickyZoneStyles()

    return (
      <AppBar
        className={classes.appBar}
        color="transparent"
        position="fixed"
        ref={ref}
        {...rest}
      >
        <Card className={classes.card}>{children}</Card>
      </AppBar>
    )
  }
)

StickyZone.displayName = 'StickyZone'

export const stickySorter = (a: UISchemaElement, b: UISchemaElement) =>
  a.options?.stickyOnMobileIndex > b.options?.stickyOnMobileIndex
    ? 1
    : a.options?.stickyOnMobileIndex < b.options?.stickyOnMobileIndex
      ? -1
      : 0

export function getStickyItems(elements: UISchemaElement[]) {
  const arr: UISchemaElement[] = []

  elements.map((e) => {
    if (e.options && e.options.stickyOnMobile) {
      arr.push(e)
    }
  })

  return arr
}

export default withJsonFormsLayoutProps(MainContentCartLayout)
