import { BooleanControl } from '@epilot/journey-elements'
import type {
  ConsentConfig,
  ConsentData,
  ConsentTopic
} from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { mdToHTML } from '../../..'

export type ConsentViewProps = {
  value: Record<ConsentTopic, ConsentData>
  config: ConsentConfig
  onChange: (value: Record<ConsentTopic, ConsentData>) => void
  error?: string
  id: string
}

export function ConsentView({
  value,
  error,
  config,
  onChange,
  id
}: ConsentViewProps) {
  const { t } = useTranslation()
  const { required, textMD = '', topics = [] } = config
  const booleanValue =
    topics.filter((top) => value[top] && value[top].agreed).length > 0
  const hasError = error && required && !booleanValue

  const consentTopicId = appendStepBlockId(id, `Consent ${topics.join('_')}`)

  return (
    <BooleanControl
      errorMessage={hasError ? t('field_required') : undefined}
      id={consentTopicId}
      label={<span>{mdToHTML(textMD)}</span>}
      onChange={(v) => {
        if (v) {
          const newValue = { ...value }

          topics.map((top) => {
            newValue[top] = {
              agreed: true,
              topic: top,
              text: textMD,
              time: new Date()
            }
          })
          onChange(newValue)
        } else {
          const newValue = { ...value }

          topics.map((top) => {
            delete newValue[top]
          })
          onChange(newValue)
        }
      }}
      required={required}
      uiType="checkbox"
      value={booleanValue}
    />
  )
}
