import type { ComponentType } from 'react'

import type { NormalizedSectionContainerProps } from '../../types'

import { ProductImageRenderer } from './ProductImageRenderer'
import { ProductCouponWrapper } from './ProductImageRenderer/ProductCoupon'
import { ProductTitle } from './ProductTitle'

export const TileHeader = ({
  IMAGE_PREVIEW_API_URL,
  NormalizedSectionContainer
}: {
  IMAGE_PREVIEW_API_URL: string
  NormalizedSectionContainer: ComponentType<NormalizedSectionContainerProps>
}) => {
  return (
    <header>
      <NormalizedSectionContainer sectionName="concordeTitle">
        <ProductTitle />
      </NormalizedSectionContainer>
      <ProductCouponWrapper>
        <ProductImageRenderer IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL} />
      </ProductCouponWrapper>
    </header>
  )
}
