const NoProductsIcon = () => (
  <>
    <svg
      fill="none"
      style={{ width: '200px' }}
      viewBox="0 0 300 282"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h300v282H0z" fill="#fff" />
      <path
        d="M179.61 163.826a4.26 4.26 0 0 1 4.261 4.252 4.26 4.26 0 0 1-4.261 4.252h-61.072a4.259 4.259 0 0 1-4.261-4.252c0-1.176.476-2.239 1.25-3.004a4.224 4.224 0 0 1 3.011-1.248h61.072Z"
        fill="#FFA78E"
      />
      <path
        d="M222.22 138.293v65.715c0 4.032-3.182 7.299-7.102 7.299H84.451c-3.764 0-6.846-3.012-7.087-6.825 21.794.475 120.32-1.282 143.514-70.377a7.074 7.074 0 0 1 1.342 4.188Z"
        fill="#FFA78E"
      />
      <path
        d="M78.137 134.998c1.179-2.275 3.566-3.77 6.314-3.77h40.478v11.339h48.29v-11.339h41.899c2.372 0 4.474 1.113 5.759 2.877-23.194 69.095-121.719 70.852-143.514 70.377a10.217 10.217 0 0 1-.014-.474v-65.715c0-1.204.284-2.324.788-3.295Zm105.735 33.08a4.26 4.26 0 0 0-4.261-4.252h-61.073a4.224 4.224 0 0 0-3.011 1.248 4.207 4.207 0 0 0-1.25 3.004 4.26 4.26 0 0 0 4.261 4.252h61.073a4.26 4.26 0 0 0 4.261-4.252Z"
        fill="#F9E1D8"
      />
      <path
        d="m78.137 134.998 14.998-39.94a5.122 5.122 0 0 0-.163 1.282v34.888h-8.521c-2.749 0-5.135 1.495-6.314 3.77Zm127.599-39.99 15.233 38.828a1.298 1.298 0 0 1-.092.269c-1.286-1.764-3.388-2.877-5.759-2.877h-9.232V96.341c0-.461-.05-.907-.15-1.333Z"
        fill="#EDF2F7"
      />
      <path
        d="M200.673 90.834c2.592 0 4.531 1.793 5.063 4.174.101.425.15.872.15 1.333v34.887h-32.667v11.339h-48.29v-11.339H92.973V96.34c0-.439.056-.871.162-1.282.618-2.403 2.919-4.224 5.532-4.224h102.006Z"
        fill="#EDF2F7"
      />
      <path
        d="M173.219 131.228h41.899c2.372 0 4.474 1.113 5.758 2.877a7.074 7.074 0 0 1 1.343 4.188v65.715c0 4.032-3.181 7.299-7.101 7.299H84.451c-3.765 0-6.847-3.012-7.088-6.825a10.217 10.217 0 0 1-.014-.474v-65.715c0-1.204.284-2.324.788-3.295 1.179-2.275 3.565-3.77 6.314-3.77h40.478m-31.957 0V96.34c0-.439.056-.871.163-1.282.618-2.403 2.918-4.224 5.532-4.224h102.006c2.592 0 4.53 1.793 5.063 4.174.1.425.15.872.15 1.333v34.887"
        stroke="#3F455D"
      />
      <path
        d="m78.123 135.041.013-.043 14.999-39.94.022-.05m128.288 40.033-.021-.05-.455-1.155-15.233-38.828m-80.807 36.22v11.339h48.29v-11.339m10.652 36.85a4.26 4.26 0 0 1-4.261 4.252h-61.072a4.259 4.259 0 0 1-4.261-4.252c0-1.176.476-2.239 1.25-3.004a4.224 4.224 0 0 1 3.011-1.248h61.072a4.26 4.26 0 0 1 4.261 4.252Zm-52.551-12.047h35.508"
        stroke="#3F455D"
      />
      <path
        d="m177.835 99.197-28.2 24.569h-.177l-28.08-24.569h14.26V48.173h26.276v51.024h15.921Z"
        fill="#26BCFC"
      />
      <path
        d="m149.458 123.766-28.08-24.569h14.26V48.173h12.073"
        fill="#26BCFC"
      />
      <path
        d="m149.458 123.766-28.08-24.569h14.26V48.173h12.073"
        stroke="#3F455D"
      />
      <path
        d="m149.635 123.766 28.2-24.569h-15.921V48.173h-14.203"
        fill="#26BCFC"
      />
      <path
        d="m149.635 123.766 28.2-24.569h-15.921V48.173h-14.203"
        stroke="#3F455D"
      />
      <path
        d="M216.715 183.904c6.178 0 11.185 6.03 11.185 13.464 0 7.441-5.007 13.465-11.185 13.465-6.178 0-11.185-6.024-11.185-13.465 0-7.434 5.007-13.464 11.185-13.464Zm6.363 13.464c0-4.231-2.848-7.66-6.363-7.66-3.515 0-6.363 3.429-6.363 7.66s2.848 7.668 6.363 7.668c3.515 0 6.363-3.437 6.363-7.668Z"
        fill="#fff"
      />
      <path
        d="M216.715 189.708c3.516 0 6.363 3.429 6.363 7.66s-2.847 7.668-6.363 7.668c-3.515 0-6.362-3.437-6.362-7.668s2.847-7.66 6.362-7.66Z"
        fill="#FF3A3F"
      />
      <path
        d="M216.893 174.691c12.484 0 22.604 10.105 22.604 22.564 0 12.458-10.12 22.556-22.604 22.556s-22.604-10.098-22.604-22.556c0-12.459 10.12-22.564 22.604-22.564Zm11.007 22.677c0-7.434-5.006-13.464-11.184-13.464-6.179 0-11.185 6.03-11.185 13.464 0 7.441 5.006 13.465 11.185 13.465 6.178 0 11.184-6.024 11.184-13.465Z"
        fill="#FF3A3F"
      />
      <path
        d="M239.497 197.255c0 12.458-10.12 22.556-22.604 22.556s-22.604-10.098-22.604-22.556c0-12.459 10.12-22.564 22.604-22.564s22.604 10.105 22.604 22.564Z"
        stroke="#3F455D"
      />
      <path
        d="M227.9 197.368c0 7.441-5.007 13.465-11.185 13.465-6.178 0-11.185-6.024-11.185-13.465 0-7.434 5.007-13.464 11.185-13.464 6.178 0 11.185 6.03 11.185 13.464Z"
        stroke="#3F455D"
      />
      <path
        d="M223.078 197.368c0 4.231-2.847 7.668-6.363 7.668-3.515 0-6.362-3.437-6.362-7.668s2.847-7.66 6.362-7.66c3.516 0 6.363 3.429 6.363 7.66Z"
        stroke="#3F455D"
      />
      <path
        d="M217.249 179.404c-14.651 0-17.477 14.059-17.591 14.655"
        stroke="#FF6A3F"
      />
    </svg>
  </>
)

export default NoProductsIcon
