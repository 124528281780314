import type { StreetSuggestion } from '@epilot/journey-elements'
import type {
  Address,
  AddressSuggestionsSource,
  FieldOption
} from '@epilot/journey-logic-commons'
import { useMemo } from 'react'
import type {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldAutocomplete } from '../../../../components/FieldAutocomplete'
import { useConfig, useJourneyContext } from '../../../../utils'
import type { StreetSuggestionData } from '../types'
import { FeedBackType } from '../types'
import { sendFeedback } from '../utils'

import { useAddressSuggestions } from './hooks'

export type FieldStreetInputProps<T extends FieldValues> = {
  addressSuggestionsSource:
    | AddressSuggestionsSource
    | AddressSuggestionsSource[]
    | undefined
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: PathValue<T, Path<T>>
  path: string
  rules?: UseControllerProps['rules']
  fieldValues: Address
  acceptSuggestedOnly?: boolean
  freeSolo?: boolean
  disableAddressSuggestions?: boolean
  keepAutoCompleteClosed?: boolean
  id: string
} & FieldOption

const MIN_CHARS = 2

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldStreetInput = <T extends Record<string, any>>(
  props: FieldStreetInputProps<T>
) => {
  const {
    addressSuggestionsSource,
    label,
    required,
    fieldValues,
    disableAddressSuggestions,
    keepAutoCompleteClosed,
    ...autocompleteProps
  } = props

  const { t } = useTranslation()
  const { context } = useJourneyContext()
  const {
    journey: { organizationId: orgId, settings }
  } = context
  const publicToken = settings?.publicToken as string

  const { getSuggestions, options, isLoading } = useAddressSuggestions(
    'street',
    disableAddressSuggestions,
    fieldValues,
    addressSuggestionsSource,
    publicToken,
    settings?.addressSuggestionsFileUrl,
    orgId,
    settings?.addressSuggestionsFileId
  )

  const { ADDRESS_API_URL } = useConfig()

  const streetSuggestions: string[] = useMemo(() => {
    if (!options) {
      return []
    }

    return (options as StreetSuggestionData)
      .map((item) => {
        return item?.street
      })
      .filter(Boolean)
  }, [options])

  return (
    <FieldAutocomplete<T, string, StreetSuggestion>
      {...autocompleteProps}
      getSuggestions={
        getSuggestions as (
          value: string
        ) => Promise<StreetSuggestion[]> | undefined
      }
      keepAutoCompleteClosed={keepAutoCompleteClosed}
      label={(label || t('Street')) + (required ? ' *' : '')}
      loading={isLoading}
      minChars={MIN_CHARS}
      noOptionsText={t('no_street')}
      onChange={(data, field) => {
        if (typeof data === 'string') {
          const suggestion = (options as StreetSuggestionData)?.find((item) => {
            return data === item.street
          })

          sendFeedback(
            ADDRESS_API_URL,
            FeedBackType.STREETS,
            fieldValues.countryCode,
            suggestion,
            publicToken
          )

          return field.onChange(data)
        } else {
          return field.onChange('')
        }
      }}
      options={streetSuggestions || []}
      required={required}
    />
  )
}
