import type { Theme } from '@epilot/journey-elements'
import {
  Box,
  Card,
  CardContent,
  H5,
  Typography,
  useTheme,
  makeStyles,
  createStyles
} from '@epilot/journey-elements'
import { isLayout } from '@jsonforms/core'
import type { LayoutProps, UISchemaElement } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'

import { IsMobileOrTablet } from '../../utils/helper'

type GroupOptions = {
  name: string
  title: string
  subtitle: string
  showPaper: boolean
  display: boolean
}

const useStyles = makeStyles((theme: Theme) => {
  const spacing =
    (theme && typeof theme.spacing === 'number' && theme.spacing) || 4

  return createStyles({
    contentStyle: {
      paddingTop: `${spacing * 3}px`,
      paddingBottom: `${spacing * 3}px`,
      paddingLeft: `${spacing * 3}px`,
      paddingRight: `${spacing * 3}px`
    },
    blocksContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: `${spacing * 3}px`
    },
    fullWidth: {
      width: '100%',
      // Allow wrapping for group layout on smaller screens
      '@media (min-width: 400px)': {
        minWidth: '270px'
      }
    },
    halfWidth: {
      flexGrow: 1,
      width: '45%',
      minWidth: '270px'
    },
    bigTitle: {
      fontSize: '100%'
    },
    smallTitle: {
      fontSize: '80%'
    },
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
})

function GroupLayout({
  uischema,
  schema,
  enabled,
  path,
  renderers
}: LayoutProps) {
  const theme = useTheme()
  const classes = useStyles(theme)

  if (!isLayout(uischema)) {
    return null
  }

  const { name, title, subtitle, showPaper, display } =
    uischema.options as GroupOptions
  const blocks = uischema.elements

  const mobile = IsMobileOrTablet()

  const mappedBlocks = blocks.map((block, index) => {
    const options: UISchemaElement['options'] = {
      ...block.options,
      isNested: true
    }

    block.options = options

    return (
      <div
        className={options.halfWidth ? classes.halfWidth : classes.fullWidth}
        key={`${name}${index}`}
      >
        <JsonFormsDispatch
          enabled={enabled}
          path={path}
          renderers={renderers}
          schema={schema}
          uischema={block}
        />
      </div>
    )
  })

  const content =
    title || subtitle ? (
      <>
        <div>
          {title ? (
            <Box pb={0}>
              <H5>
                <b className={mobile ? classes.smallTitle : classes.bigTitle}>
                  {title}
                </b>
              </H5>
            </Box>
          ) : null}
          {subtitle ? (
            <div>
              <Typography variant="body1">{subtitle}</Typography>
            </div>
          ) : null}
        </div>
        <div className={classes.blocksContainer}>{mappedBlocks}</div>
      </>
    ) : (
      <div className={classes.blocksContainer}>{mappedBlocks}</div>
    )

  if (!display) {
    return null
  }

  return showPaper ? (
    <Card className={classes.paper}>
      <CardContent className={classes.contentStyle}>{content}</CardContent>
    </Card>
  ) : (
    <div className={classes.contentStyle}>{content}</div>
  )
}

export default withJsonFormsLayoutProps(GroupLayout)
