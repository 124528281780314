/* eslint-disable @typescript-eslint/no-explicit-any */
import { BodyText } from '@epilot/journey-elements'
import type { S3File } from '@epilot/journey-elements'
import type { Source } from '@epilot/journey-logic-commons'
import { CONTROL_NAME } from '@epilot/journey-logic-commons'
import { formatPriceUnit } from '@epilot/pricing'
import type { TFunction } from 'i18next'
import isDate from 'lodash/isDate'
import isObject from 'lodash/isObject'
import type { ReactElement } from 'react'

import { toDateString } from '../../../utils/helper'
import { formatNumberInUserLocale } from '../../../utils/numberFormat'
import type { EntityLookupControlOptions } from '../EntityLookupControl'
import {
  AttributeItem,
  isAddressRelationArray
} from '../EntityLookupControl/components/AttributeItem'
import type { InputCalculatorDevice } from '../InputCalculatorControl/types'
import type { DynamicMeterReadingFormValues } from '../MeterReadingDynamicControl'

import {
  formatDeviceConsumptionStr,
  formatConsumptionToLocaleString
} from './utils'

// Data mapping based on required order and block info
export function getBlockDataRender(
  blockData: any,
  t: TFunction,
  blockSource?: Source
): (string | ReactElement)[][] {
  const controlNameId = blockSource?.controlNameId

  switch (controlNameId) {
    case CONTROL_NAME.BOOLEAN_CONTROL:
      return [[blockData ? t('Yes') : t('No')]]
    case CONTROL_NAME.ENUM_CONTROL: {
      return [[blockData]]
    }
    case CONTROL_NAME.PERSONAL_INFORMATION_CONTROL:
      // 2d array for collect the data for each Block Control type
      return [
        [
          blockData?.salutation
            ? t(
                `salutation.options.${blockData?.salutation.replace(
                  /\./g,
                  '·'
                )}`,
                blockData?.salutation
              )
            : blockData?.salutation,
          blockData?.title
        ],
        [blockData?.firstName, blockData?.lastName],
        [blockData?.birthDate ? toDateString(blockData.birthDate) : undefined],
        [blockData?.email],
        [blockData?.telephone],
        [blockData?.companyName],
        [blockData?.registryCourt],
        [blockData?.registerNumber]
      ]
    case CONTROL_NAME.ADDRESS_BLOCK:
      return [
        [
          blockData?.salutation
            ? t(
                `salutation.options.${blockData?.salutation.replace(
                  /\./g,
                  '·'
                )}`,
                blockData?.salutation
              )
            : blockData?.salutation,
          blockData?.title
        ],
        [blockData?.firstName, blockData?.lastName],
        [blockData?.streetName, blockData?.houseNumber],
        [
          blockData?.plotArea
            ? `${t('Plot Area')} ${blockData.plotArea}`
            : undefined,
          blockData?.plotOfLand
            ? `${t('Plot of Land')} ${blockData.plotOfLand}`
            : undefined
        ],
        [blockData?.suburb],
        [blockData?.zipCode, blockData?.city],
        [blockData?.addressExtention || blockData?.extention],
        [blockData?.companyName]
      ]
    case CONTROL_NAME.INPUT_CALCULATOR_CONTROL: {
      const data = blockData

      if (data == undefined || data === null) return []

      const devices = data.devices?.map(
        (device: string | InputCalculatorDevice) => {
          if (typeof device === 'string') return [device]

          const unitLabel = data.numberUnit
            ? t(`units.${data.numberUnit}`, data.numberUnit)
            : ''

          return [formatDeviceConsumptionStr(device, unitLabel as string)]
        }
      )

      return [
        ...(devices || []),
        [
          data.numberInput
            ? `${t(
                'input_calculator.total',
                'Total'
              )} ${formatConsumptionToLocaleString(data.numberInput)} ${
                data.numberUnit
                  ? t(`units.${data.numberUnit}`, data.numberUnit)
                  : ''
              }`
            : ''
        ]
      ]
    }
    case CONTROL_NAME.AVAILABILITY_CHECK:
      return [
        [blockData?.streetName, blockData?.streetNumber],
        [blockData?.zipCode, blockData?.city]
      ]
    case CONTROL_NAME.UPLOAD_CONTROL: {
      const fileNames = (blockData as any[])?.map((file: S3File) => {
        return [file.original_name]
      })

      return fileNames || []
    }

    case CONTROL_NAME.DATE_PICKER: {
      const dateStringStart = blockData && toDateString(blockData?.startDate)
      const dateStringEnd = blockData && toDateString(blockData?.endDate)

      return [
        [`${dateStringStart}${dateStringEnd ? ` - ${dateStringEnd}` : ''}`]
      ]
    }

    case CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL: {
      const company_name = blockData && blockData?.company_name

      return [[`${company_name}`]]
    }

    case CONTROL_NAME.PAYMENT_CONTROL: {
      return [
        [blockData?.label || t(blockData?.type)],
        [blockData?.data?.fullname],
        [blockData?.data?.iban]
      ]
    }

    case CONTROL_NAME.METER_READING_CONTROL: {
      // If blockData has a key of meters, loop through it and output multiple meter readings
      if (blockData['meters'] && Array.isArray(blockData['meters'])) {
        return [
          blockData['meters'].map(
            (meter: DynamicMeterReadingFormValues, index) => {
              if (!isObject(meter)) {
                return ''
              }

              // get keys from the meter reading and filter primitive types + remove the key meterType, which is used as a label
              const renderedKeys = ['readBy', 'readingDate', 'reason']

              // get label from meterType
              const label = `${t('meter_number')}: ${meter._meter_number}`

              return (
                <div
                  key={index}
                  style={index !== 0 ? { marginTop: '8px' } : {}}
                >
                  <BodyText color="textSecondary" variant="body2">
                    <strong>{label}</strong>
                  </BodyText>
                  {meter.readings?.map((reading, index) => {
                    const value = reading.value
                    const unit = reading.unit

                    return (
                      <BodyText color="textSecondary" key={index}>
                        {`${reading._label}: ${formatNumberInUserLocale(
                          value + '',
                          true
                        )} ${unit ? t(unit) : ''}`}
                      </BodyText>
                    )
                  })}
                  {renderedKeys.map((k) => {
                    const value =
                      meter[k as keyof DynamicMeterReadingFormValues]

                    if (!value) {
                      return <></>
                    }

                    return (
                      <BodyText color="textSecondary" key={k}>
                        {t(k)}:{' '}
                        {isDate(value)
                          ? toDateString(value)
                          : String(value || '')}
                      </BodyText>
                    )
                  })}
                </div>
              )
            }
          )
        ]
      }

      return [
        [blockData?.label || t(blockData?.type)],
        [blockData?.maloId],
        [blockData?.meterId],
        [blockData?.meterType],
        [blockData?.readingDate && toDateString(blockData?.readingDate)],
        [blockData?.numberInput],
        [blockData?.numberUnit],
        [blockData?.readBy],
        [blockData?.reason]
      ]
    }

    case CONTROL_NAME.MULTICHOICE_CONTROL: {
      return [[blockData?.join(', ')]]
    }

    case CONTROL_NAME.NUMBER_INPUT_CONTROL: {
      const unit = formatPriceUnit(blockData?.numberUnit)
      const numberInput = blockData?.numberInput || 0
      const unitSuffix =
        numberInput > 1 && ['generic', 'piece_one'].includes(numberInput)
          ? '_plural'
          : ''

      return blockData?.numberInput || blockData.numberInput === 0
        ? [
            [
              `${
                blockData.numberInput
                  ? formatNumberInUserLocale(blockData.numberInput)
                  : ''
              } ${
                blockData?.numberUnit
                  ? t(`units.${unit}${unitSuffix}`, unit)
                  : ''
              }`
            ]
          ]
        : []
    }

    case CONTROL_NAME.CONSENTS_CONTROL: {
      if (blockData) {
        const keys = Object.keys(blockData)

        return [
          keys.map((k) => (
            <BodyText color="textSecondary" key={k}>
              <>{t(k)} - </>
              <strong>{blockData[k].agreed ? t('Yes') : t('No')}</strong>
            </BodyText>
          ))
        ]
      } else {
        return []
      }
    }

    case CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL: {
      return [
        [
          <img
            alt={CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL}
            key={controlNameId}
            src={`${blockData}`}
            style={{ height: 80, width: 'auto' }}
          />
        ]
      ]
    }

    case CONTROL_NAME.ENTITY_LOOKUP_CONTROL: {
      if (!blockData['entity']) {
        return []
      }

      const entity = blockData['entity']
      const slug = blockData['slug']
      const tableAttributes = (blockSource?.uischema?.options
        ?.tableAttributes ||
        []) as EntityLookupControlOptions['tableAttributes']

      // get keys from the meter reading and filter primitive types + remove the key meterType, which is used as a label
      const renderedKeys = Object.keys(entity).filter(
        (k) =>
          isDate(entity[k]) ||
          isAddressRelationArray(entity[k]) ||
          (!isObject(entity[k]) && !k.startsWith('_'))
      )

      return [
        [
          <div key={slug}>
            <BodyText color="textSecondary" variant="body2">
              <strong>
                {t('your_selection', 'Your details:', {
                  slug: t(`schema.${slug}`)
                })}
              </strong>
            </BodyText>
            {renderedKeys.map((k) => {
              const value = entity[k]
              const attribute = tableAttributes?.find((att) => att.name === k)

              return (
                <AttributeItem
                  displayMode="simple"
                  key={'attrib' + k}
                  label={attribute ? attribute.label : k}
                  slug={slug}
                  value={value}
                />
              )
            })}
          </div>
        ]
      ]
    }

    case CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL: {
      const newValue = blockData['newValue']

      if (!newValue || (isObject(newValue) && !isDate(newValue))) {
        if (
          Array.isArray(newValue) &&
          newValue[0] &&
          Object.keys(newValue[0]).includes('postal_code')
        ) {
          const addressData = newValue[0]

          return [
            [addressData?.street, addressData?.street_number],
            [addressData?.postal_code, addressData?.city],
            [addressData?.additional_info]
          ]
        }

        return []
      }

      const value = isDate(blockData['newValue'])
        ? toDateString(blockData['newValue'])
        : String(blockData['newValue'])

      return [[value]]
    }

    default:
      if (
        blockData &&
        typeof blockData === 'object' &&
        '_summary' in blockData
      ) {
        return [[blockData._summary]]
      }

      return [[blockData]]
  }
}
