import { findBlockInStep } from './findBlockInStep'
import type {
  Block,
  BlockLocatorDetails,
  BlockFindingOperation,
  SearchCriteria,
  SearchOptions,
  SearchElement
} from './types'

export function findBlocks(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions?: Omit<SearchOptions, 'representBy'> & {
    representBy?: 'single'
  },
  operation?: BlockFindingOperation
): BlockLocatorDetails[]
export function findBlocks(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions: Omit<SearchOptions, 'representBy'> & { representBy: 'full' },
  operation?: BlockFindingOperation
): Block[]
export function findBlocks(
  searchElement: SearchElement,
  searchCriteria: SearchCriteria,
  searchOptions?: SearchOptions,
  operation?: BlockFindingOperation
): (Block | BlockLocatorDetails)[] {
  if (
    !(
      ('name' in searchCriteria && searchCriteria.name) ||
      ('type' in searchCriteria && searchCriteria.type)
    )
  ) {
    // eslint-disable-next-line no-console
    console.warn('findBlocks --> a search criteria needs to be defined')

    return []
  }

  /**
   * @todo This scenario should not occur, as function should only be callable with either one or the other.
   * Remove when it's certain typeguards are preventing this case.
   */
  if (
    'name' in searchCriteria &&
    searchCriteria.name &&
    'type' in searchCriteria &&
    (searchCriteria as { type: string }).type
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      'findBlocks --> multiple search criteria found. Searching by type'
    )
  }

  if (Array.isArray(searchElement)) {
    if (searchOptions?.representBy === 'full') {
      const blocks: Block[] = []

      searchElement.forEach((step) => {
        const block = findBlockInStep(
          step,
          searchCriteria,
          { ...searchOptions, representBy: 'full' },
          operation
        )

        if (block) {
          blocks.push({ uischema: block?.uischema, schema: block?.schema })
        }
      })

      return blocks
    } else {
      const blocks: BlockLocatorDetails[] = []

      searchElement.forEach((step, index) => {
        if ('type' in searchCriteria) {
          const items = findBlockInStep(
            step,
            searchCriteria,
            { ...searchOptions, representBy: undefined },
            operation
          )

          if (items) {
            blocks.push(
              ...items.map((item) => ({
                ...item,
                stepIndex: index,
                stepNumber: index + 1
              }))
            )
          }
        } else if (searchCriteria.name) {
          const block = findBlockInStep(
            step,
            searchCriteria,
            { ...searchOptions, representBy: undefined },
            operation
          )

          if (block) {
            blocks.push({
              ...block,
              stepIndex: index,
              stepNumber: index + 1
            })
          }
        } else {
          // eslint-disable-next-line no-console
          console.error('Error in findBlocks. Invalid searchcriteria passed. ')
        }
      })

      return blocks
    }
  } else {
    if (searchOptions?.representBy === 'full') {
      const block = findBlockInStep(
        searchElement,
        searchCriteria,
        { ...searchOptions, representBy: 'full' },
        operation
      )

      if (block) {
        return [block]
      }
    } else {
      if ('type' in searchCriteria) {
        const items = findBlockInStep(
          searchElement,
          { type: searchCriteria.type },
          { ...searchOptions, representBy: undefined },
          operation
        )

        if (items) {
          return items.map<BlockLocatorDetails>((item) => ({
            ...item,
            stepIndex: searchElement.stepIndex,
            stepNumber: searchElement.stepIndex + 1
          }))
        }
      } else if (searchCriteria.name) {
        const block = findBlockInStep(
          searchElement,
          searchCriteria,
          { ...searchOptions, representBy: undefined },
          operation
        )

        if (block) {
          return [
            {
              ...block,
              stepIndex: searchElement.stepIndex,
              stepNumber: searchElement.stepIndex + 1
            } as const
          ]
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Error in findBlocks. Invalid searchcriteria passed. ')
      }
    }
  }

  return []
}
