import type { Control } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FieldSelect } from '../../../../components/FieldAutocomplete/FieldSelect'
import { FieldText } from '../../../../components/FieldText'
import type { InputCalculatorFormValues } from '../types'

import { useDeviceSelectionStyles } from './inputCalculatorStyles'

type DeviceSelectionFieldProps = {
  control: Control<InputCalculatorFormValues>
  hasError: boolean
  index: number
  label: string
  required: boolean
  options: string[]
  id: string
}

export const DeviceSelectionField = ({
  control,
  hasError,
  index,
  label,
  required,
  options,
  id
}: DeviceSelectionFieldProps) => {
  const classes = useDeviceSelectionStyles()
  const { t } = useTranslation()
  const watchedDevices = useWatch({ control, name: 'devices' })

  const selectedDevices =
    watchedDevices
      ?.filter((_, i) => i !== index)
      .map((device) => device.name) || []

  const filteredOptions = options.filter(
    (option) =>
      option === t('input_calculator.other_option') ||
      !selectedDevices?.includes(option)
  )

  const currentDevice = watchedDevices?.[index].name
  const otherNameError = t('input_calculator.device_other_name_missing')

  return (
    <div className={classes.container}>
      <div className={classes.field}>
        <FieldSelect
          control={control}
          customBackground="white"
          hasError={hasError}
          id={id}
          label={label}
          name={`devices.${index}.name`}
          options={filteredOptions}
          required={required}
        />
      </div>
      {currentDevice === t('input_calculator.other_option') && (
        <div className={classes.field}>
          <FieldText
            control={control}
            errorMessage={otherNameError}
            hasError={hasError}
            id={`${id}_otherName`}
            label={label}
            name={`devices.${index}.otherName`}
            required={true}
          />
        </div>
      )}
    </div>
  )
}
