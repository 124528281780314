import { Hidden } from '@epilot/journey-elements'
import { isContactControlOptions } from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { ContactForm } from './components/ContactForm'
import { isContactControlData } from './types.guard'
import { mapToContactOptions } from './utils'

// PersonalInformationControl
function ContactControl({
  data,
  handleChange,
  path,
  errors,
  uischema,
  required,
  visible,
  disabledFields
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const hasError = !!errors

  const { t } = useTranslation()

  // map configurator dto to props the ContactForm can understand easily
  const [contactOptions, setContactOptions] = useState(() =>
    mapToContactOptions(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      uischema.options as any,
      t,
      undefined,
      Array.isArray(disabledFields) ? disabledFields : undefined
    )
  )

  useEffect(() => {
    setContactOptions(
      mapToContactOptions(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        uischema.options as any,
        t,
        undefined,
        Array.isArray(disabledFields) ? disabledFields : undefined
      )
    )
  }, [uischema.options, path, disabledFields])

  if (!isContactControlOptions(contactOptions)) {
    console.error(
      'ContactControl -> Invalid uischema.options detected. Passed options are:',
      { options: contactOptions }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isContactControlData(data)) {
    console.error('ContactControl -> Invalid data detected. Current data is:', {
      data
    })

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <ContactForm
        data={data}
        handleChange={(p, v) =>
          v?.email
            ? handleChange(p, { ...v, email: v.email.trim().toLowerCase() })
            : handleChange(p, v)
        }
        hasError={hasError}
        id={stepBlockId}
        isRequired={!!required}
        path={path}
        {...contactOptions}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: ContactControl }) as never
)
