import {
  DateFnsUtils,
  Grid,
  MuiPickersUtilsProvider
} from '@epilot/journey-elements'
import {
  appendStepBlockId,
  addDate,
  getLocaleDateString,
  findNextAvailableDay,
  findInitialValues,
  isBlockDataValid,
  createDateSchema,
  LOCALE_MAP
} from '@epilot/journey-logic-commons'
import type {
  DatePickerFormProps,
  DatePickerFormValues
} from '@epilot/journey-logic-commons'
import { yupResolver } from '@hookform/resolvers/yup'
import { add } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FieldDatePicker } from '../../../../components/FieldDatePicker'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetOnChange } from '../../../../utils/hooks/forms/useResetOnChange'

/**
 * Enables DatePicker components and manages / validates form state
 * Note: Dates have the toJSON method, which will call toISOString internally
 * when stringifying it, and transforming it back to a Date Object when parsing
 */
export const DatePickerForm = (props: DatePickerFormProps) => {
  const {
    hasError,
    handleChange,
    path,
    data,
    fields,
    disableDays,
    isRequired,
    id
  } = props

  const format = getLocaleDateString(navigator.language)

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substring(
    0,
    navigator.language.indexOf('-')
  )

  // set locale. Default to de
  const locale = (
    ['de', 'en', 'fr'].includes(localeDetected) ? localeDetected : 'de'
  ) as 'de' | 'en' | 'fr'

  const { t } = useTranslation()

  const dateSchema = useMemo(
    () => createDateSchema(t, format, { endDate: !!fields.endDate }),
    [t, format, fields.endDate]
  )

  // get initial values for form reset considering prefilling
  const initialValues = useMemo(() => {
    const initialStart = findInitialValues(
      data?.startDate,
      fields.startDate.prefillByDays,
      disableDays
    )
    const initialEnd = findInitialValues(
      data?.endDate,
      fields.endDate?.prefillByDays,
      disableDays
    )

    const isValid = isBlockDataValid(
      fields,
      isRequired,
      initialStart,
      initialEnd
    )

    return {
      startDate: initialStart,
      endDate: initialEnd,
      _isValid: isValid
    }
  }, [data?.endDate, data?.startDate, disableDays, fields, isRequired])

  // TODO replace this with a better solution
  // setting initial values using useEffect
  useEffect(() => {
    if (!data && initialValues) {
      setTimeout(() => {
        handleChange(path, initialValues)
      }, 200)
    }
  }, [handleChange, path, initialValues, data])

  const { control, resetField } = useFormHandler<DatePickerFormValues>({
    handleChange,
    path,
    fields,
    data: data,
    isRequired,
    formProps: {
      defaultValues: initialValues,
      resolver: yupResolver(dateSchema),
      context: {
        startDate: fields.startDate,
        endDate: fields.endDate,
        disableDays,
        required: isRequired
      }
    }
  })

  // resets default value if it changes
  useResetOnChange(resetField, {
    name: 'startDate',
    watch: fields.startDate.prefillByDays,
    value:
      typeof fields.startDate.prefillByDays === 'number'
        ? findNextAvailableDay(
            add(addDate(), {
              days: fields.startDate.prefillByDays
            }),
            disableDays
          )
        : null
  })

  // resets default value if it changes
  useResetOnChange(resetField, {
    name: 'endDate',
    watch: fields.endDate?.prefillByDays,
    value:
      typeof fields.endDate?.prefillByDays === 'number'
        ? findNextAvailableDay(
            add(addDate(), {
              days: fields.endDate?.prefillByDays
            }),
            disableDays
          )
        : null
  })

  return (
    <MuiPickersUtilsProvider locale={LOCALE_MAP[locale]} utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item sm={fields.endDate ? 6 : 12} xs={12}>
          <FieldDatePicker
            control={control}
            disableDays={disableDays}
            format={format}
            hasError={hasError}
            id={appendStepBlockId(id, 'Start Date')}
            name="startDate"
            path={path}
            required={isRequired}
            {...fields.startDate}
          />
        </Grid>
        {fields.endDate && (
          <Grid item sm={6} xs={12}>
            <FieldDatePicker
              control={control}
              disableDays={disableDays}
              format={format}
              hasError={hasError}
              id={appendStepBlockId(id, 'End Date')}
              name="endDate"
              path={path}
              required={isRequired}
              {...fields.endDate}
            />
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
