import { isMeterReadingControlOptions } from '@epilot/journey-logic-commons'
import type {
  MeterReadingControlOptions,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { MeterReadingForm } from './MeterReadingForm'
import type { MeterReadingFormValues } from './types'
import { isMeterReadingControlData } from './types.guard'

function MeterReadingControl(props: EpilotControlProps) {
  const {
    uischema: { options },
    data,
    path,
    required,
    errors,
    handleChange
  } = props

  const stepBlockId = useStepBlockId(path)

  const { entity_updating_mode } = options as MeterReadingControlOptions

  if (!isMeterReadingControlOptions(options)) {
    console.error(
      'MeterReadingControl -> Invalid uischema.options detected. Passed options are:',
      { options: options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  if (!isMeterReadingControlData(data)) {
    console.error(
      'EnumButtonControl -> Invalid data detected. Current data is:',
      { data }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  // detect language and only get language code before the first dash
  const localeDetected = navigator.language.substr(
    0,
    navigator.language.indexOf('-')
  )
  // set locale. Default to de
  const locale = ['de', 'en', 'fr'].includes(localeDetected)
    ? localeDetected
    : 'de'

  return (
    <MeterReadingForm
      data={data as MeterReadingFormValues}
      fields={
        entity_updating_mode
          ? {
              ...options?.fields,
              maloId: { disabled: true },
              meterId: { disabled: true },
              meterType: { disabled: true }
            }
          : {
              ...options?.fields,
              readingValue: {
                ...options?.fields?.readingValue,
                required: true
              }
            }
      }
      handleChange={handleChange}
      hasError={!!errors}
      id={stepBlockId}
      isRequired={!!required}
      locale={locale}
      path={path}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: MeterReadingControl }) as never
)
