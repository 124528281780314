import { v4 as uuidv4 } from 'uuid'

import type { Step } from '../types'

/**
 * Generates a unique Step ID for a given Step name
 *
 * WARNING: StepIDs cannot contain dots ("."), otherwise they can cause issues with automations
 * due to how paths are traversed over there. Read more {@link https://e-pilot.atlassian.net/browse/STABLE360-6331}
 *
 * @deprecated soon to be removed, please directly use a new UuidV4 instead
 *
 * TODO: {@link https://e-pilot.atlassian.net/browse/ER-2838}
 */
export const generateStepId = (_steps: Step[], _stepName: string) => uuidv4()
