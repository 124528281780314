import { clsx, createStyles, makeStyles } from '@epilot/journey-elements'
import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import { useEffect, useMemo, useRef } from 'react'

import { useSize } from '../../hooks/useSize'

import { useNormalizedSectionContext } from './NormalizedSectionProvider'

type NormalizedSectionContainerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  sectionName:
    | 'tile'
    | 'concordeTile'
    | 'features'
    | 'concordeFeatures'
    | 'description'
    | 'concordeDescription'
    | 'price'
    | 'concordePrice'
    | 'tileBodyBottom'
    | 'title'
    | 'concordeTitle'
    | 'footer'
    | 'concordeFooter'
    | 'concordePriceComponents'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      /**
       * Ensure that bellow xs breakpoint the tile height is not normalized
       */
      [theme.breakpoints.down('xs')]: {
        minHeight: 'initial !important'
      }
    },
    content: {
      minHeight: '100%',
      width: '100%'
    }
  })
)

export const NormalizedSectionContainer = ({
  sectionName,
  className,
  ...props
}: NormalizedSectionContainerProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { height } = useSize(ref)
  const { setElementHeight, maximumHeightMap } = useNormalizedSectionContext()
  const classes = useStyles()

  useEffect(() => {
    if (typeof height === 'number') {
      setElementHeight(sectionName, ref, height)
    }
  }, [height, sectionName, setElementHeight])

  const style = useMemo(
    () => ({ minHeight: maximumHeightMap[sectionName] }),
    [maximumHeightMap, sectionName]
  )

  return (
    <div className={classes.wrapper} style={style}>
      <div {...props} className={clsx(classes.content, className)} ref={ref} />
    </div>
  )
}
