import {
  getAvailableProductIds,
  getJourneyPricesToQuery
} from '@epilot/journey-logic-commons'
import type {
  GetAvailableProductIdsOptions,
  GetJourneyPricesToQueryOptions,
  ProductType
} from '@epilot/journey-logic-commons'
import type { Components } from '@epilot/pricing-client'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import getClient from '../../utils/clients/pricing-client'
import { useConfig } from '../context'

export type CatalogSearchResult = Components.Schemas.CatalogSearchResult
type ComputePriceParams = Components.Schemas.ComputePriceParams

export type ProductPrice = {
  productId: string
  priceId: string
}

const SERVER_KEYS = {
  SEARCH_PRODUCTS_BY_PRICES: 'SEARCH_PRODUCTS_BY_PRICES'
}

export const searchGetAgProviders = async (
  apiUrl: string,
  orgId: string,
  params: {
    productType: ProductType
    postalCode: string
    city: string
    street?: string
    streetNumber?: string
  }
) => {
  return getClient(apiUrl).$searchProviders(
    {
      integrationId: 'getag',
      'X-Epilot-Org-ID': orgId
    },
    {
      type: params.productType,
      postal_code: params.postalCode,
      city: params.city,
      street: params.street,
      street_number: params.streetNumber
    }
  )
}

export const searchGetAgStreets = async (
  apiUrl: string,
  orgId: string,
  params: {
    postalCode: string
    city: string
  }
) => {
  return await getClient(apiUrl).$searchStreets(
    {
      integrationId: 'getag',
      'X-Epilot-Org-ID': orgId
    },
    {
      postal_code: params.postalCode,
      city: params.city
    }
  )
}

export const computeGetAGPrices = async (
  apiUrl: string,
  orgId: string,
  params: ComputePriceParams
) => {
  return getClient(apiUrl).$computePrice(
    {
      integrationId: 'getag',
      'X-Epilot-Org-ID': orgId
    },
    params
  )
}

export async function searchPrices(
  apiUrl: string,
  orgId: string,
  productPriceIds: Array<string>,
  publicToken: string
) {
  const searchQuery = productPriceIds
    .flatMap((id) => [
      /* Find prices with this id */
      `(_id:${id} AND active:true)`,
      /* Find coupons for this price */
      `prices.$relation.entity_id:${id}`
    ])
    .join(' OR ')

  const data = {
    q: searchQuery,
    hydrate: true
  }

  /**
   * @todo Remove assertion of response body type when the API response is typed to include coupons
   */
  return getClient(apiUrl, orgId, publicToken).$searchCatalog(undefined, data)
}

const DEFAULT_SEARCH_PRICES_RESULTS: NonNullable<
  Awaited<ReturnType<typeof searchPrices>>['data']['results']
> = []
const DEFAULT_SEARCH_PRICES_HITS = 0

type UseSearchPricesOptions = GetAvailableProductIdsOptions &
  Pick<GetJourneyPricesToQueryOptions, 'journeyPrices'> & {
    orgId: string
    publicToken: string
  }

export function useSearchPrices({
  orgId,
  journeyPrices,
  crossSellProductIds,
  availabilityProductIds,
  publicToken
}: UseSearchPricesOptions) {
  const { PRICING_API_URL } = useConfig()
  const productPriceIds = useMemo(() => {
    const availableProductIds = getAvailableProductIds({
      crossSellProductIds,
      availabilityProductIds
    })

    const pricesToQuery = getJourneyPricesToQuery({
      journeyPrices,
      availableProductIds
    })

    return extractProductPriceIds(pricesToQuery)
  }, [availabilityProductIds, crossSellProductIds, journeyPrices])

  const queryResult = useQuery(
    [SERVER_KEYS.SEARCH_PRODUCTS_BY_PRICES, orgId, ...productPriceIds],
    async () => {
      if (!orgId || productPriceIds.length === 0) {
        return {
          data: {
            hits: DEFAULT_SEARCH_PRICES_HITS,
            results: DEFAULT_SEARCH_PRICES_RESULTS
          }
        }
      } else {
        return searchPrices(
          PRICING_API_URL,
          orgId,
          productPriceIds,
          publicToken
        )
      }
    }
  )

  return useMemo(
    () => ({
      ...queryResult,
      hits: DEFAULT_SEARCH_PRICES_HITS,
      results: DEFAULT_SEARCH_PRICES_RESULTS,
      ...queryResult.data?.data
    }),
    [queryResult]
  )
}

const deduplicateArray = <T>(array: T[]) => [...new Set(array)]

const extractProductPriceIds = (prices: ProductPrice[]) =>
  deduplicateArray(
    prices
      .flatMap(({ productId, priceId }) => [productId, priceId])
      .filter(Boolean)
  )
