import { CircularProgress, Grid } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useTranslation } from 'react-i18next'

import { useJourneyContext, useStepBlockId } from '../../../utils'
import type {
  MeterEntityWithValidation,
  MeterReadingValidation
} from '../../../utils/clients/entity-client'
import { includeCommon } from '../../../utils/includeCommon'
import { MeterCardDivider } from '../MeterReadingControl/components/MeterCardDivider'
import { MeterCardHeader } from '../MeterReadingControl/components/MeterCardHeader'
import { useGetContracts } from '../MeterReadingControl/useGetContracts'

import { CounterReadingForm } from './CounterReadingForm'
import { MeterReadingOptionalFields } from './MeterReadingOptionalFields'
import type {
  CounterReading,
  MeterReadingDynamicControlDataMeters,
  MeterReadingDynamicControlOptions,
  MeterReadingDynamicFields,
  DynamicMeterReadingFormValues
} from './types'

function MeterReadingDynamicControl(props: EpilotControlProps) {
  const { uischema, data, path, handleChange, errors } = props

  const stepBlockId = useStepBlockId(path)

  const options = uischema.options as MeterReadingDynamicControlOptions
  const { fields } = options
  const { context } = useJourneyContext()
  const { t } = useTranslation()

  const { contracts, isLoading } = useGetContracts({
    shouldCheck: true,
    journeySteps: context.journey.steps,
    stepsHistoryStateArray: context._stepsHistoryStateArray,
    contextEntities: context._contextEntitiesData || {},
    isPreview: context._isPreview
  })

  if (isLoading) {
    return (
      <center>
        <CircularProgress />
      </center>
    )
  }
  // if not contracts where found in the journey, show error
  else if (contracts.length === 0 && !isLoading) {
    return (
      <div>
        {t('meter_reading.contracts_error', 'No contract was selected')}
      </div>
    )
  }

  const onChangeMeterData = (
    meterId: string,
    value: DynamicMeterReadingFormValues
  ) => {
    if (!value.readingDate) {
      value.readingDate = new Date()
    }
    const oldData = (data as MeterReadingDynamicControlDataMeters)?.meters
    const newData = Array.isArray(oldData) ? [...oldData] : []
    const existingIndex = newData.findIndex((m) => m.meterId === meterId)

    if (existingIndex > -1) {
      newData[existingIndex] = value
    } else {
      newData.push(value)
    }

    const totalNumberOfMeters = contracts.reduce(
      (acc, contract) => acc + contract.meters.length,
      0
    )

    handleChange(path, {
      meters: newData,
      _isValid:
        newData.length === totalNumberOfMeters &&
        newData.every((m) => m._isValid)
    })
  }

  const renderMeterReadingForm = (meter: MeterEntityWithValidation) => {
    // if contracts where found in the journey, but no meters were found, show loading
    if (!Array.isArray(meter.counters) || meter.counters.length === 0) {
      return (
        <div>{t('meter_reading.counters_error', 'No counters were found')}</div>
      )
    }

    const existingMeterData = (
      data as MeterReadingDynamicControlDataMeters
    )?.meters?.find((m) => m.meterId === meter._id)

    const readingsData = existingMeterData?.readings || []

    return (
      <>
        <MeterCardHeader slug="meter" title={meter.meter_number} />
        <Grid container spacing={2}>
          {meter.counters.map((counter, i) => {
            const oldReading = meter.validation?.find(
              (r) => r.counter_id === counter._id
            )

            return (
              <>
                <CounterReadingForm
                  counterEntity={counter}
                  handleChange={(value) => {
                    const newReadings = existingMeterData?.readings
                      ? [...existingMeterData.readings]
                      : []

                    const existingIndex = newReadings.findIndex(
                      (cont: CounterReading) =>
                        cont.counterId === value.counterId
                    )

                    if (existingIndex > -1) {
                      newReadings[existingIndex] = value
                    } else {
                      newReadings.push(value)
                    }

                    onChangeMeterData(meter._id, {
                      ...existingMeterData,
                      readings: newReadings,
                      meterId: meter._id,
                      _meter_number: meter.meter_number,
                      _isValid: isMeterDataValid(
                        {
                          ...existingMeterData,
                          readings: newReadings,
                          meterId: meter._id
                        },
                        fields,
                        meter.counters.length,
                        meter.validation
                      )
                    })
                  }}
                  hasError={!!errors}
                  id={stepBlockId}
                  key={i}
                  oldReading={oldReading}
                  value={readingsData.find((r) => r.counterId === counter._id)}
                />
              </>
            )
          })}
          <MeterReadingOptionalFields
            fields={fields}
            handleChange={(value) => {
              onChangeMeterData(meter._id, {
                ...existingMeterData,
                ...value,
                meterId: meter._id,
                _meter_number: meter.meter_number,
                _isValid: isMeterDataValid(
                  {
                    ...existingMeterData,
                    ...value,
                    meterId: meter._id
                  },
                  fields,
                  meter.counters.length,
                  meter.validation
                )
              })
            }}
            hasError={!!errors}
            id={stepBlockId}
            value={existingMeterData as never}
          />
        </Grid>
      </>
    )
  }

  return (
    <>
      {contracts?.map((singleContract, i) => {
        const meters = singleContract.meters

        // if no meters, then display a messaage
        if (
          !Array.isArray(singleContract.meters) ||
          singleContract.meters.length === 0
        ) {
          return (
            <div>
              {t(
                'meter_reading.meters_error',
                'No meters were found for the contract'
              )}
            </div>
          )
        }

        return (
          <div key={'contract' + i}>
            {i > 0 && <MeterCardDivider />}
            {meters.map((meter: MeterEntityWithValidation, index: number) => (
              <>
                {index > 0 && <MeterCardDivider />}
                <MeterCardHeader
                  slug="contract"
                  title={singleContract.contract_name}
                />
                {renderMeterReadingForm(meter)}
              </>
            ))}
          </div>
        )
      })}
    </>
  )
}
export default withJsonFormsControlProps(
  includeCommon({ component: MeterReadingDynamicControl }) as never
)

function isMeterDataValid(
  meterData: DynamicMeterReadingFormValues,
  fields: MeterReadingDynamicFields,
  meterCountersLength?: number,
  meterValidations?: MeterReadingValidation[]
) {
  // if the data is missing meter id, then invalid
  if (!meterData.meterId) {
    return false
  }
  // if any of the required fields are missing, then invalid
  if (fields.readBy?.required && !meterData.readBy) {
    return false
  }
  if (fields.readingDate?.required && !meterData.readingDate) {
    return false
  }
  if (fields.reason?.required && !meterData.reason) {
    return false
  }

  // if there are no readings or not all readings are filled, then invalid
  if (
    !meterData.readings ||
    meterData.readings.length !== meterCountersLength
  ) {
    return false
  }

  // if any of the readings are missing a value, then invalid
  if (meterData.readings.find((r) => !r.value)) {
    return false
  }

  // if any of the readings are outside the min/max range, then invalid
  if (
    meterValidations?.some((validation) => {
      const reading = meterData?.readings?.find(
        (r) => r.counterId === validation.counter_id
      )

      if (!reading) {
        return false
      }

      if (
        typeof validation.min_value === 'number' &&
        reading.value < validation.min_value
      ) {
        return true
      }

      return false
    })
  ) {
    return false
  }

  return true
}
