import { SmallText } from '@epilot/journey-elements'
import type { Control } from 'react-hook-form'
import { useFormState } from 'react-hook-form'

import type { InputCalculatorFormValues } from '../types'

export const ConsumptionFieldsRootErrors = ({
  hasError,
  control
}: {
  hasError: boolean
  control: Control<InputCalculatorFormValues>
}) => {
  const { errors } = useFormState({
    control: control
  })

  return errors?.devices?.root?.ref?.name === 'devices' && hasError ? (
    <SmallText color="error">{errors?.devices?.root?.message}</SmallText>
  ) : null
}
