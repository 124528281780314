import type { SpacingProps, GridProps } from '@epilot/journey-elements'
import { Hidden, StackSpacing, Grid, clsx } from '@epilot/journey-elements'
import type { LayoutProps, Layout, UISchemaElement } from '@jsonforms/core'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import { useMemo } from 'react'

import { IsMobile } from '../../utils/helper'
import { useStickyHeight } from '../../utils/hooks/useStickyHeight'

import {
  getStickyItems,
  stickySorter,
  StickyZone,
  useWrapperStyles
} from './MainContentCartLayout'
import { useMainLinearLayoutStyles } from './styles'

export type MainLinearLayoutOptions = Pick<
  SpacingProps,
  'alignItems' | 'scale' | 'children'
> & {
  spacing?: GridProps['spacing']
}

const MainLinearLayout = ({
  uischema,
  renderers,
  schema,
  path,
  enabled,
  visible
}: LayoutProps) => {
  const { wrapperStyles } = useWrapperStyles()

  const layout = uischema as Layout
  const {
    scale = 3,
    spacing = 4,
    alignItems = 'stretch',
    ...rest
  } = (uischema.options as MainLinearLayoutOptions) || {}

  const sticky: UISchemaElement[] = useMemo(() => {
    const items = getStickyItems(layout.elements)

    items.sort(stickySorter)

    return items
  }, [layout.elements])

  const mobile = IsMobile()

  const { stickyRef, stickyHeight } = useStickyHeight(mobile)
  const classes = useMainLinearLayoutStyles({ stickyHeight })

  return (
    <Hidden xsUp={!visible}>
      <div className={clsx(classes.container, mobile && classes.withPadding)}>
        <div className={wrapperStyles}>
          <Grid container spacing={spacing} {...rest}>
            <Grid item xs={12}>
              <StackSpacing alignItems={alignItems} scale={scale} {...rest}>
                {layout.elements.map((child, index) => {
                  if (child.options?.stickyOnMobile && mobile) {
                    return null
                  } else {
                    return (
                      <JsonFormsDispatch
                        enabled={enabled}
                        key={index}
                        path={path}
                        renderers={renderers}
                        schema={schema}
                        uischema={child}
                      />
                    )
                  }
                })}
              </StackSpacing>
            </Grid>
          </Grid>
        </div>
        {mobile && sticky.length > 0 && (
          <StickyZone ref={stickyRef}>
            {sticky.map((child, index) => (
              <JsonFormsDispatch
                enabled={enabled}
                key={'footer' + index}
                path={path}
                renderers={renderers}
                schema={schema}
                uischema={child}
              />
            ))}
          </StickyZone>
        )}
      </div>
    </Hidden>
  )
}

export default withJsonFormsLayoutProps(MainLinearLayout)
