import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { DragEvent } from 'react'

import { useTheme } from './styles'
import type { Theme } from './styles'

export function IsMobile() {
  const theme = useTheme<Theme>()

  return useMediaQuery(theme.breakpoints.down('sm'))
}

function isObject<T>(v: any): v is T {
  return typeof v === 'object' && v !== null
}

function isDataTransfer(value: any): value is DataTransfer {
  return isObject(value)
}

export function isDragEvent(value: any): value is DragEvent {
  return isObject<DragEvent>(value) && isDataTransfer(value.dataTransfer)
}
