import type { JSONConfiguration } from '@epilot/journey-logic-commons'
import { CONTROL_NAME } from '@epilot/journey-logic-commons'
import isDate from 'lodash/isDate'
import type { ComponentType } from 'react'

import type { EntityAttribute } from '../../../utils/clients/entity-client'
import { AddressControlPure } from '../AddressControl'
import { BooleanControlPure } from '../BooleanControl'
import { DatePickerControlPure } from '../DatePickerControl'
import { EnumAutocompleteControlPure } from '../EnumAutocompleteControl'
import { EnumButtonControlPure } from '../EnumButtonControl'
import { EnumImageButtonControlPure } from '../EnumImageButtonControl'
import { EnumRadioControlPure } from '../EnumRadioControl'
import { MultichoiceControlPure } from '../MultichoiceControl'
import { NumberInputControlPure } from '../NumberInputControl'
import { TextFieldControlPure } from '../TextFieldControl'

type getAttributeControlType = {
  component: ComponentType<any>
  getData?: (v: Record<string, unknown>) => unknown
  setData?: (
    v: unknown | Record<string, unknown>,
    data: Record<string, unknown>
  ) => Record<string, unknown>
  controlId: CONTROL_NAME
  id?: string // Update newer attribute controls to use existing id if applicable
}

export const getAttributeControl = (
  attributeType: EntityAttribute['type'],
  config: JSONConfiguration | undefined
): getAttributeControlType => {
  switch (attributeType) {
    case 'checkbox':
    case 'boolean':
      return {
        component: BooleanControlPure,
        controlId: CONTROL_NAME.BOOLEAN_CONTROL
      }
    case 'string': {
      return {
        component: TextFieldControlPure,
        controlId: CONTROL_NAME.TEXTFIELD_CONTROL
      }
    }
    case 'number':
      return {
        component: NumberInputControlPure,
        getData: (v: Record<string, unknown>) => v?.['numberInput'],
        setData: (v: unknown, data = {}) => ({
          ...data,
          numberInput: v
        }),
        controlId: CONTROL_NAME.NUMBER_INPUT_CONTROL
      }
    case 'multiselect':
      return {
        component: MultichoiceControlPure,
        controlId: CONTROL_NAME.MULTICHOICE_CONTROL
      }
    case 'radio':
    case 'select':
    case 'status': {
      if (config?.uischema?.options?.button) {
        return {
          component: EnumButtonControlPure,
          controlId: CONTROL_NAME.ENUM_CONTROL
        }
      } else if (config?.uischema?.options?.radio) {
        return {
          component: EnumRadioControlPure,
          controlId: CONTROL_NAME.ENUM_CONTROL
        }
      } else if (config?.uischema?.options?.imageButton) {
        return {
          component: EnumImageButtonControlPure,
          controlId: CONTROL_NAME.ENUM_CONTROL
        }
      } else {
        return {
          component: EnumAutocompleteControlPure,
          controlId: CONTROL_NAME.ENUM_CONTROL
        }
      }
    }
    case 'date': {
      return {
        component: DatePickerControlPure,
        getData: (v: Record<string, unknown>) => v?.['startDate'],
        setData: (v: unknown, data = {}) => ({
          ...data,
          startDate:
            isDate(v) || (typeof v === 'string' && Date.parse(v))
              ? new Date(v)
              : null
        }),
        controlId: CONTROL_NAME.DATE_PICKER
      }
    }

    case 'address': {
      return {
        component: AddressControlPure,
        controlId: CONTROL_NAME.ADDRESS_BLOCK,
        getData: (v: Record<string, unknown>) => [
          {
            country: v?.countryCode,
            city: v?.city,
            postal_code: v?.zipCode,
            additional_info: v?.extention,
            street: v?.streetName,
            street_number: v?.houseNumber,
            _tags: v?._tags,
            _id: v?._id,
            _isValid: v?._isValid
          }
        ],
        setData: (v: any, data = {}) => {
          if (Array.isArray(v) && v.length > 0) {
            v = v[0]
          }

          return {
            ...data,
            countryCode: v?.country || 'DE',
            city: v?.city,
            zipCode: v?.postal_code,
            extention: v?.additional_info,
            streetName: v?.street,
            houseNumber: v?.street_number,
            _tags: v?._tags,
            _id: v?._id,
            _isValid: true
          }
        }
      }
    }

    default:
      return { component: () => null, controlId: CONTROL_NAME.CONTROL }
  }
}
