import {
  Collapse,
  InsetSpacing,
  StackSpacing,
  Button,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import type { EpilotTheme } from '@epilot/journey-elements'
import React, { useState } from 'react'

import { ExpandIcon } from '../../../../../../components/Product'
import { useTileContext } from '../../../utils/tile-context-utils'

export const ProductCollapsedData = ({
  children
}: {
  children?: React.ReactNode
}) => {
  const [expanded, setExpanded] = useState(false)

  function toggleExpanded() {
    setExpanded((prevExpanded) => !prevExpanded)
  }
  const { config, actions } = useTileContext()
  const classes = useStyles()

  const detailsTitle = actions.t(config.content.details.title)

  return (
    <>
      <Button
        aria-expanded={expanded}
        aria-label="Open Details"
        className={classes.detailsButton}
        fullWidth
        onClick={toggleExpanded}
        size="large"
      >
        {detailsTitle}
        <ExpandIcon expanded={expanded} />
      </Button>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <InsetSpacing scale={4}>
          <StackSpacing alignItems="stretch" scale={4}>
            {children}
          </StackSpacing>
        </InsetSpacing>
      </Collapse>
    </>
  )
}

const useStyles = makeStyles((theme: EpilotTheme) =>
  createStyles({
    detailsButton: {
      borderRadius: 0,
      backgroundColor: theme.palette?.grey?.[20],
      color: theme.palette?.text?.primary,
      fontSize: 14,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: `min(${theme.shape?.borderRadius}px, 20px)`,
      borderBottomRightRadius: `min(${theme.shape?.borderRadius}px, 20px)`,
      '& > span': { maxWidth: 'fit-content' },
      '&:hover': {
        backgroundColor: theme.palette?.grey?.[20]
      }
    }
  })
)
