import { TileLayoutContextProvider } from '@epilot/concorde-elements'
import { useTheme } from '@epilot/journey-elements'
import type {
  GeneralTileConfiguration,
  ProductTileData,
  SelectionType,
  SelectedProductTile
} from '@epilot/journey-logic-commons'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { FeatureFlags, useFlags, useJourneyContext } from '../../../../../utils'
import { isProductTileSelected } from '../../utils/product-tiles-control-utils'
import { CatalogProductTile } from '../logicComponents/CatalogProductTile'
import type { CatalogProductTilesControlProps } from '../logicComponents/CatalogProductTilesControl'

import { NormalizedSectionProvider } from './NormalizedSectionProvider'
import { useProductTilesControl } from './styles'

interface CatalogProductTilesControlHtmlProps {
  generalTileConfiguration: GeneralTileConfiguration
  productTiles: ProductTileData[]
  selectedProductTiles?: SelectedProductTile | SelectedProductTile[]
  selectionType?: SelectionType
  t: CatalogProductTilesControlProps['t']
  onChange?: CatalogProductTilesControlProps['onChange']
  id: CatalogProductTilesControlProps['id']
}

export const CatalogProductTilesControlHtml = ({
  generalTileConfiguration,
  productTiles,
  selectedProductTiles,
  selectionType,
  t,
  onChange,
  id
}: CatalogProductTilesControlHtmlProps) => {
  const classes = useProductTilesControl({
    justifyContent: generalTileConfiguration.justifyContent
  })
  const theme = useTheme()
  const isScreenMediumOrLarger = useMediaQuery(theme.breakpoints.up('sm'))
  const { context: journeyContext } = useJourneyContext()
  const useNewDesign = journeyContext.journey.settings?.useNewDesign

  const flags = useFlags()

  const tileVariantsEnabled = Boolean(flags[FeatureFlags.PRODUCT_TILE_LAYOUT])

  const areProductsWithFeatures = productTiles.some(
    ({ product }) => product.feature?.length
  )

  const renderProductTiles = productTiles.map(
    ({
      product,
      price,
      isGetAgPrice,
      config,
      isFeatured,
      featuredLabel,
      featuredColor,
      featuredLabelColor,
      coupons
    }) => (
      <CatalogProductTile
        config={{
          ...config,
          isSelected: isProductTileSelected(
            { product, price },
            selectedProductTiles,
            selectionType
          ),
          selectionType,
          generalTileConfiguration,
          tileControlConfiguration: {
            screenSizeIsSmall: !isScreenMediumOrLarger
          },
          isGetAgPrice: isGetAgPrice,
          isFeatured: isFeatured,
          featuredLabel: featuredLabel,
          featuredColor,
          featuredLabelColor,
          showFeaturesSection: areProductsWithFeatures,
          coupons
        }}
        coupons={coupons}
        id={id}
        key={`${product._id}_${price._id}`}
        onChange={onChange}
        price={price}
        product={product}
        selectedProductTile={selectedProductTiles}
        t={t}
        tileVariantsEnabled={tileVariantsEnabled}
      />
    )
  )

  return (
    <div className={classes.container}>
      {/* Only enable tile variants for concorde elements */}
      {tileVariantsEnabled && useNewDesign ? (
        <TileLayoutContextProvider>
          {renderProductTiles}
        </TileLayoutContextProvider>
      ) : (
        <NormalizedSectionProvider>
          {renderProductTiles}
        </NormalizedSectionProvider>
      )}
    </div>
  )
}
