import classes from '../../styles/ProductCTA.module.scss'

import { ProductAction } from './ProductAction'
import { ProductQuantityStepper } from './ProductQuantityStepper'

export const ProductCTA = () => {
  return (
    <div className={classes.actionsContainer}>
      <ProductQuantityStepper />
      <ProductAction />
    </div>
  )
}
