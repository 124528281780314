import { BodyText, createStyles, makeStyles } from '@epilot/journey-elements'

import { SchemaIcon } from '../../../../components/SchemaIcon'

const useStyles = makeStyles((theme) =>
  createStyles({
    headerStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      marginTop: 4
    },
    iconContainer: {
      height: 36,
      width: 36,
      minWidth: 36,
      minHeight: 36,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      alignItems: 'center',
      marginRight: 8
    }
  })
)

type AttributesHeaderProps = {
  showIcon?: boolean
  slug: string
  title?: string
}
export function AttributesHeader(props: AttributesHeaderProps) {
  const { title, slug, showIcon } = props
  const styles = useStyles(props)

  return title ? (
    <div className={styles.headerStyle}>
      {showIcon && (
        <span className={styles.iconContainer}>
          <SchemaIcon iconProps={{ fill: 'white' }} schemaSlug={slug} />
        </span>
      )}
      <BodyText>{title || ''}</BodyText>
    </div>
  ) : (
    <>
      {showIcon && (
        <span className={styles.iconContainer}>
          <SchemaIcon iconProps={{ fill: 'white' }} schemaSlug={slug} />
        </span>
      )}
    </>
  )
}
