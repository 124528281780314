import { InputAdornment, TextField, Typography } from '@epilot/journey-elements'
import type { MeterReadingFields } from '@epilot/journey-logic-commons'
import { Controller } from 'react-hook-form'
import type { Control, Path } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { MeterReadingFormValues } from './types'

/**
 * These should be the units from AvailableUnits in
 * packages/blocks-configurators/src/configurators/NumberInputConfigurator/utils.ts
 * @todo Move those to the commons package
 */
const standardUnits = [
  'l',
  'm',
  'm2',
  'cubic-meter',
  'cubic-meter-h',
  'l',
  'ls',
  'a',
  'kva',
  'w',
  'kw',
  'kwh',
  'wp',
  'kwp'
]

interface Props {
  fields: MeterReadingFields
  label: string
  placeholder: string
  control: Control<MeterReadingFormValues>
  name: Path<MeterReadingFormValues>
  required?: boolean
  hasError: boolean
  id: string
}

export const FieldNumberInput = (props: Props) => {
  const { t } = useTranslation()
  const { fields, label, placeholder, control, name, required, hasError, id } =
    props

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <TextField
            InputLabelProps={{ htmlFor: id }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {fields.readingValue.unit?.show && (
                    <Typography variant="h6">
                      {
                        // this is complex because not all units labels are pre defined by epilot
                        t(
                          fields?.readingValue?.unit?.label
                            ? standardUnits.includes(
                                fields?.readingValue?.unit?.label
                              )
                              ? `units.${fields?.readingValue?.unit?.label}`
                              : fields?.readingValue?.unit?.label
                            : ''
                        )
                      }
                    </Typography>
                  )}
                </InputAdornment>
              )
            }}
            error={hasError && Boolean(fieldState.error)}
            fullWidth
            id={id}
            inputProps={{ inputMode: 'decimal' }}
            label={label}
            onChange={(e) => {
              field.onChange(e.target.value ? parseInt(e.target.value) : null)
            }}
            placeholder={placeholder}
            required={true}
            value={field.value}
          />
        )
      }}
      rules={{
        required
      }}
    />
  )
}
