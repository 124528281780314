/**
 * Returns a date
 * default date = now, with hours set to midnight.
 * Helps to mimic DatePicker component as it sets the hours to midnight as well
 */
export const addDate = (config?: {
  date?: Date
  hour?: number
  minute?: number
  second?: number
}) => {
  const { date, hour = 0, minute = 0, second = 0 } = config || {}

  if (date) {
    return new Date(date.setHours(hour, minute, second, 0))
  }

  return new Date(new Date().setHours(hour, minute, second, 0))
}
