import {
  InputAdornment,
  TextField,
  DebouncedTextField,
  Typography,
  AutocompleteX
} from '@epilot/journey-elements'
import type { TextFieldProps } from '@epilot/journey-elements'
import type { NumberSuggestion } from '@epilot/journey-logic-commons'
import React from 'react'
import type {
  Control,
  ControllerFieldState,
  ControllerRenderProps
} from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { MaterialSymbol } from '../../../../components/MaterialSymbol'
import type { NumberInputControlOptions, NumberInputFormValues } from '../types'
import { formatNumberToStr, NUMBER_INPUT_FIELD_NAME } from '../utils'

import { useStyles } from './styles'

/**
 * These should be the units from AvailableUnits in
 * packages/blocks-configurators/src/configurators/NumberInputConfigurator/utils.ts
 * @todo Move those to the commons package
 */
const standardUnits = [
  'l',
  'm',
  'm2',
  'cubic-meter',
  'cubic-meter-h',
  'l',
  'ls',
  'a',
  'kva',
  'w',
  'kw',
  'kwh',
  'wp',
  'kwp',
  'percent',
  'v',
  'euro',
  'c'
]

export type PureNumberInputProps = {
  hasError: boolean
  control: Control<NumberInputFormValues, object>
  placeholder: string
  path: string
  fields: NumberInputControlOptions['fields']
  isRequired: boolean
  suggestions: NumberSuggestion[]
  input_icon: NumberInputControlOptions['input_icon']
  id: string
}

export function PureNumberInput({
  hasError,
  control,
  placeholder,
  fields,
  isRequired,
  suggestions,
  input_icon,
  id
}: PureNumberInputProps) {
  const { t } = useTranslation()

  const classes = useStyles()

  // this is complex because not all units labels are pre defined by epilot
  const unitLabel = t(
    fields.numberInput.unit?.label &&
      fields.numberInput.unit?.label !== 'generic'
      ? standardUnits.includes(fields.numberInput.unit?.label)
        ? `units.${fields.numberInput.unit?.label}`
        : fields.numberInput.unit?.label
      : ''
  )

  const getHelperText = (fieldState: ControllerFieldState) =>
    hasError && fieldState.invalid !== undefined
      ? fields.numberInput.errorMessage ||
        fieldState.error?.message ||
        (fieldState.error?.type === 'decimalParts'
          ? t('field_number_input_invalid_format', {
              format: placeholder
            })
          : fieldState.error?.type === 'range'
            ? typeof fields.numberInput.range?.max === 'number'
              ? t('field_number_input_invalid_range', {
                  min:
                    typeof fields.numberInput.range?.min === 'number'
                      ? formatNumberToStr(fields.numberInput.range?.min)
                      : '0',
                  max: formatNumberToStr(fields.numberInput.range?.max),
                  unit: fields.numberInput.unit?.show ? unitLabel : ''
                })
              : t('field_number_input_invalid_range_min', {
                  min:
                    typeof fields.numberInput.range?.min === 'number'
                      ? formatNumberToStr(fields.numberInput.range?.min)
                      : '0',
                  unit: fields.numberInput.unit?.show ? unitLabel : ''
                })
            : t('field_required'))
      : ''

  const getEndAdornment = (endAdornment?: JSX.Element) => (
    <InputAdornment className={classes.adornmentContainer} position="end">
      {fields.numberInput.unit?.show && (
        <Typography className={classes.numberInput} variant="h6">
          {unitLabel}
        </Typography>
      )}
      {
        // this is important as MUI4 autocomplete is passing its own endAdornment, therefore we want its children without the wrapper
        endAdornment && React.cloneElement(endAdornment).props.children
      }
    </InputAdornment>
  )

  const StartAdornment = input_icon ? (
    <InputAdornment className={classes.inputAdornment} position="start">
      <MaterialSymbol {...input_icon} />
    </InputAdornment>
  ) : (
    <></>
  )

  const getPlaceholder = fields.numberInput.format?.show
    ? placeholder
    : fields.numberInput.placeholder

  const getInput = (
    field: ControllerRenderProps<NumberInputFormValues, 'numberInput'>,
    fieldState: ControllerFieldState
  ) => {
    const textfieldProps: TextFieldProps = {
      InputLabelProps: { htmlFor: id },
      fullWidth: true,
      id: id
    }

    const textFieldLabel = fields.numberInput.label

    const { onChange, value, ...rest } = field

    return suggestions.length > 0 ? (
      <AutocompleteX
        classes={{ paper: classes.paper }}
        freeSolo
        inputValue={value || ''}
        multiple={false}
        onChange={(_, newValue) => onChange(newValue)}
        onInputChange={(_, newValue) => onChange(newValue)}
        options={suggestions.map((e) => e.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: getEndAdornment(
                params.InputProps.endAdornment as JSX.Element
              ),
              startAdornment: StartAdornment
            }}
            error={hasError}
            helperText={getHelperText(fieldState)}
            inputProps={{
              ...params.inputProps,
              inputMode: 'decimal'
            }}
            label={textFieldLabel}
            placeholder={getPlaceholder}
            required={isRequired}
          />
        )}
        renderOption={(v: string) => (
          <>{suggestions.find((e) => v === e.value)?.label}</>
        )}
        value={value}
        {...(textfieldProps as {})}
        {...(rest as {})}
      />
    ) : (
      <DebouncedTextField
        InputProps={{
          endAdornment: getEndAdornment(),
          startAdornment: StartAdornment
        }}
        className={classes.inputContainer}
        error={hasError}
        helperText={getHelperText(fieldState)}
        inputProps={{
          inputMode: 'decimal'
        }}
        label={textFieldLabel}
        onChange={(e) => onChange(e.target.value.trim())}
        placeholder={getPlaceholder}
        required={isRequired}
        value={value}
        {...textfieldProps}
        {...rest}
      />
    )
  }

  return (
    <Controller
      control={control}
      defaultValue={fields.numberInput.defaultValue}
      name={NUMBER_INPUT_FIELD_NAME}
      render={({ field, fieldState }) => {
        return getInput(field, fieldState)
      }}
      rules={{
        required: isRequired,
        pattern: {
          value: /^[0-9]+([.,][0-9]+)?$/,
          message: t('field_number_input_invalid_digits')
        },
        validate: {
          range: (value) => {
            if (!value) return true
            const parsedValue = parseFloat(value.replace(',', '.'))

            if (
              typeof fields.numberInput.range?.min === 'number' &&
              parsedValue < fields.numberInput.range?.min
            ) {
              return false
            }

            if (
              typeof fields.numberInput.range?.max === 'number' &&
              parsedValue > fields.numberInput.range?.max
            ) {
              return false
            }

            return true
          },
          decimalParts: (value) => {
            // dont validate further if no value or if validate option is disabled
            if (!fields.numberInput.format?.validate || !value) return true

            // accepts decimal numbers with or without decimal point (, or .)
            const parts = value.includes(',')
              ? value.split(',')
              : value.split('.')

            if (
              fields.numberInput.format?.digitsBeforeDecimalPoint !=
                parts[0].length ||
              fields.numberInput.format?.decimalPlaces !=
                (parts[1] ? parts[1].length : 0)
            ) {
              return false
            }

            return true
          }
        }
      }}
    />
  )
}
