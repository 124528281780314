import type { IconConfig } from '@epilot/journey-logic-commons'
import { clsx } from '@epilot/journey-logic-commons'
import { useTheme } from '@material-ui/core'
import type { ComponentProps } from 'react'
import { useMemo } from 'react'

type MaterialSymbolProps = IconConfig & ComponentProps<'span'>

export function MaterialSymbol(props: MaterialSymbolProps) {
  const { name, settings, color, className, style } = props
  const theme = useTheme()

  const colorStyle = useMemo(() => {
    switch (color) {
      case 'primary':
        return { color: theme.palette?.primary.main }
      case 'secondary':
        return { color: theme.palette?.secondary.main }
      case 'error':
        return { color: theme.palette?.error.main }
      default:
        return undefined
    }
  }, [theme, color])

  const settingsStyle = useMemo(
    () => ({
      fontVariationSettings: `'FILL' ${settings?.fill ? 1 : 0}, 'wght' ${
        settings?.weight || '400'
      }, 'GRAD' ${settings?.grade || 0}, 'opsz' ${settings?.size || 24}`,
      fontSize: settings?.size || 24
    }),
    [settings?.fill, settings?.grade, settings?.size, settings?.weight]
  )

  const iconsVariantClass = `material-symbols-${settings?.variant || 'sharp'}`

  return (
    <span
      className={clsx(iconsVariantClass, className)}
      style={{ ...colorStyle, ...settingsStyle, ...style }}
    >
      {name}
    </span>
  )
}
