/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import type { InputCalculatorControlData } from './types'

export function isInputCalculatorControlData(
  obj: any,
  _argumentName?: string
): obj is InputCalculatorControlData {
  return (
    typeof obj === 'undefined' ||
    obj === null ||
    (((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
      (typeof obj.numberInput === 'undefined' ||
        typeof obj.numberInput === 'string') &&
      (typeof obj.numberUnit === 'undefined' ||
        typeof obj.numberUnit === 'string') &&
      (typeof obj.frequencyUnit === 'undefined' ||
        typeof obj.frequencyUnit === 'string') &&
      (typeof obj.devices === 'undefined' ||
        (Array.isArray(obj.devices) &&
          obj.devices.every(
            (e: any) =>
              ((e !== null && typeof e === 'object') ||
                typeof e === 'function') &&
              (e.name === null || typeof e.name === 'string') &&
              typeof e.quantity === 'string' &&
              typeof e.unitaryConsumption === 'string' &&
              (typeof e.consumption === 'undefined' ||
                typeof e.consumption === 'string')
          ))) &&
      (typeof obj._isValid === 'undefined' ||
        obj._isValid === false ||
        obj._isValid === true))
  )
}
