export function formatNumberInUserLocale(value: string, useGrouping?: boolean) {
  const cleanedValue = value.replace(/[^\d,.]/g, '')
  const number = parseFloat(cleanedValue.replace(',', '.'))

  if (isNaN(number)) return value

  return new Intl.NumberFormat(window.navigator.language, {
    useGrouping: !!useGrouping
  }).format(number)
}
