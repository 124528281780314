import type {
  OptionalPriceComponentBlockMapping,
  Step,
  AvailabilityCheckControlData,
  Price,
  CompositePrice,
  InjectedAddressData
} from '@epilot/journey-logic-commons'

import type { JourneyContextValue } from '../../../../utils'
import type { AddressControlData } from '../../AddressControl/types'
import type { ProductSelection } from '../types'

type InjectAddressSettings = {
  relatedBlock?: string // format: stepId/blockName
}

export const getInjectedAddressData = (
  context: JourneyContextValue,
  options: Record<string, unknown> | undefined
): InjectedAddressData | undefined => {
  const relatedBlock = (
    options?.['injectAddressSettings'] as InjectAddressSettings
  )?.relatedBlock

  if (!relatedBlock) {
    return undefined
  }

  const [stepId, blockName] = relatedBlock.split('/')

  if (!stepId || !blockName) {
    return undefined
  }

  const {
    _stepsStateArray,
    journey: { steps }
  } = context

  const relatedStepIndex = steps.findIndex(
    (step: Step) => step.stepId === stepId
  )

  if (relatedStepIndex < 0) {
    return undefined
  }

  const relatedBlockState = _stepsStateArray[relatedStepIndex]?.[
    blockName
  ] as AvailabilityCheckControlData & AddressControlData

  return {
    city: relatedBlockState?.city,
    countryCode: relatedBlockState?.countryCode || 'DE',
    zipCode: relatedBlockState?.zipCode,
    streetName: relatedBlockState?.streetName,
    streetNumber:
      relatedBlockState?.houseNumber || relatedBlockState?.streetNumber
  }
}

export const DEFAULT_OPTIONAL_PRICE_COMPONENT_MAPPINGS: OptionalPriceComponentBlockMapping[] =
  []

/**
 * Type guard, allows us to narrow down the type
 * of the price to a composite price
 */
export const isCompositePriceEntity = (
  price: Price | CompositePrice
): price is CompositePrice => Boolean(price.is_composite_price)

type Filter = {
  [key: string]: ProductSelection[] | ProductSelection
}

export const getAllCrossSellProducts = (filter: Filter): string[] => {
  return Object.keys(filter).reduce(
    (filterAcc: string[], filterKey: string) => {
      // depending on selectionOption filter entry can be an array or not
      const filterEntry = Array.isArray(filter[filterKey])
        ? filter[filterKey]
        : [filter[filterKey]]

      const blockCrossSellProducts = (filterEntry as ProductSelection[]).reduce(
        (productIds: string[], productSelection: ProductSelection) => {
          const crossSellableProducts =
            productSelection.product?.selectionMetadata?.selectedProduct
              .cross_sellable_products
          const crossSellableProductIds =
            Array.isArray(crossSellableProducts) &&
            crossSellableProducts.map(({ _id }: { _id: string }) => _id)

          if (!crossSellableProductIds) {
            return productIds
          }

          return [...productIds, ...crossSellableProductIds]
        },
        []
      )

      if (!blockCrossSellProducts) {
        return filterAcc
      }

      return [...filterAcc, ...blockCrossSellProducts]
    },
    []
  )
}
