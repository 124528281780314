import { H2, Switch } from '@epilot/journey-elements'
import {
  convertToAddressSuggestionsSourceType,
  appendStepBlockId
} from '@epilot/journey-logic-commons'
import type { Address } from '@epilot/journey-logic-commons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddressFinderMap } from '../../../../components/AddressFinderMap'
import { useFormHandler } from '../../../../utils/hooks/forms/useFormHandler'
import { useResetFieldsIfOneChanged } from '../../../../utils/hooks/forms/useResetFieldsIfOneChanged'
import { useResetOnNotEqual } from '../../../../utils/hooks/forms/useResetOnNotEqual'
import type { AddressFormProps } from '../types'
import type { WithDataInjectionProps } from '../withDataInjection'

import { ListedAddressForm } from './ListedAddressForm'
import { useStyles } from './styles'
import { UnlistedAddressForm } from './UnlistedAddressForm'

export const AddressForm = (
  props: AddressFormProps & WithDataInjectionProps
): ReactElement => {
  const {
    addressSuggestionsSource,
    handleChange,
    path,
    hasError,
    unlistedFields,
    fields,
    data,
    label,
    isRequired,
    acceptSuggestedOnly = true,
    injectedData,
    supportUnlisted,
    countryAddressSettings: journeyAddressSettings,
    googleMapsIntegrationOptions,
    isInjectAddressEnabled,
    id
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const addressSuggestionsOnly =
    (journeyAddressSettings?.enableAutoComplete &&
      !journeyAddressSettings.enableFreeText) ??
    acceptSuggestedOnly

  const disableAddressSuggestions = !(
    journeyAddressSettings?.enableAutoComplete ?? true
  )
  const addressFreeText = journeyAddressSettings?.enableFreeText
  const addressCountryCode = journeyAddressSettings?.countryCode || 'DE' // this ensures that the address format validation will always work
  const [addressDown, setAddressDown] = useState<boolean>(false)

  const [isResetForbidden, setIsResetForbidden] = useState(false)

  const { control, fieldValues, setValue, register, resetField } =
    useFormHandler<Address>({
      handleChange: (path: string, address: Address | null | undefined) => {
        // if no address values, pass data 1to1
        if (!address) return handleChange(path, address)

        // go through all keys and check if its value is empty. If so, remove it from the object
        Object.keys(address).forEach((key) => {
          if (
            key in address &&
            !address[key as keyof Address] &&
            typeof address[key as keyof Address] !== 'boolean'
          ) {
            delete address[key as keyof Address]
          }
        })

        // check if countryCode is the only remaining item in address. If so, pass undefined as this is not valid
        if (
          'countryCode' in address &&
          (Object.keys(address).length === 1 ||
            (Object.keys(address).length === 2 && '_isValid' in address))
        ) {
          return setTimeout(() => handleChange(path, undefined), 50)
        }

        // pass address values
        return setTimeout(() => handleChange(path, address), 50)
      },
      fields,
      path,
      data,
      isRequired,
      formProps: {
        defaultValues: {
          countryCode: addressCountryCode,
          ...data
        }
      }
    })

  const [isUnlisted, setIsUnlisted] = useState<boolean>(
    !!fieldValues?.plotArea || !!fieldValues.plotOfLand || false
  )

  useResetOnNotEqual(resetField, {
    name: 'companyName',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.companyName?.defaultValue || '',
    match: fields.companyName?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registerNumber',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registerNumber?.defaultValue || '',
    match: fields.registerNumber?.showIf
  })

  useResetOnNotEqual(resetField, {
    name: 'registryCourt',
    watch: { name: 'customerType', value: fieldValues.customerType },
    value: fields.registryCourt?.defaultValue || '',
    match: fields.registryCourt?.showIf
  })

  useResetFieldsIfOneChanged(resetField, {
    name: 'zipCode',
    watch: fieldValues?.zipCode,
    isResetForbidden,
    fieldsToReset: [
      { name: 'streetName', value: '' },
      { name: 'houseNumber', value: '' },
      { name: 'suburb', value: '' },
      { name: 'extention', value: '' },
      { name: 'coordinates', value: '' },
      { name: 'plotArea', value: '' },
      { name: 'plotOfLand', value: '' }
    ]
  })

  const commonFormProps = {
    acceptSuggestedOnly: !addressDown && addressSuggestionsOnly,
    addressCountryCode,
    addressFreeText,
    addressSuggestionsSource: convertToAddressSuggestionsSourceType(
      addressSuggestionsSource
    ),
    disableAddressSuggestions,
    hasError,
    injectedData,
    path,
    setAddressDown
  }

  const reactHookFormProps = {
    fieldValues,
    control,
    register,
    resetField,
    setValue,
    fields
  }

  const addressFinderData = isInjectAddressEnabled
    ? {
        zipCode: injectedData?.zipCode || fieldValues?.zipCode,
        city: injectedData?.city || fieldValues?.city,
        streetName: injectedData?.streetName || fieldValues?.streetName,
        houseNumber: injectedData?.houseNumber || fieldValues?.houseNumber,
        suburb: injectedData?.suburb || fieldValues?.suburb,
        coordinates: injectedData?.coordinates || fieldValues?.coordinates,
        plotOfLand: fieldValues?.plotOfLand,
        plotArea: fieldValues?.plotArea
      }
    : {
        zipCode: fieldValues?.zipCode,
        city: fieldValues?.city,
        streetName: fieldValues?.streetName,
        houseNumber: fieldValues?.houseNumber,
        suburb: fieldValues?.suburb,
        coordinates: fieldValues?.coordinates,
        plotOfLand: fieldValues?.plotOfLand,
        plotArea: fieldValues?.plotArea
      }

  return (
    <>
      {label && <H2>{label}</H2>}
      {supportUnlisted && (
        <div className={classes.switchContainer}>
          <Switch
            checked={isUnlisted}
            id={appendStepBlockId(id, 'Address is unlisted')}
            label={t('Address is unlisted')}
            onChange={(_e, v) => {
              isInjectAddressEnabled &&
                setValue('zipCode', injectedData?.zipCode)
              setValue(
                'streetName',
                isUnlisted && isInjectAddressEnabled
                  ? injectedData?.streetName
                  : ''
              )
              setValue(
                'houseNumber',
                isUnlisted && isInjectAddressEnabled
                  ? injectedData?.houseNumber
                  : ''
              )
              setValue('plotOfLand', '')
              setValue('plotArea', '')
              setValue(
                'coordinates',
                isUnlisted && isInjectAddressEnabled
                  ? injectedData?.coordinates
                  : ''
              )
              setIsUnlisted(v)
            }}
          />
        </div>
      )}
      {!isUnlisted && (
        <ListedAddressForm
          {...commonFormProps}
          {...reactHookFormProps}
          id={id}
          keepAutoCompleteClosed={
            googleMapsIntegrationOptions?.isRepositioningAllowed
          }
        />
      )}

      {isUnlisted && (
        <UnlistedAddressForm
          id={id}
          unlistedFields={unlistedFields}
          {...commonFormProps}
          {...reactHookFormProps}
          keepAutoCompleteClosed={
            googleMapsIntegrationOptions?.isRepositioningAllowed
          }
        />
      )}
      {googleMapsIntegrationOptions?.isGoogleMapsEnabled && (
        <AddressFinderMap
          addressData={addressFinderData}
          hideMapIfUnlisted={
            isUnlisted && !googleMapsIntegrationOptions.isRepositioningAllowed
          }
          houseNumberKey="houseNumber"
          id={appendStepBlockId(id, 'Google Maps House Number')}
          isPinReset={isUnlisted}
          isRepositioningAllowed={
            googleMapsIntegrationOptions?.isRepositioningAllowed
          }
          isResetForbidden={isResetForbidden}
          setIsResetForbidden={setIsResetForbidden}
          setValue={setValue}
        />
      )}
    </>
  )
}
