import { Hidden } from '@epilot/journey-elements'
import {
  isPvRoofPlannerOptions,
  appendStepBlockId
} from '@epilot/journey-logic-commons'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'

import { Map } from './components/Map'

function PvRoofPlannerControl({
  uischema,
  visible,
  path,
  handleChange
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)

  if (!isPvRoofPlannerOptions(uischema.options)) {
    console.error(
      'PvRoofPlannerControl -> Invalid uischema.options detected. Passed options are:',
      { options: uischema.options }
    )

    return <div>Invalid Block. Check console for more details</div>
  }

  return (
    <Hidden xsUp={!visible}>
      <Map
        handleChange={handleChange}
        id={appendStepBlockId(stepBlockId, 'Google Maps')}
        panelLifetimeYears={uischema?.options?.panelLifetimeYears}
        path={path}
        relatedAvailabilityBlock={uischema?.options?.relatedAvailabilityBlock}
        relatedNumberBlock={uischema?.options?.relatedNumberBlock}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: PvRoofPlannerControl
  }) as any
)
