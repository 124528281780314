import { makeStyles, createStyles, EnumControl } from '@epilot/journey-elements'
import type { FieldOption } from '@epilot/journey-logic-commons'
import type {
  Control,
  Path,
  PathValue,
  UseControllerProps,
  FieldValues
} from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type FieldToggleButtonProps<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  hasError: boolean
  defaultValue?: PathValue<T, Path<T>>
  rules?: UseControllerProps['rules']
  options: string[]
  optionsLabels?: string[]
  id?: string
} & FieldOption

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiToggleButtonGroup-root': {
        marginTop: 0
      }
    }
  })
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldToggleButton = <T extends Record<string, any>>(
  props: FieldToggleButtonProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    errorMessage,
    defaultValue,
    options,
    optionsLabels,
    required,
    rules,
    id
  } = props

  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <div className={classes.root}>
            <EnumControl
              errorMessage={
                hasError && fieldState.invalid
                  ? errorMessage || t('field_required')
                  : undefined
              }
              id={id}
              label={label}
              options={options}
              optionsLabels={optionsLabels}
              required={required}
              uiType="toggle"
              {...field}
            />
          </div>
        )
      }}
      rules={{
        required,
        ...(rules && { rules })
      }}
    />
  )
}
