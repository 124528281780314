import type { Step } from '../../..'
import { isNestedUiSchema } from '../../..'

import { findBlockUischema } from './findBlockUischema'
import type { Optional, StepExtended } from './types'

/**
 * Removes a block inside a step and returns the updated step, or undefined if the block was not found
 * @param items the step used for the delete operation, and potential logics, rules, etc. to take into consideration
 * @param blockName the current block name used to be removed
 * @returns updated step
 */
export const removeBlockByName = (
  { step }: { step: Optional<StepExtended, 'stepIndex'> },
  blockName: string
): Step => {
  const { stepIndex: _si, ...stepCurrent } = step
  const { schema: schemaCurrent, uischema: uischemaCurrent } = stepCurrent
  const schema = { ...schemaCurrent }
  let uischema: StepExtended['uischema'] | undefined = undefined

  // remove property if given
  if (schemaCurrent.properties && blockName in schemaCurrent.properties) {
    const { [blockName]: _omit, ...properties } = schemaCurrent.properties

    schema['properties'] = properties
  }

  // remove from required list if included
  const required = schema.required?.filter((item) => item !== blockName)

  if (!isNestedUiSchema(uischemaCurrent)) {
    const item = findBlockUischema(
      uischemaCurrent,
      { name: blockName },
      { representBy: 'full' },
      'remove'
    )

    uischema = item.uischema
  } else if (isNestedUiSchema(uischemaCurrent)) {
    const item = findBlockUischema(
      uischemaCurrent,
      { name: blockName },
      { representBy: 'full' },
      'remove'
    )

    uischema = item.uischema
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'Error when attempting to remove uischema. Uischema is not of expected type'
    )

    return stepCurrent
  }

  return {
    ...stepCurrent,
    uischema,
    schema: {
      ...schema,
      required
    }
  }
}
