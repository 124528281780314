import type { SelectedProductTile, SelectionType } from '../types'

export const getNewQuantity = (
  { minValue, maxValue }: { minValue: number; maxValue?: number },
  quantity: number
) => Math.min(maxValue ?? Infinity, Math.max(minValue, quantity))

export function getDefaultQuantity({
  selectionType = 'single',
  selectedProductTile,
  minValue,
  productId,
  priceId
}: {
  selectionType: SelectionType | undefined
  selectedProductTile?: SelectedProductTile | SelectedProductTile[]
  minValue?: number
  productId?: string
  priceId?: string
}) {
  const defaultQuantity = minValue ?? 0

  if (selectedProductTile) {
    if (selectionType === 'single') {
      return (selectedProductTile as SelectedProductTile).quantity
    } else {
      const selectedProductTiles = selectedProductTile
        ? (selectedProductTile as SelectedProductTile[])
        : []

      const currentSelectedProductTile = selectedProductTiles.find(
        (productTile) =>
          productTile.product.selectedProductId === productId &&
          productTile.product.selectedPriceId === priceId
      )

      return currentSelectedProductTile?.quantity ?? defaultQuantity
    }
  }

  return defaultQuantity
}
