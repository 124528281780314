import { useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  SmallText
} from '../'

import { useStyles } from './styles'
import type { DigitalSignatureProps } from './types'

export function DigitalSignature({
  onSave,
  picWidth = 300,
  picHeight = 75,
  backgroundColor = '#f0f0f0',
  title = 'Unterschrift',
  scale = 2,
  penColor = 'blue',
  clearText = 'löschen',
  saveText = 'speichern',
  closeText = 'schließen',
  value = '',
  disabled,
  error,
  id,
  ...rest
}: DigitalSignatureProps) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [imageURL, setImageURL] = useState(value)
  const classes = useStyles({ picHeight, picWidth, backgroundColor, error })
  const sigCanvas = useRef<ReactSignatureCanvas>()

  const handleButClick = () => {
    setDialogOpen(true)
  }
  const handleClose = () => {
    setDialogOpen(false)
  }
  const handleClear = () => {
    sigCanvas.current?.clear()
  }
  const handleSave = () => {
    const canvas = sigCanvas.current?.getCanvas()

    if (canvas) {
      // Check if any pixel has any data
      const isEmpty = !canvas
        .getContext('2d')
        ?.getImageData(0, 0, canvas.width, canvas.height)
        .data.some((channel) => channel !== 0)

      if (isEmpty) {
        handleClose()
      } else {
        // get string from canvas
        canvas.toBlob((blob) => {
          const signature = URL.createObjectURL(blob as Blob)

          // set the string in the state
          if (signature) {
            setImageURL(signature)
          }
          // trigger the call back
          if (onSave) {
            onSave(signature)
          }
          // close the dialog
          handleClose()
        })
      }
    }
  }

  return (
    <div {...rest}>
      <Button
        className={classes.button}
        disabled={disabled}
        id={id}
        onClick={handleButClick}
        size="large"
      >
        {imageURL && (
          <img
            alt="signature"
            height={picHeight}
            src={imageURL}
            width={picWidth}
          />
        )}
      </Button>

      <div className={classes.errorContainer}>
        <SmallText color="error">{error}</SmallText>
        <SmallText color={error ? 'error' : 'inherit'}>{title}</SmallText>
      </div>

      <Dialog
        aria-labelledby="customized-dialog-title"
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        open={dialogOpen}
      >
        <Card className={classes.paper}>
          <CardContent className={classes.cardContent}>
            <ReactSignatureCanvas
              canvasProps={{
                width: picWidth * scale,
                height: picHeight * scale,
                className: 'sigCanvas'
              }}
              penColor={penColor}
              ref={(ref) => {
                if (ref) sigCanvas.current = ref
              }}
            />
          </CardContent>
          <CardActionArea>
            <Button color="primary" onClick={handleSave} size="large">
              {saveText}
            </Button>
            <Button color="primary" onClick={handleClear} size="large">
              {clearText}
            </Button>
            <Button color="primary" onClick={handleClose} size="large">
              {closeText}
            </Button>
          </CardActionArea>
        </Card>
      </Dialog>
    </div>
  )
}
