import type { ComponentPropsWithoutRef } from 'react'

const EmptyIllustration = (
  props: ComponentPropsWithoutRef<'svg'> & { hideCount?: boolean }
) => {
  const { hideCount: _hc = false, ...elementProps } = props

  return (
    <svg
      {...elementProps}
      fill="none"
      height="282"
      viewBox="0 0 300 282"
      width="300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="white" fillOpacity="0.01" height="282" width="300" />
      <path
        d="M179.61 163.826C181.961 163.826 183.871 165.733 183.871 168.078C183.871 170.424 181.961 172.33 179.61 172.33H118.538C116.186 172.33 114.277 170.424 114.277 168.078C114.277 166.902 114.753 165.839 115.527 165.074C116.294 164.301 117.359 163.826 118.538 163.826H179.61Z"
        fill="#FFA78E"
      />
      <path
        d="M222.22 138.293V204.008C222.22 208.04 219.038 211.307 215.118 211.307H84.451C80.6866 211.307 77.6045 208.295 77.3638 204.482C99.1582 204.957 197.684 203.2 220.878 134.105C221.084 134.389 221.268 134.679 221.425 134.991C221.936 135.969 222.22 137.082 222.22 138.293Z"
        fill="#FFA78E"
      />
      <path
        d="M78.1369 134.998C79.3157 132.723 81.7026 131.228 84.4508 131.228H92.9726H124.929V142.567H173.219V131.228H205.886H215.118C217.49 131.228 219.592 132.341 220.877 134.105C197.683 203.2 99.158 204.957 77.3628 204.482C77.3557 204.327 77.3486 204.171 77.3486 204.008V138.293C77.3493 137.089 77.6327 135.969 78.1369 134.998ZM183.872 168.078C183.872 165.733 181.961 163.826 179.611 163.826H118.538C117.359 163.826 116.294 164.301 115.527 165.074C114.753 165.839 114.277 166.902 114.277 168.078C114.277 170.424 116.187 172.33 118.538 172.33H179.611C181.961 172.33 183.872 170.424 183.872 168.078Z"
        fill="#F9E1D8"
      />
      <path
        d="M78.1367 134.998L93.135 95.0579C93.0285 95.4689 92.9724 95.9012 92.9724 96.3405V131.228H84.4506C81.7024 131.228 79.3156 132.723 78.1367 134.998Z"
        fill="#EDF2F7"
      />
      <path
        d="M205.736 95.0083L220.969 133.836C220.948 133.928 220.92 134.013 220.877 134.105C219.591 132.341 217.489 131.228 215.118 131.228H205.886V96.3406C205.886 95.88 205.836 95.4335 205.736 95.0083Z"
        fill="#EDF2F7"
      />
      <path
        d="M200.673 90.8342C203.265 90.8342 205.204 92.6271 205.736 95.0082C205.837 95.4334 205.886 95.8799 205.886 96.3405V131.228H173.219V142.567H124.929V131.228H92.9727V96.3405C92.9727 95.9012 93.0288 95.4689 93.1353 95.0578C93.7531 92.6555 96.054 90.8342 98.6673 90.8342H200.673Z"
        fill="#EDF2F7"
      />
      <path
        d="M173.219 131.228H205.886H215.118C217.49 131.228 219.592 132.341 220.876 134.105C221.082 134.389 221.267 134.679 221.424 134.991C221.935 135.969 222.219 137.082 222.219 138.293V204.008C222.219 208.04 219.038 211.307 215.118 211.307H84.4506C80.6861 211.307 77.6041 208.295 77.3633 204.482C77.3562 204.327 77.3491 204.171 77.3491 204.008V138.293C77.3491 137.089 77.6325 135.969 78.1367 134.998C79.3155 132.723 81.7023 131.228 84.4506 131.228H92.9724H124.929"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M92.9722 131.228V96.3405C92.9722 95.9012 93.0283 95.4689 93.1348 95.0578C93.7526 92.6555 96.0535 90.8342 98.6668 90.8342H200.673C203.265 90.8342 205.203 92.6271 205.736 95.0082C205.836 95.4334 205.886 95.8799 205.886 96.3405V131.228"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M78.123 135.041L78.1365 134.998L93.1349 95.0579L93.1569 95.0083"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M221.445 135.041L221.424 134.991L220.969 133.836L205.736 95.0083"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M124.929 131.228V142.567H173.219V131.228"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M183.871 168.078C183.871 170.424 181.961 172.33 179.61 172.33H118.538C116.186 172.33 114.277 170.424 114.277 168.078C114.277 166.902 114.753 165.839 115.527 165.074C116.294 164.301 117.359 163.826 118.538 163.826H179.61C181.96 163.826 183.871 165.733 183.871 168.078Z"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M131.32 156.031H166.828"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M177.835 99.197L149.635 123.766H149.458L121.378 99.197H135.638V48.1733H147.711H161.914V99.197H177.835Z"
        fill="#26BCFC"
      />
      <path
        d="M149.458 123.766L121.378 99.197H135.638V48.1733H147.711"
        fill="#26BCFC"
      />
      <path
        d="M149.458 123.766L121.378 99.197H135.638V48.1733H147.711"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M149.635 123.766L177.835 99.197H161.914V48.1733H147.711"
        fill="#26BCFC"
      />
      <path
        d="M149.635 123.766L177.835 99.197H161.914V48.1733H147.711"
        stroke="#3F455D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      {!props?.hideCount && (
        <>
          <path
            d="M216.715 183.904C222.893 183.904 227.9 189.934 227.9 197.368C227.9 204.809 222.893 210.833 216.715 210.833C210.537 210.833 205.53 204.809 205.53 197.368C205.53 189.934 210.537 183.904 216.715 183.904ZM223.078 197.368C223.078 193.137 220.23 189.708 216.715 189.708C213.2 189.708 210.352 193.137 210.352 197.368C210.352 201.599 213.2 205.036 216.715 205.036C220.23 205.036 223.078 201.599 223.078 197.368Z"
            fill="white"
          />
          <path
            d="M216.715 189.708C220.231 189.708 223.078 193.137 223.078 197.368C223.078 201.599 220.231 205.036 216.715 205.036C213.2 205.036 210.353 201.599 210.353 197.368C210.353 193.137 213.2 189.708 216.715 189.708Z"
            fill="#FF3A3F"
          />
          <path
            d="M216.893 174.691C229.377 174.691 239.497 184.796 239.497 197.255C239.497 209.713 229.377 219.811 216.893 219.811C204.409 219.811 194.289 209.713 194.289 197.255C194.289 184.796 204.409 174.691 216.893 174.691ZM227.9 197.368C227.9 189.934 222.894 183.904 216.716 183.904C210.537 183.904 205.531 189.934 205.531 197.368C205.531 204.809 210.537 210.833 216.716 210.833C222.894 210.833 227.9 204.809 227.9 197.368Z"
            fill="#FF3A3F"
          />
          <path
            d="M239.497 197.255C239.497 209.713 229.377 219.811 216.893 219.811C204.409 219.811 194.289 209.713 194.289 197.255C194.289 184.796 204.409 174.691 216.893 174.691C229.377 174.691 239.497 184.796 239.497 197.255Z"
            stroke="#3F455D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            d="M227.9 197.368C227.9 204.809 222.893 210.833 216.715 210.833C210.537 210.833 205.53 204.809 205.53 197.368C205.53 189.934 210.537 183.904 216.715 183.904C222.893 183.904 227.9 189.934 227.9 197.368Z"
            stroke="#3F455D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            d="M223.078 197.368C223.078 201.599 220.231 205.036 216.715 205.036C213.2 205.036 210.353 201.599 210.353 197.368C210.353 193.137 213.2 189.708 216.715 189.708C220.231 189.708 223.078 193.137 223.078 197.368Z"
            stroke="#3F455D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <path
            d="M217.249 179.404C202.598 179.404 199.772 193.463 199.658 194.059"
            stroke="#FF6A3F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </>
      )}
    </svg>
  )
}

export default EmptyIllustration
