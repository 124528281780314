import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductTitle } from '../TileHeader/ProductTitle'

import classes from './TileSections.module.scss'

export const TileHeadline = () => {
  return (
    <TileLayoutContainer
      className={classes.titleContainer}
      tileLayoutSection="headline"
    >
      <ProductTitle />
    </TileLayoutContainer>
  )
}
