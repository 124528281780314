import { makeStyles } from '@material-ui/core'

import type { Theme } from '../'
import {
  FormControl,
  ToggleButtonGroup,
  SmallText,
  ToggleButton,
  createStyles,
  InputLabel,
  InlineSpacing,
  appendToId
} from '../'

import type { EnumControlProps } from './EnumControl'

export type EnumToggleButtonsControlProps = EnumControlProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 32,
      marginTop: theme.spacing(6)
    },
    errorColor: {
      color: theme.palette.error.main
    },
    stretch: {
      display: 'flex',
      '& button': {
        flex: 1
      }
    }
  })
)

export function EnumToggleButtonsControl({
  errorMessage,
  label,
  onChange,
  options = [],
  optionsLabels,
  value,
  disabled,
  stretch = false,
  required,
  defaultSelection: initial,
  id
}: EnumToggleButtonsControlProps) {
  const labels = optionsLabels ? optionsLabels : options
  const classes = useStyles()

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newvalue: string | null
  ) => {
    onChange(newvalue)
  }

  return (
    <FormControl error={errorMessage ? true : false} fullWidth>
      <InputLabel
        className={errorMessage ? classes.errorColor : ''}
        htmlFor={id}
      >
        {label ? label : ' '}
      </InputLabel>
      <InlineSpacing scale={0.5}>
        <ToggleButtonGroup
          className={`${classes.container} ${stretch ? classes.stretch : ''}`}
          exclusive
          id={id}
          onChange={handleChange}
          value={value || initial}
        >
          {options &&
            options.map((item: string, index: number) => {
              const label = labels[index]
              const buttonId = appendToId(id, label)

              return (
                <ToggleButton
                  disabled={disabled}
                  id={buttonId}
                  key={'toggle' + index}
                  value={item}
                >
                  {label}
                </ToggleButton>
              )
            })}
        </ToggleButtonGroup>
        {required && (
          <SmallText color={errorMessage ? 'error' : 'textSecondary'}>
            *
          </SmallText>
        )}
      </InlineSpacing>

      <SmallText color="error">{errorMessage ? errorMessage : ' '}</SmallText>
    </FormControl>
  )
}
