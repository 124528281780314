/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import type {
  SecondaryActionBarControlOptions,
  SecondaryActionBarOptions
} from './types'

export function isSecondaryActionBarControlOptions(
  obj: any,
  _argumentName?: string
): obj is SecondaryActionBarControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    (typeof obj.optionsLabels === 'undefined' ||
      (Array.isArray(obj.optionsLabels) &&
        obj.optionsLabels.every((e: any) => typeof e === 'string')))
  )
}

export function isSecondaryActionBarOptions(
  obj: any,
  _argumentName?: string
): obj is SecondaryActionBarOptions {
  return Array.isArray(obj) && obj.every((e: any) => typeof e === 'string')
}
