import type { Theme } from '@epilot/journey-elements'
import {
  Icon,
  KeyboardDatePicker,
  createStyles,
  makeStyles
} from '@epilot/journey-elements'
import { addDate } from '@epilot/journey-logic-commons'
import { add } from 'date-fns'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FeatureFlags, useFlags } from '../../utils'
import { useResetOnChange } from '../../utils/hooks/forms/useResetOnChange'

import type { FieldDatePickerProps } from './types'
import {
  addCurrentTimeToDate,
  getClientDateWithIgnoredTZ,
  getOffsetedClientDateFromUTC
} from './utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldDatePicker = <T extends Record<string, any>>(
  props: FieldDatePickerProps<T>
) => {
  const {
    hasError,
    control,
    name,
    label,
    placeholder,
    errorMessage,
    required,
    rules,
    format,
    disableDays,
    limits,
    disableFuture,
    formProps,
    defaultValue: valueBeforeConverting,
    shouldContainTime,
    id
  } = props

  const isDateReworkEnabled = useFlags()[FeatureFlags.JOURNEY_DATE_REWORK]

  const defaultValue = isDateReworkEnabled
    ? valueBeforeConverting
      ? getOffsetedClientDateFromUTC(valueBeforeConverting)
      : undefined
    : valueBeforeConverting

  // resets default value if it changes
  useResetOnChange(formProps?.resetField, {
    name: name,
    watch: defaultValue as never,
    value: defaultValue as never
  })

  const { t } = useTranslation()

  const minDate =
    typeof limits?.minByDays === 'number'
      ? add(addDate(), {
          days: limits.minByDays
        })
      : undefined

  const maxDate =
    typeof limits?.maxByDays === 'number'
      ? add(addDate(), {
          days: limits.maxByDays
        })
      : undefined

  const classes = useStyles()

  return (
    <Controller<T>
      control={control}
      defaultValue={defaultValue as never}
      name={name}
      render={({ field, fieldState }) => {
        const fieldError = fieldState.error

        return (
          <KeyboardDatePicker
            InputLabelProps={{ htmlFor: id }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            PopoverProps={{ classes: { paper: classes.calendar } }}
            autoOk
            disableFuture={disableFuture}
            disablePast={limits?.disablePast}
            disableToolbar
            error={hasError && Boolean(fieldError)}
            format={format}
            fullWidth
            helperText={
              hasError
                ? errorMessage ||
                  fieldState?.error?.message ||
                  t('field_required')
                : ''
            }
            id={id}
            keyboardIcon={<Icon color="secondary" name="event" />}
            // prevents not showing the required asterisk when a label is missing
            label={label ?? ' '}
            margin="none"
            maxDate={maxDate}
            minDate={minDate}
            placeholder={placeholder}
            required={required}
            shouldDisableDate={
              disableDays
                ? (date) => {
                    if (!date) return false
                    const day = date.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6

                    return disableDays.includes(day)
                  }
                : undefined
            }
            variant="inline"
            {...field}
            onChange={(value) => {
              if (isDateReworkEnabled) {
                // required to gain consistent behaviour. Without it, the picked date would include a time, and the typed in date would be at midnight.
                return field.onChange(
                  value
                    ? shouldContainTime
                      ? addCurrentTimeToDate(getClientDateWithIgnoredTZ(value))
                      : getClientDateWithIgnoredTZ(value)
                    : value
                )
              } else {
                const currentDate = new Date()
                let currentTime = {}

                if (shouldContainTime) {
                  currentTime = {
                    hour: currentDate.getHours(),
                    minute: currentDate.getMinutes(),
                    second: currentDate.getSeconds()
                  }
                }

                return field.onChange(
                  value ? addDate({ ...currentTime, date: value }) : value
                )
              }
            }}
            value={field.value || null}
          />
        )
      }}
      rules={
        {
          required,
          ...rules
        } as never
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)
