import type { ComponentType } from 'react'

import classes from '../../styles/TileFooter.module.scss'
import type {
  ProductDetailsModalProps,
  NormalizedSectionContainerProps
} from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

import { ProductDetails } from './ProductDetails'
import { ProductDownloads } from './ProductDownloads'

export const TileFooter = ({
  ProductDetailsModalComponent,
  NormalizedSectionContainer
}: {
  ProductDetailsModalComponent: ComponentType<ProductDetailsModalProps>
  NormalizedSectionContainer: ComponentType<NormalizedSectionContainerProps>
}) => {
  const { config } = useTileContext()

  return (
    <NormalizedSectionContainer sectionName="concordeFooter">
      <footer className={classes.container} data-testid="footer">
        <div className={classes.footerData}>
          <ProductDetails
            ProductDetailsModalComponent={ProductDetailsModalComponent}
          />
          {!config.generalTileConfiguration.showProductDetails && (
            <ProductDownloads />
          )}
        </div>
      </footer>
    </NormalizedSectionContainer>
  )
}
