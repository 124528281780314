import type { MutableRefObject } from 'react'
import { useLayoutEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type NullableSize = {
  width: number | null
  height: number | null
}

export const useSize = (
  ref: MutableRefObject<HTMLDivElement | null>
): NullableSize => {
  const [size, setSize] = useState<NullableSize>({ width: null, height: null })

  const handleResize = useDebouncedCallback<ResizeObserverCallback>(
    ([entry]) => {
      if (entry?.contentRect) {
        const { width, height } = entry.contentRect

        setSize({ width, height })
      }
    },
    1000,
    { leading: true }
  )

  useLayoutEffect(() => {
    if (!ref.current || !window.ResizeObserver) return

    const observer = new window.ResizeObserver(handleResize)

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [ref, handleResize])

  return size
}
