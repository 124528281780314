export const WATER_HEATING_FACTOR = [
  { quantity: 1, multiplicationFactor: 1.0 },
  { quantity: 2, multiplicationFactor: 0.717948717948718 },
  { quantity: 3, multiplicationFactor: 0.547008547008547 },
  { quantity: 4, multiplicationFactor: 0.461538461538462 },
  { quantity: 5, multiplicationFactor: 0.41025641025641 },
  { quantity: 6, multiplicationFactor: 0.367521367521368 },
  { quantity: 7, multiplicationFactor: 0.336996336996337 },
  { quantity: 8, multiplicationFactor: 0.307692307692308 },
  { quantity: 9, multiplicationFactor: 0.284900284900285 },
  { quantity: 10, multiplicationFactor: 0.266666666666667 },
  { quantity: 11, multiplicationFactor: 0.247086247086247 },
  { quantity: 12, multiplicationFactor: 0.230769230769231 },
  { quantity: 13, multiplicationFactor: 0.216962524654832 },
  { quantity: 14, multiplicationFactor: 0.205128205128205 },
  { quantity: 15, multiplicationFactor: 0.194871794871795 },
  { quantity: 16, multiplicationFactor: 0.185897435897436 },
  { quantity: 17, multiplicationFactor: 0.177978883861237 },
  { quantity: 18, multiplicationFactor: 0.170940170940171 },
  { quantity: 19, multiplicationFactor: 0.164642375168691 },
  { quantity: 20, multiplicationFactor: 0.158974358974359 },
  { quantity: 21, multiplicationFactor: 0.153846153846154 },
  { quantity: 22, multiplicationFactor: 0.149184149184149 },
  { quantity: 23, multiplicationFactor: 0.144927536231884 },
  { quantity: 24, multiplicationFactor: 0.141025641025641 },
  { quantity: 25, multiplicationFactor: 0.137435897435897 },
  { quantity: 26, multiplicationFactor: 0.134122287968442 },
  { quantity: 27, multiplicationFactor: 0.131054131054131 },
  { quantity: 28, multiplicationFactor: 0.128205128205128 },
  { quantity: 29, multiplicationFactor: 0.125552608311229 },
  { quantity: 30, multiplicationFactor: 0.121367521367521 },
  { quantity: 31, multiplicationFactor: 0.118279569892473 },
  { quantity: 32, multiplicationFactor: 0.115384615384615 },
  { quantity: 33, multiplicationFactor: 0.112665112665113 },
  { quantity: 34, multiplicationFactor: 0.110105580693816 },
  { quantity: 35, multiplicationFactor: 0.107692307692308 },
  { quantity: 36, multiplicationFactor: 0.105413105413105 },
  { quantity: 37, multiplicationFactor: 0.103257103257103 },
  { quantity: 38, multiplicationFactor: 0.101214574898785 },
  { quantity: 39, multiplicationFactor: 0.099276791584483 },
  { quantity: 40, multiplicationFactor: 0.097435897435897 },
  { quantity: 41, multiplicationFactor: 0.095559724828017 },
  { quantity: 42, multiplicationFactor: 0.093772893772893 },
  { quantity: 43, multiplicationFactor: 0.092069171138938 },
  { quantity: 44, multiplicationFactor: 0.09044289044289 },
  { quantity: 45, multiplicationFactor: 0.088888888888888 }
]

export const POWER_FACTOR = [
  { quantity: 1, unitaryDemand: 13.05 },
  { quantity: 2, unitaryDemand: 10.8 },
  { quantity: 3, unitaryDemand: 9.3 },
  { quantity: 4, unitaryDemand: 7.88 },
  { quantity: 5, unitaryDemand: 6.68 },
  { quantity: 6, unitaryDemand: 5.8 },
  { quantity: 7, unitaryDemand: 5.17 },
  { quantity: 8, unitaryDemand: 4.7 },
  { quantity: 9, unitaryDemand: 4.33 },
  { quantity: 10, unitaryDemand: 4.04 },
  { quantity: 11, unitaryDemand: 3.75 },
  { quantity: 12, unitaryDemand: 3.5 },
  { quantity: 13, unitaryDemand: 3.3 },
  { quantity: 14, unitaryDemand: 3.12 },
  { quantity: 15, unitaryDemand: 2.97 },
  { quantity: 16, unitaryDemand: 2.84 },
  { quantity: 17, unitaryDemand: 2.72 },
  { quantity: 18, unitaryDemand: 2.62 },
  { quantity: 19, unitaryDemand: 2.52 },
  { quantity: 20, unitaryDemand: 2.44 },
  { quantity: 21, unitaryDemand: 2.34 }
]

export const GAS_FACTOR = [
  { quantity: 1, unitaryDemand: 20 },
  { quantity: 2, unitaryDemand: 17.5 },
  { quantity: 3, unitaryDemand: 15 },
  { quantity: 4, unitaryDemand: 12.5 }
]
