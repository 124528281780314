import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles((theme) => ({
  priceContainer: {
    textAlign: 'end'
  },
  container: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  discountWrapper: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold as never
  }
}))
