import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductDownloads } from '../TileFooter/ProductDownloads'

import classes from './TileSections.module.scss'

export const TileDownloads = () => {
  return (
    <TileLayoutContainer
      className={classes.downloadContainer}
      tileLayoutSection="downloads"
    >
      <ProductDownloads />
    </TileLayoutContainer>
  )
}
