import {
  Card,
  CardContent,
  Hidden,
  Radio,
  RadioGroup,
  StackSpacing,
  CircularProgress,
  Checkbox,
  Pagination,
  clsx
} from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { appendStepBlockId } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useCallback, useEffect, useState } from 'react'

import { useStepBlockId } from '../../../utils'
import type { BaseEntity } from '../../../utils/clients/entity-client'
import { includeCommon } from '../../../utils/includeCommon'

import { AttributesHeader } from './components/AttributesHeader'
import { AttributesLabel } from './components/AttributesLabel'
import useSetEntities from './hooks/useSetEntities'
import { useStyles } from './styles'
import type { EntityLookupControlOptions } from './types'
export * from './types'

export type EntityLookupControlData = {
  id: string
  slug: string
  entity: BaseEntity
}

const PAGE_SIZE = 5

function EntityLookupControl({
  uischema,
  visible,
  handleChange,
  data,
  path
}: EpilotControlProps) {
  const stepBlockId = useStepBlockId(path)
  const styles = useStyles()
  const props = uischema.options as EntityLookupControlOptions
  const multiSelectAllowed = props.multiSelectAllowed
  const {
    slug,
    tableAttributes = [],
    relatedLookupBlock,
    relatedLookupAttribute,
    relatedContextEntity,
    relatedContextAttribute,
    titleAttribute
  } = props
  const [entities] = useSetEntities({
    slug,
    tableAttributes,
    relatedLookupBlock,
    relatedLookupAttribute,
    relatedContextEntity,
    relatedContextAttribute
  })
  const showIcon = props.showIcon === undefined ? true : props.showIcon

  // PAGINATION
  const entities_count = entities.length
  const [activePage, setActivePage] = useState(1)

  const onActivePageChange = (page: number) => {
    setActivePage(page)
  }

  const handleOnchange = useCallback(
    (v?: EntityLookupControlData | EntityLookupControlData[]) => {
      handleChange(path, v)
    },
    [path, handleChange]
  )

  const selectedIds: string[] = props.multiSelectAllowed
    ? data?.map((d: EntityLookupControlData) => d.id) || []
    : [data?.id]

  useEffect(() => {
    if (entities.length === 1) {
      handleOnchange(
        props.multiSelectAllowed
          ? [
              {
                id: entities[0]._id,
                slug,
                entity: entities[0]
              }
            ]
          : {
              id: entities[0]._id,
              slug,
              entity: entities[0]
            }
      )
    }
  }, [entities])

  if (entities.length === 0) {
    return (
      <center>
        <CircularProgress />
      </center>
    )
  }

  if (entities.length === 1) {
    return (
      <Hidden xsUp={!visible}>
        <Card className={styles.paper} variant="outlined">
          <CardContent>
            {titleAttribute && (
              <AttributesHeader
                showIcon={showIcon}
                slug={slug}
                title={entities[0][titleAttribute]}
              />
            )}
            <AttributesLabel
              entity={entities[0]}
              showIcon={!showIcon || titleAttribute ? false : true}
              slug={slug}
              tableAttributes={tableAttributes.filter(
                (attributeName) => attributeName.name !== titleAttribute
              )}
            />
          </CardContent>
        </Card>
      </Hidden>
    )
  }

  return (
    <Hidden xsUp={!visible}>
      {multiSelectAllowed ? (
        <div className={styles.radio_group}>
          <StackSpacing scale={6}>
            {entities
              .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)
              .map((entity, index) => (
                <Card
                  className={clsx(
                    styles.card,
                    styles.paper,
                    selectedIds.includes(entity._id) && styles.selectedCard
                  )}
                  key={entity._id}
                  variant="outlined"
                >
                  <>
                    <Checkbox
                      checkboxPlacement="middle"
                      checked={selectedIds.includes(entity._id)}
                      id={appendStepBlockId(
                        stepBlockId,
                        `Entity ${index + 1} ${entity.name}`
                      )}
                      label={
                        tableAttributes &&
                        entity && (
                          <>
                            {titleAttribute && (
                              <AttributesHeader
                                showIcon={showIcon}
                                slug={slug}
                                title={entity?.[titleAttribute]}
                              />
                            )}
                            <AttributesLabel
                              entity={entity}
                              showIcon={
                                !showIcon || titleAttribute ? false : true
                              }
                              slug={slug}
                              tableAttributes={tableAttributes.filter(
                                (attributeName) =>
                                  attributeName.name !== titleAttribute
                              )}
                            />
                          </>
                        )
                      }
                      labelPlacement="end"
                      onChange={(e, checked) => {
                        let newData = data ? [...data] : []

                        if (checked) {
                          newData.push({
                            id: e.target.value,
                            slug,
                            entity: entities.find(
                              (en) => en._id === e.target.value
                            ) as BaseEntity
                          })
                        } else {
                          newData = newData.filter(
                            (data) => data.id != e.target.value
                          )
                        }
                        handleOnchange(newData)
                      }}
                      value={entity._id}
                    />
                  </>
                </Card>
              ))}
          </StackSpacing>
        </div>
      ) : (
        <RadioGroup
          className={styles.radio_group}
          onChange={(e) =>
            handleOnchange({
              id: e.target.value,
              slug,
              entity: entities.find(
                (en) => en._id === e.target.value
              ) as BaseEntity
            })
          }
          value={selectedIds[0]}
        >
          <StackSpacing scale={6}>
            {entities
              .slice((activePage - 1) * PAGE_SIZE, activePage * PAGE_SIZE)
              .map((entity, index) => (
                <Card
                  className={`${styles.card} ${
                    selectedIds[0] === entity._id ? styles.selectedCard : ''
                  }`}
                  key={entity._id}
                  variant="outlined"
                >
                  <>
                    <Radio
                      id={appendStepBlockId(
                        stepBlockId,
                        `Entity ${index + 1} ${entity.name}`
                      )}
                      label={
                        tableAttributes &&
                        entity && (
                          <>
                            {titleAttribute && (
                              <AttributesHeader
                                showIcon={showIcon}
                                slug={slug}
                                title={entity?.[titleAttribute]}
                              />
                            )}
                            <AttributesLabel
                              entity={entity}
                              showIcon={
                                !props.showIcon || titleAttribute ? false : true
                              }
                              slug={slug}
                              tableAttributes={tableAttributes.filter(
                                (attributeName) =>
                                  attributeName.name !== titleAttribute
                              )}
                            />
                          </>
                        )
                      }
                      labelPlacement="end"
                      radioPlacement="middle"
                      value={entity._id}
                    />
                  </>
                </Card>
              ))}
          </StackSpacing>
        </RadioGroup>
      )}
      <div className={styles.pagination}>
        {entities_count > PAGE_SIZE && (
          <Pagination
            activePage={activePage}
            id={appendStepBlockId(stepBlockId, 'Pagination')}
            itemsCount={entities_count}
            onActivePageChange={onActivePageChange}
            pageSize={PAGE_SIZE}
            showPageInput={false}
            showPageSizeSelector={false}
          />
        )}
      </div>
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EntityLookupControl }) as never
)
