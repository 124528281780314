import type { Step } from '../../types'

import { findBlockUischema } from './findBlockUischema'
import type { Optional, StepExtended } from './types'

/**
 * Removes a block inside a step and returns the updated step, or undefined if the block was not found
 * @param items the step used for the delete operation, and potential logics, rules, etc. to take into consideration
 * @param blockName the current block name used to be removed
 * @returns updated step
 * @todo Redo function signature, there's no reason to accept an object with the step property, should just accept the step itselft
 */
export const ungroup = (
  { step }: { step: Optional<StepExtended, 'stepIndex'> },
  blockName: string
): Step => {
  const { stepIndex: _si, ...stepCurrent } = step
  const { uischema: uischemaCurrent } = stepCurrent

  const { uischema } = findBlockUischema(
    uischemaCurrent,
    { name: blockName },
    { representBy: 'full' },
    'ungroup'
  )

  if (!uischema) {
    // eslint-disable-next-line no-console
    console.error(
      'Error when attempting to ungroup uischema. Uischema not found. Returning input step'
    )

    return stepCurrent
  }

  return { ...stepCurrent, uischema }
}
