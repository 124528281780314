import type { RendererReturnValue } from '@epilot/journey-logic-commons'
import type { ControlElement } from '@jsonforms/core'
import isEqual from 'fast-deep-equal'
import debounce from 'lodash/debounce'
import { useEffect, memo, useRef } from 'react'
import type {
  Control,
  SubmitErrorHandler,
  SubmitHandler
} from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '../../../../utils/hooks/usePrevious'
import type {
  InputCalculatorControlData,
  InputCalculatorFormValues
} from '../types'

type AutoSaveProps = {
  handleChange: (
    path: string,
    value: RendererReturnValue<InputCalculatorFormValues>
  ) => void
  path: string
  uischema: ControlElement
  data: InputCalculatorControlData
  control: Control<InputCalculatorFormValues>
  reset: (data?: InputCalculatorFormValues | undefined) => void
  handleSubmit: (
    onValid: SubmitHandler<InputCalculatorFormValues>,
    onError: SubmitErrorHandler<InputCalculatorFormValues>
  ) => () => Promise<void>
}

export const AutoSave = memo<AutoSaveProps>((props) => {
  const { path, handleChange, control, handleSubmit, reset, data, uischema } =
    props

  const isMounted = useRef(true)
  const { t } = useTranslation()

  const watchedFieldsData = useWatch({
    control: control
  })

  const prevWatchedFieldsData = usePrevious(structuredClone(watchedFieldsData))

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    reset(data ?? undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, uischema])

  const cleanValues = (values: InputCalculatorFormValues | undefined) => {
    if (!values) {
      return undefined
    }

    const cleanedValues = structuredClone(values)
    const cleanedDevices = cleanedValues.devices?.map((device) => {
      if (device.name !== t('input_calculator.other_option')) {
        delete device.otherName
      }

      return device
    })

    cleanedValues.devices = cleanedDevices

    return cleanedValues
  }

  const onValid: SubmitHandler<InputCalculatorFormValues> = (
    values: InputCalculatorFormValues | undefined
  ) => {
    if (isMounted.current) {
      const cleanedValues = cleanValues(values)

      handleChange(path, {
        ...cleanedValues,
        _isValid: true
      })
    }
  }

  const onError: SubmitErrorHandler<InputCalculatorFormValues> = (data) => {
    // in case of error, and json forms schema is not required, pass null to instruct JSON Forms this form is in error state
    return handleChange(
      path,
      structuredClone({
        ...(data as InputCalculatorFormValues),
        _isValid: false
      })
    )
  }

  const debouncedSave = useRef(
    debounce((data) => {
      handleSubmit(
        onValid,
        onError(data) as SubmitErrorHandler<InputCalculatorFormValues>
      )()
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncedSave.cancel()
    }
  }, [debouncedSave])

  useEffect(() => {
    if (!isEqual(prevWatchedFieldsData, watchedFieldsData)) {
      debouncedSave(watchedFieldsData)
    }
  }, [debouncedSave, prevWatchedFieldsData, watchedFieldsData])

  return null
})
