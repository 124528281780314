import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles({
  containerWithoutLabel: {
    textAlign: 'right'
  },
  column: {
    flexDirection: 'column',
    margin: -1.5
  },
  asterisk: {
    textAlign: 'right'
  }
})
