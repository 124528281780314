import type { EnumUiTypes, IbanResponse } from '@epilot/journey-elements'
import type { PaymentMethod as PaymentMethodInitial } from '@epilot/journey-logic-commons'

export type PaymentMethod = PaymentMethodInitial<React.FC<any>>
export type PaymentInformationValue = {
  type?: 'payment_invoice' | 'payment_sepa'
  data?: SEPAData
  label?: string
}

export type SEPAData = {
  fullname: string
  iban: string
  consent: boolean
}

export type PaymentInformationProps = {
  /**
   * array of payment methods
   */
  paymentMethods: PaymentMethod[]

  /**
   * @default 'primary'
   */
  color?: 'primary' | 'secondary' | 'default'

  /**
   * when set to true, the radio buttons will be disabled
   */
  disabled?: boolean

  onPaymentMethodSelected?: (
    value?: PaymentInformationValue,
    hasError?: boolean
  ) => void

  /**
   * the data of the payment method
   */
  value: PaymentInformationValue
  error?: string

  initialType?: string

  uiType?: EnumUiTypes
  required: boolean | undefined

  /**
   * the id of the payment method
   */
  id: string
}
export enum PAYMENT_METHOD {
  SEPA = 'payment_sepa',
  TRANSFER = 'payment_invoice'
}

export type SEPAProps = {
  /**
   * did the end customer agree for SEPA
   * @deprecated use value
   */
  consent?: boolean

  /**
   * the text of the consent
   */
  consentLabel?: string
  /**
   * the base url of checking for iban
   */
  apiBaseUrl: string

  /**
   * a callback that will be called when the user enters a valid iban
   */
  onChange?: (newData: SEPAData) => void

  value?: SEPAData

  /**
   * value of accountOwnerName field
   * @deprecated use value
   */
  accountOwnerName?: string
  /**
   * label of accountOwnerName field
   */
  accountOwnerNameLabel: string
  /**
   * helper text of accountOwnerName field
   */
  accountOwnerNameHelper?: string
  /**
   * error message
   */
  error?: string

  /**
   * value of iban field
   * @deprecated use value
   */
  iban?: string
  /**
   * label of iban field
   * @default "IBAN"
   */
  ibanLabel?: string
  /**
   * helper text of iban field
   */
  ibanHelper?: string
  /**
   * values of iban response
   */
  ibanResponse?: IbanResponse

  /**
   * value of bic field
   */
  bic?: string
  /**
   * label of bic field
   * @default "BIC"
   */
  bicLabel?: string
  /**
   * helper text of bic field
   */
  bicHelper?: string

  /**
   * value of bankName field
   */
  bankName?: string
  /**
   * label of bankName field
   */
  bankNameLabel: string
  /**
   * helper text of bankName field
   */
  bankNameHelper?: string

  /**
   * the scale of inner spacing
   * @default 2
   */
  scale?: number
  required: boolean | undefined

  /**
   * id text of field
   */
  id: string
}

export type BankTransferProps = {
  bankDetails: {
    title: string
    value: string
  }[]
}
