import type { CompositePriceItem, Price } from '@epilot/journey-logic-commons'
import { priceHasComponents } from '@epilot/journey-logic-commons'
import { useMemo, type ComponentType } from 'react'

import { Spacing } from '../../../../components'
import classes from '../../styles/ProductPricesComponents.module.scss'
import type { ComponentMappingDataGraduatedProps } from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

import { PriceComponentsItem } from './PriceComponentsItem'

interface ProductPricesComponentsProps {
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
}

export const ProductPricesComponents = ({
  ComponentMappingDataGraduated
}: ProductPricesComponentsProps) => {
  const {
    price,
    product,
    config,
    pricingDetails,
    tileState: { priceDisplayInJourneys },
    tileVariantsEnabled
  } = useTileContext()

  const components = (pricingDetails?.items?.[0] as CompositePriceItem)
    ?.item_components

  const shouldDisplayPriceComponents = useMemo(
    () =>
      priceDisplayInJourneys !== 'show_as_on_request' &&
      !tileVariantsEnabled &&
      config.generalTileConfiguration.displayPriceComponents &&
      priceHasComponents(price),
    [
      config.generalTileConfiguration.displayPriceComponents,
      price,
      priceDisplayInJourneys,
      tileVariantsEnabled
    ]
  )

  if (!shouldDisplayPriceComponents) {
    return null
  }

  return (
    <Spacing
      className={classes.priceComponentsContainer}
      scale={1}
      variant="stack"
    >
      <div className={classes.container}>
        {(components || []).map((priceComponent: Price, index: number) => (
          <PriceComponentsItem
            ComponentMappingDataGraduated={ComponentMappingDataGraduated}
            key={`${product._id}:${price._id}:${priceComponent._id}:${index}`}
            priceComponent={priceComponent}
          />
        ))}
      </div>
    </Spacing>
  )
}
