export const logicOperators = {
  Equal: '::=::',
  NotEqual: '::!=::',
  Greater: '::>::',
  GreaterOrEqual: '::>=::',
  Less: '::<::',
  LessOrEqual: '::<=::',
  Between: '::RANGE::',
  NotEmpty: '::DEF::',
  IsEmpty: '::!!::',
  ContEither: '::CONTEITHER::',
  ContAll: '::CONTALL::',
  ContNot: '::CONTNOT::',
  ExactMatch: '::EXACTMATCH::',
  In: '::IN::',
  NotIn: '::NOTIN::'
}

export const OperatorValueMapper: Record<string, string> = {
  '::CONTALL::': 'contAll',
  '::EXACTMATCH::': 'exactMatch',
  '::CONTEITHER::': 'contEither',
  '::CONTNOT::': 'contNot',
  '::=::': 'equal',
  '::IN::': 'in',
  '::NOTIN::': 'notIn',
  '::>::': 'greater',
  '::>=::': 'greaterOrEqual',
  '::<::': 'less',
  '::<=::': 'lessOrEqual',
  '::RANGE::': 'between'
}

export const DisplayOperatorSeparatorMap = new Map<string, string>([
  [logicOperators.ContAll, 'And'],
  [logicOperators.ExactMatch, 'And'],
  [logicOperators.ContEither, 'Or'],
  [logicOperators.In, 'Or'],
  [logicOperators.ContNot, 'And'],
  [logicOperators.NotIn, 'And'],
  [logicOperators.Between, 'And']
])

export const logicActionTypes = {
  InjectValue: '::InjV::',
  InjectValueWithKey: '::InjVWK::',
  InjectFilter: '::InjFter::',
  NavigateToStep: '::NavStep::',
  ClearData: '::ClrData::',
  AssignTags: '::AsTags::',
  GoNext: '::GoN::',
  DisplayBlock: '::DspBlock::',
  SkipStep: '::SkipStep::'
}

export enum LogicTriggerEventName {
  VALUE_CHANGE = '||VC||',
  NEXT = '||NX||'
}

export const UploadFileTypesMapper = new Map<string, string>([
  ['Image', 'image/*, image/heic'],
  [
    'Text',
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, text/plain'
  ],
  ['PDF', 'application/pdf'],
  [
    'Sheet',
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet, application/vnd.google-apps.spreadsheet, application/vnd.ms-excel.sheet.macroEnabled.12'
  ],
  [
    'Presentation',
    'application/vnd.oasis.opendocument.presentation,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.apple.keynote,application/x-iwork-keynote-sffkey,application/vnd.google-apps.presentation'
  ],
  ['Audio', 'audio/*'],
  ['Video', 'video/*'],
  [
    'Archives',
    'application/zip, application/x-rar-compressed, application/x-tar, application/gzip'
  ],
  ['Mail', 'application/vnd.ms-outlook,application/vnd.ms-outlook.msg,.msg'],
  ['CAD', '.dxf, application/dxf, image/vnd.dxf']
])

type LogicOperatorsKeys = keyof typeof logicOperators
export type LogicOperatorsValues = (typeof logicOperators)[LogicOperatorsKeys]
type LogicActionTypesKeys = keyof typeof logicActionTypes
export type LogicActionTypesValues =
  (typeof logicActionTypes)[LogicActionTypesKeys]
