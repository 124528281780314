import type { MobileStepperProps } from '@epilot/journey-elements'
import {
  SmallText,
  InlineSpacing,
  Button,
  StepLabel,
  Stepper,
  Step,
  MobileStepper,
  IconButton,
  clsx,
  useTheme
} from '@epilot/journey-elements'
import ChevronLeft from '@epilot360/icons/react/ChevronLeft'
import { memo } from 'react'

import { STEPPER_DESKTOP_LIMIT } from '../../utils/config'
import { useWrapperStyles } from '../JourneyPage/styles'

import { ProgressBar } from './components/ProgressBar'
import { useStepStyles, useStyles } from './styles'
import type { JourneyStepperProps } from './types'

export const JourneyStepper = memo(
  ({
    currentStepIndex: currentStepIndexFull,
    steps,
    onChangeStepIndex,
    isMobile,
    isTablet,
    isProgressBar = true,
    remainingSteps,
    stack: fullStack
  }: JourneyStepperProps) => {
    // non injected stack
    const stack = fullStack.filter((step) => !step.isInjected)
    const injectedStepsCount = fullStack.length - stack.length
    // currentStepIndex now comes from the non injected steps
    const currentStepIndex = currentStepIndexFull - injectedStepsCount

    const currentStep = steps[currentStepIndex] || {}
    const maxSteps = steps.length - injectedStepsCount
    const { stepStyles } = useStepStyles()
    const { wrapperStyles } = useWrapperStyles()
    const theme = useTheme()

    const classes = useStyles({
      activeStep: currentStepIndex,
      steps: maxSteps
    } as MobileStepperProps)

    const handleNavigation = (nextIndex: number) => {
      if (nextIndex >= 0 && nextIndex < maxSteps) {
        onChangeStepIndex(nextIndex)
      }
    }

    // if not linear or if linear + maxSteps higher than limit -> Progressbar
    if (isProgressBar) {
      return (
        <ProgressBar current={stack.length - 1} remaining={remainingSteps} />
      )
    }

    // if isMobile || isTablet -> MobileStepper
    if (isMobile || isTablet) {
      return (
        <MobileStepper
          activeStep={currentStepIndex}
          backButton={
            maxSteps <= STEPPER_DESKTOP_LIMIT ? (
              <IconButton
                disabled={currentStepIndex === 0}
                onClick={() => handleNavigation(currentStepIndex - 1)}
              >
                <ChevronLeft
                  fill={theme.palette.primary.main}
                  variant="filled"
                />
              </IconButton>
            ) : (
              <Button
                className={classes.visibleButton}
                disabled={currentStepIndex === 0}
                onClick={() => handleNavigation(currentStepIndex - 1)}
              >
                <InlineSpacing alignItems="center" scale={1}>
                  <ChevronLeft
                    fill={theme.palette.primary.main}
                    variant="filled"
                  />
                  <b>Zurück</b>
                </InlineSpacing>
              </Button>
            )
          }
          className={clsx(classes.mobileStepperStyles, classes.stepper)}
          classes={{ dot: classes.dot, root: classes.root }}
          nextButton={
            maxSteps <= STEPPER_DESKTOP_LIMIT ? (
              <IconButton className={classes.hiddenButton} disabled>
                <ChevronLeft
                  fill={theme.palette.primary.main}
                  variant="filled"
                />
              </IconButton>
            ) : (
              <Button className={classes.hiddenButton} disabled>
                {
                  // This button is useless, it is there just to balance the space in the bar, thats why the label is Zurück
                }
                <InlineSpacing alignItems="center" scale={1}>
                  <ChevronLeft
                    fill={theme.palette.primary.main}
                    variant="filled"
                  />
                  <b>Zurück</b>
                </InlineSpacing>
              </Button>
            )
          }
          position="static"
          steps={maxSteps}
          variant={maxSteps > STEPPER_DESKTOP_LIMIT ? 'text' : 'dots'}
        />
      )
    }

    // else -> DesktopStepper
    return (
      <div className={wrapperStyles}>
        <Stepper
          activeStep={currentStepIndex}
          alignment="center"
          className={classes.activeStep}
        >
          {steps.map((step, index) =>
            injectedStepsCount && index <= injectedStepsCount ? null : (
              <Step
                className={stepStyles}
                key={index}
                onClick={() =>
                  currentStepIndex >= index
                    ? onChangeStepIndex(index + injectedStepsCount)
                    : {}
                }
              >
                <StepLabel>
                  <SmallText>
                    <b>
                      {currentStep.showStepperLabels && !isMobile
                        ? step.name
                        : ''}
                    </b>
                  </SmallText>
                </StepLabel>
              </Step>
            )
          )}
        </Stepper>
      </div>
    )
  }
)

JourneyStepper.displayName = 'JourneyStepper'
