import isEqual from 'fast-deep-equal'
import { useEffect, useRef } from 'react'
import type {
  DefaultValues,
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormProps
} from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { usePrevious } from '../usePrevious'

type UseFormHandlerParams<T extends FieldValues> = {
  handleChange: (path: string, value: T | undefined | null) => void
  path: string
  data: T | DefaultValues<T> | undefined | null
  formProps?: UseFormProps<T>
  fields?: unknown
  isRequired?: boolean // based on schema, not on field level.
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFormHandler = <T extends Record<string, any>>(
  params: UseFormHandlerParams<T>
) => {
  const { handleChange, path, formProps, fields, data } = params
  const { watch, handleSubmit, reset, trigger, ...rest } = useForm<T>(formProps)
  const fieldValues = watch()
  const prevValues = usePrevious(fieldValues)
  const isMounted = useRef(true)

  const onValid: SubmitHandler<T> = (values: T | undefined) => {
    if (isMounted.current)
      handleChange(path, { ...values, _isValid: true } as unknown as T)
  }

  const onError: SubmitErrorHandler<T> = () => {
    // in case of error, and json forms schema is not required, pass null to instruct JSON Forms this form is in error state
    return handleChange(path, {
      ...fieldValues,
      _isValid: false
    })
  }

  useEffect(() => {
    if (!isEqual(prevValues, fieldValues)) {
      handleSubmit(onValid, onError)()
    }
  }, [fieldValues])

  // trigger validation if field options change
  useEffect(() => {
    trigger()
  }, [fields, trigger])

  // reset to default, or given values if path changed
  useEffect(() => {
    reset(data ?? undefined)
  }, [path])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return {
    ...rest,
    trigger,
    watch,
    reset,
    handleSubmit,
    fieldValues
  }
}
