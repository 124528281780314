import { appendStepBlockId } from '@epilot/journey-logic-commons'
import type { ChangeEvent } from 'react'

import { StepperInput } from '../../../../components'
import classes from '../../styles/ProductQuantityStepper.module.scss'
import { useTileContext } from '../../utils/tile-context-utils'

export const ProductQuantityStepper = () => {
  const { config, actions, tileState, id } = useTileContext()
  const showQuantity = config.generalTileConfiguration.quantityConfig.show
  const { quantity } = tileState
  const { setQuantity, increaseQuantity, decreaseQuantity } = actions

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const inputValue = parseInt(event.target.value)
    const isValidNumber = !isNaN(inputValue) && isFinite(inputValue)

    if (isValidNumber) {
      return setQuantity(inputValue)
    }

    return setQuantity(0)
  }

  if (!showQuantity) return null

  const stepperId = appendStepBlockId(id, 'Quantity Stepper')

  return (
    <StepperInput
      adornmentProps={{
        className: classes.stepperButton
      }}
      aria-label="Product quantity"
      className={classes.stepperInput}
      id={stepperId}
      isFullWidth
      onChange={handleChange}
      onEndIconClick={increaseQuantity}
      onStartIconClick={decreaseQuantity}
      value={quantity}
    />
  )
}
