import type { IconName } from '@epilot/journey-elements'
import type { Design, EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useCallback, useMemo } from 'react'

import { JourneyLauncherTabs } from '../../../components/JourneyLauncherTabs'
import { includeCommon } from '../../../utils/includeCommon'
import { withQueryProvider } from '../../../utils/react-query'
import type { JsonFormsOnChange } from '../../../utils/types'

import { useLoadFirstJourneyAndPreloadFollowing } from './JourneyService'

type LinkedJourney = {
  id: string
  label: string
  icon: IconName | null
  design?: Design
}

export type JourneyLauncherControlOptions = {
  linkedJourneys: LinkedJourney[]
}

const JourneyLauncherControl = ({
  uischema,
  renderers = [],
  path,
  handleChange,
  data = {}
}: EpilotControlProps) => {
  /**
   * @todo This occurs because JSONForms will immediately render the control with the value passed in handleChange after a change,
   * before it renders it again with the data being passed in `data`.
   * @todo This is a temporary fix, we need to discern which renders are being triggered with { data: {...}, errors: [...] } being received
   */
  const innerData = 'data' in data ? data.data : data

  const handleOnChange = useCallback<JsonFormsOnChange>(
    (value) => handleChange(path, value),
    [handleChange, path]
  )

  const linkedJourneys = useMemo(
    () =>
      (uischema.options as JourneyLauncherControlOptions | undefined)
        ?.linkedJourneys || [],
    [uischema.options]
  )

  const linkedJourneyIds = useMemo(
    () => linkedJourneys.map(({ id }) => id),
    [linkedJourneys]
  )

  useLoadFirstJourneyAndPreloadFollowing(linkedJourneyIds)

  return (
    <JourneyLauncherTabs
      data={innerData}
      journeys={linkedJourneys}
      onChange={handleOnChange}
      renderers={renderers}
      uiSchema={uischema}
    />
  )
}

export default withJsonFormsControlProps(
  includeCommon({
    component: withQueryProvider(JourneyLauncherControl)
  }) as never
)
