/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import type { UploadPanelControlOptions } from './types'

export function isUploadPanelControlOptions(
  obj: any,
  _argumentName?: string
): obj is UploadPanelControlOptions {
  return (
    ((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
    typeof obj.maxQuantity === 'number' &&
    (typeof obj.restricted === 'undefined' ||
      obj.restricted === false ||
      obj.restricted === true) &&
    (typeof obj.supportedTypes === 'undefined' ||
      obj.supportedTypes === null ||
      typeof obj.supportedTypes === 'string') &&
    (typeof obj.tags === 'undefined' ||
      (Array.isArray(obj.tags) &&
        obj.tags.every((e: any) => typeof e === 'string'))) &&
    (typeof obj.zoneLabel === 'undefined' || typeof obj.zoneLabel === 'string')
  )
}
