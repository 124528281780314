/**
 * a function that takes a date. then overwrites the time to the current time
 * @param date
 * @returns
 */
export function addCurrentTimeToDate(date: Date): Date {
  const now = new Date()

  date.setHours(now.getHours())
  date.setMinutes(now.getMinutes())
  date.setSeconds(now.getSeconds())
  date.setMilliseconds(now.getMilliseconds())

  return date
}

/**
 * a function will take the date which is selected by the client, then converts it to UTC date that matches the client Day and resets hours to mid night
 * ex. 23:00 @31.12 in UTC is 01:00 @01.01 in Germany. so the function will return the date as 00:00 @01.01 in UTC
 * @param date
 * @returns
 */
export function getClientDateWithIgnoredTZ(date: Date): Date {
  // check if the date is a valida date and not a string
  if (isNaN(date as never)) {
    // d.getTime() or d.valueOf() will also work
    // date object is not valid
    return date
  } else {
    // date object is valid
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
    // reset time from offsetDate to 00:00:00.000
    const result = new Date(
      `${offsetDate.toISOString().split('T')[0]}T00:00:00.000Z`
    )

    return result
  }
}

export function getOffsetedClientDateFromUTC(date: Date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}
