import {
  CircularProgress,
  Grid,
  Hidden,
  LargeText
} from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { useEffect } from 'react'

import { useJourneyContext, useStepBlockId } from '../../../utils'
import { includeCommon } from '../../../utils/includeCommon'
import type { EntityLookupControlData } from '../EntityLookupControl'
import { AttributeItem } from '../EntityLookupControl/components/AttributeItem'

import { getAttributeControl } from './getAttributeControl'
import { getPreviewData } from './getPreviewData'
import type { EntityAttributeControlOptions } from './types'
export * from './types'

export type EntityAttributeControlData = {
  oldValue: unknown
  newValue: unknown
  entityId: string
  slug: string
}

function EntityAttributeControl(props: EpilotControlProps) {
  const { uischema, visible, data, handleChange, required, path } = props

  const stepBlockId = useStepBlockId(path)

  const options = uischema.options as EntityAttributeControlOptions

  const {
    slug,
    attributeName,
    attributeType,
    relatedLookupBlock,
    relatedContextEntity,
    additionalOptions,
    readOnly,
    attributeLabel
  } = options
  const { context } = useJourneyContext()

  // Determine where lookupData comes from - Context or Lookup
  let lookupData

  if (relatedLookupBlock) {
    const [stepIndex, blockName] = relatedLookupBlock?.split('/') || []

    lookupData = context._stepsHistoryStateArray[+stepIndex]?.[
      blockName
    ] as EntityLookupControlData
  } else if (relatedContextEntity && context._contextEntitiesData) {
    const key = relatedContextEntity.toLowerCase()

    lookupData = {
      entity: context._contextEntitiesData[key]
    } as EntityLookupControlData
  }

  const oldData = attributeName
    ? lookupData?.entity?.[attributeName]
    : undefined

  useEffect(() => {
    if (oldData) {
      setTimeout(() => {
        handleOnChange(path, oldData)
      }, 200)
    }
  }, [oldData])

  // WHAT IS THIS DOING?
  if (data?.preview) {
    return <LargeText>{data?.preview}</LargeText>
  }

  // These needs to be defined for placeholder to work
  if (!attributeType || !attributeName) {
    return <Hidden xsUp={!visible}> </Hidden>
  }

  const attributeControl =
    attributeType && getAttributeControl(attributeType, additionalOptions)
  const Component = attributeControl?.component

  if (context._isPreview && additionalOptions && Component) {
    const previewData = getPreviewData(attributeType)

    if (readOnly) {
      return (
        <Grid container>
          <AttributeItem
            label={attributeLabel || attributeName}
            value={lookupData?.entity?.[attributeName]}
          />
        </Grid>
      )
    } else {
      return (
        <Component
          {...props}
          data={previewData}
          id={stepBlockId}
          required={required}
          uischema={{
            ...uischema,
            options: additionalOptions?.uischema?.options
          }}
        />
      )
    }
  }

  if (!lookupData) {
    return <Hidden xsUp={!visible}> </Hidden>
  }

  if (readOnly) {
    return (
      <Grid container>
        <AttributeItem
          label={attributeLabel || attributeName}
          value={lookupData?.entity?.[attributeName]}
        />
      </Grid>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (path: string, v: any) => {
    const isDataInvalid =
      !v ||
      (typeof v === 'object' && v !== null && '_isValid' in v && !v._isValid)

    handleChange(
      path,
      required && isDataInvalid
        ? undefined
        : {
            newValue: attributeControl?.getData
              ? attributeControl.getData(v as Record<string, unknown>)
              : v,
            oldValue: oldData,
            entityId: data?.entityId,
            slug
          }
    )
  }

  const rawValue =
    typeof data?.newValue === 'undefined' ? oldData : data?.newValue

  const componentData = attributeControl?.setData
    ? attributeControl.setData(rawValue, {})
    : rawValue

  return (
    <Hidden xsUp={!visible}>
      {Component ? (
        <Component
          {...props}
          data={componentData}
          handleChange={handleOnChange}
          id={stepBlockId}
          required={required}
          uischema={{
            ...uischema,
            options: additionalOptions?.uischema?.options
          }}
        />
      ) : (
        <CircularProgress size={40} />
      )}
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: EntityAttributeControl }) as never
)
