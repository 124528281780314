import type { Theme } from '@material-ui/core'
import {
  StylesProvider as MuiStylesProvider,
  ThemeProvider as MuiThemeProvider,
  createTheme,
  createGenerateClassName
} from '@material-ui/core'

import { ClassPrefixProvider } from '../context/ClassPrefixContext'

import { DefaultEpilotTheme } from './DefaultEpilotTheme'
import { parseMuiTheme } from './mapping'
import type { EpilotTheme, ThemeProviderProps } from './types'

export function ThemeProvider({
  children,
  theme = DefaultEpilotTheme as EpilotTheme,
  classPrefix = '',
  isMuiTheme
}: ThemeProviderProps) {
  const muiTheme = isMuiTheme
    ? (theme as Theme)
    : createTheme(parseMuiTheme(theme as EpilotTheme, classPrefix))

  const generateClassName = createGenerateClassName({
    seed: classPrefix || DefaultEpilotTheme.prefix
  })

  return (
    <ClassPrefixProvider classPrefix={classPrefix}>
      <MuiStylesProvider
        generateClassName={(rule, sheet) => {
          const className = generateClassName(rule, sheet)
          // Generate random ID for to randomise jss class rule - eg. 7yadq
          const id = (Math.random() + 1).toString(36).substring(7)

          return className.includes('jss') ? `${className}--${id}` : className
        }}
      >
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </MuiStylesProvider>
    </ClassPrefixProvider>
  )
}
