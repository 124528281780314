import { add } from 'date-fns'

import { addDate, findNextAvailableDay } from '../../date'
import type { DatePickerControlOptions } from '../../types'

export const findInitialValues = (
  date: Date | null | string | undefined,
  prefillByDays: number | undefined,
  disableDays: number[] | undefined
) => {
  if (date) return date

  return typeof prefillByDays === 'number'
    ? findNextAvailableDay(add(addDate(), { days: prefillByDays }), disableDays)
    : null
}

export const isBlockDataValid = (
  fields: DatePickerControlOptions['fields'],
  isRequired?: boolean,
  initialStart?: Date | null | string,
  initialEnd?: Date | null | string
) => {
  return isRequired &&
    ((fields.startDate && !initialStart) || (fields.endDate && !initialEnd))
    ? false
    : true
}
