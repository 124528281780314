import { Hidden } from '@epilot/journey-elements'
import type { EpilotControlProps } from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'

import { includeCommon } from '../../../utils/includeCommon'

import { CustomBlockComponent } from './CustomBlockComponent'
import type { CustomBlockControlOptions } from './types'

function CustomBlockControl({
  data,
  handleChange,
  path,
  uischema,
  visible,
  errors,
  required
}: EpilotControlProps) {
  const props = uischema.options as CustomBlockControlOptions

  const handleOnChange = (newData: unknown) => {
    try {
      if (newData !== 'object' && typeof newData === 'string') {
        newData = JSON.parse(newData)
      }
    } catch (error) {
      console.error('error', error)
    }

    handleChange(path, newData)
  }

  return (
    <Hidden xsUp={!visible}>
      <CustomBlockComponent
        errors={errors}
        onChange={handleOnChange}
        required={required}
        value={data}
        {...props}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: CustomBlockControl }) as never
)
