import { makeStyles } from '@epilot/journey-elements'

export const useStyles = makeStyles({
  divider: {
    width: '100%',
    margin: '4px 0px'
  },
  dividerContainer: {
    padding: '0 6px'
  }
})
