import { Typography as ConcordeTypography } from '@epilot/concorde-elements'
import type { Theme } from '@epilot/journey-elements'
import { createStyles, makeStyles, Typography } from '@epilot/journey-elements'
import type {
  CompositePriceItem,
  PriceItem,
  PriceTier
} from '@epilot/journey-logic-commons'
import {
  getPriceType,
  hasTieredPricingModel,
  priceItemIsComposite
} from '@epilot/journey-logic-commons'
import {
  computeQuantities,
  getDisplayTierByQuantity,
  getTierDescription,
  PricingModel
} from '@epilot/pricing'
import type { Currency } from '@epilot/pricing'

import { ComponentMappingDataGraduated } from '../../../../../../components/Product'
import { useTileContext } from '../../../utils/tile-context-utils'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    priceComponent: {
      color: theme.palette.text.secondary,
      fontSize: 12
    },
    text: {
      fontSize: 12,
      maxWidth: '150px',
      lineHeight: 1.5
    },
    price: {
      fontSize: 12,
      display: 'flex',
      lineHeight: 1.5,
      maxWidth: '75px',
      wordBreak: 'break-all'
    }
  })
)

export const PriceTierDetails = ({
  priceItem
}: {
  priceItem: PriceItem | CompositePriceItem
}) => {
  const classes = useStyles()
  const { actions, tileState, config, useNewDesign } = useTileContext()

  if (priceItemIsComposite(priceItem) || !priceItem) return null

  if (!hasTieredPricingModel(priceItem)) return null

  if (priceItem.pricing_model === PricingModel.tieredGraduated) {
    return (
      <ComponentMappingDataGraduated
        className={classes.priceComponent}
        displayInOneLine
        displayUnitaryAverage={
          config.generalTileConfiguration.displayUnitaryAverage
        }
        isShownOnProductCard={true}
        price={priceItem._price}
        quantity={tileState.quantity}
        showPriceDescription
        showTrailingDecimalZeros={
          config.generalTileConfiguration.showTrailingDecimalZeros
        }
        t={actions.t}
        useNewDesign={useNewDesign}
      />
    )
  }

  const { quantityToSelectTier } = computeQuantities(
    priceItem._price,
    tileState.quantity,
    {
      frequency_unit: priceItem._price.blockMappingData?.frequencyUnit,
      value: priceItem._price.blockMappingData?.numberInput
    }
  )

  const tier = getDisplayTierByQuantity(
    priceItem._price.tiers || [],
    quantityToSelectTier,
    priceItem._price.pricing_model,
    priceItem._price.is_tax_inclusive,
    priceItem._price.tax?.[0]
  )

  const displayableTier: PriceTier = {
    ...tier,
    unit_amount: tier?.unit_amount_gross,
    unit_amount_decimal: tier?.unit_amount_gross_decimal,
    flat_fee_amount: tier?.flat_fee_amount_gross,
    flat_fee_amount_decimal: tier?.flat_fee_amount_gross_decimal
  }

  const amountLabel = getTierDescription(
    priceItem._price.pricing_model,
    displayableTier,
    priceItem._price.unit,
    navigator.language,
    priceItem._price.unit_amount_currency as Currency,
    (key: string) => actions.t(key),
    {
      showStartsAt:
        priceItem._price.price_display_in_journeys === 'show_as_starting_price',
      enableSubunitDisplay: true
    }
  )

  const billingPeriod =
    !priceItem._price.variable_price &&
    priceItem._price.type !== 'one_time' &&
    getPriceType(priceItem._price.type, priceItem._price.billing_period)

  const description = priceItem.description

  return (
    <>
      {useNewDesign ? (
        <>
          <ConcordeTypography className={classes.text} variant="secondary">
            {description && <span>{description}</span>}
          </ConcordeTypography>
          <ConcordeTypography className={classes.price}>
            <span>{amountLabel}</span>
            {billingPeriod && (
              <span>{actions.t(billingPeriod).toLocaleLowerCase()}</span>
            )}
          </ConcordeTypography>
        </>
      ) : (
        <Typography className={classes.priceComponent}>
          <strong>{amountLabel}</strong>{' '}
          {billingPeriod ? actions.t(billingPeriod).toLocaleLowerCase() : ''}
          {description && ` - ${description}`}
        </Typography>
      )}
    </>
  )
}
