import type {
  CompositePrice,
  Price,
  PriceTier
} from '@epilot/journey-logic-commons'
import {
  getPriceType,
  hasTieredPricingModel,
  priceIsComposite
} from '@epilot/journey-logic-commons'
import {
  computeQuantities,
  getDisplayTierByQuantity,
  getTierDescription,
  PricingModel
} from '@epilot/pricing'
import type { Currency } from '@epilot/pricing'
import type { ComponentType } from 'react'

import { Typography } from '../../../../components'
import classes from '../../styles/PriceTierDetails.module.scss'
import type { ComponentMappingDataGraduatedProps } from '../../types'
import { useTileContext } from '../../utils/tile-context-utils'

export const PriceTierDetails = ({
  price,
  ComponentMappingDataGraduated
}: {
  price: Price | CompositePrice
  ComponentMappingDataGraduated: ComponentType<ComponentMappingDataGraduatedProps>
}) => {
  const { actions, tileState, config } = useTileContext()

  if (priceIsComposite(price) || !price) return null

  if (!hasTieredPricingModel(price)) return null

  if (price.pricing_model === PricingModel.tieredGraduated) {
    return (
      <ComponentMappingDataGraduated
        className={classes.priceComponent}
        displayInOneLine
        displayUnitaryAverage={
          config.generalTileConfiguration.displayUnitaryAverage
        }
        isShownOnProductCard={true}
        price={price._price}
        quantity={tileState.quantity}
        showPriceDescription
        showTrailingDecimalZeros={
          config.generalTileConfiguration.showTrailingDecimalZeros
        }
        t={actions.t}
        useNewDesign={true}
      />
    )
  }

  const { quantityToSelectTier } = computeQuantities(
    price._price,
    tileState.quantity,
    {
      frequency_unit: price._price.blockMappingData?.frequencyUnit,
      value: price._price.blockMappingData?.numberInput
    }
  )

  const tier = getDisplayTierByQuantity(
    price._price.tiers || [],
    quantityToSelectTier,
    price._price.pricing_model,
    price._price.is_tax_inclusive,
    price._price.tax?.[0]
  )

  const displayableTier: PriceTier = {
    ...tier,
    unit_amount: tier?.unit_amount_gross,
    unit_amount_decimal: tier?.unit_amount_gross_decimal,
    flat_fee_amount: tier?.flat_fee_amount_gross,
    flat_fee_amount_decimal: tier?.flat_fee_amount_gross_decimal
  }

  const amountLabel = getTierDescription(
    price._price.pricing_model,
    displayableTier,
    price._price.unit,
    navigator.language,
    price._price.unit_amount_currency as Currency,
    (key: string) => actions.t(key),
    {
      showStartsAt:
        price._price.price_display_in_journeys === 'show_as_starting_price',
      enableSubunitDisplay: true
    }
  )

  const billingPeriod =
    !price._price.variable_price &&
    price._price.type !== 'one_time' &&
    getPriceType(price._price.type, price._price.billing_period)

  const description = price.description

  return (
    <>
      <Typography className={classes.text} variant="secondary">
        {description && <span>{description}</span>}
      </Typography>
      <Typography className={classes.price}>
        <span>{amountLabel}</span>
        {billingPeriod && (
          <span>{actions.t(billingPeriod).toLocaleLowerCase()}</span>
        )}
      </Typography>
    </>
  )
}
