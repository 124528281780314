import { createStyles, makeStyles } from '@epilot/journey-elements'
import { priceHasComponents } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { useTileContext } from '../../../utils/tile-context-utils'
import { NormalizedSectionContainer } from '../NormalizedSectionContainer'
import { ProductAction } from '../ProductAction'
import { ProductPricesComponents } from '../ProductPricesComponents'
import { ProductQuantityStepper } from '../ProductQuantityStepper'

import { ProductDescription } from './ProductDescription'
import { ProductFeatures } from './ProductFeatures'
import { ProductPrices } from './ProductPrices'

const useStyles = makeStyles(() =>
  createStyles({
    rowsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
      padding: 32,
      paddingBottom: 14,
      flex: 1
    },
    actionsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24
    },
    priceComponents: {
      width: '100%'
    },
    tileBodyBottom: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 24
    },
    wrapper: {
      marginTop: 16
    },
    concordeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
      marginTop: 12
    }
  })
)

export const TileBody = () => {
  const classes = useStyles()

  const {
    price,
    config,
    tileState: { priceDisplayInJourneys },
    useNewDesign,
    displayCtaButtonUnderPrice
  } = useTileContext()

  const shouldDisplayPriceComponents = useMemo(
    () =>
      priceDisplayInJourneys !== 'show_as_on_request' &&
      config.generalTileConfiguration.displayPriceComponents &&
      priceHasComponents(price),
    [
      config.generalTileConfiguration.displayPriceComponents,
      price,
      priceDisplayInJourneys
    ]
  )

  const showFeaturesSection = config.showFeaturesSection

  const renderActions = (
    <div className={classes.actionsContainer}>
      <ProductQuantityStepper fullWidth />
      <ProductAction fullWidth />
    </div>
  )

  return (
    <article
      className={
        useNewDesign ? classes.concordeContainer : classes.rowsContainer
      }
    >
      <ProductDescription />
      <ProductPrices />
      {useNewDesign && (
        <NormalizedSectionContainer
          className={classes.tileBodyBottom}
          sectionName="concordePriceComponents"
        >
          {shouldDisplayPriceComponents && (
            <ProductPricesComponents
              className={classes.priceComponents}
              displayTitle={false}
            />
          )}
        </NormalizedSectionContainer>
      )}
      {displayCtaButtonUnderPrice && renderActions}
      {showFeaturesSection && <ProductFeatures />}
      {!displayCtaButtonUnderPrice && renderActions}
      {!useNewDesign && (
        <NormalizedSectionContainer
          className={classes.tileBodyBottom}
          sectionName="tileBodyBottom"
        >
          {config.generalTileConfiguration.showProductDetails &&
            shouldDisplayPriceComponents && (
              <ProductPricesComponents
                className={classes.priceComponents}
                displayTitle={false}
              />
            )}
        </NormalizedSectionContainer>
      )}
    </article>
  )
}
