import { makeStyles, Tooltip as MuiTooltip } from '@material-ui/core'

import type { TooltipProps } from './types'

const useStylesLight = makeStyles(() => ({
  arrow: {
    color: '#ffffff'
  },
  tooltip: {
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 2px rgba(89, 103, 117, 0.2)',
    color: '#000000'
  }
}))

export const Tooltip = ({ mode = 'dark', ...props }: TooltipProps) => {
  const classes = useStylesLight()

  return (
    <MuiTooltip classes={mode === 'light' ? classes : undefined} {...props} />
  )
}
