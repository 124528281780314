import type { Filter, ProductTileData } from '@epilot/journey-logic-commons'

import { getFilterValues, getItemIndex } from './utils'

export type ProductTileDataWithProductId = ProductTileData & {
  productId?: string
}
/**
 * Attempts to match a value by given filter. If found and type EXCLUDE, will filter it from the initialValues
 * Returns initialValues or filteredValues following 2 approaches:
 * @todo INCLUDE approach
 * on table mount -> fetch products + blocks + options
 * start creating filters object with all the available ids there
 * ----
 * on select -> push id to array
 * on deselect -> remove id from array by filtering it
 * EXCLUDE approach
 * on table mount -> just fetch products + blocks + options
 * nothing to do (assuming that all the ids are preselected initially) if not already provided
 * ----
 * on select do nothing or remove it if it exists in the ids array
 * on deselect -> add ids to be excluded in filters object
 */
export const applyFilters = (
  filters: Filter[] | undefined,
  _filterValuesInitial: Record<string, unknown> | undefined,
  values: ProductTileDataWithProductId[],
  matchKeys: string[]
): ProductTileDataWithProductId[] => {
  // if no filter or no filterValues to match, return passed products
  if (
    !filters ||
    filters.length === 0 ||
    !_filterValuesInitial ||
    _filterValuesInitial.length === 0
  )
    return values

  if (!Array.isArray(values)) {
    console.warn(
      'applyFilters --> unknown format of initialValues. Expected Array. Returning unfiltered values'
    )

    return values
  }

  // copy values to avoid mutating original
  const available: ProductTileDataWithProductId[] = []

  for (const value of values) {
    if (typeof value !== 'object') {
      console.warn(
        'applyFilters --> Provided values are not objects. Returning unfiltered values'
      )

      return values
    }
    available.push({
      ...value,
      productId: value.product?._id || value.productId
    })
  }

  // remove step part from _filterValues
  const _filterValues = getFilterValues(_filterValuesInitial)

  // go through filter blocks
  filters.forEach((filter) => {
    const { blockName, options } = filter

    const filterValue = _filterValues[blockName || '']

    // check if filterValue for block matches one of the filter options
    const matchedValue = options.find((option) => option.value === filterValue)

    // if not leave in array
    if (!matchedValue) return

    // if it matches, and type of option is EXCLUDE remove from array
    if (matchedValue && matchedValue.type === 'EXCLUDE') {
      for (const id of matchedValue.ids) {
        const index = getItemIndex(available, matchKeys, id)

        // item is already not passed to this function
        if (index === -1) continue

        available.splice(index, 1)
      }
    }
  })

  return available
}
