import {
  Typography as ConcordeTypography,
  Icon as ConcordeIcon
} from '@epilot/concorde-elements'
import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import {
  Grid,
  Icon,
  Paper,
  Typography,
  useTheme,
  createStyles,
  makeStyles,
  clsx
} from '@epilot/journey-elements'
import type { Price } from '@epilot/journey-logic-commons'
import { omitTrailingDoubleDecimalZeros } from '@epilot/journey-logic-commons'
import type { Currency } from '@epilot/pricing'
import { computeCumulativeValue, computeQuantities } from '@epilot/pricing'
import Popper from '@material-ui/core/Popper'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import type { TFunction } from 'i18next'
import { useState } from 'react'
import type { MouseEvent } from 'react'

import {
  getFormattedQuantity,
  formatUnitWithSuffix,
  formatProductConsumptionToLocaleString
} from '../../utils/products/quantity-utils'

export const ComponentMappingDataGraduated = ({
  displayInOneLine,
  displayUnitaryAverage,
  price,
  quantity,
  showPriceDescription,
  showTrailingDecimalZeros,
  t,
  className,
  useNewDesign,
  isShownOnProductCard
}: {
  displayInOneLine?: boolean
  displayUnitaryAverage?: boolean
  price?: Price
  quantity?: number
  showPriceDescription?: boolean
  showTrailingDecimalZeros: boolean
  t: TFunction
  className?: string
  useNewDesign?: boolean
  isShownOnProductCard?: boolean
}) => {
  const classes = useCartItemDetailsLineStyles({
    isExpanded: false,
    displayInOneLine
  })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    }
  }

  const handleMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
    if (!isMobile) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  if (!price) {
    return null
  }

  const { quantityToSelectTier } = computeQuantities(
    price,
    quantity as number,
    {
      frequency_unit: price?.blockMappingData?.frequencyUnit,
      value: price?.blockMappingData?.numberInput
    }
  )

  const tax = Array.isArray(price.tax) && price.tax[0]
  const computedValues = computeCumulativeValue(
    price?.tiers,
    quantityToSelectTier,
    price?.unit,
    navigator.language,
    price.unit_amount_currency as Currency,
    (key: string) => t(key),
    price?.is_tax_inclusive,
    {},
    tax || undefined
  )

  if (!computedValues || typeof computedValues === 'string') {
    return null
  }

  const formattedQuantity = getFormattedQuantity(price, t)

  const unit = formatUnitWithSuffix(price?.unit, quantityToSelectTier, t)
  const formattedNormalizedQuantity = `${formatProductConsumptionToLocaleString(
    quantityToSelectTier,
    2
  )} ${unit}`

  const { average, total, totalWithPrecision, breakdown } = computedValues

  const formattedAverage = showTrailingDecimalZeros
    ? average
    : omitTrailingDoubleDecimalZeros(average)

  const formattedTotal = showTrailingDecimalZeros
    ? total
    : omitTrailingDoubleDecimalZeros(total)
  const formattedTotalWithPrecision = showTrailingDecimalZeros
    ? totalWithPrecision
    : omitTrailingDoubleDecimalZeros(totalWithPrecision)

  return (
    <>
      {isShownOnProductCard && useNewDesign ? (
        <ConcordeMappingDataButton
          displayUnitaryAverage={displayUnitaryAverage}
          formattedAverage={formattedAverage}
          handleClick={handleClick}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          price={price}
          showPriceDescription={showPriceDescription}
          t={t}
        />
      ) : (
        <MappingDataButton
          className={className}
          displayInOneLine={displayInOneLine}
          displayUnitaryAverage={displayUnitaryAverage}
          formattedAverage={formattedAverage}
          formattedQuantity={formattedQuantity}
          handleClick={handleClick}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          price={price}
          showPriceDescription={showPriceDescription}
          t={t}
        />
      )}
      <Popper
        anchorEl={anchorEl}
        className={classes.popper}
        open={Boolean(anchorEl)}
        placement={isMobile ? 'top' : 'right'}
      >
        <Paper className={classes.paper} elevation={3}>
          <Grid
            className={classes.popperInnerContainer}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <Typography color="textPrimary" variant="body1">
                <b>
                  {displayUnitaryAverage
                    ? t(
                        'cumulative_breakdown_title',
                        '{{formattedNormalizedQuantity}} cost {{formattedTotal}}, for an average of {{formattedAverage}}.',
                        {
                          formattedNormalizedQuantity,
                          formattedTotal,
                          formattedAverage
                        }
                      )
                    : t(
                        'cumulative_breakdown_title_no_average',
                        '{{formattedNormalizedQuantity}} cost {{formattedTotal}}.',
                        {
                          formattedNormalizedQuantity,
                          formattedTotal
                        }
                      )}
                </b>
              </Typography>
            </Grid>
            <Grid
              className={classes.textContainer}
              container
              direction="column"
              item
            >
              <Grid item>
                <Typography color="textPrimary" variant="body2">
                  {t(
                    'cumulative_breakdown_subtitle',
                    'This is how we calculate it:'
                  )}
                </Typography>
              </Grid>
              {breakdown.map((breakdownItem, index) => (
                <Grid item key={`${index}-${breakdownItem.totalAmountDecimal}`}>
                  <Typography color="textPrimary" variant="body2">
                    {t(
                      `${
                        index === 0 ? 'first' : 'next'
                      }_cumulative_breakdown_item`,
                      index === 0 ? 'First' : 'Next'
                    )}{' '}
                    {breakdownItem.quantityUsed} *{' '}
                    {showTrailingDecimalZeros
                      ? breakdownItem.tierAmountDecimal
                      : omitTrailingDoubleDecimalZeros(
                          breakdownItem.tierAmountDecimal
                        )}{' '}
                    ={' '}
                    {showTrailingDecimalZeros
                      ? breakdownItem.totalAmountDecimal
                      : omitTrailingDoubleDecimalZeros(
                          breakdownItem.totalAmountDecimal
                        )}
                  </Typography>
                </Grid>
              ))}
              {displayUnitaryAverage && (
                <Grid className={classes.textContainer} item>
                  <Typography color="textPrimary" variant="body2">
                    {`${formattedTotalWithPrecision} / ${formattedNormalizedQuantity} = ${formattedAverage}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </>
  )
}

interface MappingDataButtonProps {
  className?: string
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleMouseEnter: (event: MouseEvent<HTMLButtonElement>) => void
  handleMouseLeave: () => void
  formattedQuantity?: string
  displayUnitaryAverage?: boolean
  formattedAverage: string
  showPriceDescription?: boolean
  price: Price
  displayInOneLine?: boolean
  t: TFunction
}

const MappingDataButton = ({
  className,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  formattedQuantity,
  displayUnitaryAverage,
  formattedAverage,
  showPriceDescription,
  price,
  displayInOneLine,
  t
}: MappingDataButtonProps) => {
  const classes = useCartItemDetailsLineStyles({
    isExpanded: false,
    displayInOneLine
  })

  return (
    <button
      className={clsx(classes.graduatedButton, className)}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>
        {formattedQuantity && (
          <>
            {formattedQuantity}
            {displayUnitaryAverage || showPriceDescription ? ` | ` : ' '}
          </>
        )}
        {displayUnitaryAverage &&
          `${t('Average', 'Average')} ${formattedAverage}`}
        {displayUnitaryAverage && showPriceDescription && ` - `}
        {showPriceDescription ? `${price.description}` : ''}
      </span>
      <Icon
        className="cumulative-info-icon"
        data-testid="tooltip"
        name={'info_outline'}
      />
    </button>
  )
}

const ConcordeMappingDataButton = ({
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  displayUnitaryAverage,
  formattedAverage,
  showPriceDescription,
  price
}: MappingDataButtonProps) => {
  const classes = useConcordeStyles()

  return (
    <>
      <ConcordeTypography className={classes.text} variant="secondary">
        {showPriceDescription ? `${price.description}` : ''}
      </ConcordeTypography>
      <ConcordeTypography className={classes.price}>
        <button
          className={classes.button}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span>{displayUnitaryAverage && `Ø ${formattedAverage}`}</span>
          <ConcordeIcon
            className={classes.concordeIcon}
            data-testid="tooltip"
            name={'info'}
          />
        </button>
      </ConcordeTypography>
    </>
  )
}

const useCartItemDetailsLineStyles = makeStyles<
  EpilotTheme,
  { isExpanded: boolean; displayInOneLine?: boolean }
>((theme) =>
  createStyles({
    cartComponents: {
      background: theme?.palette?.grey[20],
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      padding: '0px 12px 10px',
      marginTop: '-22px',
      display: (props) => (props.isExpanded ? 'flex' : 'none')
    },
    divider: {
      backgroundColor: theme.palette?.text.secondary,
      width: '100%',
      margin: '8px auto',
      opacity: '0.3'
    },
    cartComponentItem: {
      padding: 0
    },
    graduatedButton: (props) => ({
      all: 'unset',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '2px',
      '&:hover span': {
        textDecoration: 'underline',
        color: theme.palette?.text.primary
      },
      '&:hover .cumulative-info-icon': {
        color: theme.palette?.text.primary
      },
      '& div': {
        display: 'flex',
        flexDirection: props.displayInOneLine ? 'row' : 'column',
        alignItems: props.displayInOneLine ? 'center' : 'flex-start',
        [theme?.breakpoints?.down?.('md') ?? '@media (max-width: 960px)']: {
          flexDirection: 'column',
          alignItems: 'flex-start'
        }
      },
      '& .cumulative-info-icon': {
        fontSize: '16px',
        color: theme.palette?.text.secondary
      }
    }),
    popper: {
      pointerEvents: 'none',
      zIndex: 1500
    },
    popperInnerContainer: {
      padding: 16
    },
    textContainer: {
      marginTop: 10
    },
    paper: {
      borderRadius: `min(${theme.shape?.borderRadius}px, 20px)`
    }
  })
)

const useConcordeStyles = makeStyles(() =>
  createStyles({
    concordeIcon: {
      fontSize: '12px'
    },
    text: {
      fontSize: 12
    },
    price: {
      fontSize: 12,
      display: 'flex'
    },
    button: {
      all: 'unset',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '4px',
      '&:hover span:first-of-type': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    }
  })
)
