// this file exports a function called blockExists which will check if the block exists in the provided steps
// it will also get in the options if the search for the block name is sensitive or insensitive
// it will return true if the block exists and false if it doesn't

import type { Step } from '../../types'

import { blockController } from './blockController'

// TODO: unit test
export const blockExists = (
  steps: Step[] | Step,
  blockName: string,
  /** @deprecated shall be removed if not used */
  options?: {
    /** @default false */
    caseSensitive?: boolean
  }
): boolean => {
  const { caseSensitive } = Object.assign({ caseSensitive: false }, options)

  const _steps = Array.isArray(steps) ? steps : [steps]

  for (const step of _steps) {
    const properties = step.schema.properties

    if (properties) {
      const blockNames = Object.keys(properties)
      const doesBlockExist = caseSensitive
        ? blockNames.includes(blockName)
        : blockNames.findIndex(
            (name) => name.toLowerCase() === blockName.toLowerCase()
          ) !== -1

      if (doesBlockExist) return doesBlockExist
    }
  }

  return false
}

// TODO: unit test
export const groupExists = (
  steps: Step[] | Step,
  blockName: string,
  /** @deprecated shall be removed if not used */
  options?: {
    /** @default false */
    caseSensitive?: boolean
  }
): boolean => {
  const { caseSensitive } = Object.assign({ caseSensitive: false }, options)

  const _steps = Array.isArray(steps) ? steps : [steps]

  const groups = blockController.findBlocks(_steps, { type: 'GroupLayout' })

  return groups.some((group) =>
    caseSensitive
      ? group.name === blockName
      : group.name.toLowerCase() === blockName.toLowerCase()
  )
}
