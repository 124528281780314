import type { JsonSchema7 } from '@jsonforms/core'

import type { Step, UiSchema } from '../../types'
import {
  isBlockUiSchema,
  isLayoutUiSchema,
  isNestedUiSchema
} from '../../types'

import { findBlockUischema } from './findBlockUischema'
import type { Optional, StepExtended } from './types'

/**
 * Removes a block inside a step and returns the updated step, or undefined if the block was not found
 * @param items the step used for the delete operation, and potential logics, rules, etc. to take into consideration
 * @param to the current block name used to be removed
 * @returns updated step
 */
export const addBlock = (
  {
    step
  }: {
    step: Optional<StepExtended, 'stepIndex'>
  },
  to: {
    /** The block name the element will be added to */
    blockName?: string
    /** The position the element will be added to */
    index?: number
    /** The position of the zone the element will be added to if mode = zone */
    zoneIndex?: number
    /** Where to add the item to */
    mode: 'root' | 'block' | 'zone'
  },
  block: {
    /** name of the block to be added */
    name: string
    /** schema of the block to be added */
    schema?: { [key: string]: JsonSchema7 }
    /** whether the block to be added is required or not */
    required?: boolean
    /** uischema of the block to be added */
    uischema: StepExtended['uischema']
  }
): Step => {
  const {
    name,
    schema: blockSchema,
    uischema: blockUischema,
    required: blockRequired
  } = block
  const { stepIndex: _si, ...stepCurrent } = step
  const { schema: schemaCurrent, uischema: uischemaCurrent } = stepCurrent

  // add property to schema if given
  const schema = blockSchema
    ? {
        ...schemaCurrent,
        properties: { ...schemaCurrent.properties, ...blockSchema }
      }
    : schemaCurrent

  // add block to required array if needed
  const required = blockRequired
    ? [...(schema.required || []), name]
    : schema.required

  let uischema: StepExtended['uischema'] | undefined = undefined

  if (
    to.mode === 'block' &&
    (isLayoutUiSchema(blockUischema) || isBlockUiSchema(blockUischema))
  ) {
    if (isLayoutUiSchema(blockUischema) || isBlockUiSchema(blockUischema)) {
      uischema = findBlockUischema(
        uischemaCurrent,
        { name: to.blockName },
        {
          representBy: 'full',
          replaceBy: { uischema: blockUischema },
          insertIndex: to.index
        },
        'add'
      ).uischema
    } else if (isNestedUiSchema(uischemaCurrent)) {
      uischema = findBlockUischema(
        uischemaCurrent,
        { name: to.blockName },
        {
          representBy: 'full',
          replaceBy: { uischema: blockUischema },
          insertIndex: to.index
        },
        'add'
      ).uischema
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Attempt to add block. UiSchema Elements is not of correct type'
      )

      return stepCurrent
    }
  } else if (
    to.mode === 'root' &&
    (isLayoutUiSchema(blockUischema) || isBlockUiSchema(blockUischema)) &&
    isLayoutUiSchema(uischemaCurrent) &&
    uischemaCurrent?.type
  ) {
    if (typeof to.index === 'number') {
      uischema = {
        ...uischemaCurrent,
        elements: [
          // part of the array before the specified index
          ...(uischemaCurrent?.elements?.slice(0, to.index) || []),
          // inserted item
          blockUischema,
          // part of the array after the specified index
          ...(uischemaCurrent?.elements?.slice(to.index) || [])
        ]
      }
    } else {
      uischema = {
        ...uischemaCurrent,
        elements: [...(uischemaCurrent?.elements || []), blockUischema]
      }
    }
  } else if (to.mode === 'zone') {
    if (typeof to.zoneIndex !== 'number') {
      // eslint-disable-next-line no-console
      console.warn('Attempt to add block to zone failed. No zone index given')

      return stepCurrent
    }

    if (isNestedUiSchema(uischemaCurrent)) {
      uischema = {
        ...uischemaCurrent,
        elements: uischemaCurrent.elements?.map((zone, index) => {
          if (to.zoneIndex === index) {
            if (typeof to.index === 'number') {
              return [
                // part of the array before the specified index
                ...(zone.slice(0, to.index) || []),
                // inserted item
                blockUischema,
                // part of the array after the specified index
                ...(zone.slice(to.index) || [])
              ]
            } else {
              return [...zone, blockUischema]
            }
          }

          return zone
        }) as UiSchema[][]
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        'Attempt to add block to zone failed. UiSchema Elements is not of correct type'
      )

      return stepCurrent
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Attempt to add block failed. You likely mismatched the mode. If "zone" you also need to pass a zoneIndex'
    )

    return stepCurrent
  }

  return {
    ...stepCurrent,
    uischema,
    schema: {
      ...schema,
      required
    }
  }
}
