import type { Currency } from '@epilot/pricing'
import { DEFAULT_CURRENCY, formatAmount } from '@epilot/pricing'

import type { Coupon } from '../types'

import { omitTrailingDoubleDecimalZeros } from './pricing'

const formatPercentageDiscount = (coupon: Coupon) =>
  `-${coupon.percentage_value}%`

const formatFixedDiscount = (coupon: Coupon) =>
  `-${omitTrailingDoubleDecimalZeros(
    formatAmount({
      amount: coupon.fixed_value ?? 0,
      currency:
        (coupon.fixed_value_currency as Currency | undefined) ??
        DEFAULT_CURRENCY,
      locale: navigator.language,
      enableSubunitDisplay: false
    })
  )}`

export const formatDiscount = (coupon: Coupon) => {
  switch (coupon.type) {
    case 'percentage':
      return formatPercentageDiscount(coupon)
    case 'fixed':
      return formatFixedDiscount(coupon)
  }
}
