import { TileLayoutContainer } from '../../TileLayoutContainer'
import { ProductImageRenderer } from '../TileHeader/ProductImageRenderer'
import { ProductCouponWrapper } from '../TileHeader/ProductImageRenderer/ProductCoupon'

import classes from './TileSections.module.scss'

type TileImageProps = {
  IMAGE_PREVIEW_API_URL: string
}

export const TileImage = ({ IMAGE_PREVIEW_API_URL }: TileImageProps) => {
  return (
    <TileLayoutContainer
      className={classes.imageContainer}
      tileLayoutSection="image"
    >
      <ProductCouponWrapper>
        <ProductImageRenderer IMAGE_PREVIEW_API_URL={IMAGE_PREVIEW_API_URL} />
      </ProductCouponWrapper>
    </TileLayoutContainer>
  )
}
