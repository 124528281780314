import { makeStyles } from '@epilot/journey-elements'
import type { ReactNode } from 'react'

export const IconPlaceholder = ({ children }: { children?: ReactNode }) => {
  const style = useStyles()

  return <span className={style.iconPlaceholder}>{children}</span>
}

const useStyles = makeStyles({
  iconPlaceholder: {
    height: '24px',
    width: '24px',
    minWidth: '24px',
    userSelect: 'none',
    marginRight: '8px'
  }
})
