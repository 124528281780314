import type { Theme } from '@epilot/journey-elements'
import {
  Hidden,
  InputAdornment,
  makeStyles,
  TextField
} from '@epilot/journey-elements'
import type {
  IconConfig,
  EpilotControlProps
} from '@epilot/journey-logic-commons'
import { withJsonFormsControlProps } from '@jsonforms/react'
import type { ChangeEvent, CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { MaterialSymbol } from '../../components/MaterialSymbol'
import { useStepBlockId } from '../../utils'
import { includeCommon } from '../../utils/includeCommon'

type TextFieldControlOptions = Record<string, unknown> & {
  multiline?: boolean
  label?: string
  input_icon?: IconConfig
}

export const useStyles = makeStyles((theme: Theme) => {
  const overridenInputBase = theme?.overrides?.MuiInputBase
    ?.input as CSSProperties

  return {
    inputMultilineOverride: {
      padding: '0 !important'
    },
    multilineOverride: {
      display: 'flex',
      alignItems: 'flex-start',
      ...(overridenInputBase?.padding && {
        padding: overridenInputBase?.padding
      }),
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px',
      '& textarea': {
        minHeight: '38px !important',
        borderRadius: 0
      }
    },
    inputAdornment: {
      color: theme.palette.text.primary
    },
    inputContainer: {
      '& .MuiFilledInput-adornedStart': {
        paddingLeft: '0 !important'
      }
    }
  }
})

export function TextFieldControlPure({
  data,
  handleChange,
  path,
  uischema,
  errors,
  enabled,
  required,
  schema,
  visible,
  id
}: EpilotControlProps) {
  const { t } = useTranslation()
  const stepBlockId = useStepBlockId(path, id)

  const classes = useStyles()
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    // If value is empty set value to undefined to avoid format validation error
    const value = event.target.value === '' ? undefined : event.target.value

    handleChange(path, value)
  }

  const options = uischema.options as TextFieldControlOptions

  const label = options?.label || uischema?.label

  const { errorMessage, examples } = schema

  const placeholder = Array.isArray(examples) && examples[0] ? examples[0] : ''

  return (
    <Hidden xsUp={!visible}>
      <TextField
        InputLabelProps={{ htmlFor: stepBlockId }}
        InputProps={{
          classes: {
            inputMultiline: classes.inputMultilineOverride,
            multiline: classes.multilineOverride
          },
          startAdornment: options?.input_icon ? (
            <InputAdornment className={classes.inputAdornment} position="start">
              <MaterialSymbol {...options.input_icon} />
            </InputAdornment>
          ) : (
            <></>
          )
        }}
        className={options?.multiline ? '' : classes.inputContainer}
        disabled={!enabled}
        error={errors ? true : false}
        fullWidth
        helperText={
          errors
            ? errorMessage && typeof errorMessage === 'string'
              ? errorMessage
              : t('field_required')
            : ''
        }
        id={stepBlockId}
        label={label as string}
        multiline={options?.multiline}
        onChange={handleOnChange}
        placeholder={placeholder}
        required={required}
        value={data || ''}
      />
    </Hidden>
  )
}

export default withJsonFormsControlProps(
  includeCommon({ component: TextFieldControlPure }) as never
)
