import type {
  BlockMappingData,
  Price,
  GeneralTileConfiguration,
  ProductTileData
} from '@epilot/journey-logic-commons'
import type { TimeFrequency } from '@epilot/pricing'
import { normalizeTimeFrequency } from '@epilot/pricing'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useConfig } from '../../../../utils'
import { computeGetAGPrices } from '../../../../utils/services/product-service'

type UseProductsTitlesWithExtIntegrationProps = {
  generalTileConfiguration: GeneralTileConfiguration
  baseProductTiles: ProductTileData[]
}

const USE_GET_EXTERNAL_PRICES_KEY = 'USE_GET_EXTERNAL_PRICES_KEY'
const DEFAULT_PRODUCT_TILES: ProductTileData[] = []

export const useGetExternalPrices = ({
  generalTileConfiguration,
  baseProductTiles
}: UseProductsTitlesWithExtIntegrationProps) => {
  const { getAgMetadata, orgId, isPreviewMode } = generalTileConfiguration
  const { PRICING_API_URL } = useConfig()

  const enabled = Boolean(
    getAgMetadata?.hasGetAGProducts &&
      (getAgMetadata?.getAgPowerUniqueProvider ||
        getAgMetadata?.getAgGasUniqueProvider)
  )

  const getExternalPricesQuery = useQuery({
    queryKey: [
      USE_GET_EXTERNAL_PRICES_KEY,
      orgId,
      getAgMetadata,
      baseProductTiles.map(({ price }) => price._id)
    ],
    queryFn: async () => {
      const resolvedProductTiles = await Promise.all(
        baseProductTiles.map(async (productTile) => {
          if (!productTile.isGetAgPrice) {
            return productTile
          }

          const type = productTile.getAgCategory || 'power'

          const providerId =
            type === 'gas'
              ? getAgMetadata?.getAgGasUniqueProvider?.code || ''
              : getAgMetadata?.getAgPowerUniqueProvider?.code || ''

          if (!providerId) {
            return undefined
          }

          const { yearlyConsumptionHT, yearlyConsumptionNT } =
            getGetAGConsumptionsForComputation(productTile)

          const { data: computedPrice } = await computeGetAGPrices(
            PRICING_API_URL,
            orgId || '',
            {
              postal_code: getAgMetadata?.injectedAddress?.zipCode || '',
              consumption: yearlyConsumptionHT,
              consumption_HT: yearlyConsumptionHT || 0,
              ...(yearlyConsumptionNT && {
                consumption_NT: yearlyConsumptionNT
              }),
              consumption_type:
                type === 'power' ? productTile.getAGConsumptionType : undefined,
              association_id: providerId,
              type,
              billing_period: productTile.billingPeriod,
              ...(type === 'gas' && {
                concession_type: productTile.getAGConcessionType
              }),
              ...(type === 'power' && {
                meter_type: productTile.getAGMeterType
              })
            }
          )

          return {
            ...productTile,
            price: {
              ...productTile.price,
              getag_price: {
                ...computedPrice,
                inputs: {
                  consumptionHT: yearlyConsumptionHT,
                  consumptionNT: yearlyConsumptionNT,
                  consumptionType: productTile.getAGConsumptionType,
                  zipCode: getAgMetadata?.injectedAddress?.zipCode || '',
                  city: getAgMetadata?.injectedAddress?.city || '',
                  providerId,
                  type
                }
              }
            }
          }
        })
      )

      return resolvedProductTiles.filter(Boolean) as ProductTileData[]
    },
    onError: (error: Error) => {
      console.error('Error while fetching external prices', error)
    },
    enabled
  })

  const productTiles = useMemo(() => {
    if (!getAgMetadata?.hasGetAGProducts) {
      return baseProductTiles
    }

    if (
      getAgMetadata?.getAgPowerUniqueProvider ||
      getAgMetadata?.getAgGasUniqueProvider
    ) {
      return getExternalPricesQuery.data ?? DEFAULT_PRODUCT_TILES
    }

    return isPreviewMode
      ? baseProductTiles
      : baseProductTiles.filter((productTile) => !productTile.isGetAgPrice)
  }, [
    baseProductTiles,
    getAgMetadata?.getAgPowerUniqueProvider,
    getAgMetadata?.getAgGasUniqueProvider,
    getAgMetadata?.hasGetAGProducts,
    isPreviewMode,
    getExternalPricesQuery.data
  ])

  return {
    ...getExternalPricesQuery,
    productTiles,
    isLoadingProductTiles:
      getExternalPricesQuery.isLoading || getAgMetadata?.isLoadingGetAGProviders
  }
}

const getGetAGConsumptionsForComputation = (productTile: ProductTileData) => {
  let yearlyConsumptionHT
  let yearlyConsumptionNT

  if (!productTile.price.is_composite_price) {
    if (productTile.price.get_ag?.type === 'base_price') {
      return { yearlyConsumptionHT, yearlyConsumptionNT }
    }

    if (productTile.price.get_ag?.tariff_type === 'NT') {
      yearlyConsumptionNT = productTile.price.blockMappingData?.numberInput || 0
    } else {
      yearlyConsumptionHT = productTile.price.blockMappingData?.numberInput || 0
    }
  } else {
    const price_components: Price[] = productTile.price.price_components || []
    const { constHT, constNT } = price_components.reduce(
      (acc: { constHT?: number; constNT?: number }, priceComponent: Price) => {
        const yearlyVariableInput =
          priceComponent.get_ag?.type === 'work_price' &&
          normalizeVariableInput(priceComponent.blockMappingData)

        if (typeof yearlyVariableInput !== 'number') {
          return acc
        }

        if (priceComponent.get_ag?.tariff_type === 'NT') {
          acc.constNT = (acc.constNT || 0) + yearlyVariableInput
        } else {
          acc.constHT = (acc.constHT || 0) + yearlyVariableInput
        }

        return acc
      },
      {}
    )

    yearlyConsumptionHT = constHT
    yearlyConsumptionNT = constNT
  }

  return { yearlyConsumptionHT, yearlyConsumptionNT }
}

const normalizeVariableInput = (blockMappingData?: BlockMappingData) => {
  const numberInput = blockMappingData?.numberInput
  const frequencyUnit = blockMappingData?.frequencyUnit

  if (!numberInput || frequencyUnit === 'yearly') {
    return numberInput
  }

  return normalizeTimeFrequency(
    numberInput,
    frequencyUnit as TimeFrequency,
    'yearly'
  )
}
