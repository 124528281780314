import type {
  PricingBlockMapping,
  GeneralTileConfiguration,
  ProductTileData,
  SelectionType,
  SelectedProductTile
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo } from 'react'

import { useJourneyContext } from '../../../../../utils'
import { useGetExternalPrices } from '../../hooks/useGetExternalPrices'
import {
  computeSelectedProductTilesBlockMappings,
  computeSingleSelectedProductTileBlockMappings,
  extractProductTileState,
  filterValidSelection
} from '../../utils/product-tiles-control-utils'
import { CatalogProductTilesControlHtml } from '../htmlComponents/CatalogProductTilesControlHtml'
import { Loading } from '../Loading'
import { NoProducts } from '../NoProducts'

export type CatalogProductTilesControlProps = {
  /**
   * An custom translation function to enable tile translations.
   *
   * By default, a noop translation function is used (no translations).
   */
  t: TFunction

  /**
   * Defines whether the user can select 1 or many product tiles.
   */
  selectionType?: SelectionType

  /**
   * Defines the general Product Tiles configurations.
   */
  generalTileConfiguration: GeneralTileConfiguration

  data: ProductTileData[]

  onChange?: (value?: SelectedProductTile | SelectedProductTile[]) => void
  selectedProductTiles?: SelectedProductTile | SelectedProductTile[]
  blockMappings?: PricingBlockMapping[]
  id: string
}

const DEFAULT_OMITTED_PRICE_COMPONENTS = [] as const

export const CatalogProductTilesControl = (
  props: CatalogProductTilesControlProps
) => {
  const {
    data: productTilesData,
    selectionType,
    generalTileConfiguration,
    t,
    onChange,
    selectedProductTiles,
    blockMappings,
    id
  } = props

  const { context } = useJourneyContext()

  const omittedPriceComponents = useMemo(() => {
    const path = generalTileConfiguration.blockPath

    return (
      (path && context.omittedPriceComponentsPerStep[path]) ||
      DEFAULT_OMITTED_PRICE_COMPONENTS
    )
  }, [
    context.omittedPriceComponentsPerStep,
    generalTileConfiguration.blockPath
  ])

  const baseProductTiles = useMemo(
    () =>
      extractProductTileState(
        omittedPriceComponents,
        productTilesData,
        generalTileConfiguration.blockMappings,
        blockMappings
      ),
    [
      productTilesData,
      generalTileConfiguration.blockMappings,
      blockMappings,
      omittedPriceComponents
    ]
  )

  const { productTiles, isLoadingProductTiles } = useGetExternalPrices({
    generalTileConfiguration,
    baseProductTiles
  })

  /**
   * We're recomputing product tiles.
   * If the block mappings have changed, the returned computation will be different,
   * which will trigger a call to onChange.
   */
  useEffect(() => {
    if (selectedProductTiles && onChange && !isLoadingProductTiles) {
      const tiles = Array.isArray(selectedProductTiles)
        ? computeSelectedProductTilesBlockMappings(
            productTiles,
            selectedProductTiles
          )
        : computeSingleSelectedProductTileBlockMappings(
            productTiles,
            selectedProductTiles
          )

      const validSelection = filterValidSelection(productTiles, tiles)

      if (Array.isArray(validSelection) && !validSelection.length) {
        onChange(undefined)
      } else if (!isEqual(validSelection, selectedProductTiles)) {
        onChange(validSelection)
      }
    }
  }, [isLoadingProductTiles, onChange, productTiles, selectedProductTiles])

  if (isLoadingProductTiles) {
    return <Loading />
  }

  if (!isLoadingProductTiles && (!productTiles || productTiles.length === 0)) {
    // if we are showing additional address, we should not show empty state.
    // otherwise, we should show it.
    return generalTileConfiguration.getAgMetadata
      ?.showAdditionalAddress ? null : (
      <NoProducts />
    )
  }

  const commonProps = {
    generalTileConfiguration,
    onChange,
    productTiles,
    selectedProductTiles,
    selectionType,
    t,
    id
  }

  return <CatalogProductTilesControlHtml {...commonProps} />
}
