import Close from '@epilot360/icons/react/Close'
import { useMemo } from 'react'

import {
  Paper,
  LinearProgress,
  useTheme,
  ElevatedIconButton,
  InlineSpacing,
  makeStyles
} from '../'

import { isUploadStatus } from './helpers'
import type { FileProgressProps } from './types'
import { FileUploadStatus } from './types'

const useStyle = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius:
      theme.shape?.borderRadius !== undefined
        ? `min(${theme.shape.borderRadius}px, 20px)`
        : '4px'
  }
}))

export function FileProgress({
  fileName,
  progress,
  onClickCancel,
  status,
  statusComponents,
  ...rest
}: FileProgressProps) {
  const theme = useTheme()
  const styles = useStyle()
  const isStatus = useMemo(() => isUploadStatus(status), [status])
  const hasProgress =
    (isStatus.Progress || isStatus.Successful) &&
    typeof progress !== 'undefined'

  return (
    <Paper className={styles.paper} {...rest}>
      <span
        style={{
          width: 16,
          backgroundColor: hasProgress
            ? theme.palette.action.hover
            : 'transparent'
        }}
      >
        &#160;
      </span>
      <span
        style={{
          flexDirection: 'column',
          display: 'flex',
          flex: 1,
          paddingTop: 8,
          paddingBottom: 8,
          background: hasProgress
            ? `linear-gradient(90deg, ${theme.palette.action.hover} ${progress}%, transparent ${progress}%)`
            : undefined
        }}
      >
        <span
          style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}
        >
          <span>
            <div>
              <b>{getStringByStatus(status, statusComponents)}</b> ({fileName})
            </div>
            {hasProgress && <div>{progress}% uploaded</div>}
          </span>

          {isStatus.Progress && onClickCancel && (
            <InlineSpacing alignItems="center" scale={1}>
              <ElevatedIconButton onClick={onClickCancel} variant="circle">
                <Close fill={theme.palette.secondary.main} variant="filled" />
              </ElevatedIconButton>
            </InlineSpacing>
          )}
        </span>
        {hasProgress && (
          <LinearProgress
            color="primary"
            value={progress}
            variant="determinate"
          />
        )}
      </span>
      <span
        style={{
          width: 16,
          backgroundColor:
            progress && progress >= 100
              ? theme.palette.action.hover
              : 'transparent'
        }}
      >
        &#160;
      </span>
    </Paper>
  )
}

function getStringByStatus(
  status: FileProgressProps['status'],
  statusComponents: FileProgressProps['statusComponents']
) {
  switch (status) {
    case FileUploadStatus.Cancelled:
      return statusComponents?.Cancelled || 'Cancelled!'
    case FileUploadStatus.Failed:
      return statusComponents?.Failed || 'Failed!'
    case FileUploadStatus.Successful:
      return statusComponents?.Successful || 'Finished.'
    default:
      return statusComponents?.Progress || 'Uploading ...'
  }
}
