import { Badge as MuiBadge } from '@material-ui/core'
import type { ElementRef, PropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import type { BadgeProps } from './types'

export const Badge = forwardRef<
  ElementRef<typeof MuiBadge>,
  PropsWithoutRef<BadgeProps>
>((props, ref) => <MuiBadge ref={ref} showZero {...props} />)

Badge.displayName = 'Badge'
