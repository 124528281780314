import type { Theme } from '@material-ui/core'
import {
  Icon as MuiIcon,
  SvgIcon as MuiSvgIcon,
  useTheme
} from '@material-ui/core'
import type { CSSProperties } from 'react'

import type { IconProps, SvgIconProps, EIconProps, FontSize } from './types'

export default function Icon(props: IconProps) {
  const { name, fontSize, customColor, style, ...rest } = props

  return (
    <MuiIcon
      {...rest}
      fontSize={fontSize}
      style={{ ...style, color: customColor }}
    >
      {name}
    </MuiIcon>
  )
}

export function EIcon(props: EIconProps) {
  const { name, color, fontSize = 'medium', htmlColor, style, ...rest } = props

  const theme = useTheme()

  const colorCss = getColor(theme, color, htmlColor)

  const sizeCss = getSize(fontSize)

  const componentStyle = {
    ...style,
    display: 'inline-flex',
    fontSize: sizeCss,
    color: colorCss
  } as CSSProperties

  return (
    <i
      className={`epilot-icon epilot-icon-${name}  notranslate`}
      style={componentStyle}
      {...rest}
    />
  )
}

function getSize(fontSize?: FontSize | number) {
  if (fontSize) {
    switch (fontSize) {
      case 'inherit':
        return ''
      case 'large':
        return 32
      case 'small':
        return 20
      case 'medium':
        return 24
      default:
        return fontSize
    }
  }

  return 24
}
function getColor(theme: Theme, color?: string, htmlcolor?: string) {
  switch (color) {
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    case 'action':
      return theme.palette.success.main
    case 'disabled':
      return theme.palette.action.disabled
    case 'error':
      return theme.palette.error.main
    default:
      return htmlcolor
  }
}

export function SvgIcon(props: SvgIconProps) {
  return <MuiSvgIcon {...props} />
}
