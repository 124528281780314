import type { Price, PriceItem, PriceTier } from '@epilot/journey-logic-commons'
import { omitTrailingDoubleDecimalZeros } from '@epilot/journey-logic-commons'
import type { Currency } from '@epilot/pricing'
import {
  computeQuantities,
  getDisplayTierByQuantity,
  getTierDescription,
  PricingModel
} from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { getFormattedQuantity } from '../../../utils/products/quantity-utils'

export const ComponentMappingDataVolume = ({
  item,
  t,
  quantity,
  showTrailingDecimalZeros
}: {
  item: PriceItem
  t: TFunction
  quantity?: number
  showTrailingDecimalZeros: boolean
}) => {
  const price = item._price as Price
  const formattedQuantity = getFormattedQuantity(price, t)

  const { quantityToSelectTier } = computeQuantities(
    price,
    quantity as number,
    {
      frequency_unit: item._price?.blockMappingData?.frequencyUnit,
      value: item._price?.blockMappingData?.numberInput
    }
  )

  const tier = getDisplayTierByQuantity(
    item._price?.tiers || [],
    quantityToSelectTier,
    PricingModel.tieredVolume,
    item._price?.is_tax_inclusive,
    item._price?.tax
  )

  const displayableTier: PriceTier = {
    ...tier,
    unit_amount: tier?.unit_amount_gross,
    unit_amount_decimal: tier?.unit_amount_gross_decimal,
    flat_fee_amount: tier?.flat_fee_amount_gross,
    flat_fee_amount_decimal: tier?.flat_fee_amount_gross_decimal
  }

  const amountLabel = getTierDescription(
    PricingModel.tieredVolume,
    displayableTier,
    item._price?.unit,
    navigator.language,
    item.currency as Currency,
    (key) => t(key),
    {
      showStartsAt:
        item._price?.price_display_in_journeys === 'show_as_starting_price',
      enableSubunitDisplay: true
    }
  )

  return (
    <>
      {formattedQuantity ? `${formattedQuantity} | ` : ' '}
      {showTrailingDecimalZeros
        ? amountLabel
        : omitTrailingDoubleDecimalZeros(amountLabel!)}
    </>
  )
}
