import { makeStyles } from '@epilot/journey-elements'

export const useFieldAutocompleteStyles = makeStyles({
  disabled: {
    color: 'currentColor'
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  listBoxDesktop: {
    maxHeight: '165px',
    overflowY: 'auto',
    paddingLeft: '0'
  },
  listBoxMobile: {
    maxHeight: '217px',
    overflowY: 'auto',
    paddingLeft: '0'
  }
})
