import { SALUTATION_OPTIONS } from '@epilot/journey-logic-commons'

const BASE_SALUTATION_OPTIONS = SALUTATION_OPTIONS.slice(0, 3)

export const showSalutationOptionsButton = (options: string[]) => {
  return (
    options.length < 4 &&
    options.every((option) => BASE_SALUTATION_OPTIONS.includes(option))
  ) // ['Mr·', 'Ms. / Mrs.', 'Other']
}
