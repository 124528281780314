import type { StepState } from '../../types'
import type { BlockLocatorDetails, StepExtended } from '../blockController'
import { blockController } from '../blockController'

/**
 * Validates whether a certain block is considered valid
 * by checking whether the given block is hidden and
 * if the state consists _isValid: false for the given blockName
 * If a block with `blockName` doesn't exist or is hidden, it returns true.
 */
export const isBlockValid = (
  step: StepExtended,
  state: StepState,
  blockName: string
): boolean => {
  const block = blockController.findBlock(step, { name: blockName })

  return isBlockValidHelper(block, state, blockName)
}

export const isBlockValidHelper = (
  block: BlockLocatorDetails | undefined,
  state: StepState,
  blockName: string
) => {
  // check if the block is hidden or doesn't exist
  if (block?.uischema?.options?.display === false) {
    return true
  }

  // use the type guard to check and narrow down the type
  const blockState = state[blockName]

  if (!isBlockState(blockState)) {
    return true
  }

  return blockState._isValid !== false
}

function isBlockState(
  value: unknown
): value is { _isValid?: boolean; [key: string]: unknown } {
  return typeof value === 'object' && value !== null && '_isValid' in value
}
