import type {
  Journey,
  Settings,
  Step,
  StepState
} from '@epilot/journey-logic-commons'
import {
  blockController,
  CONTROL_NAME,
  isLauncherJourney
} from '@epilot/journey-logic-commons'
import uniq from 'lodash/uniq'
import type { ComponentProps, Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { makeJourneyEmptyStepValues } from '../../utils'
import type {
  CustomerPortalClient,
  EntitySlug
} from '../clients/customer-portal-client'
import type { BaseEntity, EntityClient } from '../clients/entity-client'
import { useEntityClient } from '../clients/entity-client'
import type { HistoryStackState } from '../hooks/useHistoryStack'

import { getOmittedPriceComponentsPerStep } from './getOmittedPriceComponentsPerStep'
import { prepareDataForContext } from './prepareDataForContext'
import type {
  JourneyContextState,
  JourneyContextValue,
  WithSessionIdGetter
} from './types'

type JourneyContextContextValue = WithSessionIdGetter & {
  context: JourneyContextValue
  updateContext: Dispatch<SetStateAction<JourneyContextState>>
}

const JourneyContext = createContext<JourneyContextContextValue | undefined>(
  undefined
)

export const useJourneyContext = () => {
  const context = useContext(JourneyContext)

  if (!context) {
    throw new Error(
      'useJourneyContext must be used within a JourneyContextProvider'
    )
  }

  return context
}

type JourneyContextProviderProps = WithSessionIdGetter &
  Omit<ComponentProps<typeof JourneyContext.Provider>, 'value'> & {
    /**
     * initialState is only relevant within journey builder, and can be
     * disregarded when journey app is running in standalone mode
     */
    initialState?: StepState[]
    journey: Journey
    history: HistoryStackState
    blocksDisplaySettings?: JourneyContextState['blocksDisplaySettings']
    isPreview?: boolean
    contextValues: Record<string, unknown>
    shouldCheckContextValues: boolean
  }

const initialValues: Omit<
  JourneyContextState,
  '_stepsStateArray' | '_errors' | 'journeyStepStateMap'
> = {
  _activeLinkedJourneyId: undefined,
  errorValidationMode: 'ValidateAndHide',
  _productsAdditionalAddresses: undefined
}

const DEFAULT_STEP_STATE_ARRAY: Array<StepState> = []

const getStepIdFromIndex = (steps: Step[], index: number): string | undefined =>
  steps[index]?.stepId

export function JourneyContextProvider({
  journey,
  initialState,
  history: { stack, currentIndex },
  blocksDisplaySettings,
  isPreview = false,
  sessionIdGetter,
  contextValues,
  shouldCheckContextValues,
  ...props
}: JourneyContextProviderProps) {
  const [context, updateContext] = useState<JourneyContextState>({
    ...initialValues,
    _errors: Array.from({ length: journey.steps.length }, () => []),
    journeyStepStateMap: {
      [journey.journeyId]: initialState ?? makeJourneyEmptyStepValues(journey)
    }
  })

  const _stepsStateArray =
    context._activeLinkedJourneyId &&
    context._activeLinkedJourneyId in context.journeyStepStateMap
      ? context.journeyStepStateMap[context._activeLinkedJourneyId]
      : (context.journeyStepStateMap[journey.journeyId] ??
        DEFAULT_STEP_STATE_ARRAY)

  const productSelectionBlocksWithOptionalMappings = useMemo(
    () =>
      blockController
        .findBlocks(journey.steps, {
          type: CONTROL_NAME.PRODUCT_SELECTION_CONTROL
        })
        .filter(
          (block) => block.uischema.options?.optionalPriceComponentMappings
        ),
    [journey.steps]
  )

  const journeyIsLauncherJourney = useMemo(
    () => isLauncherJourney(journey.steps),
    [journey.steps]
  )

  const settings = useMemo(() => {
    const journeySettings = journey.settings

    if (
      journeyIsLauncherJourney &&
      context._activeLinkedJourneyId &&
      context._linkedJourneyMap
    ) {
      const linkedJourney =
        context._linkedJourneyMap[context._activeLinkedJourneyId]
      const addressSuggestionsFileUrl =
        linkedJourney?.settings?.addressSuggestionsFileUrl
      const addressSuggestionsFileId =
        linkedJourney?.settings?.addressSuggestionsFileId

      return {
        ...journey.settings,
        ...(addressSuggestionsFileUrl && { addressSuggestionsFileUrl }),
        ...(addressSuggestionsFileId && { addressSuggestionsFileId })
      } as Settings
    }

    return journeySettings
  }, [
    context._activeLinkedJourneyId,
    context._linkedJourneyMap,
    journey.settings,
    journeyIsLauncherJourney
  ])

  // Retrieve context entities
  const contextSchema = journey.contextSchema || []
  const neededAttributes: Record<string, string[]> = {}
  const [contextEntitiesData, setContextEntitiesData] =
    useState<Record<string, BaseEntity>>()
  const [requiredContextMissing, setRequiredContextMissing] =
    useState<Boolean>(false)
  const { client, isPortal } = useEntityClient()

  const attributeBlocks = useMemo(
    () =>
      blockController.findBlocks(journey.steps, {
        type: CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL
      }),
    [journey.steps]
  )
  const lookupBlocks = useMemo(
    () =>
      blockController.findBlocks(journey.steps, {
        type: CONTROL_NAME.ENTITY_LOOKUP_CONTROL
      }),
    [journey.steps]
  )

  useEffect(() => {
    attributeBlocks.forEach((attributeBlock) => {
      const key: string =
        attributeBlock.uischema.options?.relatedContextEntity?.toLowerCase()

      if (neededAttributes[key] === undefined) {
        neededAttributes[key] = []
      }
      neededAttributes[key].push(attributeBlock.uischema.options?.attributeName)
    })

    lookupBlocks.forEach((lookupBlock) => {
      const key: string =
        lookupBlock.uischema.options?.relatedContextEntity?.toLowerCase()

      if (neededAttributes[key] === undefined) {
        neededAttributes[key] = []
      }
      neededAttributes[key].push(
        lookupBlock.uischema.options?.relatedContextAttribute
      )
    })

    async function fetchEntities() {
      if (isPreview) {
        return
      }
      const entitiesData: Record<string, BaseEntity> = {}

      if (contextSchema.length > 0) {
        for (const c of contextSchema) {
          const key = c.paramKey.toLowerCase()
          const slug = c.type
          const entityAttributes = neededAttributes[key] || []

          if (slug === 'String') {
            continue
          }
          if (slug === 'contract') {
            entityAttributes.push('customer')
          }
          const entityId = contextValues[key]

          if (shouldCheckContextValues && !entityId && c.isRequired) {
            setRequiredContextMissing(true)
            // eslint-disable-next-line no-console
            console.error('Missing required context item', c)
          } else if (entityId && c.shouldLoadEntity === true) {
            if (isPortal) {
              await (client as CustomerPortalClient)
                .searchPortalUserEntities(null, {
                  slug: slug as EntitySlug,
                  fields: uniq(['_id', ...entityAttributes]),
                  hydrate: true
                })
                .then((res) => {
                  const results = res?.data?.results || []
                  const result = results.filter(
                    (res) => res._id === entityId
                  )[0]

                  if (result) {
                    entitiesData[key] = result
                  } else {
                    setRequiredContextMissing(true)
                    console.error('Provided id did not retrieve any entity', c)
                  }
                })
                .catch((e) => {
                  setRequiredContextMissing(true)
                  console.error('Entity failed to load', c, e)
                })
            } else {
              await (client as EntityClient)
                .searchEntities(null, {
                  q: `_id:${entityId} AND _schema:${slug}`,
                  fields: uniq(['_id', ...entityAttributes]),
                  hydrate: true
                } as never)
                .then((res) => {
                  const results = res?.data?.results || []

                  if (results[0]) {
                    entitiesData[key] = results[0]
                  } else {
                    setRequiredContextMissing(true)
                    console.error('Provided id did not retrieve any entity', c)
                  }
                })
                .catch((e) => {
                  setRequiredContextMissing(true)
                  console.error('Entity failed to load', c, e)
                })
            }
          }
        }
      }
      setContextEntitiesData(entitiesData)
    }
    fetchEntities()
  }, [contextValues, shouldCheckContextValues])

  const value = useMemo<JourneyContextContextValue>(() => {
    const omittedPriceComponentsPerStep = getOmittedPriceComponentsPerStep({
      stepsStateArray: _stepsStateArray,
      steps: journey.steps,
      productSelectionBlocksWithOptionalMappings:
        productSelectionBlocksWithOptionalMappings
    })

    const preparedData = prepareDataForContext(
      _stepsStateArray,
      context._journeySources,
      stack
    )

    const currentStepName = getStepIdFromIndex(journey.steps, currentIndex)

    return {
      context: {
        ...context,
        /* If we're in preview mode, always override errorValidationMode to be 'ValidateAndHide' */
        ...(isPreview && { errorValidationMode: 'ValidateAndHide' }),
        omittedPriceComponentsPerStep,
        blocksDisplaySettings,
        _stepsStateArray,
        ...preparedData,
        isLauncherJourney: journeyIsLauncherJourney,
        _navigationInfo: {
          currentStepIndex: currentIndex,
          currentStepId: currentStepName,
          stack
        },
        journey: {
          ...journey,
          settings
        },
        _isPreview: isPreview,
        _contextEntitiesData: contextEntitiesData,
        _missingContext: requiredContextMissing
      },
      updateContext,
      sessionIdGetter
    }
  }, [
    _stepsStateArray,
    journey,
    productSelectionBlocksWithOptionalMappings,
    context,
    stack,
    currentIndex,
    isPreview,
    blocksDisplaySettings,
    journeyIsLauncherJourney,
    settings,
    contextEntitiesData,
    requiredContextMissing,
    sessionIdGetter
  ])

  return <JourneyContext.Provider {...props} value={value} />
}

JourneyContextProvider.displayName = 'JourneyContextProvider'
