import {
  createStyles,
  makeStyles,
  Select as MuiSelect,
  InputLabel as MuiInputLabel
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'

import type { Theme } from '../'
import { FormControl, FormHelperText } from '../'
import { useTextFieldStyles } from '../TextField/styles'

import type { SelectProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholderColor: {
      color: theme.palette.text.secondary
    },
    selectFocus: {
      '& :focus': {
        background: 'inherit'
      }
    },
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px'
    }
  })
)

export function Select({ customBackgroundColor, ...props }: SelectProps) {
  const classes = useStyles(props)
  const { root } = useTextFieldStyles({ customBackgroundColor })

  const {
    id = 'no-id',
    children,
    disabled,
    error,
    fullWidth = false,
    label,
    onChange,
    placeholder,
    required: _r = false,
    value = '',
    containerStyle,
    IconComponent = ExpandMore,
    variant = 'filled',
    size = 'medium',
    ...rest
  } = props
  const htmlId = id.replace(/^#\/properties\//, '')

  return (
    <FormControl
      disabled={disabled}
      error={!!error}
      fullWidth={fullWidth}
      size={size}
      style={containerStyle}
      variant={variant}
    >
      <MuiInputLabel htmlFor={id} shrink>
        {label}
      </MuiInputLabel>
      <MuiSelect
        id={`select-${htmlId}`}
        inputProps={{ id }}
        name={htmlId}
        {...rest}
        IconComponent={IconComponent}
        MenuProps={{ classes: { paper: classes.paper } }}
        className={
          !value
            ? `${root} ${classes.placeholderColor} ${classes.selectFocus}`
            : `${root} ${classes.selectFocus}`
        }
        displayEmpty
        onChange={onChange}
        renderValue={
          value !== '' || placeholder === ''
            ? undefined
            : () => <div aria-label="None">{placeholder}</div>
        }
        value={value}
      >
        {children}
      </MuiSelect>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}
