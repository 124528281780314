import type { ProductTileData } from '@epilot/journey-logic-commons'

export const filterValuesByProductCategories = (
  initialValues: ProductTileData[],
  selectedCategories?: string[]
): ProductTileData[] => {
  if (!selectedCategories?.length) {
    // Return the initial values if either selectedCategories is undefined or empty, or if initialValues is undefined
    return initialValues
  }

  // Filter the array of initial values
  return initialValues.filter((value) => {
    /**
     * @todo Evaluate if this is the desired behavior,
     * it seems like it would make sense to exclude if value is not an object or is null, not include
     */
    if (typeof value !== 'object' || value === null) {
      // Include if value is not an object or is null
      return true
    }

    const product = value['product']

    if (
      product &&
      typeof product === 'object' &&
      Array.isArray(product.categories)
    ) {
      // Check if at least one of the product categories was selected
      return product.categories.some(
        (productCategory: unknown) =>
          typeof productCategory === 'string' &&
          selectedCategories.includes(productCategory)
      )
    }

    // Exclude if value is an object with missing or invalid properties
    return false
  })
}
