import { findStepIndexById } from '../navigation'
import type { Logic, LogicAction, LogicCondition, Step } from '../utils'
import {
  BLOCK_LOCATOR_SEPARATOR,
  logicActionTypes,
  parseLogicActionTarget
} from '../utils'

export type InjectFilterLogic = Logic & {
  blockName: string
  stepId: string
  conditionsRelatedToStep: LogicCondition[]
  actionsIncludesFilter: (LogicAction & {
    targetStepIndex: number
    targetStepId?: string
    targetBlockName: string
  })[]
  injectedFilterValueName?: string
}

/**
 * Return array, each element is an array of logic objects that correspondes to Inject Filter
 * This means, ALL OF THE FOLLOWING TRUE:
 * 1. One of the conditions includes a block in the Step
 * 2. One of the actions include the Inject Filter action type
 */
export function getLogicsInjectFilter(
  steps: Step[],
  logics: Logic[]
): InjectFilterLogic[][] {
  const logicsPerStepIndex = Array.from({
    length: steps.length
  }).map<Array<InjectFilterLogic>>(() => [])

  steps.forEach((step, i) => {
    const logsInStep: InjectFilterLogic[] = []

    logics.forEach((log) => {
      let blockName = ''
      // One of the conditions includes a block in the Step
      const condSourceStep = log.conditions?.filter((cond) => {
        const parts = cond.conditionSource?.split(BLOCK_LOCATOR_SEPARATOR)

        if (parts && parts[0] === `$$${step.stepId}`) {
          blockName = parts[1]
            ? parts[1].endsWith('$$')
              ? parts[1].slice(0, -2)
              : parts[1]
            : ''

          return true
        }

        return false
      })

      if (Array.isArray(condSourceStep) && condSourceStep.length > 0) {
        // One of the actions include the Inject Filter action type
        const actFilter = log.actions
          ?.filter((act) =>
            logicActionTypes.InjectFilter.includes(act.actionType)
          )
          .map((e) => {
            const target = parseLogicActionTarget(e.target)
            const targetParts = target?.split(BLOCK_LOCATOR_SEPARATOR)

            return {
              ...e,
              targetBlockName: targetParts?.[1],
              targetStepId: targetParts?.[0],
              targetStepIndex: findStepIndexById(
                steps,
                targetParts?.[0] || '',
                0
              )
            }
          })

        if (Array.isArray(actFilter) && actFilter.length > 0) {
          logsInStep.push({
            ...log,
            blockName: blockName,
            stepId: step.stepId,
            conditionsRelatedToStep: condSourceStep,
            actionsIncludesFilter: actFilter as never
          })
        }
      }
    })

    logicsPerStepIndex[i] = logsInStep
  })

  return logicsPerStepIndex
}
