import type { AvailabilityCheck, Address } from '@epilot/journey-logic-commons'
import axios from 'axios'
import type { MutableRefObject } from 'react'
import type { UseFormSetValue } from 'react-hook-form'

import { apiCall } from '../../api'
import {
  assignNewMarker,
  getCoordinatesFromAddress,
  GOOGLE_MAP_ID
} from '../../utils'

export const GERMANY_CENTER = {
  lat: 51.1657,
  lng: 10.4515
}

export const MAP_SETTINGS = {
  zoom: 6,
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  streetViewControl: false,
  rotateControl: true,
  rotateControlOptions: {
    position: 7
  },
  zoomControlOptions: {
    position: 9
  },
  mapId: GOOGLE_MAP_ID,
  tilt: 0,
  center: GERMANY_CENTER,
  gestureHandling: 'cooperative' as const,
  styles: [
    {
      featureType: 'all' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'administrative' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'on' }]
    },
    {
      featureType: 'poi' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit' as const,
      elementType: 'labels' as const,
      stylers: [{ visibility: 'off' }]
    }
  ]
}

export type AddressMapData = {
  zipCode: string | undefined
  city: string | undefined
  streetName: string | undefined
  houseNumber: string | undefined
  suburb?: string | undefined
  coordinates?: string
  plotOfLand?: string | undefined
  plotArea?: string | undefined
}

export type HouseNumberKey = 'houseNumber' | 'streetNumber'

export const getHouseAddress = (addressData: AddressMapData) => {
  return addressData?.zipCode && addressData?.city
    ? `${addressData?.streetName ?? ''} ${addressData?.houseNumber ?? ''}${
        addressData?.streetName || addressData?.houseNumber ? ',' : ''
      } ${addressData?.zipCode}, ${addressData?.city}`
    : undefined
}

export const validateAndSetValue = (
  key:
    | 'zipCode'
    | 'streetName'
    | 'streetNumber'
    | 'city'
    | 'houseNumber'
    | 'suburb'
    | 'plotOfLand'
    | 'plotArea'
    | 'coordinates',
  newValue: string,
  prevValue: string,
  setValue: UseFormSetValue<AvailabilityCheck | Address>
) => {
  if (newValue === prevValue) return
  if (newValue) {
    setValue(key, newValue, {
      shouldValidate: true
    })
  } else {
    setValue(key, '')
  }
}

export const fetchCoordinatesData = async ({
  controller,
  controllerRef,
  address,
  pinRef,
  addressData,
  map,
  setValue,
  googleMapsApiUrl,
  publicToken
}: {
  controller?: AbortController
  controllerRef?: MutableRefObject<AbortController>
  address: string
  pinRef: MutableRefObject<google.maps.marker.AdvancedMarkerElement | null>
  addressData: AddressMapData | undefined | null
  map: google.maps.Map
  setValue: UseFormSetValue<Address>
  googleMapsApiUrl: string
  publicToken: string
}) => {
  try {
    // abort the previous request
    if (controller && controllerRef) {
      controller.abort()
      // create a new controller for the current request
      controllerRef.current = new AbortController()
    }

    const newController = controllerRef?.current
    const newSignal = newController?.signal

    const location = await getCoordinatesFromAddress(
      googleMapsApiUrl,
      address,
      publicToken,
      newSignal
    )

    if (location) {
      assignNewMarker({ pinRef, coordinates: location, map })

      validateAndSetValue(
        'coordinates',
        `${location.lat}, ${location.lng}`,
        addressData?.coordinates || '',
        setValue
      )

      map.setCenter(location)
      map.setZoom(
        addressData?.houseNumber ? 19 : addressData?.streetName ? 16 : 14
      )
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.name === 'AbortError') {
      console.log('Previous request aborted:', error.message)
    } else {
      console.error(`Fetching address coordinates error: ${error}`)
    }
  }
}

export const validateAndSetValues = ({
  addressFromCoordinates,
  addressData,
  setValue,
  houseNumberKey
}: {
  addressFromCoordinates: AddressMapData
  addressData: AddressMapData | undefined | null
  setValue: UseFormSetValue<AvailabilityCheck | Address>
  houseNumberKey: HouseNumberKey
}) => {
  validateAndSetValue(
    'zipCode',
    addressFromCoordinates.zipCode || '',
    addressData?.zipCode || '',
    setValue
  )
  validateAndSetValue(
    'city',
    addressFromCoordinates.city || '',
    addressData?.city || '',
    setValue
  )
  validateAndSetValue(
    'suburb',
    addressFromCoordinates.suburb || '',
    addressData?.suburb || '',
    setValue
  )
  validateAndSetValue(
    'streetName',
    addressFromCoordinates.streetName || '',
    addressData?.streetName || '',
    setValue
  )
  validateAndSetValue(
    houseNumberKey,
    addressFromCoordinates.houseNumber || '',
    addressData?.houseNumber || '',
    setValue
  )
  validateAndSetValue(
    'plotOfLand',
    addressFromCoordinates.plotOfLand || '',
    addressData?.plotOfLand || '',
    setValue
  )
  validateAndSetValue(
    'plotArea',
    addressFromCoordinates.plotArea || '',
    addressData?.plotArea || '',
    setValue
  )
  validateAndSetValue(
    'coordinates',
    addressFromCoordinates.coordinates || '',
    addressData?.coordinates || '',
    setValue
  )
}

export function convertStringToLatLngObject(str: string): {
  lng: number
  lat: number
} {
  const [latStr, lngStr] = str.split(',').map((coord) => coord.trim())
  const lat = parseFloat(latStr)
  const lng = parseFloat(lngStr)

  if (isNaN(lat) || isNaN(lng)) {
    console.error('Invalid coordinates string')
  }

  return { lat, lng }
}

type AddressResponseData = {
  types: string[]
  longName: string
  shortName: string
}

export async function getAddressFromCoordinates(
  coordinates: google.maps.LatLngLiteral | google.maps.LatLng,
  googleMapsApiUrl: string,
  publicToken: string
): Promise<AddressMapData> {
  try {
    const response = await apiCall({
      method: 'GET',
      url: `${googleMapsApiUrl}/v1/geocode-api/address?latitude=${coordinates.lat}&longitude=${coordinates.lng}`,
      headers: {
        authorization: `Bearer ${publicToken}`
      }
    })

    const address: AddressMapData = {
      zipCode: undefined,
      city: undefined,
      streetName: undefined,
      houseNumber: undefined,
      suburb: undefined
    }

    response.data.addressComponents.forEach(
      (component: AddressResponseData) => {
        if (component.types.includes('postal_code')) {
          address.zipCode = component.longName
        }
        if (component.types.includes('locality')) {
          address.city = component.longName
        }
        if (component.types.includes('route')) {
          address.streetName = component.longName
        }
        if (component.types.includes('street_number')) {
          address.houseNumber = component.longName
        }
        if (component.types.includes('sublocality')) {
          address.suburb = component.longName
        }
      }
    )

    return address
  } catch (error) {
    console.error('Failed to retrieve address from Google Maps API:', error)
    throw error
  }
}
