import type { CSSProperties } from 'react'

import {
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Typography,
  BodyText,
  appendToId
} from '../'
import { IsMobile } from '../utils'

import type { EnumControlProps } from './EnumControl'

export function EnumRadioControl({
  errorMessage,
  label,
  onChange,
  options = [],
  optionsLabels,
  value,
  size,
  color = 'primary',
  disabled,
  defaultSelection: initial,
  required,
  id
}: EnumControlProps) {
  const labels = optionsLabels ? optionsLabels : options

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const mobile = IsMobile()
  const noLabelStyle: CSSProperties = {
    textAlign: 'right',
    marginBottom: '-20px'
  }

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={errorMessage ? true : false}
    >
      <div style={required && !label ? noLabelStyle : {}}>
        <Typography color="textSecondary" variant={label ? 'body2' : 'body1'}>
          {`${label ? label : ''}${required ? '*' : ''}`}
        </Typography>
      </div>
      <RadioGroup
        id={id}
        onChange={handleRadioChange}
        row={mobile ? false : true}
        value={value || initial}
      >
        {options.map((item, index) => {
          const label = labels[index]
          const radioId = appendToId(id, label)

          return (
            <Radio
              color={color}
              id={radioId}
              key={item}
              label={<BodyText color="textSecondary">{label}</BodyText>}
              labelPlacement="end"
              size={size}
              value={item}
            />
          )
        })}
      </RadioGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}
