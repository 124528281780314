export const JOURNEY_NAVIGATION_EVENT = 'JourneyNavigationEvent'
export const JOURNEY_LOADING_EVENT = 'JourneyLoadingEvent'
export const JOURNEY_EXIT_EVENT = 'JourneyExitEvent'

/**
 * @todo Start using this enum instead of the constants
 */
export enum JOURNEY_EVENT {
  NAVIGATION = 'JourneyNavigationEvent',
  LOADING = 'JourneyLoadingEvent',
  EXIT = 'JourneyExitEvent'
}
